@define-mixin profileColorVariant $backgroundColor, $fontColor {
  background: $backgroundColor;

  & .Profile__description {
    color: $fontColor;

    &:not(.Profile__description--noQuotation)::before {
      background: svg-load("Icons/ProfileVector.svg", fill: $fontColor);
    }
  }
}

.Profile {
  &,
  &.Profile--None {
    @mixin profileColorVariant var(--whiteColor), var(--blackColor);
  }

  &.Profile--Beige {
    @mixin profileColorVariant var(--theme-color-beige-bg-color),
      var(--theme-color-beige-text-color);
  }

  &.Profile--BlueFive {
    @mixin profileColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color);
  }

  &.Profile--BlueTwo {
    @mixin profileColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color);
  }

  &.Profile--SandFour {
    @mixin profileColorVariant var(--theme-color-brown-bg-color),
      var(--theme-color-brown-text-color);
  }

  &.Profile--GreenFour {
    @mixin profileColorVariant var(--theme-color-green-bg-color),
      var(--theme-color-green-text-color);
  }

  &.Profile--YellowFour {
    @mixin profileColorVariant var(--theme-color-yellow-bg-color),
      var(--theme-color-yellow-text-color);
  }

  &.Profile--TealFive {
    @mixin profileColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color);
  }

  &.Profile--TealTwo {
    @mixin profileColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color);
  }

  &.Profile--GreenTwo {
    @mixin profileColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color);
  }

  &.Profile--SandTwo {
    @mixin profileColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color);
  }

  &.Profile--ForestTwo {
    @mixin profileColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color);
  }

  &.Profile--GreenOne {
    @mixin profileColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color);
  }

  &.Profile--GreenFive {
    @mixin profileColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color);
  }

  &.Profile--ForestFive {
    @mixin profileColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color);
  }

  &.Profile--BlueThree {
    @mixin profileColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color);
  }

  &.Profile--YellowTwo {
    @mixin profileColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color);
  }

  &.Profile--YellowFive {
    @mixin profileColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color);
  }

  &.Profile--RedFour {
    @mixin profileColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color);
  }

  &.Profile--GreyTwo {
    @mixin profileColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color);
  }

  &.Profile--GreyFive {
    @mixin profileColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color);
  }
}
