/* stylelint-disable declaration-no-important */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-empty-line-before */
.ComparisonBlock--sidePaddings {
  @media (--tablet) {
    padding: 0 calc(40px + var(--spacing-xs) + var(--spacing-xxxs));
  }
}

.ComparisonBlock__contentWrapper {
  position: relative;
}

.ComparisonBlock__content {
  width: 100%;
  padding-bottom: calc(var(--spacing-xs) + calc(1.5 * var(--spacing-xxxs)));
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  scroll-behavior: smooth;

  @media (--laptop) {
    padding-bottom: calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs)));
  }
}

.ComparisonBlock__content::-webkit-scrollbar {
  height: 4px;
  background-color: #d9d9d9;
}

.ComparisonBlock__content::-webkit-scrollbar-thumb {
  background-color: var(--blackColor);
  border-radius: 1px;
}

.ComparisonBlock__content::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

.ComparisonBlock__moveBtn {
  position: absolute;

  @media (--mobileOnly) {
    display: none !important;
  }

  &.ComparisonBlock__moveBtn--left {
    right: calc(100% + var(--spacing-xs) + var(--spacing-xxxs));

    & svg {
      transform: rotate(90deg);
    }
  }

  &.ComparisonBlock__moveBtn--right {
    left: calc(100% + var(--spacing-xs) + var(--spacing-xxxs));

    & svg {
      transform: rotate(-90deg);
    }
  }

  &.ComparisonBlock__moveBtn--hidden {
    display: none !important;
  }
}

.ComparisonBlock__table {
  border-collapse: collapse;
  width: 100%;
  border: none;
  color: var(--base-grey-dark-100);

  & .visuallyHidden {
    top: 0;
    left: 0;
  }
}

.ComparisonBlock__productNameDrawer {
  @mixin typographyMobileStrongLarge;
  padding: calc(1.375 * var(--spacing-xxs)) calc(1.25 * var(--spacing-xxs));
  vertical-align: middle;

  @media (--tablet) {
    @mixin typographyDesktopStrongLarge;
    padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-xxs)
      calc(var(--spacing-xxs) + var(--spacing-xxxs));

    &:not(:last-child) {
      padding-right: var(--spacing-s);
    }
  }
}

.ComparisonBlock__specRow {
  &:last-child {
    & .ComparisonBlock__specNameDrawer {
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background: var(--base-grey-dark-60);
        top: calc(100% - 1px);
        left: 0;
      }
    }

    & .ComparisonBlock__specValueDrawer {
      border-bottom: 2px solid var(--base-grey-medium-100);
    }
  }
}

.ComparisonBlock__sticky {
  position: sticky;
  left: 0;
  z-index: 100;
}

.ComparisonBlock__sticky--header {
  background-color: white;
}

.ComparisonBlock__specNameDrawer {
  padding: calc(1.375 * var(--spacing-xxs)) calc(1.25 * var(--spacing-xxs));
  background: var(--base-grey-light-80);
  min-width: 155px;
  max-width: 155px;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: var(--base-grey-dark-60);
    top: -1px;
    left: 0;
  }

  @media (--tablet) {
    padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-xxxs)
      calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0;
  }
}

.ComparisonBlock__specNameDrawerContent {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxxs);
}

.ComparisonBlock__specName {
  @mixin typographyMobileStrongLarge;
  width: max-content;

  @media (--tablet) {
    @mixin typographyDesktopStrongLarge;
  }
}

.ComparisonBlock__specValueDrawer {
  @mixin typographyMobileDefaultLarge;
  padding: calc(1.375 * var(--spacing-xxs)) calc(1.25 * var(--spacing-xxs));
  border-top: 2px solid var(--base-grey-medium-100);
  min-width: 157px;

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;
    padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-xxs)
      calc(var(--spacing-xxs) + var(--spacing-xxxs));
    min-width: 177px;

    &:not(:last-child) {
      padding-right: var(--spacing-s);
    }
  }
}

.ComparisonBlock__tooltipTrigger {
  @mixin resetButtonStylesNoOverride;
}

.ComparisonBlock__tooltipTriggerIcon {
  font-size: 1rem;
}

.ComparisonBlock__tooltipContent {
  text-align: center;
}
