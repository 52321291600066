.StoryTextContent__inner {
  & .StoryRichtextBlock {
    clear: both;
    margin: 0 30px;
    width: calc(100% - 60px);

    @media (--tablet) {
      clear: none;
      float: right;
      margin: 10px 60px 80px 0;
      max-width: calc(70% - 60px);
      padding: 0 0 0 25px;
      width: calc(100% - 60px);
    }

    @media (--laptop) {
      margin: 10px calc((100% - 890px) / 2) 50px 25px;
      max-width: calc(560px + 25px);
    }
  }

  & ul.StoryVerticalContentList__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & li {
      padding: var(--spacing-s) 0;
      border-bottom: 1px solid var(--base-grey-medium-80);

      @media (--mobileSmall) {
        padding: var(--spacing-m) 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
