.InteractiveHeroForVesselSegments {
  width: 100%;

  & .ImageHotSpots__Container {
    position: relative;
    display: block;
    padding: 0;

    & img {
        display: block;
        position: static;
    }

    & .LabeledHotSpot__Link {
      display: none;
      @media (--tablet) {
        display: block;
      }
    }
  }
}

.InteractiveHeroForVesselSegments__picture {
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.InteractiveHeroForVesselSegments__picture--mobile {
  display: block;

  @media (--tablet) {
    display: none;
  }

  & img {
    width: 100%;
  }
}

