@define-mixin buttonSecondary {
  @mixin button;

  display: inline-block;
  min-width: 145px;
  padding: var(--spacing-xxs) var(--spacing-s);
  background: transparent;
  border: 2px solid var(--blackColor);
  color: var(--blackColor);
  text-align: center;
  transition:
    border-color 0.2s ease-out,
    color 0.2s ease-out;
  letter-spacing: normal;
  cursor: pointer;

  &:hover {
    background: transparent;
    border-color: var(--grayColor_tint_3);
    color: var(--grayColor_tint_3);
  }

  &:active {
    background: transparent;
    border-color: var(--blackColor);
    color: var(--blackColor);
  }

  &:disabled {
    background: transparent;
    border-color: var(--grayColor_tint_4);
    color: var(--grayColor_tint_4);
  }

  &:focus {
    background: transparent;
    outline: var(--outlineColorTertiary_tint_1) solid 2px;
  }
}

@define-mixin buttonSecondaryColorVariant $color, $activeColor, $fontColor: $color,
  $fontActiveColor: $activeColor {
  color: $fontColor;
  border-color: $color;

  &:active {
    color: $fontActiveColor;
    border-color: $activeColor;
  }
}

@define-mixin buttonSecondaryBronze {
  @mixin buttonSecondaryColorVariant var(--kog_bronze_tint_6), var(--kog_bronze_tint_7);
}

@define-mixin buttonSecondaryAqua {
  @mixin buttonSecondaryColorVariant var(--kog_aqua_tint_6), var(--kog_aqua_tint_7);
}

@define-mixin buttonSecondaryGreen {
  @mixin buttonSecondaryColorVariant var(--kog_green_tint_3), var(--kog_green_tint_4);
}

/* theme colors */
@define-mixin buttonSecondaryThemeColorBeige {
  @mixin buttonSecondaryColorVariant var(--theme-color-beige-button-secondary-bg-color),
    var(--theme-color-beige-button-secondary-active-bg-color),
    var(--theme-color-beige-button-secondary-text-color),
    var(--theme-color-beige-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorGreenFour {
  @mixin buttonSecondaryColorVariant var(--theme-color-green-button-secondary-bg-color),
    var(--theme-color-green-button-secondary-active-bg-color),
    var(--theme-color-green-button-secondary-text-color),
    var(--theme-color-green-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorBlueFive {
  @mixin buttonSecondaryColorVariant var(--theme-color-blue-button-secondary-bg-color),
    var(--theme-color-blue-button-secondary-active-bg-color),
    var(--theme-color-blue-button-secondary-text-color),
    var(--theme-color-blue-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorTealTwo {
  @mixin buttonSecondaryColorVariant var(--theme-color-turquoise-dark-button-secondary-bg-color),
    var(--theme-color-turquoise-dark-button-secondary-active-bg-color),
    var(--theme-color-turquoise-dark-button-secondary-text-color),
    var(--theme-color-turquoise-dark-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorSandFour {
  @mixin buttonSecondaryColorVariant var(--theme-color-brown-button-secondary-bg-color),
    var(--theme-color-brown-button-secondary-active-bg-color),
    var(--theme-color-brown-button-secondary-text-color),
    var(--theme-color-brown-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorTealFive {
  @mixin buttonSecondaryColorVariant var(--theme-color-turquoise-button-secondary-bg-color),
    var(--theme-color-turquoise-button-secondary-active-bg-color),
    var(--theme-color-turquoise-button-secondary-text-color),
    var(--theme-color-turquoise-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorYellowFour {
  @mixin buttonSecondaryColorVariant var(--theme-color-yellow-button-secondary-bg-color),
    var(--theme-color-yellow-button-secondary-active-bg-color),
    var(--theme-color-yellow-button-secondary-text-color),
    var(--theme-color-yellow-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryYellowFour {
  @mixin buttonSecondaryColorVariant var(--kog_yellow_tint_3), var(--kog_yellow_tint_2),
    var(--theme-color-yellow-button-primary-text-color),
    var(--theme-color-yellow-button-primary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorGreenTwo {
  @mixin buttonSecondaryColorVariant var(--theme-color-green-dark-button-secondary-bg-color),
    var(--theme-color-green-dark-button-secondary-active-bg-color),
    var(--theme-color-green-dark-button-secondary-text-color),
    var(--theme-color-green-dark-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorBlueTwo {
  @mixin buttonSecondaryColorVariant var(--theme-color-blue-dark-button-secondary-bg-color),
    var(--theme-color-blue-dark-button-secondary-active-bg-color),
    var(--theme-color-blue-dark-button-secondary-text-color),
    var(--theme-color-blue-dark-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorSandTwo {
  @mixin buttonSecondaryColorVariant var(--theme-color-sand-dark-button-secondary-bg-color),
    var(--theme-color-sand-dark-button-secondary-active-bg-color),
    var(--theme-color-sand-dark-button-secondary-text-color),
    var(--theme-color-sand-dark-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorForestTwo {
  @mixin buttonSecondaryColorVariant var(--theme-color-forest-dark-button-secondary-bg-color),
    var(--theme-color-forest-dark-button-secondary-active-bg-color),
    var(--theme-color-forest-dark-button-secondary-text-color),
    var(--theme-color-forest-dark-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorPink {
  @mixin buttonSecondaryColorVariant var(--theme-color-pink-button-secondary-bg-color),
    var(--theme-color-pink-button-secondary-active-bg-color),
    var(--theme-color-pink-button-secondary-text-color),
    var(--theme-color-pink-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorGreenOne {
  @mixin buttonSecondaryColorVariant var(--theme-color-green-one-button-secondary-bg-color),
    var(--theme-color-green-one-button-secondary-active-bg-color),
    var(--theme-color-green-one-button-secondary-text-color),
    var(--theme-color-green-one-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorGreenFive {
  @mixin buttonSecondaryColorVariant var(--theme-color-green-five-button-secondary-bg-color),
    var(--theme-color-green-five-button-secondary-active-bg-color),
    var(--theme-color-green-five-button-secondary-text-color),
    var(--theme-color-green-five-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorForestFive {
  @mixin buttonSecondaryColorVariant var(--theme-color-forest-five-button-secondary-bg-color),
    var(--theme-color-forest-five-button-secondary-active-bg-color),
    var(--theme-color-forest-five-button-secondary-text-color),
    var(--theme-color-forest-five-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorBlueThree {
  @mixin buttonSecondaryColorVariant var(--theme-color-blue-three-button-secondary-bg-color),
    var(--theme-color-blue-three-button-secondary-active-bg-color),
    var(--theme-color-blue-three-button-secondary-text-color),
    var(--theme-color-blue-three-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorYellowTwo {
  @mixin buttonSecondaryColorVariant var(--theme-color-yellow-two-button-secondary-bg-color),
    var(--theme-color-yellow-two-button-secondary-active-bg-color),
    var(--theme-color-yellow-two-button-secondary-text-color),
    var(--theme-color-yellow-two-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryYellowTwo {
  @mixin buttonSecondaryColorVariant var(--kog_yellow_tint_2), var(--kog_yellow), var(--blackColor),
    var(--blackColor);
}

@define-mixin buttonSecondaryThemeColorYellowFive {
  @mixin buttonSecondaryColorVariant var(--theme-color-yellow-five-button-secondary-bg-color),
    var(--theme-color-yellow-five-button-secondary-active-bg-color),
    var(--theme-color-yellow-five-button-secondary-text-color),
    var(--theme-color-yellow-five-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryYellowFive {
  @mixin buttonSecondaryColorVariant var(--kog_yellow_tint_4), var(--kog_yellow), var(--blackColor),
    var(--blackColor);
}

@define-mixin buttonSecondaryThemeColorRedFour {
  @mixin buttonSecondaryColorVariant var(--theme-color-red-four-button-secondary-bg-color),
    var(--theme-color-red-four-button-secondary-active-bg-color),
    var(--theme-color-red-four-button-secondary-text-color),
    var(--theme-color-red-four-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorGreyTwo {
  @mixin buttonSecondaryColorVariant var(--theme-color-grey-two-button-secondary-bg-color),
    var(--theme-color-grey-two-button-secondary-active-bg-color),
    var(--theme-color-grey-two-button-secondary-text-color),
    var(--theme-color-grey-two-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryGreyTwo {
  @mixin buttonSecondaryColorVariant var(--grayColor_tint_2), var(--grayColor_tint_4),
    var(--blackColor), var(--blackColor), "darken";
}

@define-mixin buttonSecondaryThemeColorGreyFive {
  @mixin buttonSecondaryColorVariant var(--theme-color-grey-five-button-secondary-bg-color),
    var(--theme-color-grey-five-button-secondary-active-bg-color),
    var(--theme-color-grey-five-button-secondary-text-color),
    var(--theme-color-grey-five-button-secondary-active-text-color);
}

@define-mixin buttonSecondaryThemeColorWhite {
  @mixin buttonSecondaryColorVariant var(--whiteColor), var(--base-grey-light-100),
    var(--whiteColor), var(--base-grey-light-100);
}

@define-mixin buttonSecondaryThemeColorBlack {
  @mixin buttonSecondaryColorVariant var(--base-grey-black-100), var(--grayColor_tint_3),
    var(--base-grey-black-100), var(--grayColor_tint_3);
}
