.Toast {
  display: flex;
  padding: calc(var(--spacing-xxs) + var(--spacing-xxxs) + calc(var(--spacing-xxxs) / 2))
    calc(var(--spacing-xxs) + var(--spacing-xxxs))
    calc(var(--spacing-xxs) + var(--spacing-xxxs) + calc(var(--spacing-xxxs) / 2))
    calc(var(--spacing-xxs) + calc(var(--spacing-xxxs) / 2)); /* 14px 12px 14px 10px */
  border-radius: 2px;
  border: 1px solid #eeede6;
  background-color: var(--base-grey-black-100);
  color: var(--base-grey-white);
  align-items: center;
  max-width: 358px;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.Toast--informative {
  background-color: var(--accent-blue-accent-default);
}

.Toast--success {
  background-color: var(--success-success-darker);
}

.Toast--alert {
  background-color: var(--alert-alert-darker);
}

.Toast--error {
  background-color: var(--error-error-darker);
}

.Toast__icon {
  font-size: 1.5rem; /* 24px */
  line-height: 1;
}

.Toast__content {
  @mixin typographyDesktopDefaultSmall;

  flex: 1;
  margin-inline: var(--spacing-xxs) calc(var(--spacing-xs) + calc(var(--spacing-xxxs) / 2));
}

.Toast__closeButton {
  @mixin resetButtonStylesNoOverride;
  @mixin focusVisible;

  cursor: pointer;
}

.Toast__closeButtonIcon {
  font-size: 1rem; /* 16px */
  line-height: 1;
}
