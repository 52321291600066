.RichTestimonial {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 0;
  z-index: 1;

  @media (--tablet) {
    padding: 2.5rem 0;
  }

  @media (--laptop) {
    padding: 3.5rem 0;
  }

  @media (--desktop) {
    padding: 4.5rem 0;
  }
}

.RichTestimonial__inner {
  display: flex;
  flex-flow: row wrap;
  position: relative;

  @media (--tablet) {
    align-items: center;
    margin: 0 0 1.5rem 0;
  }
}

.RichTestimonial__quote {
  width: 100%;
  margin: 0 0 1rem 0;

  @media (--tablet) {
    order: 2;
    margin: 0 0 3rem calc(200px + 4rem);
  }

  & blockquote {
    /* use after accepting new testimonial design */
    /* @mixin blockquote; */
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
    margin: 0;
    text-transform: none;

    @media (--tablet) {
      font-size: 26px;
      line-height: 1.4;
    }

    @media (--laptop) {
      font-size: 30px;
      line-height: 1.4;
    }

    @media (--desktop) {
      font-size: 36px;
      line-height: 1.4;
    }
  }
}

.RichTestimonial__author {
  width: 60%;
  align-self: center;
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  font-style: italic;

  @media (--tablet) {
    font-size: 26px;
    order: 3;
    align-self: flex-start;
    width: auto;
    margin-left: calc(200px + 4rem);
  }

  @media (--laptop) {
    font-size: 30px;
  }

  @media (--desktop) {
    font-size: 34px;
  }
}

.RichTestimonial__authorName {
  margin-right: 0.5rem;
}

.RichTestimonial__picture {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 1rem;

  @media (--tablet) {
    width: 200px;
    order: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & picture {
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin: initial;

    @media (--tablet) {
      width: 200px;
      height: 200px;
    }
  }

  & img {
    min-height: 100%;
    width: 100%;
  }
}

.break::before {
  content: ',';
  display: inline-block;
  margin-right: 0.4rem;
}

.RichTestimonial.fullWidth {
  & .RichTestimonial__quote {
    @media (--tablet) {
      width: 100%;
      margin-left: initial;
    }
  }

  & .RichTestimonial__author {
    margin-left: 0;
  }
}

.RichTestimonial__heading {
  @mixin h2;
}

.RichTestimonial__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.RichTestimonial__background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 130%;
  height: 130%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(15px);
  opacity: 0.4;
}
