.ResourceTile {
  position: relative;
}

.ResourceTile__label {
  display: flex;
  margin-block: var(--spacing-xs);
  line-height: 1;

  @media (--tablet) {
    position: absolute;
    top: 10px;
    left: 10px;
    margin-block: 0;
    z-index: var(--z-index-resource-tile-label);
  }

  & .Label.Label--large {
    @mixin labelLargeDesktop;
  }
}

.ResourceTile__excerpt {
  color: var(--base-grey-dark-100);
  font-size: 0.875rem;
  line-height: normal;
  margin-block: 0;

  @mixin lineClamp 3;

  @media (--tablet) {
    margin-block: 0 var(--spacing-xs);
  }
}

.ResourceTile__link {
  display: block;

  @mixin focusVisible var(--outlineColorSecondary);

  & + .ResourceTile__excerpt {
    @media (--tablet) {
      margin-block: var(--spacing-xs);
    }
  }
}

.ResourceTile__thumbnail {
  margin-bottom: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  aspect-ratio: 335 / 175;

  @media (--tablet) {
    aspect-ratio: 235 / 140;
  }

  & img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 335 / 175;

    @media (--tablet) {
      aspect-ratio: 235 / 140;
    }
  }
}

.ResourceTile__title {
  @mixin typographyDesktopStrongLarge;

  color: var(--base-grey-black-100);
}

.ResourceTile__tags {
  display: none;

  @media (--tablet) {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));
    line-height: 1;
  }
}

.ResourceTile--big {
  & .ResourceTile__label {
    @media (--tablet) {
      top: 20px;
      left: 20px;
    }
  }

  & .ResourceTile__excerpt {
    @media (--tablet) {
      @mixin typographyDesktopDefaultMedium;

      margin-block: 0 var(--spacing-xs);
    }
  }
}

.ResourceTile--small {
  & .ResourceTile__thumbnail {
    aspect-ratio: 245 / 210;

    @media (--tablet) {
      aspect-ratio: 333 / 140;
      margin-bottom: var(--spacing-xxs);
    }
  }

  & .ResourceTile__title {
    @mixin typographyMobileStrongLarge;

    @media (--tablet) {
      @mixin typographyDesktopStrongLarge;
    }
  }

  & .ResourceTile__excerpt {
    @mixin typographyMobileDefaultSmall;

    margin-block: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0;

    @media (--tablet) {
      @mixin typographyDesktopDefaultSmall;

      margin-block: var(--spacing-xxs) 0;
    }
  }
}
