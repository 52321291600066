.ContainerWithBackground {
  padding: 1rem 0;

  @media (--tablet) {
    padding: 3rem 0;
  }

  & .VideoFigureWrapper {
    padding: 0;
  }
}
