@define-mixin ctaButtonColorVariant $primaryBtnVariant, $secondaryBtnVariant {
  &.CallToAction__background .CallToAction__buttons,
  & .CallToAction__background .CallToAction__buttons {
    & li {
      & .CallToActionButton--primary:not(.CallToActionButton--colored) {
        @mixin $(primaryBtnVariant);
      }

      & .CallToActionButton--secondary:not(.CallToActionButton--colored) {
        @mixin $(secondaryBtnVariant);
      }
    }
  }
}

.CallToAction {
  &.CallToAction--buttonColorWhite {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorWhite, buttonSecondaryThemeColorWhite;
  }

  &.CallToAction--buttonColorBlack {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorBlack, buttonSecondaryThemeColorBlack;
  }

  &.CallToAction--buttonColorGreenFour {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorGreenFour,
      buttonSecondaryThemeColorGreenFour;
  }

  &.CallToAction--buttonColorBlueFive {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorBlueFive, buttonSecondaryThemeColorBlueFive;
  }

  &.CallToAction--buttonColorTealTwo {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorTealTwo, buttonSecondaryThemeColorTealTwo;
  }

  &.CallToAction--buttonColorSandFour {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorSandFour, buttonSecondaryThemeColorSandFour;
  }

  &.CallToAction--buttonColorTealFive {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorTealFive, buttonSecondaryThemeColorTealFive;
  }

  &.CallToAction--buttonColorYellowFour {
    @mixin ctaButtonColorVariant buttonPrimaryYellowFour, buttonSecondaryYellowFour;
  }

  &.CallToAction--buttonColorGreenTwo {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorGreenFive,
      buttonSecondaryThemeColorGreenFive;
  }

  &.CallToAction--buttonColorBlueTwo {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorBlueTwo, buttonSecondaryThemeColorBlueTwo;
  }

  &.CallToAction--buttonColorSandTwo {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorSandTwo, buttonSecondaryThemeColorSandTwo;
  }

  &.CallToAction--buttonColorForestTwo {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorForestTwo,
      buttonSecondaryThemeColorForestTwo;
  }

  &.CallToAction--buttonColorGreenOne {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorGreenOne, buttonSecondaryThemeColorGreenOne;
  }

  &.CallToAction--buttonColorGreenFive {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorGreenTwo, buttonSecondaryThemeColorGreenTwo;
  }

  &.CallToAction--buttonColorForestFive {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorForestFive,
      buttonSecondaryThemeColorForestFive;
  }

  &.CallToAction--buttonColorBlueThree {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorBlueThree,
      buttonSecondaryThemeColorBlueThree;
  }

  &.CallToAction--buttonColorYellowTwo {
    @mixin ctaButtonColorVariant buttonPrimaryYellowTwo, buttonSecondaryYellowTwo;
  }

  &.CallToAction--buttonColorYellowFive {
    @mixin ctaButtonColorVariant buttonPrimaryYellowFive, buttonSecondaryYellowFive;
  }

  &.CallToAction--buttonColorRedFour {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorRedFour, buttonSecondaryThemeColorRedFour;
  }

  &.CallToAction--buttonColorGreyTwo {
    @mixin ctaButtonColorVariant buttonPrimaryGreyTwo, buttonSecondaryGreyTwo;
  }

  &.CallToAction--buttonColorGreyFive {
    @mixin ctaButtonColorVariant buttonPrimaryThemeColorGreyFive, buttonSecondaryThemeColorGreyFive;
  }
}
