.RadioSelect {
  position: relative;
}

.RadioSelect__control {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonBaseOutlined;

  width: 100%;
  justify-content: space-between;

  @media (--tablet) {
    width: auto;
  }
}

.RadioSelect__control--focused {
  outline: 2px solid var(--outlineColorSecondary);
  outline-offset: 1px;
  color: var(--base-grey-black-100);
  border-color: var(--base-grey-black-100);
  background: var(--base-grey-medium-100);
}

.RadioSelect__valueContainer {
  display: flex;
  overflow: hidden;
}

.RadioSelect__placeholder,
.RadioSelect__singleValue {
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.RadioSelect__indicatorsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RadioSelect__dropdownIndicator {
  transition: transform 250ms;

  @media (--reduced-motion) {
    transition: none;
  }
}

.RadioSelect__dropdownIndicator--inverted {
  transform: rotate(180deg);
}

.RadioSelect__menuPortal {
  position: fixed;
  inset: 0;
  z-index: 1000;
}

.RadioSelect__drawer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 90%;

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  @supports (max-height: 90dvh) {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    max-height: 90dvh;
  }

  /* stylelint-disable-next-line csstools/media-use-custom-media */
  @media (min-height: 450px) {
    max-height: 70%;

    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    @supports (max-height: 70dvh) {
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      max-height: 70dvh;
    }
  }
}

.RadioSelect__drawerInner {
  background-color: var(--base-grey-white);
  border-radius: 12px 12px 0 0;
  height: 100%;
  transition: transform 250ms;
  display: flex;
  flex-direction: column;
}

.RadioSelect__drawerContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.RadioSelect__menu {
  z-index: 100;

  @media (--tablet) {
    height: auto;
    position: absolute;
    left: 0;
    background-color: var(--base-grey-white);
    margin-top: 3px;
    border-radius: 2px;
    box-shadow: 0 1px 6px 0 rgb(84 96 118 / 0.24);
    min-width: 100%;
  }
}

.RadioSelect__menuList {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    padding-block: var(--spacing-xxxs);
    overflow-y: auto;
    max-height: 70%;

    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    @supports (max-height: 70dvh) {
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      max-height: 70dvh;
    }
  }
}

.RadioSelect__menuHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 0.375rem 1.625rem; /* 6px 26px */
}

.RadioSelect__handle {
  display: block;
  background-color: var(--base-grey-medium-100);
  border-radius: 21px;
  width: 64px;
  height: 4px;
}

.RadioSelect__menuTitle {
  @mixin typographyMobileStrongXXLarge;

  color: var(--blackColor);
  padding-block: 0 1.625rem; /* 26px */
  text-align: center;
}

.RadioSelect__menuListOptions {
  padding-inline: var(--spacing-xs);
  overflow-y: scroll;
  padding-top: 2px; /* To prevent outline being cut off */
  margin-top: -2px;

  @media (--tablet) {
    overflow-y: visible;
    padding-inline: 0;
    padding-top: 0;
    margin-top: 0;
  }
}

.RadioSelect__menuOption {
  padding-block: 0.4375rem; /* 7px */
  border-bottom: 1px solid var(--base-grey-light-100);
  transition: transform 250ms;

  @media (--tablet) {
    padding-inline: var(--spacing-xs);
    padding-block: 0;
    border-bottom: 0;
    cursor: pointer;
  }

  &:first-child {
    padding-top: 0;
  }

  &:hover {
    background-color: var(--base-grey-light-100);
  }

  &.RadioSelect__menuOption--focused {
    outline: var(--outlineColor) solid 2px;
  }

  &.RadioSelect__menuOption--selected {
    background-color: var(--base-grey-light-100);
  }
}

.RadioSelect__menuOptionInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: var(--spacing-xxs);
  height: 2.5rem; /* 40px */
  overflow: hidden;
}

.RadioSelect__menuOptionLabel {
  @mixin typographyDesktopDefaultLarge;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (--tablet) {
    @mixin typographyDesktopDefaultMedium;
  }
}

.RadioSelect__menuOptionRadioButton {
  @media (--tablet) {
    display: none;
  }
}
