.TileGrid {
  @mixin kogGrid 5, 12, 20px;
}

.TileGrid__TopBar {
  grid-column: 1/-1;
}

.TileGrid__SidePanel {
  grid-column: 1/-1;

  @media (--laptop) {
    grid-column: span 3;
  }
}

.TileGrid__MainContent {
  grid-column: 1/-1;

  @media (--laptop) {
    grid-column: 4/-1;
  }
}

.TileGrid__MainContent--fullWidth {
  @media (--laptop) {
    grid-column: 1/-1;
  }
}
