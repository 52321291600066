.ArticlesArchive__content {
    @mixin wrapper;
}

.ArticlesArchive__loadMore {
    margin: 90px 0 0;

    & button {
        margin: 0 auto;
        @mixin buttonLarge;
    }
}

.ArticlesArchiveFiltersList,
.ArticlesArchiveFiltersActive {
    @mixin wrapper;
}
