.HeroTile__imageContainer {
  position: absolute;
  inset: 0;
  z-index: var(--z-index-product-category-tile-background-image);
  transition: transform var(--transition-duration);

  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    z-index: var(--z-index-product-category-tile-background-image-overlay);
    background: linear-gradient(
      0deg,
      color(var(--blackColor) a(75%)) 15.5%,
      color(var(--blackColor) a(0%)) 61.9%
    );
  }
}

.HeroTile__image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.HeroTile__overlay {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: calc(var(--spacing-xs) + (var(--spacing-xxxs) / 2));
  position: absolute;
  right: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
  left: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
  bottom: 0;
  z-index: var(--z-index-product-category-tile-overlay);
  color: var(--base-grey-white);
}

.HeroTile__content {
  display: flex;
  flex-direction: column;
  max-width: 250px;
}

.HeroTile__title {
  @mixin typographyMobileStrongLarge;
  @mixin lineClamp 2;

  margin-block: 0 var(--spacing-xxs);
  transition: all var(--transition-duration);

  @media (--mobileSmall) {
    @mixin typographyDesktopStrongLarge;

    margin-block: 0 var(--spacing-xxs);
  }

  &:only-child {
    margin-block: 0 var(--spacing-xs);
  }

  @media (--laptop) {
    margin-block: 0 var(--spacing-xs);
  }

  @media (--reduced-motion) {
    transition-duration: var(--transition-duration-reduced-motion);
  }
}

.HeroTile__descriptionWrapper {
  transition: all var(--transition-duration);

  @media (--laptop) {
    height: 0;
    margin-block: 0;
  }

  @media (--reduced-motion) {
    transition-duration: var(--transition-duration-reduced-motion);
  }
}

.HeroTile__description {
  @mixin typographyMobileDefaultSmall;
  @mixin lineClamp 3;

  margin-block: 0 var(--spacing-xs);

  @media (--mobileSmall) {
    @mixin typographyDesktopDefaultSmall;
  }
}

.HeroTile__icon {
  @mixin flexCenter;

  width: var(--spacing-l);
  height: var(--spacing-l);
  margin-block: 0 var(--spacing-xs);

  & svg {
    fill: currentColor;
  }
}

.HeroTile {
  --product-category-tile-height: 13.125rem; /* 210px */

  @mixin resetButtonStylesNoOverride;

  display: block;
  position: relative;
  width: 100%;
  height: var(--product-category-tile-height);
  overflow: hidden;
  cursor: pointer;

  @mixin focusVisible var(--outlineColorSecondary), 3px, 1px;

  &:hover {
    & .HeroTile__imageContainer {
      transform: scale(1.2);

      @media (--reduced-motion) {
        transform: none;
      }
    }

    & .HeroTile__title:not(:only-child) {
      @media (--laptop) {
        margin-block: 0 var(--spacing-xxs);
      }
    }

    & .HeroTile__descriptionWrapper {
      @media (--laptop) {
        height: calc(3.25rem + var(--spacing-xs));
        margin-block: 0;
      }
    }
  }
}
