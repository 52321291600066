.ButtonOutlined.ProductsListingFilters__showFilters {
  width: 100%;
  justify-content: center;
  margin-bottom: var(--spacing-m);
}

.ProductsListingFilters__content {
  @media (--laptop) {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-xxs) + (var(--spacing-xxxs) * 0.5));
    padding-block: 0 var(--spacing-s);
  }
}
