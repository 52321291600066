.grid {
  @neat-outer-container;
}

.row {
  @neat-container;
}

.col-2 {
  @neat-span-columns 2;
}

.col-3 {
  @neat-span-columns 3;
  @neat-omega 4n;
}

.col-4 {
  @neat-span-columns 4;
  @neat-omega 3n;
}

.col-5 {
  @neat-span-columns 5;
}
.col-6 {
  @neat-span-columns 6;
}

.col-8 {
  @neat-span-columns 8;
}

.col-9 {
  @neat-span-columns 9;
}

.col-10 {
  @neat-span-columns 10;
}

.col-11 {
  @neat-span-columns 11;
}

.col-12 {
  @neat-span-columns 12;
}

.t-col-2 {
  @media (--tablet) {
    @neat-span-columns 2;
    @neat-omega 6n;
  }
}

.t-col-3 {
  @media (--tablet) {
    @neat-span-columns 3;
    @neat-omega 4n;
  }
}

.t-col-4 {
  @media (--tablet) {
    @neat-span-columns 4;
    @neat-omega 3n;
  }
}

.t-col-6 {
  @media (--tabletOnly) {
    @neat-span-columns 6;
    @neat-omega 2n;
  }
}

.tt-col-6 {
@media (--tablet) {
        @neat-span-columns 6;
        @neat-omega 2n;
    }
}

.t-col-8 {
  @media (--tablet) {
    @neat-span-columns 8;
  }
}

.t-col-9 {
  @media (--tablet) {
    @neat-span-columns 9;
  }
}

.t-col-10 {
  @media (--tablet) {
    @neat-span-columns 10;
  }
}

.l-col-4 {
  @media (--laptop) {
    @neat-span-columns 4;
  }
}

.l-col-5 {
  @media (--laptop) {
    @neat-span-columns 5;
  }
}

.l-col-7 {
  @media (--laptop) {
    @neat-span-columns 7;
  }
}

.l-col-8 {
  @media (--laptop) {
    @neat-span-columns 8;
  }
}

.d-col-3 {
  @media (--desktop) {
    @neat-span-columns 3;
    @neat-omega 4n;
  }
}

.d-col-4 {
  @media (--desktop) {
    @neat-span-columns 4;
    @neat-omega 3n;
  }
}

.d-col-5 {
  @media (--desktop) {
    @neat-span-columns 5;
  }
}

.d-col-6 {
  @media (--desktop) {
    @neat-span-columns 6;
    @neat-omega 2n;
  }
}

.d-col-7 {
  @media (--desktop) {
    @neat-span-columns 7;
  }
}

.d-col-8 {
  @media (--desktop) {
    @neat-span-columns 8;
  }
}

.d-col-9 {
  @media (--desktop) {
    @neat-span-columns 9;
  }
}

.d-col-10 {
  @media (--desktop) {
    @neat-span-columns 10;
  }
}


.m-col-12 {
  @media (--mobileOnly) {
    @neat-span-columns 12;
  }
}
