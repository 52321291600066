.RichOverlayImage {
  min-height: 400px;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
  }

  @media (--tablet) {
    background-position: center;
    min-height: 720px;
  }

  & .SubTitle {
    margin-bottom: 2rem;

    @media (--tablet) {
      margin-bottom: 4rem;
    }
  }

  & .SlidesNavigation__innerScroll {
    @media (--laptop) {
      max-height: 35vh;
    }
  }

  &.SlidesNavigation__slideContainer {
    background-repeat: no-repeat;

    @media (--mobileAndTablet) {
      background-image: none;
    }
  }
}

.RichOverlayImage__inner {
  @mixin richCampaignWrapper;

  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  z-index: 1;
  position: relative;
}

.RichOverlayImage__title {
  @mixin h2;

  @media (--laptop) {
    margin-block-end: 1.6rem;
  }
}

.RichOverlayImage__body p {
  @mixin p;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.RichOverlayImage__body ul,
.RichOverlayImage__body ol {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.RichOverlayImage__button {
  display: inline-flex;
  min-width: 200px;
  max-width: 100%;
  margin-bottom: var(--spacing-xxxxl);
  border-radius: 2px;

  @media (--laptop) {
    margin-bottom: 0;
    min-width: 260px;
  }
}

.RichOverlayImage .RichOverlayImage__button a {
  @mixin typographyDesktopStrongMedium;

  display: flex;
  justify-content: center;
  letter-spacing: -0.01em;
  padding: var(--spacing-xs);
  width: 100%;
  margin-block-end: 0;
  cursor: pointer;
  transition: box-shadow 0.2s ease-out;

  &:hover {
    @mixin hoverDarken;
  }

  &:focus {
    outline-color: var(--outlineColorSecondary);
  }
}

.RichOverlayImage__content {
  margin-bottom: 1.8rem;
  width: 100%;

  @media (--laptop) {
    margin-bottom: 2.35rem;
  }
}

.RichOverlayImage__image {
  padding-top: 56%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @mixin richCampaignWrapper;

  width: 100%;

  @media (--laptop) {
    display: none;
  }
}

.RichOverlayImage.SlidesNavigation__slideContainer.SlideBackgroundColor--none {
  @media (--mobileAndTablet) {
    padding-bottom: 0;
  }
}
