.InteractiveHeroBackground {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;

    @media (--tablet) {
        width: 100%;
    }
}

.InteractiveHeroBackground__media,
.InteractiveHeroBackground__container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.InteractiveHeroBackground__container {
    display: flex;
    align-items: flex-end;
    height: 75vh;
    min-height: 400px;

    @media (--laptop) {
        height: auto;
        padding-top: min(51.6%, 100vh);
        max-height: 100vh;
    }
}

.InteractiveHeroBackground__picture {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    & picture {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & img {
        width: 100%;
        min-height: 100%;
        height: 75vh;
        object-fit: cover;

        @media (--laptop) {
            height: auto;
        }
    }

    @media (--mobileAndTablet) {
        display: none;
    }
}

.InteractiveHeroBackground__picture--mobile {
    display: none;
    @media (--mobileAndTablet) {
        display: block;
    }
}

.InteractiveHeroBackground__placeholder {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
    filter: blur(1rem);

    @media (--tablet) {
        background-size: cover;
    }
}

.InteractiveHeroBackground__link {
    position: absolute;
    bottom: 0.3rem;
    left: 0;

    @media (--mobileOnly) {
        transform: scale(.8);
    }

    @media (--tablet) {
        bottom: 1rem;
    }

    @media (--laptop) {
        bottom: 1.5rem;
    }
}

.InteractiveHeroBackground__media {
    min-height: 240px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

.InteractiveHeroBackground__headingContainer {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;

    & > .wrapper {
        display: flex;
    }
}

.InteractiveHeroBackground__gradientLight,
.InteractiveHeroBackground__gradientDark {
    z-index: 1;
    width: 100%;
    background-size: cover;
    pointer-events: none;
    height: 100%;
    position: absolute;
}

.InteractiveHeroBackground--wrapper {
    position: relative;
    height: fit-content;
}

.InteractiveHeroBackground__heading {
    position: relative;
    z-index: 1;
    margin: 0;
    width: 100%;
    user-select: none;
    padding: 0 2rem 0 0;

    @media (--tablet) {
        width: var(--ksatHeroTextBarWidthDesktop);
    }

    &::before {
        content: '';
        @mixin absoluteFullSize;
        width: calc(100vw + 60px);
        right: 30px;
        left: -30px;
        background: var(--themeBrandColor_dark);

        @media (--tablet) {
            background: color(var(--themeBrandColor_dark) a(85%));
        }

        @media (--laptop) {
            right: 0;
            left: auto;
        }
    }

    & h1,
    & h2 {
        position: relative;
        color: var(--whiteColor);
        line-height: 1.05;
        z-index: 1;
        text-transform: uppercase;
    }

    & h1 {
        font-size: 24px;
        margin: 2rem 0 1rem;
        font-weight: 500;
        letter-spacing: -1px;

        @media (--tablet) {
            margin: 3rem 0 1rem; 
            font-size: 36px;
        }

        @media (--laptop) {
            @mixin dynamicFontSize 28, 56;
        }
        
        @media (--desktopMedium) {
            font-size: 52px;
        }
    }

    & h2 {
        margin: 1rem 0 1.7rem;
        letter-spacing: -0.5px;
        font-size: 20px;

        @media (--tablet) {
            margin: 1rem 0 2.3rem;
            font-size: 32px;
        }

        @media (--laptop) {
            @mixin dynamicFontSize 24, 34;
        }
    }
}
