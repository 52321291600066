:root {
  --sideOffset: calc(2 * var(--menuBarSize));
  --bannerHeight: 80px;
  --bannerHeight4k: 160px; 
}

.MicrositeProductFeatures {
  height: var(--bannerHeight);
  width: 100%;
  position: absolute;
  z-index: 7;
  bottom: 0;
  left: 0;

  @media (--desktop4k) {
    height: var(--bannerHeight4k);
  }
}

.MicrositeProductFeatures__inner {
  display: flex;
  flex-direction: column;
  height: calc(57vh + 16px);
  padding: 0 16px;
  transition: background-color .3s cubic-bezier(.87, .01, .65, 1);

  @media (--tablet) {
    padding: 0 40px;
  }

  @media (--laptop) {
    padding: 0;
  }

  &.MicrositeProductFeatures__inner--dark {
    &.MicrositeProductFeatures__inner--opened {
      background-color: color(var(--blackColor) a(90%));
    }

    & .MicrositeProductFeatures__button {
      color: var(--whiteColor);
      border-top: 1px solid var(--whiteColor) !important;

      & .MicrositeProductFeatures__buttonInner:before {    
        @mixin arrowIcon;
      }
    }
  }

  &.MicrositeProductFeatures__inner--light {
    &.MicrositeProductFeatures__inner--opened {
      background-color: var(--kog_blue);

      & .MicrositeProductFeatures__button {
        color: var(--whiteColor);
        border-top: 1px solid var(--whiteColor) !important;
  
        & .MicrositeProductFeatures__buttonInner:before {    
          @mixin arrowIcon;
        }
      }
    }

    & .MicrositeProductFeatures__button {
      color: var(--blackColor);
      border-top: 1px solid var(--blackColor) !important;

      & .MicrositeProductFeatures__buttonInner:before {    
        @mixin arrowIcon var(--blackColor);
      }
    }
  }

  & .MicrositeProductFeatures__content {
    color: var(--whiteColor);
  }
}

.MicrositeProductFeatures__button {
  @mixin resetButtonStyles;
  min-height: var(--bannerHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: all .3s ease-in-out;

  @media (--laptop) {
    margin: 0 124px !important;
  }

  @media (--desktop4k) {
    min-height: var(--bannerHeight4k);
  }

  &:focus,
  &:active,
  &:hover {
    outline: none !important;
    border-color: var(--whiteColor) !important;
  }

  & .MicrositeProductFeatures__buttonInner {
    position: relative;
    display: block;
    padding-right: 24px;

    @media (--laptop) {
      padding-right: 48px;
    }

    @media (--desktop4k) {
      @mixin pMicro;
    }

    &:before {
      @mixin arrowIcon;
      position: absolute;
      content: "";
      right: 5px;
      transition: right 0.3s ease-in-out;
      transform: translateY(calc(50% - 4px)) rotate(-90deg);

      @media (--desktop4k) {
        top: calc(50% - 7px);
        transform: rotate(-90deg);
      }
    }
  }

  &.MicrositeProductFeatures__button--opened {
    & .MicrositeProductFeatures__buttonInner:before {
      transform: translateY(calc(50% - 4px)) rotate(90deg);

      @media (--desktop4k) {
        transform: translateY(calc(50% - 14px)) rotate(90deg);
      }
    }
  }
}

.MicrositeProductFeatures__content {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  overflow: auto;
  height: 100%;

  @media (--tablet) {
    flex-direction: row;
    justify-content: center;
    margin: 32px 0;

    & .MicrositeProductFeatures__description {
      margin-right: 32px;
    }

    & .MicrositeProductFeatures__features {
      margin-left: 32px;
    }
  }

  @media (--laptop) {
    margin: 32px 224px 32px;
  }

  & .MicrositeProductFeatures__description,
  & .MicrositeProductFeatures__features {
    & a {
      @mixin linkMicro;
    }

    & p, 
    & li {
      @mixin pMicro;
    }

    @media (--tablet) {
      flex: 0.5;
    }
  }
}
