/* Instruction how to use the buttons mixins (There are also ready to use classes)
In order to use proper styling, you have to add class to your <button> element and apply two mixins:
  - one for defining size and icon existence/position: without icon, icon on the left, icon on the right, icon only; size small/default
  - one for defining appearance/color - all defined in Figma design system */

@define-mixin buttonBasicStyles {
  --transition-duration: 0.2s;

  transition:
    background var(--transition-duration),
    color var(--transition-duration),
    border-color var(--transition-duration);
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 2px;
  display: inline-block;
  height: fit-content;
  width: fit-content;
  min-width: auto;
  box-shadow: none;

  & svg {
    flex-shrink: 0;
    stroke: currentColor;
  }

  &[aria-disabled="true"],
  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:focus-visible {
    outline: 2px solid var(--outlineColorSecondary);
    outline-offset: 1px;
  }
}

@define-mixin buttonSmallSizeBase {
  @mixin resetButtonStylesNoOverride;
  @mixin buttonBasicStyles;
  @mixin typographyDesktopStrongXSmall;

  line-height: 1rem;
  letter-spacing: normal;

  & svg {
    font-size: 1.125rem;
  }
}

@define-mixin buttonDefaultSizeBase {
  @mixin resetButtonStylesNoOverride;
  @mixin buttonBasicStyles;
  @mixin typographyDesktopStrongMedium;

  & svg {
    font-size: 1.5rem;
  }
}

@define-mixin buttonSmallSizeWithIconBase {
  @mixin buttonSmallSizeBase;

  display: flex;
  align-items: center;
  gap: 4px;
}

@define-mixin buttonDefaultSizeWithIconBase {
  @mixin buttonDefaultSizeBase;

  display: flex;
  align-items: center;
  gap: 6px;
}

/* Mixins for external use */

/* Mixins for defining size and icon existence/position */
@define-mixin buttonSmallSize {
  @mixin buttonSmallSizeBase;

  padding: 4px 8px;
}

@define-mixin buttonSmallSizeIconLeft {
  @mixin buttonSmallSizeWithIconBase;

  padding: 3px 14px 3px 8px;
}

@define-mixin buttonSmallSizeIconRight {
  @mixin buttonSmallSizeWithIconBase;

  padding: 3px 8px 3px 14px;
}

@define-mixin buttonSmallSizeIconOnly {
  @mixin buttonSmallSizeBase;

  padding: 3px;
}

@define-mixin buttonDefaultSize {
  @mixin buttonDefaultSizeBase;

  padding: 8px 16px;
}

@define-mixin buttonDefaultSizeIconLeft {
  @mixin buttonDefaultSizeWithIconBase;

  padding: 6px 16px 6px 10px;
}

@define-mixin buttonDefaultSizeIconRight {
  @mixin buttonDefaultSizeWithIconBase;

  padding: 6px 10px 6px 16px;
}

@define-mixin buttonDefaultSizeIconOnly {
  @mixin buttonDefaultSizeBase;

  padding: 6px;
}

/* Mixins for defining button type/color */
@define-mixin buttonPrimaryBlueFill {
  color: var(--base-grey-white);
  background: var(--accent-blue-accent-default);
  border-color: transparent;

  &:hover {
    background: var(--accent-blue-accent-dark);
    border-color: transparent;
  }

  &:active,
  &:focus-visible {
    background: var(--accent-blue-accent-darker);
    border-color: transparent;
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-light-100);
    background: var(--base-grey-medium-100);
    border-color: transparent;
  }
}

@define-mixin buttonPrimaryBlueOutlined {
  color: var(--accent-blue-accent-default);
  border-color: var(--accent-blue-accent-default);

  &:hover {
    color: var(--accent-blue-accent-dark);
    border-color: var(--accent-blue-accent-dark);
  }

  &:active,
  &:focus-visible {
    color: var(--accent-blue-accent-darker);
    border-color: var(--accent-blue-accent-darker);
    background: var(--accent-blue-accent-light);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    border-color: var(--base-grey-medium-100);
  }
}

@define-mixin buttonPrimaryBlueGhost {
  color: var(--accent-blue-accent-default);

  &:hover {
    background: var(--accent-blue-accent-lighter);
  }

  &:active,
  &:focus-visible {
    color: var(--accent-blue-accent-darker);
    background: var(--accent-blue-accent-light);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    background: transparent;
  }
}

@define-mixin buttonPrimaryBlueGhostDarkMode {
  color: var(--base-grey-white);

  &:hover {
    background: var(--accent-blue-accent-dark);
  }

  &:active,
  &:focus-visible {
    background: var(--accent-blue-accent-default);
  }

  &[aria-disabled="true"],
  &:disabled {
    opacity: 0.8;
    color: var(--accent-blue-accent-dark);
    background: transparent;
  }
}

@define-mixin buttonPrimaryTealFill {
  color: var(--base-grey-white);
  background: var(--accent-teal-accent-default);
  border-color: transparent;
  position: relative;

  &::after {
    display: block;
    content: "";
    border: 1px solid transparent;
    border-radius: 2px;
    position: absolute;
    inset: -2px;
    transition: border-color var(--transition-duration);
  }

  &:hover {
    background: var(--accent-teal-accent-dark);
    border-color: transparent;
  }

  &:active,
  &:focus-visible {
    background: var(--accent-teal-accent-darker);
    border-color: transparent;

    &::after {
      border-color: var(--base-grey-white);
    }
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-light-100);
    background: var(--base-grey-medium-100);
    border-color: transparent;
  }
}

@define-mixin buttonPrimaryTealOutlined {
  color: var(--accent-teal-accent-dark);
  border-color: var(--accent-teal-accent-dark);

  &:hover {
    color: var(--accent-teal-accent-default);
    border-color: var(--accent-teal-accent-default);
  }

  &:active,
  &:focus-visible {
    color: var(--accent-teal-accent-darker);
    border-color: var(--accent-teal-accent-darker);
    background: var(--accent-teal-accent-light);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    border-color: var(--base-grey-medium-100);
  }
}

@define-mixin buttonPrimaryTealGhost {
  color: var(--accent-teal-accent-dark);

  &:hover {
    background: var(--accent-teal-accent-lighter);
  }

  &:active,
  &:focus-visible {
    color: var(--accent-teal-accent-darker);
    background: var(--accent-teal-accent-light);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    background: transparent;
  }
}

@define-mixin buttonPrimaryTealGhostDarkMode {
  color: var(--base-grey-white);

  &:hover {
    background: var(--accent-teal-accent-dark);
  }

  &:active,
  &:focus-visible {
    background: var(--accent-teal-accent-default);
  }

  &[aria-disabled="true"],
  &:disabled {
    opacity: 0.3;
    color: var(--accent-teal-accent-dark);
    background: transparent;
  }
}

@define-mixin buttonBaseFill {
  color: var(--base-grey-white);
  background: var(--base-grey-dark-100);
  border-color: transparent;

  &:hover {
    background: var(--base-grey-dark-80);
    border-color: transparent;
  }

  &:active,
  &:focus-visible {
    background: var(--base-grey-black-100);
    border-color: transparent;
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-light-100);
    background: var(--base-grey-medium-100);
    border-color: transparent;
  }
}

@define-mixin buttonBaseOutlined {
  color: var(--base-grey-dark-100);
  border-color: var(--base-grey-dark-100);

  &:hover {
    color: var(--base-grey-dark-80);
    border-color: var(--base-grey-dark-80);
  }

  &:active,
  &:focus-visible {
    color: var(--base-grey-black-100);
    border-color: var(--base-grey-black-100);
    background: var(--base-grey-medium-100);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    border-color: var(--base-grey-medium-100);
  }
}

@define-mixin buttonBaseGhost {
  color: var(--base-grey-dark-100);

  &:hover {
    background: var(--base-grey-light-100);
  }

  &:active,
  &:focus-visible {
    background: var(--base-grey-medium-100);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    background: transparent;
  }
}

@define-mixin buttonBaseFillDarkMode {
  color: var(--base-grey-black-100);
  background: var(--base-grey-white);

  &:hover {
    background: var(--base-grey-light-80);
  }

  &:active,
  &:focus-visible {
    background: var(--base-grey-medium-100);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-black-100);
    background: var(--base-grey-dark-100);
  }
}

@define-mixin buttonBaseOutlinedDarkMode {
  color: var(--base-grey-white);
  border-color: var(--base-grey-white);

  &:hover {
    color: var(--base-grey-light-80);
    border-color: var(--base-grey-light-80);
  }

  &:active,
  &:focus-visible {
    color: var(--base-grey-white);
    border-color: var(--base-grey-white);
    background: var(--base-grey-dark-100);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-dark-100);
    border-color: var(--base-grey-dark-100);
    background: transparent;
  }
}

@define-mixin buttonBaseGhostDarkMode {
  color: var(--base-grey-white);

  &:hover {
    background: var(--base-grey-dark-100);
  }

  &:active,
  &:focus-visible {
    background: var(--base-grey-dark-80);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-dark-100);
    background: transparent;
  }
}

@define-mixin buttonDestructiveFill {
  color: var(--base-grey-white);
  background: var(--error-error-default);
  border-color: transparent;

  &:hover {
    background: var(--error-error-dark);
    border-color: transparent;
  }

  &:active,
  &:focus-visible {
    background: var(--error-error-darker);
    border-color: transparent;
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-light-100);
    background: var(--base-grey-medium-100);
    border-color: transparent;
  }
}

@define-mixin buttonDestructiveOutlined {
  color: var(--error-error-default);
  border-color: var(--error-error-default);

  &:hover {
    color: var(--error-error-dark);
    border-color: var(--error-error-dark);
  }

  &:active,
  &:focus-visible {
    color: var(--error-error-darker);
    border-color: var(--error-error-darker);
    background: var(--error-error-light);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    border-color: var(--base-grey-medium-100);
  }
}

@define-mixin buttonDestructiveGhost {
  color: var(--error-error-default);

  &:hover {
    background: var(--error-error-lighter);
  }

  &:active,
  &:focus-visible {
    color: var(--error-error-dark);
    background: var(--error-error-light);
  }

  &[aria-disabled="true"],
  &:disabled {
    color: var(--base-grey-medium-100);
    background: transparent;
  }
}

@define-mixin buttonFullWidth {
  display: flex;
  justify-content: center;
  width: 100%;
}
