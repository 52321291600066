.ImageWithCaption {
  --image-with-caption-label-max-width: 888px;
  --image-with-caption-medium-label-max-width: 688px;
  --image-with-caption-small-label-max-width: 510px;
  --image-with-caption-max-height: 810px;

  width: 100%;
  max-height: var(--image-with-caption-max-height);
}

.ImageWithCaption--medium,
.ImageWithCaption--small {
  margin-block: var(--spacing-m);

  @media (--tablet) {
    margin-block: var(--spacing-xl);
  }

  @media (--laptop) {
    margin-block: var(--spacing-xxxl);
  }
}

.ImageWithCaption--small {
  max-width: 598px;

  & .ImageWithCaption__label {
    @media (--mobileOnly) {
      padding-inline: 0;
    }

    @media (--tablet) {
      min-width: 300px;
      max-width: var(--image-with-caption-small-label-max-width);
    }
  }
}

.ImageWithCaption--medium {
  @mixin wrapper;

  & .ImageWithCaption__label {
    @media (--mobileOnly) {
      padding-inline: 0;
    }

    @media (--laptop) {
      max-width: var(--image-with-caption-medium-label-max-width);
    }
  }
}

.ImageWithCaption__inner {
  position: relative;

  &,
  & img,
  & picture {
    width: 100%;
    height: 100%;
    max-height: var(--image-with-caption-max-height);
  }
}

.ImageWithCaption__label {
  padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--wrapperPadding);

  &.ImageWithCaption__label--Left {
    left: 0;
    right: unset;
  }

  @media (--tablet) {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: calc(var(--spacing-xs) + var(--spacing-xxxs)) var(--spacing-m) calc(var(--spacing-s) + var(--spacing-xxxs)) calc(var(--spacing-xs) + var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));
    min-width: 400px;
    max-width: var(--image-with-caption-medium-label-max-width);
    overflow: hidden;
  }

  @media (--laptop) {
    min-width: 600px;
    max-width: var(--image-with-caption-label-max-width);
  }

  &.animate {
    opacity: 0;
    transform: translateY(80px);
  }
}

.ImageWithCaption__labelInner {
  @media (--laptop) {
    max-width: 665px;
  }

  & a {
    color: var(--kog_blue_tint_8);

    &:hover {
      text-decoration: underline;
    }
  }

  & p {
    font-size: 0.875rem;
    line-height: 1.125;
    letter-spacing: -0.00875rem;
    margin-block: 0;

    @media (--tablet) {
      @mixin typographyDesktopDefaultSmall;
    }

    @media (--laptop) {
      @mixin typographyDesktopDefaultMedium;
    }
  }
}
