.StoriesCategoryHero {
  color: var(--whiteColor);
  position: relative;
  width: 100%;
  overflow: hidden;

  & svg {
    fill: var(--whiteColor);
  }
}

.StoriesCategoryHero__inner {
  position: absolute;
  width: 110%;
  height: 110%;
  top: -5%;
  left: -5%;
  filter: blur(6px);
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--kog_blue);
    z-index: 0;
  }
}

.StoriesCategoryHero__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  position: relative;

  @media (--tablet) {
    height: 15vw;
    min-height: 200px;
    max-height: 300px;
  }
}

.StoriesCategoryHero__logo {
  display: block;
  width: 15vw;
  max-width: 345px;
  min-width: 95px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;

  @media (--tablet) {
    min-width: 150px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 90%;
  }
}

.StoriesCategoryHero__title {
  float: right;
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-flow: row-reverse;
  justify-content: flex-end;

  @media (--tablet) {
    flex-flow: row;
    justify-content: flex-start;
    width: calc(100% - 20vw - 4rem);
    margin-left: 4rem;
    margin-right: auto;
  }
}

.StoriesCategoryHero__titleHeading {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
  width: calc(100% - 90px);
  flex: 1 0 auto;
  margin: 1.4rem 0;

  @media (--tablet) {
    margin: 2.4rem 0;
  }

  @media (--laptop) {
    margin: 2.8rem 0;
  }

  @media (--tablet) {
    @mixin dynamicFontSize 20, 56;
  }
}

.StoriesCategoryHero__icon {
  width: 45px;
  height: 45px;
  margin-left: 10px;

  @media (--tablet) {
    width: auto;
    height: auto;
    margin-right: 3rem;
    position: static;
  }

  & svg {
    width: 45px;
    height: 45px;
    float: left;
    min-width: 45px;
    max-width: 160px;

    @media (--tablet) {
      @mixin dynamicSize 45, 180;
      min-width: 90px;
      height: auto;
    }
  }
}