/* stylelint-disable declaration-no-important */

/* important is required to overwrite button styles */

.InfoRevealTile {
  height: 100%;
  display: block;

  @mixin focusVisible var(--outlineColorSecondary), 3px, 1px;
}

.InfoRevealTile__content {
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-inline-end: solid 1px var(--kog_blue_50);
    background: linear-gradient(180deg, transparent 67%, rgb(0 0 0 / 0.8) 85%);
    opacity: 1;
    transform: scaleY(1);
    transform-origin: bottom;
    transition: opacity 0.5s;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0 1px 0 0;
    z-index: -1;
    background: linear-gradient(180deg, transparent 18%, rgb(0 0 0 / 0.8) 71%);
    opacity: 0;
    transform: scaleY(0);
    transform-origin: bottom;
    transition:
      opacity 0.5s,
      transform 0.5s;
  }
}

.InfoMosaic__item:last-of-type .InfoRevealTile__content {
  &::before,
  &::after {
    inset: 0;
    border-inline-end: unset;
  }
}

.InfoRevealTile__textWrapper {
  color: var(--whiteColor);
  display: grid;
  grid-template-rows: auto 0fr auto;
  align-content: end;
  gap: var(--spacing-xs);
  height: fit-content;
  align-self: flex-end;
  margin: auto 0 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
  padding: calc(var(--spacing-s) + var(--spacing-xxxs))
    calc(var(--spacing-xxs) + var(--spacing-xxxs));
  transition: grid-template-rows 0.5s;
}

.InfoRevealTile__title {
  @mixin typographyDesktopStrongXLarge;

  max-width: 20rem;
  grid-row: 1/2;
}

.InfoRevealTile__description {
  @mixin typographyDesktopDefaultSmall;
  @mixin lineClamp 5;

  grid-row: 2/3;
  hyphens: auto;
  max-width: 20rem;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(100%);
  transition:
    transform 0.5s,
    opacity 0.5s;

  @media (--laptop) {
    @mixin lineClamp 7;
  }
}

.InfoRevealTile__image {
  position: absolute;
  inset: 0;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s;
  height: 100%;
  width: 100%;
  pointer-events: none;
  filter: brightness(0.85);
}

.InfoRevealTile__iconBtn {
  display: flex !important;
  flex-direction: row-reverse;
  gap: 0.5rem;
  font-size: var(--desktop-strong-small-font-size) !important;
  padding-inline-start: 0 !important;
  align-items: center;
  grid-row: 3/4;

  & svg {
    transition: transform 0.5s;
  }

  &:hover,
  &:active,
  &:focus-visible {
    background: transparent !important;
  }

  @media (--mobileAndTablet) {
    display: none !important;
  }
}

.InfoRevealTile:hover {
  & .InfoRevealTile__content {
    &::after {
      opacity: 1;
      transform: scaleY(1);
    }
  }

  & .InfoRevealTile__textWrapper {
    grid-template-rows: auto 1fr auto;
  }

  & .InfoRevealTile__image {
    opacity: 1;
  }

  & .InfoRevealTile__description {
    opacity: 1;
    transform: translateY(0);
    padding-block-end: 0;
  }

  & .InfoRevealTile__iconBtn svg {
    transform: translateX(var(--spacing-xxs));
  }
}
