.CallOut {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  position: relative;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (--tablet) {
    background-position: center;
    min-height: 720px;
  }

  & .SubTitle {
    margin-bottom: 2rem;

    @media (--tablet) {
      margin-bottom: 4rem;
    }
  }
}

_:-ms-fullscreen, :root .CallOut {
    height: 400px;
}

.CallOut__wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @mixin wrapper;
  position: relative;
  z-index: 1;
}

.CallOut__Title {
  font-size: 26px;
  line-height: 1.14;
  margin: 1.4rem 0;
  max-width: 100%;

  @media (--tablet) {
    font-size: 56px;
    margin: 2.8rem 0;
  }
}

.CallOut__body,
.CallOut__body p {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.56;
  margin: 0 0 1.2rem 0;

  @media (--tablet) {
    font-size: 42px;
    margin: 0 0 2.5rem 0;
  }
}

.CallOut__body {
  overflow: hidden;
  max-width: 100%;
  
  @media (--tablet) {
    overflow: initial;
  }
}

.CallOut__buttons {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-width: 200px;
  max-width: 100%;

  & a {
    background: var(--kog_red);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 1.94;
    letter-spacing: normal;
    color: var(--whiteColor);
    cursor: pointer;
    transition: box-shadow .2s ease-out;
    width: 100%;
    height: 50px;
    min-width: 160px;
    padding: 0 20px;
    font-size: 20px;
    margin-bottom: 30px;
    
    @media (--tablet) {
      height: 65px;
      font-size: 32px;
      min-width: 260px;
    }

    &:hover {
      @mixin hoverDarken;
    }
  }
  
  & a:focus {
    outline-color: var(--outlineColorSecondary);
  }
}