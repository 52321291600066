@define-mixin teaserColorVariant $backgroundColor, $fontColor, $primaryBtnVariant {
  & .Teaser__details {
    color: $fontColor;
    background-color: $backgroundColor;
  }

  & .Teaser__button {
    @mixin $(primaryBtnVariant);
  }
}

.Teaser {
  &.Teaser--backgroundColorDefault,
  &.Teaser--backgroundColorGreyTwo {
    @mixin teaserColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color), buttonPrimaryThemeColorGreyTwo;
  }

  &.Teaser--backgroundColorGreenFour {
    @mixin teaserColorVariant var(--theme-color-green-bg-color), var(--theme-color-green-text-color),
      buttonPrimaryThemeColorGreenFour;
  }

  &.Teaser--backgroundColorBlueFive {
    @mixin teaserColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color),
      buttonPrimaryThemeColorBlueFive;
  }

  &.Teaser--backgroundColorTealTwo {
    @mixin teaserColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color), buttonPrimaryThemeColorTealTwo;
  }

  &.Teaser--backgroundColorSandFour {
    @mixin teaserColorVariant var(--theme-color-brown-bg-color), var(--theme-color-brown-text-color),
      buttonPrimaryThemeColorSandFour;
  }

  &.Teaser--backgroundColorTealFive {
    @mixin teaserColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color), buttonPrimaryThemeColorTealFive;
  }

  &.Teaser--backgroundColorYellowFour {
    @mixin teaserColorVariant var(--theme-color-yellow-bg-color),
      var(--theme-color-yellow-text-color), buttonPrimaryThemeColorYellowFour;
  }

  &.Teaser--backgroundColorGreenTwo {
    @mixin teaserColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color), buttonPrimaryThemeColorGreenTwo;
  }

  &.Teaser--backgroundColorBlueTwo {
    @mixin teaserColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color), buttonPrimaryThemeColorBlueTwo;
  }

  &.Teaser--backgroundColorSandTwo {
    @mixin teaserColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color), buttonPrimaryThemeColorSandTwo;
  }

  &.Teaser--backgroundColorForestTwo {
    @mixin teaserColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color), buttonPrimaryThemeColorForestTwo;
  }

  &.Teaser--backgroundColorGreenOne {
    @mixin teaserColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color), buttonPrimaryThemeColorGreenOne;
  }

  &.Teaser--backgroundColorGreenFive {
    @mixin teaserColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color), buttonPrimaryThemeColorGreenFive;
  }

  &.Teaser--backgroundColorForestFive {
    @mixin teaserColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color), buttonPrimaryThemeColorForestFive;
  }

  &.Teaser--backgroundColorBlueThree {
    @mixin teaserColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color), buttonPrimaryThemeColorBlueThree;
  }

  &.Teaser--backgroundColorYellowTwo {
    @mixin teaserColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color), buttonPrimaryThemeColorYellowTwo;
  }

  &.Teaser--backgroundColorYellowFive {
    @mixin teaserColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color), buttonPrimaryThemeColorYellowFive;
  }

  &.Teaser--backgroundColorRedFour {
    @mixin teaserColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color), buttonPrimaryThemeColorRedFour;
  }

  &.Teaser--backgroundColorGreyFive {
    @mixin teaserColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color), buttonPrimaryThemeColorGreyFive;
  }
}
