.SlideBackgroundColor--Default {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--whiteColor) a(85%));
    }
  }
}

.SlideBackgroundColor--BlueFive {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_blue_tint_7) a(85%));
    }
  }
}

.SlideBackgroundColor--SandFour {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_bronze_tint_1) a(85%));
    }
  }
}

.SlideBackgroundColor--TealTwo {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_aqua_tint_6) a(85%));
    }
  }
}

.SlideBackgroundColor--TealFive {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_aqua_tint_1) a(85%));
    }
  }
}

.SlideBackgroundColor--YellowFour {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_yellow) a(85%));
    }
  }
}

.SlideBackgroundColor--GreenTwo {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_green_tint_3) a(85%));
    }
  }
}

.SlideBackgroundColor--BlueTwo {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_blue_tint_1) a(85%));
    }
  }
}

.SlideBackgroundColor--SandTwo {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_bronze_tint_6) a(85%));
    }
  }
}

.SlideBackgroundColor--ForestTwo {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_forest_tint_3) a(85%));
    }
  }
}

.SlideBackgroundColor--GreenFour {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_green_tint_2) a(85%));
    }
  }
}

.SlideBackgroundColor--White {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--whiteColor) a(85%));
    }
  }
}

.SlideBackgroundColor--Black {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--textColor) a(85%));
    }
  }
}

.SlideBackgroundColor--GreenOne {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_green_tint_4) a(85%));
    }
  }
}

.SlideBackgroundColor--GreenFive {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_green_tint_1) a(85%));
    }
  }
}

.SlideBackgroundColor--ForestFive {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_forest_tint_1) a(85%));
    }
  }
}

.SlideBackgroundColor--BlueThree {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_blue_tint_9) a(85%));
    }
  }
}

.SlideBackgroundColor--YellowTwo {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_yellow_tint_3) a(85%));
    }
  }
}

.SlideBackgroundColor--YellowFive {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_yellow_tint_1) a(85%));
    }
  }
}

.SlideBackgroundColor--RedFour {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--kog_red_tint_1) a(85%));
    }
  }
}

.SlideBackgroundColor--GreyTwo {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--grayColor_tint_6) a(85%));
    }
  }
}

.SlideBackgroundColor--GreyFive {
  &.RichCallOut--overlay {
    & .RichCallOut__wrapper {
      background-color: color(var(--grayColor_tint_2) a(85%));
    }
  }
}
