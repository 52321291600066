.RichCallToAction__linksContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column nowrap;
  margin: 0;

  @media (--tablet) {
    margin: 0 auto;
  }
  
  & .RichCallToAction__link + .RichCallToAction__link {
    margin-top: 0.5rem;

    @media (--tablet) {
      margin-top: 1rem;
    }
  }
}