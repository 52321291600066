.Link {
  @mixin resetButtonStylesNoOverride;
  @mixin typographyDesktopStrongSmall;

  display: flex;
  align-items: center;
  color: var(--black-800);
  gap: var(--spacing-xxxs);
  cursor: pointer;
  transition: color var(--transition-duration);

  @media (--reduced-motion) {
    transition: none;
  }

  & > svg {
    font-size: var(--spacing-s);
  }

  &:hover {
    color: var(--accent-blue-accent-dark);
  }

  &:active {
    color: var(--black-700);
  }

  @mixin focusVisible var(--outlineColorSecondary);

  &.Link--darkMode {
    color: var(--white-0);

    &:hover {
      color: var(--blue-200);
    }

    &:active {
      color: var(--blue-100);
    }
  }
}
