.InteractiveHeroBackground--editMode.InteractiveHeroBackground {
    height: 400px;

    @media(--tablet) {
        height: 600px;
    }

    @media(--laptop) {
        height: 700px;
    }

    & .InteractiveHeroBackground__container {
        height: 400px;
        min-height: auto;

        @media(--tablet) {
            height: 600px;
        }

        @media(--laptop) {
            height: 700px;
        }
    }
}