.MicrositeHotSpot__spotOuter {
  display: flex;
  width: 490px;
  height: 200px;

  @media (--desktop4k) {
    width: 1050px;
    height: 420px;
  }

  &.MicrositeHotSpot__spotOuter--light {
    & .MicrositeHotSpot__spot {
      @mixin backgroundGradient color(var(--whiteColor) a(90%) transparent);
      border-color: var(--blackColor) !important;
    }

    & .MicrositeHotSpot__content {
      color: var(--blackColor);
    }

    & .MicrositeHotSpot__link .MicrositeHotSpot__linkArrow {
      @mixin arrowIcon var(--blackColor);
    }
  }

  &.MicrositeHotSpot__spotOuter--dark {
    & .MicrositeHotSpot__spot {
      @mixin backgroundGradient;
      border-color: var(--whiteColor) !important;
    }

    & .MicrositeHotSpot__content {
      color: var(--whiteColor);
    }

    & .MicrositeHotSpot__link .MicrositeHotSpot__linkArrow {
      @mixin arrowIcon;
    }
  }
}

.MicrositeHotSpot__spot {
  @mixin resetButtonStyles;
  @mixin backgroundGradient;
  border-radius: 5px;
  border-width: 1px !important;
  border-style: solid !important;
  flex: 1;
  display: none;
  opacity: 0;
  cursor: pointer;

  & .MicrositeHotSpot__image {
    width: 200px;
    height: 195px;
    object-fit: cover;
    z-index: initial;

    @media (--desktop4k) {
      width: 440px;
      height: 418px; 
    }
  }

  & .MicrositeHotSpot__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    padding: 16px;

    & h4 {
      margin: 0;
    }
  }

  & .MicrositeHotSpot__heading {
    @media (--desktop4k) {
      font-size: 40px;
    }
  }

  & .MicrositeHotSpot__link {
    position: relative;
    display: block;
    margin-right: auto;

    @media (--desktop4k) {
      font-size: 24px;
    }

    & .MicrositeHotSpot__linkArrow {
      position: absolute;
      right: -16px;
      top: 4px;

      @media (--desktop4k) {
        top: 2px;
        right: -24px;
      }
    }
  }

  & .MicrositeHotSpot__image img {
    width: 100%;
    height: 100%;
  }
}
