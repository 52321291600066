.CounterList--extended {
  &::before {
    content: none;
  }

  & .CounterList__headingsWrapper {
    gap: var(--spacing-xs);
    padding: var(--spacing-s) 0 var(--spacing-xs);

    @media (--tablet) {
      padding: var(--spacing-xl) 0 var(--spacing-m);
    }
  }

  & .CounterList__title {
    @mixin typographyMobileDisplayLarge;

    @media (--tablet) {
      @mixin typographyDesktopStrongXXLarge;
    }
  }

  & .CounterList__intro {
    &,
    & p {
      @mixin typographyMobileDefaultLarge;

      @media (--tablet) {
        @mixin typographyDesktopDefaultXLarge;
      }
    }
  }

  & .Counter {
    flex-flow: column;
    text-align: initial;
    align-items: initial;
    padding: var(--spacing-xs) 0;
    gap: var(--spacing-xs);
    border-bottom: 1px solid var(--base-grey-medium-100);

    @media (--tablet) {
      border-bottom: 0;
    }

    &::before {
      height: 100%;
    }
  }

  &.CounterList--valuePositionTop {
    & .Counter {
      flex-flow: column-reverse;
    }
  }

  &.CounterList--valueSizeMedium {
    & .Counter__value {
      @mixin typographyMobileStrongXXLarge;

      @media (--laptop) {
        @mixin typographyDesktopStrongXXLarge;
      }
    }
  }

  &.CounterList--valueSizeXLarge {
    & .Counter__value {
      @mixin typographyMobileDisplayXLarge;

      @media (--laptop) {
        @mixin typographyDesktopDisplayXLarge;
      }
    }
  }

  &.CounterList--neutral {
    background-color: var(--base-grey-light-80);

    & .Counter {
      &::before {
        background-color: var(--base-grey-medium-100);
      }
    }

    & .CounterList__title,
    & .CounterList__intro {
      color: var(--base-grey-black-100);
    }

    & .Counter__text {
      &.Counter__text--visible {
        color: var(--base-grey-black-100);
      }
    }

    & .Counter__value {
      color: var(--base-grey-black-100);
    }
  }

  &.CounterList--blue {
    background-color: var(--blue-50);

    & .Counter {
      &::before {
        background-color: var(--blue-500);
      }
    }

    & .CounterList__title,
    & .CounterList__intro {
      color: var(--blue-900);
    }

    & .Counter__text {
      &.Counter__text--visible {
        color: var(--blue-900);
      }
    }

    & .Counter__value {
      color: var(--blue-900);
    }
  }

  &.CounterList--grey {
    background-color: var(--base-grey-white);

    & .Counter {
      &::before {
        background-color: var(--blue-500);
      }
    }

    & .CounterList__title,
    & .CounterList__intro {
      color: var(--blue-900);
    }

    & .Counter__text {
      &.Counter__text--visible {
        color: var(--blue-900);
      }
    }

    & .Counter__value {
      color: var(--blue-900);
    }
  }

  &.CounterList--sand {
    background-color: var(--sand-50);

    & .Counter {
      &::before {
        background-color: var(--sand-500);
      }
    }

    & .CounterList__title,
    & .CounterList__intro {
      color: var(--sand-950);
    }

    & .Counter__text {
      &.Counter__text--visible {
        color: var(--sand-950);
      }
    }

    & .Counter__value {
      color: var(--sand-950);
    }
  }

  & .CounterList__itemsList {
    gap: 0;

    & li:last-child:not(:first-child) .Counter::before {
      @media (--tablet) {
        display: block;
      }
    }

    & li:last-child .Counter {
      @media (--tablet) {
        padding-right: var(--spacing-xs);
      }
    }
  }
}
