.ContactTile {
  container-type: inline-size;
  container-name: tile;
}

.ContactTile__container {
  --social-column: 24px;

  display: grid;
  grid-template: repeat(4, auto) / minmax(auto, 281px) var(--social-column);
  grid-template-areas:
    "photo socials"
    "name socials"
    "position socials"
    "timePlace socials"
    "readMore readMore";
  gap: 0 20px;
  margin-block-end: var(--spacing-xl);

  @media (--laptop) {
    grid-template: repeat(4, auto) / minmax(auto, 244px) var(--social-column);
    grid-template-areas:
      "photo socials"
      "name socials"
      "position socials"
      "timePlace socials"
      "readMore readMore";
  }

  picture {
    grid-area: photo;

    img {
      aspect-ratio: 1/1;
    }
  }
}

.ContactTile__name {
  grid-area: name;
  @mixin typographyMobileStrongXXLarge;

  margin-block-start: var(--spacing-s);
  @media (--laptop) {
    @mixin typographyDesktopStrongXLarge;

    margin-block-start: var(--spacing-s);
  }
}

.ContactTile__position {
  position: relative;
  grid-area: position;
  @mixin typographyMobileDefaultLarge;

  padding-block-end: var(--spacing-s);
  margin-block-end: var(--spacing-s);

  @media (--laptop) {
    @mixin typographyDesktopDefaultLarge;

    padding-block-end: var(--spacing-s);
    margin-block-end: var(--spacing-s);
  }

  /* TODO Uncomment when doing speaker related agenda item details */

  /* &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 40px;
      background: var(--blackColor);
    }
  } */
}

.ContactTile__socials {
  grid-area: socials;
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.ContactTile__social {
  font-size: 1.5rem;

  svg path {
    fill: var(--kog_blue_tint_9);
  }
}

.ContactTile__readMore {
  @mixin typographyDesktopStrongSmall;

  grid-area: readMore;
  display: flex;
  align-items: center;
  padding-block-start: var(--spacing-s);
  gap: var(--spacing-xxxs);

  svg {
    font-size: 1.5rem;
  }
}

@container tile (min-width: 480px) {
  .ContactTile__container {
    grid-template: repeat(4, auto) / minmax(auto, 233px) 1fr;
    grid-template-areas:
      "photo name"
      "photo position"
      "photo timePlace"
      "socials readMore";
  }

  .ContactTile__name {
    margin-block-start: 0;

    @media (--laptop) {
      margin-block-start: 0;
    }
  }

  .ContactTile__socials {
    flex-direction: row;
    align-items: flex-end;
    padding-block-end: 0;
  }
}

@container tile (min-width: 992px) {
  .ContactTile__container {
    grid-template: repeat(4, auto) / minmax(auto, 510px) 2fr 1fr;
    grid-template-areas:
      "photo name socials"
      "photo position socials"
      "photo timePlace socials"
      "photo readMore socials";

    picture {
      img {
        aspect-ratio: 85/61;
      }
    }
  }

  .ContactTile__name,
  .ContactTile__socials {
    margin-block-start: var(--spacing-l);

    @media (--laptop) {
      margin-block-start: var(--spacing-l);
    }
  }

  .ContactTile__socials {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .ContactTile__readMore {
    margin: var(--spacing-l) 0;

    @media (--laptop) {
      margin: var(--spacing-l) 0;
    }
  }
}
