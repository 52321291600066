@define-mixin downloadResource $color {
  
  &::after {
    content: '';
    width: 26px;
    height: 26px;
    left: 23px;
    position: absolute;
    display: block;
    background: svg-load('Icons/DownloadResource.svg', fill: $color) no-repeat center;
    background-size: 100%;
  }

  &:hover {
    text-decoration: underline;
  }
}

