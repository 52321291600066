.Hero--withHeading {
  & .Hero__headingContainer {
    color: var(--blackColor);
  }

  & .Hero__heading {
    @mixin wrapper;

    display: flex;
    flex-wrap: wrap;
    
    @media (--tablet) {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
    }

    & h1 {
      @mixin h3Desktop;

      display: flex;
      align-items: flex-end;
      position: relative;
      z-index: 8;
      margin-block: var(--spacing-xs) 0;
      width: 100%;
      padding: 0;

      @media (--mobileSmall) {
        font-size: clamp(var(--h3-desktop-font-size), calc(6vw + 1rem), var(--h1-desktop-font-size));
        line-height: var(--h1-desktop-line-height);
      }

      @media (--tablet) {
        margin-block: var(--spacing-xxxxl) 0;
      }

      @media (--laptop) {
        @mixin h1Desktop;
      }
    }

    & .Hero__titleText {
      @mixin h6Desktop;

      display: none;
      position: relative;
      z-index: 8;
      margin-block: 0 var(--spacing-xxs);

      @media (--laptop) {
        @mixin h5Desktop;

        margin-block: 0 var(--spacing-xxs);
      }
    }
  }

  & .Hero__link {
    @mixin arrowLinkRightGlyph;
    @mixin focusVisible var(--outlineColor), 1px;

    color: inherit;
  }
}
