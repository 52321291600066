@define-mixin iconPhone $color {
  &::before {
    content: '';
    margin-right: 6px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: inline-flex;
    background-size: contain;
    background: svg-load('Icons/Phone1.svg', fill: $color) no-repeat;
  }
}