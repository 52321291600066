.ImageWithCaption {
  @media (--tablet) {
    & .ImageWithCaption__label--BlueFive {
      @mixin CommonBlockColors var(--theme-color-blue-bg-color),
        var(--theme-color-blue-text-color-lighter);
    }

    & .ImageWithCaption__label--BlueTwo {
      @mixin CommonBlockColors var(--theme-color-blue-dark-bg-color-lighter),
        var(--theme-color-blue-dark-text-color);
    }

    & .ImageWithCaption__label--Default,
    & .ImageWithCaption__label--SandFour {
      @mixin CommonBlockColors var(--theme-color-brown-bg-color-lighter),
        var(--theme-color-brown-text-color);
    }

    & .ImageWithCaption__label--GreenFour {
      @mixin CommonBlockColors var(--theme-color-green-bg-color),
        var(--theme-color-green-text-color-darker);
    }

    & .ImageWithCaption__label--YellowFour {
      @mixin CommonBlockColors var(--theme-color-yellow-bg-color),
        var(--theme-color-yellow-text-color-lighter);
    }

    & .ImageWithCaption__label--TealFive {
      @mixin CommonBlockColors var(--theme-color-turquoise-bg-color),
        var(--theme-color-turquoise-text-color);
    }

    & .ImageWithCaption__label--TealTwo {
      @mixin CommonBlockColors var(--theme-color-turquoise-dark-bg-color),
        var(--theme-color-turquoise-dark-text-color);
    }

    & .ImageWithCaption__label--GreenTwo {
      @mixin CommonBlockColors var(--theme-color-green-dark-bg-color),
        var(--theme-color-green-dark-text-color-darker);
    }

    & .ImageWithCaption__label--SandTwo {
      @mixin CommonBlockColors var(--theme-color-sand-dark-bg-color),
        var(--theme-color-sand-dark-text-color);
    }

    & .ImageWithCaption__label--ForestTwo {
      @mixin CommonBlockColors var(--theme-color-forest-dark-bg-color),
        var(--theme-color-forest-dark-text-color);
    }

    & .ImageWithCaption__label--White {
      @mixin CommonBlockColors var(--whiteColor), var(--base-grey-black-100);
    }

    & .ImageWithCaption__label--Black {
      @mixin CommonBlockColors #000000, var(--whiteColor);
    }

    & .ImageWithCaption__label--GreenOne {
      @mixin CommonBlockColors var(--theme-color-green-one-bg-color),
        var(--theme-color-green-one-text-color);
    }

    & .ImageWithCaption__label--GreenFive {
      @mixin CommonBlockColors var(--theme-color-green-five-bg-color),
        var(--theme-color-green-five-text-color);
    }

    & .ImageWithCaption__label--ForestFive {
      @mixin CommonBlockColors var(--theme-color-forest-five-bg-color),
        var(--theme-color-forest-five-text-color);
    }

    & .ImageWithCaption__label--BlueThree {
      @mixin CommonBlockColors var(--theme-color-blue-three-bg-color),
        var(--theme-color-blue-three-text-color);
    }

    & .ImageWithCaption__label--YellowTwo {
      @mixin CommonBlockColors var(--theme-color-yellow-two-bg-color),
        var(--theme-color-yellow-two-text-color);
    }

    & .ImageWithCaption__label--YellowFive {
      @mixin CommonBlockColors var(--theme-color-yellow-five-bg-color),
        var(--theme-color-yellow-five-text-color);
    }

    & .ImageWithCaption__label--RedFour {
      @mixin CommonBlockColors var(--theme-color-red-four-bg-color),
        var(--theme-color-red-four-text-color);
    }

    & .ImageWithCaption__label--GreyTwo {
      @mixin CommonBlockColors var(--theme-color-grey-two-bg-color),
        var(--theme-color-grey-two-text-color);
    }

    & .ImageWithCaption__label--GreyFive {
      @mixin CommonBlockColors var(--theme-color-grey-five-bg-color),
        var(--theme-color-grey-five-text-color);
    }
  }
}
