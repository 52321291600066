.LatestNews {
  clear: both;
}

.LatestNews__title {
  margin: 0 0 var(--spacing-s);
  
  @media (--tablet) {
    margin: 0 0 var(--spacing-l);
  }
}

.LatestNews .ContentCard a,
.LatestNews .ContentCard a:visited,
.LatestNews .LatestNews__readMore .Btn--readMore {
  color: var(--textColor);
  text-decoration: none;
  
  &:hover {
    color: var(--textColor);
    text-decoration: none;
  }
}

.LatestNews .ContentCard--featured a:focus {
  outline-color: var(--outlineColorSecondary); 
}

.LatestNews__readMore {
  display: flex;
  justify-content: center;
}

.LatestNews__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin:0;
  padding: 0;
  
  @media (--tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  & > li {
    width: 100%;
    
    @media (--tablet) {
      max-width: 48%;
    }
    
    @media (--laptop) {
      max-width: 300px;
    }
    
    @media (--laptopMedium) {
      max-width: 320px;
    }
  }
}