.ContentList--emphasized {
  position: relative;
  padding: var(--spacing-xxxl) 0;
  overflow: hidden;
  color: var(--textColor);
  
  @media(--tablet) {
    padding: var(--spacing-xxxxxl) 0;
  }

  &.ContentList--dark {
    color: var(--whiteColor);

    & .ContentList__Background::before {
      background-color: var(--kog_blue);
    }
    
    & a:focus {
      outline-color: var(--outlineColorSecondary);                                        ;
    }
    
    & .ContentCard__arrow {
      @mixin linkWithArrowSmall var(--whiteColor);
    }
  }
  
  & .Section__title {
    margin-bottom: var(--spacing-xs);
    
    @media (--tablet) {
      margin-bottom: var(--spacing-l);
    }
  }
  
  & .ContentCard {
    margin-bottom: var(--spacing-xs);
    
    @media (--tablet) {
      margin-bottom: var(--spacing-m);
    }
  }
  
  & .LinkBlock__icon {
    @media (--laptop) {
      margin: 4rem 0 1.5rem;
    }
  }
  
  & .ContentAreaSection__item {
    padding: 0;
    max-width: 100%;
  }
}

.ContentList__Background {
  background-size: cover;
  display: block;
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  filter: blur(6px);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--whiteColor);
    opacity: 0.8;
  }
}
