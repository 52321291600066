@define-mixin storyFactsListColorVariant $backgroundColor, $fontColor {
  & .StoryFactsList {
    color: $fontColor;

    & .StoryFactsList__background {
      &::before {
        background-color: $backgroundColor;
      }
    }

    & .StoryFact__button {
      color: $fontColor;
    }

    & .StoryFactsList__header {
      & svg {
        fill: $fontColor;
      }
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin storyFactsListColorVariant var(--theme-color-beige-bg-color), var(--theme-color-beige-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin storyFactsListColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin storyFactsListColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin storyFactsListColorVariant var(--theme-color-brown-bg-color), var(--theme-color-brown-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin storyFactsListColorVariant var(--theme-color-green-bg-color), var(--theme-color-green-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin storyFactsListColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color);
}

.ContainerWithBackground--Pink {
  @mixin storyFactsListColorVariant var(--theme-color-pink-bg-color), var(--theme-color-pink-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin storyFactsListColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin storyFactsListColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin storyFactsListColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin storyFactsListColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin storyFactsListColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin storyFactsListColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin storyFactsListColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin storyFactsListColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin storyFactsListColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin storyFactsListColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin storyFactsListColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin storyFactsListColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin storyFactsListColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin storyFactsListColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color);
}
