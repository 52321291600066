html {
  font-size: var(--fontSizeBase);
}

body {
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: var(--fontFamilyPrimary);
  color: var(--textColor);
  font-size: var(--bodyFontSize);
}

ol {
  list-style-type: decimal;
}

i {
  font-style: italic;
}