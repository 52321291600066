.TextWithColorBlock--Default,
.TextWithColorBlock--Yellow {
  color: var(--kog_yellow);
}

.TextWithColorBlock--Green {
  color: var(--kog_green_tint_2);
}

.TextWithColorBlock--Blue {
  color: var(--themeBrandColor_dark_tint_7);
}

.TextWithColorBlock--TurquoiseDark {
  color: var(--kog_aqua_tint_6);
}

.TextWithColorBlock--Brown {
  color: var(--themeSecondaryColor_tint_1);
}

.TextWithColorBlock--Turquoise {
  color: var(--kog_aqua_tint_1);
}

.TextWithColorBlock--GreenDark {
  color: var(--kog_green_tint_3);
}

.TextWithColorBlock--BlueDark {
  color: var(--kog_blue_tint_1);
}

.TextWithColorBlock--SandDark {
  color: var(--kog_bronze_tint_6);
}

.TextWithColorBlock--ForestDark {
  color: var(--kog_forest_tint_3);
}
