.IntroductionBlock__title {
  font-size: 28px;
  line-height: 42px;
  font-weight: 600;
  color: var(--blackColor);
  
  @media (--tablet) {
    font-size: 33px;
    line-height: 44px;
  }

  &.IntroductionBlock__title--red {
    color: var(--kog_red);
  }
}

.IntroductionBlock__body {
  @mixin h7;
}