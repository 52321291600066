.ButtonDropdown {
  & svg {
    transition: transform 250ms;

    @media (--reduced-motion) {
      transition: none;
    }
  }
}

.ButtonDropdown--opened {
  & svg {
    transform: rotate(180deg);
  }
}
