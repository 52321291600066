/* stylelint-disable selector-type-no-unknown */
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-disable no-descending-specificity */
.InfoPanel {
  background: var(--brandColor_dark_tint_6);
  padding: 15px;
  word-break: break-word;
}

.InfoPanel__richText {
  & > div {
    display: flex;
    flex-flow: column nowrap;
  }

  & h4:first-child {
    margin-top: 0;
  }
  
  & p,
  & a {
    margin-bottom: 1rem;
  }
  
  & h4 {
    margin-bottom: 0;
  }
}

_:-ms-fullscreen,
:root .InfoPanel__richText a {
  word-break: break-all;
}
