.SlidesNavigation--editMode,
.SlidesNavigation--previewMode {
  & .SlidesNavigation__container {
    @media (--laptop) {
      height: 100%;
    }
  }

  & .SlidesNavigation__slide {
    position: relative;

    @media (--laptop) {
      height: 130%;
    }

    &.SlidesNavigation__slide--current,
    &.SlidesNavigation__slide--up,
    &.SlidesNavigation__slide--down {
      @media (--laptop) {
        transform: none;
      }
    }

    &.SlidesNavigation__slide--lazy {
      @media (--laptop) {
        display: block;
        transform: none;
      }
    }

    & .SlidesNavigation__slideContainer {
      @media (--laptop) {
        padding: 3rem 0;
      }
    }
  }
}
