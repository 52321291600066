.ProductsListing {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.ProductsListing__header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.ProductsListing__title {
  @mixin typographyMobileDisplayLarge;

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.ProductsListing__description {
  @mixin typographyMobileDefaultXLarge;

  @media (--tablet) {
    @mixin typographyDesktopDefaultXLarge;
  }
}

.ProductsListing__topBar {
  display: flex;
  justify-content: space-between;
}

.ProductsListing__topBarColumn {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

.ButtonOutlined.ProductsListing__toggleFiltersButton {
  display: flex;
  gap: 6px;
  align-items: center;

  &:hover {
    & .ProductsListing__filtersCount {
      background-color: var(--base-grey-dark-80);
    }
  }

  &:active,
  &:focus {
    & .ProductsListing__filtersCount {
      background-color: var(--base-grey-black-100);
    }
  }
}
