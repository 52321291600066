@define-mixin contactBlockIcon $icon {
  content: "";
  width: 25px;
  height: 25px;
  display: block;
  margin-right: 8px;
  border-radius: 4px;
  transform: scale(0.8);
  transform-origin: left;
  background: svg-load($icon, fill: inherit);
}

.RichContentList {
  & .ContactCard {
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    min-height: 355px;
    min-width: 200px;
    width: 100%;
    flex-grow: 1;

    @media (--tablet) {
      max-width: 360px;
    }

    @media (--laptop) {
      transform: scale(0.9);
      transform-origin: top;
    }

    @media (--desktop) {
      transform: none;
    }

    & .ContactCard__inner {
      display: flex;
      flex-flow: column;
      width: 100%;
      z-index: 1;
      padding: 180px 25px 30px;
      position: relative;

      @media (--tablet) {
        padding: 200px 25px 40px;
      }

      @media (--desktopLarge) {
        padding: 265px 25px 40px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 85px;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;

        @media (--tablet) {
          top: 100px;
        }

        @media (--desktopLarge) {
          top: 120px;
        }
      }
    }

    & a {
      display: flex;
      flex-flow: row wrap;
      color: inherit;

      &:focus {
        outline-color: var(--outlineColorSecondary);
      }
    }

    & .ContactCard__link {
      display: none;
    }

    &.RequireMargins {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & .ContactCard__picture {
    position: absolute;
    width: 148px;
    height: 148px;
    overflow: hidden;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;

    @media (--tablet) {
      width: 160px;
      height: 160px;
      top: 20px;
    }

    @media (--desktopLarge) {
      width: 200px;
      height: 200px;
      top: 40px;
    }

    & picture {
      display: block;
      width: 148px;
      height: 148px;

      @media (--tablet) {
        width: 160px;
        height: 160px;
      }

      @media (--desktopLarge) {
        width: 200px;
        height: 200px;
      }
    }

    & img {
      min-height: 100%;
      width: 100%;
    }
  }

  & .ContactCard__name {
    @mixin h8Desktop;

    margin-bottom: 5px;
    text-transform: uppercase;
    position: relative;
    font-weight: 500;

    @media (--tablet) {
      @mixin h8Desktop;

      margin-bottom: 12px;
      text-transform: uppercase;
      position: relative;
      font-weight: 500;
    }

    @media (--desktopLarge) {
      @mixin h4Desktop;

      margin-bottom: 12px;
      text-transform: uppercase;
      position: relative;
      font-weight: 500;
    }

    & .ContactCard__title {
      @mixin h8;

      margin-bottom: 16px;
      width: 100%;
      min-height: initial;
      position: relative;

      @media (--tablet) {
        margin-bottom: 16px;
        width: 90%;
        min-height: 40px;
      }
    }

    & .ContactCard__address {
      @mixin h8;

      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      line-height: 1;

      @media (--tablet) {
        margin-bottom: 12px;
        line-height: 1;
      }

      &::before {
        @mixin contactBlockIcon "Icons/EmailEnvelope.svg";
      }
    }

    & .ContactCard__phone {
      position: relative;
      display: flex;
      align-items: center;
      @mixin h8;

      &::before {
        @mixin contactBlockIcon "Icons/Phone1.svg";
      }
    }

    & .ContactCard__readMore {
      margin-top: 2rem;
    }

    & .ContactCard__readMoreLink {
      position: relative;
      padding-left: 15px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 1.25rem;
      max-width: 65vw;
      line-height: 1.2;
      color: var(--blackColor);

      &::before {
        content: "";
        position: absolute;
        top: calc(50% - 7px);
        left: -2px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: svg-load("Icons/ArrowRight.svg", fill: var(--blackColor)) no-repeat;
        background-size: contain;
      }
    }

    & .ContactCard__info {
      flex: 1 0 auto;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }
  }
}
