body {
  color: var(--textColor);
  font-weight: 400;
  line-height: 1.5;
  text-underline-offset: 4px;
  text-decoration-skip-ink: none;
}

h1, h2, h3, h4, h5, h6 {
  &.mono {
    font-family: var(--FamilyPressuraMono);
  }
}

h1 {
  @mixin h1;
  
  &.withHero {
    margin: 0 0 var(--spacing-xl) 0;
  }
}

h2 {
  @mixin h2;
}

h3 {
  @mixin h3;
}

h4 {
  @mixin h4;
}

h5 {
  @mixin h5;
}

h6 {
  @mixin h6;
}

p {
  @mixin p;
}

ul, ol {
  @mixin p;
  display: block;
}

a {
  color: var(--textColor);
  text-decoration: none;
  word-break: break-word;
}