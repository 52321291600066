.ContentList {
  &.ContentList--backgroundColorGreyTwo {
    background: var(--theme-color-grey-two-bg-color);
  }

  &.ContentList--backgroundColorGreenFour {
    background: var(--theme-color-green-bg-color);
  }

  &.ContentList--backgroundColorBlueFive {
    background: var(--theme-color-blue-bg-color);
  }

  &.ContentList--backgroundColorTealTwo {
    background: var(--theme-color-turquoise-dark-bg-color);
  }

  &.ContentList--backgroundColorSandFour {
    background: var(--theme-color-brown-bg-color);
  }

  &.ContentList--backgroundColorTealFive {
    background: var(--theme-color-turquoise-bg-color);
  }

  &.ContentList--backgroundColorYellowFour {
    background: var(--theme-color-yellow-bg-color);
  }

  &.ContentList--backgroundColorGreenTwo {
    background: var(--theme-color-green-dark-bg-color);
  }

  &.ContentList--backgroundColorBlueTwo {
    background: var(--theme-color-blue-dark-bg-color);
  }

  &.ContentList--backgroundColorSandTwo {
    background: var(--theme-color-sand-dark-bg-color);
  }

  &.ContentList--backgroundColorForestTwo {
    background: var(--theme-color-forest-dark-bg-color);
  }

  &.ContentList--backgroundColorGreenOne {
    background: var(--theme-color-green-one-bg-color);
  }

  &.ContentList--backgroundColorGreenFive {
    background: var(--theme-color-green-five-bg-color);
  }

  &.ContentList--backgroundColorForestFive {
    background: var(--theme-color-forest-five-bg-color);
  }

  &.ContentList--backgroundColorBlueThree {
    background: var(--theme-color-blue-three-bg-color);
  }

  &.ContentList--backgroundColorYellowTwo {
    background: var(--theme-color-yellow-two-bg-color);
  }

  &.ContentList--backgroundColorYellowFive {
    background: var(--theme-color-yellow-five-bg-color);
  }

  &.ContentList--backgroundColorRedFour {
    background: var(--theme-color-red-four-bg-color);
  }

  &.ContentList--backgroundColorGreyFive {
    background: var(--theme-color-grey-five-bg-color);
  }
}
