.RichProduct--editMode,
.RichProduct--previewMode {
  position: relative;
  z-index: 1;

  &.RichProduct__ImageTop,
  &.RichProduct__ImageBottom {
    & .RichProduct__Image {
      @media (--laptop) {
        height: auto;
      }
    }
  }
}
