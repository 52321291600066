.ImageViewer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(1.25 * var(--spacing-xxs));
}

.ProductPage__richtextBlock .ImageViewer__wrapper {
  @media (--desktop) {
    max-width: 960px;
  }
}

.ImageViewer__title {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 20px;
  margin: var(--spacing-xs) 0 var(--spacing-l);

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 24px;
  }
}

.ImageViewer__mainImageArrowsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 375 / 200;
  width: 100%;

  @media (--tablet) {
    display: none;
  }
}

.ContentAreaSection__item .ImageViewer__title {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 24px;
  font-weight: 400;
  line-height: 1.14;
  margin: 0 0 1.2rem;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 42px;
    margin: 0 0 var(--spacing-l);
  }

  @media (--mobileOnly) {
    @mixin wrapper;
    /* stylelint-disable-next-line declaration-no-important */
    margin-left: auto !important;
    /* stylelint-disable-next-line declaration-no-important */
    margin-right: auto !important;
  }
}

.ArticlePage__body .RichtextBlock .ImageViewer__wrapper {
  @mixin wrapper;

  @media (--laptop) {
    padding-left: 0;
    max-width: var(--wrapperWidth);
    padding-right: var(--sidebarWidth);
  }
}

.Productpage__body .RichtextBlock .ImageViewer__wrapper {
  @mixin wrapper;

  padding-left: 0;

  @media (--laptop) {
    padding-left: 5.5rem;
    padding-right: calc(var(--sidebarWidth) - 5.5rem);
  }
}
