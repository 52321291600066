.KeyBenefits {

  & .cardsList__3col {
    margin-bottom: 0.5rem;

    @media (--desktop) {
      margin-bottom: -3rem;
    }
    
    & li {
      margin-bottom: 1.8rem;

      @media (--desktop) {
        margin-bottom: 3rem;
      }
    }
  }
}
