:root {
  --radio-size: 1rem; /* 16px */
  --radio-inner-circle-size: 0.5rem; /* 8px */
}

.Radio {
  display: block;
}

.Radio__input {
  @mixin visuallyHidden;
}

.Radio__inner {
  display: flex;
  border: 1px solid var(--base-grey-medium-100);
  width: var(--radio-size);
  height: var(--radio-size);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all .1s;

  &::before {
    display: block;
    content: '';
    width: var(--radio-inner-circle-size);
    height: var(--radio-inner-circle-size);
    border-radius: var(--radio-inner-circle-size);
    background-color: transparent;
    transition: all .1s;
  }
}

.Radio__inner:hover,
.Radio--focused .Radio__inner,
.Radio__input:focus + .Radio__inner {
  border-color: var(--base-grey-dark-100);
}

.Radio__input:checked + .Radio__inner,
.Radio--selected > .Radio__inner {
  border-color: var(--base-grey-dark-100);

  &::before {
    background-color: var(--base-grey-dark-100);
  }
}
