.MicrositeMenuModalItem__link {
  display: flex;
  justify-content: space-between;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  margin: 12px 0;
  transition: color 0.3s ease-in-out;

  &.MicrositeMenuModalItem__link--light {
    &:hover,
    &:focus,
    &:focus-visible {
      & .MicrositeMenuModalItem__linkLabel,
      & .MicrositeMenuModalItem__linkSubLabel {
        color: color(var(--blackColor) a(50%));
      }

      & + .MicrositeMenuModalItem__linkBorder {
        background: color(var(--blackColor) a(30%));
      }

    }

    & .MicrositeMenuModalItem__linkLabel {
      color: var(--blackColor);

      @media (--tablet) {
        color: color(var(--blackColor) a(100%));
      }
    }

    & .MicrositeMenuModalItem__linkSubLabel {
      color: color(var(--blackColor) a(100%));
    }
  }

  &.MicrositeMenuModalItem__link--dark {
    &:hover,
    &:focus,
    &:focus-visible {
      & .MicrositeMenuModalItem__linkLabel,
      & .MicrositeMenuModalItem__linkSubLabel {
        color: color(var(--whiteColor) a(100%));
      }

      & + .MicrositeMenuModalItem__linkBorder {
        background: var(--whiteColor);
      }
    }

    & .MicrositeMenuModalItem__linkLabel {
      color: var(--whiteColor);

      @media (--tablet) {
        color: color(var(--whiteColor) a(50%));
      }
    }

    & .MicrositeMenuModalItem__linkSubLabel {
      color: color(var(--whiteColor) a(50%));
    }
  }
}

.MicrositeMenuModalItem__linkLabel {
  @mixin h1Micro;
  text-align: left;
  transition: color 0.3s ease-in-out;
  font-weight: 400;

  @media (--mobileOnly) {
    font-size: 32px;
    line-height: 1;
  }
}

.MicrositeMenuModalItem__linkSubLabel {
  min-width: 90px;
  margin-top: -8px;
  margin-left: 16px;
  text-align: right;
  text-transform: uppercase;
  font-family: var(--FamilyPressura);
  transition: color 0.3s ease-in-out;

  @media (--laptop) {
    font-size: 16px;
    margin-top: 0;
  }

  @media (--desktop4k) {
    @mixin dynamicFontSize 20, 24;
  }
}

.MicrositeMenuModalItem__linkBorder {
  height: 1px;
  transition: background 0.3s ease-in-out;

  &.MicrositeMenuModalItem__linkBorder--light {
    background: var(--blackColor);
  }

  &.MicrositeMenuModalItem__linkBorder--dark {
    background: color(var(--whiteColor) a(30%));
  }
}
