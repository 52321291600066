@keyframes innerCircle {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  50% {
    width: 40px;
    height: 40px;
  }

  75% {
    opacity: 0;
  }

  100% {
    width: 20px;
    height: 20px;
  }
}

@keyframes outerCircle {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  50% {
    width: 54px;
    height: 54px;
  }

  75% {
    opacity: 0;
  }

  100% {
    width: 20px;
    height: 20px;
    opacity: 0;
  }
}

.SideTextHotSpotVariantB__Container {
  position: absolute;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  left: -27px;
  top: -27px;

  @media (--mobileOnly) {
    display: none;
  }
}

.SideTextHotSpotVariantB__Container--mobile {
  display: none;

  @media (--mobileOnly) {
    display: flex;
  }
}

.SideTextHotSpotVariantB__PreviewText {
  @mixin typographyDesktopStrongLarge;

  text-transform: uppercase;
  color: var(--whiteColor);
}

.SideTextHotSpotVariantB__PreviewButton {
  border-radius: 50%;
  font-size: 1.25rem;
  background: var(--kog_blue);
  border: 1px solid var(--kog_aqua);
  height: 28px;
  width: 28px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteColor);
  font-family: var(--fontFamilyPrimary);
}

.SideTextHotSpotVariantB__PreviewAnimatedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.SideTextHotSpotVariantB__PreviewAnimation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: "";
    background-color: var(--kog_aqua);
    border-radius: 50%;
    position: absolute;
    animation: innerCircle cubic-bezier(0.75, 0, 0.45, 0.85);
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  &::before {
    content: "";
    border: 1px solid var(--kog_aqua);
    border-radius: 50%;
    position: absolute;
    animation: outerCircle cubic-bezier(0.6, 0, 0.6, 0.8);
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }
}

@define-mixin dotColor $color {
  & .SideTextHotSpotVariantB__PreviewButton {
    border-color: $color;
  }

  & .SideTextHotSpotVariantB__PreviewAnimation {
    &::after {
      background-color: $color;
    }

    &::before {
      border-color: $color;
    }
  }
}

.SideTextHotSpotVariantB__Container--default {
  @mixin dotColor var(--kog_aqua);
}

.SideTextHotSpotVariantB__Container--blueFive {
  @mixin dotColor var(--kog_blue_tint_7);
}

.SideTextHotSpotVariantB__Container--sandFour {
  @mixin dotColor var(--kog_bronze_tint_1);
}

.SideTextHotSpotVariantB__Container--tealTwo {
  @mixin dotColor var(--kog_aqua_tint_6);
}

.SideTextHotSpotVariantB__Container--tealFive {
  @mixin dotColor var(--kog_aqua_tint_1);
}

.SideTextHotSpotVariantB__Container--yellowFour {
  @mixin dotColor var(--kog_yellow);
}

.SideTextHotSpotVariantB__Container--greenTwo {
  @mixin dotColor var(--kog_green_tint_3);
}

.SideTextHotSpotVariantB__Container--blueTwo {
  @mixin dotColor var(--kog_blue_tint_1);
}

.SideTextHotSpotVariantB__Container--sandTwo {
  @mixin dotColor var(--kog_bronze_tint_6);
}

.SideTextHotSpotVariantB__Container--forestTwo {
  @mixin dotColor var(--kog_forest_tint_3);
}

.SideTextHotSpotVariantB__Container--greenFour {
  @mixin dotColor var(--kog_green_tint_2);
}

.SideTextHotSpotVariantB__Container--white {
  @mixin dotColor var(--whiteColor);
}

.SideTextHotSpotVariantB__Container--black {
  @mixin dotColor var(--textColor);
}

.SideTextHotSpotVariantB__Container--greenOne {
  @mixin dotColor var(--kog_green_tint_4);
}

.SideTextHotSpotVariantB__Container--greenFive {
  @mixin dotColor var(--kog_green_tint_1);
}

.SideTextHotSpotVariantB__Container--forestFive {
  @mixin dotColor var(--kog_forest_tint_1);
}

.SideTextHotSpotVariantB__Container--blueThree {
  @mixin dotColor var(--kog_blue_tint_9);
}

.SideTextHotSpotVariantB__Container--yellowTwo {
  @mixin dotColor var(--kog_yellow_tint_3);
}

.SideTextHotSpotVariantB__Container--yellowFive {
  @mixin dotColor var(--kog_yellow_tint_1);
}

.SideTextHotSpotVariantB__Container--redFour {
  @mixin dotColor var(--kog_red_tint_1);
}

.SideTextHotSpotVariantB__Container--greyTwo {
  @mixin dotColor var(--grayColor_tint_6);
}

.SideTextHotSpotVariantB__Container--greyFive {
  @mixin dotColor var(--grayColor_tint_2);
}
