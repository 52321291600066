.LabeledHotSpot {
  position: absolute;
}

.LabeledHotSpot__Link {
  word-break: unset;

  & .LabeledHotSpot__Text {
    color: var(--whiteColor);
    display: block;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 1px 1px #0000004D;

    line-height: 32px;
    padding: 0 16px;

    &.Wrap {
      line-height: 24px;
      min-width: 16ch;
      padding: 4px 16px;
      width: min-content;
    }

    &:hover {
      box-shadow: 0px 1px 1px 0px #0000004D;
      text-decoration: underline;
    }

    &.Red{
      @mixin backgroundColor var(--kog_red), var(--kog_red_tint_3);
    }
    &.Gold{
      @mixin backgroundColor var(--kog_bronze), var(--kog_bronze_tint_6);
    }
    &.Aqua{
      @mixin backgroundColor var(--kog_aqua), var(--kog_aqua_tint_7);
    }
    &.Blue {
      @mixin backgroundColor var(--kog_blue_tint_1), var(--kog_blue);
    }
  }

  @media (--mobileAndTablet) {
    display: none;
  }
}
