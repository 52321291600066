.StoryVideo {
  height: 100%;
  padding: 3rem 0;
  background: var(--kog_blue);
  margin: 0 0 40px;

  & .RichVideo__wrapper {
    width: 100%;
  }

  & .RichVideo__player {
    & video,
    & iframe {
      /* stylelint-disable-next-line declaration-no-important */
      height: 100% !important;
    }
  }

  & .RichVideo__playerWrapper {
    &,
    &::after,
    & .RichVideo__player,
    & .RichVideo__poster {
      max-height: 100vh;
    }
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(80px);
    }
  }

  @media (--laptop) {
    margin: 0;
    max-height: 60vw;
    height: 100vh;
    padding: 0;

    & .RichVideo__title {
      font-size: 4rem;
    }
  }
}

.StoryVideo--limited {
  max-height: 500px;

  & .RichVideo__wrapper {
    max-width: var(--storiestextwrapperWidth);
    @mixin storyTextContent;

    width: calc(100% - 30px);
    position: relative;

    @media (--tablet) {
      margin-inline-start: auto;
    }

    @media (--laptop) {
      width: calc(100% - 160px);
      margin-inline-start: auto;
    }
  }

  & .RichVideo__text {
    @media (--tablet) {
      padding-block: 0;
      place-content: center start;
    }
  }

  & .RichVideo__intro {
    display: none;

    @media (--laptop) {
      @mixin typographyMobileDefaultLarge;
    }
  }

  .RichVideo__title {
    @mixin typographyMobileDisplayLarge;
  }

  & .RichVideo__text,
  & .RichVideo__title {
    max-width: none;
  }
}
