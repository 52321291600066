.ResultsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ResultsList__item {
  padding: 20px 0;
  
  &:nth-child(even) {
    background: color(var(--kog_blue_tint_5) a(50%));
  }
}