.QuestionAndAnswerList {
  background: var(--base-grey-light-80);
  padding: var(--spacing-xxxxl) 0;

  & a {
    color: var(--brandColor);

    &:hover {
      text-decoration: underline;
    }
  }
}

.QuestionAndAnswerList__headingsWrapper {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  margin-bottom: var(--spacing-m);
}

.QuestionAndAnswerList__title {
  @mixin typographyMobileDisplayLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.QuestionAndAnswerList__subheading {
  @mixin typographyMobileDefaultXLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopDefaultXLarge;
  }
}
