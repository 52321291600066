.StoryHeaderInfo__Background {
  &.StoryHeaderInfo__Background--Default {
    background-color: transparent;
  }

  &.StoryHeaderInfo__Background--BlueFive {
    background-color: var(--kog_blue_tint_7);
  }

  &.StoryHeaderInfo__Background--SandFour {
    background-color: var(--kog_bronze_tint_1);
  }

  &.StoryHeaderInfo__Background--TealTwo {
    background-color: var(--kog_aqua_tint_6);
  }

  &.StoryHeaderInfo__Background--TealFive {
    background-color: var(--kog_aqua_tint_1);
  }

  &.StoryHeaderInfo__Background--YellowFour {
    background-color: var(--kog_yellow);
  }

  &.StoryHeaderInfo__Background--GreenTwo {
    background-color: var(--kog_green_tint_3);
  }

  &.StoryHeaderInfo__Background--ForestTwo {
    background-color: var(--kog_forest_tint_3);
  }

  &.StoryHeaderInfo__Background--GreenFour {
    background-color: var(--kog_green_tint_2);
  }

  &.StoryHeaderInfo__Background--White {
    background-color: var(--whiteColor);
  }

  &.StoryHeaderInfo__Background--Black {
    background-color: var(--textColor);
  }

  &.StoryHeaderInfo__Background--GreenOne {
    background-color: var(--kog_green_tint_4);
  }

  &.StoryHeaderInfo__Background--GreenFive {
    background-color: var(--kog_green_tint_1);
  }

  &.StoryHeaderInfo__Background--ForestFive {
    background-color: var(--kog_forest_tint_1);
  }

  &.StoryHeaderInfo__Background--BlueTwo {
    background-color: var(--kog_blue_tint_1);
  }

  &.StoryHeaderInfo__Background--BlueThree {
    background-color: var(--kog_blue_tint_9);
  }

  &.StoryHeaderInfo__Background--YellowTwo {
    background-color: var(--kog_yellow_tint_3);
  }

  &.StoryHeaderInfo__Background--YellowFive {
    background-color: var(--kog_yellow_tint_1);
  }

  &.StoryHeaderInfo__Background--RedFour {
    background-color: var(--kog_red_tint_1);
  }

  &.StoryHeaderInfo__Background--GreyTwo {
    background-color: var(--grayColor_tint_6);
  }

  &.StoryHeaderInfo__Background--GreyFive {
    background-color: var(--grayColor_tint_2);
  }
}
