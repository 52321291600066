.RichTextBlock__sidebarContent.RichtextArea {
  & p {
    @mixin typographyMobileDefaultLarge;

    margin-block: 0 var(--spacing-xxs);

    @media (--tablet) {
      @mixin typographyDesktopDefaultMedium;

      margin-block: 0 var(--spacing-xxs);
    }
  }
}

.RichTextBlock {
  position: relative;
  padding-block: var(--spacing-s);
  display: flex;
  align-items: center;

  @media (--tablet) {
    padding-block: var(--spacing-m);
  }

  & .RichtextArea {
    & ul, & ol {
      padding-left: var(--spacing-s);
    }

    & *:last-child {
      margin-bottom: 0;
    }
  }
}

.RichTextBlock__inner {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  @mixin richCampaignWrapper;

  @media (--laptop) {
    flex-flow: row nowrap;
  }
}

.RichTextBlock__aside {
  position: relative;
  margin-bottom: var(--spacing-s);
  padding-left: var(--spacing-xs);

  @media (--tablet) {
    margin-bottom: var(--spacing-m);
  }

  @media (--laptop) {
    width: 270px;
    margin-bottom: 0;
    padding-left: 0;
  }

  &::after {
    content: '';
    display: block;
    background: var(--base-grey-medium-80);
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;

    @media (--laptop) {
      top: 10%;
      left: auto;
      right: -45px;
      height: 80%;
    }
  }
}

.RichTextBlock__tagLine {
  @mixin typographyMobileStrongXXLarge;

  margin-block: 0 var(--spacing-xs);

  @media (--tablet) {
    @mixin typographyDesktopStrongXXLarge;

    margin-block: 0 var(--spacing-s);
  }
}

.RichTextBlock__content {
  @media (--laptop) {
    max-height: 70vh;
    width: calc(100% - 320px);
    padding-left: var(--spacing-m);
    margin-left: auto;
  }
}