.NavigationBar {
  min-height: 70px;
  color: var(--whiteColor);
  background-color: var(--brandColor_dark_tint_1);
  display: flex;
  align-items: center;
  margin: 0;

  @media (--tablet) {
    margin: 8rem 0 3.5rem 0;
  }

  & a {
    color: inherit;
  }

  & a:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.NavigationBar__list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  list-style-type: none;
  padding: 0;

  @media (--laptop) {
    justify-content: flex-start;
  }
}

.NavigationBar___listItem {
  font-size: 20px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;

  @media (--tablet) {
    font-size: 30px;
  }

  &::after {
    content: '|';
    display: inline-block;
    margin: 0 1rem;
    font-size: larger;
  }

  &:last-of-type:after {
    display: none;
  }
}

.SupportedProductsGroups {
  @media (--laptop) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
}

.SupportedProductsGroup {
  position: relative;
  background-color: color(var(--brandColor_dark_tint_5) a(17%));
}

.SupportedProductsGroup__title {
  font-size: 20px;
  line-height: 1.2;
  background-color: var(--brandColor_dark_tint_6);
  font-weight: normal;
  letter-spacing: normal;
  padding: 0.8rem;
  margin: 2rem 0 1.5rem;
  position: relative;

  @media (--tablet) {
    font-size: 32px;
  }
}

.SupportedProductsGroup__toTop {
  position: absolute;
  top: calc(50% - 15px);
  right: 20px;
  width: 30px;
  height: 30px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: svg-load('Icons/ArrowRightSimple.svg',
    fill: var(--textColor)) no-repeat center right;
    background-size: 85%;
    transform: rotate(-90deg);
    cursor: pointer;
  }

  &:hover:before {
    background: svg-load('Icons/ArrowRightSimple.svg',
    fill: var(--brandColor)) no-repeat center right;
  }
}

.SupportedProducts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.SupportedProduct {
  position: relative;

  &:last-of-type {
    box-shadow: 0 1px 0 0 var(--brandColor_dark_tint_5);
  }
}

.SupportedProduct__title {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.71;
  text-transform: none;
  margin: 0;
  min-height: 42px;
  padding-left: 1rem;
  padding-right: 3rem;
  user-select: none;

  @media (--tablet) {
    font-size: 24px;
  }
}

.SupportedProduct__link {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 42px;
  cursor: pointer;
  border-top: 1px solid var(--brandColor_dark_tint_5);
  z-index: 1;

  &:hover {
    background-color: color(white a(80%));
  }
}

.ProductSupportPage__heroSection {
  min-height: 200px;
  padding: var(--spacing-m) 0;

  @media (--tablet) {
    min-height: 280px;
    padding: var(--spacing-l) 0;
  }

  @media (--laptop) {
    min-height: 380px;
    padding: var(--spacing-xl) 0;
  }
}

.ProductSupportPage__introductionText {
  &,
  & > p {
    @mixin typographyMobileDefaultMedium;

    @media (--laptop) {
      @mixin typographyDesktopDefaultXLarge;
    }
  }
}

.ProductSupportPage__introductionSection {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  padding: var(--spacing-s) 0 calc(var(--spacing-m) + var(--spacing-xxxs));

  @media (--laptop) {
    padding: var(--spacing-xxxxl) 0;
  }
}
