.MicrositeHeader {
  @mixin micrositeLandingPagePosition 100px;
  text-align: right;

  @media (--tablet) {
    @mixin micrositeLandingPagePosition;
    margin-top: 36px;
  }

  &.MicrositeHeader--dark {
    color: var(--whiteColor);
  }

  &.MicrositeHeader--light {
    color: var(--blackColor);
  }

  & .MicrositeHeader__button.MicrositeHeader__button {
    @media (--desktop4k) {
      margin-top: 32px !important;
    }
  }
}

.MicrositeHeader__heading {
  @mixin h1Micro;
}

.MicrositeHeader__subheading {
  @mixin h2Micro;
  margin: 16px 0;

  @media (--tablet) {
    margin: 0;
  }
}
