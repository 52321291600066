.ImageViewerThumbnails {
  display: none;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  padding: 0 var(--spacing-xs);

  @media (--tablet) {
    display: flex;
  }
}

.ImageViewerThumbnails__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  max-width: calc(
    100% - calc(2 * 2.625rem) - calc(2 * var(--spacing-xs))
  ); /* 2 * arrow button width, 2 * side padding of wrapper */
}

.ImageViewerThumbnails__item {
  width: 60px;
  height: 46px;

  & > button {
    @mixin resetButtonStylesNoOverride;
    @mixin focusVisible var(--outlineColorSecondary);

    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;

    & img {
      height: 100%;
      width: 100%;
      opacity: 0.2;
      transition: opacity 0.2s;
    }
  }

  &.ImageViewerThumbnails__item--active img {
    opacity: 1;
  }

  &:not(.ImageViewerThumbnails__item--active) > button:hover img {
    opacity: 0.3;
  }
}
