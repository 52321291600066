.StoryVerticalContentList {
  position: relative;

  & .StoryVerticalContentList__contentWrapper {
    @mixin storyContentWrapper;

    position: relative;
    padding: var(--spacing-s) 0;

    @media (--tablet) {
      padding: var(--spacing-m) 0;
    }
  }

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      padding: var(--spacing-s) 0;
      border-bottom: 1px solid var(--base-grey-medium-80);

      @media (--mobileSmall) {
        padding: var(--spacing-m) 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
