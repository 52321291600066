@define-mixin backgroundOverlay $color: var(--blackColor), $alpha: 40%, $zIndex: 2 {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: color($color a($alpha));
    z-index: $zIndex;
    top: 0;
    right: 0;
  }
}