.SearchResults {
  position: relative;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-bottom: 1rem;
  height: auto;

  @media (--tablet) {
    height: 140px;
    padding-bottom: 0;
  }
  
  & .react-autosuggest__suggestions-list {
    top: 0;

    height: auto;
    overflow: visible;
  }

  & .react-autosuggest__container {
    width: 100%;
    flex-flow: row wrap;

    @media (--tablet) {
      flex-flow: row nowrap;
    }
  }

  & .react-autosuggest__container input[type="text"] {
    width: 100%;
    padding: 0 60px 0 20px;

    @media (--tablet) {
      width: 100%;
      padding: 0 80px 0 20px;
    }
  }

  & .react-autosuggest__suggestions-container--open {
    padding: 20px 0 40px;
    top: 20px;
    height: auto;
    position: relative;
    background: var(--kog_aqua_tint_2);

    @media (--tablet) {
      top: 100px;
      padding: 20px 0 80px;
      position: relative;
    }
  }

  & .SearchInput__button {
    width: 60px;
    right: 0;
    left: auto;

    @media (--tablet) {
      width: 100px;
    }
  }

  & .SearchInput {
    position: relative;
    justify-content: space-between;
    max-width: initial;
  }

  & .SearchInput__inner {
    background: none;
    padding: 0;

    @media (--tablet) {
      width: 100%;
    }
  }

  & .SearchInput__inner::before {
    width: 80px;
    background-size: 25px;
    background-position: 40px center;
    left: auto;
    right: 0;

    @media (--tablet) {
      width: 100px;
      background-size: 40px;
    }
  }

  & p {
    color: var(--textColor);
    font-size: 16px;
    margin-bottom: 0;
  }

  & .DropdownList {
    width: 100%;

    @media (--tablet) {
      width: auto;
    }
  }
}

_:-ms-fullscreen, :root .SearchResults .SearchInput {
  max-width: none;
}

.SearchResults_inner {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  @media (--tablet) {
    flex-flow: row nowrap;
  }

  & [id^="react"] {
    width: 100%;
  }
}

.SearchResults__info {
  width: 100%;

  @media (--tablet) {
    padding-left: 2rem;
    width: 45%;
  }

  & span {
    font-weight: normal;
  }
}

.SearchResults__filters {
  margin-bottom: 3.4rem;

  & .FacetFilters {
    flex-flow: row wrap;
  }
}

.SearchResults__filtersContainer {
  padding: 1rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  & .DropDownList {
    width: 100%;

    @media (--tablet) {
      width: auto;
    }
  }
}

.SearchResultsContainer {
  min-height: 500px;
}

@-moz-document url-prefix() {
  .react-autosuggest__container input[type="text"] {
    @media (--tablet) {
      padding: 18px 80px 18px 30px;
    }
  }
}
