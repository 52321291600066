.OfficeLocationsWithMapListing {
  --gap: 0.75rem; /* 12px */

  margin-bottom: var(--spacing-l);

  @media (--tablet) {
    --gap: 2.5rem; /* 40px */
  }

  &.OfficeLocationsWithMapListing--hasAdvancedFilters {
    --gap: 0.75rem; /* 12px */

    @media (--tablet) {
      --gap: 1rem; /* 16px */
    }
  }
}
