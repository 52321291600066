.SearchHits {
  animation: fade-in-up 0.3s ease;

  & ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 2rem 0;

    @media (--tablet) {
      margin: 0 0 4.5rem 0;
    }
  }

  & button {
    margin: 0 auto;
  }
}

.SearchHits__inner {
  max-width: 750px;
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}