.FiltersModal {
  background-color: var(--base-grey-white);
  overflow-y: scroll;
  height: 100vh;

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  @supports (height: 100dvh) {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    height: 100dvh;
  }
}

.FiltersModal__header {
  display: flex;
  flex-direction: column;
  padding: calc(var(--spacing-xs) + (var(--spacing-xxxs) * 0.5)) var(--spacing-s)
    calc(var(--spacing-s) + var(--spacing-xxxs));
  gap: var(--spacing-xxs);
}

.FiltersModal__headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
}

.FiltersModal__headerRow--filtersCount {
  @mixin typographyMobileDefaultLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopStrongMedium;

    color: var(--base-grey-dark-80);
  }
}

.FiltersModal__titleLabel {
  @mixin typographyMobileStrongXLarge;
}

.FiltersModal__close {
  @mixin resetButtonStylesNoOverride;

  font-size: 2rem;
  @mixin focusVisible;
}

.FiltersModal__content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + (var(--spacing-xxxs) * 0.5));
  padding-inline: var(--spacing-s);
  padding-block: 0 var(--spacing-s);
}
