.CompanyCertificatesPage:last-child {
    margin-bottom: 10rem;
}

.CompanyCertificatesPage__intro {
    margin-bottom: 10rem;
}

.CompanyCertificatesPage__company {
    display: block;
    margin: 4rem 0;
    & > h4 {
        margin: 1rem 0;
    }
}