.IntroductionBlock {
  @mixin wrapper;
  
  &.RequireMargins {
    margin: var(--spacing-xl) auto var(--spacing-xxl) auto;

    @media(--tablet) {
      margin: var(--spacing-xxxxxxxxl) auto;
    }
  }
  
  @media(--tablet) {
    display: flex;
  }
}

.IntroductionBlock__logo {
  max-width: 80%;
  width: 250px;
  height: 100px;
  text-align: center;
  margin: var(--spacing-m) 0 0;

  & img {
    max-height: 100px;
  }
  
  @media (--tablet) {
    text-align: left;
    margin: 0;
  }
}

.IntroductionBlock__title {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-m);
}

.IntroductionBlock__logoContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}