.InteractiveHeroBlock__Text {
  color: var(--whiteColor);
  text-shadow: 0 2px 2px var(--blackColor);
  opacity: 0;
  margin-left: -15px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  text-align: center;
}

.InteractiveHeroBlock__Icon {
  border-radius: 50%;
  padding: 4px;
  display: block;
  transition: border 0.3s ease-in-out;
  position: relative;

  @media (--desktop) {
    padding: 5px;
  }
}

.InteractiveHeroBlock__Inner {
  height: 50px;
  width: 50px;
  position: relative;
  border-radius: 50%;

  @media (--desktop) {
    height: 70px;
    width: 70px;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 35px;
    width: 35px;
    z-index: 1;

    @media (--desktop) {
      height: 50px;
      width: 50px;
    }
  }
}

.InteractiveHeroBlock__HotSpotLink {
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);

  @media (--mobileAndTablet) {
    display: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    transition: box-shadow 0.3s ease-in-out;
    z-index: -1;

    @media (--desktop) {
      height: 70px;
      width: 70px;
    }
  }

  &:hover {
    & .InteractiveHeroBlock__Text {
      opacity: 1;
      margin-left: 0;
    }
  }
}
