.ModelViewerFigureWrapper {
  padding: var(--spacing-xs) 0 var(--spacing-s) 0;

  @media (--tablet) {
    padding: var(--spacing-xxxxl) 0;
  }

  & .Section__title {
    margin: 0 0 var(--spacing-xs);

    @media (--tablet) {
      margin: 0 0 var(--spacing-xl);
    }
  }
}

.ModelViewerFigure {
  margin: 0;
  text-align: center;

  & .Figure__caption {
    position: relative;
  }
}

.ModelViewerFigure__ModelContainer {
  overflow: hidden;
  width: 50vw;
  height: 70vh;
  margin: 0 auto;
}

.modelViewer {
  width: 100%;
  height: 90%;
}
