.CounterList--light {
    & .CounterList__title,
    & .CounterList__intro {
        color: var(--kog_blue_tint_1);
    }

    &::before {
        background: color(var(--whiteColor) a(85%));
    }

    & .Counter__value {
        color: var(--kog_blue_tint_1);
    }

    & .Counter__text--visible {
        color: var(--kog_blue_tint_1);
    }

    & .Counter::before {
        background: var(--kog_blue_tint_1);
    }
}

.CounterList--dark {
    & .CounterList__title,
    & .CounterList__intro {
        color: var(--whiteColor);
    }

    &::before {
        background: color(var(--themeBrandColor_dark) a(85%));
    }

    & .Counter__value {
        color: var(--kog_aqua);
    }

    & .Counter__text--visible {
        color: var(--whiteColor);
    }

    & .Counter::before {
        background: var(--whiteColor);
    }
}