.RichCallOut {
  & .RichCallOut__buttonColor--Default a {
    background-color: var(--textColor);
  }

  & .RichCallOut__buttonColor--BlueFive a {
    background-color: var(--kog_blue_tint_7);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--SandFour a {
    background-color: var(--kog_bronze_tint_1);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--TealTwo a {
    background-color: var(--kog_aqua_tint_6);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--TealFive a {
    background-color: var(--kog_aqua_tint_1);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--YellowFour a {
    background-color: var(--kog_yellow);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--GreenTwo a {
    background-color: var(--kog_green_tint_3);
  }

  & .RichCallOut__buttonColor--BlueTwo a {
    background-color: var(--kog_blue_tint_1);
  }

  & .RichCallOut__buttonColor--SandTwo a {
    background-color: var(--kog_bronze_tint_6);
  }

  & .RichCallOut__buttonColor--ForestTwo a {
    background-color: var(--kog_forest_tint_3);
  }

  & .RichCallOut__buttonColor--GreenFour a {
    background-color: var(--kog_green_tint_2);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--White a {
    background-color: var(--whiteColor);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--Black a {
    background-color: var(--textColor);
  }

  & .RichCallOut__buttonColor--GreenOne a {
    background-color: var(--kog_green_tint_4);
  }

  & .RichCallOut__buttonColor--GreenFive a {
    background-color: var(--kog_green_tint_1);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--ForestFive a {
    background-color: var(--kog_forest_tint_1);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--BlueThree a {
    background-color: var(--kog_blue_tint_9);
  }

  & .RichCallOut__buttonColor--YellowTwo a {
    background-color: var(--kog_yellow_tint_3);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--YellowFive a {
    background-color: var(--kog_yellow_tint_1);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--RedFour a {
    background-color: var(--kog_red_tint_1);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--GreyTwo a {
    background-color: var(--grayColor_tint_6);
    color: var(--textColor);
  }

  & .RichCallOut__buttonColor--GreyFive a {
    background-color: var(--grayColor_tint_2);
    color: var(--textColor);
  }
}
