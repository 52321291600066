/* stylelint-disable no-descending-specificity */
.StoryTextContent__bodyRichText {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 0.1px;

  @media (--tablet) {
    font-size: 1.5rem;
  }

  @media (--laptop) {
    line-height: 2;
    font-size: 2rem;
  }

  & p {
    font-size: 0.875rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.9;
    letter-spacing: 0.05px;
    margin: 0 0 var(--spacing-xs);

    @media (--tablet) {
      font-size: 1.125rem;
      line-height: 1.9;
      margin: 0 0 calc(var(--spacing-xs) + var(--spacing-xxxs));
    }

    @media (--laptop) {
      font-size: 1.25rem;
      line-height: 2rem;
      margin: 0 0 calc(var(--spacing-s) + var(--spacing-xxxs));
    }

    & strong {
      font-weight: 600;
      letter-spacing: 1px;
    }

    &:last-of-type {
      @media (--tablet) {
        margin-bottom: var(--spacing-s);
      }

      @media (--laptop) {
        margin-bottom: var(--spacing-l);
      }
    }
  }

  & a,
  & a:visited {
    text-decoration: none;
    color: var(--brandColor);

    &:hover {
      text-decoration: underline;
    }
  }

  & .PictureFigure figcaption {
    max-width: 425px;
  }

  & p > video {
    max-width: 100%;
    outline: none;
    margin: 2rem 0;
  }

  &.StoryTextContent__body.RichtextArea h2 {
    font-size: 1rem;
    margin-bottom: var(--spacing-xs);

    @media (--tablet) {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: calc(var(--spacing-xs) + var(--spacing-xxxs));
    }
    @media (--laptop) {
      font-size: 1.5rem;
      line-height: 2.375rem;
      margin-bottom: calc(var(--spacing-s) + var(--spacing-xxxs));
    }
  }
}

.StoryTextContent__asideRichText {
  & p,
  & span {
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.8;

    @media (--tablet) {
      font-size: 1rem;
      line-height: 2rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & strong {
    font-weight: 600;
    letter-spacing: 1px;
  }

  & a,
  & a:visited {
    text-decoration: none;
    color: var(--brandColor);

    &:hover {
      text-decoration: underline;
    }
  }
}
