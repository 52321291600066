.FinancialSnapshot {
  @mixin wrapper;

  padding: 16px 0;

  @media (--mobileSmall) {
    padding: 48px 0;
  }
}

.FinancialSnapshot--Positive {
  & .FinancialSnapshot__value,
  & .FinancialSnapshot__change {
    color: var(--greenColor);
  }

  & .FinancialSnapshot__value::after {
    background-image: svg-load("Icons/ArrowRightSimple.svg", fill: var(--greenColor));
    transform: rotate(-90deg);
  }
}

.FinancialSnapshot--Negative {
  & .FinancialSnapshot__value,
  & .FinancialSnapshot__change {
    color: var(--tertiaryColor);
  }

  & .FinancialSnapshot__value::after {
    background-image: svg-load("Icons/ArrowRightSimple.svg", fill: var(--tertiaryColor));
    transform: rotate(90deg);
  }
}

.FinancialSnapshot--Unchanged {
  & .FinancialSnapshot__value,
  & .FinancialSnapshot__change {
    color: var(--kog_blue_tint_1);
  }

  & .FinancialSnapshot__value::after {
    background-image: svg-load("Icons/Minus.svg", fill: var(--kog_blue_tint_1));
  }
}

.FinancialSnapshot__container {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  flex-grow: 0;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-direction: row;
  margin: 0 auto;
  max-width: 500px;

  @media (--laptop) {
    width: 75%;
    max-width: 728px;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.FinancialSnapshot__value {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 36px;
  font-weight: 400;
  white-space: nowrap;
  border-right: solid 4px var(--grayColor);
  width: 65%;

  @media (--mobileSmall) {
    width: 70%;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 70px;
  }

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 100px;
    width: 75%;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &::after {
    content: "";
    display: inline-block;
    background: no-repeat center;
    background-size: cover;
    position: relative;
    margin-left: -10px;
    top: 10px;
    width: 28px;
    height: 44px;

    @media (--mobileSmall) {
      width: 51px;
      height: 84px;
      top: 18px;
    }

    @media (--laptop) {
      top: 12px;
      width: 118px;
      height: 92px;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.FinancialSnapshot__change {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  padding: 0 0 0 16px;

  @media (--mobileSmall) {
    width: 30%;
    padding-left: 32px;
  }

  @media (--laptop) {
    width: 25%;
  }

  & span {
    display: block;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 20px;
    line-height: 1.3;

    @media (--mobileSmall) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 26px;
    }

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 37px;
    }
  }
}

.FinancialSnapshot__timestamp {
  font-style: italic;
  padding-top: 32px;
  width: 100%;

  @media (--tablet) {
    width: 70%;
  }

  @media (--laptop) {
    width: 75%;
  }
}

.FinancialSnapshot__link {
  padding-top: 8px;
  width: 100%;

  @media (--tablet) {
    width: 30%;
    padding-left: 32px;
    padding-top: 32px;
    white-space: nowrap;
  }

  @media (--laptop) {
    width: 25%;
  }

  & .Btn--explore::after {
    top: -1px;
  }
}

.FinancialSnapshot__error {
  text-align: center;
}
