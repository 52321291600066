.EventCampaignPageHeader {
  & .HeroHeader__title {
    @mixin typographyMobileDisplayXLarge;

    @media (--laptop) {
      @mixin typographyDesktopDisplayXLarge;
    }
  }
}

.EventCampaignPageHeader__label {
  @mixin typographyMobileCapsLarge;

  margin-block: 0 var(--spacing-xxs);

  @media (--laptop) {
    margin-block: 0 var(--spacing-xs);
  }
}

.EventCampaignPageHeader__bottomRow {
  margin-top: var(--spacing-l);
}

.EventCampaignPageHeader__sideContent {
  display: grid;
  height: 100%;
  
  @media (--tablet) {
    place-content: end;
  }
}

.EventCampaignPageHeader__link {
  width: 100%;
  justify-content: end;
}
