.Hero--fullWidth {
  & .Hero__media {
    min-height: var(--heroFullWidthMobileHeight);

    @media (--tablet) {
      min-height: var(--heroFullWidthTabletHeight);
    }

    @media (--laptop) {
      min-height: var(--heroFullWidthDesktopHeight);
    }
  }

  & .Hero__media,
  & .Hero__container,
  & .Hero__picture,
  & .Hero__placeholder {
    max-height: var(--heroFullWidthMobileHeight);

    @media (--tablet) {
      max-height: var(--heroFullWidthTabletHeight);
    }

    @media (--laptop) {
      max-height: var(--heroFullWidthDesktopHeight);
    }
  }

  & .Hero__picture {
    & img {
      max-height: var(--heroFullWidthMobileHeight);
    }
  }
}

.Hero--withHeading.Hero--fullWidth {
  & .Hero__heading h1 {
    @media (--mobileOnly) {
      margin-block: 0;
      font-size: 2rem;
    }
  }
}
