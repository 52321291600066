.StoryContactInfo__name {
  font-weight: 600;

  &.StoryContactInfo__text {
    flex-grow: 0;
    max-width: none;
    font-style: normal;
  }
}

.StoryContactInfo__text {
  max-width: 60%;
  margin: 0;
  font-size: 0.875rem;
  font-style: italic;

  @media (--tablet) {
    max-width: none;
    font-size: 1.125rem;
  }

  @media (--laptop) {
    font-size: 1.25rem;
  }
}

.StoryContactInfo__contact {
  margin-top: 0.75rem;

  @media (--tablet) {
    margin-top: 1rem;
  }
}

.StoryContactInfo__link {
  display: flex;
  align-items: center;
  font-style: normal;
  line-height: 2;
  color: var(--whiteColor);
  white-space: nowrap;

  &::before {
    min-width: 18px;
    width: 18px;
    height: 18px;
    background-size: contain;

    @media (--tablet) {
      min-width: 27px;
      width: 27px;
      height: 27px;
    }
  }
}
