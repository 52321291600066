.ContentList {
  & .Section__title {
    padding-top: var(--spacing-xs);
    margin: 0 0 var(--spacing-m);
    color: var(--black-800);

    @media (--tablet) {
      margin-bottom: var(--spacing-xxxl);
    }
  }

  & .Section__description {
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: var(--spacing-m);
    border-bottom: 1px solid var(--grayColor_tint_4);
    margin-top: calc(var(--spacing-m) * -1);
    margin-bottom: var(--spacing-xs);
  }
}

.ContentList__grid {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--spacing-xs);

  @media (--mobileSmall) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-s);
  }

  @media (--tablet) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: var(--spacing-m);
  }

  @media (--laptop) {
    column-gap: var(--spacing-l);
  }

  & > li {
    width: 100%;
    margin: 0 0 var(--spacing-m);
    display: flex;
    flex-direction: column;

    & > div {
      flex-grow: 1;
    }
  }
}

.ContentList__grid--items4 {
  @media (--laptopMedium) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: var(--spacing-m);
  }
}

.ContentList__grid--items2 {
  @media (--laptopMedium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-m);
  }
}

.ContentList__ctaButtonWrapper {
  padding-bottom: var(--spacing-xs);
}

.ContentList__ctaButton {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryBlueFill;

  margin: 0 auto;
  display: block;

  @media (--mobileAndTablet) {
    width: 100%;
    text-align: center;
  }
}
