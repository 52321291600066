.CallToActionButton {
  /* TODO Clean up buttons - sizing/typography is copied from buttomPrimary */
  @mixin buttonDefaultSize;
  @mixin button;

  min-width: 145px;
  
  /* max-width: 350px; // temporary disablement of fix for issue #34622 */
  padding: calc(0.8 * var(--spacing-xxs)) var(--spacing-s);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
