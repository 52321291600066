.GroupedContentListBlock {
    & .GroupedContentList__item {
        display: grid;
        grid-template-columns: 1fr;
        position: relative;

        .SimpleTile__labels {
            position: absolute;
            top: calc(var(--spacing-xxxs) + var(--spacing-xxs));
            left: calc(var(--spacing-xxxs) + var(--spacing-xxs));
        }

        @media (--tablet) {
            grid-template-columns: 2fr 3fr;
            column-gap: var(--spacing-xxxxxxl);

            .GroupedContentList__tile {
                grid-column: 1/2;
                grid-row: span 3;
            }

            .GroupedContentList__heading,
            .GroupedContentList__description,
            .Link.Link--iconRight {
                grid-column: 2/3;
                margin: 0;
                align-self: center;
            }

            .GroupedContentList__heading {
                align-self: end;
                @mixin typographyMobileStrongXLarge;

                @media (--laptop) {
                    @mixin typographyDesktopStrongXLarge;
                }
            }

            .Link.Link--iconRight {
                align-self: start;
            }
        }

        &:last-child {
            margin-block-end: var(--spacing-xxxxxxxl);
        }

        &+& {
            margin-block-start: var(--spacing-xxxl);

            &::before {
                content: "";
                position: absolute;
                top: calc((var(--spacing-m) + var(--spacing-xxs)) * -1);
                left: 0;
                right: 0;
                height: 2px;
                background: var(--grayColor_tint_2);
            }
        }
    }

    & .PlainIntroductionSection__title {
        @mixin typographyMobileStrongXXLarge;

        margin-block-start: var(--spacing-m);

        @media (--laptop) {
            @mixin typographyDesktopStrongXXLarge;

            margin-block-start: var(--spacing-m);
        }
    }

    & .PlainIntroductionSection__description {
        @mixin typographyMobileDefaultXLarge;

        margin-block: var(--spacing-s) var(--spacing-m);

        @media (--tablet) {
            @mixin typographyDesktopDefaultXLarge;

            margin-block: var(--spacing-s) var(--spacing-xxxl);
        }
    }
}