@define-mixin wrapperStory {
    max-width: var(--storiestextwrapperWidth);
    margin-left: 30px;
    margin-right: 50px;
    
    @media (--laptop) {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
}