@define-mixin tileColor $backgroundColor, $color {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: $backgroundColor;
    z-index: -1;
  }

  & .Testimonial__inner {
    background-color: $backgroundColor;
    color: $color;
  }
}

.Testimonial--tile {
  position: static;

  .Testimonial--default,
  .Testimonial--sandFive {
    @mixin tileColor var(--kog_bronze_tint_2), var(--kog_blue);
  }

  .Testimonial--blueFive {
    @mixin tileColor var(--kog_blue_tint_7), var(--kog_blue);
  }

  .Testimonial--sandFour {
    @mixin tileColor var(--kog_bronze_tint_1), var(--kog_bronze_tint_7);
  }

  .Testimonial--tealTwo {
    @mixin tileColor var(--kog_aqua_tint_6), var(--whiteColor);
  }

  .Testimonial--tealFive {
    @mixin tileColor var(--kog_aqua_tint_1), var(--kog_aqua_tint_7);
  }

  .Testimonial--yellowFour {
    @mixin tileColor var(--kog_yellow), var(--kog_blue);
  }

  .Testimonial--greenTwo {
    @mixin tileColor var(--kog_green_tint_3), var(--whiteColor);
  }

  .Testimonial--blueTwo {
    @mixin tileColor var(--kog_blue_tint_1), var(--whiteColor);
  }

  .Testimonial--sandTwo {
    @mixin tileColor var(--kog_bronze_tint_6), var(--whiteColor);
  }

  .Testimonial--forestTwo {
    @mixin tileColor var(--kog_forest_tint_3), var(--whiteColor);
  }

  .Testimonial--greenFour {
    @mixin tileColor var(--kog_green_tint_2), var(--kog_green_tint_4);
  }
}
