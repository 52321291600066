@define-mixin counterListColorVariant $backgroundColor, $fontColor {
  & .CounterList {
    &,
    &.CounterList--extended {
      background-image: none;
      background-color: transparent;

      &::before {
        background: $backgroundColor;
      }

      &.CounterList--withImage {
        &::before {
          background: color($backgroundColor a(85%));
        }
      }

      /* stylelint-disable-next-line no-descending-specificity */
      & .Counter__value,
      & .Counter__text.Counter__text--visible,
      & .Counter__text.Counter__text--visible a,
      & .CounterList__title,
      & .CounterList__intro,
      & .CounterList__intro a {
        color: $fontColor;
      }

      & .Counter::before {
        background: $fontColor;
      }
    }
  }
}

.ContainerWithBackground--None {
  @mixin counterListColorVariant var(--whiteColor), var(--blackColor);
}

.ContainerWithBackground--Beige {
  @mixin counterListColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin counterListColorVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin counterListColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin counterListColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin counterListColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin counterListColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color);
}

.ContainerWithBackground--Pink {
  @mixin counterListColorVariant var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin counterListColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin counterListColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin counterListColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin counterListColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin counterListColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin counterListColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin counterListColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin counterListColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin counterListColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin counterListColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin counterListColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin counterListColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin counterListColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin counterListColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color);
}
