.CallOutMinimalistic {
  margin: 0 auto;
  background: var(--accent-teal-accent-darker);
  color: var(--whiteColor);
}

.CallOutMinimalistic__wrapper {
  @mixin wrapper;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  padding: var(--spacing-m) 0;
  background: var(--accent-teal-accent-darker);
}

.CallOutMinimalistic__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  max-width: 641px;
}

.CallOutMinimalistic__title {
  @mixin typographyMobileStrongXLarge;

  @media (--laptop) {
    @mixin typographyDesktopStrongXLarge;
  }
}

.CallOutMinimalistic__body,
.CallOutMinimalistic__body p {
  @mixin typographyMobileDefaultLarge;

  @media (--laptop) {
    @mixin typographyDesktopDefaultLarge;
  }
}
