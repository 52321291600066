.AccordionList {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);
}

.AccordionList__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs);
}

.AccordionList__itemTitle {
  @mixin typographyMobileStrongLarge;
}

.AccordionList__itemButton {
  @mixin resetButtonStylesNoOverride;
  @mixin focusVisible var(--outlineColorSecondary);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-m);
  min-height: 60px;
  cursor: pointer;
  width: 100%;
  padding: var(--spacing-xs);

  @media (--tablet) {
    padding: var(--spacing-xs) var(--spacing-s);
  }
}

.AccordionList__itemIconWrapper {
  position: relative;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
}

.AccordionList__itemIcon {
  transition: opacity 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.AccordionList__itemIcon--minus {
    opacity: 0;
  }
}

.AccordionList__itemContentWrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.4s;
  margin: 0 auto;
}

.AccordionList__itemContent {
  overflow: hidden;
}

.AccordionList__itemContentInner {
  padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-s) var(--spacing-s);

  & p {
    margin-bottom: var(--spacing-xs);
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & a {
    @mixin focusVisible var(--outlineColorSecondary);
  }

  & * {
    @mixin typographyMobileDefaultMedium;
  }
}

.AccordionList__item {
  background: var(--whiteColor);
  border-radius: 0 0 4px 4px;

  &.AccordionList__item--opened {
    & .AccordionList__itemIcon--plus {
      opacity: 0;
    }

    & .AccordionList__itemIcon--minus {
      opacity: 1;
    }

    & .AccordionList__itemContentWrapper {
      grid-template-rows: 1fr;
    }
  }
}
