.DownloadsMultipleItems {
    position: relative;
}

.DownloadsMultipleItems__button {
  @mixin resetButtonStylesNoOverride;
}

.DownloadsMultipleItems__dropdown {
    display: block;
    list-style: none;
    position: absolute;
    z-index: var(--z-index-downloads-multiple-items-dropdown);
    width: 100%;
    margin-top: 3px;
    background-color: var(--whiteColor);
    box-shadow: 0 1px 6px var(--shadowColor_gray);
    padding: var(--spacing-xxxs);

    & .DownloadsMultipleItems__dropdownItem {
      display: block;
    }

    &.DownloadsMultipleItems__dropdown--hidden {
      visibility: hidden;
    }

    & .DownloadsMultipleItems__dropdownItemLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: calc(var(--spacing-xxxs) + var(--spacing-xxs));
      gap: var(--spacing-xxs);

      &:hover {
        background-color: var(--base-grey-light-100);
      }

      @mixin focusVisible var(--outlineColorSecondary);

      & > svg {
        flex: 0 0 auto;
      }
    }
}
