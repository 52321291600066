.VesselSegmentPage__header {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  padding: var(--spacing-m) 0 var(--spacing-l);

  @media (--laptop) {
    padding: var(--spacing-xl) 0 var(--spacing-xxxl);
    gap: calc(var(--spacing-s) + var(--spacing-xxxs));
  }
}

.VesselSegmentPage__heading {
  @mixin typographyMobileDisplayLarge;

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.VesselSegmentPage__intro {
  &,
  & p {
    @mixin typographyMobileDefaultXLarge;
  }

  & a {
    @mixin typographyDesktopStrongSmall;
    @mixin arrowLinkRightMedium var(--kog_blue_tint_1);
    
    color: var(--kog_blue_tint_1);
    margin-top: var(--spacing-m);
    display: flex;
    gap: 10px;
    align-items: center;
    width: max-content;
    
    &::after {
      margin-top: calc(1rem + 2px);
    }
  }
  
  @media (--laptop) {
    &,
    & p {
      @mixin typographyDesktopDefaultXLarge;
    }
  }
}

.VesselSegmentPage__content {
  background: var(--base-grey-light-80);
}

.VesselSegmentPage__plainVesselListWrapper {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  padding: var(--spacing-m) 0 var(--spacing-s);

  @media (--laptop) {
    padding: var(--spacing-xxl) 0 var(--spacing-xl);
  }
}
