@font-face {
  font-display: swap;
  font-family: 'Soehne';
  src: url('../Fonts/soehne-buch.kog.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Soehne';
  src: url('../Fonts/soehne-buch-kursiv.kog.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Soehne';
  src: url('../Fonts/soehne-halbfett.kog.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}