/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-class-pattern */
.ImageMedium {
  @media (--laptopMedium) {
    display: block;
    max-width: var(--wrapperWidth) !important;
    width: var(--wrapperWidth) !important;

    & img {
      width: 100%;
    }

    & .Figure__caption {
      max-width: 66% !important;
    }
  }
}

.ImageFullWidth {
  @media (--laptop) {
    display: block;
    max-width: 100vw !important;
    width: 100vw !important;
    margin-left: 50% !important;
    transform: translate3d(calc(-50% + 12.5rem), 0, 0);

    &.ImageFullWidth--product-page {
      transform: translate3d(calc(-50% + 5.5rem), 0, 0);
    }

    & img {
      width: 100%;
    }

    & .Figure__caption {
      max-width: 62% !important;
    }
  }
}

.Figure {
  & img {
    width: 100%;
  }

  & .Figure__caption {
    @mixin typographyDesktopDefaultSmall;

    margin-block: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0;
    width: 100%;
    font-style: normal;
    letter-spacing: -0.14px;

    &.animate {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

@media (--laptop) {
  :not(.ImageFullWidth, .ImageMedium) {
    &.Figure--float-right {
      float: right;
      width: 40%;
      margin: var(--spacing-s) 0 var(--spacing-s) var(--spacing-l) !important;
    }

    &.Figure--float-left {
      float: left;
      width: 40%;
      margin: var(--spacing-s) var(--spacing-l) var(--spacing-s) 0 !important;
    }
  }
}

.Figure--caption-right .Figure__caption {
  right: 0;
}

.Figure--caption-left .Figure__caption {
  left: 0;
}

.Figure--caption-right .Figure__caption,
.Figure--caption-left .Figure__caption {
  max-width: 100%;
}

@media (--tablet) {
  :not(.Figure--float-left, .Figure--float-right) {
    &.Figure--caption-right .Figure__caption,
    &.Figure--caption-left .Figure__caption {
      position: absolute;
      bottom: 0;
      padding: var(--spacing-xs) var(--spacing-m) var(--spacing-s) calc(2.75 * var(--spacing-base));
      margin-top: 0 !important;
      max-width: 85%;
      background-color: var(--theme-color-brown-bg-color-lighter);
      color: var(--theme-color-brown-text-color);
      letter-spacing: normal;

      &.animate {
        opacity: 0;
        transform: translateY(80px);
      }
    }
  }
}
