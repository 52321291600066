:root {
    --flagSize: 60px;
    --flagBackgroundSize: 64px;
    --borderWidth: 3px;
}

.Reference__countries {
    @neat-outer-container ;
    width: 100%;
    list-style: none;
    padding-left: 0;
    margin-bottom: 100px;
    margin-top: 50px;
    display: flex;
    flex-flow: row wrap;

    & li {
      display: flex;
      flex-flow: row nowrap;
      width: 50%;  
    
      @media screen and (min-width: 400px) {  
          width: 110px;
      }  

      @media (--desktop) {
          width: 120px;
      }  
    }

    & .Reference__country {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
}

.Reference__country--flagWrapper {
    width: var(--flagBackgroundSize);
    background: var(--kog_bronze_tint_4);
    padding: 2px;
    border-radius: var(--flagSize);
    
    &.ReferenceCountry__item--flagFullPurchase {
        background: var(--kog_bronze);
    }
    
    & .Reference__country--flag {
        height: var(--flagSize);
        width: var(--flagSize);
        border-radius: calc(var(--flagSize) / 2);
    }
}

.Reference__country--name {
    font-size: 14px;
    display: block;
    text-align: center;
    width: 100px;
    word-break: break-word;
    padding: 1rem 0;
}
