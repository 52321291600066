.FactBox {
  color: var(--whiteColor);
  background: var(--brandColor);
}

.FactBox__title {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 0 0 1rem;

  @media (--tablet) {
    font-size: 1.625rem;
    margin: 0 0 1.5rem;
  }
}

.FactBox__richText {
  & a {
    color: var(--whiteColor);
  }

  & a:visited {
    color: var(--whiteColor);
  }
}

.FactBox__richText--bulletDot {
  & li {
    &::before {
      content: "\2022";
      left: 5px;
    }
  }
}

.FactBox__richText--bulletArrow {
  & li {
    &::before {
      background: svg-load('Icons/ArrowRightSimple.svg',
      fill: var(--whiteColor)) no-repeat;
      background-size: contain;
      transform: rotate(45deg);
      width: 24px;
      height: 24px;
      top: 4px;
      left: -5px;
    }
  }
}