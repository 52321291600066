.CourseCategoryTeaser {
    margin: 2.5rem 0;
    
    @media(--tablet) {
        margin: 4rem 0;
    }
    
    & a:hover {
        & .Btn--explore {
            text-decoration: underline;
        }

        & .Btn--explore::after {
            @mixin hoverMoveRight;
        }
    }

    & p {
        margin: 0;

        @media (--tablet) {
            margin: 0 0 1rem 0;
        }
    }

    & a {
        flex: 1 0 auto;
        display: flex;
        flex-flow: column;
    }
}

.CourseCategoryTeaser__title {
    font-size: 20px;
    margin: 0 0 0.25rem 0;

    @media (--tablet) {
        font-size: 24px;
        margin: 0 0 1rem 0;
    }
}

.CourseCategoryTeaser__textContainer {
    margin-bottom: 0.5rem;
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;

    @media (--laptop) {
        margin-bottom: 1.2rem;
    }
}