.FilterInput {
    & > .Filter__label {
        @mixin typographyDesktopStrongMedium;

        color: var(--kog_blue);
        margin-bottom: 10px;
    }
}

.FilterInput__input {
    display: flex;
    align-items: center;

    & input {
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--base-grey-black-100);
        background: transparent;
        min-height: unset;
        height: 40px;
        padding-left: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
            border-bottom: 1px solid var(--base-grey-black-100);
        }

        &::placeholder {
            color: var(--grayColor_tint_5);
        }
    }
}