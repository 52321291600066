.ContactCard--vertical {
  min-height: 355px;
  min-width: 200px;
  width: 100%;
  flex-grow: 1;

  @media (--tablet) {
    max-width: 420px;
  }

  & .ContactCard__inner {
    padding: 180px 25px 30px;
    position: relative;

    @media (--tablet) {
      padding: 245px 25px 40px;
    }

    @media (--tablet) {
      &::after {
        top: 120px;
      }
    }
  }
  
  & .ContactCard__info {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}

.ContactBlock__outer {
    display: flex;
    list-style-type: none;
  }