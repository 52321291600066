.Microsite {
  overflow: hidden;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
}

.Microsite__inner {
  z-index: 5;
  max-width: 100vw;
  overflow: hidden;

  @media (--tablet) {
    max-height: 100vh;
  }
}

.Microsite__logo {
  position: absolute;
  z-index: 15;
  top: 20px;
  left: 16px;

  @media (--tablet) {
    top: 40px;
    left: 48px;
  }
}

.Microsite--mobileHidden {
  @media (--mobileOnly) {
    display: none;
  }
}

.Microsite--mobileAndTabletHidden {
  @media (--mobileAndTablet) {
    display: none;
  }
}
