.ProductSuggestions__dropdown {
  width: 100%;
  margin-block-start: calc(var(--spacing-xs) + var(--spacing-xxxs));
  opacity: 0;
  transition: opacity 0.5s;
  transform: scale(0);

  &:not(.ProductSuggestions__dropdown--expanded) {
    margin-block-start: 0;
  }

  @media (--laptop) {
    padding: var(--spacing-xs) calc(var(--spacing-xxs) + var(--spacing-xxxs));
    margin: 0;
    position: absolute;
    inset: unset;
    inset: 0;
    height: fit-content;
    background: var(--whiteColor);
    max-width: var(--max-width);
    border-radius: 2px;
    z-index: var(--z-index-above);
    box-shadow: 0 1px 6px 0 rgba(0 0 0 / 0.24);
    overflow: auto;
  }
}

.ProductSuggestions__dropdown--expanded {
  display: block;
  opacity: 1;
  transform: scale(1);
}

.ProductSuggestions__title {
  @mixin typographyDesktopStrongMedium;

  margin-block-end: var(--spacing-xs);
}

.ProductSuggestions__resultList {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);

  @media (--laptop) {
    gap: 0;
    margin: 0;
  }
}

.ProductSuggestions__result {
  width: 100%;
  margin-inline: auto;
  position: relative;

  &:hover {
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      inset: calc(var(--spacing-xs) * -1) calc(var(--spacing-s) * -1);
      background: var(--grayLight80);
      z-index: var(--z-index-below);

      @media (--laptop) {
        inset: calc(var(--spacing-xxxs) * -1) calc((var(--spacing-xxs) + var(--spacing-xxxs)) * -1);
      }
    }
  }
}

.ProductSuggestions__resultContent {
  padding: 0;
  width: 100%;
  display: grid;
  grid-template: repeat(3, auto) / 4rem 1fr;
  grid-template-areas: "picture name" "picture label" "picture tagline";
  gap: 0 var(--spacing-xs);
  align-items: center;
  @mixin focusVisible var(--outlineColorSecondary);

  @media (--laptop) {
    padding: var(--spacing-xxs) 0;
  }

  & picture {
    grid-area: picture;
  }
}

.ProductSuggestions__name {
  grid-area: name;
  align-self: end;
  @mixin typographyDesktopStrongMedium;
}

.ProductSuggestions__labels {
  grid-area: label;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xxxs);
  padding-block: var(--spacing-xxxs);

  &:empty {
    padding-block: 0;
  }
}

.ProductSuggestions__tagline {
  grid-area: tagline;
  align-self: start;
  @mixin typographyDesktopDefaultXSmall;
}
