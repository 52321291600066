.JobsTabs__tabsWrapper {
  @mixin wrapper var(--wrapperWidth), 0;

  margin-block-start: var(--spacing-m);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: var(--kog_aqua_tint_3);
    z-index: var(--z-index-base);
  }
}

.JobsTabs__hoverIndicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: var(--kog_aqua_tint_3);
  z-index: var(--z-index-base);
  pointer-events: none;
  transition: all 0.25s;
  transition-property: transform, background;
}

.JobsTabs__tabs {
  width: 100%;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  overflow: auto visible;

  @media (--tablet) {
    justify-content: left;
  }

  &:hover {
    .JobsTabs__hoverIndicator {
      background: var(--kog_aqua_tint_7);
    }
  }
}

.JobsTabs__tab {
  @mixin resetButtonStylesNoOverride;
  @mixin typographyMobileStrongLarge;
  @mixin focusVisible var(--outlineColorSecondary), 2px, -2px;

  height: 3rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-inline: var(--spacing-s);
  color: var(--kog_aqua_tint_6);
  transition: color 0.25s;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: var(--kog_aqua_tint_3);
    z-index: var(--z-index-base);
    pointer-events: none;

    @media (--hover-devices) {
      transition: background 0.25s;
    }
  }

  &:hover {
    color: var(--kog_aqua_tint_7);
  }

  &:last-child {
    padding-inline-end: 0;
  }

  @media (--tablet) {
    @mixin typographyDesktopStrongMedium;

    &:last-child {
      padding-inline-end: var(--spacing-s);
    }
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
  }
}

.JobsTabs__tab--active {
  color: var(--kog_aqua_tint_7);

  &::before {
    background: var(--kog_aqua_tint_7);
    z-index: var(--z-index-above);
  }
}

.JobsTabs__sectionWrapper--hidden {
  display: none;
}
