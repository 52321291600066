.EventCampaignPage__introduction {
  margin-block: var(--spacing-xxl);

  @media (--laptop) {
    margin-block: var(--spacing-xxxxl) var(--spacing-xxxxxxxl);
  }
}

.EventCampaignPage__introductionWrapper {
  @media (--tablet) {
    display: grid;
    grid-template-columns: 4fr 3fr;
    column-gap: 90px;
  }

  & h4 {
    @media (--mobileOnly) {
      @mixin typographyMobileStrongXXLarge;
    }
  }

  @mixin headings {
    margin-block: var(--spacing-xxl) var(--spacing-xs);

    @media (--tablet) {
      margin-block: var(--spacing-l) var(--spacing-xs);
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.EventCampaignPage__introductionContent {
  margin-block: var(--spacing-xxl);

  @media (--tablet) {
    margin-block: 0;
  }
}

.EventCampaignPage__introductionSideColumnContainer {
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    justify-content: center;
    align-items: flex-end;
    max-width: 350px;
  }
}

.EventCampaignPage__introductionSideImage {
  margin: 0;

  & img {
    aspect-ratio: 1 / 1;
    width: 100%;

    @media (--tablet) {
      aspect-ratio: auto;
    }
  }
}

.EventCampaignPage__introductionSideImageCaption {
  @mixin typographyDesktopDefaultXSmall;

  margin-top: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
}
