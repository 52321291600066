.SideTextHotSpotsImage__Description {
  position: absolute;
  top: 50px;
  background: var(--whiteColor);
  max-height: calc(100% - 100px);
  width: 30%;
  padding: 80px 25px 50px;
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.5s;

  @media (--tabletOnly) {
    width: calc(100% - 200px);
  }

  @media (--mobileOnly) {
    display: none;
  }

  & a {
    color: var(--kog_aqua);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.SideTextHotpotsImage__DescriptionInner {
  padding: var(--spacing-xxs) var(--spacing-s);
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 0.2);
  }

  &::-webkit-scrollbar {
    height: 0;
    width: 5px;
    transform: translateX(20px);
  }

  &::-webkit-scrollbar-track {
    background: rgb(0 0 0 / 0.1);
  }

  & img {
    position: initial;
    width: 100%;
    height: auto;
    z-index: auto;
  }
}

.SideTextHotSpotsImage__Description--left {
  left: 50px;

  @media (--tabletOnly) {
    left: 100px;
    right: 100px;
  }
}

.SideTextHotSpotsImage__Description--leftHidden {
  left: -50%;

  @media (--tabletOnly) {
    left: -100%;
    right: 200%;
  }
}

.SideTextHotSpotsImage__Description--right {
  right: 50px;

  @media (--tabletOnly) {
    left: 100px;
    right: 100px;
  }
}

.SideTextHotSpotsImage__Description--rightHidden {
  right: -50%;

  @media (--tabletOnly) {
    right: -100%;
    left: 200%;
  }
}

.SideTextHotSpotsImage__DescriptionCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  font-family: var(--fontFamilyPrimary);
  font-size: 1.25rem;
  cursor: pointer;
  margin: 25px;
  background: transparent;
  border: none;

  &:focus {
    border: 1px solid var(--textColor);
  }
}

.SideTextHotSpotsImage__DescriptionLinkArrow {
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 30px;
  background: var(--whiteColor) svg-load("Icons/ArrowRightLong.svg", fill: var(--kog_red));
  transform: rotate(-45deg);
  transition: all linear 0.3s;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 0);
    transition: all 0.3s ease;
  }
}

.SideTextHotSpotsImage__DescriptionLink {
  font-size: 1.4rem;
  margin-top: 40px;
  display: flex;
  align-items: center;
  color: var(--textColor);

  &:hover {
    & .SideTextHotSpotsImage__DescriptionLinkArrow {
      &::after {
        background: rgb(0 0 0 / 0.2);
      }
    }
  }

  @media (--mobileOnly) {
    margin-bottom: 20px;
  }
}

.SideTextHotSpotsImage__DescriptionLinkText {
  color: var(--textColor);
}
