.HeroV2--withHeadings {
  & .HeroV2__headingContainer {
    @mixin backgroundOverlay var(--blackColor), 33%;

    position: absolute;
    z-index: 1;
    color: var(--whiteColor);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  & .HeroV2__headings {
    @mixin wrapper;

    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (--tablet) {
      bottom: 80px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    & .HeroV2__superHeadingText {
      font-size: 1.25rem;
      line-height: 1.625rem;
      font-weight: 400;
      position: relative;
      z-index: 8;

      @media (--tablet) {
        font-size: 1.625rem;
        line-height: 2.25rem;
      }
    }

    & > h1 {
      @mixin h2;

      display: flex;
      align-items: flex-end;
      position: relative;
      z-index: 8;
      width: 100%;
      padding: 0;
      margin-block: calc(var(--spacing-xxxs) + calc(var(--spacing-xxxs)));
      max-width: var(--hero-heading-max-width);

      & .HeroV2__headingText {
        @mixin lineClamp 5;
      }
    }
  }

  & :has(.HeroV2__photoCreditContainer) .HeroV2__headings {
    @media (--mobileOnly) {
      bottom: 62px;
    }
  }

  & .HeroV2__subheadingText {
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: 400;
    position: relative;
    z-index: 8;

    @media (--tablet) {
      font-size: 1.625rem;
      line-height: 2.25rem;
    }
  }

  .HeroV2__buttons {
    z-index: 10;
    display: flex;
    flex-flow: row wrap;
    gap: var(--spacing-xs);

    /* buttons full width for mobile */
    width: 100%;

    & > div {
      width: 100%;

      & .CallToActionButton {
        width: 100%;
      }
    }

    @media (--tablet) {
      width: auto;
      flex-wrap: unset;
    }
  }
}
