@define-mixin intro {
  @mixin pLarge;

  @media (--laptop) {
    @mixin h7Desktop;
    margin-top: 0;
  }
}

.Intro {
  @mixin intro;
  margin-block: var(--introMargin);
  
  @media (--laptop) {
    margin-block: var(--introMarginLaptop);
  }

  &:empty {
    margin: 0;
  }

  & > p {
    @mixin intro;
  }
}

.Title {
  font-size: 26px;
  line-height: 1.14;
  margin: 2rem 0;

  @media (--tablet) {
    font-size: 42px;
    margin: 3rem 0;
  }
  
  @media (--laptop) {
    font-size: 56px;
  }

  &.withHero {
    margin: 0 0 1.4rem 0;

    @media (--tablet) {
      margin: 0 0 3rem 0;
    }
  }
}

.SubTitle {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.14;
  text-transform: none;

  @media (--tablet) {
    font-size: 42px;
  }
}

.Image {
  margin: 1rem 0 2rem;

  @media (--tablet) {
    margin: 1rem 0 4.5rem;
  }
}
