/* stylelint-disable declaration-property-unit-allowed-list */
@define-mixin articlesArchiveFiltersChip {
  font-size: 14px;
  line-height: 1.2;
}

@define-mixin contentCardWideDate {
  font-size: 14px;
}

@define-mixin campaingIntro {
  margin: 0;
  text-align: left;

  & p {
    font-size: 18px;
    font-weight: 300;

    @media (--tablet) {
      font-size: 20px;
    }

    @media (--laptop) {
      font-size: 24px;
    }
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & strong {
    font-weight: 500;
  }
}

@define-mixin storyContentWrapper {
  margin: 0 30px;

  @media (--laptop) {
    width: 100%;
    margin: 0 auto;
    max-width: 890px;
  }
}

@define-mixin typography $fontSize, $lineHeight, $fontWeight, $marginBlock: 0 {
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  margin-block: $marginBlock;
}

/* Design System: Desktop/H0 */
@define-mixin h0Desktop {
  @mixin typography var(--h0-desktop-font-size), var(--h0-desktop-line-height),
    var(--h0-desktop-font-weight), var(--h0-desktop-margin-block);
}

/* Design System: Mobile/H1 */
@define-mixin h1Mobile {
  @mixin typography var(--h1-mobile-font-size), var(--h1-mobile-line-height),
    var(--h1-mobile-font-weight), var(--h1-mobile-margin-block);
}

/* Design System: Desktop/H1 */
@define-mixin h1Desktop {
  @mixin typography var(--h1-desktop-font-size), var(--h1-desktop-line-height),
    var(--h1-desktop-font-weight), var(--h1-desktop-margin-block);
}

@define-mixin h1 {
  @mixin typography 35px, 1.14, var(--h1-mobile-font-weight), var(--h1-mobile-margin-block);

  @media (--tablet) {
    font-size: 50px;
    margin-block: var(--spacing-l);
  }

  @media (--laptop) {
    font-size: 60px;
  }
}

/* Design System: Mobile/H2 */
@define-mixin h2Mobile {
  @mixin typography var(--h2-mobile-font-size), var(--h2-mobile-line-height),
    var(--h2-mobile-font-weight), var(--h2-mobile-margin-block);
}

/* Design System: Desktop/H2 */
@define-mixin h2Desktop {
  @mixin typography var(--h2-desktop-font-size), var(--h2-desktop-line-height),
    var(--h2-desktop-font-weight), var(--h2-desktop-margin-block);
}

@define-mixin h2 {
  @mixin typography 30px, 1.14, var(--h2-mobile-font-weight), var(--h2-mobile-margin-block);

  @media (--tablet) {
    font-size: 40px;
    margin-bottom: var(--spacing-m);
  }

  @media (--laptop) {
    font-size: 45px;
  }
}

/* Design System: Desktop/H3 */
@define-mixin h3Desktop {
  @mixin typography var(--h3-desktop-font-size), var(--h3-desktop-line-height),
    var(--h3-desktop-font-weight), var(--h3-desktop-margin-block);
}

@define-mixin h3 {
  font-size: 26px;
  line-height: 1.14;
  font-weight: 600;
  margin-block: var(--spacing-xs) var(--spacing-m);

  @media (--tablet) {
    font-size: 30px;
  }

  @media (--laptop) {
    font-size: 35px;
  }
}

/* Design System: Desktop/H4 */
@define-mixin h4Desktop {
  @mixin typography var(--h4-desktop-font-size), var(--h4-desktop-line-height),
    var(--h4-desktop-font-weight), var(--h4-desktop-margin-block);
}

@define-mixin h4 {
  font-size: 22px;
  line-height: 1.36;
  font-weight: 600;
  margin-block: var(--spacing-xs) var(--spacing-xxs);

  @media (--tablet) {
    font-size: 24px;
    line-height: 1.33;
  }

  @media (--laptop) {
    font-size: 26px;
    line-height: 1.31;
  }
}

/* Design System: Desktop/H5 */
@define-mixin h5Desktop {
  @mixin typography var(--h5-desktop-font-size), var(--h5-desktop-line-height),
    var(--h5-desktop-font-weight), var(--h5-desktop-margin-block);
}

@define-mixin h5 {
  font-size: 21px;
  line-height: 1.3;
  font-weight: 600;
  margin-block: var(--spacing-xs) var(--spacing-xxs);
}

/* Design System: Desktop/H6 */
@define-mixin h6Desktop {
  @mixin typography var(--h6-desktop-font-size), var(--h6-desktop-line-height),
    var(--h6-desktop-font-weight), var(--h6-desktop-margin-block);
}

@define-mixin h6 {
  font-size: 21px;
  line-height: 1.3;
  font-weight: 600;
  margin-block: var(--spacing-xs) var(--spacing-xxs);
}

/* Design System: Mobile/H7 */
@define-mixin h7Mobile {
  @mixin typography var(--h7-mobile-font-size), var(--h7-mobile-line-height),
    var(--h7-mobile-font-weight), var(--h7-mobile-margin-block);
}

/* Design System: Desktop/H7 */
@define-mixin h7Desktop {
  @mixin typography var(--h7-desktop-font-size), var(--h7-desktop-line-height),
    var(--h7-desktop-font-weight), var(--h7-desktop-margin-block);
}

@define-mixin h7 {
  @mixin h7Mobile;

  @media (--tablet) {
    font-size: 22px;
  }

  @media (--laptop) {
    @mixin h7Desktop;
  }
}

/* Design System: Desktop/H8 */
@define-mixin h8Desktop {
  @mixin typography var(--h8-desktop-font-size), var(--h8-desktop-line-height),
    var(--h8-desktop-font-weight), var(--h8-desktop-margin-block);
}

@define-mixin h8 {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  margin-block: var(--spacing-xxs);

  @media (--tablet) {
    font-size: 18px;
  }

  @media (--laptop) {
    @mixin h8Desktop;
  }
}

/* Design System: Desktop/H8-High */
@define-mixin h8HighDesktop {
  @mixin typography var(--h8-high-desktop-font-size), var(--h8-high-desktop-line-height),
    var(--h8-high-desktop-font-weight), var(--h8-high-desktop-margin-block);
}

@define-mixin h8High {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin-block: var(--spacing-xxs);

  @media (--tablet) {
    font-size: 18px;
  }

  @media (--laptop) {
    @mixin h8HighDesktop;
  }
}

/* Design System: Desktop/H10 */
@define-mixin h10Desktop {
  @mixin typography var(--h10-desktop-font-size), var(--h10-desktop-line-height),
    var(--h10-desktop-font-weight), var(--h10-desktop-margin-block);
}

@define-mixin h10 {
  font-size: 14px;
  line-height: 1.9;
  font-weight: 600;
  margin-block: var(--spacing-xxxs);

  @media (--tablet) {
    @mixin h10Desktop;
  }
}

/* Design System: Desktop/Paragraph/Large 17 */
@define-mixin p {
  font-size: 16px;
  line-height: 1.53;
  margin-block: 0 var(--spacing-s);

  @media (--tablet) {
    font-size: 17px;
  }
}

/* Design System: Desktop/Paragraph/Bold */
@define-mixin pBold {
  @mixin p;

  font-weight: 600;
}

/* Design System: Desktop/Paragraph/Large 20 */
@define-mixin pLarge {
  font-size: 20px;
  line-height: 1.5;
  margin-block: 0 var(--spacing-m);
}

/* Design System: Desktop/Paragraph/Small */
@define-mixin pSmall {
  font-size: 16px;
  line-height: 1.5;
  margin-block: 0 var(--spacing-xs);
}

/* Design System: Desktop/Paragraph/Extra Small */
@define-mixin pExtraSmall {
  font-size: 15px;
  line-height: 1.5;
  margin-block: 0 var(--spacing-xs);
}

/* Design System: Desktop/Paragraph/Quote */
@define-mixin blockquote {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  margin-block: 0;
}

/* Design System: Button/Button */
@define-mixin button {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

/* Design System: Button/TextButton15 */
@define-mixin textButton {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
}

@define-mixin textButtonLink {
  @mixin textButton;

  &:hover {
    @mixin textButtonLinkUnderline;
  }
}

/* Design System: Button/TextButton22 */
@define-mixin textButtonLarge {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
}

@define-mixin textButtonLargeLink {
  @mixin textButtonLarge;

  &:hover {
    @mixin textButtonLinkUnderline;
  }
}

@define-mixin textButtonLinkUnderline $color: currentcolor, $thickness: 3px, $offset: 3px {
  text-decoration: underline;
  text-underline-offset: $offset;
  text-decoration-skip-ink: none;
  text-decoration-thickness: $thickness;
  text-decoration-color: $color;
}

@define-mixin resetTextButtonLinkUnderline {
  text-decoration: initial;
  text-underline-offset: initial;
  text-decoration-skip-ink: initial;
  text-decoration-thickness: initial;
  text-decoration-color: initial;
}

@define-mixin ingress {
  font-family: var(--font-family-stories-primary);
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 1.4rem;

  @media (--tablet) {
    font-size: 18px;
    line-height: 1.56;
    margin: 0 0 1.8rem;
  }
}

@define-mixin ingressAlternative {
  @mixin ingress;

  font-family: var(--font-family-stories-secondary);
}

/* Typography (Discovery) */
@define-mixin typographyMobileDefaultUnderlinedXSmall {
  @mixin typography var(--mobile-default-x-small-font-size),
    var(--mobile-default-x-small-line-height), var(--mobile-default-x-small-font-weight);

  text-decoration: underline;
  text-underline-offset: 2px;
}

/* Discovery Design System: Mobile/Display/Large */
@define-mixin typographyMobileDisplayLarge {
  @mixin typography var(--mobile-display-large-font-size), var(--mobile-display-large-line-height),
    var(--mobile-display-large-font-weight);

  letter-spacing: var(--mobile-display-large-letter-spacing);
}

/* Discovery Design System: Mobile/Display/XLarge */
@define-mixin typographyMobileDisplayXLarge {
  @mixin typography var(--mobile-display-x-large-font-size), var(--mobile-display-x-large-line-height),
    var(--mobile-display-x-large-font-weight);

  letter-spacing: var(--mobile-display-x-large-letter-spacing);
}

/* Discovery Design System: Mobile/Default/XSmall */
@define-mixin typographyMobileDefaultXSmall {
  @mixin typography var(--mobile-default-x-small-font-size),
    var(--mobile-default-x-small-line-height), var(--mobile-default-x-small-font-weight);
}

/* Discovery Design System: Mobile/Default/Small */
@define-mixin typographyMobileDefaultSmall {
  @mixin typography var(--mobile-default-small-font-size), var(--mobile-default-small-line-height),
    var(--mobile-default-small-font-weight);
}

/* Discovery Design System: Mobile/Default/Medium */
@define-mixin typographyMobileDefaultMedium {
  @mixin typography var(--mobile-default-medium-font-size), var(--mobile-default-medium-line-height),
    var(--mobile-default-medium-font-weight);
}

/* Discovery Design System: Mobile/Default/Large */
@define-mixin typographyMobileDefaultLarge {
  @mixin typography var(--mobile-default-large-font-size), var(--mobile-default-large-line-height),
    var(--mobile-default-large-font-weight);
}

/* Discovery Design System: Mobile/Default/XLarge */
@define-mixin typographyMobileDefaultXLarge {
  @mixin typography var(--mobile-default-x-large-font-size),
    var(--mobile-default-x-large-line-height), var(--mobile-default-x-large-font-weight);
}

/* Discovery Design System: Mobile/Default/XXLarge */
@define-mixin typographyMobileDefaultXXLarge {
  @mixin typography var(--mobile-default-xx-large-font-size),
    var(--mobile-default-xx-large-line-height), var(--mobile-default-xx-large-font-weight);
}

/* Discovery Design System: Mobile/Strong/XSmall */
@define-mixin typographyMobileStrongXSmall {
  @mixin typography var(--mobile-strong-x-small-font-size), var(--mobile-strong-x-small-line-height),
    var(--mobile-strong-x-small-font-weight);
}

/* Discovery Design System: Mobile/Strong/Small */
@define-mixin typographyMobileStrongSmall {
  @mixin typography var(--mobile-strong-small-font-size), var(--mobile-strong-small-line-height),
    var(--mobile-strong-small-font-weight);
}

/* Discovery Design System: Mobile/Strong/Small */
@define-mixin typographyMobileStrongSmall {
  @mixin typography var(--mobile-strong-small-font-size), var(--mobile-strong-small-line-height),
    var(--mobile-strong-small-font-weight);
}

/* Discovery Design System: Mobile/Strong/Medium */
@define-mixin typographyMobileStrongMedium {
  @mixin typography var(--mobile-strong-medium-font-size), var(--mobile-strong-medium-line-height),
    var(--mobile-strong-medium-font-weight);
}

/* Discovery Design System: Mobile/Strong/Large */
@define-mixin typographyMobileStrongLarge {
  @mixin typography var(--mobile-strong-large-font-size), var(--mobile-strong-large-line-height),
    var(--mobile-strong-large-font-weight);
}

/* Discovery Design System: Mobile/Strong/X-Large */
@define-mixin typographyMobileStrongXLarge {
  @mixin typography var(--mobile-strong-x-large-font-size), var(--mobile-strong-x-large-line-height),
    var(--mobile-strong-x-large-font-weight);
}

/* Discovery Design System: Mobile/Strong/XX-Large */
@define-mixin typographyMobileStrongXXLarge {
  @mixin typography var(--mobile-strong-xx-large-font-size),
    var(--mobile-strong-xx-large-line-height), var(--mobile-strong-xx-large-font-weight);
}

@define-mixin typographyMobileCapsDefault {
  @mixin typography var(--mobile-caps-default-font-size), var(--mobile-caps-default-line-height),
    var(--mobile-caps-default-font-weight);

  letter-spacing: var(--mobile-caps-default-letter-spacing);
  text-transform: uppercase;
}

@define-mixin typographyMobileCapsLarge {
  @mixin typography var(--mobile-caps-large-font-size), var(--mobile-caps-large-line-height),
    var(--mobile-caps-large-font-weight);

  letter-spacing: var(--mobile-caps-large-letter-spacing);
  text-transform: uppercase;
}

@define-mixin typographyDesktopDefaultUnderlinedLarge {
  @mixin typography var(--desktop-default-underlined-large-font-size),
    var(--desktop-default-underlined-large-line-height),
    var(--desktop-default-underlined-large-font-weight);

  letter-spacing: var(--desktop-default-underlined-large-letter-spacing);
  text-decoration: underline;
  text-underline-offset: 2px;
}

@define-mixin typographyDesktopDisplayLarge {
  @mixin typography var(--desktop-display-large-font-size), var(--desktop-display-large-line-height),
    var(--desktop-display-large-font-weight);

  letter-spacing: var(--desktop-display-large-letter-spacing);
}

@define-mixin typographyDesktopDisplayXLarge {
  @mixin typography var(--desktop-display-x-large-font-size), var(--desktop-display-x-large-line-height),
    var(--desktop-display-x-large-font-weight);

  letter-spacing: var(--desktop-display-x-large-letter-spacing);
}

@define-mixin typographyDesktopDefaultXSmall {
  @mixin typography var(--desktop-default-x-small-font-size),
    var(--desktop-default-x-small-line-height), var(--desktop-default-x-small-font-weight);
}

@define-mixin typographyDesktopDefaultSmall {
  @mixin typography var(--desktop-default-small-font-size), var(--desktop-default-small-line-height),
    var(--desktop-default-small-font-weight);
}

@define-mixin typographyDesktopDefaultMedium {
  @mixin typography var(--desktop-default-medium-font-size),
    var(--desktop-default-medium-line-height), var(--desktop-default-medium-font-weight);

  letter-spacing: var(--desktop-default-medium-letter-spacing);
}

@define-mixin typographyDesktopDefaultLarge {
  @mixin typography var(--desktop-default-large-font-size), var(--desktop-default-large-line-height),
    var(--desktop-default-large-font-weight);

  letter-spacing: var(--desktop-default-large-letter-spacing);
}

@define-mixin typographyDesktopDefaultXXLarge {
  @mixin typography var(--desktop-default-xx-large-font-size),
    var(--desktop-default-xx-large-line-height), var(--desktop-default-xx-large-font-weight);

  letter-spacing: var(--desktop-default-xx-large-letter-spacing);
}

@define-mixin typographyDesktopDefaultXLarge {
  @mixin typography var(--desktop-default-x-large-font-size),
    var(--desktop-default-x-large-line-height), var(--desktop-default-x-large-font-weight);

  letter-spacing: var(--desktop-default-x-large-letter-spacing);
}

@define-mixin typographyDesktopStrongXSmall {
  @mixin typography var(--desktop-strong-x-small-font-size),
    var(--desktop-strong-x-small-line-height), var(--desktop-strong-x-small-font-weight);
}

@define-mixin typographyDesktopStrongSmall {
  @mixin typography var(--desktop-strong-small-font-size), var(--desktop-strong-small-line-height),
    var(--desktop-strong-small-font-weight);
}

@define-mixin typographyDesktopStrongMedium {
  @mixin typography var(--desktop-strong-medium-font-size), var(--desktop-strong-medium-line-height),
    var(--desktop-strong-medium-font-weight);

  letter-spacing: var(--desktop-strong-medium-letter-spacing);
}

@define-mixin typographyDesktopStrongLarge {
  @mixin typography var(--desktop-strong-large-font-size), var(--desktop-strong-large-line-height),
    var(--desktop-strong-large-font-weight);

  letter-spacing: var(--desktop-strong-large-letter-spacing);
}

@define-mixin typographyDesktopStrongXLarge {
  @mixin typography var(--desktop-strong-x-large-font-size),
    var(--desktop-strong-x-large-line-height), var(--desktop-strong-x-large-font-weight);

  letter-spacing: var(--desktop-strong-x-large-letter-spacing);
}

@define-mixin typographyDesktopStrongXXLarge {
  @mixin typography var(--desktop-strong-xx-large-font-size),
    var(--desktop-strong-xx-large-line-height), var(--desktop-strong-xx-large-font-weight);

  letter-spacing: var(--desktop-strong-xx-large-letter-spacing);
}

@define-mixin typographyDesktopCapsDefault {
  @mixin typography var(--desktop-caps-default-font-size), var(--desktop-caps-default-line-height),
    var(--desktop-caps-default-font-weight);

  letter-spacing: var(--desktop-caps-default-letter-spacing);
  text-transform: uppercase;
}
