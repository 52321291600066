.NavSearchInput {
  display: flex;
  order: 1;
}

.NavSearchInput__form {
  display: flex;
  width: 100%;
}

.NavSearchInput__searchField {
  display: flex;
  width: 100%;
  gap: calc(0.75 * var(--spacing-xxs));
  align-items: center;
  margin: 0;

  @media (--laptop) {
    gap: 0;
  }

  & .SearchField__input {
    @media (--laptop) {
      width: 151px;
    }
  }
}
