@keyframes pulseRing {
  0% {
    transform: translate(-50%, -50%) scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulseRingClear {
  0% {
    transform: translate(-50%, -50%) scale(.5);
  }
  80%, 100% {
    border-width: 25px;
    opacity: 0;
  }
}

@keyframes pulseRingClearHD {
  0% {
    transform: translate(-50%, -50%) scale(.5);
  }
  80%, 100% {
    border-width: 35px;
    opacity: 0;
  }
}

@keyframes pulseDot {
  0% {
    transform: translate(-50%, -50%) scale(.8);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(.8);
  }
}

@define-mixin hotSpot $size: 32px, $baseColor: var(--whiteColor), $pulseColor: var(--kog_bronze), $borderColor: $baseColor {
  @mixin absoluteCenter;
  border: none;
  width: $size;
  height: $size;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    & .MicrositeHotSpot__pulseDot {
      animation-duration: 3s;
    }

    & .MicrositeHotSpot__pulseRing {
      opacity: 0;
    }
  }

  & .MicrositeHotSpot__pulseRing,
  & .MicrositeHotSpot__pulseDot {
    @mixin absoluteCenter;
    content: "";
    display: block;
    border-radius: 100%;
  }

  & .MicrositeHotSpot__pulseRing {
    width: 200%;
    height: 200%;
    background-color: $pulseColor;
    animation: pulseRing 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  & .MicrositeHotSpot__pulseDot {
    width: 100%;
    height: 100%;
    background-color: $baseColor;
    transform: translate(-50%, -50%);
    border: 1px solid $borderColor;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulseDot 2.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@define-mixin hotSpotClear $size, $color: var(--whiteColor) {
  @mixin hotSpot $size, transparent, $color, $color;

  & .MicrositeHotSpot__pulseRing {
    width: 200%;
    height: 200%;
    background: transparent;
    border: 10px solid $color;
    animation: pulseRingClear 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;

    @media (--desktop4k) {
      border-width: 30px;
      animation: pulseRingClearHD 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
  }
}
