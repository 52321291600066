@define-mixin hotspotPreviewText {
  @mixin typographyDesktopStrongLarge;

  text-transform: uppercase;
  color: var(--whiteColor);
  text-shadow: 0 2px 2px var(--blackColor);
  margin-block: 0;
  cursor: pointer;
  background: none;
  border: none;
}

.SideTextHotSpotVariantA__PreviewText {
  @mixin hotspotPreviewText;

  @media (--mobileOnly) {
    display: none;
  }
}

.SideTextHotSpotVariantA__PreviewText--mobile {
  display: none;
  @mixin hotspotPreviewText;

  &.SideTextHotSpotVariantA__PreviewText--leftDown {
    transform: translate(-100%, -20px);
  }

  &.SideTextHotSpotVariantA__PreviewText--rightDown {
    transform: translateY(-20px);
  }

  @media (--mobileOnly) {
    display: inline-block;
  }
}

.SideTextHotSpotVariantA__PreviewText--right {
  margin-left: 30px;

  @media (--mobileOnly) {
    margin-left: 30px;
  }
}

.SideTextHotSpotVariantA__PreviewText--left {
  margin-left: -30px;
  transform: translateX(-100%);

  @media (--mobileOnly) {
    margin-left: -20px;
  }
}

.SideTextHotSpotVariantA--inactive {
  opacity: 0.3;
  pointer-events: none;
}

.SideTextHotSpotVariantA__LineSvg {
  width: 29px;
  height: 57px;

  & #PointerExpand {
    transition: all linear 0.3s;
  }
}

.SideTextHotSpotVariantA__Container--inverted {
  transform: translateY(-100%);
  display: flex;
  flex-flow: column-reverse;
  transition: opacity linear 0.5s;
  position: absolute;
  cursor: pointer;

  & .SideTextHotSpotVariantA__PreviewText {
    margin-bottom: -20px;
  }

  & .SideTextHotSpotVariantA__PreviewText--mobile {
    margin-bottom: -20px;
  }

  &:hover .SideTextHotSpotVariantA__LineSquare::before {
    padding: 6px;
  }
}

.SideTextHotSpotVariantA__Container {
  transition: opacity linear 0.5s;
  position: absolute;
  cursor: pointer;

  & .SideTextHotSpotVariantA__PreviewText {
    position: absolute;
    width: max-content;
    margin-top: -24px;
  }

  &:hover .SideTextHotSpotVariantA__LineSquare::before {
    padding: 6px;
  }

  & .SideTextHotSpotVariantA__PreviewText--mobile {
    margin-top: -15px;
  }
}

.SideTextHotSpotVariantA__Container--inverted:hover,
.SideTextHotSpotVariantA__Container:hover {
  & .SideTextHotSpotVariantA__LineSvg #PointerExpand {
    /* stylelint-disable-next-line declaration-no-important */
    width: 14px !important;
    /* stylelint-disable-next-line declaration-no-important */
    height: 14px !important;
    /* stylelint-disable-next-line number-max-precision */
    transform: matrix(-0.707107, -0.707107, -0.707107, 0.707107, 20.75, 5);
  }
}

.SideTextHotSpotVariantA__LineSvg--leftUp {
  margin-left: -20px;

  @media (--tablet) {
    margin-left: -29px;
  }
}

.SideTextHotSpotVariantA__LineSvg--rightUp {
  transform: scaleX(-1);
}

.SideTextHotSpotVariantA__LineSvg--leftDown {
  margin-left: -20px;
  transform: scaleY(-1);

  @media (--tablet) {
    margin-left: -29px;
  }
}

.SideTextHotSpotVariantA__LineSvg--rightDown {
  transform: scale(-1);
}
