.MicrositeHero {
  min-height: 100vh;
  display: flex;
  position: relative;

  &:before {
    @mixin absoluteFullSize;
    display: block;
    content: "";
  }

  &.MicrositeHero--dark {
    &:before {
      @mixin backgroundGradient;
    }

    &.MicrositeHero--lowerOpacity {
      &:before {
        opacity: 0.4;
      }
    }
  }
}

.MicrositeHero__image {
  flex: 1;

  & img {
    width: 100%;
    height: 100%;
  }
}

.MicrositeHero__list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (--laptop) {
    display: block;
  }
}

.MicrositeHero__listItem {
  position: absolute;
}
