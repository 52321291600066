.RichFactsList {
  position: relative;
  padding: 1rem 0;
  overflow: hidden;
  color: var(--textColor);
  display: flex;
  align-items: center;

  & ul.cardsList__4col--featured > li {
    @media (--laptop) {
      margin-bottom: 0;
    }
  }

  @media (--laptop) {
    padding: 5rem 0;
  }

  & .LinkBlock__icon {
    @media (--laptop) {
      margin: 4rem 0 1.5rem;
    }
  }
}

.RichFactsList__wrapper {
  @mixin richCampaignWrapper;

  position: relative;
}

.RichFactsList__background {
  background-size: cover;
  display: block;
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  filter: blur(6px);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--whiteColor);
    opacity: 0.8;
  }
}

.RichFactsList__header {
  @mixin h5Desktop;

  margin: 0 0 1.4rem;

  @media (--tablet) {
    @mixin h1Mobile;

    margin: 2rem 0;
    min-height: 55px;
  }

  @media (--laptop) {
    @mixin h1Desktop;

    min-height: 77px;
    margin: 0 0 4rem;
  }

  & svg {
    max-height: 120px;
    max-width: 120px;
  }
}

.RichFactsList__bigText {
  @mixin h3;

  margin: 1.4rem 0;

  @media (--tablet) {
    margin: 2rem 0;
  }

  @media (--laptop) {
    @mixin h1Desktop;
  }
}
