.Testimonial--tile {
  --columns: 1.25rem auto; /* 20px auto */
  --gap: 0.625rem 1.375rem; /* 10px 22px */

  background-color: transparent;
  color: inherit;
  padding: 0;

  & .Testimonial__container {
    @mixin wrapper;
  }

  & .Testimonial__inner {
    display: grid;
    grid-auto-flow: row;
    gap: 40px 0;
    border-radius: 2px;
    padding: calc(var(--spacing-xxs) + var(--spacing-xxxs) + (var(--spacing-xxxs) / 2))
      calc(var(--spacing-xxs) + var(--spacing-xxxs)) calc(var(--spacing-xs) + var(--spacing-xxxs)); /* 14px 12px 20px */

    @media (--tablet) {
      grid-template-columns: var(--spacing-m) var(--spacing-s) 1fr;
      padding: var(--spacing-l) var(--spacing-s);
    }
  }

  & .Testimonial__quote {
    margin: 0;
    position: relative;

    @media (--tablet) {
      display: grid;
      grid-template-columns: var(--spacing-m) var(--spacing-s) 1fr;
      grid-auto-flow: column;
      grid-column: 1/4;
    }

    &::before {
      display: block;
      content: "“";
      @mixin typographyDesktopDisplayLarge;

      line-height: 2rem; /* 32px */
      margin-block: 0;

      @media (--tablet) {
        margin-block: 0.375rem 0; /* 6px 0 */
        @mixin typographyDesktopDisplayXLarge;

        font-size: 3.5rem;
        line-height: 0.9;
      }
    }

    & blockquote {
      @mixin typographyMobileStrongXLarge;

      &::before,
      &::after {
        content: unset;
      }

      @media (--tablet) {
        @mixin typographyDesktopStrongLarge;

        grid-column: 3/4;
      }
    }
  }

  & .Testimonial__author {
    flex-flow: column nowrap;
    align-self: initial;
    width: 100%;
    font-style: normal;
    font-size: inherit;

    @media (--tablet) {
      grid-column: 2/4;
      margin-inline-start: 0;
      margin-block-start: 0;
    }
  }

  & .Testimonial__authorName {
    @mixin typographyMobileStrongSmall;

    margin-inline: 0;

    @media (--tablet) {
      @mixin typographyDesktopStrongSmall;

      margin-block: 0;
    }
  }

  & .Testimonial__authorJobTitle {
    @mixin typographyMobileDefaultSmall;

    margin-block: 0;

    @media (--tablet) {
      @mixin typographyDesktopDefaultSmall;

      margin-block: 0;
    }
  }
}
