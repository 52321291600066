.QuoteSection {
    &.Quote {
        height: auto;
        min-height: 810px;
    }

    & .Quote__content {
        margin-top: unset;
    }

    & .Quote__background {
        background-attachment: unset;
    }

    & .QuoteBlock__richText {
        @mixin typographyMobileDisplayLarge;
    }

    @media (--tablet) {
        & .Quote__content {
            left: unset;
            transform: unset;
            margin-left: 14%;
        }

        & .QuoteBlock__richText {
            @mixin typographyDesktopDisplayLarge;
        }

        & .Quote__textWrapper {
            align-items: end;
        }
    }
}