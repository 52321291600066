.LinkBlock--withImage {
  max-width: unset;
  border-radius: 4px;
  border: 1px solid var(--base-grey-medium-100);
  background: var(--whiteColor);

  & .LinkBlock__titleButton {
    @mixin buttonDefaultSize;
    @mixin buttonBaseOutlined;

    margin: auto calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-xs);
    order: 1;
    width: calc(100% - var(--spacing-s));
    text-align: center;

    & > h3,
    & > span {
      @mixin typographyDesktopStrongMedium;

      margin: 0;
    }
  }

  & .LinkBlock__imageWrapper {
    overflow: hidden;
    aspect-ratio: 320 / 188;

    & img {
      aspect-ratio: 320 / 188;
      object-fit: cover;
      transition: transform 0.3s;
      will-change: transform;
    }
  }

  & > a {
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--base-grey-dark-100) !important;
    transition: color 0.2s;

    &:hover {
      /* stylelint-disable-next-line declaration-no-important */
      color: var(--base-grey-black-100) !important;

      & .LinkBlock__imageWrapper img {
        transform: scale(1.2);
      }

      & .LinkBlock__titleButton {
        border-color: var(--base-grey-dark-100);
        background: var(--base-grey-dark-100);

        & > h3,
        & > span {
          color: var(--whiteColor);
        }
      }
    }
  }

  & .LinkBlock__textContainer {
    gap: var(--spacing-xs);
    height: 100%;
  }

  & .LinkBlock__text {
    margin: 0 calc(var(--spacing-xxs) + var(--spacing-xxxs));

    &,
    & p {
      @mixin typographyMobileDefaultMedium;

      @media (--laptop) {
        @mixin typographyDesktopDefaultMedium;
      }
    }
  }
}
