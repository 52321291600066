/* stylelint-disable declaration-property-unit-allowed-list */
.Filters {
  background: color(var(--kog_blue_tint_5) a(50%));
  padding: 20px 0 10px;

  @media (--tablet) {
    padding: 40px 0 30px;
  }

  & input[type="text"] {
    border-color: var(--kog_blue_tint_1);
    background: var(--whiteColor);
    width: 100%;
    min-height: 50px;
    color: var(--kog_blue_tint_1);
    font-size: 16px;

    &:focus {
      box-shadow: 0 0 8px var(--kog_blue_tint_3);
      border-color: var(--kog_blue_tint_1);
      color: var(--kog_blue_tint_1);
    }

    &::placeholder {
      font-size: 16px;
      color: var(--kog_blue_tint_1);
      opacity: 1;
    }
  }

  &.Filters--sand {
    display: flex;
    align-items: initial;
    flex-direction: column;
    background-color: var(--kog_bronze_tint_2);

    @media (--laptop) {
      position: sticky;
      top: var(--header-height-with-breadcrumbs);
      z-index: 100;
    }

    @media (--laptop) {
      display: block;
      max-width: unset;
      width: unset;
    }

    & input[type="text"] {
      min-height: unset;
      height: 40px;
      border-bottom: 1px solid var(--base-grey-black-100);
      background: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: var(--grayColor_tint_5);
      }
    }
  }
}

.ItemList {
  margin-top: 0;

  @media (--laptop) {
    margin-top: 20px;
  }
}

.FiltersList {
  display: grid;
  grid-template-columns: 1fr;
  list-style-type: none;
  margin: 0 auto;
  max-width: 65rem;
  width: calc(100% - 60px);
  padding: 0;

  @media (--tablet) {
    grid-template-columns: 1fr;
    gap: 0 calc(var(--spacing-xs) + var(--spacing-xxxs));
  }

  @media (--laptop) {
    @mixin wrapper;

    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-items: flex-end;
  }

  & input[type="text"] {
    margin-top: 0;
  }
}

.FiltersList__item {
  margin-bottom: 20px;

  & > .Filter__label {
    @mixin typographyDesktopStrongLarge;

    margin-bottom: 10px;
    display: block;
  }
}

.FiltersList__tooltipWrapper {
  max-width: 1rem;
  padding-left: 1rem;
}

.FiltersList__item--share {
  min-height: 40px;
}

.ListingFilters__showFilters {
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
}

.Filter__select--label {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.ItemList__items {
  @media (--laptop) {
    @mixin wrapper;
  }
}

.FiltersList__tooltipTrigger {
  @mixin resetButtonStylesNoOverride;

  width: 100%;

  &:focus-visible > .ButtonOutlined--blue {
    color: var(--kog_blue);
    border-color: var(--kog_blue);
    background: var(--kog_blue_tint_8);
  }

  & > span {
    width: calc(100% - 48px);
  }
}

.FiltersList__resetBtn {
  padding: 0 !important;

  &:hover {
    background-color: unset !important;
  }
}