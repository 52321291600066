@define-mixin factsHeroThemeColorVariant $backgroundColor, $textColor, $buttonVariant {
  & .FactsHero {
    background-color: $backgroundColor;
    color: $textColor;
  }

  & .FactsHero__link {
    @mixin $(buttonVariant);
  }
}

.ContainerWithBackground--Beige {
  @mixin factsHeroThemeColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color), buttonPrimaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin factsHeroThemeColorVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color), buttonPrimaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin factsHeroThemeColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color), buttonPrimaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin factsHeroThemeColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color), buttonPrimaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin factsHeroThemeColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color), buttonPrimaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin factsHeroThemeColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color), buttonPrimaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin factsHeroThemeColorVariant var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color), buttonPrimaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin factsHeroThemeColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color), buttonPrimaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin factsHeroThemeColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color), buttonPrimaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin factsHeroThemeColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color), buttonPrimaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin factsHeroThemeColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color), buttonPrimaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin factsHeroThemeColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color), buttonPrimaryThemeColorForestTwo;
}

.ContainerWithBackground--GreenOne {
  @mixin factsHeroThemeColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color), buttonPrimaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin factsHeroThemeColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color), buttonPrimaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin factsHeroThemeColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color), buttonPrimaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin factsHeroThemeColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color), buttonPrimaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin factsHeroThemeColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color), buttonPrimaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin factsHeroThemeColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color), buttonPrimaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin factsHeroThemeColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color), buttonPrimaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin factsHeroThemeColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color), buttonPrimaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin factsHeroThemeColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color), buttonPrimaryThemeColorGreyFive;
}
