@define-mixin buttonLarge {
  font: inherit;
  overflow: visible;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.13;
  letter-spacing: normal;
  cursor: pointer;
  margin-bottom: 1rem;
  color: var(--whiteColor);
  transition: box-shadow 0.2s ease-out;
  background: var(--brandColor);
  border: none;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 22px;
  height: 50px;
  padding: 0 20px;

  @media (--tablet) {
    height: 70px;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 32px;
    padding: 0 60px;
    width: auto;
  }

  &:hover {
    @mixin hoverDarken;
  }
}
