:root {
  --storiesTextContentBgImageHeightMobile: 100vh;
  --storiesTextContentBgImageHeight: 100vh;
}

.StoryHeaderInfo {
  position: relative;
}

.StoryHeaderInfo__background {
  top: 0;
  left: 0;
  width: 100%;
  height: var(--storiesTextContentBgImageHeightMobile);
  max-height: 1400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  z-index: 0;
  backface-visibility: hidden;
  transition: opacity 0.2s ease;
  pointer-events: none;

  @media (--laptop) {
    height: var(--storiesTextContentBgImageHeight);
  }

  @media (--laptop) {
    height: 100%;
    max-height: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.6s ease-in-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.6s ease-in-out;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 0.5) 0%,
      rgb(0 0 0 / 0) 51%,
      rgb(0 0 0 / 0) 80%,
      rgb(0 0 0 / 0.3) 100%
    );
    pointer-events: none;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--storiesTextContentBgImageHeightMobile);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (--laptop) {
      height: var(--storiesTextContentBgImageHeight);
    }

    @media (--desktop) {
      height: 100vh;
    }
  }

  & .VideoFigure__VideoContainer {
    padding-top: 0;
  }
}

.StoryHeaderInfo__media {
  background-position: top 50px center;
  background-repeat: no-repeat;
  background-size: cover;
}

.StoryHeaderInfo__loader,
.StoryHeaderInfo__picture {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.StoryHeaderInfo__loader {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 140% 400%;
  filter: blur(7rem);
}

.StoryHeaderInfo__picture {
  & img {
    opacity: 0;
    animation: blurIn 0.6s ease 0s forwards;
  }
}

.StoryHeaderInfo__header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--laptop) {
    min-height: 100vh;
    justify-content: flex-end;
  }
}

.StoryHeaderInfo__logoWrapper {
  @mixin storyContentWrapper;

  flex: 1 0 auto;

  & .StoryHeaderInfo__logo {
    position: fixed;
    width: 160px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    transition: transform 1.1s ease;
    z-index: 1;

    @media (--tablet) {
      width: 200px;
      height: 120px;
    }

    @media (--laptop) {
      top: 0;
      width: 240px;
      height: 150px;
      margin-top: 40px;
    }

    @media (--desktop) {
      background-position: right center;
    }
  }
}

.StoryHeaderInfo__logo--hide {
  transform: translateY(-200%);
}

.StoryHeaderInfo__headerText {
  padding: 1.5rem 0 3.75rem;

  @media (--tablet) {
    padding: 3rem 0 6rem;
  }
}

.StoryHeaderInfo__textWrapper {
  @mixin storyContentWrapper;

  z-index: 1;
  position: relative;
}

.StoryHeaderInfo__title {
  max-width: 350px;
  margin: 1.5rem 0;
  font-family: var(--font-family-stories-secondary);
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 30px;
  line-height: 1.3;
  letter-spacing: 2px;

  @media (--tablet) {
    max-width: 550px;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 46px;
  }

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 60px;
    max-width: 80%;
    margin: 3rem 0 2.125rem;
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(-120px);
    }
  }
}

.StoryHeaderInfo__subTitle {
  margin: 0;
  font-family: var(--font-family-stories-secondary);
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 2px;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 18px;
  }

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 20px;
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(-190px);
    }
  }
}

.StoryHeaderInfo__breadcrumbs {
  &.animate {
    @media (--tablet) {
      transform: translateY(-120px);
    }
  }

  & .StoriesBreadcrumbs {
    border-bottom: none;

    & .wrapper {
      width: 100%;
    }

    & .StoriesBreadcrumbs__inner {
      @media (--laptop) {
        margin: 0;
      }
    }
  }
}

.StoryHeaderInfo__content {
  padding-bottom: 2rem;
  z-index: 1;
  position: relative;

  @media (--laptop) {
    padding-bottom: 4.3rem;
  }
}

.StoryHeaderInfo__contentWrapper {
  @mixin storyContentWrapper;
}

.StoryHeaderInfo__description {
  margin-bottom: 1.9rem;

  @media (--tablet) {
    margin-bottom: 2.5rem;
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(120px);
    }
  }

  & > p {
    font-size: 1.25rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;

    @media (--tablet) {
      font-size: 1.5rem;
      line-height: 1.9;
    }

    @media (--laptop) {
      margin-bottom: 2.5rem;
      font-size: 2rem;
      line-height: 1.9;
    }
  }

  & a {
    &:hover,
    &:visited {
      color: var(--brandColor);
    }
  }
}

.StoryHeaderInfo .StoryHeaderInfo__description a {
  &:hover {
    text-decoration: none;
  }
}

.StoryHeaderInfo__categories {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 -1.125rem 0.625rem;
  padding: 0;
  list-style: none;

  @media (--tablet) {
    padding: 0;
    margin: 0 -2.5rem 1rem;
  }

  & li:nth-child(1n).animate {
    @media (--tablet) {
      transform: translateY(60px);
    }
  }

  & li:nth-child(2n).animate {
    @media (--tablet) {
      transform: translateY(100px);
    }
  }

  & li:nth-child(3n).animate {
    @media (--tablet) {
      transform: translateY(140px);
    }
  }
}

.StoryHeaderInfo__people {
  margin: 0 0 0.825rem;
  padding: 0;

  @media (--tablet) {
    margin: 0 0 1.2rem;
  }
}

.StoryHeaderInfo__category {
  padding: 0 1.125rem 1.2rem;

  @media (--tablet) {
    padding: 0 2.25rem 2.2rem;
  }

  & svg {
    fill: var(--whiteColor);
    max-width: 48px;
    max-height: 45px;

    @media (--tablet) {
      max-width: 69px;
      max-height: 64px;
    }
  }
}

.StoryHeaderInfo__infoInner {
  &.animate {
    @media (--tablet) {
      transform: translateY(120px);
    }
  }
}

/* Specific styles for edit mode */
/* stylelint-disable-next-line selector-class-pattern */
.StoryHeaderInfo--edit-mode .StoryHeaderInfo__header {
  min-height: 900px;
}
