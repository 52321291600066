@define-mixin layoutColorVariant $fontColor {
  & .Layout {
    & .ImageWithCaption {
      & .ImageWithCaption__label {
        color: $fontColor;
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: none !important;

        @media (--tablet) {
          background: none;
        }
      }

      & .ImageWithCaption__labelInner {
        & a {
          color: $fontColor;
          @mixin textButtonLinkUnderline currentcolor, 2px;
        }
      }
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin layoutColorVariant var(--theme-color-beige-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin layoutColorVariant var(--theme-color-blue-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin layoutColorVariant var(--theme-color-blue-dark-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin layoutColorVariant var(--theme-color-brown-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin layoutColorVariant var(--theme-color-green-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin layoutColorVariant var(--theme-color-yellow-text-color);
}

.ContainerWithBackground--Pink {
  @mixin layoutColorVariant var(--theme-color-pink-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin layoutColorVariant var(--theme-color-turquoise-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin layoutColorVariant var(--theme-color-turquoise-dark-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin layoutColorVariant var(--theme-color-green-dark-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin layoutColorVariant var(--theme-color-sand-dark-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin layoutColorVariant var(--theme-color-forest-dark-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin layoutColorVariant var(--theme-color-green-one-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin layoutColorVariant var(--theme-color-green-five-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin layoutColorVariant var(--theme-color-forest-five-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin layoutColorVariant var(--theme-color-blue-three-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin layoutColorVariant var(--theme-color-yellow-two-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin layoutColorVariant var(--theme-color-yellow-five-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin layoutColorVariant var(--theme-color-red-four-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin layoutColorVariant var(--theme-color-grey-two-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin layoutColorVariant var(--theme-color-grey-five-text-color);
}
