.CurrentSoftwareVersion {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  gap: var(--spacing-m);

  @media (--tablet) {
    flex-direction: row;
    gap: calc(var(--spacing-xxxxxxxxl) - var(--spacing-xs));
  }
}

.CurrentSoftwareVersion__info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.CurrentSoftwareVersion__heading {
  @mixin typographyDesktopStrongXXLarge;
}

.CurrentSoftwareVersion__subheading {
  @mixin typographyDesktopStrongLarge;
}

.CurrentSoftwareVersion__body {
  @mixin typographyDesktopDefaultMedium;

  & > p {
    margin: 0;
  }
}

.CurrentSoftwareVersion__versionWrapper {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CurrentSoftwareVersion__version {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: var(--spacing-s);
  width: 440px;
  max-width: 100%;

  @media (--tablet) {
    flex-direction: row;
  }
}

.CurrentSoftwareVersion__versionNumberHeading {
  @mixin typographyDesktopStrongSmall;
}

.CurrentSoftwareVersion__versionNumber {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) 0;
  border-bottom: 1px solid #dddee0;

  @media (--tablet) {
    border-right: 1px solid #dddee0;
    border-bottom: none;
    gap: var(--spacing-s);
  }
}

.CurrentSoftwareVersion__versionNumberValue {
  @mixin typographyDesktopStrongXXLarge;
}

.CurrentSoftwareVersion__releaseNotes {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs);
  padding: var(--spacing-xs) 0;
}

.CurrentSoftwareVersion__downloadReleaseNotes > a,
.CurrentSoftwareVersion__seeAllReleaseNotes > a {
  color: currentColor;
}

.CurrentSoftwareVersion__downloadButtons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);

  @media (--mobileOnly) {
    flex-direction: column;
    align-items: center;

    & a {
      /* stylelint-disable-next-line declaration-no-important */
      width: 100% !important;
      justify-content: center;
      text-align: center;
    }
  }
}

.CurrentSoftwareVersion__downloadUpgrade {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 8px 10px 8px 16px !important;
}

.CurrentSoftwareVersion__registeredDealer {
  padding: 24px 0;
  border-top: 1px solid var(--grayColor_tint_4);
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.CurrentSoftwareVersion__registeredDealerBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  flex-basis: 50%;
}

.CurrentSoftwareVersion__registeredDealerHeading {
  @mixin typographyDesktopStrongLarge;
}

.CurrentSoftwareVersion__registeredDealerBodyText {
  @mixin typographyDesktopDefaultSmall;
}

.ButtonMobile--noPaddingLeft {
  @media (--mobileOnly) {
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: 0 !important;
  }
}
