@define-mixin whiteTheme {
  & h1,
  & h2,
  & h3,
  & div {
    color: var(--whiteColor);

    & a,
    & p a,
    & p a:hover,
    & p a:visited,
    & p span {
      color: var(--whiteColor);
    }

    & svg path {
      fill: var(--whiteColor);
    }
  }

  .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar white;
  }
}

.SlideBackgroundColor--none {
  background-color: var(--whiteColor);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar var(--textColor);
  }
}

.SlideBackgroundColor--gray {
  background-color: color(var(--brandColor_dark_tint_5) a(50%));

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar var(--textColor);
  }
}

.SlideBackgroundColor--aqua {
  background-color: var(--kog_aqua);
  @mixin whiteTheme;
}

.SlideBackgroundColor--blue {
  background-color: var(--kog_blue);
  @mixin whiteTheme;
}

.SlideBackgroundColor--red {
  background-color: var(--kog_red);
  @mixin whiteTheme;
}

.SlideBackgroundColor--brown {
  background-color: var(--kog_bronze_tint_5);
  @mixin whiteTheme;
}

.SlideBackgroundColor--light,
.SlideBackgroundColor--dark {
  & h1,
  & h2,
  & h3,
  & div {
    & p a {
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.SlideBackgroundColor--dark,
.SlideBackgroundColor--aqua.SlideBackgroundColor--dark {
  & h1,
  & h2,
  & h3,
  & div {
    color: var(--whiteColor);

    /* stylelint-disable-next-line no-descending-specificity */
    & a,
    & p a,
    & p a:hover,
    & p a:visited,
    & p span {
      color: var(--whiteColor);
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & svg path {
      fill: var(--whiteColor);
    }
  }
}

.SlideBackgroundColor--dark {
  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar white;
  }
}

.SlideBackgroundColor--light {
  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--Default {
  background-color: var(--whiteColor);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--BlueFive {
  background-color: var(--kog_blue_tint_7);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--SandFour {
  background-color: var(--kog_bronze_tint_1);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--TealTwo {
  background-color: var(--kog_aqua_tint_6);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--TealFive {
  background-color: var(--kog_aqua_tint_1);
}

.SlideBackgroundColor--YellowFour {
  background-color: var(--kog_yellow);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--GreenTwo {
  background-color: var(--kog_green_tint_3);
  @mixin whiteTheme;
}

.SlideBackgroundColor--BlueTwo {
  background-color: var(--kog_blue_tint_1);
  @mixin whiteTheme;
}

.SlideBackgroundColor--SandTwo {
  background-color: var(--kog_bronze_tint_6);
  @mixin whiteTheme;
}

.SlideBackgroundColor--ForestTwo {
  background-color: var(--kog_forest_tint_3);
  @mixin whiteTheme;
}

.SlideBackgroundColor--GreenFour {
  background-color: var(--kog_green_tint_2);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--White {
  background-color: var(--whiteColor);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--Black {
  background-color: var(--textColor);
  @mixin whiteTheme;
}

.SlideBackgroundColor--GreenOne {
  background-color: var(--kog_green_tint_4);
  @mixin whiteTheme;
}

.SlideBackgroundColor--GreenFive {
  background-color: var(--kog_green_tint_1);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--ForestFive {
  background-color: var(--kog_forest_tint_1);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--BlueThree {
  background-color: var(--kog_blue_tint_9);
  @mixin whiteTheme;
}

.SlideBackgroundColor--YellowTwo {
  background-color: var(--kog_yellow_tint_3);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--YellowFive {
  background-color: var(--kog_yellow_tint_1);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--RedFour {
  background-color: var(--kog_red_tint_1);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}

.SlideBackgroundColor--GreyTwo {
  background-color: var(--grayColor_tint_6);
  @mixin whiteTheme;
}

.SlideBackgroundColor--GreyFive {
  background-color: var(--grayColor_tint_2);

  & .SlidesNavigation__innerScroll {
    @mixin richCampaignScrollBar black;
  }
}
