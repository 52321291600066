.SearchHit__title {
    font-size: 20px;
    
    @media (--tablet) {
        font-size: 32px;
    }
}

.SearchHit {
    & a {
        color: var(--brandColor);
    }
}