@define-mixin buttonPrimary {
  @mixin button;

  background: var(--themeTertiaryColor);
  display: inline-block;
  min-width: 145px;
  padding: var(--spacing-xxs) var(--spacing-s);
  text-align: center;
  transition: background-color 0.2s ease-out;
  letter-spacing: normal;
  color: var(--whiteColor);
  cursor: pointer;
  border: 2px solid transparent;
  outline: none;

  &:hover {
    background: var(--blackColor);
  }

  &:active {
    background: var(--themeTertiaryColor_tint_4);
  }

  &:disabled {
    background: var(--grayColor_tint_4);
  }

  &:focus {
    outline: var(--outlineColorTertiary_tint_1) solid 1px;
    box-shadow: 0 0 0 2px var(--outlineColorTertiary);
  }
}

@define-mixin buttonPrimaryColorVariant $color, $activeColor, $fontColor: var(--whiteColor),
  $fontActiveColor: var(--whiteColor), $hoverBehaviour: "darken" {
  /* hoverBehaviour: "darken" | "lighten" */
  background-color: $color;
  color: $fontColor;
  transition: all 0.2s;

  &:hover {
    @if ($hoverBehaviour == "darken") {
      box-shadow: inset 0 0 0 6000px rgb(0 0 0 / 0.1); /* button gets a little bit darker */
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    } @else  {
      box-shadow: inset 0 0 0 6000px rgb(255 255 255 / 0.2); /* button gets a little bit lighter */
    }
  }

  &:active {
    color: $fontActiveColor;
    background: $activeColor;
  }
}

@define-mixin buttonPrimaryBlue {
  @mixin buttonPrimaryColorVariant var(--kog_blue_tint_9), var(--kog_blue);
}

@define-mixin buttonPrimaryGreen {
  @mixin buttonPrimaryColorVariant var(--kog_green_tint_3), var(--kog_green_tint_4);
}

@define-mixin buttonPrimaryYellow {
  @mixin buttonPrimaryColorVariant var(--kog_yellow_tint_3), var(--kog_yellow_tint_4);
}

@define-mixin buttonPrimaryBronze {
  @mixin buttonPrimaryColorVariant var(--kog_bronze_tint_6), var(--kog_bronze_tint_7);
}

@define-mixin buttonPrimaryAqua {
  @mixin buttonPrimaryColorVariant var(--kog_aqua_tint_3), var(--kog_aqua_tint_7), var(--blackColor),
    var(--blackColor);
}

/* theme colors */
@define-mixin buttonPrimaryThemeColorBeige {
  @mixin buttonPrimaryColorVariant var(--theme-color-beige-button-primary-bg-color),
    var(--theme-color-beige-button-primary-active-bg-color),
    var(--theme-color-beige-button-primary-text-color),
    var(--theme-color-beige-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorGreenFour {
  @mixin buttonPrimaryColorVariant var(--theme-color-green-button-primary-bg-color),
    var(--theme-color-green-button-primary-active-bg-color),
    var(--theme-color-green-button-primary-text-color),
    var(--theme-color-green-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorBlueFive {
  @mixin buttonPrimaryColorVariant var(--theme-color-blue-button-primary-bg-color),
    var(--theme-color-blue-button-primary-active-bg-color),
    var(--theme-color-blue-button-primary-text-color),
    var(--theme-color-blue-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorTealTwo {
  @mixin buttonPrimaryColorVariant var(--theme-color-turquoise-dark-button-primary-bg-color),
    var(--theme-color-turquoise-dark-button-primary-active-bg-color),
    var(--theme-color-turquoise-dark-button-primary-text-color),
    var(--theme-color-turquoise-dark-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorSandFour {
  @mixin buttonPrimaryColorVariant var(--theme-color-brown-button-primary-bg-color),
    var(--theme-color-brown-button-primary-active-bg-color),
    var(--theme-color-brown-button-primary-text-color),
    var(--theme-color-brown-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorTealFive {
  @mixin buttonPrimaryColorVariant var(--theme-color-turquoise-button-primary-bg-color),
    var(--theme-color-turquoise-button-primary-active-bg-color),
    var(--theme-color-turquoise-button-primary-text-color),
    var(--theme-color-turquoise-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorYellowFour {
  @mixin buttonPrimaryColorVariant var(--theme-color-yellow-button-primary-bg-color),
    var(--theme-color-yellow-button-primary-active-bg-color),
    var(--theme-color-yellow-button-primary-text-color),
    var(--theme-color-yellow-button-primary-active-text-color);
}

@define-mixin buttonPrimaryYellowFour {
  @mixin buttonPrimaryColorVariant var(--kog_yellow_tint_3), var(--kog_yellow_tint_2),
    var(--theme-color-yellow-button-primary-text-color),
    var(--theme-color-yellow-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorGreenTwo {
  @mixin buttonPrimaryColorVariant var(--theme-color-green-dark-button-primary-bg-color),
    var(--theme-color-green-dark-button-primary-active-bg-color),
    var(--theme-color-green-dark-button-primary-text-color),
    var(--theme-color-green-dark-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorBlueTwo {
  @mixin buttonPrimaryColorVariant var(--theme-color-blue-dark-button-primary-bg-color),
    var(--theme-color-blue-dark-button-primary-active-bg-color),
    var(--theme-color-blue-dark-button-primary-text-color),
    var(--theme-color-blue-dark-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorSandTwo {
  @mixin buttonPrimaryColorVariant var(--theme-color-sand-dark-button-primary-bg-color),
    var(--theme-color-sand-dark-button-primary-active-bg-color),
    var(--theme-color-sand-dark-button-primary-text-color),
    var(--theme-color-sand-dark-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorForestTwo {
  @mixin buttonPrimaryColorVariant var(--theme-color-forest-dark-button-primary-bg-color),
    var(--theme-color-forest-dark-button-primary-active-bg-color),
    var(--theme-color-forest-dark-button-primary-text-color),
    var(--theme-color-forest-dark-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorPink {
  @mixin buttonPrimaryColorVariant var(--theme-color-pink-button-primary-bg-color),
    var(--theme-color-pink-button-primary-active-bg-color),
    var(--theme-color-pink-button-primary-text-color),
    var(--theme-color-pink-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorGreenOne {
  @mixin buttonPrimaryColorVariant var(--theme-color-green-one-button-primary-bg-color),
    var(--theme-color-green-one-button-primary-active-bg-color),
    var(--theme-color-green-one-button-primary-text-color),
    var(--theme-color-green-one-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorGreenFive {
  @mixin buttonPrimaryColorVariant var(--theme-color-green-five-button-primary-bg-color),
    var(--theme-color-green-five-button-primary-active-bg-color),
    var(--theme-color-green-five-button-primary-text-color),
    var(--theme-color-green-five-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorForestFive {
  @mixin buttonPrimaryColorVariant var(--theme-color-forest-five-button-primary-bg-color),
    var(--theme-color-forest-five-button-primary-active-bg-color),
    var(--theme-color-forest-five-button-primary-text-color),
    var(--theme-color-forest-five-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorBlueThree {
  @mixin buttonPrimaryColorVariant var(--theme-color-blue-three-button-primary-bg-color),
    var(--theme-color-blue-three-button-primary-active-bg-color),
    var(--theme-color-blue-three-button-primary-text-color),
    var(--theme-color-blue-three-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorYellowTwo {
  @mixin buttonPrimaryColorVariant var(--theme-color-yellow-two-button-primary-bg-color),
    var(--theme-color-yellow-two-button-primary-active-bg-color),
    var(--theme-color-yellow-two-button-primary-text-color),
    var(--theme-color-yellow-two-button-primary-active-text-color);
}

@define-mixin buttonPrimaryYellowTwo {
  @mixin buttonPrimaryColorVariant var(--kog_yellow_tint_2), var(--kog_yellow), var(--blackColor),
    var(--blackColor);
}

@define-mixin buttonPrimaryThemeColorYellowFive {
  @mixin buttonPrimaryColorVariant var(--theme-color-yellow-five-button-primary-bg-color),
    var(--theme-color-yellow-five-button-primary-active-bg-color),
    var(--theme-color-yellow-five-button-primary-text-color),
    var(--theme-color-yellow-five-button-primary-active-text-color);
}

@define-mixin buttonPrimaryYellowFive {
  @mixin buttonPrimaryColorVariant var(--kog_yellow_tint_4), var(--kog_yellow), var(--blackColor),
    var(--blackColor);
}

@define-mixin buttonPrimaryThemeColorRedFour {
  @mixin buttonPrimaryColorVariant var(--theme-color-red-four-button-primary-bg-color),
    var(--theme-color-red-four-button-primary-active-bg-color),
    var(--theme-color-red-four-button-primary-text-color),
    var(--theme-color-red-four-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorGreyTwo {
  @mixin buttonPrimaryColorVariant var(--theme-color-grey-two-button-primary-bg-color),
    var(--theme-color-grey-two-button-primary-active-bg-color),
    var(--theme-color-grey-two-button-primary-text-color),
    var(--theme-color-grey-two-button-primary-active-text-color), "lighten";
}

@define-mixin buttonPrimaryGreyTwo {
  @mixin buttonPrimaryColorVariant var(--grayColor_tint_2), var(--grayColor_tint_4),
    var(--blackColor), var(--blackColor), "darken";
}

@define-mixin buttonPrimaryThemeColorGreyFive {
  @mixin buttonPrimaryColorVariant var(--theme-color-grey-five-button-primary-bg-color),
    var(--theme-color-grey-five-button-primary-active-bg-color),
    var(--theme-color-grey-five-button-primary-text-color),
    var(--theme-color-grey-five-button-primary-active-text-color);
}

@define-mixin buttonPrimaryThemeColorWhite {
  @mixin buttonPrimaryColorVariant var(--whiteColor), var(--base-grey-light-100), var(--blackColor),
    var(--blackColor), "darken";
}

@define-mixin buttonPrimaryThemeColorBlack {
  @mixin buttonPrimaryColorVariant var(--base-grey-black-100), var(--grayColor_tint_3),
    var(--whiteColor), var(--whiteColor), "lighten";
}
