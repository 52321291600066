.PromotedContent {
  min-height: 507px;

  @media (--tablet) {
    min-height: 560px;
  }

  @media (--desktop) {
    min-height: 651px;
  }
}

.PromotedContent__content {
  max-width: 618px;
  
  & > a {
    @mixin buttonDefaultSize;
    @mixin buttonBaseOutlinedDarkMode;

    @media (--mobileOnly) {
      @mixin buttonFullWidth;
    }

    margin-top: var(--spacing-m);
  }
}

.PromotedContent__title {
  @mixin typographyMobileDisplayLarge;

  margin-bottom: var(--spacing-xxs);

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    margin-bottom: var(--spacing-xxs);
  }
}

.PromotedContent__description {
  @mixin typographyMobileDefaultXLarge;

  max-width: 460px;

  @media (--tablet) {
    @mixin typographyDesktopDefaultXLarge;
  }
}
