.VesselTypePage {
  & aside {
    position: relative;
  }

  & .ContactCard {
    margin-top: initial;
    position: relative;
    margin-bottom: 3em;
  }

  & .ContentAreaSection {
    & *[id] {
      scroll-margin-top: var(--sections-navbar-scroll-margin);
    }
  }
}

.VesselTypePage__relatedArticle {
  & .ArticleTeaser {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.VesselTypePage__body {
  padding-top: var(--spacing-s);

  @media (--laptop) {
    padding-top: var(--spacing-xxl);
  }
}

.VesselTypePage__introduction {
  margin-block: var(--spacing-m);

  @media (--laptop) {
    margin-block: var(--spacing-xxxxl);
  }
}

.VesselTypePage__introductionBody {
  &,
  & p {
    @mixin typographyDesktopDefaultLarge;

    color: var(--base-grey-black-100);
    margin-block: 0 var(--spacing-xs);
  }

  & a {
    @mixin typographyDesktopStrongSmall;
    @mixin arrowLinkRightMedium var(--kog_blue_tint_1);

    color: var(--kog_blue_tint_1);
    margin-top: var(--spacing-m);
    display: flex;
    gap: 10px;
    align-items: center;
    width: max-content;

    &::after {
      margin-top: calc(1rem + 2px);
    }
  }
}


.VesselTypePage__solutions {
  & .Section__title {
    @mixin typographyDesktopDisplayLarge;

    margin-block: 0 var(--spacing-s);
  }

  & .Section__description {
    @mixin typographyDesktopDefaultLarge;

    margin-block: 0 var(--spacing-s);
  }

  & .HeroProductCatalog {
    @mixin resetWrapper;
  }
}
