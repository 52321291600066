.LatestNews {
  @mixin wrapper;
  
  & .ContentCard--standard {
    margin-bottom: var(--spacing-xs);
    
    @media (--laptop) {
      margin-bottom: 0;
    }
  }
}

.LatestNews__list {
  margin-bottom: var(--spacing-m);
  
  @media (--laptop) {
    margin-bottom: var(--spacing-xxl);
  }
}

.LatestNews .ContentCard a,
.LatestNews .ContentCard a:visited {
  color: var(--textColor);
  text-decoration: none;
  
  &:hover {
    color: var(--textColor);
    text-decoration: none;
  }
}

.LatestNews .ContentCard--featured a:focus {
  outline-color: var(--outlineColorSecondary); 
}
