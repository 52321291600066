@define-mixin storyProductButtonColorVariant $secondaryBtnVariant {
  & .StoryProduct__button {
    @mixin $(secondaryBtnVariant);
  }
}

.StoryProduct {
  &.StoryProduct--buttonColorGreyTwo {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorGreyTwo;
  }

  &.StoryProduct--buttonColorGreenFour {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorGreenFour;
  }

  &.StoryProduct--buttonColorBlueFive {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorBlueFive;
  }

  &.StoryProduct--buttonColorTealTwo {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorTealTwo;
  }

  &.StoryProduct--buttonColorSandFour {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorSandFour;
  }

  &.StoryProduct--buttonColorTealFive {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorTealFive;
  }

  &.StoryProduct--buttonColorYellowFour {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorYellowFour;
  }

  &.StoryProduct--buttonColorGreenTwo {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorGreenTwo;
  }

  &.StoryProduct--buttonColorBlueTwo {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorBlueTwo;
  }

  &.StoryProduct--buttonColorSandTwo {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorSandTwo;
  }

  &.StoryProduct--buttonColorForestTwo {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorForestTwo;
  }

  &.StoryProduct--buttonColorGreenOne {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorGreenOne;
  }

  &.StoryProduct--buttonColorGreenFive {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorGreenFive;
  }

  &.StoryProduct--buttonColorForestFive {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorForestFive;
  }

  &.StoryProduct--buttonColorBlueThree {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorBlueThree;
  }

  &.StoryProduct--buttonColorYellowTwo {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorYellowTwo;
  }

  &.StoryProduct--buttonColorYellowFive {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorYellowFive;
  }

  &.StoryProduct--buttonColorRedFour {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorRedFour;
  }

  &.StoryProduct--buttonColorGreyFive {
    @mixin storyProductButtonColorVariant buttonSecondaryThemeColorGreyFive;
  }
}
