.RichProduct {
  height: 100%;
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  flex-direction: column;

  @media (--tablet) {
    text-align: left;
  }

  & .SlidesNavigation__innerScroll {
    @media (--laptop) {
      max-height: 60vh;
    }
  }
}

.RichProduct__wrapper {
  @mixin richCampaignWrapper;

  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (--tablet) {
    text-align: left;
  }
}

.RichProduct__Text {
  font-weight: 400;

  @media (--laptop) {
    max-width: 35%;
  }
}

.RichProduct__Image {
  width: 100%;

  @media (--laptop) {
    max-height: 60vh;
  }

  & img {
    width: 100%;
    overflow: visible;
    object-fit: contain;

    @media (--laptop) {
      height: 100%;
    }
  }
}