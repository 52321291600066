.RichOverlayImage--editMode,
.RichOverlayImage--previewMode {
  &.RichOverlayImage__mediaPositionTop,
  &.RichOverlayImage__mediaPositionBottom {
    & .RichOverlayImage__inner {
      @media (--laptop) {
        height: 55%;
      }
    }
  }

  &.RichOverlayImage__mediaPositionTop {
    & .RichOverlayImage__inner {
      @media (--laptop) {
        margin-top: 0;
      }
    }
  }
}
