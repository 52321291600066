.RichProduct__ImageLeft {
  & .RichProduct__wrapper {
    @media (--laptop) {
      flex-direction: row-reverse;
      justify-content: end;
    }
  }

  & .RichProduct__Text {
    margin-top: auto;
    margin-bottom: auto;

    @media (--laptop) {
      margin-left: 5rem;
    }
  }
}

.RichProduct__ImageRight {
  & .RichProduct__wrapper {
    @media (--laptop) {
      flex-direction: row;
    }
  }

  & .RichProduct__Text {
    margin: auto 0.5rem;

    @media (--laptop) {
      margin: auto 5rem auto 0;
    }
  }
}

.RichProduct__ImageLeft,
.RichProduct__ImageRight {
  & .RichProduct__Image {
    margin-left: auto;
    margin-right: auto;

    @media (--laptop) {
      margin: auto 0 auto 40px;
    }

    @media (--laptop) {
      margin: 0 0 0 40px;
    }
  }

  & .RichProduct__Text {
    & div {
      @mixin p;
    }

    & h2 {
      @mixin h2;
    }
  }
}

.RichProduct__ImageTop {
  & .RichProduct__wrapper {
    flex-direction: column-reverse;
  }

  & .RichProduct__Image {
    margin: 0 auto 40px;
    width: 100%;

    @media (--laptop) {
      height: 40vh;
    }
  }

  & .RichProduct__Text {
    max-width: none;

    & p {
      @mixin p;
    }

    & h2 {
      @mixin h2;
    }

    @media (--laptop) {
      width: 50%;
      margin: auto;
    }
  }
}

.RichProduct__ImageBottom {
  & .RichProduct__wrapper {
    flex-direction: column;
  }

  & .RichProduct__Image {
    margin: 40px auto 0;
    width: 100%;

    @media (--laptop) {
      height: 40vh;
    }
  }

  & .RichProduct__Text {
    max-width: none;

    & p {
      @mixin p;
    }

    & h2 {
      @mixin h2;
    }

    @media (--laptop) {
      width: 50%;
      margin: auto;
    }
  }
}

.RichProduct__ImageTop,
.RichProduct__ImageBottom {
  & .SlidesNavigation__innerScroll {
    @media (--laptop) {
      max-height: 35vh;
    }
  }
}
