.DiscoveriesListing__jobList {
  --columns: 1;

  padding-inline-start: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: var(--spacing-xs);

  @media (--mobileSmall) {
    --columns: 2;
  }

  @media (--laptop) {
    --columns: 3;

    gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  }
}

.DiscoveriesListing__loadMore {
  padding-block: var(--spacing-m);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-s);
}

.DiscoveriesListing__loadMoreInfo {
  @mixin typographyDesktopDefaultSmall;
}

.DiscoveriesListing__selectedDiscoveryHeading {
  @mixin typographyDesktopStrongXLarge;

  margin-bottom: var(--spacing-xxs);
}

.DiscoveriesListing__selectedDiscoveryIntro {
  @mixin typographyDesktopDefaultLarge;

  & h4, & h5, & h6 {
    @mixin typographyDesktopStrongLarge;

    margin-bottom: var(--spacing-xxs);
  }
}
