.MicrositeProductHomeButton {
  @mixin resetButtonStyles;
  @mixin sectionPageCloseButtonPosition 212px, 144px, 0;
  left: 16px;
  transition: background .3s ease-in-out;
  border-radius: 100%;
  cursor: pointer;
  width: 64px;
  height: 64px;
  position: absolute;
  z-index: 5;

  @media (--tablet) {
    left: unset;
  }

  @media (--desktop4k) {
    right: 240px;
  }

  &.MicrositeProductHomeButton--dark {
    & .MicrositeProduct__buttonOutline {
      @mixin circleOutline;
      background: svg-load("Icons/MicrositeHome.svg", fill: var(--whiteColor)) no-repeat;
      background-position: 50%;
    }

    &.MicrositeProductHomeButton--solid {
      background: var(--whiteColor);
      
      & .MicrositeProduct__buttonOutline {
        background: svg-load("Icons/MicrositeHome.svg", fill: var(--blackColor)) no-repeat;
        background-position: 50%;
      } 
    }
  }

  &.MicrositeProductHomeButton--light {
    & .MicrositeProduct__buttonOutline {
      @mixin circleOutline var(--blackColor);
      background: svg-load("Icons/MicrositeHome.svg", fill: var(--blackColor)) no-repeat;
      background-position: 50%;
    }

    &.MicrositeProductHomeButton--solid {
      background: var(--blackColor);

      & .MicrositeProduct__buttonOutline {
        background: svg-load("Icons/MicrositeHome.svg", fill: var(--whiteColor)) no-repeat;
        background-position: 50%;
      } 
    }
  }
}
