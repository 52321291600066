@define-mixin storyCtaColorVariant $backgroundColor, $fontColor, $primaryBtnVariant {
  & .StoryCallToAction__title {
    color: $fontColor;
  }

  & .StoryCallToAction__body {
    & > * {
      color: $fontColor;
    }
  }

  & .StoryCallToAction__overlay::after {
    background-color: $backgroundColor;
  }

  & .StoryCallToAction__button {
    @mixin $(primaryBtnVariant);
  }
}

.StoryCallToAction {
  &.StoryCallToAction--backgroundColorDefault,
  &.StoryCallToAction--backgroundColorGreyTwo {
    @mixin storyCtaColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color), buttonPrimaryThemeColorGreyTwo;
  }

  &.StoryCallToAction--backgroundColorGreenFour {
    @mixin storyCtaColorVariant var(--theme-color-green-bg-color), var(--theme-color-green-text-color),
      buttonPrimaryThemeColorGreenFour;
  }

  &.StoryCallToAction--backgroundColorBlueFive {
    @mixin storyCtaColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color),
      buttonPrimaryThemeColorBlueFive;
  }

  &.StoryCallToAction--backgroundColorTealTwo {
    @mixin storyCtaColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color), buttonPrimaryThemeColorTealTwo;
  }

  &.StoryCallToAction--backgroundColorSandFour {
    @mixin storyCtaColorVariant var(--theme-color-brown-bg-color), var(--theme-color-brown-text-color),
      buttonPrimaryThemeColorSandFour;
  }

  &.StoryCallToAction--backgroundColorTealFive {
    @mixin storyCtaColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color), buttonPrimaryThemeColorTealFive;
  }

  &.StoryCallToAction--backgroundColorYellowFour {
    @mixin storyCtaColorVariant var(--theme-color-yellow-bg-color), var(--theme-color-yellow-text-color),
      buttonPrimaryThemeColorYellowFour;
  }

  &.StoryCallToAction--backgroundColorGreenTwo {
    @mixin storyCtaColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color), buttonPrimaryThemeColorGreenTwo;
  }

  &.StoryCallToAction--backgroundColorBlueTwo {
    @mixin storyCtaColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color), buttonPrimaryThemeColorBlueTwo;
  }

  &.StoryCallToAction--backgroundColorSandTwo {
    @mixin storyCtaColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color), buttonPrimaryThemeColorSandTwo;
  }

  &.StoryCallToAction--backgroundColorForestTwo {
    @mixin storyCtaColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color), buttonPrimaryThemeColorForestTwo;
  }

  &.StoryCallToAction--backgroundColorGreenOne {
    @mixin storyCtaColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color), buttonPrimaryThemeColorGreenOne;
  }

  &.StoryCallToAction--backgroundColorGreenFive {
    @mixin storyCtaColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color), buttonPrimaryThemeColorGreenFive;
  }

  &.StoryCallToAction--backgroundColorForestFive {
    @mixin storyCtaColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color), buttonPrimaryThemeColorForestFive;
  }

  &.StoryCallToAction--backgroundColorBlueThree {
    @mixin storyCtaColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color), buttonPrimaryThemeColorBlueThree;
  }

  &.StoryCallToAction--backgroundColorYellowTwo {
    @mixin storyCtaColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color), buttonPrimaryThemeColorYellowTwo;
  }

  &.StoryCallToAction--backgroundColorYellowFive {
    @mixin storyCtaColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color), buttonPrimaryThemeColorYellowFive;
  }

  &.StoryCallToAction--backgroundColorRedFour {
    @mixin storyCtaColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color), buttonPrimaryThemeColorRedFour;
  }

  &.StoryCallToAction--backgroundColorGreyFive {
    @mixin storyCtaColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color), buttonPrimaryThemeColorGreyFive;
  }
}
