@font-face {
  font-display: swap;
  font-family: 'GTPressuraMono';
  src: url('../Fonts/GTPressuraMono-Regular.kog.woff2') format('woff2'),
  url('../Fonts/GTPressuraMono-Regular.kog.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'GTPressuraMono';
  src: url('../Fonts/GTPressuraMono-Light.kog.woff2') format('woff2'),
  url('../Fonts/GTPressuraMono-Light.kog.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'GTPressuraMono';
  src: url('../Fonts/GTPressuraMono-Bold.kog.woff2') format('woff2'),
  url('../Fonts/GTPressuraMono-Bold.kog.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'GTPressura';
  src: url('../Fonts/GTPressura-Light.kog.woff2') format('woff2'),
  url('../Fonts/GTPressura-Light.kog.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'GTPressura';
  src: url('../Fonts/GTPressura-Regular.kog.woff2') format('woff2'),
  url('../Fonts/GTPressura-Regular.kog.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'GTPressura';
  src: url('../Fonts/GTPressura-Bold.kog.woff2') format('woff2'),
  url('../Fonts/GTPressura-Bold.kog.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
