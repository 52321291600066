.DepartmentContact {
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
}

.DepartmentContact__title {
  @mixin h6Desktop;

  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media (--tablet) {
    align-items: flex-start;
  }

  @media (--laptop) {
    @mixin h5Desktop;
  }
}