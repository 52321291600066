.OfficeLocationsList {
  order: 1;
  @mixin kogTabletColumn 5;

  @media (--tablet) {
    display: flex;
    flex-direction: column;
    aspect-ratio: 422 / 610;
    overflow: hidden;
  }
}

.OfficeLocationsList__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  order: 3;
  gap: 0.75rem; /* 12px */

  @media (--tablet) {
    overflow-y: auto;
    gap: var(--spacing-xs);
  }
}

.OfficeLocationsList__noOffices {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  justify-content: center;
  align-items: center;
  padding: var(--spacing-l) var(--spacing-m);
  border: 2px solid var(--base-grey-light-100);
  border-radius: 2px;
}

.OfficeLocationsList__noOfficesTitle {
  @mixin typographyDesktopStrongSmall;
}

.OfficeLocationsList__noOfficesDescription {
  @mixin typographyDesktopDefaultSmall;
}

.OfficeLocationsList__noOfficesTitle,
.OfficeLocationsList__noOfficesDescription {
  text-align: center;
  line-height: 1;
}

.OfficeLocationsList__nearestOfficesTitle {
  @mixin typographyDesktopStrongLarge;
  margin-block: var(--spacing-xs);
}
