.RichtextBlock {
  width: 100%;
  @mixin blockSpacing;
  
  & .RequireMargins {
    @mixin blockSpacing;
  }
  
  & .CallToAction {
    margin-bottom: 2rem;
    
    @media (--tablet) {
      margin-bottom: 4rem;
    }
    
    @media (--laptop) {
      margin-bottom: 6rem;
    }
  }
  
  & > .FactBox {
    @mixin wrapper;
    padding: 1.4rem 1rem;
    
    @media (--tablet) {
      padding: 2.3rem 2rem;
    }    
    
    @media (--laptop) {
      padding: 3rem;
    }
    
    & .FactBox__title {
      font-size: 24px;
      margin: 0 0 1.2rem;

      @media (--tablet) {
        font-size: 32px;
      }
    }
    
    & .FactBox__richText {
      @media (--tablet) {
        column-count: 2;
      }
    }
  }

  & > .TextBlock {
    @mixin wrapper;

    @media (--laptop) {
      padding-left: 0;
      max-width: var(--wrapperWidth);
      padding-right: var(--sidebarWidth);
    }
  }

  &:has(.RichVideo.RichVideo--small) {
    @media (--laptop) {
      margin-block: calc(var(--spacing-xxxxl) - var(--spacing-xxxs)) calc(var(--spacing-xxl) - var(--spacing-xxxs));
    }
  }
}
