.FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_3), var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color), buttonPrimaryThemeColorGreyTwo,
    buttonSecondaryThemeColorGreyTwo, var(--theme-color-grey-two-button-primary-text-color),
    var(--theme-color-grey-two-button-primary-active-text-color);

  & a {
    text-decoration: underline;
  }

  & .Form__Element.FormSubmitButton,
  & .Form__Element.FormResetButton {
    box-shadow: none;
  }
}
