.ButtonPrimary {
  @mixin buttonPrimary;

  &.ButtonPrimary--blue {
    @mixin buttonPrimaryBlue;
  }

  &.ButtonPrimary--green {
    @mixin buttonPrimaryGreen;
  }

  &.ButtonPrimary--yellow {
    @mixin buttonPrimaryYellow;
  }

  &.ButtonPrimary--bronze {
    @mixin buttonPrimaryBronze;
  }
  
  &.ButtonPrimary--aqua {
    @mixin buttonPrimaryAqua;
  }
}

.ButtonSecondary {
  @mixin buttonSecondary;

  &.ButtonSecondary--bronze {
    @mixin buttonSecondaryBronze;
  }

  &.ButtonSecondary--aqua {
    @mixin buttonSecondaryAqua;
  }

  &.ButtonSecondary--green {
    @mixin buttonSecondaryGreen;
  }
}
