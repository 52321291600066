.StoriesHeaderPage__content .RequireMargins {
  @mixin blockSpacing;
}

.StoriesCategoryPage__breadcrumbs {
  & .StoriesBreadcrumbs__inner {
    margin: 0;
  }
}

.StoriesHeaderPage__content + div {
  box-shadow: 0 -2px 0 0 #fff;
}
