.FactBox {
  padding: 1.9rem;
  min-height: 230px;
  position: relative;
  max-width: 100%;
  flex-grow: 1;
  height: 100%;

  @media (--tablet) {
    max-width: 420px;
    min-height: 330px;
  }

  @media (--laptop) {
    min-height: 400px;
  }
}

.FactBox__richText {
  & p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  & ul {
    padding: 0;
    list-style-type: none;
  }
  
  &:has(p) ul {
    margin-block-start: -1rem;
  }

  & li {
    font-size: 1rem;
    line-height: 2;
    padding: 0 0 0 24px;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      position: absolute;
    }
  }

  & a {
    font-weight: 400;
    text-decoration: none;
    word-wrap: break-word;
  }

  & a:visited {
    font-weight: 400;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
}

.FactBox--navy {
  background: var(--brandColor_dark_tint_1);
}
