.Tooltip {
  --max-width: none;

  background-color: var(--accent-blue-accent-default);
  color: var(--base-grey-light-60);
  border-radius: 4px;
  padding: calc(var(--spacing-xxxs) + calc(var(--spacing-xxxs) / 2)) var(--spacing-xxs);
  max-width: var(--max-width);

  /* TODO: use custom variable on top that can be overriden with var from variables.css as falback */
  z-index: var(--z-index-tooltip);

  @media (--tablet) {
    padding: var(--spacing-xxs) calc(var(--spacing-xxs) + calc(var(--spacing-xxxs) / 2));
  }
}

.Tooltip__content {
  @mixin typographyMobileDefaultMedium;

  @media (--tablet) {
    @mixin typographyDesktopDefaultMedium;
  }
}

.Tooltip__arrow {
  color: var(--accent-blue-accent-default);
}
