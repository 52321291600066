.react-tabs {
  padding: 1.8rem 0 0 0;

  @media (--tablet) {
    padding: 3rem 0 0 0;
  }
}

.react-tabs__tab-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;

  @media (--tablet) {
    flex-flow: row nowrap;
  }
}

.react-tabs__tab {
  display: inline-flex;
  position: relative;
  list-style: none;
  cursor: pointer;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;

  @media (--tablet) {
    margin-bottom: 0;
    width: auto;
    max-width: 365px;
    margin-right: 1.5rem;
  }

  &:last-of-type {
    margin-right: 0;
  }

  & span {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    background: color(var(--whiteColor) a(40%));
    padding: 1rem 1rem 0.5rem 1rem;
    flex-grow: 1;
    justify-content: center;
    text-align: center;

    @media (--tablet) {
      font-size: 24px;
      background: color(var(--whiteColor) a(65%));
    }

    @media (--laptop) {
      font-size: 28px;
    }
  }
}

_:-ms-fullscreen, :root .react-tabs__tab span {
  padding: 1.3rem 1rem 0.5rem 1rem;
}

.react-tabs__tab--selected {
  color: black;
  background: var(--whiteColor);

  & span {
    background: white;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% + 0.8rem);
      width: calc(100% - 1rem);
      background-color: white;
      z-index: -1;
      padding: 0 0.5rem;
      display: none;

      @media (--tablet) {
        display: block;
      }
    }
  }
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: flex;
  background: white;
  margin-top: 0.8rem;
  padding: 3rem 0;
  min-height: 400px;
}
