.ContactCard--sidebar {
  min-height: 355px;
  min-width: 200px;
  width: 100%;
  flex-grow: 1;
  flex-flow: column;

  @media (--tablet) {
    max-width: 360px;
  }

  & .ContactCard__inner {
    padding: 180px 25px 30px 25px;
    position: relative;

    @media (--tablet) {
      padding: 245px 25px 40px 25px;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: 0;
      height: 103px;
      width: 100%;
      background-image: svg-load('Grid/grid-item-white.svg');
      background-size: 34.4px 34.4px;
      background-position: left 0 bottom 0;
    }

    @media (--tablet) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 160px;
        width: 100%;
        background-size: 40px 40px;
        background-position: left 0 top 0;
      }
    }

    &::after {
      background-position: bottom right;
    }
  }
  
  & .ContactCard__info {
    flex: none;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  
  & .ContactCard__picture {
    top: 10px;

    @media (--tablet) {
      top: 20px;
    }
  }
  
  & .ContactCardLink_wrapper {
    width: 100%;
    margin-top: 1rem;
    min-height: 50px;

    @media(--tablet) {
      margin-top: 2rem;
      min-height: 60px;
    }
    
    & .ContactCard__link {
        display: inline-block;
        text-align: center;
        padding: 11px;
        height: auto;
        width: auto;
      }
    }
  
}