.ImageHotSpots__TextContainer {
  @mixin wrapper;
}

.ImageHotSpots__Title {
  @mixin typographyMobileDisplayLarge;

  margin-block: var(--spacing-m);

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;

    margin-block: var(--spacing-l);
  }
}

.ImageHotSpots__Container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 56.25%;

  & img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
  }
}

.ImageHotSpots__Container--limited {
  max-width: 100%;
  width: calc(100% - 30px);
  margin: 40px auto 0;
  position: initial;
  padding: 0;

  & .SideTextHotSpotsImage__Description {
    width: 50%;
  }

  & .ImageHotSpots__ContainerInner {
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    height: 100%;
  }
}
