.InfoMosaic {
  width: 100%;
}

.InfoMosaic__titleWrapper {
  margin-bottom: calc(var(--spacing-s) + var(--spacing-xxxs));

  @media (--tablet) {
    margin-bottom: calc(var(--spacing-s) + var(--spacing-xxxs));
  }
}

.InfoMosaic__title {
  @mixin typographyMobileDisplayLarge;

  margin-bottom: calc(1.75 * var(--spacing-xxs));

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    margin-bottom: var(--spacing-xxs);
  }
}

.InfoMosaic__description {
  @mixin typographyMobileDefaultLarge;

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;
  }
}

.InfoMosaic__image {
  position: absolute;
  inset: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s;
  filter: brightness(0.85);
}

.InfoMosaic__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  height: 357px;

  @media (--laptop) {
    height: 516px;
  }

  & > li {
    flex: 1;

    &:not(:first-child):hover {
      & ~ .InfoMosaic__image {
        opacity: 0;
      }
    }
  }
}
