.Profile.Profile--noImage {
  & .Profile__description {
    padding-left: 0;
    max-width: none;
    width: 100%;

    &::before {
      left: 0;

      @media (--mobileOnly) {
        top: -45px;
      }
    }
  }

  & .Profile__inner {
    @media (--mobileOnly) {
      padding-top: 60px;
    }
  }
}

.StoryTextContent__inner .Profile {
  @media (--mobileOnly) {
    margin-bottom: 32px;
  }

  & .Profile__inner {
    display: flex;
    flex-flow: column;
    padding: 16px;

    @media (--tablet) {
      padding: 40px 45px;
    }
  }

  &.Profile--noImage {
    & .Profile__inner {
      padding-top: 60px;
    }

    & .Profile__description::before {
      top: -45px;

      @media (--tablet) {
        top: -45px;
      }
    }
  }

  & .Profile__imgContainer {
    margin: 0 auto 1rem 0;

    @media (--tablet) {
      min-width: 210px;
      width: 210px;
      height: 210px;
    }
  }

  & .Profile__description {
    width: 100%;
    max-width: none;

    @media (--tablet) {
      padding: 0;

      &::before {
        left: initial;
        top: -60px;
      }
    }
  }
}

.Profile__inner.Profile__inner--imgRight {
  flex-direction: row-reverse;

  @media (--tablet) {
    & .Profile__imgContainer {
      margin-left: 1rem;
    }
  }
}
