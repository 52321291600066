.FastFact {
  max-width: 500px;
  position: relative;
}

.FastFact__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  inset: 0;
}

.FastFact__inner {
  display: flex;
  flex-flow: column nowrap;

  &.FastFact__inner--inGrid {
    display: none;
  }
}

.FastFact__icon {
  width: 60px;
  height: 60px;
  max-width: 90%;
  display: flex;

  @media (--tablet) {
    width: 90px;
    height: 90px;
  }
}

.FastFact__icon svg {
  max-width: 90%;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
