/* stylelint-disable no-descending-specificity */
.RichVideo {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: var(--blackColor);
  z-index: 1;
  overflow: clip;
  --rich-video-button-size: 2rem; /* 32px */
  --rich-video-button-icon-size: 1.5rem; /* 24px */

  @media (--tablet) {
    --rich-video-button-size: 4.25rem; /* 68px */
    --rich-video-button-icon-size: 3.125rem; /* 50px */
  }
}

.RichVideo__wrapper {
  @mixin resetWrapper;

  width: 100%;
  height: auto;
  display: block;
  margin-block: 0;
  padding-block: 0;

  @media (--tablet) {
    @mixin wrapperDiscoveryWideMedia;
  }
}

.RichVideo__poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: var(--z-index-rich-video-poster);

  @media (--laptop) {
    display: block;
  }

  &.RichVideo__poster--visible {
    opacity: 1;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

.RichVideo__btn {
  @mixin discoveryButtonPlayVideo var(--rich-video-button-size), var(--rich-video-button-icon-size);

  display: flex;
  align-items: center;
  font-family: var(--fontFamilyPrimary);
  background: transparent;
  border: none;
  margin-bottom: 0;
  min-height: var(--rich-video-button-size);
}

.RichVideo__btnLabel {
  @mixin typographyMobileStrongXXLarge;

  padding-inline: calc(
      var(--rich-video-button-size) + var(--spacing-xs) + (var(--spacing-xxxs) / 2)
    )
    0;
  color: var(--base-grey-white);

  @media (--tablet) {
    @mixin typographyDesktopStrongXXLarge;

    padding-inline: calc(
        var(--rich-video-button-size) + var(--spacing-xs) + var(--spacing-xxxs) +
          (var(--spacing-xxxs) / 2)
      )
      0;
    line-height: var(--rich-video-button-size);
  }
}

.RichVideo__btnLabel--hidden {
  visibility: hidden;
}

.RichVideo__playerWrapper {
  position: relative;
  width: auto;
  height: auto;
  padding-top: 0;
  margin-top: 0;
  z-index: var(--z-index-rich-video-player-wrapper);
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    display: block;
    inset: 0;
    background: linear-gradient(0deg, rgba(0 0 0 / 0.2) 0%, rgba(0 0 0 / 0.2) 100%),
      linear-gradient(180deg, rgba(0 0 0 / 0) 30.73%, var(--blackColor) 100%);
    transition: all 0.6s ease;
    animation: fadeIn 0.3s ease;
    z-index: var(--z-index-rich-video-overlay);
  }

  & > .RichVideo__btn {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @media (--laptop) {
      display: none;
    }
  }
}

.RichVideo__playerWrapper--isPlaying {
  &::after {
    opacity: 0;
    pointer-events: none;
  }

  & > .RichVideo__btn {
    display: none;
  }
}

.RichVideo__player {
  position: static;
  background-color: var(--blackColor);
  aspect-ratio: 375 / 225;

  @media (--tablet) {
    aspect-ratio: 1440 / 810;
  }

  & video,
  & iframe {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--blackColor);
    top: auto;
    left: auto;
  }
}

.RichVideo__text {
  display: grid;
  place-content: end start;
  gap: 2.25rem;
  position: absolute;
  inset: 0;
  z-index: var(--z-index-rich-video-text);
  padding: var(--spacing-s);

  @media (--tablet) {
    @mixin wrapper;

    padding: var(--spacing-xxxl) var(--spacing-l);
  }

  &.RichVideo__text--isPlaying {
    display: none;

    @media (--tablet) {
      display: none;
    }
  }

  &.RichVideo__text--center {
    place-content: center center;
    text-align: center;

    & .RichVideo__btn {
      width: fit-content;
      margin-inline: auto;

      &:has(.RichVideo__btnLabel--hidden) {
        transform: translateX(11ch);
      }
    }
  }
}

.RichVideo__title {
  @mixin typographyMobileDisplayLarge;

  display: none;

  @media (--tablet) {
    display: block;
    font-size: 2.25rem;
    line-height: normal;
    letter-spacing: -0.54px;
    margin-block: 0;
  }

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;

    max-width: 565px;
  }
}

.RichVideo__intro {
  @mixin campaingIntro;

  margin-top: calc(var(--spacing-xxxs) + var(--spacing-xxs));
  display: none;
  max-width: 40rem;

  @media (--tablet) {
    display: block;
    margin-block: 0;
  }

  & p {
    @mixin typographyMobileDefaultLarge;

    @media (--tablet) {
      @mixin typographyDesktopDefaultMedium;
    }

    @media (--laptop) {
      @mixin typographyDesktopDefaultXLarge;
    }
  }
}

.RichVideo--medium,
.RichVideo--small {
  &,
  &.SlidesNavigation__slideContainer {
    background-color: transparent;

    & .RichVideo__intro,
    & .RichVideo__title {
      &,
      & * {
        @media (--mobileAndTablet) {
          color: var(--blackColor);
        }
      }
    }
  }

  @media (--mobileOnly) {
    & .RichVideo__wrapper {
      width: calc(100% - var(--wrapperPadding));
      margin-inline: auto;
    }
  }

  & .RichVideo__wrapper {
    padding-block: 0;
  }
}

.RichVideo--medium {
  @media (--laptop) {
    max-height: 585px;
  }

  & .RichVideo__wrapper {
    @media (--laptop) {
      position: relative;
      padding-block: var(--spacing-xxxl);
      padding-inline: var(--spacing-xxxxxxl);
    }
  }

  & .RichVideo__text {
    @media (--laptop) {
      max-width: calc(100% - var(--spacing-xxxxxxl) * 2);
      padding-block: calc(var(--spacing-xxxl) * 2);
      aspect-ratio: 1440 / 810;
    }
  }
}

.RichVideo--small {
  @media (--laptop) {
    max-height: 336px;
  }

  & .RichVideo__wrapper {
    width: 100%;

    @media (--laptop) {
      padding-block: var(--spacing-m);
      padding-inline: var(--spacing-m);
    }
  }

  & .RichVideo__title {
    @media (--laptop) {
      max-width: none;
    }
  }

  & .RichVideo__intro {
    @media (--laptop) {
      max-width: none;
    }
  }
}

.SlidesNavigation__slideContainer {
  &.RichVideo {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 !important;
    aspect-ratio: 375/225;

    @media (--tablet) {
      aspect-ratio: 1440 / 810;
    }

    & h1 {
      margin-top: 0;
    }

    @media (--laptop) {
      max-height: 60vw;
      height: 100vh;
    }
  }

  & .RichVideo__wrapper {
    position: relative;
    height: 100%;
    padding-block: var(--spacing-m);

    @media (--tablet) {
      padding-block: var(--spacing-l);
    }

    @media (--laptop) {
      display: flex;
      align-items: flex-end;
      padding-block: var(--spacing-xxxxxxl);
    }

    & > div {
      @media (--laptop) {
        height: 100%;
      }
    }
  }

  & .RichVideo__playerWrapper {
    height: 100%;
    width: 100%;
    padding-top: 56.25%;
    top: 0;
    left: 0;
    transition: opacity 0.6s ease;

    @media (--laptop) {
      position: absolute;
      padding-top: 0;
    }

    &::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0 22 57 / 0.6);
      opacity: 1;
      transition: opacity 0.6s ease;
    }

    & > .RichVideo__btn {
      display: block;
    }
  }

  & .RichVideo__playerWrapper--isPlaying {
    &::after {
      content: none;
    }

    & > .RichVideo__btn {
      display: none;
    }
  }

  & .RichVideo__player {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blackColor);

    & video,
    & iframe {
      @media (--laptop) {
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  & .RichVideo__text {
    position: absolute;
    z-index: 1;
    color: var(--whiteColor);
    transition: all 0.6s ease;

    @media (--laptop) {
      flex-direction: column;
      justify-content: flex-start;
      max-width: 662px;
      height: 100%;
    }

    &.RichVideo__text--isPlaying {
      @media (--laptop) {
        display: none;
      }
    }

    &.RichVideo__text--center {
        justify-content: center;
    }
  }

  & p a {
    color: var(--kog_aqua);
  }
}
