.EpiEditModePanel {
    border: solid 2px var(--kog_red);
    padding: 1rem;
    margin-top: 2rem
}

.EpiEditModePanel small {
    font-size: 75%;
}

.EpiEditModePanel div.ErrorLabel {
    color: var(--kog_red);
    margin-bottom: 1rem;
}

.EpiEditModeBox__container {
    @mixin wrapper;
    position: absolute;
    top: 0;
}

.EpiEditModeBox {
    height: 50px;
    position: relative;
    display: inline;
    z-index: 1;
    font-weight: normal;
    font-size: 24px;
    top: 100px;
    color: blue;
    margin-right: 3rem;
}