.RichCallOut--withLinks {

  & .RichCallOut__buttons {
    width: 40%;
    align-items: start;

    & a {
      background: none;
      margin: auto 15px auto 0;
      @mixin buttonSquareRotated arrowRightSimple, var(--tertiaryColor), white;
      color: white;
      justify-content: flex-start;
      background-color: inherit;
    }
  }
}