.ContactSectionHero {
  @media (--tablet) {
    background-color: var(--base-grey-light-60);
  }

  & .ContactSectionHero__button {
    @media (--mobileOnly) {
      @mixin buttonFullWidth;
    }
  }
}

.ContactSectionHero__wrapper {
  padding-block: var(--spacing-s);

  @media (--tablet) {
    padding-block: var(--spacing-xl);
  }

  @media (--laptop) {
    display: flex;
    flex-direction: row-reverse;
    padding-block: var(--spacing-xxxxxl);
    gap: var(--spacing-xs);
    align-items: center;
    justify-content: space-between;
  }
}

.ContactSectionHero__map {
  margin-inline: auto;
  margin-block: 0 var(--spacing-xs);
  width: 100%;
  max-width: 504px;

  & img {
    width: 100%;
  }

  @media (--tablet) {
    margin-block: 0 var(--spacing-m);
  }

  @media (--laptop) {
    margin-inline: 0;
    margin-block: 0;
  }
}

.ContactSectionHero__title {
  @mixin typographyMobileDisplayLarge;

  color: var(--black-1000);
  margin-block: 0 calc(var(--spacing-xxs) + var(--spacing-xxxs));

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    color: var(--base-grey-black-100);
    margin-block: 0 var(--spacing-l);
  }
}

.ContactSectionHero__contactsList {
  margin-block: 0 var(--spacing-s);

  @media (--tablet) {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0 var(--spacing-xs);
    margin-block: 0 var(--spacing-xl);
  }

  & dt {
    @mixin typographyMobileDefaultMedium;

    color: var(--base-grey-dark-100);
    margin-block: 0 var(--spacing-xxs);

    @media (--tablet) {
      @mixin typographyDesktopDefaultLarge;

      color: var(--base-grey-black-100);
      line-height: 2.5rem;
      white-space: nowrap;
    }
  }

  & dd {
    margin-inline: 0;
    margin-block: 0 var(--spacing-xs);

    @media (--tablet) {
      margin-block: 0;
    }
  }

  & .ButtonOutlined {
    @media (--mobileOnly) {
      @mixin buttonFullWidth;
    }

    @media (--tablet) {
      border-color: transparent;
      padding: 6px 16px 6px 10px;

      & svg {
        transition: opacity var(--transition-duration);
        opacity: 0;
        stroke: none;
      }

      &:focus-visible {
        background-color: transparent;
        border-color: transparent;
        color: var(--accent-blue-accent-default);
        outline-offset: 0;

        & svg {
          opacity: 1;
        }
      }

      &:hover {
        background-color: var(--kog_blue_tint_7);
        border-color: var(--kog_blue_tint_7);
        color: var(--accent-blue-accent-default);

        & svg {
          opacity: 1;
        }
      }

      &:active {
        background-color: var(--kog_blue_tint_8);
        border-color: var(--kog_blue_tint_8);
        color: var(--kog_blue);

        & svg {
          opacity: 1;
        }
      }
    }
  }
}
