.ResourcesSectionList {
  & .ResourcesSectionList__item {
    width: 245px;

    @media (--tablet) {
      width: 333px;
    }

    & .LinkBlock {
      height: 100%;
    }
  }
}
