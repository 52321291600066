.FastFactList--Circle {
  & .FastFact {
    & .FastFact__heading {
      font-weight: 400;
    }

    & .FastFact__text {
      font-weight: 600;
    }
  }
}
