.SectionsNavbar__arrows {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.SectionsNavbar__arrow {
  color: var(--base-grey-white);
  font-size: 1.5rem;
  padding: var(--spacing-xxs);
  pointer-events: all;
  /* stylelint-disable-next-line declaration-no-important */
  transition: all var(--transition-duration) !important;

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline-offset: -2px !important;
  }

  &.SectionsNavbar__arrow--teal {
    color: var(--whiteColor);

    &.splide__arrow--next::after {
      background: linear-gradient(
        270deg,
        var(--kog_aqua_tint_7) 50%,
        color(var(--kog_aqua_tint_7) a(0%)) 100%
      );
    }

    &.splide__arrow--prev::after {
      background: linear-gradient(
        90deg,
        var(--kog_aqua_tint_7) 50%,
        color(var(--kog_aqua_tint_7) a(0%)) 100%
      );
    }
  }

  &.SectionsNavbar__arrow--base {
    color: var(--blackColor);

    &.splide__arrow--next::after {
      background: linear-gradient(
        270deg,
        var(--base-grey-light-80) 50%,
        color(var(--base-grey-light-80) a(0%)) 100%
      );
    }

    &.splide__arrow--prev::after {
      background: linear-gradient(
        90deg,
        var(--base-grey-light-80) 50%,
        color(var(--base-grey-light-80) a(0%)) 100%
      );
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: var(--z-index-sections-navbar-arrow-background);
    width: 4.25rem;
    height: 2.5rem;
    transition: all var(--transition-duration);
  }

  & svg {
    position: relative;
    z-index: var(--z-index-sections-navbar-arrow);
    transition: all var(--transition-duration);
  }

  &[disabled] {
    /* stylelint-disable-next-line declaration-no-important */
    opacity: 0 !important;
    pointer-events: none;

    &::after,
    & svg {
      opacity: 0;
    }
  }

  &.splide__arrow--prev {
    & svg {
      transform: rotate(90deg);
    }

    &::after {
      right: auto;
      left: 0;
    }
  }

  &.splide__arrow--next {
    & svg {
      transform: rotate(-90deg);
    }
  }
}
