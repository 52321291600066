.StoryCallToAction {
  transform: translateY(80px);
  min-height: 560px;

  @media (--tablet) {
    height: 681px;
  }

  @media (--laptop) {
    height: 852px;
  }
}

.StoryCallToAction__title {
  @mixin typographyMobileDisplayLarge;

  margin-block: 0 var(--spacing-s);

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    margin-block: 0 var(--spacing-s);
  }
}

.StoryCallToAction__body {
  &,
  & * {
    @mixin typographyMobileDefaultLarge;

    margin-block: 0 var(--spacing-s);

    @media (--tablet) {
      @mixin typographyDesktopDefaultLarge;

      margin-block: 0 var(--spacing-s);
    }
  }
}

.StoryCallToAction__overlay::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.StoryCallToAction__content {
  @mixin storyTextContent;

  max-height: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  @media (--mobileOnly) {
    margin-right: 30px;
    margin-left: 30px;
  }

  @media (--tablet) {
    float: right;
    padding-top: 45px;
    padding-bottom: 95px;
    width: calc(100% - 30px);
  }

  @media (--laptop) {
    padding-top: 95px;
    padding-bottom: 190px;
  }
}

.StoryCallToAction__button {
  @mixin buttonPrimary;
}

.StoryCallToAction__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.StoryCallToAction__backgroundImage {
  position: absolute;

  &,
  & picture,
  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
