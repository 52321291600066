.ContactInfo > div {
  margin-bottom: 0.5rem;  
}

.ContactInfo__title {
  font-size: 24px;
  line-height: 1.2;
  margin: 0 0 1.5rem 0;

  @media (--tablet) {
    font-size: 32px;
  }
}

.ContactInfo__subtitle {
  font-size: 20px;
  margin: 2rem 0 1rem 0;
}

.ContactInfo__email {
  display: flex;
  align-items: center;
  line-height: 1.2;
  word-wrap: break-word;

  &::before {
    content: '';
    margin-right: 10px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: inline-flex;
    background: svg-load('Icons/EmailDark.svg',
    fill: var(--textColor)) no-repeat;
    background-size: contain;
  }
  
  & > span span {
    display: inline-block;
  }
}

.ContactInfo__call {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    margin-right: 10px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: inline-flex;
    background-size: contain;
  }
}

.ContactInfo__phone {
  &::before {
    background: svg-load('Icons/PhoneDark.svg',
    fill: var(--textColor)) no-repeat;
  }
}

.ContactInfo__fax {
  &::before {
    background: svg-load('Icons/Fax.svg',
    fill: var(--textColor)) no-repeat;
  }
}

.ContactInfo__richtextArea {
  & p {
    margin: 0 0 0.3rem 0;
  }
}