@keyframes move {
  0%, 100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-3px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blurIn {
  0% {
    filter: blur(20px);
    opacity: 0;
  }

  100% {
    filter: none;
    opacity: 1;
  }
}

@keyframes hoop {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-3px);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(40);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeUpText {
  transform: translateY(40px);
  animation: fadeUp 1.2s cubic-bezier(0.22, 0.44, 0, 1) forwards;
}

.fadeInText {
  opacity: 0;
  animation: fadeIn 1.5s cubic-bezier(0.22, 0.44, 0, 1) forwards;
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes moveBackgroundImage-10px {
  0%, 100% {
    background-position-x: 0;
  }

  50% {
    background-position-x: 10px;
  }
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes moveBackgroundImage-10pxReverse {
  0%, 100% {
    background-position-x: 100%;
  }

  50% {
    background-position-x: calc(100% - 10px);
  }
}
