.CategoryPageHeader__title {
  @mixin typographyMobileDisplayLarge;

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.CategoryPageHeader__description {
  @mixin typographyMobileDefaultXLarge;
  margin-top: var(--spacing-xs);

  @media (--tablet) {
    @mixin typographyDesktopDefaultXLarge;
    margin-top: var(--spacing-xs);
  }
}

.CategoryPageHeader__button {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryTealFill;
  margin-top: calc(var(--spacing-xs) + var(--spacing-xxxs));
  display: block;
}