.CallOut.CallOut--withLinks {
    & .CallOut__wrapper {
        width: 100%;
        max-width: 100%;
        flex-flow: row;
        align-items: stretch;
        min-height: 220px;
    }

    & .CallOut__content {
        background: rgba(0, 22, 57, 0.5);
        width: 60%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0;

        & .CallOut__body p {
            margin: 0;
        }
    }

    & .CallOut__buttons {
        background: rgba(0, 22, 57, 0.8);
        width: 40%;
        align-items: start;

        & a {
            background: none;
            margin: auto 15px auto -35px;
            @mixin buttonSquareRotated arrowRightSimple, var(--tertiaryColor), white;
            color: white;
            padding-left: 35px;
        }
    }
}