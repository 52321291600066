.TabSections__section {
  scroll-margin-top: calc(var(--mobile-header-height) + var(--anchor-navigation-height));

  &.TabSections__section--withList {
    @media (--laptop) {
      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  &:not(.TabSections__section--withList) {
    @media (--laptop) {
      &:first-of-type {
        padding-top: var(--spacing-s);
      }

      &:not(:last-of-type) {
        border-bottom: 2px solid var(--base-grey-medium-80);
      }
    }
  }

  &.TabSections__section--collapsible {
    &:not(:last-of-type) {
      border-bottom: 2px solid #cfd1d3;
    }
  }

  @media (--laptop) {
    scroll-margin-top: calc(
      var(--header-height-with-breadcrumbs) + var(--anchor-navigation-height)
    );
    padding: var(--spacing-s) 0 var(--spacing-xl);
  }
}

.TabSections__sectionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
  height: 53px;
  padding: 0 var(--spacing-s);
  background: var(--whiteColor);
  border-bottom: 1px solid var(--base-grey-light-100);
  width: 100%;

  @media (--laptop) {
    border-bottom: none;
    padding: 0;
    height: 80px;
  }

  & svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
    transition: transform 0.2s;
  }

  &.TabSections__sectionTitle--asButton {
    @mixin resetButtonStylesNoOverride;
    @mixin focusVisible var(--outlineColorSecondary);

    background: var(--base-grey-light-80);
    border-bottom: 1px solid var(--base-grey-light-100);
    padding: 0 var(--spacing-s);
    height: 60px;
    cursor: pointer;

    @media (--laptop) {
      display: none;
    }
  }

  &.TabSections__sectionTitle--asDiv {
    @media (--mobileAndTablet) {
      margin-bottom: 3px;
    }
  }

  &.TabSections__sectionTitle--hiddenOnMobile {
    @media (--mobileAndTablet) {
      display: none;
    }
  }

  &.TabSections__sectionTitle--noMargin {
    margin-bottom: 0;
  }

  &.TabSections__sectionTitle--expanded {
    & svg {
      transform: rotate(45deg);
    }
  }

  &.TabSections__sectionTitle--expanded,
  &.TabSections__sectionTitle--asDiv {
    @media (--mobileAndTablet) {
      position: sticky;
      top: var(--mobile-header-height);
      z-index: var(--z-index-above);
    }
  }

  &.TabSections__sectionTitle--withBackground {
    background: var(--base-grey-light-80);
  }

  &.TabSections__sectionTitle--withBackgroundOnlyMobile {
    @media (--mobileAndTablet) {
      background: var(--base-grey-light-80);
    }
  }

  &.TabSections__sectionTitle--negativeMarginBottom {
    @media (--laptop) {
      margin-bottom: calc(-1 * var(--spacing-xxs));
    }
  }
}

.TabSections__sectionTitleInner {
  @mixin wrapper var(--wrapperWidth), 0;

  @media (--laptop) {
    @mixin wrapper var(--wrapperWidth), var(--spacing-s);

    &.TabSections__sectionTitleInner--withHorizontalPadding {
      padding: 0 var(--spacing-xxs);
    }
  }
}

.TabSections__sectionHeading {
  @mixin typographyMobileStrongLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopStrongXLarge;
  }
}

.TabSections__seeMoreLink {
  display: flex;
  justify-content: center;
}

.TabSections__sectionContentPaddingBottom {
  height: var(--spacing-s);
  transition: height 0.3s;

  &.TabSections__sectionContentPaddingBottom--withBackground {
    background: var(--base-grey-light-80);
  }

  &.TabSections__sectionContentPaddingBottom--small {
    height: calc(1.75 * var(--spacing-xxs));
  }

  @media (--laptop) {
    display: none;
  }
}

.TabSections__sectionContentInner {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  overflow: hidden;

  @media (--laptop) {
    gap: calc(var(--spacing-s) + var(--spacing-xxxs));
  }
}

.TabSections__sectionContent {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  display: grid;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  transition: grid-template-rows 0.3s;

  @media (--laptop) {
    gap: calc(var(--spacing-s) + var(--spacing-xxxs));
  }

  &.TabSections__sectionContent--expandable {
    & .TabSections__sectionContentInner {
      overflow: hidden;
    }

    & .TabSections__sectionContentPaddingBottom {
      height: 0;
    }

    @media (--mobileAndTablet) {
      grid-template-rows: 0fr;

      & .TabSections__sectionDescription {
        margin-top: calc(var(--spacing-xs) + var(--spacing-xxxs));
      }
    }
  }

  &.TabSections__sectionContent--expanded {
    grid-template-rows: 1fr;

    & .TabSections__sectionContentPaddingBottom {
      height: var(--spacing-s);
    }
  }

  &.TabSections__sectionContent--noHorizontalMarginOnMobile {
    @media (--mobileAndTablet) {
      @mixin wrapper var(--wrapperWidth), 0;
    }
  }
}

.TabSections__sectionDescription {
  &,
  & p {
    @mixin typographyMobileDefaultLarge;

    color: var(--base-grey-black-100);

    @media (--laptop) {
      @mixin typographyDesktopDefaultSmall;
    }
  }

  & a {
    @mixin typographyDesktopStrongSmall;
    @mixin arrowLinkRightMedium var(--kog_blue_tint_1);

    color: var(--kog_blue_tint_1);
    margin-top: var(--spacing-m);
    display: flex;
    gap: 10px;
    align-items: center;
    width: max-content;

    &::after {
      margin-top: calc(1rem + 2px);
    }
  }
}
