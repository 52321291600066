.StepByStepPicture {
  position: relative;
  padding-left: 20px;
  margin-bottom: 1rem;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 100%;
    z-index: 0;
  }

  & picture {
    position: relative;
    padding-top: 60%;
  }
}

.StepByStepPicture--default::before {
  background: var(--kog_blue_tint_9);
}

.StepByStepPicture--blue::before {
    background: var(--kog_blue_tint_8);
  }

.StepByStepPicture--aqua::before {
  background: var(--kog_aqua_tint_6);
}

.StepByStepPicture--sand::before {
  background: var(--kog_bronze);
}
