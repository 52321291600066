.ProductBanner {
  --product-banner-offset: 0;

  position: sticky;
  top: var(--product-banner-offset);
  left: 0;
  right: 0;
  z-index: var(--z-index-product-banner);
  width: 100%;
  color: var(--base-grey-black-100);
}

.ProductBanner__component {
  &.ProductBanner__component--green {
    background-color: var(--kog_green_tint_4);
    color: var(--kog_green_tint_1);
  }

  &.ProductBanner__component--orange {
    background-color: var(--kog_yellow_tint_3);
  }

  &.ProductBanner__component--yellow {
    background-color: var(--kog_yellow_tint_2);
  }
}

.ProductBanner__replacementLink {
  @mixin typographyDesktopStrongMedium;

  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.ProductBanner__inner {
  padding: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2)) var(--spacing-xs);

  @media (--tablet) {
    @mixin wrapper;

    padding-inline: 0;
  }

  & p {
    @mixin typographyMobileDefaultMedium;

    @media (--tablet) {
      @mixin typographyDesktopDefaultMedium;
    }

    & strong {
      @mixin typographyDesktopStrongMedium;

      @media (--tablet) {
        @mixin typographyDesktopStrongLarge;
      }
    }

    &:not(:last-child) {
      margin-top: calc((var(--spacing-xxxs) + (var(--spacing-xxxs) / 2) / 2));

      @media (--tablet) {
        margin-top: calc(var(--spacing-xxxs));
      }
    }
  }
}
