@define-mixin richTextLinks {
  & a {
    color: var(--brandColor);
    text-decoration: none;
    word-break: break-word;
  }

  & a:hover {
    color: var(--brandColor);
    text-decoration: underline;
  }

  & a:visited {
    color: var(--brandColor);
  }
}
