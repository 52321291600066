.CourseDetailsPage {
    @mixin blockSpacing;

    & .Table__wrapper {
        & td,
        & th {
            vertical-align: middle;
        }
    }
}

.CourseDetailsPage__message {
    @mixin wrapper;
    color: var(--blackLighterColor);
    text-align: center;
    padding: 32px 15px;
}

.CourseDetailsPage__body {
    @mixin blockSpacing;
    background-color: color(var(--kog_blue_tint_5) a(50%));
    padding: 2rem 0;
    
    @media (--tablet) {
        padding: 50px 0;
    }
}

.CourseDetailsPage__heading {
    @mixin wrapper;
    margin-bottom: 1rem;
    
    @media (--tablet) {
        margin-bottom: 24px;
    }
}

.CourseDetailsPage__subheading {
    @mixin wrapper;
    font-size: 20px;
    border-bottom: 6px solid var(--whiteColor);
    margin-bottom: 0;
    padding-bottom: 16px;
    margin-top: 1rem;
    
    @media (--tablet) {
        font-size: 24px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        margin-top: 2rem;
    }
}

.CourseDetailsPage__section {
    border-bottom: 10px solid var(--whiteColor);
}

.CourseDetailsPage__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
    & > li:nth-child(even) {
        background: var(--whiteColor);
    }
}

.Course,
.CourseOnline {
    @mixin wrapper;
    display: flex;
    align-items: center;
    padding: 10px 0;
    flex-shrink: 0;
    flex-grow: 0;
    flex-wrap: wrap;

    @media (--tablet) {
        padding: 16px 0;
    }
}

.Course {
    & span,
    & time {
        padding: 5px 0;
        
        @media (--mobileSmallOnly) {
            @neat-span-columns 6;    
        }
        
        @media (--mobileSmall) {
            @neat-span-columns 4;
        }
        
        @media (--tablet) {
            @neat-span-columns 2;
            @neat-omega 6n;
        }
    }
    & .Btn--link, & .Btn--withBorder {
        min-width: auto;
    }
    & .Btn--link {
        text-decoration: underline;
    }   
    
    & .Btn--link:hover {
        text-decoration: underline;
    }

    @media (--mobileSmallOnly) {
        & .Course__date {
            order: 1;
        }

        & .Course__duration {
            order: 5;
        }

        & .Course__subject {
            order: 3;
            width: 100%;
            margin-right: 0;
        }

        & .Course__location {
            order: 2;
            margin-right: 0;
        }
        
        & .Course__price {
            order: 3;
            margin-right: 0;
        }

        & .Course__action {
            order: 6;
            margin-right: 0;
            width: 100%;
        }
    }
    
    @media(--mobileSmall) {
        & .Course__date {
            order: 1;
        }

        & .Course__duration {
            order: 5;
            max-width: 90px;
        }

        & .Course__subject {
            order: 2;
        }

        & .Course__location {
            order: 4;
        }
        & .Course__price {
            order: 3;
            margin-right: 0;
        }

        & .Course__action {
            order: 6;
            margin-right: 0;
            min-width: 150px;
        }
    }
    
    @media (--tablet) {
        & .Course__date {
            order: 1;
        }

        & .Course__duration {
            order: 5;
        }

        & .Course__subject {
            order: 2;
        }

        & .Course__location {
            order: 4;
        }
        & .Course__price {
            order: 3;
            margin-right: 0;
        }

        & .Course__action {
            order: 6;
            margin-right: 0;
        }   
    }
    
    @media (--laptop) {
        & .Course__date,
        & .Course__duration,
        & .Course__subject,
        & .Course__location,
        & .Course__price,
        & .Course__action {
            order: unset;
        }
    }
}

.Course--head,
.CourseOnline--head {
    & span {
        font-size: 18px;
        font-weight: 400;
        
        @media (--tablet) {
            font-size: 22px;
            font-weight: 400;
        }
    }
    
    & .CourseDetailsPage__listAction {
        display: none;
        
        @media (--mobileSmall) {
            display: block;
        }
    }
}

.CourseOnline {
    & span {
        padding: 5px 0;
        @neat-span-columns 6;
        
        @media (--tablet) {
            @neat-span-columns 3;
            @neat-omega 4n;
        }
    }

    @media (--mobileSmallOnly) {
        & .CourseOnline__action {
            width: 100%;
        }
    }

    @media (--mobileOnly) {
        & .CourseOnline__action {
            margin-right: 0;
        }
        
        & .CourseOnline__availability {
            margin-right: 0;
        }
    }
}