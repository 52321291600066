.MicrositeSection__backButton {
  @mixin resetButtonStyles;
  transition: background .3s ease-in-out;
  border-radius: 100%;
  cursor: pointer;
  transform: rotate(180deg);
  width: 64px;
  height: 64px;
  position: absolute;
  z-index: 1;

  @media (--tablet) {
    opacity: 1;
    margin-left: 0;
  }

  @media (--laptop) {
    top: 32px;
  }

  @media (--desktop4k) {
    width: 96px;
    height: 96px;
  }

  &.MicrositeSection__backButton--hidden {
    @media (--mobileOnly) {
      transition: opacity .3s ease-in-out;
      pointer-events: none;
      opacity: 0;
    }
  }

  &.MicrositeSection__backButton--visible {
    @media (--mobileOnly) {
      transition: top .3s ease-in-out;
      pointer-events: all;
      opacity: 1;
      top: 96px;
    }
  }

  &.MicrositeSection__backButton--dark {
    & .MicrositeSection__buttonOutline {
      @mixin circleOutline;
      background: svg-load("Icons/MicrositeChevron.svg", stroke: var(--whiteColor)) no-repeat;
      background-position: 50%;

      @media (--desktop4k) {
        background-position: 55%;
        background-size: 15px;
      }
    }

    &.MicrositeSection__backButton--solid {
      background: var(--whiteColor);

      & .MicrositeSection__buttonOutline {
        background: svg-load("Icons/MicrositeChevron.svg", stroke: var(--blackColor)) no-repeat;
        background-position: 50%;

        @media (--desktop4k) {
          background-position: 55%;
          background-size: 15px;
        }
      }
    }

    &.MicrositeSection__backButton--solid {
      background: var(--whiteColor);
      
      & .MicrositeSection__buttonOutline {
        background: svg-load("Icons/MicrositeChevron.svg", stroke: var(--blackColor)) no-repeat;
        background-position: 50%;
      } 
    }
  }

  &.MicrositeSection__backButton--light {
    & .MicrositeSection__buttonOutline {
      @mixin circleOutline var(--blackColor);
      background: svg-load("Icons/MicrositeChevron.svg", stroke: var(--blackColor)) no-repeat;
      background-position: 50%;

      @media (--desktop4k) {
        background-position: 55%;
        background-size: 15px;
      }
    }

    &.MicrositeSection__backButton--solid {
      background: var(--blackColor);

      & .MicrositeSection__buttonOutline {
        background: svg-load("Icons/MicrositeChevron.svg", stroke: var(--whiteColor)) no-repeat;
        background-position: 50%;

        @media (--desktop4k) {
          background-position: 55%;
          background-size: 15px;
        }
      }
    }
  }

  &.MicrositeSection__backButton--mobileOnly {
    display: block;

    @media (--tablet) {
      display: none;
    }
  }

  &.MicrositeSection__backButton--tabletPlus {
    display: none;

    @media (--tablet) {
      display: block;
    }

    &.MicrositeSection__backButton--solid {
      background: var(--blackColor);

      & .MicrositeSection__buttonOutline {
        background: svg-load("Icons/MicrositeChevron.svg", stroke: var(--whiteColor)) no-repeat;
        background-position: 50%;
      } 
    }
  }

  &.MicrositeSection__backButton--mobileOnly {
    display: block;

    @media (--tablet) {
      display: none;
    }
  }

  &.MicrositeSection__backButton--tabletPlus {
    display: none;

    @media (--tablet) {
      display: block;
    }
  }
}
