.ProductRangeCategoryPage {
  & > .Section:nth-child(even) {
    background-color: var(--base-grey-light-60);
  }
}

.ProductRangeCategoryPage__title {
  font-size: 1.625rem; /* 26px */
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.39px;
  margin-block: 1.6875rem; /* 27px */

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    margin-block: var(--spacing-m);
  }
}
