.Hero--editMode {
  justify-content: center;
  max-height: 680px;

  & .Hero__inner {
    padding-top: 0 !important;
    display: flex;
    flex-flow: row-reverse;
  }

  & .Hero__picture {
    position: static;
    top: initial;
    left: initial;
    transform: none;
    display: block;
  }

  & .Hero__heading {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}
