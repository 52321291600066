.MicrositeSectionContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  @media (--laptop) {
    justify-content: center;
  }
}

.MicrositeSectionContent__outer {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @media (--tablet) {
    overflow: hidden;
    height: auto;
    padding: 40px 32px;
    margin: 32px 0;
    border-radius: 25px;
  }
}

.MicrositeSectionContent__inner {
  position: relative;
  margin: 80vh 0 0;
  padding: 16px;

  @media (--tablet) {
    height: 100%;
    overflow: auto;
    margin: 32px 0 0;
    padding: 0 24px 32px 0;
    height: calc(100% - 80px);
  }

  & h1 {
    margin: 32px 0;

    @media (--tablet) {
      margin: 0 0 32px;
    }
  }
}

.MicrositeSectionContent__backButton {
  position: sticky;
  top: 16px;

  @media (--tablet) {
    top: 0;
  }
}

.MicrositeSectionContent__heading {
  @mixin h1Micro;
  margin: 16px 0 32px;

  @media (--tablet) {
    margin-top: 0;
  }
}

.MicrositeSectionContent__description {
  & a {
    @mixin linkMicro;
  }

  & p {
    @mixin pMicro;
  }
}

.MicrositeSectionContent__list {
  list-style: none;
  padding: 0;

  @media (--laptop) {
    width: 75%;
  }
}

.MicrositeSectionContent__listItem {
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  & .MicrositeSectionContent__button {
    @mixin resetButtonStyles;
    position: relative;
    cursor: pointer;
    margin: 8px 0 !important;
    padding-right: 16px !important;
    font-weight: 400;
    font-size: 30px;
    width: 100%;
    transition: all ease 0.3s;

    @media (--tablet) {
      font-size: 20px;
      line-height: 1.6;
    }

    @media (--desktop4k) {
      font-size: 42px;
      margin: 16px 0 !important;
      line-height: 1;
    }

    &:after {
      @media (--mobileOnly) {
        position: absolute;
        content: "";
        top: 8px;
        right: 5px;
      }
    }
  }
}

.MicrositeSectionContent__observer {
  width: 100%;
  height: 1px;
  position: absolute;
  top: -100px;

  @media (--tablet) {
    display: none;
  }
}
