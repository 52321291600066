@define-mixin richCampaignScrollBar $color: white, $width: 5px {
  @media (--laptop) {
    overflow-y: auto;
    padding-right: 3rem;
    margin-right: -3rem;

    &::-webkit-scrollbar {
      height: 0;
      width: $width;
      transform: translateX(20px);
    }

    &::-webkit-scrollbar-track {
      background: color($color a(10%));
    }

    &::-webkit-scrollbar-thumb {
      background: color($color a(20%));
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--grayMedium);
    }
  }
}