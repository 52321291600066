@define-mixin overlayImageButton $background, $color: var(--whiteColor) {
    background-color: $background;

    /* to increase specificity */
    && > a { 
    color: $color;
    }
}

.RichOverlayImage__button {
    &.RichOverlayImage__button--Default {
        @mixin overlayImageButton transparent;
    }

    &.RichOverlayImage__button--BlueFive {
        @mixin overlayImageButton var(--kog_blue_tint_7), var(--textColor);
    }

    &.RichOverlayImage__button--SandFour {
        @mixin overlayImageButton var(--kog_bronze_tint_1), var(--textColor);
    }

    &.RichOverlayImage__button--TealTwo {
        @mixin overlayImageButton var(--kog_aqua_tint_6);
    }

    &.RichOverlayImage__button--TealFive {
        @mixin overlayImageButton var(--kog_aqua_tint_1), var(--textColor);
    }

    &.RichOverlayImage__button--YellowFour {
        @mixin overlayImageButton var(--kog_yellow), var(--textColor);
    }

    &.RichOverlayImage__button--GreenTwo {
        @mixin overlayImageButton var(--kog_green_tint_3);
    }

    &.RichOverlayImage__button--ForestTwo {
        @mixin overlayImageButton var(--kog_forest_tint_3);
    }

    &.RichOverlayImage__button--GreenFour {
        @mixin overlayImageButton var(--kog_green_tint_2), var(--textColor);
    }

    &.RichOverlayImage__button--White {
        @mixin overlayImageButton var(--whiteColor), var(--textColor);
    }

    &.RichOverlayImage__button--Black {
        @mixin overlayImageButton var(--textColor);
    }

    &.RichOverlayImage__button--GreenOne {
        @mixin overlayImageButton var(--kog_green_tint_4);
    }

    &.RichOverlayImage__button--GreenFive {
        @mixin overlayImageButton var(--kog_green_tint_1), var(--textColor);
    }

    &.RichOverlayImage__button--ForestFive {
        @mixin overlayImageButton var(--kog_forest_tint_1), var(--textColor);
    }

    &.RichOverlayImage__button--BlueTwo {
        @mixin overlayImageButton var(--kog_blue_tint_1);
    }

    &.RichOverlayImage__button--BlueThree {
        @mixin overlayImageButton var(--kog_blue_tint_9);
    }

    &.RichOverlayImage__button--YellowTwo {
        @mixin overlayImageButton var(--kog_yellow_tint_3), var(--textColor);
    }

    &.RichOverlayImage__button--YellowFive {
        @mixin overlayImageButton var(--kog_yellow_tint_1), var(--textColor);
    }

    &.RichOverlayImage__button--RedFour {
        @mixin overlayImageButton var(--kog_red_tint_1), var(--textColor);
    }

    &.RichOverlayImage__button--GreyTwo {
        @mixin overlayImageButton var(--grayColor_tint_6);
    }

    &.RichOverlayImage__button--GreyFive {
        @mixin overlayImageButton var(--grayColor_tint_2), var(--textColor);
    }
}