@define-mixin clickDimensionFormColorVariant $captchaBgColor, $fontColor, $buttonVariant,
  $buttonColor, $buttonActiveColor {
  & .clickdform span {
    /* stylelint-disable-next-line declaration-no-important */
    color: $fontColor !important;
  }

  & .clickdform input[type="text"],
  & .clickdform select,
  & .clickdform textarea {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: var(--whiteColor) !important;
  }

  & .QapTcha .dropError,
  & .QapTcha .dropSuccess {
    background: $captchaBgColor;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  & .clickdform #btnSubmit,
  & .clickdform .WizardNextButton,
  & .clickdform .WizardPrevButton {
    @mixin $(buttonVariant);

    /* stylelint-disable-next-line declaration-no-important */
    color: $buttonColor !important;

    &:active {
      /* stylelint-disable-next-line declaration-no-important */
      color: $buttonActiveColor !important;
    }
  }
}
