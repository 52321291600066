@define-mixin richTextBlockColorVariant $backgroundColor, $fontColor {
  background-color: $backgroundColor;
  color: $fontColor;

  & .RichTextBlock__aside {
    &::after {
      background: $fontColor;
    }
  }

  & .RichTextBlock__content {
    @media (--laptop) {
      overflow-y: auto;
      padding-right: var(--spacing-m);
      margin-right: calc(-1 * var(--spacing-m));

      &::-webkit-scrollbar {
        height: 0;
        width: 5px;
        transform: translateX(20px);
      }

      &::-webkit-scrollbar-track {
        background: color($fontColor a(10%));
      }

      &::-webkit-scrollbar-thumb {
        background: color($fontColor a(20%));
      }

      &::-webkit-scrollbar-thumb:hover {
        background: color($fontColor a(40%));
      }
    }
  }
}

.RichTextBlock {
  &.RichTextBlock--backgroundColorGreyTwo {
    @mixin richTextBlockColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color);
  }

  &.RichTextBlock--backgroundColorGreenFour {
    @mixin richTextBlockColorVariant var(--theme-color-green-bg-color), var(--theme-color-green-text-color);
  }

  &.RichTextBlock--backgroundColorBlueFive {
    @mixin richTextBlockColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color);
  }

  &.RichTextBlock--backgroundColorTealTwo {
    @mixin richTextBlockColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color);
  }

  &.RichTextBlock--backgroundColorSandFour {
    @mixin richTextBlockColorVariant var(--theme-color-brown-bg-color), var(--theme-color-brown-text-color);
  }

  &.RichTextBlock--backgroundColorTealFive {
    @mixin richTextBlockColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color);
  }

  &.RichTextBlock--backgroundColorYellowFour {
    @mixin richTextBlockColorVariant var(--theme-color-yellow-bg-color), var(--theme-color-yellow-text-color);
  }

  &.RichTextBlock--backgroundColorGreenTwo {
    @mixin richTextBlockColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color);
  }

  &.RichTextBlock--backgroundColorBlueTwo {
    @mixin richTextBlockColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color);
  }

  &.RichTextBlock--backgroundColorSandTwo {
    @mixin richTextBlockColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color);
  }

  &.RichTextBlock--backgroundColorForestTwo {
    @mixin richTextBlockColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color);
  }

  &.RichTextBlock--backgroundColorGreenOne {
    @mixin richTextBlockColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color);
  }

  &.RichTextBlock--backgroundColorGreenFive {
    @mixin richTextBlockColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color);
  }

  &.RichTextBlock--backgroundColorForestFive {
    @mixin richTextBlockColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color);
  }

  &.RichTextBlock--backgroundColorBlueThree {
    @mixin richTextBlockColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color);
  }

  &.RichTextBlock--backgroundColorYellowTwo {
    @mixin richTextBlockColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color);
  }

  &.RichTextBlock--backgroundColorYellowFive {
    @mixin richTextBlockColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color);
  }

  &.RichTextBlock--backgroundColorRedFour {
    @mixin richTextBlockColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color);
  }

  &.RichTextBlock--backgroundColorGreyFive {
    @mixin richTextBlockColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color);
  }
}
