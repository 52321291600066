.ContentCard--featured {
    max-width: 100%;
    margin-bottom: var(--spacing-xl);
  
    @media (--tablet) {
      margin-bottom: var(--spacing-m);
    }

    & .ContentCard__body {
      position: relative;
      z-index: 1;
      
      @media (--tablet) {
        min-height: 400px;
      }
    }
    
    & .ContentCard__title {
      font-size: 24px;
      line-height: 1.5;
      margin: 0;
      
      @media (--tablet) {
        font-size: 28px;
      }

      & + p {
        margin-top: 8px;

        @media (--tablet) {
          margin-top: 16px;
        }
      }
    }
  
    & .ContentCard__text {
        width: 100%;
        color: var(--kog_bronze_tint_7);
        padding: var(--spacing-s) var(--spacing-m);
        position: relative;
        max-width: 100%;
        background-color: var(--themeSecondaryColor_tint_2);
        
        @media (--tablet) {
            max-width: 360px;
            min-height: 400px;
        }
    }
    
    & .ContentCard__pictureContainer {
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin-bottom: 0;
        min-height: 194px;
        max-height: 200px;
        position: relative;
      
        @media (--tablet) {
          width: calc(100% - 360px);
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
            opacity: 0;
            display: block;
            background: linear-gradient(to bottom, var(--blackColor) 0%, color(var(--blackColor) a(50%)) 75%);
            pointer-events: none;
            z-index: 1;
        }

        @media (--tablet) {
            height: initial;
            min-height: 400px;
            max-height: initial;
        }
    }
  
    & .ContentCard__picture {
      height: 100%;
      width: 100%;
      position: relative;
      padding-top: 200px;

      @media (--tablet) {
        position: absolute;
        padding-top: 0;
      }
      
      & img {
        height: 100%;
        min-height: auto;
      }
    }

    & .ContentCard__date {      
      & span {
        padding-top: 32px;
        
        @media (--tablet) {
          padding-top: 24px;
        }
      }
    }
}