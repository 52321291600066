/* stylelint-disable rule-empty-line-before */
/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-id-pattern */
#ot-sdk-cookie-policy {
  & #ot-sdk-cookie-policy-v2 {
    &.ot-sdk-cookie-policy.ot-sdk-container {
      @mixin wrapper;
    }

    &.ot-sdk-cookie-policy {
      & * {
        color: inherit !important;
      }

      & .ot-sdk-cookie-policy-group {
        @mixin h4;
      }

      & .ot-sdk-cookie-policy-group-desc,
      & #cookie-policy-description {
        @mixin p;
      }

      & a {
        background-color: transparent;
      }

      & table {
        border: none;
        & th {
          text-align: center;
          border-right: none;
        }

        & tbody {
          & tr:nth-child(even) {
            background-color: color(var(--brandColor_dark_tint_6) a(45%));
          }
        }
      }
    }
  }
}
