.InlineFrame {
    width: 100%;
    position: relative;
}

.InlineFrame__frame {
    width: 100%;
    border: none;
}

.InlineFrame--fixedRatio {
    padding-top: 100%;

    @media (--tablet) {
        padding-top: 32.5%;
    }
    
    @media (--laptop) {
        padding-top: 20%;
    }

    & .InlineFrame__frame {
        position: absolute;
        top: 0;
        left: 0;
    }
}