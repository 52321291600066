.OfficeContactInfo {
  margin-bottom: 2rem;
}

.OfficeContactInfo__wrapper {
  @mixin wrapper;
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  padding-top: 2rem;
  border-top: 2px solid var(--kog-white-700);

  @media (--laptop) {
    flex-wrap: nowrap;
  }
}

.OfficeContactInfo__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  @media (--laptop) {
    width: 65%;
  }
}

.OfficeContactInfo__title {
  font-family: GTPressura, sans-serif;
  font-size: 14px;
  line-height: 1.2;
  margin: 0 0 1.5rem 0;

  @media (--tablet) {
    font-size: 26px;
  }
}

.OfficeContactInfo__subtitle {
  font-size: 14px;
  margin: 0 0 1rem 0;

  @media (--tablet) {
    font-size: 20px;
  }
}

.OfficeContactInfo__tags {
  margin-bottom: 2rem;

  @media (--mobileSmall) {
    column-count: 2;
    column-fill: balance;
    column-gap: 3%;
    flex: 1 0 auto;
  }
  
  & a:hover {
    text-decoration: underline;
  }

  & ul,
  & ol {
    padding-left: 1.5rem;
    margin: 0;

    @media (--tablet) {
      margin: 0 0 2rem 0;
    }

    & li {
      margin-bottom: 0.25rem;
      font-size: 14px;

      @media (--tablet) {
        font-size: 18px;
      }
    }
  }

  & .Btn--readMore {
    margin-bottom: 1rem;

    @media (--tablet) {
      margin-left: -35px;
    }
  }

  & .Btn--readMore:last-of-type {
    margin-bottom: 0;
  }
}

.ContactInfo__linkSection {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.OfficeContactInfo__contact {
  background-color: var(--kog_blue_50);
  padding: var(--spacing-xs) calc(var(--spacing-xxxs) + var(--spacing-xxs));
  width: 100%;

  @media (--laptop) {
    width: 35%;
  }
  
  h2, h3 {
    @mixin typographyDesktopStrongLarge;

    color: var(--kog_blue_tint_1);
    margin-bottom: var(--spacing-xxs);

    @media (--mobileOnly) {
      font-size: 1rem;
    }
  }

  .ContactInfo > div {
    padding-bottom: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    margin-bottom: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    border-bottom: 1px solid var(--kog_blue_300);
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}




.OfficeContactInfo__richtextArea {
  @media (--mobileSmall) {
    column-count: 2;
    column-fill: balance;
    column-gap: 3%;
    flex: 1 0 auto;
  }

  & p {
    margin: 0 0 0.3rem 0;
  }

  & h2 {
    font-size: 20px;
    margin: 0 0 0.3rem 0;

    @media (--tablet) {
      font-size: 24px;
    }
  }

  & h3 {
    font-size: 20px;
  }

  & ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  & a:hover {
    text-decoration: underline;
  }
}