.TabsListing__tabsWrapper {
  @mixin wrapper var(--wrapperWidth), 0;
}

.TabsListing__tabs {
  width: 100%;
  position: relative;
  display: flex;

  @media (--laptop) {
    width: 375px;
  }
}

.TabsListing__tab {
  @mixin resetButtonStylesNoOverride;
  @mixin typographyMobileDefaultLarge;
  @mixin focusVisible var(--outlineColorSecondary), 2px, -2px;

  height: 3rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--base-grey-black-100);

  @media (--tablet) {
    @mixin typographyDesktopDefaultMedium;
  }
}

.TabsListing__activeTabIndicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--base-grey-light-80);
  z-index: var(--z-index-below);
  pointer-events: none;

  @media (--hover-devices) {
    transition: transform 0.2s;
  }
}

.TabsListing__sectionWrapper--hidden {
  display: none;
}
