.ArticlePage {
  .Hero,
  .HeroV2 {
    margin-bottom: 2rem;
  }
    
  & .RichtextArea {
    order: 1;
  }

  & .RichtextBlock {
    order: 1;

    &:first-child {
      margin-top: 0;
    }
  }

  & .ArticlePage__twitter {
    order: 2;
    
    @media (--tablet) {
      float: right;
      width: 290px;
      position: relative;
      max-width: 360px;
      min-height: 330px;
      margin: 0 0 1.5rem 2rem;
      right: 15px;
    }
    
    @media (--laptop) {
      float: right;
      width: 335px;
      position: relative;
      right: calc(100% / 2 - 1140px / 2);
    }
  }
}

.ArticlePage--noHero {
  box-shadow: none;
}