.Count--bold {
  @mixin typographyMobileDefaultLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopStrongMedium;

    color: var(--base-grey-dark-80);
  }
}

.Count--pill {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: var(--base-grey-dark-100);
  color: var(--base-grey-white);
  @mixin typographyMobileCapsLarge;

  padding: 2px 6px;
  transition: inherit;
  min-width: 19px;
  min-height: 19px;

  @media (--laptop) {
    @mixin typographyDesktopStrongXSmall;
  }
}
