.ContentCard--wide {
  width: auto;
  height: auto;
  
  & a {
    display: block;
  }
  
  & .ContentCard__body {
    overflow: hidden;
    
    @media (--tablet) {
      display: flex;
    }
  }
  
  & .ContentCard__pictureContainer {
    @media (--tablet) {
      @neat-span-columns 5;
      margin-bottom: 0;
    }
  }
  
  & .ContentCard__picture {
    padding-top: 70%;
  }
  
  & .ContentCard__text {
    @media (--tablet) {
      @neat-span-columns 6;
      padding-bottom: 90px;
      position: relative;

      &.ContentCard__text--noImage {
        @neat-span-columns 12;
      }
    }
  }
    
  & .ContentCard__title {
    font-size: 24px;
    line-height: 1.5;
    
    @media (--tablet) {
      font-size: 28px;
    }
    
  }
  
  & .ContentCard__date {
    @media (--tablet) {
      position: absolute;
      bottom: 35px;
      left: 0;
      font-size: 18px;
      
      & span {
        padding-top: 0;
      }
    }
  }
}
