@define-mixin storyCtaButtonColorVariant $primaryBtnVariant {
  & .StoryCallToAction__button {
    @mixin $(primaryBtnVariant);
  }
}

.StoryCallToAction {
  &.StoryCallToAction--buttonColorWhite {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorWhite;
  }

  &.StoryCallToAction--buttonColorBlack {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorBlack;
  }

  &.StoryCallToAction--buttonColorGreenFour {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorGreenFour;
  }

  &.StoryCallToAction--buttonColorBlueFive {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorBlueFive;
  }

  &.StoryCallToAction--buttonColorTealTwo {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorTealTwo;
  }

  &.StoryCallToAction--buttonColorSandFour {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorSandFour;
  }

  &.StoryCallToAction--buttonColorTealFive {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorTealFive;
  }

  &.StoryCallToAction--buttonColorYellowFour {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorYellowFour;
  }

  &.StoryCallToAction--buttonColorGreenTwo {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorGreenTwo;
  }

  &.StoryCallToAction--buttonColorBlueTwo {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorBlueTwo;
  }

  &.StoryCallToAction--buttonColorSandTwo {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorSandTwo;
  }

  &.StoryCallToAction--buttonColorForestTwo {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorForestTwo;
  }

  &.StoryCallToAction--buttonColorGreenOne {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorGreenOne;
  }

  &.StoryCallToAction--buttonColorGreenFive {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorGreenFive;
  }

  &.StoryCallToAction--buttonColorForestFive {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorForestFive;
  }

  &.StoryCallToAction--buttonColorBlueThree {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorBlueThree;
  }

  &.StoryCallToAction--buttonColorYellowTwo {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorYellowTwo;
  }

  &.StoryCallToAction--buttonColorYellowFive {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorYellowFive;
  }

  &.StoryCallToAction--buttonColorRedFour {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorRedFour;
  }

  &.StoryCallToAction--buttonColorGreyTwo {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorGreyTwo;
  }

  &.StoryCallToAction--buttonColorGreyFive {
    @mixin storyCtaButtonColorVariant buttonPrimaryThemeColorGreyFive;
  }
}
