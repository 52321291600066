.react-autosuggest__suggestions-container {
  display: none;
  position: relative;
}

.react-autosuggest__suggestions-container--open {
  display: flex;
  justify-content: center;
  left: 0;
  width: 100%;
  border: none;
  font-weight: 300;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  transition: all .3s ease;
  overflow: hidden;
  padding: 0;
  height: 80vh;
  position: fixed;

  @media (--tablet) {
    height: auto;
    position: relative;
    overflow: visible;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  top: 30px;
  position: relative;
  display: block;
  width: 85%;
  border: none;
  font-weight: 300;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  height: 100%;
  overflow: auto;
  
  @media (--tablet) {
    overflow: visible;
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 0;
  margin: 0;

  @media (--tablet) {
    padding: 10px 20px;
  }
}

.react-autosuggest__suggestion--highlighted {
  color: var(--whiteColor);
}