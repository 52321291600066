.MicrositeSideBar__menuButtonOuter {
  & .MicrositeSideBar__hamburger {
    position: relative;
    display: block;
    margin: 5px;
    width: 15px;
    height: 40px;

    @media (--laptop) {
      height: 50px;
    }

    @media (--desktop4k) {
      width: 26px;
      height: 90px;
    }

    & .MicrositeSideBar__patty,
    &:before,
    &:after {
      transition: all .5s ease-in-out;
      position: absolute;
      display: block;
      content: "";
      width: 2px;
      height: 100%;
      top: 0;
      opacity: 1;
    }

    & .MicrositeSideBar__patty {
      left: 50%;
      opacity: 1;
    }

    &:before {
      left: 0;
      transform: translateX(-50%);
    }

    &:after {
      left: 100%;
      transform: translateX(50%);
    }
  }

  & .MicrositeSideBar__hamburger.MicrositeSideBar__hamburger--open {
    & .MicrositeSideBar__patty {
      opacity: 0;
    }

    &:before {
      transform: translateX(6px) rotate(15deg);
    }

    &:after {
      transform: translateX(-9px) rotate(-15deg);
    }

    @media (--laptop) {
      & .MicrositeSideBar__patty,
      &:before,
      &:after {
        height: 52px;
      }

      &:before {
        transform: translateX(5px) rotate(15deg);
      }
    }

    @media (--desktop4k) {
      & .MicrositeSideBar__patty,
      &:before,
      &:after {
        height: 92px;
      }

      &:before {
        transform: translateX(12px) rotate(15deg);
      }
  
      &:after {
        transform: translateX(-12px) rotate(-15deg);
      }
    }
  }
}
