:root {
    --calendarItemMobileMaxWidth: 180px;
    --calendarItemLaptopMaxWidth: 230px;
    --calendarItemsGapVertical: var(--itemsGapMobile);
    --calendarItemsGapHorizontal: var(--itemsGapLaptop);
}

.CalendarBlock {
    background-color: var(--themeSecondaryColor_tint_2);
    padding-block: var(--spacing-xl);
    
    @media (--laptop) {
        padding-block: var(--spacing-xxxl);
    }
}

.CalendarBlock__title {
    margin-block: 0 var(--spacing-l);
    color: var(--themeSecondaryColor_tint_6);

    @media (--laptop) {
        margin-block: 0 var(--spacing-m);
    }
}

.CalendarBlock__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 var(--spacing-l);
    gap: var(--calendarItemsGapVertical) var(--calendarItemsGapHorizontal);
    
    @media (--mobileSmall) {
        flex-direction: row;
    }

    @media (--laptop) {
        margin-bottom: var(--spacing-m);
    }
    
    & > li {
        display: block;
        width: 100%;
        max-width: var(--calendarItemMobileMaxWidth);
        
        @media (--laptop) {
            max-width: var(--calendarItemLaptopMaxWidth);
        }
    }
}

.CalendarBlock__itemDate {
    @mixin pLarge;
    margin-block: 0;
}

.CalendarBlock__itemTitle {
    @mixin linkWithArrowMedium;
    margin-block: var(--spacing-xxs) 0;
}

.CalendarBlock__actions {
    @mixin flexCenter;
}
