.flex {
  @media (--tablet) {
    display: flex;
  }
}

.flexCenter {
  @mixin flexCenter;
}

.textCenter {
  text-align: center;
}

.visuallyHidden {
  @mixin visuallyHidden;
}

.verticalMargins {
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (--tablet) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.verticalMargins--small {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.clear {
  @mixin clearfix();

  clear: both;
}

.fullWidth {
  width: 100%;
  max-width: 100%;
}

.Body--overflowHidden {
  overflow: hidden;
}

.hideOnDesktop {
  @media (--desktop) {
    display: none;
  }
}

.hideOnMobile {
  display: none;

  @media (--desktop) {
    display: initial;
  }
}
