.ErrorPage__hero {
  background: var(--base-grey-light-60);
  min-height: 32vh;
  display: flex;
  align-items: center;

  @media (--laptop) {
    min-height: 50vh;
  }
}

.ErrorPage__sectionContent {
  @mixin wrapper var(--wrapperWidth), var(--spacing-xs);

  @media (--laptop) {
    @mixin wrapper var(--wrapperWidth), var(--spacing-s);
  }
}

.ErrorPage__title {
  @mixin typographyMobileDisplayLarge;

  margin-bottom: var(--spacing-xxs);
  max-width: 700px;
  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;

    margin-bottom: var(--spacing-xs);
  }
}

.ErrorPage__description {
  @mixin typographyMobileDefaultXLarge;

  max-width: 890px;
  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopDefaultXLarge;
  }
}

.ErrorPage__linksSection {
  padding: var(--spacing-m) 0 var(--spacing-xxl);

  @media (--laptop) {
    padding: var(--spacing-xxxxxl) 0;
  }
}

.ErrorPage__linksTitle {
  @mixin typographyMobileStrongLarge;

  margin-bottom: calc(var(--spacing-s) + var(--spacing-xxxs));
  max-width: 250px;
  color: var(--base-grey-black-100);

  @media (--tablet) {
    max-width: 550px;
  }

  @media (--laptop) {
    @mixin typographyDesktopStrongLarge;

    margin-bottom: var(--spacing-s);
  }
}

.ErrorPage__linksListItem > a {
  @mixin textButtonLinkUnderline currentColor, 2px;
  @mixin typographyMobileStrongLarge;
  @mixin focusVisible var(--outlineColorSecondary), 2px, 1px;

  color: var(--base-grey-black-100);
}

.ErrorPage__linksList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  @media (--laptop) {
    flex-flow: row wrap;
    gap: var(--spacing-xs) var(--spacing-m);
  }
}
