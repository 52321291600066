.VacancyCard {
  transition: background-color 0.3s ease;
  border-radius: 6px;

  &:hover {
    background-color: rgba(36 99 194 / 0.05); /* var(--kog_blue_tint_9); */
  }

  & a {
    display: flex;

    @media (--mobileSmall) {
      display: block;
    }
  }
}

.VacancyCard__tile {
  transition: border 0.3s ease;
  border: 1px solid var(--kog_blue_300);
  border-radius: 6px;
  position: relative;
  flex: 1;
  min-height: 129px;
  padding: 12px 60px 12px 12px;

  @mixin arrowRightSimple;

  &:hover {
    border: 1px solid var(--kog_blue_tint_9);
    text-decoration: none;
  }

  @media (--tablet) {
    min-height: 169px;
    padding: 12px 60px 12px 12px;
  }

  &::before {
    position: absolute;
    right: 20px;
    bottom: 27px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    z-index: 2;

    @media (--mobileSmall) {
      width: 40px;
      height: 40px;
      right: 27px;
      bottom: 27px;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 12px;
    bottom: 12px;
    z-index: 1;

    @media (--mobileSmall) {
      width: 40px;
      height: 40px;
      right: 16px;
      bottom: 16px;
    }
  }
}

.VacancyCard__title {
  color: var(--blackColor);
  margin: 0;
  font-size: 1rem;
  line-height: 1.33;
  text-transform: none;
  font-weight: 600;
}

.VacancyCard__location,
.VacancyCard__deadline {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
}

.VacancyCard__locationIcon {
  &::before {
    content: "";
    margin-right: 7px;
    width: 15.5px;
    height: 18.5px;
    display: inline-flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: svg-load("Icons/Marker.svg", fill: var(--kog_blue_tint_9));
  }
}

.VacancyCard__deadlineIcon {
  &::before {
    content: "";
    margin-right: 7px;
    width: 14px;
    height: 14px;
    display: inline-flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: svg-load("Icons/Calendar.svg", fill: var(--kog_blue_tint_9));
    position: relative;
    bottom: 4px;
    left: 1px;
  }
}

.VacancyCard__locationText,
.VacancyCard__deadlineText {
  color: var(--blackLighterColor);
  font-size: 0.875rem;
  line-height: 1.33;
  text-transform: none;
  font-weight: 400;
}
