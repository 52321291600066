.OfficeLocationTag {
    @mixin typographyMobileDefaultXSmall;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: var(--accent-blue-accent-lighter);
    color: var(--accent-blue-accent-darker);
    height: var(--spacing-s);
    padding: var(--spacing-xxs);
}
