@define-mixin kogGrid $mobileColumns: 5, $tabletColumns: 12, $gap: 0 {
  --columns: $mobileColumns;
  --gap: $gap;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: var(--gap);

  @media (--tablet) {
    --columns: $tabletColumns;
  }

  /* each item takes full width by default */
  & > * {
    grid-column-end: span var(--columns);
  }
}

@define-mixin kogGridAutoCols $mobileColumns, $tabletColumns, $gap {
  @mixin kogGrid $mobileColumns, $tabletColumns, $gap;

  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

@define-mixin kogGridColumns $prefix, $columns: 12 {
  @for $i from 1 to $columns {
    .$(prefix)-col-$(i) { grid-column-end: span $(i); }
    .$(prefix)-col-start-$(i) { grid-column-start: $(i); }
    .$(prefix)-col-end-$(i) {
      grid-column-end: $(i);
      order: $(i);
    }

    @media (--tablet) {
      .$(prefix)-t-col-$(i) { grid-column-end: span $(i); }
      .$(prefix)-t-col-start-$(i) { grid-column-start: $(i); }
      .$(prefix)-t-col-end-$(i) {
        grid-column-end: $(i);
        order: $(i);
      }
    }

    @media (--laptop) {
      .$(prefix)-l-col-$(i) { grid-column-end: span $(i); }
      .$(prefix)-l-col-start-$(i) { grid-column-start: $(i); }
      .$(prefix)-l-col-end-$(i) {
        grid-column-end: $(i);
        order: $(i);
      }
    }

    @media (--desktop) {
      .$(prefix)-d-col-$(i) { grid-column-end: span $(i); }
      .$(prefix)-d-col-start-$(i) { grid-column-start: $(i); }
      .$(prefix)-d-col-end-$(i) {
        grid-column-end: $(i);
        order: $(i);
      }
    }
  }

  .$(prefix)-col-start { grid-column-start: 1; }
  .$(prefix)-col-end {
    grid-column-end: -1;
    order: 100;
  }

  @media (--tablet) {
    .$(prefix)-t-col-start { grid-column-start: 1; }
    .$(prefix)-t-col-end {
      grid-column-end: -1;
      order: 100;
    }
  }

  @media (--laptop) {
    .$(prefix)-l-col-start { grid-column-start: 1; }
    .$(prefix)-l-col-end {
      grid-column-end: -1;
      order: 100;
    }
  }

  @media (--desktop) {
    .$(prefix)-d-col-start { grid-column-start: 1; }
    .$(prefix)-d-col-end {
      grid-column-end: -1;
      order: 100;
    }
  }
}

@define-mixin kogColumnStart $i: 1 {
  grid-column-start: $(i);
}

@define-mixin kogColumnEnd $i: -1 {
  grid-column-end: $(i);

  @if $i == -1 {
    order: 100;
  } @else {
    order: $(i);
  }
}

@define-mixin kogColumn $i: 1 {
  grid-column-end: span $(i);
}

@define-mixin kogTabletColumnStart $i: 1 {
  @media (--tablet) {
    grid-column-start: $(i);
  }
}

@define-mixin kogTabletColumnEnd $i: -1 {
  @media (--tablet) {
    grid-column-end: $(i);

    @if $i == -1 {
      order: 100;
    } @else {
      order: $(i);
    }
  }
}

@define-mixin kogTabletColumn $i: 1 {
  @media (--tablet) {
    grid-column-end: span $(i);
  }
}

@define-mixin kogLaptopColumnStart $i: 1 {
  @media (--laptop) {
    grid-column-start: $(i);
  }
}

@define-mixin kogLaptopColumnEnd $i: -1 {
  @media (--laptop) {
    grid-column-end: $(i);

    @if $i == -1 {
      order: 100;
    } @else {
      order: $(i);
    }
  }
}

@define-mixin kogLaptopColumn $i: 1 {
  @media (--laptop) {
    grid-column-end: span $(i);
  }
}

@define-mixin kogDesktopColumnStart $i: 1 {
  @media (--desktop) {
    grid-column-start: $(i);
  }
}

@define-mixin kogDesktopColumnEnd $i: -1 {
  @media (--desktop) {
    grid-column-end: $(i);

    @if $i == -1 {
      order: 100;
    } @else {
      order: $(i);
    }
  }
}

@define-mixin kogDesktopColumn $i: 1 {
  @media (--desktop) {
    grid-column-end: span $(i);
  }
}
