@define-mixin quoteTextBoxVariant $backgroundColor, $textColor {
  color: $textColor;

  &::after {
    background-color: $backgroundColor;
  }
}

.Quote__textBox--BlueFive {
  @mixin quoteTextBoxVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color-lighter);
}

.Quote__textBox--Default,
.Quote__textBox--BlueTwo {
  @mixin quoteTextBoxVariant var(--theme-color-blue-dark-bg-color-lighter),
    var(--theme-color-blue-dark-text-color);
}

.Quote__textBox--SandFour {
  @mixin quoteTextBoxVariant var(--theme-color-brown-bg-color-lighter),
    var(--theme-color-brown-text-color);
}

.Quote__textBox--GreenFour {
  @mixin quoteTextBoxVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color-darker);
}

.Quote__textBox--YellowFour {
  @mixin quoteTextBoxVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color-lighter);
}

.Quote__textBox--TealFive {
  @mixin quoteTextBoxVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color);
}

.Quote__textBox--TealTwo {
  @mixin quoteTextBoxVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color);
}

.Quote__textBox--GreenTwo {
  @mixin quoteTextBoxVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color-darker);
}

.Quote__textBox--SandTwo {
  @mixin quoteTextBoxVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color);
}

.Quote__textBox--ForestTwo {
  @mixin quoteTextBoxVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color);
}

.Quote__textBox--White {
  @mixin quoteTextBoxVariant var(--whiteColor), var(--base-grey-black-100);
}

.Quote__textBox--Black {
  @mixin quoteTextBoxVariant #000000, var(--whiteColor);
}

.Quote__textBox--GreenOne {
  @mixin quoteTextBoxVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color);
}

.Quote__textBox--GreenFive {
  @mixin quoteTextBoxVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color);
}

.Quote__textBox--ForestFive {
  @mixin quoteTextBoxVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color);
}

.Quote__textBox--BlueThree {
  @mixin quoteTextBoxVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color);
}

.Quote__textBox--YellowTwo {
  @mixin quoteTextBoxVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color);
}

.Quote__textBox--YellowFive {
  @mixin quoteTextBoxVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color);
}

.Quote__textBox--RedFour {
  @mixin quoteTextBoxVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color);
}

.Quote__textBox--GreyTwo {
  @mixin quoteTextBoxVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color);
}

.Quote__textBox--GreyFive {
  @mixin quoteTextBoxVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color);
}
