.StoryCategoriesList {
  @mixin wrapper;
}

.StoryCategoriesList .StoryCategoriesList__inner {
  margin-bottom: 2rem;

  @media (--tablet) {
    display: flex;
    flex-flow: row nowrap;
  }
}

.StoryCategoriesList__title {
  @media (--tablet) {
    max-width: 250px;
    padding-top: 10px;
  }
}

.StoryCategoriesList__items {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 100%;
  margin: 0 -1.125rem;
  padding: 0;
  list-style: none;

  @media (--tablet) {
    margin-left: 2.5rem;
  }
}

.StoryCategoriesList__item {
  padding: 0 1.125rem 1.2rem;

  @media (--tablet) {
    padding: 0 2.25rem 2.2rem;
  }

  & svg {
    max-width: 48px;
    max-height: 45px;

    @media (--tablet) {
      max-width: 69px;
      max-height: 64px;
    }
  }
}

.StoryPage__bodyText {
    max-width: var(--storiestextwrapperWidth);
}