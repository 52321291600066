:root {
  --mobile-nav-header-link-height: 3.75rem; /* 60px */
}

.NavMobile {
  position: fixed;
  top: var(--mobile-header-top-nav-height);
  right: 0;
  width: 100%;
  transition: transform 0.3s;
  will-change: transform;
  transform: translateX(100%);
  background: var(--whiteColor);
  height: calc(100vh - var(--mobile-header-top-nav-height));

  @media (--laptop) {
    display: none;
  }

  &.NavMobile--opened {
    transform: translateX(0);
  }
}

.NavMobile__logoPlaceholder {
  height: var(--mobile-header-height-without-top-nav);
  border-bottom: 1px solid var(--base-grey-light-80);
}

.NavMobile__content {
  height: calc(100% - var(--mobile-header-height-without-top-nav));
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overflow-y: scroll;

  &.NavMobile__content--withPaddingBottom {
    padding-bottom: var(--mobile-nav-header-link-height);
  }
}

.NavMobile__searchInputWrapper {
  padding: calc(var(--spacing-s) + var(--spacing-xxxs)) var(--spacing-s);
}

.NavMobile__navListWrapper {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 var(--mobile-nav-list-padding) 0 !important;

  &.NavMobile__navListWrapper--withPaddingBottom {
    /* stylelint-disable-next-line declaration-no-important */
    padding-bottom: var(--spacing-s) !important;
  }
}

.NavMobile__microMenuItemsWrapper {
  padding: 0 var(--spacing-s) var(--spacing-s);
}

.NavMobile__microMenuItemsList {
  list-style: none;
  padding: var(--spacing-xs) 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  border-top: 1px solid var(--base-grey-medium-100);
}

.NavMobile__microMenuItem {
  & > a {
    @mixin typographyDesktopDefaultMedium;
    @mixin focusVisible var(--outlineColorSecondary);
  }
}

.NavMobile__headerLinkWrapper {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 var(--spacing-s);
  background: var(--whiteColor);
  box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 0.25);
  height: var(--mobile-nav-header-link-height);
}

.NavMobile__headerLink {
  color: var(--blackColor);
}
