/* stylelint-disable plugin/declaration-block-no-ignored-properties */
/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-class-pattern */
.ProductPage {
  position: relative;
  
  .HeroV2 {
    & .HeroV2__container,
    & .HeroV2__picture {
      height: calc(100vh - var(--mobile-header-height) - var(--navbar-mobile-height));
    }

    & .HeroV2__picture {
      & img {
        height: calc(100vh - var(--mobile-header-height) - var(--navbar-mobile-height));
      }
    }
  }
}

.ProductPage__richtext,
.ProductPage__richtextBlock .LinkSection,
.ProductPage__richtextBlock .TextBlock {
  @mixin wrapper;

  padding-left: 0;

  @media (--laptop) {
    padding-left: 5.5rem;
    padding-right: calc(var(--sidebarWidth) - 5.5rem);
  }
}

:root .ProductPage .layout--2col__image picture {
  display: flex !important;
  align-items: center;
}

:root .ProductPage .layout--2col__image img {
  left: auto !important;
  top: auto !important;
  width: 100% !important;
  height: auto !important;
  margin: auto !important;
  position: static !important;
  max-width: 100% !important;
}

.ProductPage__body {
  margin-top: var(--spacing-xxxl);

  & *[id] {
    scroll-margin-top: var(--sections-navbar-scroll-margin);
  }
}

.CourseSection {
  margin: 0;
  padding: 0;
  list-style: none;
}

.CourseSection__item {
  margin-bottom: 1rem;

  @media (--tablet) {
    margin-bottom: 1.9rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.CourseSection__description {
  margin-bottom: 1rem;

  @media (--tablet) {
    padding-top: 1.9rem;
  }
}

.CourseSection__link {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 35px;

  @mixin arrowRightSimple var(--buttonExploreColor);

  &::after {
    left: 0;
  }
}

.CourseSection__schedule-heading {
  margin: 2rem 0 0;
}

.CourseSection__schedule {
  margin: 0;
  padding: 0 0 1rem;
  list-style: none;

  @media (--tablet) {
    padding-bottom: 2.75rem;
  }
}

.CourseSection__row {
  margin-bottom: 1.125rem;

  @media (--tablet) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid var(--kog_blue_tint_1);
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &.CourseSection__row--head {
    display: none;

    @media (--tablet) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    &::after {
      content: "";
      flex: 1;
      display: block;
      width: 100%;
      max-width: 164px;
    }
  }
}

.CourseSection__label {
  font-weight: 400;

  @media (--tablet) {
    display: block;
    font-size: 1.375rem;
  }

  &.CourseSection__label--inline {
    margin-right: 0.5rem;
    padding: 0;

    @media (--tablet) {
      display: none;
    }
  }
}

.CourseSection__label,
.CourseSection__value {
  padding: 0.5rem 0;

  @media (--tablet) {
    flex: 1;
    max-width: 126px;
    padding: 1rem 0;
  }
}

.CourseSection__value {
  display: block;

  &:last-child {
    @media (--tablet) {
      max-width: 164px;
    }
  }

  & .Btn {
    min-width: auto;
  }
}

/* TODO To remove after moving to "Hero block" approach */
.ProductPageHeader__buttons {
  z-index: 10;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-l);
}