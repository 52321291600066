@define-mixin ctaColorVariant $backgroundColor, $fontColor, $primaryBtnVariant, $secondaryBtnVariant {
  & .CallToAction__background,
  &.CallToAction__background {
    color: $fontColor;
    background-color: $backgroundColor;

    & svg {
      &.UpperContent__fillIcon{
        fill: $fontColor;
      }

      &.UpperContent__strokeIcon{
        stroke: $fontColor;
      }
    }

    & .UpperContent__additionalInfo{
      & *:not(:first-child) {
        border-color: $fontColor;
      }
    }

    & .CallToAction__buttons {
      & li {
          & .CallToActionButton--primary:not(.CallToActionButton--colored) {
            @mixin $(primaryBtnVariant);
          }

          & .CallToActionButton--secondary:not(.CallToActionButton--colored) {
            @mixin $(secondaryBtnVariant);
          }
      }
    }
  }
}

.CallToAction {
  &.CallToAction--backgroundColorDefault,
  &.CallToAction--backgroundColorNone,
  &.CallToAction--backgroundColorGreyTwo {
    @mixin ctaColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color), buttonPrimaryThemeColorGreyTwo,
      buttonSecondaryThemeColorGreyTwo;
  }

  &.CallToAction--backgroundColorGreenFour {
    @mixin ctaColorVariant var(--theme-color-green-bg-color), var(--theme-color-green-text-color),
      buttonPrimaryThemeColorGreenFour, buttonSecondaryThemeColorGreenFour;
  }

  &.CallToAction--backgroundColorBlueFive {
    @mixin ctaColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color),
      buttonPrimaryThemeColorBlueFive, buttonSecondaryThemeColorBlueFive;
  }

  &.CallToAction--backgroundColorTealTwo {
    @mixin ctaColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color), buttonPrimaryThemeColorTealTwo,
      buttonSecondaryThemeColorTealTwo;
  }

  &.CallToAction--backgroundColorSandFour {
    @mixin ctaColorVariant var(--theme-color-brown-bg-color), var(--theme-color-brown-text-color),
      buttonPrimaryThemeColorSandFour, buttonSecondaryThemeColorSandFour;
  }

  &.CallToAction--backgroundColorTealFive {
    @mixin ctaColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color), buttonPrimaryThemeColorTealFive,
      buttonSecondaryThemeColorTealFive;
  }

  &.CallToAction--backgroundColorYellowFour {
    @mixin ctaColorVariant var(--theme-color-yellow-bg-color), var(--theme-color-yellow-text-color),
      buttonPrimaryThemeColorYellowFour, buttonSecondaryThemeColorYellowFour;
  }

  &.CallToAction--backgroundColorGreenTwo {
    @mixin ctaColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color), buttonPrimaryThemeColorGreenTwo,
      buttonSecondaryThemeColorGreenTwo;
  }

  &.CallToAction--backgroundColorBlueTwo {
    @mixin ctaColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color), buttonPrimaryThemeColorBlueTwo,
      buttonSecondaryThemeColorBlueTwo;
  }

  &.CallToAction--backgroundColorSandTwo {
    @mixin ctaColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color), buttonPrimaryThemeColorSandTwo,
      buttonSecondaryThemeColorSandTwo;
  }

  &.CallToAction--backgroundColorForestTwo {
    @mixin ctaColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color), buttonPrimaryThemeColorForestTwo,
      buttonSecondaryThemeColorForestTwo;
  }

  &.CallToAction--backgroundColorGreenOne {
    @mixin ctaColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color), buttonPrimaryThemeColorGreenOne,
      buttonSecondaryThemeColorGreenOne;
  }

  &.CallToAction--backgroundColorGreenFive {
    @mixin ctaColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color), buttonPrimaryThemeColorGreenFive,
      buttonSecondaryThemeColorGreenFive;
  }

  &.CallToAction--backgroundColorForestFive {
    @mixin ctaColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color), buttonPrimaryThemeColorForestFive,
      buttonSecondaryThemeColorForestFive;
  }

  &.CallToAction--backgroundColorBlueThree {
    @mixin ctaColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color), buttonPrimaryThemeColorBlueThree,
      buttonSecondaryThemeColorBlueThree;
  }

  &.CallToAction--backgroundColorYellowTwo {
    @mixin ctaColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color), buttonPrimaryThemeColorYellowTwo,
      buttonSecondaryThemeColorYellowTwo;
  }

  &.CallToAction--backgroundColorYellowFive {
    @mixin ctaColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color), buttonPrimaryThemeColorYellowFive,
      buttonSecondaryThemeColorYellowFive;
  }

  &.CallToAction--backgroundColorRedFour {
    @mixin ctaColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color), buttonPrimaryThemeColorRedFour,
      buttonSecondaryThemeColorRedFour;
  }

  &.CallToAction--backgroundColorGreyFive {
    @mixin ctaColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color), buttonPrimaryThemeColorGreyFive,
      buttonSecondaryThemeColorGreyFive;
  }
}
