.OfficeLocations {
  margin: 0 0 120px;
}

.OfficeLocationsItemList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  @media (--laptop) {
    @mixin wrapper;
  }
}

.OfficeLocationsFiltersList {
  display: grid;
  grid-template-columns: 1fr;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  @media (--tablet) {
    grid-template-columns: 1fr;
    gap: 0 calc(var(--spacing-xs) + var(--spacing-xxxs));
  }

  @media (--laptop) {
    @mixin wrapper;

    grid-template-columns: repeat(4, minmax(0, 1fr)) auto;
    align-items: flex-end;
  }

  & input[type="text"] {
    margin-top: 0;
  }
}

.OfficeLocationsFiltersList__item {
  margin-bottom: 20px;
}

.OfficeLocationsTypes {
  @mixin wrapper;
  margin-bottom: 30px;
}

.OfficeLocationsTypes__container {
  @neat-outer-container;
  border: none;
  padding: 0;
  margin: 0;

  & > div,
  & > legend {
    @neat-span-columns 6;

    @media (--mobileOnly) {
      @neat-omega 2n + 1;
    }
    
    @media (--tablet) {
      @neat-span-columns 2;
      @neat-omega 6n;
    }
  }
}

.OfficeLocationsTypes__legend {
  padding: 0;
}

.OfficeLocations__map {
  @mixin wrapper;
  width: 100%;
  
  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 62.50%;
    background: url('/wwwroot/Static/Images/Common/world-map.png') no-repeat center center;
    background-size: contain;
  }
}

.OfficeLocation__buttons {
  @mixin wrapper;
  text-align: center;
  
  & button {
    margin: 0 auto;
  }
}

.OfficeLocations__showMoreContainer {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: 1rem;
}