.SimpleTile {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @mixin focusVisible var(--outlineColorSecondary);
}

.SimpleTile__imageWrapper {
  overflow: hidden;
  flex-basis: content;
  aspect-ratio: 1 / 1;
  background-color: color(#e8e8e8 a(20%));
  border-radius: 2px;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.SimpleTile__name {
  @mixin typographyDesktopStrongLarge;

  margin-top: calc(var(--spacing-xxs) + var(--spacing-xxxs));
}

.SimpleTile__labels {
  display: flex;
  flex-wrap: wrap;
  gap: calc(1.25 * var(--spacing-xxxs));
  position: absolute;
  top: var(--spacing-xxs);
  left: var(--spacing-xxs);
  width: calc(100% - var(--spacing-s));
}

.SimpleTile__tagline {
  @mixin typographyDesktopDefaultMedium;
}
