.layout--2col {
  @neat-outer-container;

  @media (--tablet) {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.layout--2col__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable-next-line selector-class-pattern */
:root .layout--2col__image picture {
  width: 100%;
  height: 250px;

  @media (--tablet) {
    width: 100%;
    height: 80%;
  }
}

.wrapper {
  @mixin wrapper;

  position: relative;
}

.wrapperStretched {
  @mixin wrapperStretched;
}
