.ImageWithFactsViewerModal {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-image-with-facts-viewer-modal-overlay);
  background: color(#090909 a(50%));
}

.ImageWithFactsViewerModal__container {
  @mixin wrapper;

  display: grid;
  grid-template-rows: auto 1fr;
  background-color: #f5f5f5;
  border-radius: var(--spacing-xxs);
  max-height: calc(100% - (var(--spacing-s) * 2));
  padding: var(--spacing-xs) calc(var(--spacing-xs) + var(--spacing-xxxs)) var(--spacing-xl);
  margin-block: var(--spacing-s);

  @media (--tablet) {
    padding: var(--spacing-l) var(--spacing-l) var(--spacing-xxxl);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-block: 0;
  }
}

.ImageWithFactsViewerModal__header {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: var(--spacing-xxs);
  border-bottom: 1px solid var(--base-black-100);
  padding-bottom: var(--spacing-s);
  margin-bottom: var(--spacing-s);

  @media (--tablet) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: var(--spacing-m);
    margin-bottom: var(--spacing-m);
  }
}

.ImageWithFactsViewerModal__headerCloseButton {
  @mixin resetButtonStylesNoOverride;

  display: block;
  color: var(--blackColor);
  font-size: 2rem;
  cursor: pointer;
  order: 1;

  @mixin focusVisible var(--outlineColorSecondary);

  & svg {
    transform: rotate(45deg);
  }

  @media (--tablet) {
    order: 0;
  }
}

.ImageWithFactsViewerModal__headerHeading {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--spacing-xxs);
  order: 2;

  @media (--tablet) {
    order: 0;
    width: auto;
  }
}

.ImageWithFactsViewerModal__headerLetter {
  @mixin typographyDesktopStrongMedium;

  color: var(--base-grey-black-100);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--base-grey-black-100);
  width: 2rem;
  height: 2rem;
}

.ImageWithFactsViewerModal__headerTitle {
  @mixin typographyMobileStrongXLarge;

  color: var(--blackColor);
  line-height: 2rem;
}

.ImageWithFactsViewerModal__content {
  overflow: hidden auto;
  color: var(--base-grey-black-100);

  &.ImageWithFactsViewerModal__content--withImage {
    display: grid;
    row-gap: var(--spacing-xl);

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
      column-gap: var(--spacing-xxxxxl);
    }
  }

  & figure {
    margin: 0;
  }
}

.ImageWithFactsViewerModal__text {
  &,
  & p {
    font-size: 0.875rem;
    line-height: 1.4;

    @media (--tablet) {
      @mixin typographyDesktopDefaultLarge;

      line-height: 1.3;
    }
  }

  & p {
    margin-block: 0;

    &:not(:last-child) {
      margin-block: 0 var(--spacing-xs);

      @media (--tablet) {
        margin-block: 0 var(--spacing-s);
      }
    }
  }
}

.ImageWithFactsViewerModal__figure {
  & .ImageWithFactsViewerModal__caption {
    @media (--tablet) {
      margin-right: calc(var(--spacing-xxxxxl) + var(--spacing-xxxs));
    }

    &,
    & p {
      margin-top: calc(var(--spacing-xxs) + var(--spacing-xxxs));
      line-height: 1.5;
    }
  }
}
