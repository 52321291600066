.StoryHeaderInfo--themeDark {
  color: var(--whiteColor);

  &:not(.StoryHeaderInfo__Background) {
    background-color: var(--blackColor);
  }

  & .StoryHeaderInfo__background {
    &::before {
      background-color: var(--blackColor);
      opacity: var(--storiesBlockInitialOpacity);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.animate-opacity::before {
      opacity: var(--storiesBlockFinalOpacity);
    }
  }

  & .StoryHeaderInfo__description a {
    color: var(--whiteColor);
    text-decoration: underline;
  }

  & .StoriesBreadcrumbs {
    & a {
      color: var(--whiteColor);
    }

    & li:first-of-type::before {
      background: svg-load("Icons/CrumbArrow.svg", fill: var(--whiteColor)) no-repeat center;
    }

    & li::after {
      background: svg-load("Icons/CrumbArrow.svg", fill: var(--whiteColor)) no-repeat center;
    }
  }
}

.StoryHeaderInfo--themeLight {
  color: var(--textColor);

  &:not(.StoryHeaderInfo__Background) {
    background-color: var(--whiteColor);
  }

  & .StoryHeaderInfo__headerText,
  & .StoryHeaderInfo__content {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--whiteColor);
      opacity: var(--storiesBlockInitialOpacityLight);
      z-index: 1;
    }
  }

  & .StoryHeaderInfo__content {
    overflow: hidden;
  }

  & .StoryHeaderInfo__contentWrapper {
    position: relative;
    z-index: 2;
  }

  & .StoryHeaderInfo__blur {
    display: none;

    @media (--laptop) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% + 40px);
      background-position: 50%;
      background-size: cover;
      background-attachment: fixed;
      filter: blur(10px);
      z-index: 0;
    }
  }

  & .StoriesBreadcrumbs {
    & a {
      color: var(--textColor);
    }

    & li::after {
      background: svg-load("Icons/CrumbArrow.svg", fill: var(--textColor)) no-repeat center;
    }
  }

  & .StoryContactInfo__link {
    color: var(--textColor);

    &.Link--PhoneWhite {
      @mixin iconPhone black;
    }

    &.Link--EmailWhite {
      @mixin iconEmail black;
    }
  }

  & .StoryHeaderInfo__category {
    & svg path {
      fill: black;
    }
  }
}
