.ContentCard {
  width: 100%;
  margin-bottom: var(--spacing-l);

  @media (--tablet) {
    margin-bottom: var(--spacing-m);
  }

  &:hover {
    & .ContentCard__picture::before {
      opacity: 0.1;
    }
  }

  &:hover .ContentCard__arrow::after {
    @mixin arrowLinkRightAnimation;
  }

  & a {
    color: var(--textColor);
    text-decoration: none;
    word-break: break-word;
    height: 100%;
  }

  & p {
    margin: 0;
  }
}

.ContentCard__pictureContainer {
  width: 100%;
  margin-bottom: var(--spacing-xs);
}

.ContentCard__footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 5px;
}

/* stylelint-disable-next-line selector-class-pattern */
.ContentCard__footer-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & .ContentCard__link {
    flex: unset;
    margin-right: 10px;
  }
}

.ContentCard__picture {
  position: relative;
  padding-top: 220px;
  overflow: hidden;

  & picture {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.6s ease;
  }

  & img {
    min-height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.35s ease-in-out;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    z-index: 2;
    display: block;
    transition: all 0.3s ease;
    background: linear-gradient(to bottom, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.5) 75%);
  }
}

.ContentCard__title {
  margin: 0 0 var(--spacing-xxs);
  text-transform: none;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 20px;
  line-height: 1.5;

  & + p {
    margin-top: 4px;
  }

  &:only-child {
    margin: 0;
  }
}

.ContentCard__title--capitalized {
  text-transform: uppercase;
}

.ContentCard__date {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 14px;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 16px;
  }

  & span {
    padding-top: 15px;
    display: block;

    @media (--tablet) {
      padding-top: 24px;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.ContentCard__link {
  margin-top: 23px;
  flex: 1;
}

.ContentCard__categories {
  list-style: none;
  margin: 0 0 4px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.ContentCard__category {
  display: block;
  margin: 0 16px 16px 0;
  width: auto;
  border-radius: 3px;

  &:last-child {
    margin-right: 0;
  }
}

.ContentCard__categoryButton {
  color: var(--whiteColor);
  height: 25px;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 14px;
  line-height: 1.2;
  padding: 4px 12px;
  background-color: color(var(--kog_blue_tint_1) a(80%));
  outline: 1px solid transparent;
  outline-offset: 1px;
  border: none;
  font-family: inherit;
  appearance: none;
  text-decoration: none;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid color(var(--kog_blue_tint_1) a(80%));
  }
}

.ContentCard__categoryButton--disabled {
  cursor: default;
}

.ContentCard__arrow {
  @mixin linkWithArrowSmall;

  margin: 0;
}
