.ChartTile {
  background: var(--brandColor_dark_tint_6);
  padding: 1rem 1rem 0.5rem 1rem;
  font-size: 20px;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  
  @media (--tablet) {
    font-size: 22px;
  }

  @media (--laptop) {
    font-size: 24px;
  }
  
  & p {
    width: 100%;
    margin-bottom: 0.3rem;
  }
  
  & span {
    font-size: 14px;
    display: block;
    text-align: center;
    min-height: 20px;
  }
} 