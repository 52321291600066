/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-property-unit-allowed-list */
/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-id-pattern */
#onetrust-consent-sdk {
  & #onetrust-pc-sdk {
    max-width: 765px;
    width: 100%;
    box-shadow: none;
    border-radius: 0;

    & > div {
      display: flex;
      flex-direction: column;
    }

    & .ot-pc-header {
      height: 60px;
      border-bottom: none;
      padding: 0;
      flex: 0 0 auto;

      @media (--tablet) {
        height: 72px;
      }

      @media (--laptop) {
        height: 88px;
      }

      & .ot-pc-logo {
        display: none;
        visibility: hidden;
      }

      & #close-pc-btn-handler {
        width: 40px;
        height: 40px;
        background: var(--kog_blue_tint_6) !important;
        border-radius: 50%;
        top: 10px;
        right: 10px;
        margin: 0;
        padding: 0;
        transform: scale(0.8);

        @media (--tablet) {
          top: 16px;
          right: 20px;
        }

        @media (--laptop) {
          top: 32px;
          right: 32px;
          transform: none;
        }

        &:hover {
          background: var(--cookie-banner-primary-button-hover) !important;
          border-color: var(--cookie-banner-primary-button-hover);
          color: var(--whiteColor);
          opacity: 1;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 2px;
          height: 24px;
          border-radius: 15px;
          top: 50%;
          bottom: 50%;
          background: var(--blackColor);
        }

        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    & #ot-pc-content {
      position: static;
      height: auto;
      flex: 1 1 auto;
      width: calc(100% - 32px);
      margin: 0 8px 0 16px;
      padding: 0 8px 0 0;

      @media (--tablet) {
        width: calc(100% - 48px);
        margin: 0 16px 0 24px;
      }

      @media (--laptop) {
        width: calc(100% - 98px);
        margin: 0 32px 0 48px;
        padding: 0 16px 0 0;
      }

      &.ot-pc-scrollbar {
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #c6c6c7;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }
      }

      & #ot-pc-title {
        color: var(--kog_blue_tint_6) !important;
        font-size: 20px;
        margin: 0 0 8px;
        line-height: 1.2;
        float: none;
        font-weight: 400;

        @media (--laptop) {
          font-size: 24px;
          line-height: 1;
          margin: 0 0 16px;
        }
      }

      & #ot-pc-desc {
        color: var(--kog_blue_tint_6) !important;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 16px;

        @media (--laptop) {
          margin-bottom: 24px;
        }
      }

      & #accept-recommended-btn-handler {
        display: inline-block !important;
        background: var(--kog_blue_tint_6) !important;
        border-color: var(--kog_blue_tint_6) !important;
        color: var(--kog_blue) !important;
        text-transform: uppercase;
        border-radius: 0;
        margin: 0;
        font-weight: 400;
        font-size: 13px;
        padding: 0;
        max-width: none;
        position: absolute;
        bottom: calc(16px + 40px + 8px);
        right: 16px;
        height: 40px;
        line-height: 38px;
        width: calc(50% - 16px - 4px);
        border-width: 2px;

        @media (--mobileSmall) {
          height: 44px;
          line-height: 42px;
          bottom: calc(16px + 44px + 8px);
        }

        @media (--tablet) {
          font-size: 15px;
          width: 167px;
          bottom: 12px;
          right: calc(24px + 167px + 16px);
        }

        @media (--laptop) {
          bottom: 24px;
          right: calc(32px + 167px + 16px);
          height: 48px;
          line-height: 46px;
        }

        &:hover {
          background: var(--cookie-banner-primary-button-hover) !important;
          border-color: var(--cookie-banner-primary-button-hover) !important;
          color: var(--kog_blue) !important;
          opacity: 1;
        }
      }

      & .ot-sdk-row.ot-cat-grp {
        margin-top: 32px;

        @media (--laptop) {
          margin-top: 48px;
        }
      }

      & #ot-category-title {
        color: var(--whiteColor) !important;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 16px;
        padding: 0;
        float: none;

        @media (--laptop) {
          font-size: 24px;
          line-height: 1;
          margin-bottom: 24px;
        }
      }

      & .ot-cat-grp {
        & .ot-cat-item {
          background: var(--kog_blue_tint_6);
          border: 0;
          height: auto;
          float: none;
          width: 100%;
          border-radius: 0;
          margin-top: 8px;
          display: block;
          flex-direction: column;

          &:first-of-type {
            margin-top: 0;
          }

          & > button {
            border-radius: 0;
            padding: 8px;

            &[aria-expanded="true"] ~ .ot-acc-hdr .ot-plus-minus span:first-of-type {
              display: none;
            }

            &[aria-expanded="true"] ~ .ot-acc-hdr .ot-plus-minus span:last-of-type {
              transform: none;
            }

            &[aria-expanded="true"] ~ .ot-acc-txt {
              padding-bottom: 0;
              max-height: none;
              margin-bottom: -8px;
            }
          }

          & .ot-acc-hdr {
            height: 24px;
            min-height: 24px;
            flex: 0 0 24px;
            padding: 20px 32px;
            width: calc(100% - 64px);

            &.ot-always-active-group {
              & .ot-always-active {
                color: var(--kog_blue);
                font-weight: 400;
                font-size: 12px;
                line-height: 1;
                right: 16px;
                width: 34px;
                text-align: center;

                @media (--laptop) {
                  line-height: 1;
                  right: 32px;
                  width: auto;
                  text-align: left;
                }
              }
            }

            & .ot-plus-minus {
              width: 16px;
              height: 16px;
              top: 50%;
              transform: translateY(-50%) scale(0.85);
              left: 16px;
              position: absolute;
              margin-right: 0;

              @media (--laptop) {
                transform: translateY(-50%);
                left: 32px;
              }

              & span {
                background: var(--blackLighterColor);

                &:first-of-type {
                  width: 1px;
                  left: 50%;
                  top: 0;
                  bottom: 0;
                  transform: translateX(-50%);
                }

                &:last-of-type {
                  height: 1px;
                  top: 50%;
                  left: 0;
                  right: 0;
                  transform: translateY(-50%);
                }
              }
            }

            & .ot-cat-header {
              color: var(--kog_blue);
              font-weight: 400;
              font-size: 15px;
              line-height: 1.7;
              margin-left: 16px;
              width: calc(100% - 32px - 16px);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              @media (--laptop) {
                font-size: 18px;
                line-height: 1.5;
                margin-left: 46px;
                width: auto;
              }
            }
          }

          & .ot-acc-txt {
            display: block;
            max-height: 0;
            height: auto;
            overflow: hidden;
            z-index: 2;
            background: var(--whiteColor);
            transition: none;

            & .ot-category-desc {
              color: var(--blackLighterColor);
              font-weight: 300;
              font-size: 16px;
              padding: 16px 20px;
              margin: 0;
              width: calc(100% - 40px);
              float: none;
            }
          }
        }

        & .ot-accordion-layout {
          & h4 ~ .ot-tgl,
          & h4 ~ .ot-always-active {
            right: 0 !important;

            @media (--laptop) {
              right: 20px;
            }
          }
        }
      }
    }

    & .ot-pc-footer {
      border: 0;
      position: static;
      flex: 0 0 auto;

      @media (--tablet) {
        height: 100%;
        max-height: 72px;
      }

      @media (--laptop) {
        max-height: 96px;
      }

      & .ot-btn-container {
        margin: 16px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 32px);
        padding: 0;

        @media (--tablet) {
          display: block;
          margin: 16px 24px;
          width: calc(100% - 48px);
        }

        @media (--laptop) {
          margin: 24px 32px 0;
          width: calc(100% - 64px);
          display: block;
        }

        & > button {
          background: transparent !important;
          font-weight: 400;
          font-size: 13px;
          text-align: center;
          text-transform: uppercase;
          color: var(--kog_blue_tint_6) !important;
          border: 2px solid var(--kog_blue_tint_6) !important;
          margin-top: 0;
          padding: 0;
          width: 167px;
          height: 40px;
          line-height: 38px;
          letter-spacing: 0;
          border-radius: 0;
          flex: 0 0 auto;
          max-width: none;
          min-width: min-content;
          white-space: nowrap;

          @media (--mobileSmall) {
            height: 44px;
            line-height: 42px;
          }

          @media (--tablet) {
            font-size: 15px;
            width: 167px;
          }

          @media (--laptop) {
            height: 48px;
            line-height: 46px;
          }

          &:hover {
            color: var(--cookie-banner-primary-button-hover) !important;
            border: 2px solid var(--cookie-banner-primary-button-hover) !important;
            opacity: 1;
          }
        }

        & .ot-pc-refuse-all-handler {
          color: var(--kog_blue_tint_6) !important;
          align-self: flex-start;
          margin: 0 8px 8px 0;
          width: calc(50% - 4px);

          @media (--tablet) {
            width: 167px;
            margin: 0 calc(8px + 167px + 12px) 0 0;
          }
        }

        & .save-preference-btn-handler {
          background: var(--kog_blue_tint_6) !important;
          border-color: var(--kog_blue_tint_6) !important;
          color: var(--kog_blue) !important;
          margin: 0;
          width: 100%;

          @media (--tablet) {
            width: 167px;
            margin: 0 0 0 8px;
          }

          &:hover {
            background: var(--cookie-banner-primary-button-hover) !important;
            border-color: var(--cookie-banner-primary-button-hover) !important;
            color: var(--kog_blue) !important;
          }
        }
      }

      & .ot-pc-footer-logo {
        display: none;
        visibility: hidden;
      }
    }

    & .ot-tgl {
      height: 25px;

      & input:checked + .ot-switch .ot-switch-nob {
        background: var(--kog_blue_tint_2);
        border: 1px solid var(--kog_blue_tint_2);
      }

      & input:checked + .ot-switch .ot-switch-nob::before {
        transform: translateX(23px);
      }
    }

    & .ot-switch {
      width: 47px;
      height: 25px;

      & .ot-switch-nob {
        background: var(--kog_blue_tint_4);
        border: 1px solid var(--kog_blue_tint_4);
        border-radius: 15px;
        outline: none;

        &::before {
          border-radius: 15px;
          background: var(--kog_blue);
          width: 23px;
          height: 23px;
          bottom: 0;
          left: 0;
          border: 0;
        }
      }
    }
  }
}
