.KeyFigures {
  margin: 2rem 0;

  @media (--tablet) {
    margin: 2rem 0 4.5rem 0;
  }
}

.KeyFigures__inner {
  margin-bottom: 1rem;

  @media (--tablet) {
    margin-bottom: 3rem;
  }
}