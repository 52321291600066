.Pill {
  display: inline-flex;
  position: relative;
}

.Pill__label {
  display: inline-block;
  cursor: pointer;
}

.Pill__inner {
  display: flex;
  background: var(--base-grey-light-80);
  color: var(--base-grey-dark-100);
  border-radius: 20px;
  padding: 2px 9px;
  transition:
    0.25s background-color,
    0.25s color;
}

.Pill__input {
  @mixin visuallyHidden;
}

.Pill__labelText {
  display: block;
  font-size: 1rem; /* On Figma it is much larger than what it tells it is */
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
}

.Pill__label > .Pill__input:checked + .Pill__inner {
  background-color: var(--base-grey-dark-100);
  color: var(--base-grey-light-80);
}

.Pill__label > .Pill__input:focus-visible + .Pill__inner {
  outline: var(--outlineColor) solid 2px;
}
