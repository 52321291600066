.LatestIssue {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (--tablet){
    max-width: 340px;
    margin-bottom: 60px;
  }

  & picture {
    object-fit: fill;
    & img {
      width: 100%;
      object-fit: fill;
    }
  }

  & .LatestIssue__text {
    margin: 8px auto;
    text-align: center;
  }
}