.FullScreenVideoModal__closeBtn {
  @mixin resetButtonStyles;
  width: 2.5rem;
  height: 2.5rem;
  background: svg-load('Icons/Close.kog.svg', fill: var(--whiteColor)) no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
}

.FullScreenVideoModal__player {
  & video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;

    @media (--tablet) {
      max-width: 85%;
      max-height: 85%;
    }
  }
}
