.MicrositeProduct__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.MicrositeProduct__listItem {
  position: absolute;
}

.MicrositeProductHotSpot {
  @mixin resetButtonStyles;
  cursor: pointer;
}

.MicrositeProductHotSpot__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 350px;
  height: 195px;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  
  @media (--desktop4k) {
    width: 750px;
    height: 420px;
  }

  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(50, 56, 61, 0.56);
  }
}

.MicrositeProductHotSpot__content {
  text-align: center;
  color: var(--whiteColor);
  z-index: 0;
}

.MicrositeProductHotSpot__preview {
  font-size: 20px; 
  
  @media (--desktop4k){
    font-size: 42px;
  }
}

.MicrositeProductHotSpot__contentIcon {
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 16px auto 0;

  @media (--desktop4k) {
    width: 96px;
    height: 96px;
  }

  &:after {
    @mixin circleOutline;
    content: "";
  }

  &:before {
    @mixin absoluteCenter;
    display: block;
    content: "";
  }

  &.MicrositeProductHotSpot__contentIcon--gallery {
    &:before {
      @mixin fullScreenIcon;
    }
  }

  &.MicrositeProductHotSpot__contentIcon--video {
    &:before {
      left: calc(50% + 1px);
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 13px solid var(--whiteColor);

      @media (--desktop4k) {
        left: calc(50% + 3px);
        border-top-width: 24px;
        border-bottom-width: 24px;
        border-left-width: 26px;
      }
    }
  }
}

.MicrositeProductHotSpot__image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  
  & img {
    width: 100%;
  }
}
