.MicrositeProductGallery {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10% 16px;
  width: 100%;
  height: 100%;

  @media (--tablet) {
    padding: 10% 15%;
  }

  & .MicrositeProductGallery__button {
    @mixin sectionPageCloseButtonPosition var(--sectionSideOffset);
  }
}
