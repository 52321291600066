.VesselTypeTeaser {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  @media (--tablet) {
    flex-flow: row nowrap;
  }

  &:hover {
    & .VesselTypeTeaser__img::before {
      opacity: 0.1;
    }

    & .VesselTypeTeaser__boxLink {
      text-decoration: underline;
      color: var(--whiteColor);
    }
  }
  
  & a {
    color: var(--whiteColor);
  }  
  
  & a:visited {
    color: var(--whiteColor);
  }
}

.VesselTypeTeaser__img {
  width: 100%;
  max-height: 300px;
  height: 55vw;
  background-color: var(--brandColor_tint_2);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    transition: all 0.3s ease;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 75%);
    pointer-events: none;
    z-index: 1;
  }

  @media (--tablet) {
    width: calc(100% - 390px);
    height: initial;
    max-height: initial;
  }

  & img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

_:-ms-fullscreen, :root .VesselTypeTeaser__img {
  @media (--tablet) {
    height: auto;
    max-height: none;
  }
}

.VesselTypeTeaser__link {
  display: block;
  width: 100%;
  color: var(--whiteColor);
  
  & picture {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    display: block;
  }
}

.VesselTypeTeaser__text {
  width: 100%;

  @media (--tablet) {
    width: 360px;
  }
}

.VesselTypeTeaser__box {
  color: var(--whiteColor);
  padding: 24px 24px 120px 24px;
  min-height: 200px;
  position: relative;
  max-width: 100%;
  background: svg-load('Common/Background.svg',
  fill: var(--brandColor)) no-repeat;
  background-position: bottom 0 right 0;

  @media (--tablet) {
    max-width: 360px;
    padding: 24px 24px 90px 24px;
    min-height: 327px;
  }
}

.VesselTypeTeaser__boxTitle {
  font-size: 24px;
  margin: 0 0 0.4em;

  @media (--tablet) {
    font-size: 32px;
  }
}

.VesselTypeTeaser__boxLink {
  position: absolute;
  bottom: 40px;
  padding-left: 15px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 20px;
  max-width: 65vw;
  line-height: 1.2;
  color: var(--whiteColor);

  @media (--tablet) {
    max-width: initial;
  }
}

.VesselTypeTeaser__boxLink::before {
  content: "";
  position: absolute;
  top: calc(50% - 7px);
  left: -2px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: svg-load('Icons/ArrowRight.svg',
  fill: var(--whiteColor)) no-repeat;
  background-size: contain;
}