.VesselSegmentsLandingPage {
  & .ImageHotSpots__Container--limited {
    @mixin wrapper;

    margin-top: 0;

    & .ImageHotSpots__ContainerInner {
      position: relative;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  & .ContainToLayout {
    margin: 0 auto;
    width: calc(100% - 30px);
  }
}

.VesselSegmentsLandingPage__introductionSection {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  padding: var(--spacing-s) 0 calc(var(--spacing-m) + var(--spacing-xxxs));

  @media (--laptop) {
    padding: var(--spacing-xxxxl) 0;
  }
}

.VesselSegmentsLandingPage__heroSection {
  min-height: 200px;
  padding: var(--spacing-m) 0;

  @media (--tablet) {
    min-height: 280px;
    padding: var(--spacing-l) 0;
  }

  @media (--laptop) {
    min-height: 380px;
    padding: var(--spacing-xl) 0;
  }
}

.VesselSegmentsLandingPage__introductionText {
  &,
  & > p {
    @mixin typographyMobileDefaultMedium;

    @media (--laptop) {
      @mixin typographyDesktopDefaultXLarge;
    }
  }
}
