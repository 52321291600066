.Hero--withImage {
  margin-bottom: var(--spacing-xxxl);

  @media (--tablet) {
    margin-bottom: var(--spacing-xxxxl);
  }

  & .Hero__media {
    min-height: var(--hero-with-image-width-mobile-height);

    @media (--tablet) {
      min-height: var(--hero-with-image-width-tablet-height);
    }

    @media (--laptop) {
      min-height: var(--hero-with-image-width-desktop-height);
    }
  }

  & .Hero__media,
  & .Hero__container,
  & .Hero__picture,
  & .Hero__placeholder {
    max-height: var(--hero-with-image-width-mobile-height);

    @media (--tablet) {
      max-height: var(--hero-with-image-width-tablet-height);
    }

    @media (--laptop) {
      max-height: var(--hero-with-image-width-desktop-height);
    }
  }

  & .Hero__headingContainer {
    @mixin backgroundOverlay var(--blackColor), 33%;

    position: absolute;
    z-index: 1;
    color: var(--whiteColor);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  & .Hero__heading {
    position: absolute;
    bottom: var(--spacing-m);
    left: 0;
    right: 0;
    align-items: flex-end;
    
    @media (--laptop) {
      flex-direction: column;
      top: 0;
      align-items: flex-start;
      justify-content: center;
    }

    & h1 {
      margin-block: 0 var(--spacing-xxxxxxl);
      max-width: var(--hero-heading-max-width);

      @media (--tablet) {
        margin-block: calc(var(--spacing-xl) + calc(var(--spacing-xxxs) / 2)) 0;
      }

      & .Hero__headingText {
        @mixin lineClamp 5;
      }
    }
  }

  & .Hero__headingContainer:has(.Hero__photoCreditContainer) .Hero__heading {
      bottom: 62px;
  }
}
