.Search {
  display: flex;
  justify-content: center;
  opacity: 0;
  position: fixed;
  left: 0;
  background: var(--brandColor_tint_3);
  width: 100vw;
  height: 100%;
  z-index: -1;

  @media (--tablet) {
    transform: translateY(-145px);
    position: relative;
    background: none;
    width: initial;
    height: initial;
    z-index: initial;
    display: flex;
    justify-content: center;
    opacity: 0;
  }
  @media (--laptop) {
    position: absolute;
    width: 100%;
  }
}

_:-ms-fullscreen, :root .Search {
  @media (--tablet) {
    height: auto;
    z-index: auto;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}