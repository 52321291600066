.FilterableProductCatalog__filters {
  margin-bottom: 2rem;

    @media (--tablet) {
        margin-bottom: 4rem;
    }
  
  & li:empty {
    display: none;
  }
}