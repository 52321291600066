@define-mixin micrositeLandingPagePosition $top: initial, $bottom: initial {
  position: absolute;
  z-index: 1;
  right: 0;
  top: $top;
  bottom: $bottom;
  margin: 0 16px;
  color: var(--whiteColor);

  @media (--tablet) {
    margin: 0 48px;
  }
}

@define-mixin sectionPageCloseButtonPosition $offsetLaptop: 48px, $offsetTablet: 48px, $offsetMobile: 16px {
  top: 20px;
  right: $offsetMobile;

  @media (--tablet) {
    top: 40px;
    right: $offsetTablet;
  }

  @media (--laptop) {
    top: 48px;
    right: $offsetLaptop;
  }
}
