.TextBlock {
  & a {
    color: var(--brandColor);

    &:hover {
      text-decoration: underline;
    }
  }

  & video {
    max-width: 100%;
  }
}
