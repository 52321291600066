.SearchHit {
  margin-bottom: 2rem;
  animation: fade-in .4s ease;

  @media (--tablet) {
    margin-bottom: 3.8rem;
  }

  & a {
    font-weight: normal;
  }

  & p {
    word-break: break-word;
    margin-bottom: 0;
  }
}

.SearchHit__title {
  line-height: 1.2;
  margin: 0;
  text-transform: none;
}

.SearchHit__link {
  display: inline-block;
  margin-bottom: 1rem;

  & span {
    word-break: break-word;
    display: block;
  }
}