.RichHotsSpotsBlock {
  & .SideTextHotSpotsImage__MobileDescriptionBoxItemTitle span,
  & .SideTextHotSpotsImage__MobileDescriptionBoxItemDescription p {
    @mixin h10;

    font-weight: 400;
  }

  & .SideTextHotSpotsImage__MobileDescriptionBoxItemTitle {
    outline: none;
  }

  @media (--laptop) {
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;

    & .RichHotsSpotsBlock__picture .ImageHotSpots__Container {
      padding: 0;
      min-width: min-content;
      position: relative;

      & img {
        height: initial;
        width: initial;
        max-width: initial;
        min-width: 100%;
        min-height: 100%;
      }
    }

    & .SideTextHotSpotsImage__Description {
      position: fixed;

      &.SideTextHotSpotsImage__Description--leftHidden,
      &.SideTextHotSpotsImage__Description--rightHidden {
        position: absolute;
      }
    }

    & .SideTextHotSpotVariantA__PreviewText {
      font-size: 1.5rem;
    }
  }

  @media (--tablet) {
    & .RichHotsSpotsBlock__picture .ImageHotSpots__Container picture {
      width: 100%;
    }

    & .SideTextHotSpotVariantA__PreviewText {
      font-size: 1.25rem;
    }

    & .ImageHotSpots__Container {
      padding: 0;

      & img {
        position: static;
      }
    }
  }
}
