@keyframes skeletonPulse {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.Skeleton {
  display: block;
}

.Skeleton__inner {
  position: relative;
  overflow: hidden;
  display: block;
  background: var(--base-grey-light-100);
  width: 100%;
  min-height: 100%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    transform: translateX(-100%);
    background: linear-gradient(90.46deg, var(--base-grey-light-100) 0.34%, var(--base-grey-light-80) 25.14%, var(--base-grey-light-100) 49.94%, var(--base-grey-light-80) 74.74%, var(--base-grey-light-100) 99.54%);
    inset: 0;
    animation: 1.3s ease-in-out .5s infinite skeletonPulse;

    @media (--reduced-motion) {
      animation: none;
    }
  }
}

.Skeleton--inline {
  & .Skeleton__inner {
    display: inline-block;
    min-height: auto;
  }
}

.Skeleton--rounded {
  & .Skeleton__inner {
    border-radius: 6px;
  }
}
