@define-mixin buttonNext $bgColor, $iconColor {
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    animation: hoop 0.8s ease infinite;
  }
  
  &:hover:after {
    background-color: var(--tertiaryColor);
  }
  
  &::after {
    content: svg-load('Icons/ArrowRightSimple.svg', fill: $iconColor, width: 34px, height: 34px);
    background-color: $bgColor;
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }
}