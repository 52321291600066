.MicrositeLogo {
  width: 66px;
  height: 66px;
  transition: opacity .3s ease-in-out;

  @media (--laptop) {
    position: absolute;
    width: 38px;
    height: 250px;
  }

  @media (--desktop4k) {
    position: absolute;
    width: 70px;
    height: 339px;
  }

  &.MicrositeLogo--dark {
    background: svg-load('Logo/microsite-square.svg') no-repeat;

    @media (--laptop) {
      background: svg-load('Logo/microsite-vertical.svg') no-repeat;
    }

    @media (--desktop4k) {
      background: svg-load('Logo/microsite-vertical-4k.svg') no-repeat;
    }
  }

  &.MicrositeLogo--light {
    background: svg-load('Logo/microsite-square-light.svg') no-repeat;
  
    @media (--laptop) {
      background: svg-load('Logo/microsite-vertical-light.svg') no-repeat;
    }

    @media (--desktop4k) {
      background: svg-load('Logo/microsite-vertical-4k-light.svg') no-repeat;
    }
  }

  &.MicrositeLogo--mobileOnly {
    @media (--laptop) {
      display: none;
    }
  }

  &.MicrositeLogo--laptopOnly {
    @media (--mobileAndTablet) {
      display: none;
    }
  }

  &.MicrositeLogo--hidden {
    opacity: 0;
    z-index: -1;
  }
}
