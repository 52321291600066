.react-autosuggest__container {

  & input[type="text"] {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 20px 0 50px;
    background: var(--whiteColor);
    border: 1px solid var(--grayExtradark);
    border-radius: 50px;
    color: var(--textColor);
    font-size: 20px;
    font-weight: 600;

    @media (--tablet) {
      padding: 0 90px 0 35px;
      position: absolute;
    }
  }

  & input[type="text"]:focus {
    outline: none;
    background: var(--whiteColor);
    border: 1px solid var(--blackLighterColor);
    box-shadow: 0 0 8px var(--grayMedium);
  }

  & input[type="text"]::placeholder {
    opacity: 0;
    position: relative;
    top: 2px;

    @media (--tablet) {
      opacity: 1;
      line-height: 100%;
      color: var(--grayExtradark);
    }
  }

  & input[type="text"]:focus::placeholder {
    color: var(--blackLighterColor);
  }

  & input[type=text]:-ms-input-placeholder {
    opacity: 1;
  }
}