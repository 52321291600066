.DayPicker {
    display: inline-block;
    font-size: 1rem;
    font-family: GTPressura-Light, sans-serif !important;
}

.DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: GTPressura, sans-serif;
}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: var(--calendar_label);
    cursor: pointer;
    
    outline: none;
}

.DayPicker-NavButton:hover {
    opacity: 0.8;
}

.DayPicker-NavButton--prev {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 10px solid var(--calendar_label);
    margin-right: 1.4em;
    margin-top: 6px;
}

.DayPicker-NavButton--next {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid var(--calendar_label);
    margin-top: 6px;
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
}

.DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: var(--calendar_label);
    text-align: center;
    font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border-radius: 0;
    outline: none;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: var(--calendar_blue);
    font-size: 0.875em;
    cursor: pointer;
}

.DayPicker-Day--today {
    color: var(--calendar_today);
    font-weight: 700;
}

.DayPickerInput {
    display: inline-block;
}

.DatePicker-Component {
    width: 260px;
    height: 35px;
}

.DatePicker-Icon {
    width: 40px;
    height: 100%;
    border: 1px solid var(--calendar_border);
    background: var(--calendar_background) svg-load('Icons/Calendar.svg', width: 20px, height: 22px) no-repeat center;
    display: inline-block;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.DatePicker-Input {
    width: calc(100% - 40px);
    height: 100%;
    font-size: 16px;
    padding-left: 0.5rem;
    border: solid var(--calendar_border);
    border-width: 1px 0 1px 1px;
    float: left;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    outline: none;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 3;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-Month {
    border-collapse: separate;
}

.DayPicker-WeekNumber {
    outline: none;
}

.DayPicker-Day--hoverRange,
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected):hover {
    background-color: var(--calendar_selected);
}

.DayPicker-Day--selectedRange {
    background-color: var(--calendar_light_blue);
    border-top-color: var(--calendar_blue);
    border-bottom-color: var(--calendar_blue);
    border-left-color: var(--calendar_light_blue);
    border-right-color: var(--calendar_light_blue);
}

.DatePicker .DayPicker-Day--selected {
    background-color: var(--calendar_blue);
    color: var(--blackColor);
}

.DayPicker-Day--selectedRangeStart {
    background-color: var(--calendar_blue);
    border-left: 1px solid var(--calendar_blue);
}

.DayPicker-Day--selectedRangeEnd {
    background-color: var(--calendar_blue);
    border-right: 1px solid var(--calendar_blue);
}

.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
    border-radius: 0;
    color: var(--blackColor);
}

.DayPicker-Day--hoverRange:hover {
    border-radius: 0;
}