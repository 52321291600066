.PlainIntroductionSection__title {
  font-size: 1.625rem; /* 26px */
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.39px;
  margin-block: 1.6875rem; /* 27px */
  max-width: 35rem;

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    margin-block: var(--spacing-m);
  }
}

.PlainIntroductionSection__description {
  @mixin typographyMobileDefaultMedium;

  margin-block: 1.6875rem; /* 27px */
  max-width: 35rem;

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;

    margin-block: 2.125rem 2.3125rem; /* 34px 37px */
  }
}
