.SpecificationsAndDownloadsSection__content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-l) + var(--spacing-xxxs));
}

.SpecificationsAndDownloadsSection__sections {
  @mixin kogColumn 12;
  column-gap: var(--spacing-xs);
  row-gap: var(--spacing-s);

  &::after {
    height: 90px;
  }
}

.SpecificationsAndDownloadsSection__specificationsSection {
  @media (--laptop) {
    @mixin kogColumnStart 1;
    @mixin kogColumnEnd 7;
  }
}

.SpecificationsAndDownloadsSection__downloadsSection {
  @media (--laptop) {
    @mixin kogColumnStart 8;
    @mixin kogColumnEnd;
  }
}

.SpecificationsAndDownloadsSection__specificationsSection--desktopFullWidth,
.SpecificationsAndDownloadsSection__downloadsSection--desktopFullWidth {
  @media (--laptop) {
    @mixin kogColumnStart 1;
    @mixin kogColumnEnd;
  }
}
