.CampaignSubpage {

  & .CampaignHero {
    min-height: 80vh;

    @media (--tablet) {
      min-height: 450px;
    }

    @media (--laptop) { 
      min-height: 460px;
    }
  }
  
  & .Hero--withImage {
    & .Hero__media,
    & .Hero__container {
      @media (--mobileOnly) {
        height: 100vh;
        max-height: 1300px;
      }
    }
  }

  & .ContactCard {

    & .ContactCard__inner {
      &::after {
        content: '';
        position: absolute;
        top: 85px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: svg-load('Common/Background.svg',
        fill: var(--kog_bronze_tint_3)) no-repeat;
        z-index: -1;
      }
    }

    & .ContactCard__info {
      color: var(--textColor);
    }
    & .ContactCard__name {
      font-weight: 500;
    }

    & a {
      color: var(--textColor);
    }

    & .ContactCard__phone {
      &::before {
        background: svg-load('Icons/Phone1.svg');
      }
    }
    & .ContactCard__address {
      &::before {
        background: svg-load('Icons/EmailEnvelope.svg');
      }
    }
  }

  & .Downloads {
    margin: 0;
  }
  
  & .StoryTextContent {
    min-height: 100vh;
  }
}