.ImageWithFacts {
  &.ImageWithFacts--scrollList {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: var(--base-grey-white) !important;

    & .ImageWithFacts__title {
      margin-block: 0 var(--spacing-xs);

      @media (--tablet) {
        margin-block: 0 var(--spacing-s);
      }
    }

    & .ImageWithFacts__description {
      @mixin typographyMobileDefaultMedium;

      margin-block: 0 var(--spacing-s);
      color: var(--base-black-80);
      max-width: 684px;

      @media (--tablet) {
        @mixin typographyDesktopDefaultXLarge;

        margin-block: 0 var(--spacing-m);
      }
    }
  }
}
