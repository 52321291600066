.MicrositeMenuBar {
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--menuBarSize);
  z-index: 5;
  bottom: 0;

  @media (--laptop) {
    top: 0;
    bottom: initial;
    min-height: 100vh;
    width: var(--menuBarSize);
  }

  @media (--desktop4k) {
    width: var(--menuBarSize4k);
  }
}

.MicrositeMenuBar__sideBarContainer {
  width: 100%;
  z-index: 1;
}
