
.Elumee__Container{
    width: 100%;
    height: 707px;
    border: none;
    
    
    @media (max-width: 1217px){
        height: 768px;
    }
    
    @media (max-width: 1040px){
        height: 1024px;
    }
    
    @media(max-width: 768px){
        height: 568px;
    }
}
