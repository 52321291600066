.MicrositeFooter {
  @mixin micrositeLandingPagePosition initial, 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  &.MicrositeFooter--light {
    color: var(--blackColor);
  }

  &.MicrositeFooter--dark {
    color: var(--whiteColor);
  }
}

.MicrositeFooter__qr {
  width: 45px;
  height: 45px;

  @media (--desktop4k) {
    height: 220px;
    width: 220px;
  }
}

.MicrositeFooter__copyright,
.MicrositeFooter__qr {
  display: none;

  @media (--laptop) {
    display: block
  }
}

.MicrositeFooter__copyright {
  @media (--desktop4k) {
    font-size: 24px;
  }
}
