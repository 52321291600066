.ClosePage__button {
  position: fixed;
  top: 20px;
  right: 15px;
  width: 48px;
  height: 48px;
  border-radius: 17px;
  z-index: 3;
  cursor: pointer;
  transform: translateY(0);
  transition: z-index 0.5s step-start, transform 0.5s ease, opacity 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1220px) { 
      right: calc(50% - var(--wrapperWidth) / 2 - 17px); 
  }
  
  &.ClosePage__button--hide {
    opacity: 0;
    z-index: -1;
    transform: translateY(-200%);
    transition: z-index 0.5s step-end, transform 0.5s ease, opacity 0.5s ease;
  }

  @media (--laptop) {
    top: 48px;
  }
  
  @media (--desktop) {
    top: 53px;
  }

  &:hover:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &:hover:before {
    background: var(--tertiaryColor);
  }
  
  &::before {
    content: '';
    width: 34px;
    height: 34px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: black;
    position: absolute;
    display: block;
    transition: ease 0.4s;
  }

  &::after {
    content: '';
    background: svg-load('Icons/Close.svg',
    stroke: var(--whiteColor)) no-repeat center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 32px;
    height: 32px;
    transition: ease 0.4s;
  }
}