.ContainerWithBackground .FastFactList--SimpleWithGrid {
  @media (--laptop) {
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: var(--spacing-xl) !important;
    /* stylelint-disable-next-line declaration-no-important */
    margin-bottom: var(--spacing-xl) !important;
  }

  & .FastFactList__background {
    bottom: calc(-1 * var(--spacing-xxxxxxxl));
  }
}
