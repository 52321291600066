.VideoFigureWrapper {
    padding: var(--spacing-xs) 0 var(--spacing-s) 0;
    
    @media (--tablet) {
        padding: var(--spacing-xxxxl) 0;
    }
    
    & .Section__title {
        margin: 0 0 var(--spacing-xs);
        
        @media (--tablet) {
            margin: 0 0 var(--spacing-xl);
        }
    }
}

.VideoFigure {
    
    margin:0;

    & .Figure__caption {
        position: relative;
    }
}

.VideoFigure--playing {
    & .Figure__caption {
        z-index: -100;
    }
}

.VideoFigure__VideoContainer {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.VideoFigure__VideoContainer iframe,
.VideoFigure__VideoContainer object,
.VideoFigure__VideoContainer video,
.VideoFigure__VideoContainer embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.RichtextBlock {
    & .VideoFigureWrapper {
        padding: 0;
    }
    
    & .VideoFigure {
        @media (--desktop) {
            padding-left: 0;
            max-width: var(--wrapperWidth);
            padding-right: var(--sidebarWidth);
        }
    }
}

.ProductPage__richtextBlock .VideoFigure {
    @media (--desktop) {
        padding-left: 5.5rem;
        max-width: var(--wrapperWidth);
        padding-right: calc(var(--sidebarWidth) - 5.5rem);
    }
}

.VideoFigure figcaption {
    pointer-events: none;
}

