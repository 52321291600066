.PictureThumbnail {
  position: relative;
  overflow: hidden;
  min-height: 100%;
}

.PictureThumbnail__thumbnail {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100%;
  z-index: var(--z-index-picture-thumbnail-thumbnail);
}

.PictureThumbnail__picture {
  position: absolute;
  inset: 0;
  z-index: var(--z-index-picture-thumbnail-picture);

  & img {
    min-height: 100%;
    width: 100%;
  }
}
