@define-mixin whiteTheme {
    color: var(--whiteColor);

    & svg {
        fill: var(--whiteColor);
    }
}

.RichFactsList {
  &.RichFactsList--Default {
    & .RichFactsList__background::before {
      background-color: transparent;
    }
  }

  &.RichFactsList--BlueFive {
    & .RichFactsList__background::before {
      background-color: var(--kog_blue_tint_7);
    }
  }

  &.RichFactsList--SandFour {
    & .RichFactsList__background::before {
      background-color: var(--kog_bronze_tint_1);
    }
  }

  &.RichFactsList--TealTwo {
    & .RichFactsList__background::before {
      background-color: var(--kog_aqua_tint_6);
    }
  }

  &.RichFactsList--TealFive {
    & .RichFactsList__background::before {
      background-color: var(--kog_aqua_tint_1);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--YellowFour {
    & .RichFactsList__background::before {
      background-color: var(--kog_yellow);
    }
  }

  &.RichFactsList--GreenTwo {
    & .RichFactsList__background::before {
      background-color: var(--kog_green_tint_3);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--BlueTwo {
    & .RichFactsList__background::before {
      background-color: var(--kog_blue_tint_1);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--SandTwo {
    & .RichFactsList__background::before {
      background-color: var(--kog_bronze_tint_6);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--ForestTwo {
    & .RichFactsList__background::before {
      background-color: var(--kog_forest_tint_3);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--GreenFour {
    & .RichFactsList__background::before {
      background-color: var(--kog_green_tint_2);
    }
  }

  &.RichFactsList--White {
    & .RichFactsList__background::before {
      background-color: var(--whiteColor);
    }
  }

  &.RichFactsList--Black {
    & .RichFactsList__background::before {
      background-color: var(--textColor);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--GreenOne {
    & .RichFactsList__background::before {
      background-color: var(--kog_green_tint_4);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--GreenFive {
    & .RichFactsList__background::before {
      background-color: var(--kog_green_tint_1);
    }
  }

  &.RichFactsList--ForestFive {
    & .RichFactsList__background::before {
      background-color: var(--kog_forest_tint_1);
    }
  }

  &.RichFactsList--BlueThree {
    & .RichFactsList__background::before {
      background-color: var(--kog_blue_tint_9);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--YellowTwo {
    & .RichFactsList__background::before {
      background-color: var(--kog_yellow_tint_3);
    }
  }

  &.RichFactsList--YellowFive {
    & .RichFactsList__background::before {
      background-color: var(--kog_yellow_tint_1);
    }
  }

  &.RichFactsList--RedFour {
    & .RichFactsList__background::before {
      background-color: var(--kog_red_tint_1);
    }
  }

  &.RichFactsList--GreyTwo {
    & .RichFactsList__background::before {
      background-color: var(--grayColor_tint_6);
    }

    @mixin whiteTheme;
  }

  &.RichFactsList--GreyFive {
    & .RichFactsList__background::before {
      background-color: var(--grayColor_tint_2);
    }
  }
}
