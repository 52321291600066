.RichVideo__btn {
  &&::before {
    background: var(--base-grey-dark-100);
  }

  &:hover::before {
    background: var(--base-grey-dark-80);
  }

  &:focus::before {
    background: var(--base-grey-black-100);
  }

  &.RichVideo__btn--sand {
    &::before {
      background: var(--base-sand-dark-80);
    }

    &:hover::before {
      background: var(--base-sand-dark-100);
    }

    &:focus::before {
      background: var(--base-sand-brown-100);
    }
  }

  &.RichVideo__btn--grey {
    &::before {
      background: var(--base-grey-dark-100);
    }

    &:hover::before {
      background: var(--base-grey-dark-80);
    }

    &:focus::before {
      background: var(--base-grey-black-100);
    }
  }

  &.RichVideo__btn--blue {
    &::before {
      background: var(--accent-blue-accent-default);
    }

    &:hover::before {
      background: var(--accent-blue-accent-dark);
    }

    &:focus::before {
      background: var(--accent-blue-accent-darker);
    }
  }

  &.RichVideo__btn--teal {
    &::before {
      background: var(--accent-teal-accent-default);
    }

    &:hover::before {
      background: var(--accent-teal-accent-dark);
    }

    &:focus::before {
      background: var(--accent-teal-accent-darker);
    }
  }

  &.RichVideo__btn--red {
    &::before {
      background: var(--error-error-default);
    }

    &:hover::before {
      background: var(--error-error-dark);
    }

    &:focus::before {
      background: var(--error-error-darker);
    }
  }
}
