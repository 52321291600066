.Tabs__labels {
  padding-inline-start: 0;
  list-style-type: none;
  margin-block: var(--spacing-m);
  display: flex;

  --hover-color: #1e53a2;
}

.Tabs__label {
  @mixin typographyMobileStrongLarge;

  color: var(--accent-blue-accent-default);
  line-height: 1;
  background: transparent;
  border: 0;

  @media (--desktop) {
    @mixin typographyDesktopStrongLarge;

    line-height: 1;
    padding: calc(var(--spacing-xs) - 1px) var(--spacing-m);
  }
}

.Tabs__label--multipleDays {
  border-radius: 2px;
  position: relative;
  margin-inline-end: -2px;
  padding: calc(var(--spacing-xs) - 1px) var(--spacing-m);
  cursor: pointer;
  transition: all 0.25s;
  transition-property: color, background, border-color;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: transparent;
    border: solid 2px var(--accent-blue-accent-default);
    border-radius: 2px;
    pointer-events: none;
  }

  &:hover {
    color: var(--hover-color);
    cursor: pointer;

    &::before {
      border-color: var(--hover-color);
      z-index: 1;
    }
  }

  &.Tabs__label--active {
    color: var(--whiteColor);
    background: var(--accent-blue-accent-default);

    &:hover {
      background: var(--hover-color);
    }
  }

  &:focus-within {
    outline: var(--kog_blue_tint_8) solid 3px;
  }
}

.Tabs__tabpanels {
  padding-inline-start: 0;
  list-style-type: none;
  margin-block-start: 0;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-flow: column;
}

.Tabs__tab {
  overflow: hidden;
}

.Tabs__tab--active {
  overflow: visible;
  grid-column: 1/-1;
}

.Tabs__showMoreItemsButton {
  margin-block-start: var(--spacing-xxxl);
  display: flex;
  gap: var(--spacing-xxs);
  color: var(--kog_blue_tint_9);

  &:hover {
    background: transparent;
    color: var(--kog_blue);
  }
}
