.SearchSuggestion {
  display: grid;
  grid-template-columns: 1.5rem auto repeat(2, 0);
  align-items: center;
  border: solid 1px var(--blackColor);
  border-radius: 2px;
  padding: 0 0 0 calc(var(--spacing-xxs) + var(--spacing-xxxs));
  overflow: hidden;
  background: var(--whiteColor);
  transition:
    grid-template-columns 0.5s,
    top 0.5s;
}

.SearchSuggestion__input {
  @mixin typographyDesktopDefaultMedium;
  @mixin resetSearchAppearance;

  appearance: none;
  border: none;
  background: transparent;
  height: auto;
  line-height: 1;
  padding: var(--spacing-xxs) 0;

  &::placeholder {
    color: var(--grayColor_tint_5);
    opacity: 1;
  }

  @media (--laptop) {
    @mixin typographyDesktopDefaultMedium;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.SearchSuggestion__input--keyboardFocus {
  @mixin focusVisible var(--outlineColorSecondary), 2px, 1px;
}

.SearchSuggestion__button {
  @mixin resetButtonStyles;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 2rem;
  /* stylelint-disable-next-line declaration-no-important */
  padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  overflow: hidden !important;
  @mixin focusVisible var(--outlineColorSecondary);

  &:not(:first-of-type)::before {
    content: "";
    position: absolute;
    inset: 0;
    inset-block: 50%;
    transform: translateY(-50%);
    height: 22px;
    width: 1px;
    background-color: var(--black-100);
    opacity: 0;
    transition:
      transform 0.5s,
      opacity 0.5s;
  }

  svg {
    width: 1rem;
  }
}

.SearchSuggestion__icon {
  font-size: var(--spacing-xs);
}

.SearchSuggestion__icon--prefix {
  overflow: hidden;
}

.SearchSuggestion:has(.SearchSuggestion__input:not(:placeholder-shown)) {
  grid-template-columns: 0 auto repeat(2, 2rem);

  @media (--laptop) {
    overflow: visible;
  }

  .SearchSuggestion__button:not(:first-of-type)::before {
    transform: translateY(-50%);
    opacity: 1;
  }
}
