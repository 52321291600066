.Agenda {
  background: var(--accent-blue-accent-lighter);
}

.Agenda__header {
  @mixin typographyMobileStrongXXLarge;

  @media (--desktop) {
    @mixin typographyDesktopStrongXXLarge;
  }
}

.Agenda__events {
  padding-inline-start: 0;
  list-style-type: none;
  margin-block-start: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (--desktop) {
    gap: var(--spacing-xs);
  }
}

.Agenda__tile {
  background: var(--whiteColor);
  position: relative;
  border-radius: var(--spacing-xxxs);
  overflow: hidden;
  padding: var(--spacing-xs);
  padding-inline-start: var(--spacing-m);
  display: grid;
  grid-template-areas: "time" "title" "speaker" "description" " expandButton";
  grid-template-rows: repeat(3, auto) 0fr auto;
  transition: all 0.25s;
  transition-property: grid-template-rows, background;

  @media (--desktop) {
    padding: var(--spacing-s);
  }

  &::before {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline-start: 0;
    width: 6px;
    background-color: var(--kog_blue_tint_8);
    transition: background 0.25s;
  }

  &:hover {
    background: var(--kog_blue_50);

    &::before {
      background-color: var(--kog_blue_tint_9);
    }
  }

  &.Agenda__tile--expanded {
    grid-template-rows: repeat(3, auto) 1fr auto;
  }
}

.Agenda__title {
  grid-area: title;
  @mixin typographyMobileStrongXLarge;

  margin-block: var(--spacing-s) var(--spacing-xs);

  @media (--desktop) {
    @mixin typographyDesktopStrongXLarge;

    margin-block: var(--spacing-s) var(--spacing-xs);
  }
}

.Agenda__time {
  grid-area: time;
  @mixin typographyMobileDefaultMedium;

  margin: 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  color: var(--blue-900);

  @media (--desktop) {
    @mixin typographyDesktopDefaultMedium;
  }

  svg {
    stroke: var(--blue-900);
  }
}

.Agenda__speaker {
  grid-area: speaker;
  @mixin typographyMobileDefaultMedium;

  margin-block: 0 var(--spacing-xs);

  @media (--desktop) {
    @mixin typographyDesktopDefaultMedium;

    margin-block: 0 var(--spacing-xs);
  }
}

.Agenda__description {
  grid-area: description;
  overflow: hidden;
  margin: 0;
}

.Agenda__expandButton {
  grid-area: expandButton;
  padding-inline-start: 0;
  border: 0;
  text-transform: lowercase;

  svg {
    font-size: 1.125rem;
  }
}
