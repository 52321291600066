.HighlightsSection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  @media (--tablet) {
    gap: var(--spacing-l);
  }

  & .Highlight {
    &:nth-child(odd) {
      @media (--tablet) {
        & .Highlight__imageSection {
          order: 1;
        }
      }
    }

    &.Highlight--imageFullWidth:last-child {
      padding-block: calc((var(--spacing-m) * 2) - var(--spacing-s)) 0;

      @media (--tablet) {
        padding-block: calc((var(--spacing-m) * 2) - var(--spacing-l)) 0;
      }

      @media (--laptop) {
        padding-block: calc((var(--spacing-xxxxxxl) * 2) - var(--spacing-l)) 0;
      }
    }
  }
}
