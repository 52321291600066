/* stylelint-disable no-descending-specificity */
@define-mixin fastFactColorVariant $simpleVersionFontColor, $otherVersionBgColor,
  $otherVersionFontColor, $buttonVariant {
  & .FastFact {
    & .FastFact__icon svg {
      fill: $simpleVersionFontColor;
    }

    & .FastFact__link,
    & .FastFact__content {
      color: $simpleVersionFontColor;
    }
  }

  & .FastFactList--Circle {
    & .FastFact {
      background: $otherVersionBgColor;
    }

    & .FastFact__icon svg {
      fill: $otherVersionFontColor;
    }

    & .FastFact__link,
    & .FastFact__content {
      color: $otherVersionFontColor;
    }
  }

  & .FastFactList--SimpleWithGrid {
    & .FastFact .FastFact__content {
      background: $otherVersionBgColor;
    }

    & .FastFact .FastFact__link {
      &,
      &:hover {
        & .FastFact__icon svg,
        & .FastFact__text,
        & .FastFact__heading {
          color: $otherVersionFontColor;
        }

        &::after {
          background: svg-load("Icons/Discovery/ArrowRight.svg", fill: $otherVersionFontColor);
        }
      }
    }

    & .FastFactList__ctaButton {
      @mixin $(buttonVariant);
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin fastFactColorVariant var(--theme-color-beige-text-color),
    var(--theme-color-beige-block-bg-color), var(--theme-color-beige-block-text-color),
    buttonSecondaryThemeColorBeige;
}

.ContainerWithBackground--GreenFour {
  @mixin fastFactColorVariant var(--theme-color-green-text-color),
    var(--theme-color-green-block-bg-color), var(--theme-color-green-block-text-color),
    buttonSecondaryThemeColorGreenFour;
}

.ContainerWithBackground--BlueFive {
  @mixin fastFactColorVariant var(--theme-color-blue-text-color),
    var(--theme-color-blue-block-bg-color), var(--theme-color-blue-block-text-color),
    buttonSecondaryThemeColorBlueFive;
}

.ContainerWithBackground--TealTwo {
  @mixin fastFactColorVariant var(--theme-color-turquoise-dark-text-color),
    var(--theme-color-turquoise-dark-block-bg-color),
    var(--theme-color-turquoise-dark-block-text-color), buttonSecondaryThemeColorTealTwo;
}

.ContainerWithBackground--SandFour {
  @mixin fastFactColorVariant var(--theme-color-brown-text-color),
    var(--theme-color-brown-block-bg-color), var(--theme-color-brown-block-text-color),
    buttonSecondaryThemeColorSandFour;
}

.ContainerWithBackground--TealFive {
  @mixin fastFactColorVariant var(--theme-color-turquoise-text-color),
    var(--theme-color-turquoise-block-bg-color), var(--theme-color-turquoise-block-text-color),
    buttonSecondaryThemeColorTealFive;
}

.ContainerWithBackground--YellowFour {
  @mixin fastFactColorVariant var(--theme-color-yellow-text-color),
    var(--theme-color-yellow-block-bg-color), var(--theme-color-yellow-block-text-color),
    buttonSecondaryThemeColorYellowFour;
}

.ContainerWithBackground--GreenTwo {
  @mixin fastFactColorVariant var(--theme-color-green-dark-text-color),
    var(--theme-color-green-dark-block-bg-color), var(--theme-color-green-dark-block-text-color),
    buttonSecondaryThemeColorGreenTwo;
}

.ContainerWithBackground--BlueTwo {
  @mixin fastFactColorVariant var(--theme-color-blue-dark-text-color),
    var(--theme-color-blue-dark-block-bg-color), var(--theme-color-blue-dark-block-text-color),
    buttonSecondaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin fastFactColorVariant var(--theme-color-sand-dark-text-color),
    var(--theme-color-sand-dark-block-bg-color), var(--theme-color-sand-dark-block-text-color),
    buttonSecondaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin fastFactColorVariant var(--theme-color-forest-dark-text-color),
    var(--theme-color-forest-dark-block-bg-color), var(--theme-color-forest-dark-block-text-color),
    buttonSecondaryThemeColorForestTwo;
}

.ContainerWithBackground--Pink {
  @mixin fastFactColorVariant var(--theme-color-pink-text-color),
    var(--theme-color-pink-block-bg-color), var(--theme-color-pink-block-text-color),
    buttonSecondaryThemeColorPink;
}

.ContainerWithBackground--GreenOne {
  @mixin fastFactColorVariant var(--theme-color-green-one-text-color),
    var(--theme-color-green-one-block-bg-color), var(--theme-color-green-one-block-text-color),
    buttonSecondaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin fastFactColorVariant var(--theme-color-green-five-text-color),
    var(--theme-color-green-five-block-bg-color), var(--theme-color-green-five-block-text-color),
    buttonSecondaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin fastFactColorVariant var(--theme-color-forest-five-text-color),
    var(--theme-color-forest-five-block-bg-color), var(--theme-color-forest-five-block-text-color),
    buttonSecondaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin fastFactColorVariant var(--theme-color-blue-three-text-color),
    var(--theme-color-blue-three-block-bg-color), var(--theme-color-blue-three-block-text-color),
    buttonSecondaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin fastFactColorVariant var(--theme-color-yellow-two-text-color),
    var(--theme-color-yellow-two-block-bg-color), var(--theme-color-yellow-two-block-text-color),
    buttonSecondaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin fastFactColorVariant var(--theme-color-yellow-five-text-color),
    var(--theme-color-yellow-five-block-bg-color), var(--theme-color-yellow-five-block-text-color),
    buttonSecondaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin fastFactColorVariant var(--theme-color-red-four-text-color),
    var(--theme-color-red-four-block-bg-color), var(--theme-color-red-four-block-text-color),
    buttonSecondaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin fastFactColorVariant var(--theme-color-grey-two-text-color),
    var(--theme-color-grey-two-block-bg-color), var(--theme-color-grey-two-block-text-color),
    buttonSecondaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin fastFactColorVariant var(--theme-color-grey-five-text-color),
    var(--theme-color-grey-five-block-bg-color), var(--theme-color-grey-five-block-text-color),
    buttonSecondaryThemeColorGreyFive;
}
