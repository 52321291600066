:root {
  --heroHomePageMobileHeight: calc(
    var(--heroFullWidthMobileHeight) + var(--mobile-header-height-without-top-nav) + 61px
  ); /* 61px is difference between old mobile header height and current one */
  --heroHomePageDesktopHeight: calc(
    var(--heroFullWidthDesktopHeight) + var(--header-height-without-breadcrumbs) + 75px
  ); /* 75px is difference between old desktop header height and current one */
}

.Hero--homePage,
.Hero--homePage.Hero--withImage,
.Hero--homePage.Hero--fullWidth {
  & .Hero__media {
    min-height: var(--heroHomePageMobileHeight);

    @media (--laptop) {
      min-height: var(--heroHomePageDesktopHeight);
    }
  }

  & .Hero__media,
  & .Hero__container,
  & .Hero__picture,
  & .Hero__placeholder {
    max-height: var(--heroHomePageMobileHeight);

    @media (--laptop) {
      max-height: var(--heroHomePageDesktopHeight);
    }
  }

  & .Hero__picture {
    & img {
      max-height: var(--heroHomePageMobileHeight);
    }
  }

  &.Hero--withImage {
    & .Hero__heading {
      @media (--laptop) {
        top: var(--header-height-without-breadcrumbs);
        height: calc(100% - var(--header-height-without-breadcrumbs));
      }

      & h1 {
        @media (--laptop) {
          margin-block: 0;
        }
      }

      & .Hero__titleText {
        @media (--laptop) {
          display: block;
        }
      }
    }
  }
}
