:root {
  --link-block-max-width: 280px;
}

.LinkBlock {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 0 auto;
  max-width: var(--link-block-max-width);

  & p {
    margin: 0 0 var(--spacing-xs);
    line-height: 1.6;
  }

  & a {
    flex: 1 0 auto;
    @mixin focusVisible var(--outlineColorSecondary);
  }

  & .st1 {
    fill: var(--textColor);
  }

  & .ButtonLink {
    margin-bottom: var(--spacing-xxs);

    &:empty {
      @mixin arrowLinkRightMedium;

      margin: 0;
    }
  }
}

.LinkBlock__title {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 20px;
  line-height: 1.3;
  margin: 0 0 var(--spacing-xxs);

  @media (--tablet) {
    margin: 0 0 var(--spacing-xs);
  }
}

.LinkBlock__textContainer {
  margin-bottom: 0;
  display: flex;
  flex-flow: column;
}

.LinkBlock__icon {
  margin: 0 0 var(--spacing-s);
  width: 100px;
  max-height: 100px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 90px;
    height: 90px;
    fill: var(--kog_aqua_tint_6);

    @media (--tablet) {
      width: 100px;
      height: 100px;
    }
  }

  & picture {
    width: 100%;
  }
}

.LinkBlock__text {
  margin-bottom: var(--spacing-xs);

  & > p:last-of-type {
    margin-bottom: 0;
  }
}
