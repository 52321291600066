.FrontCover {
  margin: 160px auto 80px;
  @media (--tablet) {
    margin-bottom: 140px;
    max-height: 582px;
  }
}

.FrontCover__body {
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    flex-direction: row;
  }
}

.FrontCover__pictureContainer {
  @media (--tablet) {
    flex: 1;
    & picture {
      & img {
          width: 100%;
      }
    }
  }
  @media (--laptop) {
    max-width: 450px;
  }

  & picture {
    & img {
      width: 100%;
    }
  }
}

.FrontCover__text {
    @media (--tablet) {
        flex: 1;
        margin-left: 20px;
    }
    @media (--laptop) {
        flex: 1;
        margin: 0;
        padding: 0 192px 0 38px;
    }
}

.FrontCover__title {
  margin: 0.8rem 0 0;
  text-transform: none;

  @media (--tablet) {
    font-size: 24px;
  }
}

.FrontCover__PublishDate {
  @media (--tablet) {
    margin: 0 0 20px;
  }
}

.FrontCover__TextSuperHeader {
  margin: 0;
}

.FrontCover__TextHeader {
  margin: 3.2rem 0 0.8rem 0;

  @media (--tablet) {
    margin: 64px 0 18px;
  }
}

.FrontCover__title--capitalized {
  text-transform: uppercase;
}

.FrontCover__link{
  margin-top: 23px;
  flex: 1;

  @media (--tablet) {
    margin-top: 0;
  }

  & .FrontCover__link--text{
      background: svg-load('Icons/ArrowRightSimple.svg', fill: var(--buttonExploreColor)) no-repeat;
      padding-left: 35px;
      padding-top: 4px;
      font-size: 16px;
  
    &:hover{
       text-decoration: underline;
       text-decoration-color: var(--buttonExploreColor);
       animation: moveBackgroundImage-10px ease 2s infinite;
     }
  }
}
