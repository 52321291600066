.StoryPageSection:first-child .StoryTextContent--inherited {
  background-color: transparent;

  & .StoryTextContent__background {
    display: none;

    @media (--laptop) {
      background-attachment: initial;
    }
  }

  &.StoryTextContent--themeDark {
    & .StoryTextContent__background {
      display: none;

      &::before {
        animation: textContentFadeIn 0.6s ease-in-out forwards;
      }
    }
  }

  &.StoryTextContent--themeLight {
    & .StoryTextContent__background {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: 50%;
      background-size: cover;
      margin: 0;
      background-image: none !important;

      &::before {
        display: block;
      }
    }

    & .StoryTextContent__blur {
      display: none;
      
      @media (--laptop) {
        display: block;
        position: absolute;
        top: -50px;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(100% + 40px);
        background-position: 50%;
        background-size: cover;
        background-attachment: fixed;
        filter: blur(12px);
      }
    }
  }
}

@keyframes textContentFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: var(--storiesBlockFinalOpacity);
  }
}