@define-mixin storyTextContent {
    width: calc(100% - 60px);
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 2rem;

    @media (--tablet) {
        float: right;
        margin-left: 0;
        padding-left: 25px;
        margin-right: 60px;
        max-width: calc(70% - 60px);
        margin-bottom: 0;
    }

    @media (--laptop) {
        float: right;
        margin-left: 0;
        padding-left: 25px;
        margin-right: calc((100% - var(--storiestextwrapperWidth)) / 2);
        max-width: calc(560px + 25px);
    }
}

@define-mixin storyTextAside {
    @media (--tablet) {
        display: inline-block;
        float: left;
        max-width: calc(28% - 30px);
    }

    @media (--laptop) {
        max-width: 270px;
        margin-left: calc((100% - var(--storiestextwrapperWidth)) / 2);
    }
}