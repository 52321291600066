:root {
  --asideWidthTablet: 28%;
  --textWidthTablet: 70%;
  --asideWidth: 270px;
  --textWidth: 560px;
  --asidePadding: 25px;
  --margin: calc((100% - var(--storiestextwrapperWidth)) / 2);
}

.StoryTextContent {
  position: relative;
  padding: 2rem 0;

  @media (--tablet) {
    padding: 4.5rem 0;
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(80px);
    }
  }
}

.StoryTextContent__inner {
  position: relative;
  z-index: 1;
  @mixin clearfix();

  @media (--tablet) {
    display: block;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
  
  & ol,& ul {
    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 1.9;
    @media (--tablet) {
      font-size: 18px;
      line-height: 1.9;
    }
    @media (--laptop) {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.StoryTextContent__body {
  @mixin storyTextContent;
  position: relative;

  &.animate {
    @media (--tablet) {
      transform: translateY(80px);
    }
  }
}

.StoryTextContent__aside {
  @mixin storyTextAside;
  position: relative;

  & > .animate {
    @media (--tablet) {
      transform: translateY(80px);
    }
  }
}