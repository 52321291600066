:root {
  --magazineLandingPageWrapperWidth: 71.25rem; /* 1140px */
  --magazineLandingPageWrapperPadding: 15px;
}

.MagazineLandingPage {
  & .wrapper {
    @mixin wrapper var(--magazineLandingPageWrapperWidth), var(--magazineLandingPageWrapperPadding);
  }

  & .ContentCard__title {
    font-family: var(--font-family-stories-secondary);
    font-weight: 500;
  }
}

.Ingress {
  @mixin ingress;
  @neat-outer-container;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & .Ingress__leftColumn {
    margin-bottom: 1rem;
    @neat-span-columns 12;

    display: flex;
    flex-flow: column;
    font-style: italic;

    @media (--mobileOnly) {
      @neat-omega 1n;

      display: flex;
      flex-flow: column;
    }

    @media (--tablet) {
      margin-bottom: 2rem;
      @neat-span-columns 4;
      @neat-omega 2n;

      max-width: 48.6%;
      margin-right: 2.5%;
      display: flex;
      flex-flow: column;
      padding-right: 1rem;
    }
  }

  & .Ingress__rightColumn {
    margin-bottom: 1rem;
    @neat-span-columns 12;

    display: flex;
    flex-flow: column;
    position: relative;
    font-weight: 500;

    & p {
      font-weight: 500;
    }

    @media (--mobileOnly) {
      @neat-omega 1n;

      display: flex;
      flex-flow: column;
    }

    @media (--tablet) {
      margin-bottom: 2rem;
      @neat-span-columns 8;
      @neat-omega 2n;

      margin-right: 2.5%;
      display: flex;
      flex-flow: column;
      padding-left: 3rem;

      &::before {
        content: "";
        width: 1px;
        border-left: 1px solid var(--blackColor);
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @media (--desktop) {
      padding-left: 5rem;
    }

    &.Ingress__rightColumn--noSeparator {
      width: 100%;

      @media (--tablet) {
        margin: 0;
        padding: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  & .RichtextArea {
    @mixin ingressAlternative;

    & p {
      @mixin ingressAlternative;
    }

    & strong {
      font-weight: 400;
    }
  }
}
