.Chart {
  & svg > g > g:last-child {
    pointer-events: none;
  }
}

/*
  Below css classes are to fix data label position to be more centered, hacky solution
*/

.Chart--svgFixUp {
  & svg g:nth-last-child(2) {

    & text {
      transform: translate(-15px, -15px);
    }
  }
}

.Chart--svgFixDown {
  & svg g:nth-last-child(2) {

    & text {
      transform: translate(-10px, 10px);
    }
  }
}