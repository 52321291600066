.MicrositeSection {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  @media (--tablet) {
    align-items: flex-end;
  }

  @media (--laptop) {
    align-items: center;
  }
}

.MicrositeSection__hero {
  position: absolute;
  z-index: -1;
  width: 100%;

  & .MicrositeHero:before {
    z-index: 1;
  }

  @media (--mobileOnly) {
    & .MicrositeHero__image img {
      transition: filter .3s ease-in-out;
    }

    &.MicrositeSection__hero--blurred {
      & .MicrositeHero__image img {
        filter: blur(5px);
      }
    }
  }
}

.MicrositeSection__container {
  height: 100%;
  width: 100%;
  color: var(--whiteColor);

  @media (--tablet) {
    position: relative;
    padding-top: 0;
    margin: 48px;
    min-width: 600px;
    width: 75%;
    height: 75%;
  }

  @media (--laptop) {
    height: 100%;
    width: 45%;
    margin-left: calc(var(--menuBarSize) + 24px);
  }

  @media (--desktop4k) {
    margin-left: calc(var(--menuBarSize4k) + 48px);
  }
}

.MicrositeSection__product {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
