.OfficeLocationsFiltersLocateMeButton {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonBaseFill;

  @media (--mobileSmall) {
    @mixin buttonDefaultSizeIconRight;
    @mixin buttonBaseFill;
  }
}

.OfficeLocationsFiltersLocateMeButton__text {
  @media (--mobileSmallOnly) {
    @mixin visuallyHidden;
  }
}
