.StoriesImageViewer {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 70vw;
  overflow: hidden;
  white-space: nowrap;

  @media (--tablet) {
    height: 60vw;
  }

  @media (--laptop) {
    height: 60vw;
    max-height: 100vh;
  }

  @media (--desktop) {
    height: 55vw;
  }

  & img {
    width: 100%;
    height: 100%;
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(80px);
    }
  }
}

.StoriesImageViewer--limited {
  height: auto;
  max-width: 890px;
  width: calc(100% - 30px);
  margin: 0 auto;
  
  & .StoriesImageViewerSlide {
    overflow: hidden;
    padding-bottom:56.25%;

    & picture {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.StoriesImageViewer__inner {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  transition: ease 1s;
}

.StoriesImageViewerSlide {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100%;

  & picture {
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.StoriesImageViewerSlide__label {
  white-space: normal;
  position: absolute;
  color: var(--whiteColor);
  bottom: 0;
  right: 0;
  backdrop-filter: blur(2px);
  max-width: 100%;
  width: 100%;
  padding: 1rem 30px 1.3rem 30px;

  @media (--tablet) {
    padding: 1.5rem 1.5rem 1.5rem 2rem;
    position: absolute;
    overflow: visible;
    backdrop-filter: blur(36px);
    min-width: 300px;
    width: 71%;
  }

  @media (--laptop) {
    padding: 0;
    position: absolute;
    overflow: visible;
    width: calc(620px + calc((100% - 890px) / 2));
  }

  &::before {
    content: '';
    opacity: 0.7;
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--kog_blue);
    z-index: 0;
    top: 0;
    left: 0;
  }

  &.animate {
    @media (--tablet) {
      transform: translateX(100%);
    }
  }
}

.StoriesImageViewerSlide__labelInner {
  z-index: 1;
  position: relative;
  line-height: 1.9;

  @media (--mobileOnly) {
    font-size: 12px;
    max-width: none;
    line-height: 1.2;
    margin: 0;
    left: 0;
    right: 0;
    max-height: 45px;
    overflow-y: scroll;
  }

  @media (--tablet) {
    font-size: 14px;
  }

  @media (--laptop) {
    margin: 3rem 60px;
    font-size: 18px;
  }

  & a {
    color: var(--kog_blue_tint_8);

    &:hover {
      text-decoration: underline;
    }
  }

  & p {
    font-size: 12px;
    margin: 0;

    @media (--tablet) {
      font-size: 14px;
    }

    @media (--laptop) {
      font-size: 18px;
    }
  }
}

.StoriesImageViewer__changeImageButton {
  @mixin buttonNext var(--blackColor), var(--whiteColor);
  position: absolute;
  top: calc(50% - 24px);
}

.StoriesImageViewer__changeImageButton--previous {
  @mixin buttonPrevious var(--blackColor), var(--whiteColor);
  left: 30px;

  @media (--mobileOnly) {
    left: 15px;
  }
}

.StoriesImageViewer__changeImageButton--next {
  right: 30px;

  @media (--mobileOnly) {
    right: 15px;
  }
}

.StoriesImageViewer__changeImageButton:hover {
  animation: none;
}