@define-mixin discoveryCheckboxLarge {
  --checkbox-size: var(--checkbox-large-size);

  & .Checkbox__box {
    border-width: 2px;
  }

  & .Checkbox__labelText {
    @mixin typographyMobileDefaultLarge;
  }

  & .Checkbox__label > .Checkbox__input:checked + .Checkbox__inner > .Checkbox__box {
    border-width: 2px;
  }
}
