.kog-grid {
  @mixin kogGrid;

  &.kog-grid--auto-cols {
    grid-template-columns: repeat(auto-fix, minmax(0, 1));
  }
}

.kog-row {
  @mixin kogGrid;
  grid-row-end: span 1;
}

@mixin kogGridColumns kog;
