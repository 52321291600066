.OfficeLocationsFiltersSearchInput {
  display: flex;
  flex: 1;
  position: relative;
  border-bottom: 1px solid #393B3F;
}

.OfficeLocationsFiltersSearchInput__label {
  @mixin visuallyHidden;
}

.OfficeLocationsFiltersSearchInput__autocomplete {
  width: 100%;
}

.OfficeLocationsFiltersSearchInput__field {
  flex: 1;
  display: block;
  border-radius: 0;
  border: 0;
  width: 100%;
  @mixin typographyMobileDefaultLarge;
  padding: var(--spacing-base) 0;
  color: var(--base-grey-black-100);
  height: 2.5rem; /* 40px */
  @mixin resetSearchAppearance;

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::placeholder {
    color: #8A8A8A;
  }

  &:focus {
    outline: none;
  }
}

.OfficeLocationsFiltersSearchInput__button {
  @mixin resetButtonStylesNoOverride;
  @mixin flexCenter;
  cursor: pointer;
  font-size: 1.25rem; /* 20px */
  height: 2.5rem; /* 40px */
  width: 2.5rem;
  flex-shrink: 0;
  @mixin focusVisible;
}
