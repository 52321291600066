.Hero {
  width: 100%;
  overflow: hidden;
  margin-bottom: var(--spacing-m);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;

  @media (--tablet) {
    margin-bottom: var(--spacing-xxxxl);
    margin-left: 0;
    width: 100%;
  }
}

.Hero__media {
  min-height: var(--heroMaxHeight);
}

.Hero__media,
.Hero__container {
  width: 100%;
  max-height: var(--heroMaxHeight);
  overflow: hidden;
  position: relative;
}

.Hero__picture {
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: var(--heroMaxHeight);
  overflow: hidden;

  & picture {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & img {
    width: 100%;
    min-height: 100%;
    max-height: var(--heroMaxHeight);
  }
}

.Hero__placeholder {
  width: 100%;
  min-height: 100%;
  max-height: var(--heroMaxHeight);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(1rem);
}

.Hero__photoCreditContainer {
  @mixin wrapper;

  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: var(--spacing-xs);

  @media (--tablet) {
    justify-content: flex-end;
  }
}

.Hero__photoCreditText {
  @mixin typographyMobileCapsDefault;

  transition: all 0.3s;
  transition-property: max-width, margin-left;
  margin-left: 0;
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;

  @media (--tablet) {
    @mixin typographyDesktopCapsDefault;
  }
}

.Hero__photoCredit {
  display: flex;
  align-items: center;
  padding: var(--spacing-xxs);
  border-radius: 50px;
  color: #bdbcbc;
  /* stylelint-disable-next-line color-function-notation */
  background: rgba(255, 255, 255, 0.1);

  @media (--tablet) {
    &:hover {
      .Hero__photoCreditText {
        max-width: 400px;
        margin-left: 11px;
      }
    }
  }
}

@media (--mobileOnly) {
  .photoCredit:checked + .Hero__photoCreditText {
    max-width: 400px;
    margin-left: 11px;
  }
}

.Hero__heading {
  display: flex;
  flex-direction: unset;
  bottom: 0;
  gap: 40px;

  @media (--mobileOnly) {
    gap: 20px;
    bottom: 32px;
  }
}

.Hero__buttons {
  z-index: 10;
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-xs);
}
