.StoryRichtextBlock {
  &.RichtextBlock .VideoFigure {
    max-width: var(--storiestextwrapperWidth);
    margin: 0 auto;

    @media (--laptop) {
      padding: 0;
    }
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(160px);
    }
  }
}
