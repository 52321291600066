.OfficeLocationItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: var(--spacing-s);
  padding: var(--spacing-xs) var(--spacing-s);
  transition: all 0.3s;
  @mixin focusVisible var(--outlineColorSecondary);

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 1px;
    background: #cfd1d3;
    transition: all 0.3s;
  }

  @media (--laptop) {
    padding: var(--spacing-s) calc(1.75 * var(--spacing-xxs)) var(--spacing-s) var(--spacing-xxs);
  }

  &:hover {
    background: var(--kog_blue_tint_7);

    &::after {
      height: 2px;
      background: var(--accent-blue-accent-default);
    }

    & .LinkArrowBlockList__itemReadMore {
      opacity: 1;
    }

    @media (--laptop) {
      & .LinkArrowBlockList__itemArrowWrapper {
        transform: translateX(calc(1.25 * var(--spacing-xxxs)));
      }
    }
  }
}

.OfficeLocationItem__itemTextsWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  flex: 1;

  @media (--laptop) {
    gap: var(--spacing-s);
    flex-direction: row;
    align-items: center;
  }
}

.OfficeLocationItem__title {
  @mixin typographyMobileStrongLarge;

  flex: 2;

  @media (--laptop) {
    @mixin typographyDesktopStrongLarge;
  }
}

.OfficeLocationItem__location {
  @neat-span-columns 12;

  @media (--tablet) {
    @neat-span-columns 3;
  }
  
  @media (--laptop) {
    @neat-span-columns 2;
  }
}

.OfficeLocationItem__contact {
  display: none;
  font-size: 14px;
  
  @media (--laptop) {
    display: block;
    @neat-span-columns 3;
  }
  
  & > span {
    display: block;
  }
}

.OfficeLocationItem__button {
  @neat-span-columns 12;
  margin-bottom: 10px;

  @media (--tablet) {
    @neat-span-columns 4;
    margin-bottom: 0;
  }
  
  @media (--laptop) {
    @neat-span-columns 2;
  }
  
  & a {
    margin-bottom: 0;
    width: 100%;
  }
}