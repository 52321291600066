.CalendarItemPage h1.Title {
    margin-bottom: 0.5rem;
} 

.CalendarItemPage__date {
    margin-top: 3rem;
    font-size: 38px;
}

.CalendarItemPage__location {
    font-size: 38px;
    margin-bottom: 3rem;
}