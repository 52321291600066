picture img,
figure img {
  max-width: 100%;
  object-fit: cover;
  object-position: center;

  &.ImageSmall {
    width: 65%;

    @media(--mobileSmall) {
      width: 40%;
    }
  }

  &.ImageMedium {
    width: 100%;
    
    @media(--mobileSmall) {
      width: 70%;
    }
  }

  &.ImageFullWidth {
    width: 100%;
  }
}