.PillGroup__fieldset {
  --gap: 6px;

  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 0;
  padding: 0;
  border-width: 0;
}
