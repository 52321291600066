.MicrositeProductVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & .RichVideo__playerWrapper {
    position: absolute;
    padding-top: 0;
    margin-top: 0;

    &:after {
      background: rgba(50, 56, 61, 0.56);
      display: block;
    }

    &.RichVideo__playerWrapper--isPlaying {
      &:after {
        display: none;
      }
    }
  }

  & .RichVideo__text {
    display: block;
    height: auto;
  }
}

.MicrositeProductVideo__button.RichVideo__btn {
  width: 75px;
  height: 75px;
  margin: 0;
  display: block;

  @media (--tablet) {
    width: 150px;
    height: 150px;

    &.RichVideo__btn:before {
      width: 150px;
      height: 150px;
    }
  }

  &:before {
    width: 75px;
    height: 75px;
    background: transparent;
    border: 1px solid var(--whiteColor);
  }
}

.MicrositeProductVideo__close {
  @mixin sectionPageCloseButtonPosition var(--sectionSideOffset);
}
