@define-mixin hotSpotTheme $color, $modifier: white {
  &.InteractiveHeroBlock__HotSpotLink {
    & .InteractiveHeroBlock__Icon {
      border: 3px solid $color;

      @media (--dekstop) {
        border: 3px solid $color;
      }
    }

    .InteractiveHeroBlock__Inner {
      background: var(--whiteColor);

      & svg {
        fill: var(--blackColor);
      }
    }

    &::after {
      background: $color;
      box-shadow: 0 0 30px 4px $color;
    }

    &:hover {
      &::after {
        box-shadow: 0 0 30px 8px color-mix(in sRGB, $modifier 40%, $color);
      }

      & .InteractiveHeroBlock__Icon {
        border: 3px solid color-mix(in sRGB, $modifier 40%, $color);
      }
    }
  }
}

.InteractiveHeroBlock__HotSpotLink--default {
  @mixin hotSpotTheme var(--kog_aqua);
}

.InteractiveHeroBlock__HotSpotLink--red {
  @mixin hotSpotTheme var(--kog_red);
}

.InteractiveHeroBlock__HotSpotLink--blueFive {
  @mixin hotSpotTheme var(--kog_blue_tint_7);
}

.InteractiveHeroBlock__HotSpotLink--sandFour {
  @mixin hotSpotTheme var(--kog_bronze_tint_1);
}

.InteractiveHeroBlock__HotSpotLink--tealTwo {
  @mixin hotSpotTheme var(--kog_aqua_tint_6);
}

.InteractiveHeroBlock__HotSpotLink--tealFive {
  @mixin hotSpotTheme var(--kog_aqua_tint_1);
}

.InteractiveHeroBlock__HotSpotLink--yellowFour {
  @mixin hotSpotTheme var(--kog_yellow);
}

.InteractiveHeroBlock__HotSpotLink--greenTwo {
  @mixin hotSpotTheme var(--kog_green_tint_3);
}

.InteractiveHeroBlock__HotSpotLink--blueTwo {
  @mixin hotSpotTheme var(--kog_blue_tint_1);
}

.InteractiveHeroBlock__HotSpotLink--sandTwo {
  @mixin hotSpotTheme var(--kog_bronze_tint_6);
}

.InteractiveHeroBlock__HotSpotLink--forestTwo {
  @mixin hotSpotTheme var(--kog_forest_tint_3);
}

.InteractiveHeroBlock__HotSpotLink--greenFour {
  @mixin hotSpotTheme var(--kog_green_tint_2);
}

.InteractiveHeroBlock__HotSpotLink--white {
  @mixin hotSpotTheme var(--whiteColor), black;
}

.InteractiveHeroBlock__HotSpotLink--black {
  @mixin hotSpotTheme var(--textColor);
}

.InteractiveHeroBlock__HotSpotLink--greenOne {
  @mixin hotSpotTheme var(--kog_green_tint_4);
}

.InteractiveHeroBlock__HotSpotLink--greenFive {
  @mixin hotSpotTheme var(--kog_green_tint_1);
}

.InteractiveHeroBlock__HotSpotLink--forestFive {
  @mixin hotSpotTheme var(--kog_forest_tint_1);
}

.InteractiveHeroBlock__HotSpotLink--blueThree {
  @mixin hotSpotTheme var(--kog_blue_tint_9);
}

.InteractiveHeroBlock__HotSpotLink--yellowTwo {
  @mixin hotSpotTheme var(--kog_yellow_tint_3);
}

.InteractiveHeroBlock__HotSpotLink--yellowFive {
  @mixin hotSpotTheme var(--kog_yellow_tint_1);
}

.InteractiveHeroBlock__HotSpotLink--redFour {
  @mixin hotSpotTheme var(--kog_red_tint_1);
}

.InteractiveHeroBlock__HotSpotLink--greyTwo {
  @mixin hotSpotTheme var(--grayColor_tint_6);
}

.InteractiveHeroBlock__HotSpotLink--greyFive {
  @mixin hotSpotTheme var(--grayColor_tint_2);
}
