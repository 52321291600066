@define-mixin contactCardColorVariant $backgroundColor, $fontColor: var(--textColor) {
  &.ContactCard--vertical .ContactCard__inner {
    color: $fontColor;

    & a {
      color: inherit;
    }
  }

  &.ContactCard--vertical .ContactCard__inner::after {
    content: "";
    position: absolute;
    background: $backgroundColor;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  &.ContactCard--horizontal .ContactCard__info {
    background: transparent;

    &::before,
    &::after {
      background: $backgroundColor;
    }
  }

  & .ContactCard__inner .ContactCard__phone::before {
    background: svg-load("Icons/Phone1.svg", fill: $fontColor);
  }

  & .ContactCard__inner .ContactCard__address::before {
    background: svg-load("Icons/EmailEnvelope.svg", fill: $fontColor);
  }
}

.ContactCardBackground--Default {
  @mixin contactCardColorVariant var(--kog_blue_tint_7);
}

.ContactCardBackground--BlueFive {
  @mixin contactCardColorVariant var(--kog_blue_tint_7);
}

.ContactCardBackground--BlueTwo {
  @mixin contactCardColorVariant var(--kog_blue_tint_1), var(--whiteColor);
}

.ContactCardBackground--SandFour {
  @mixin contactCardColorVariant var(--kog_bronze_tint_1);
}

.ContactCardBackground--GreenFour {
  @mixin contactCardColorVariant var(--kog_green_tint_2);
}

.ContactCardBackground--YellowFour {
  @mixin contactCardColorVariant var(--kog_yellow);
}

.ContactCardBackground--TealFive {
  @mixin contactCardColorVariant var(--kog_aqua_tint_1);
}

.ContactCardBackground--TealTwo {
  @mixin contactCardColorVariant var(--kog_aqua_tint_6);
}

.ContactCardBackground--GreenTwo {
  @mixin contactCardColorVariant var(--kog_green_tint_3);
}

.ContactCardBackground--SandTwo {
  @mixin contactCardColorVariant var(--kog_bronze_tint_6);
}

.ContactCardBackground--ForestTwo {
  @mixin contactCardColorVariant var(--kog_forest_tint_3);
}

.ContactCardBackground--GreenOne {
  @mixin contactCardColorVariant var(--kog_green_tint_4), var(--whiteColor);
}

.ContactCardBackground--GreenFive {
  @mixin contactCardColorVariant var(--kog_green_tint_1);
}

.ContactCardBackground--ForestFive {
  @mixin contactCardColorVariant var(--kog_forest_tint_1);
}

.ContactCardBackground--BlueThree {
  @mixin contactCardColorVariant var(--kog_blue_tint_9), var(--whiteColor);
}

.ContactCardBackground--YellowTwo {
  @mixin contactCardColorVariant var(--kog_yellow_tint_3);
}

.ContactCardBackground--YellowFive {
  @mixin contactCardColorVariant var(--kog_yellow_tint_1);
}

.ContactCardBackground--RedFour {
  @mixin contactCardColorVariant var(--kog_red_tint_1);
}

.ContactCardBackground--GreyTwo {
  @mixin contactCardColorVariant var(--grayColor_tint_6), var(--whiteColor);
}

.ContactCardBackground--GreyFive {
  @mixin contactCardColorVariant var(--grayColor_tint_2);
}
