.SectionPage {
  .Intro {
    margin-block: var(--spacing-xxxl);
  }

  .IntroButtonWrapper,
  .BodyButtonWrapper {
    margin-top: var(--spacing-s);

    @media (--mobileSmall) {
      margin-top: var(--spacing-m);
    }

    & .CallToActionButton {
      width: 100%;
      max-width: unset;

      @media (--mobileSmall) {
        width: unset;
        
        /* max-width: 350px; // temporary disablement of fix for issue #34622 */
      }
    }
  }
}