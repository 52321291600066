.StoryHighlightBlock {
  display: flex;
  align-items: center;
}

.StoryHighlightBlock__icon {
  & svg {
    width: var(--spacing-xl);
    height: var(--spacing-xl);

    @media (--mobileSmall) {
      width: var(--spacing-xxxl);
      height: var(--spacing-xxxl);
    }

    @media (--tablet) {
      width: var(--spacing-xxxxl);
      height: var(--spacing-xxxxl);
    }

    & path {
      fill: var(--kog_bronze);
    }
  }
}

.StoryHighlightBlock__text {
  @mixin typographyMobileDefaultLarge;

  margin-inline: var(--spacing-m) 0;

  @media (--mobileSmall) {
    @mixin typographyMobileDefaultXLarge;
  }

  @media (--tablet) {
    @mixin typographyDesktopDefaultXLarge;
  }
}
