@define-mixin latestNewsThemeColorVariant $buttonVariant {
  & .LatestNews {
    & .LatestNews__readMore {
      & .ButtonSecondary {
        @mixin $(buttonVariant);
      }
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorForestTwo;
}

.ContainerWithBackground--GreenOne {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin latestNewsThemeColorVariant buttonSecondaryThemeColorGreyFive;
}
