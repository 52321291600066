.ProductPage {
  & *[id] {
    scroll-margin-top: var(--sections-navbar-scroll-margin);
  }

  & > .Section:nth-of-type(odd):not(.Section--blue) {
    background-color: var(--base-grey-light-60);

    & .TableContent__expandableSection {
      &::after {
        background: linear-gradient(
          0deg,
          var(--base-grey-light-60) 20%,
          color(var(--base-grey-light-60) a(60%)) 66%,
          color(var(--base-grey-light-60) a(20%)) 90%,
          color(var(--base-grey-light-60) a(0%)) 100%
        );
      }
    }
  }
}
