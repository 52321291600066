.CallToActionSection__wrapper {
    display: grid;
    gap: var(--spacing-m);
    grid-template: auto / 1fr;

    @media (--tablet){
        display: grid;
        grid-template: 1fr / 2fr 1fr;
    }
}

.CallToActionSection__leftColumn {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
    max-width: 696px;
}

.CallToActionSection__rightColumn {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (--tablet){
        align-items: end;
    }
}

.CallToActionSection__title {
    @mixin typographyMobileStrongXLarge;

    @media (--laptop) {
        @mixin typographyDesktopDisplayLarge;
    }
}

.CallToActionSection__body,
.CallToActionSection__body p {
    @mixin typographyMobileDefaultLarge;

    @media (--laptop) {
        @mixin typographyDesktopStrongXLarge;
    }
}

.CallToActionSection__buttons {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
}

.CallToActionSection__buttons > a {
    @mixin buttonDefaultSize;
    @mixin buttonPrimaryTealFill;

    @media (--mobileOnly) {
        @mixin buttonFullWidth;
    }
}