.OfficeLocationsListItem {
  border-radius: 2px;
  background-color: var(--base-grey-light-60);
  border: 2px solid var(--base-grey-light-60);
}

.OfficeLocationsListItem__article {
  padding: var(--spacing-xxs) calc(var(--spacing-xxxs) + calc(var(--spacing-xxxs) / 2)) 0;
}

.OfficeLocationsListItem__header {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-xxs);
  width: 100%;

  @media (--tablet) {
    gap: calc(var(--spacing-xxxs) + calc(var(--spacing-xxxs) / 2));
    justify-content: space-between;
  }

  @media (--laptop) {
    max-width: 250px;
  }
}

.OfficeLocationsListItem__name {
  @mixin typographyMobileStrongMedium;
  flex: 1;
  line-height: 1;

  @media (--tablet) {
    @mixin typographyDesktopStrongSmall;
    line-height: 1;
  }
}

.OfficeLocationsListItem__badges {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--spacing-xxxs);
  align-items: flex-end;

  @media (--tablet) {
    flex-direction: row;
    gap: calc(var(--spacing-xxxs) + calc(var(--spacing-xxxs) / 2));
    margin-top: calc(var(--spacing-xxxs) / 2);
  }
}

.OfficeLocationsListItem__footer {
  display: flex;
  justify-content: center;
}

.OfficeLocationsListItem__controlButton {
  @mixin resetButtonStylesNoOverride;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xxxs);
  @mixin typographyMobileDefaultMedium;
  color: var(--base-grey-black-100);
  text-transform: lowercase;
  height: 1.5rem; /* 24px */
  transition: transform 250ms;
  cursor: pointer;
  @mixin focusVisible;

  @media (--tablet) {
    @mixin typographyDesktopDefaultXSmall;
    height: 1.875rem; /* 30px */
  }
}

.OfficeLocationsListItem__controlButtonChevron {
  font-size: 0.625rem; /* 10px */
  margin-top: calc(var(--spacing-xxxs) / 2);
  transition: transform 250ms;
}

.OfficeLocationsListItem__inner {
  position: relative;
}

.OfficeLocationsListItem__contactInfo {
  margin-block: calc(var(--spacing-xxs) + var(--spacing-xxxs));

  @media (--tablet) {
    margin-block: calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-xs);
  }
}

.OfficeLocationsListItem__contactDetails {
  display: grid;
  row-gap: calc(var(--spacing-xxxs) + calc(var(--spacing-xxxs) / 2));
  grid-auto-rows: auto;
  width: 100%;
  overflow: hidden;
  margin-bottom: calc(var(--spacing-xs) + calc(var(--spacing-xxxs) / 2));
}

.OfficeLocationsListItem__link {
  display: block;
  font-size: 0.75rem; /* 12px */
  line-height: normal;
  color: var(--accent-blue-accent-default);
  text-decoration: underline;
  text-underline-offset: 2px;
  width: max-content;

  @media (--tablet) {
    @mixin typographyDesktopDefaultSmall;
    margin-block: 0 var(--spacing-xxs);
    line-height: 1;
    text-decoration: none;

    &:last-child {
      margin-block: 0;
    }
  }
}

.OfficeLocationsListItem__link--websiteLink {
  text-decoration: none;
}

.OfficeLocationsListItem__link--small {
  @mixin typographyMobileDefaultUnderlinedXSmall;

  @media (--tablet) {
    @mixin typographyMobileDefaultSmall;
    text-decoration: none;
  }
}

.OfficeLocationsListItem__locationAddress {
  @mixin typographyMobileDefaultSmall;
  padding-block: var(--spacing-xs);
  color: #64686D;

  @media (--tablet) {
    @mixin typographyDesktopDefaultXSmall;
    margin-block: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0;
    padding-block: 0;
  }
}

.OfficeLocationsListItem__locationAddressLine {
  display: block;
}

.OfficeLocationsListItem__locationDirectionsLink {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryBlueGhost;

  & > svg {
    stroke: initial;
  }

  @media (--laptop) {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (--mobileAndTablet) {
    padding-left: 0;
    margin-left: -2px;
  }
}

.OfficeLocationsListItem__subtitle {
  display: block;
  @mixin typographyMobileStrongXSmall;
  line-height: var(--spacing-xxs);
  color: var(--blackColor);
}

.OfficeLocationsListItem__detailsSection {
  display: grid;
  gap: var(--spacing-xxxs) 0;
  grid-column: span 1;

  &:first-child:last-child {
    grid-column: span 2;
  }

  & p {
    @mixin typographyMobileDefaultSmall;

    @media (--tablet) {
      @mixin typographyDesktopDefaultXSmall;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.OfficeLocationsListItem__departments {
  display: grid;
  grid-auto-rows: auto;
  row-gap: var(--spacing-xxs);
  width: 100%;
}

.OfficeLocationsListItem__departmentsRow {
  display: grid;
  column-gap: calc(var(--spacing-xxxs) + var(--spacing-xxs));
  grid-template-columns: repeat(2, minmax(0, 1fr));

  &:first-child {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
}

.OfficeLocationsListItem__department {
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: var(--spacing-xxxs);
  overflow-y: hidden;

  & .OfficeLocationsListItem__link {
    width: fit-content;
    word-wrap: break-word;
  }
}

.OfficeLocationsListItem__productsCategories {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--spacing-xxs);
}

.OfficeLocationsListItem__showAllCategoriesButton {
  @mixin resetButtonStylesNoOverride;
  @mixin focusVisible;
  cursor: pointer;
}

.OfficeLocationsListItem__buttonLink {
  @mixin flexCenter;
  @mixin typographyMobileDefaultXSmall;
  height: var(--spacing-s);
  padding: var(--spacing-xxs);
  color: var(--kog_blue_tint_9);

  &:hover {
    @mixin textButtonLinkUnderline currentcolor, 1px;
  }
}

.OfficeLocationsListItem--selected {
  border-color: var(--accent-blue-accent-dark);
}

.OfficeLocationsListItem--expanded {
  & .OfficeLocationsListItem__footer {
    margin-top: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
  }

  & .OfficeLocationsListItem__controlButtonChevron {
    transform: rotate(-180deg);
  }
}
