.SlidesNavigation {
    display: flex;
    position: relative;

    & .SlidesNavigation__stickyRoll {
        display: none;

        @media (--laptop) {
            width: 0;
            overflow: visible;
            z-index: 100;
            position: relative;
            color: white;
            display: block;
        }
    }
}

.SlidesNavigation__container {
    @media (--laptop) {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }
}

.SlidesNavigation__slide--lazy {
    @media (--laptop) {
        display: none;
        transform: translateY(130vh);
    }
}

.SlidesNavigation__slide {
    height: auto;
    will-change: transform;

    @media (--laptop) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 130vh;
        z-index: 1;
        transition: transform 1.2s cubic-bezier(0.22, 0.44, 0, 1);
    }

    & .SlidesNavigation__slideContainer {
        padding: 3rem 0;
        height: 100%;

        @media (--laptop) {
            padding: 20vh 0;
        }

        &.RichVideo {
            & .RichVideo__wrapper {
                padding-block: 0;
            }
        }
    }
}

.StyleguideComponentVariant__wrapper {
    & .SlidesNavigation__slideContainer {
        padding: 3rem 0;
        height: 100%;
        
        @media (--laptop) {
            padding: 20vh 0;
        }
    }
}

.SlidesNavigation__slide--up {
    @media (--laptop) {
        transform: translateY(-130vh);
    }
}

.SlidesNavigation__slide--down {
    @media (--laptop) {
        transform: translateY(30vh);
    }
}

.SlidesNavigation__slide--current {
    @media (--laptop) {
        transform: translateY(-15vh);
    }
}

.SlidesNavigation__innerScroll {
    @media (--laptop) {
        max-height: 70vh;
        overflow-y: auto;
    }
}