@import "../../../../node_modules/@splidejs/splide/dist/css/splide-core.min.css";

.MicrositeProductGallery {
  &.MicrositeProductGallery--dark {
    & .splide__arrow:before {
      background: svg-load('Icons/MicrositeGalleryArrow.svg', stroke: var(--whiteColor)) no-repeat;
    }
  }

  &.MicrositeProductGallery--light {
    & .splide__arrow:before {
      background: svg-load('Icons/MicrositeGalleryArrow.svg', stroke: var(--blackColor)) no-repeat;
    }
  }

  & .splide,
  & .splide__track {
    height: 100%;
  }

  & .splide__list {
    align-items: center;
  }

  & .splide__slide {
    height: 0;
    padding-top: 56.25%;

    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%,-50%);
    }
  }

  & .splide__pagination {
    display: none;

    @media (--laptop) {
      display: flex;
      margin-top: 32px
    }
  }

  & .splide__pagination__page {
    position: relative;
    background: none;;
    border: none;
    cursor: pointer;
    width: 80px;
    padding: 16px 0;
    margin: 0 8px;

    &:focus {
      outline: none;
      
      &:before {
        opacity: 1;
      }
    }

    &.is-active:before {
      opacity: 1;
      height: 10px;
    }

    &:hover:before {
      opacity: 1;
    }

    &:before {
      @mixin absoluteCenter;
      transition: all .3s ease-in-out;
      background: var(--kog_bronze);
      opacity: 0.5;
      display: block;
      content: "";
      width: 100%;
      height: 4px;
    }
  }

  & .splide__arrows {
    position: absolute;
    top: 90%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;

    @media (--tablet) {
      top: 50%;
    }
  }

  & .splide__arrow {
    @mixin resetButtonStyles;
    position: relative;
    cursor: pointer;
    opacity: 0.4;
    transition: opacity .3s ease-in-out;
    height: 118px;

    @media (--tablet) {
      height: 154px;
    }

    @media (--desktop4k) {
      font-size: 36px;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }

    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 46px;
      height: 154px;
      top: -18px;

      @media (--tablet) {
        top: 0;
      }
    }
  }

  & .splide__arrow--prev {
    padding: 20px 16px 20px 32px !important;

    @media (--tablet) {
      padding: 15px 12px 15px 24px !important;
      margin-left: -100px !important;
    }

    @media (--laptop) {
      margin-left: -124px !important;
    }

    @media (--desktop4k) {
      margin-left: -175px !important;
    }

    &:before {
      left: 0;
      transform: rotate(180deg) scale(0.75);

      @media (--tablet) {
        transform: rotate(180deg) scale(1);
      }
    }
  }

  & .splide__arrow--next {
    padding: 15px 24px 15px 12px !important;

    @media (--tablet) {
      padding: 20px 32px 20px 16px !important;
      margin-right: -100px !important;
    }

    @media (--laptop) {
      margin-right: -124px !important;
    }

    @media (--desktop4k) {
      margin-right: -175px !important;
    }

    &:before {
      right: 0;
      transform: scale(0.75);

      @media (--tablet) {
        transform: scale(1);
      }
    }
  }
}
