.OfficeLocationBadge {
  display: flex;
  @mixin typographyMobileCapsDefault;
  padding: var(--spacing-xxxs) calc(var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));
  border-radius: 14px;
  text-transform: uppercase;
  background: var(--base-grey-dark-100);
  color: var(--base-grey-light-80);
  height: 0.9375rem; /* 15px */
  line-height: 1;
  align-items: center;

  @media (--tablet) {
    @mixin typographyDesktopCapsDefault;
    padding: var(--spacing-xxxs);
    height: 0.75rem; /* 12px */
  }
}

.OfficeLocationBadge--blue {
  background-color: var(--accent-blue-accent-default);
  color: var(--accent-blue-accent-lighter);
}

.OfficeLocationBadge--teal {
  background-color: var(--accent-teal-accent-default);
  color: var(--accent-blue-accent-lighter);
}
