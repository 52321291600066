.Highlight {
  @mixin wrapper;

  display: flex;
  flex-direction: column;
}

.Highlight__textSection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);

  @media (--tablet) {
    gap: var(--spacing-xs);
  }
}

.Highlight__image {
  @mixin resetButtonStylesNoOverride;
  @mixin focusVisible var(--outlineColorSecondary);

  cursor: pointer;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
  }
}

.Highlight--imageSide {
  gap: var(--spacing-xs);

  & .Highlight__image {
    aspect-ratio: 1 / 1;
  }

  @media (--tablet) {
    gap: var(--spacing-xxl);
    flex-direction: row;

    & .Highlight__textSection {
      flex: 9;
      justify-content: center;
    }

    & .Highlight__imageSection {
      flex: 7;
    }
  }

  @media (--laptop) {
    gap: var(--spacing-xxxxxxxl);
  }
}

.Highlight--imageFullWidth {
  gap: var(--spacing-xs);

  @media (--tablet) {
    gap: var(--spacing-s);

    & .Highlight__imageSection {
      order: 1;
    }
  }
}

.Highlight__title {
  @mixin typographyMobileStrongLarge;

  @media (--tablet) {
    @mixin typographyDesktopStrongXXLarge;
  }
}

.Highlight__body,
.Highlight__body > p {
  @mixin typographyMobileDefaultMedium;

  @media (--tablet) {
    @mixin typographyDesktopDefaultMedium;
  }
}

.Highlight__body > p {
  margin-block: 0 calc(var(--spacing-xs) + var(--spacing-xxxs));

  @media (--tablet) {
    margin-block: 0 var(--spacing-s);
  }

  &:last-child {
    margin-block: 0;
  }
}
