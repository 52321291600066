.StoryTextContent__background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 270px;
  max-height: 1000px;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 2rem;
  margin-top: -4rem;

  @media (--tablet) {
    height: 50vh;
    margin-bottom: 4rem;
    margin-top: -5.5rem;
  }

  @media (--laptop) {
    background-attachment: fixed;
    position: absolute;
    height: 100%;
    max-height: none;
    margin-top: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}