.ProductsAccordionList {
  & .AccordionList__accordion {
    & .ProductsAccordionList__extendedItem {
      & > a {
        display: block;
        margin-bottom: 15px;
      }
    }

    & .AccordionList__items {
      & .ProductsAccordionList__extendedItem {
        margin: 10px 0;
      }
    }
  }

  & .ProductsAccordionList__products > .ProductCatalogItemCard {
    margin-bottom: 30px;
  }
}

.ProductList {
  /* stylelint-disable-next-line declaration-no-important */
  margin: auto !important;
}
