.TableContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.TableContent--wide {
  @media (--tablet) {
    & .TableContent__section {
      grid-template-columns: repeat(12, 1fr);
    }

    & .TableContent__sectionTitle {
      grid-column: 1 / 4;
    }

    & .TableContent__sectionContent {
      grid-column: 4 / -1;
    }
  }
}

.TableContent--narrow {
  @media (--mobileSmall) {
    & .TableContent__section {
      grid-template-columns: repeat(12, 1fr);
    }

    & .TableContent__sectionTitle {
      grid-column: 1 / 5;
    }

    & .TableContent__sectionContent {
      grid-column: 5 / -1;
    }
  }
}

.TableContent__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  margin-bottom: calc(-1 * var(--spacing-xxxs));
}

.TableContent__title {
  @mixin typographyMobileStrongXXLarge;
  color: var(--base-grey-black-100);

  @media (--tablet) {
    @mixin typographyDesktopStrongXXLarge;
  }

  &.TableContent__title--alignBottom {
    align-self: flex-end;
  }
}

.TableContent__section {
  display: grid;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  padding: var(--spacing-xxs) 0 var(--spacing-s);
  border-top: 2px solid var(--base-grey-medium-100);

  &:last-of-type {
    padding-bottom: 0;
  }

  @media (--tablet) {
    gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
    padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0 var(--spacing-xs);
  }
}

.TableContent__sectionTitle {
  @mixin typographyMobileStrongLarge;
  align-self: self-start;
  color: var(--base-grey-dark-100);

  @media (--tablet) {
    @mixin typographyDesktopStrongLarge;
  }
}

.TableContent__sectionContent {
  @media (--tablet) {
    grid-row: 1;
  }
}

.TableContent__buttonWrapperHeader {
  display: none;

  @media (--tablet) {
    display: initial;
  }
}

.TableContent__showMoreBtn {
  @media (--laptop) {
    margin-top: calc(var(--spacing-xs) + var(--spacing-xxxs)) !important;
  }
}

.TableContent__buttonWrapperFooter {
  & > button {
    width: 100% !important;
    justify-content: center;
  }

  @media (--mobileSmall) {
    & > button {
      width: fit-content !important;
      margin: 0 auto !important;
    }
  }

  @media (--tablet) {
    display: none;
  }
}
