@define-mixin factBoxColorVariant $backgroundColor, $fontColor {
  & .FactBox {
    color: $fontColor;
    background: $backgroundColor;
  }

  & .FactBox__richText a,
  & .FactBox__richText a:visited {
    color: $fontColor;
    text-decoration: underline;
  }
}

.ContainerWithBackground--Beige {
  @mixin factBoxColorVariant var(--theme-color-beige-block-bg-color),
    var(--theme-color-beige-block-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin factBoxColorVariant var(--theme-color-green-block-bg-color),
    var(--theme-color-green-block-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin factBoxColorVariant var(--theme-color-blue-block-bg-color),
    var(--theme-color-blue-block-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin factBoxColorVariant var(--theme-color-turquoise-dark-block-bg-color),
    var(--theme-color-turquoise-dark-block-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin factBoxColorVariant var(--theme-color-brown-block-bg-color),
    var(--theme-color-brown-block-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin factBoxColorVariant var(--theme-color-turquoise-block-bg-color),
    var(--theme-color-turquoise-block-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin factBoxColorVariant var(--theme-color-yellow-block-bg-color),
    var(--theme-color-yellow-block-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin factBoxColorVariant var(--theme-color-green-dark-block-bg-color),
    var(--theme-color-green-dark-block-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin factBoxColorVariant var(--theme-color-blue-dark-block-bg-color),
    var(--theme-color-blue-dark-block-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin factBoxColorVariant var(--theme-color-sand-dark-block-bg-color),
    var(--theme-color-sand-dark-block-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin factBoxColorVariant var(--theme-color-forest-dark-block-bg-color),
    var(--theme-color-forest-dark-block-text-color);
}

.ContainerWithBackground--Pink {
  @mixin factBoxColorVariant var(--theme-color-pink-block-bg-color),
    var(--theme-color-pink-block-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin factBoxColorVariant var(--theme-color-green-one-block-bg-color),
    var(--theme-color-green-one-block-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin factBoxColorVariant var(--theme-color-green-five-block-bg-color),
    var(--theme-color-green-five-block-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin factBoxColorVariant var(--theme-color-forest-five-block-bg-color),
    var(--theme-color-forest-five-block-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin factBoxColorVariant var(--theme-color-blue-three-block-bg-color),
    var(--theme-color-blue-three-block-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin factBoxColorVariant var(--theme-color-yellow-two-block-bg-color),
    var(--theme-color-yellow-two-block-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin factBoxColorVariant var(--theme-color-yellow-five-block-bg-color),
    var(--theme-color-yellow-five-block-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin factBoxColorVariant var(--theme-color-red-four-block-bg-color),
    var(--theme-color-red-four-block-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin factBoxColorVariant var(--theme-color-grey-two-block-bg-color),
    var(--theme-color-grey-two-block-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin factBoxColorVariant var(--theme-color-grey-five-block-bg-color),
    var(--theme-color-grey-five-block-text-color);
}
