.CallToAction {
  position: relative;
  width: 100%;
}

.CallToAction__inner {
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    display: grid;
    grid-template: repeat(2, auto) / repeat(2, 1fr);
  }
}

.CallToAction__title {
  @mixin h4Desktop;

  margin: 0 0 var(--spacing-xs);
  grid-column: 2 / 3;

  @media (--laptop) {
    padding: 0 0 var(--spacing-xs) 0;
  }
}

.CallToAction__upperContent {
  padding: 2px;
  margin-bottom: var(--spacing-l);
}

.CallToAction__textWrapper {
  display: flex;
  align-items: center;
}

.CallToAction__text {
  padding: var(--spacing-l) var(--wrapperPadding);
  width: 100%;
  grid-column: 2 / 3;

  @media (--cta-2-col-breakpoint) {
    padding-inline: var(--spacing-xxxl);
  }

  @media(--tablet) {
    max-width: var(--cta-text-max-width);
  }
}

.CallToAction__richText {
  &,
  & p {
    @mixin typographyMobileDefaultMedium;

    font-size: 1.0625rem;

    @media (--laptop) {
      @mixin typographyDesktopDefaultMedium;
    }
  }

  margin-bottom: var(--spacing-m);

  & p {
    @mixin lineClamp 3;

    hyphens: auto;
  }
}

.CallToAction__video {
  overflow: hidden;
}

.CallToAction__media {
  width: 100%;
  position: relative;
  grid-row: 1 / 2;
  justify-self: end;

  @media (--cta-2-col-breakpoint) {
    max-width: var(--cta-media-max-width);
    max-height: var(--cta-media-max-height);
  }

  & picture,
  & .CallToAction__video {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transform: none;
    transition: all 0.3s var(--ease_out_cubic);

    &::before {
      content: "";
      width: 100%;
      padding-top: 70%;
      display: block;

      @media (--tablet) {
        padding-top: 65%;
      }
    }
  }

  & img,
  & video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.CallToAction__mediaInner {
  width: 100%;
  height: 100%;
}

.CallToAction__buttons {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  & .CallToActionButton {
    width: 100%
  }

  @media(--tablet){
    flex-direction: unset;

    & .CallToActionButton {
      width: auto;
    }
  }

}

/* When no description is set */
.CallToAction__title + .CallToAction__buttons {
  margin-top: var(--spacing-m);
}

.CallToAction--banner {
  & .CallToAction__buttons {
    display: flex;
    gap: var(--spacing-s);
    flex-flow: row wrap;
  }
}

.RichtextArea .CallToAction a,
.RichtextArea .CallToAction a:visited,
.RichtextArea .mceContentBody .CallToAction a,
.RichtextArea .mceContentBody .CallToAction a:visited {
  color: var(--textColor);
}

.RichtextArea .CallToAction {
  margin-bottom: 4rem;
}