.ContactCard--horizontal {
  @mixin wrapper;

  padding-top: 75px;

  @media (--tablet) {
    padding-top: 0;
  }

  & .ContactCard__inner {
    position: relative;

    &::before,
    &::after {
      content: none;
    }

    @media (--tablet) {
      margin: auto;
      display: flex;
      align-items: center;
    }
  }

  & .ContactCard__picture {
    top: 0;
    transform: translate(25px, -50%);

    @media (--tablet) {
      top: 50%;
      left: 130px;
      transform: translate(-50%, -50%);
    }

    @media (--laptop) {
      left: 195px;
      width: 200px;
      height: 200px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & .ContactCard__info {
    padding: 100px 25px 45px;
    position: relative;

    & a {
      color: var(--blackColor);
    }

    & .ContactCard__address::before {
      background: svg-load("Icons/Email1.svg");
    }

    & .ContactCard__phone::before {
      background: svg-load("Icons/Phone1.svg");
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: var(--kog_blue_tint_7);
    }

    @media (--tablet) {
      width: calc(100% - 130px * 2);
      padding: 40px 50px 58px 130px;
      margin: 0 auto;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
      }
    }

    @media (--laptop) {
      width: calc(100% - 195px * 2);
      padding: 40px 50px 58px 190px;
    }
  }

  & .ContactCard__name {
    font-weight: 400;
  }

  & .ContactCard__title {
    max-width: 60%;
  }

  & .ContactCard__link {
    display: none;
  }
}
