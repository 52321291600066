.LatestStories {
  @mixin wrapper;
  clear: both;
}

.LatestStories__readMore {
  display: flex;
  justify-content: center;
  width: 100%;
}
