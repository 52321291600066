.RichCallOut {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  position: relative;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (--tablet) {
    background-position: center;
  }

  & .SubTitle {
    margin-bottom: 2rem;

    @media (--tablet) {
      margin-bottom: 4rem;
    }
  }
}

.RichCallOut__wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 1.5rem 0;

  @media (--tablet) {
    padding: 2.5rem 0;
  }
  @media (--laptop) {
    padding: 4.5rem 0;
  }
  @media (--desktop) {
    padding: 6rem 0;
  }
}

.RichCallOut__content {
  @mixin richCampaignWrapper;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.RichCallOut__Title {
  @mixin h3;

  margin: 0 0 1.4rem;
  max-width: 100%;
  text-align: left;

  @media (--tablet) {
    @mixin h2Desktop;

    margin: 0 0 2.8rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.RichCallOut__body,
.RichCallOut__body p {
  margin: 0;
  text-align: left;

  & p {
    font-size: var(--h7-mobile-font-size);

    @media (--tablet) {
      font-size: var(--h8-desktop-font-size);
    }

    @media (--laptop) {
      font-size: var(--h6-desktop-font-size);
    }
  }

  & p:last-of-type {
    margin-bottom: 0;
  }
}

.RichCallOut__body {
  overflow: hidden;
  max-width: 100%;

  &.RichtextArea {
    order: unset;
  }

  @media (--tablet) {
    overflow: initial;
  }
}

.RichCallOut__buttons {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-width: 200px;
  max-width: 100%;
  margin: 2rem 0 0;

  & a {
    background: var(--kog_red);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 1.94;
    letter-spacing: normal;
    color: var(--whiteColor);
    cursor: pointer;
    transition: box-shadow 0.2s ease-out;
    width: 100%;
    height: 50px;
    min-width: 160px;
    padding: 0 20px;
    font-size: 1.125rem;

    & + a {
      margin-bottom: 30px;
    }

    @media (--tablet) {
      height: 65px;
      font-size: 1.5rem;
      min-width: 260px;
    }

    &:hover {
      @mixin hoverDarken;
    }
  }

  & a:focus {
    outline-color: var(--outlineColorSecondary);
  }
}
