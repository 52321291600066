.SideBarForMagazine {
  position: relative;
  line-height: 32px;
  width: calc(100% - 90px);
  margin: 30px 60px 0 30px;
  @media (--tablet){
    margin: 0;
    width: auto;
  }
}

.SideBarForMagazine__text {
  margin-top: 34px;
  font-size: 20px;
  font-weight: 400;
  text-align: justify-all;
}

.SideBarForMagazine__link {
  margin-top: 23px;

  & .SideBarForMagazine__link--text {
    background: svg-load('Icons/ArrowRightSimple.svg',
    fill: var(--buttonExploreColor)) no-repeat;
    padding-left: 35px;
    padding-top: 4px;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--buttonExploreColor);
      animation: moveBackgroundImage-10px ease 2s infinite;
    }

    &.SideBarForMagazine__link--dark {
      color: var(--whiteColor);

      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--whiteColor);
      }
    }
  }
}

.StoryTextContent__inner {
  & .StoryTextContent__aside {
    & .StoryTextContent__SideBarForMagazine {
        @media (--laptop) {
          margin: 0;
        }
    }
  }
}
