@define-mixin latestStoriesColorVariant $buttonVariant {
  & .LatestStories__readMore {
    & .ButtonSecondary {
      @mixin $(buttonVariant);
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorForestTwo;
}

.ContainerWithBackground--GreenOne {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin latestStoriesColorVariant buttonSecondaryThemeColorGreyFive;
}
