.ImageWithCaptionComponent__caption {
    &,
    & p {
        @mixin typographyDesktopDefaultXSmall;

        margin-top: var(--spacing-xs);
    }
}

.ImageWithCaptionComponent__figure--dark {
    & .ImageWithCaptionComponent__caption{
        color: var(--base-grey-white);
    }
}