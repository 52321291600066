.Quote {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  min-height: 100vh;
  min-width: 100%;
  height: 250vh;
  @media (--laptop) {
    flex-flow: row nowrap;
  }
}

.StoriesHero__quote > .Quote {
  z-index: 100;
}

.Quote__backgroundWrapper {
  height: 100vh;
  pointer-events: none;
  position: sticky;
  top: 0;
  width: 100vw;
}

.Quote__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media (--desktop) {
    background-position: center;
    background-size: cover;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    background-attachment: fixed;
  }
}

.Quote__backgroundVideo {
  position: absolute;
  inset: 0;

  & video {
    width: 100%;
    height: 100%;
  }
}

.Quote__content {
  @mixin wrapper 90rem;

  margin: 0 auto;
  margin-top: 75vh;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.Quote__textWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100vh;

  &:not(:last-child) {
    margin-bottom: 15vh;
  }

  @media (--tablet) {
    padding-bottom: var(--spacing-xxxl);
  }

  @media (--mobileAndTablet) {
    justify-content: center;

    & blockquote {
      text-align: center;
    }
  }
}

.Quote__textBox {
  position: relative;
  z-index: var(--z-index-above);

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: var(--z-index-below);
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(80px);
    }
  }

  & blockquote {
    width: 100%;
    margin: 0 auto;
  }

  &.Quote__textBox--PartiallyTransparent {
    &::after {
      opacity: 0.8;
    }
  }

  &.Quote__textBox--Transparent {
    &::after {
      opacity: 0;
    }
  }
}
