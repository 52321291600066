.CampaignNavigation__container {
  background: var(--kog_blue_tint_1);
  margin-bottom: 4.5em;
}

.CampaignNavigation__links {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.CampaignNavigation__link {
  display: inline-block;

  @media (--laptop) {
    margin-right: 70px;

    &:last-child {
      margin-right: 0;
    }
  }

  & a {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--whiteColor);
    padding: 1em 15px;
    display: inline-block;

    &.active {
      text-decoration: underline;
    }
  }

  &:hover {
    background: var(--brandColor_dark);
  }
}
