.ProductsListingProducts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxl);
}

.ProductsListingProducts__gridWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.ProductsListingProducts__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));

  @media (--tablet) {
    grid-template-columns: repeat(3, 1fr);
    gap: calc(var(--spacing-l) + var(--spacing-xxxs)) calc(var(--spacing-xs) + var(--spacing-xxxs));
  }
}

.ProductsListingProducts__paginationWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(1.75 * var(--spacing-xxs));
  align-items: center;
  justify-content: center;

  & > button {
    width: 100%;
    text-align: center;
  }

  @media (--mobileSmall) {
    & > button {
      width: fit-content;
    }
  }

  @media (--tablet) {
    gap: calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs)));
  }
}

.ProductsListingProducts__paginationText {
  @mixin typographyDesktopDefaultSmall;
}
