.StoriesBreadcrumbs {
  padding: 5px 0;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  align-items: center;

  @media (--laptop) {
    display: flex;
    padding: 14px 0;
  }
}

.StoriesBreadcrumbs__inner {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  pointer-events: all;
}

.StoriesBreadcrumbs li {
  text-decoration: none;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    right: -12px;
    top: 50%;
    background: svg-load("Icons/CrumbArrow.svg", fill: var(--navColor)) no-repeat center;
    background-size: 3px;
    transform: translateY(-50%);

    @media (--laptop) {
      background-size: 5px;
    }
  }
}

.StoriesBreadcrumbs--reversed .StoriesBreadcrumbs li::after {
  transform: scaleX(-1) translateY(-50%);
}

.StoriesBreadcrumbs a {
  text-decoration: none;
  font-size: var(--navFont);

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 15px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.StoriesBreadcrumbs li:first-of-type::before {
  content: "";
  width: 5px;
  height: 100%;
  background: svg-load("Icons/CrumbArrow.svg", fill: var(--textColor)) no-repeat center;
  background-size: 3px;
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%) rotate(-180deg);

  @media (--laptop) {
    display: none;
    background-size: 5px;
  }
}

.StoriesBreadcrumbs li:last-of-type::after {
  display: none;
}
