.StoryProduct {
  display: flex;
  position: relative;
  flex-direction: column;

  @media (--tablet) {
    flex-direction: row;

    & .StoryProduct__content {
      width: 33%;
    }

    & .StoryProduct__image {
      width: 67%;
    }
  }

  @media (--laptop) {
    & .StoryProduct__content {
      width: 38%;
    }

    & .StoryProduct__image {
      width: 62%;
    }
  }

  @media (--laptopMedium) {
    & .StoryProduct__content {
      width: 40%;
    }

    & .StoryProduct__image {
      width: 60%;
    }
  }

  @media (--desktop) {
    & .StoryProduct__content {
      width: 42%;
    }

    & .StoryProduct__image {
      width: 58%;
    }
  }

  @media (--desktopMedium) {
    & .StoryProduct__content {
      width: 44%;
    }

    & .StoryProduct__image {
      width: 56%;
    }
  }
}

.StoryProduct__content {
  padding-block: var(--spacing-s);
  position: relative;
  width: 100%;

  @media (--tablet) {
    padding-block: var(--spacing-m);
  }
}

.StoryProduct__contentInner {
  margin: 0 30px;

  @media (--tablet) {
    max-width: 270px;
    margin-left: 30px;
    margin-right: 45px;
  }

  @media (--laptop) {
    margin-right: 60px;
    margin-left: auto;
  }
}

.StoryProduct__heading {
  @mixin h6Desktop;

  margin-block: 0 var(--spacing-xs);

  @media (--tablet) {
    @mixin h4Desktop;

    margin-block: 0 var(--spacing-s);
  }
}

.StoryProduct__body {
  &,
  & p {
    @mixin p;
  }

  margin-block: 0 var(--spacing-s);

  @mixin headings {
    @mixin h8Desktop;

    margin-block: 0 var(--spacing-xs);

    @media (--tablet) {
      @mixin h6Desktop;

      margin-block: 0 var(--spacing-xs);
    }
  }
}

.StoryProduct__image {
  width: 100%;
  position: relative;

  @media (--tablet) {
    padding-bottom: 40px;
  }

  & img,
  & picture {
    height: 100%;
    width: 100%;
  }
}

.StoryProduct__button {
  @mixin buttonSecondary;
  @mixin pExtraSmall;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  margin-block: 0;
}
