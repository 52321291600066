.Filter__select {
    width: 100%;

    & > button {
        width: 100%;
        justify-content: space-between;
        min-height: 40px;
    }

    & > .ButtonDropdown svg {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }

    & > .ButtonDropdown--opened svg {
        transform: rotate(180deg) translateY(50%);
    }
}