.Loader {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 6rem auto;
}

.Loader > div:nth-child(0) {
  animation-delay: -0.8s;
}

.Loader > div:nth-child(1) {
  animation-delay: -0.6s;
}

.Loader > div:nth-child(2) {
  animation-delay: -0.4s;
}

.Loader > div:nth-child(3) {
  animation-delay: -0.2s;
}

.Loader > div {
  overflow: visible;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  animation: loader-anim 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}

.Loader__blue > div {
  border: 2px solid var(--brandColor);
}

.Loader__navy > div {
  border: 2px solid var(--brandColor_dark_tint_1);
}

@keyframes loader-anim {
  0% {
    transform: scale(0.1);
    opacity: 0.9;
  }
  70% {
    transform: scale(0.9);
    opacity: 0.6;
  }
  100% {
    opacity: 0.0;
  }
}