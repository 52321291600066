@define-mixin latestNewsColorVariant $featuredCardBackgroundColor: transparent,
  $featuredCardFontColor: var(--blackColor), $buttonBorderColor: var(--blackColor),
  $buttonBorderActiveColor: var(--blackColor), $buttonFontColor: $buttonBorderColor,
  $buttonFontActiveColor: $buttonFontColor {
  & .ContentCard--featured {
    & .ContentCard__text {
      background-color: $featuredCardBackgroundColor;
      color: $featuredCardFontColor;

      & .ContentCard__title {
        color: $featuredCardFontColor;
      }
    }

    & .ContentCard__arrow {
      @mixin linkWithArrowSmall $featuredCardFontColor;
    }
  }

  & .LatestNews__readMore {
    & .ButtonSecondary {
      @mixin buttonSecondaryColorVariant $buttonBorderColor, $buttonBorderActiveColor,
        $buttonFontColor, $buttonFontActiveColor;
    }
  }
}

.LatestNews--backgroundColorNone {
  @mixin latestNewsColorVariant;
}

.LatestNews--backgroundColorDefault {
  @mixin latestNewsColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color), var(--themeSecondaryColor_tint_6),
    var(--themeSecondaryColor_tint_7), var(--themeSecondaryColor_tint_6),
    var(--themeSecondaryColor_tint_7);
}

.LatestNews--backgroundColorBlueFive {
  @mixin latestNewsColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color),
    var(--kog_blue_tint_8), var(--kog_blue_tint_9), var(--kog_blue_tint_8), var(--kog_blue_tint_9);
}

.LatestNews--backgroundColorSandFour {
  @mixin latestNewsColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color), var(--kog_bronze), var(--kog_bronze_tint_6),
    var(--kog_bronze), var(--kog_bronze_tint_6);
}

.LatestNews--backgroundColorTealFive {
  @mixin latestNewsColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color), var(--kog_aqua_tint_3), var(--kog_aqua_tint_4),
    var(--kog_aqua_tint_3), var(--kog_aqua_tint_4);
}

.LatestNews--backgroundColorGreenFour {
  @mixin latestNewsColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color), var(--kog_green), var(--kog_green_tint_3), var(--kog_green),
    var(--kog_green_tint_3);
}

.LatestNews--backgroundColorYellowFour {
  @mixin latestNewsColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color), var(--kog_red), var(--kog_red_tint_3), var(--kog_red),
    var(--kog_red_tint_3);
}

.LatestNews--backgroundColorGreenTwo {
  @mixin latestNewsColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color), var(--kog_green_tint_3), var(--kog_green_tint_4),
    var(--kog_green_tint_3), var(--kog_green_tint_4);
}

.LatestNews--backgroundColorBlueTwo {
  @mixin latestNewsColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color), var(--kog_blue_tint_1), var(--kog_blue),
    var(--kog_blue_tint_1), var(--kog_blue);
}

.LatestNews--backgroundColorTealTwo {
  @mixin latestNewsColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color), var(--kog_aqua_tint_6), var(--kog_aqua_tint_7),
    var(--kog_aqua_tint_6), var(--kog_aqua_tint_7);
}

.LatestNews--backgroundColorSandTwo {
  @mixin latestNewsColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color), var(--kog_bronze_tint_6), var(--kog_bronze_tint_7),
    var(--kog_bronze_tint_6), var(--kog_bronze_tint_7);
}

.LatestNews--backgroundColorForestTwo {
  @mixin latestNewsColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color), var(--kog_forest_tint_3), var(--kog_forest_tint_4),
    var(--kog_forest_tint_3), var(--kog_forest_tint_4);
}

.LatestNews--backgroundColorGreenOne {
  @mixin latestNewsColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color), var(--kog_green_tint_4), var(--kog_green_tint_3),
    var(--kog_green_tint_4), var(--kog_green_tint_3);
}

.LatestNews--backgroundColorGreenFive {
  @mixin latestNewsColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color), var(--kog_green_tint_4), var(--kog_green_tint_3),
    var(--kog_green_tint_4), var(--kog_green_tint_3);
}

.LatestNews--backgroundColorForestFive {
  @mixin latestNewsColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color), var(--kog_forest_tint_4), var(--kog_forest_tint_3),
    var(--kog_forest_tint_4), var(--kog_forest_tint_3);
}

.LatestNews--backgroundColorBlueThree {
  @mixin latestNewsColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color), var(--kog_blue_tint_9), var(--kog_blue_tint_1),
    var(--kog_blue_tint_9), var(--kog_blue_tint_1);
}

.LatestNews--backgroundColorYellowTwo {
  @mixin latestNewsColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color), var(--kog_red), var(--kog_red_tint_3), var(--kog_red),
    var(--kog_red_tint_3);
}

.LatestNews--backgroundColorYellowFive {
  @mixin latestNewsColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color), var(--kog_bronze_tint_6), var(--kog_bronze_tint_7),
    var(--kog_bronze_tint_6), var(--kog_bronze_tint_7);
}

.LatestNews--backgroundColorRedFour {
  @mixin latestNewsColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color), var(--kog_red_tint_3), var(--kog_red_tint_4),
    var(--kog_red_tint_3), var(--kog_red_tint_4);
}

.LatestNews--backgroundColorGreyTwo {
  @mixin latestNewsColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color), var(--grayColor_tint_3), var(--blackLighterColor),
    var(--grayColor_tint_3), var(--blackLighterColor);
}

.LatestNews--backgroundColorGreyFive {
  @mixin latestNewsColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color), var(--grayColor_tint_6), var(--grayColor_tint_3),
    var(--grayColor_tint_6), var(--grayColor_tint_3);
}
