@define-mixin arrowIcon $color: var(--whiteColor), $size: 7px {
  display: block;
  width: $size;
  height: calc((2 * $size) + 1px);
  background: svg-load('Icons/MicrositeChevron.svg', stroke: $color) no-repeat;
  background-size: 8px;

  @media (--desktop4k) {
    width: calc($size + 5px);
    height: calc((2 * $size) + 10px);
    background-size: 12px;
  }
}

@define-mixin galleryIcon $color: var(--whiteColor) {
  display: block;
  width: 18px;
  height: 18px;
  background: svg-load('Icons/MicrositeGallery.svg', stroke: $color) no-repeat;
  background-size: 18px;

  @media (--desktop4k) {
    width: 35px;
    height: 35px;
    background-size: 35px;
  }
}

@define-mixin videoIcon $color: var(--whiteColor) {
  display: block;
  width: 20px;
  height: 12px;
  background: svg-load('Icons/MicrositeVideo.svg', stroke: $color) no-repeat;
  background-size: 20px;

  @media (--desktop4k) {
    width: 45px;
    height: 30px;
    background-size: 46px;
  }
}

@define-mixin fullScreenIcon $color: var(--whiteColor) {
  display: block;
  width: 28px;
  height: 18px;
  background: svg-load('Icons/MicrositeFullScreen.svg', stroke: var(--whiteColor)) no-repeat;

  @media (--desktop4k) {
    width: 48px;
    height: 30px;
    background-size: 46px;
  }
}
