.GalleryModal {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-image-viewer-overlay);
  background: color(var(--base-grey-black-100) a(90%));
}

.GalleryModal__modal {
  display: grid;
  grid-template-rows: minmax(60%, 620px) minmax(100px, max-content);
  grid-template-columns: 100%;
  margin: 86px 12px;
  height: calc(100% - (2 * 86px));
  gap: var(--spacing-s);

  &.GalleryModal__modal--isSingleImage {
    grid-template-rows: minmax(60%, 620px);
  }

  &:focus-visible {
    outline: none;
  }

  @media (--laptop) {
    @mixin wrapper;

    margin-block: 50px;
    height: calc(100% - (2 * 50px));
  }

  /* stylelint-disable-next-line csstools/media-use-custom-media */
  @media (min-height: 870px) {
    padding: 100px 0;
  }
}

.GalleryModal__closeButton {
  @mixin resetButtonStylesNoOverride;

  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--base-grey-white);
  font-size: 2rem;
  cursor: pointer;
  @mixin focusVisible var(--outlineColorSecondary);
}

.GalleryModal__arrows {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.GalleryModal__arrow {
  @mixin resetButtonStylesNoOverride;

  display: block;
  color: var(--base-grey-white);
  font-size: 1.5rem;
  cursor: pointer;
  pointer-events: all;
  @mixin focusVisible var(--outlineColorSecondary);

  &[disabled] {
    color: var(--base-grey-dark-80);
  }

  &.splide__arrow--prev {
    transform: rotate(90deg);
  }

  &.splide__arrow--next {
    transform: rotate(-90deg);
  }
}

.GalleryModal__figure {
  margin: 0;
}

.GalleryModal__slider {
  max-height: 100%;

  & .splide__track,
  & .splide__list,
  & .splide__slide,
  & .GalleryModal__figure {
    height: 100%;
    max-height: 100%;
  }

  & .splide__track {
    /* stylelint-disable-next-line declaration-no-important */
    padding-inline: calc(var(--spacing-l) + var(--spacing-xxxs)) !important;

    @media (--laptop) {
      /* stylelint-disable-next-line declaration-no-important */
      padding-inline: 0 !important;
    }
  }

  & .GalleryModal__arrows {
    @media (--laptop) {
      display: none;
    }
  }

  & .GalleryModal__figure {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    align-items: center;
    justify-content: center;
  }

  & picture {
    display: block;
    overflow: hidden;
  }

  & img {
    height: 100%;
    object-fit: contain;
    background: white;
  }
}

.GalleryModal__caption {
  width: 100%;
  min-height: 1.0625rem; /* 17px */
  flex: 0 0 auto;
  text-wrap: balance;

  &,
  & p {
    @mixin typographyMobileDefaultSmall;

    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (--laptop) {
      @mixin typographyDesktopDefaultSmall;
    }
  }

  & p {
    &:last-child {
      margin-block: 0;
    }
  }
}

.GalleryModal__thumbnailsSlider {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  align-self: flex-end;

  & .splide__track {
    @media (--laptop) {
      margin-inline: 2.75rem;
    }
  }

  & .splide__slide {
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: 4px solid transparent;

    /* stylelint-disable-next-line selector-class-pattern */
    &.is-active {
      border-color: var(--base-grey-white);
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      border-color: var(--outlineColorSecondary);
    }

    & img {
      min-height: 35px;
      width: 100%;
    }
  }

  & .GalleryModal__arrows {
    display: none;

    @media (--laptop) {
      display: flex;
    }
  }
}

.GalleryModal__slidesCount {
  @mixin typographyDesktopDefaultMedium;

  color: var(--base-grey-white);
  text-align: center;
}

.GalleryModal__sliderContainer {
  position: relative;
}
