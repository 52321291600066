.ProductCatalogItemCard {
  width: 100%;
  min-height: 50px;
  height: 100%;
  border: 1px solid color(var(--blackColor) a(2%));
  transition: all .1s ease-in-out;
  
  &.ProductCatalogItemCard--productItem {
      background: var(--productCard);
      &:hover {
        background: var(--productCardHover);
      }
  }

  &.ProductCatalogItemCard--productCategoryItem {
      background: var(--productCategoryCard);
      &:hover {
        background: var(--productCategoryCardHover);
      }
  }

  &:hover {
    box-shadow: 0 1px 8px 0 color(var(--blackColor) a(10%));
    text-decoration: underline;

    & .ProductCatalogItemCard__arrow {
      animation-name: move;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }
}

.ProductCatalogItemCard__link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 25px;

  &:focus {
    outline-color: var(--outlineColorPrimary);
  }
}

.ProductCatalogItemCard__title {
  margin: 0;
  font-size: 18px;
  word-break: break-word;
}

.ProductCatalogItemCard__arrow {
  display: inline-block;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background: transparent svg-load('Icons/ArrowRightSimple.svg') center no-repeat;
  background-size: 100% 100%;
}

.cardsList__3col {
  & .ProductCatalogItemCard__title {
    padding: 1rem;
  }
}