.ArticleTeaser {
  position: relative;
  max-width: var(--wrapperWidth);
  background: svg-load("Common/Background.svg", fill: var(--secondaryColor_tint_3)) no-repeat;
  background-position: bottom 0 right 0;
  clear: both;
  width: calc(100% - 30px);
  margin: 0 auto;
}

.ArticleTeaser__inner {
  padding: 2rem 1rem;
  display: flex;
  flex-flow: row wrap;

  @media (--tablet) {
    padding: 1rem 4rem;
  }

  @media (--desktop) {
    padding: 1rem 5.5rem;
  }
}

.ArticleTeaser__text {
  width: 100%;
  order: 2;

  @media (--tablet) {
    width: 70%;
    order: 1;
  }
}

.ArticleTeaser__title {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 20px;
  font-weight: 400;
  line-height: 1.14;
  text-transform: uppercase;
  margin: 1rem 0;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 42px;
    margin: 2.4rem 0;
  }
}

.ArticleTeaser__link {
  margin: 0 0 1rem;
  height: 50px;

  @media (--tablet) {
    margin: 3rem 0;
    height: 65px;
  }
}

.ArticleTeaser__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  order: 1;
  margin-bottom: 2rem;

  @media (--tablet) {
    width: 30%;
    order: 2;
    margin-bottom: 4rem;
    justify-content: flex-end;
  }

  @media (--desktop) {
    margin-bottom: initial;
    justify-content: center;
  }

  & picture {
    display: block;
    width: 150px;
    height: 150px;

    @media (--tablet) {
      width: 160px;
      height: 160px;
    }
  }

  & img {
    display: block;
    width: 100%;
    min-height: 100%;
  }
}
