.CallOut--light {
  
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: color(var(--overlay-ice-blue) a(50%));
    position: absolute;
    top: 0;
    left: 0;
  }
  
  &.CallOut--fallbackBackgroundImage {
    background-image: url('/wwwroot/Static/Images/Common/world-map.png');
    background-color: var(--whiteColor);

    &::before {
      display: none;
    }
  }
}

.CallOut--dark {
  color: var(--whiteColor);

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: color(var(--kog_blue) a(50%));
    position: absolute;
    top: 0;
    left: 0;
  }
  
  & p,
  & a,
  & span {
    color: var(--whiteColor);
  }
  
  &.CallOut--fallbackBackgroundImage {
    background-image: url('/wwwroot/Static/Images/Common/world-map-blue-dark.png');
    background-color: var(--brandColor_dark_tint_1);

    &::before {
      display: none;
    }
  }
}
  

.CallOut--fallbackBackgroundImage {
  background-size: cover;
  background-position: top center;
  
  @media(--laptop) {
    background-size: contain;
  }
}