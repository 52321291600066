/* stylelint-disable selector-class-pattern */
/* stylelint-disable declaration-property-unit-allowed-list */
@define-mixin inputStyles {
  box-sizing: border-box;
  border: 1px solid var(--secondaryColor);
  background-color: var(--whiteColor);
  padding: 0 15px;
  margin: 0;
  min-height: 40px;
  color: var(--textColor);
  font-weight: 400;
  font-family: var(--fontFamilyPrimary);

  @media (--tablet) {
    min-height: 50px;
  }
}

@define-mixin inputStylesFocus {
  box-shadow: 0 0 8px var(--secondaryColor_tint_2);
  border: 1px solid var(--secondaryColor);
  background: var(--whiteColor);
  color: var(--textColor);
}

input[type="text"] {
  @mixin inputStyles;

  &:focus {
    @mixin inputStylesFocus;
  }
}

.EPiServerForms {
  margin: 0 auto;
  padding: 2rem 0;

  @media (--tablet) {
    padding: 4.5rem 0;
  }

  & li {
    padding: 0;
    display: block;
    list-style: none;
    margin: 10px 0 0;
  }

  & label,
  & legend,
  & .FormChoice--Image .Form__Element__Caption {
    margin: 0 0 5px;
    padding: 0;
    display: block;
    font-size: 16px;
    /* stylelint-disable-next-line custom-property-pattern */
    color: var(--blackLighterColor);
    font-weight: 400;

    @media (--tablet) {
      font-size: 18px;
    }
  }

  & input[type="text"],
  & input[type="date"],
  & input[type="datetime"],
  & input[type="number"],
  & input[type="search"],
  & input[type="time"],
  & input[type="url"],
  & input[type="email"],
  & textarea,
  & select {
    @mixin inputStyles;

    color: var(--blackLighterColor);
    border: 1px solid var(--blackLighterColor);
    font-size: 16px;

    @media (--tablet) {
      min-height: 48px;
      max-width: 390px;
    }
  }

  & fieldset {
    border: none;
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  & textarea {
    padding: 15px;
    height: 100%;
    min-height: 96px;
  }

  & input[type="text"]:focus,
  & input[type="date"]:focus,
  & input[type="datetime"]:focus,
  & input[type="number"]:focus,
  & input[type="search"]:focus,
  & input[type="time"]:focus,
  & input[type="url"]:focus,
  & input[type="email"]:focus,
  & textarea:focus,
  & select:focus {
    @mixin inputStylesFocus;
  }

  & button,
  & input[type="submit"],
  & input[type="reset"] {
    font: inherit;
    overflow: visible;
    user-select: none;
    width: 100%;
    height: 48px;
    font-size: 18px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    line-height: 1.94;
    letter-spacing: normal;
    color: var(--secondaryColor);
    cursor: pointer;
    transition: box-shadow 0.2s ease-out;
    margin-bottom: 1rem;
    text-transform: uppercase;
    background: var(--secondaryColor_tint_2);
    border: 1px solid var(--secondaryColor);
    padding: 0 15px;
    outline: none;
    min-height: 40px;
    box-shadow: 0 0 8px var(--secondaryColor_tint_2);
    vertical-align: top;
    margin-right: 15px;

    &:hover {
      @mixin hoverDarken;
    }

    @media (--tablet) {
      width: 170px;
    }
  }

  & .Form__MainBody {
    & .Form__Element__Caption {
      display: flex;
    }

    & .FormSubmitButton {
      color: var(--whiteColor);
      transition: box-shadow 0.2s ease-out;
      background: var(--brandColor);
      text-transform: initial;
      border: none;
      font-size: 22px;

      @media (--tablet) {
        min-width: 170px;
        width: auto;
      }
    }
  }

  & .Form__Status .hide {
    display: none;
  }

  & .Form__Description {
    margin-bottom: 1.5rem;

    @media (--tablet) {
      margin-bottom: 2.5rem;
    }
  }

  & .FormTextbox,
  & .FormCaptcha,
  & .FormSelection,
  & .FormChoice {
    & + button,
    & + input[type="submit"],
    & + input[type="reset"] {
      @media (--tablet) {
        margin-left: 20%;
      }
    }
  }

  & .Form__Element .FormChoice {
    @media (--tablet) {
      width: 80%;
      margin-left: 20%;
    }
  }
}

.Form__Title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.14;
  margin: 0 0 0.5rem;

  @media (--tablet) {
    font-size: 42px;
    margin: 0 0 1rem;
  }
}

.Form__Element {
  margin-bottom: 1rem;
}

/* stylelint-disable-next-line no-descending-specificity */
.FormTextbox,
.FormSelection,
.FormRange,
.FormFileUpload,
.FormDateTime,
.FormDateTimeRange {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  @media (--tablet) {
    flex-flow: row wrap;
  }

  & label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 15px;

    @media (--tablet) {
      width: 20%;
      justify-content: flex-end;
      text-align: right;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & input,
  & select,
  & textarea,
  & fieldset,
  & > span {
    width: 100%;

    @media (--tablet) {
      width: 80%;
    }
  }
}

.FormDateTimeRange {
  flex-flow: column wrap;

  @media (--tablet) {
    flex-direction: row;
  }

  @media (--mobileOnly) {
    & label {
      flex: 1;
    }
  }

  & .FormDateTimeRange_Seperator {
    width: auto;
    /* stylelint-disable-next-line declaration-no-important */
    margin: 0 0 0 54px !important;

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-no-important */
      margin: 10px 10px 0 !important;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.FormCaptcha {
  display: flex;
  flex-wrap: wrap;

  /* stylelint-disable-next-line no-descending-specificity */
  & .Form__Element__Caption {
    width: 100%;
    text-align: right;
    padding-right: 15px;
    align-items: center;

    @media (--tablet) {
      justify-content: flex-end;
      width: 20%;
    }
  }

  & .FormCaptcha__Refresh {
    margin-bottom: 0.5rem;
  }

  & input[type="text"].FormCaptcha__Input {
    order: 1;
    height: 50px;
  }

  & img {
    width: auto;
    order: 1;
    margin-right: 15px;
    margin-bottom: 15px;

    @media (--tablet) {
      margin-left: 20%;
    }
  }

  &::before {
    content: "";
    width: 100%;
    display: block;
    order: 1;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.FormChoice {
  & img {
    width: 100px;
    margin: 0.5rem 0;
  }
}

.Form__NavigationBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.FormTextbox.ValidationFail,
.FormSelection.ValidationFail {
  & input[type="text"],
  & input[type="date"],
  & input[type="datetime"],
  & input[type="number"],
  & input[type="search"],
  & input[type="time"],
  & input[type="url"],
  & input[type="email"],
  & select {
    border: 1px solid var(--redColor);
    background: var(--kog_red_tint_2);
    color: red;
  }

  & label,
  & fieldset {
    color: var(--kog_red);
  }
}

.Form__Element__ValidationError {
  color: red;
  display: block;
  justify-self: flex-end;
  width: 80%;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
}

.CustomCheckbox > input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: 1;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 35px;
  }

  & + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    display: inline-block;
    /* stylelint-disable-next-line plugin/declaration-block-no-ignored-properties */
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid var(--kog_blue_tint_1);
  }

  &:focus + label::before {
    box-shadow: 0 0 8px var(--kog_blue_tint_3);
    border: 1px solid var(--kog_blue_tint_1);
  }

  &:checked + label::before {
    background: #ffff svg-load("Icons/Check.svg", fill: var(--kog_blue_tint_1)) no-repeat;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label::before {
    box-shadow: none;
    background: #ddd;
  }
}
