body > .ContactCard--editMode { 
  /* selector to detect edit mode only for this specific block */
  flex-flow: column;
  max-width: 700px;
  margin: 0 auto;

  & .ContactCard__inner {
    background: var(--brandColor);
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;

    &::before,
    &::after {
      display: none;
    }
  }

  & .ContactCard__picture {
    position: static;
    transform: none;
  }

  & .ContactCard__info {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 40px;
    width: 100%;
  }
}

.cardsList__3col {
  & .epi-editContainer {
    height: 100%;

    & .ContactCard {
      height: 100%;
      max-height: 800px;
    }
  }
}