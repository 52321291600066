.CheckboxSelect {
  position: relative;
}

.CheckboxSelect__valuesCount {
  @mixin typographyMobileCapsLarge;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: var(--base-grey-dark-100);
  color: var(--base-grey-white);
  padding: 2px 6px;
  transition: inherit;
  min-width: 22px;
  min-height: 22px;
  line-height: 1;

  @media (--laptop) {
    @mixin typographyDesktopStrongSmall;

    line-height: 1;
  }
}

.CheckboxSelect__dropdown {
  z-index: var(--z-index-checkbox-select-dropdown);
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    height: auto;
    position: absolute;
    left: 0;
    background-color: var(--base-grey-white);
    margin-top: 3px;
    border-radius: 2px;
    box-shadow: 0 1px 6px 0 rgb(84 96 118 / 0.24);
    min-width: 305px;
    padding-block: var(--spacing-xxxs);
    padding-inline: var(--spacing-xxxs);
    max-height: 70vh;

    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    @supports (max-height: 70dvh) {
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      max-height: 70dvh;
    }
  }
}

.CheckboxSelect__list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0 0 var(--spacing-xxxs);
  overflow-y: auto;
  max-height: 340px;
}

.CheckboxSelect__item {
  display: block;
  position: relative;
}

.CheckboxSelect__itemLabel {
  @mixin typographyDesktopDefaultLarge;

  padding-block: 0.4375rem; /* 7px */
  border-bottom: 1px solid var(--base-grey-light-100);
  transition: transform 250ms;
  height: 2.5rem; /* 40px */
  overflow: hidden;
  border-radius: 3px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xxs);
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (--tablet) {
    @mixin typographyDesktopDefaultMedium;

    padding-inline: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    padding-block: var(--spacing-xxs);
    border-bottom: 0;
    cursor: pointer;
  }

  &:hover {
    background-color: var(--base-grey-light-100);
  }
}

.CheckboxSelect__itemCheckmark {
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  opacity: 0;
}

.CheckboxSelect__input {
  &:checked + .CheckboxSelect__itemLabel {
    & .CheckboxSelect__itemCheckmark {
      opacity: 1;
    }
  }

  &:focus + .CheckboxSelect__itemLabel {
    outline: none;
  }

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:focus-visible + .CheckboxSelect__itemLabel {
    outline: var(--outlineColorSecondary) solid 2px;
    outline-offset: -2px;
  }
}

.CheckboxSelect__controlsBar {
  display: flex;
  margin: var(--spacing-xxs) 0 0;
  gap: calc(var(--spacing-xxxs) + (var(--spacing-xxxs) / 2));
}

.CheckboxSelect__clearButton,
.CheckboxSelect__saveButton {
  flex: 1;
  text-align: center;
}
