@define-mixin storyProductColorVariant $backgroundColor, $fontColor, $secondaryBtnVariant {
  & .StoryProduct {
    background: $backgroundColor;

    & .StoryProduct__content {
      color: $fontColor;
    }

    & .StoryProduct__button {
      @mixin $(secondaryBtnVariant);
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin storyProductColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color), buttonSecondaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin storyProductColorVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color), buttonSecondaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin storyProductColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color), buttonSecondaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin storyProductColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color), buttonSecondaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin storyProductColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color), buttonSecondaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin storyProductColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color), buttonSecondaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin storyProductColorVariant var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color), buttonSecondaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin storyProductColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color), buttonSecondaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin storyProductColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color), buttonSecondaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin storyProductColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color), buttonSecondaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin storyProductColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color), buttonSecondaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin storyProductColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color), buttonSecondaryThemeColorForestTwo;
}

.ContainerWithBackground--GreenOne {
  @mixin storyProductColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color), buttonSecondaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin storyProductColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color), buttonSecondaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin storyProductColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color), buttonSecondaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin storyProductColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color), buttonSecondaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin storyProductColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color), buttonSecondaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin storyProductColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color), buttonSecondaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin storyProductColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color), buttonSecondaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin storyProductColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color), buttonSecondaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin storyProductColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color), buttonSecondaryThemeColorGreyFive;
}
