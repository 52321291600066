.StoriesHero--editMode,
.StoriesHero--previewMode {
  position: relative;
  min-height: 680px;
  justify-content: center;
  margin-bottom: 16rem;
  height: 800px;

  & .StoriesHero__media {
    position: relative;
    height: 680px;
    width: 100%;
    max-height: 1000px;
    min-height: 680px;
    margin: 0;
    overflow: hidden;

    &::before {
      display: none;
    }
  }
  
  & .StoriesHero__mediaInner {
    height: 86%;
  }
  
  & .StoriesHero__titles {
    margin-top: 0;
  }
  
  & .StoriesHero__intro {
    margin-top: 0;
    
    @media (--tablet) {
      margin-top: 250px;
    }
  }

  & .StoriesHero__text {
    position: absolute;
    top: 240px;
    animation: none;
    margin-top: 0;
    min-height: auto;
  }

  & .StoriesHero__logo {
    max-width: 200px;
    animation: none;
  }

  & .StoriesHero__picture {
    height: 100%;

    & img {
      height: 680px;
    }
  }

  & .StoriesHero__videoContainer {
    margin-right: -160px;
    width: 160px;
    height: 100px;

    @media (--tablet) {
      margin-right: -280px;
      width: 280px;
      height: 160px;
    }
  }

  & .StoriesHero__video {
    position: relative;
    height: 100%;
    width: 100%;
    max-height: 500px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--grayColor) svg-load('Icons/Video.svg',
    fill: var(--whiteColor)) no-repeat center;
    background-size: 70px 70px;
    z-index: 1;

    @media (--tablet) {
      background-size: 110px 110px;
    }
  }

  & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.SubsiteStoriesLandingPage__hero {
  & .StoriesHero.StoriesHero--editMode {
    min-height: 680px;
  }
}