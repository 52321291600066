@define-mixin downloadsSectionColorVariant $backgroundColor, $fontColor, $iconBgColor,
  $iconContentColor {
  & .Downloads {
    background-color: $backgroundColor;
    color: $fontColor;
  }

  & .RichtextArea a.Downloads__itemLink,
  & .Downloads__itemLink {
    color: $fontColor;
  }

  & .Downloads__itemName {
    &::before {
      background-color: $iconBgColor;
      background-image: svg-load("Icons/Paperclip.svg", fill: $iconContentColor);
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin downloadsSectionColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color), var(--theme-color-beige-button-primary-bg-color),
    var(--theme-color-beige-button-primary-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin downloadsSectionColorVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color), var(--theme-color-blue-button-primary-bg-color),
    var(--theme-color-blue-button-primary-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin downloadsSectionColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color), var(--theme-color-blue-dark-button-primary-bg-color),
    var(--theme-color-blue-dark-button-primary-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin downloadsSectionColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color), var(--theme-color-brown-button-primary-bg-color),
    var(--theme-color-brown-button-primary-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin downloadsSectionColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color), var(--theme-color-green-button-primary-bg-color),
    var(--theme-color-green-button-primary-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin downloadsSectionColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color), var(--theme-color-yellow-button-primary-bg-color),
    var(--theme-color-yellow-button-primary-text-color);
}

.ContainerWithBackground--Pink {
  @mixin downloadsSectionColorVariant var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color), var(--theme-color-pink-button-primary-bg-color),
    var(--theme-color-pink-button-primary-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin downloadsSectionColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color), var(--theme-color-turquoise-button-primary-bg-color),
    var(--theme-color-turquoise-button-primary-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin downloadsSectionColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color),
    var(--theme-color-turquoise-dark-button-primary-bg-color),
    var(--theme-color-turquoise-dark-button-primary-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin downloadsSectionColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color), var(--theme-color-green-dark-button-primary-bg-color),
    var(--theme-color-green-dark-button-primary-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin downloadsSectionColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color), var(--theme-color-sand-dark-button-primary-bg-color),
    var(--theme-color-sand-dark-button-primary-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin downloadsSectionColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color),
    var(--theme-color-forest-dark-button-primary-bg-color),
    var(--theme-color-forest-dark-button-primary-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin downloadsSectionColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color), var(--theme-color-green-one-button-primary-bg-color),
    var(--theme-color-green-one-button-primary-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin downloadsSectionColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color), var(--theme-color-green-five-button-primary-bg-color),
    var(--theme-color-green-five-button-primary-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin downloadsSectionColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color),
    var(--theme-color-forest-five-button-primary-bg-color),
    var(--theme-color-forest-five-button-primary-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin downloadsSectionColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color), var(--theme-color-blue-three-button-primary-bg-color),
    var(--theme-color-blue-three-button-primary-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin downloadsSectionColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color), var(--theme-color-yellow-two-button-primary-bg-color),
    var(--theme-color-yellow-two-button-primary-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin downloadsSectionColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color),
    var(--theme-color-yellow-five-button-primary-bg-color),
    var(--theme-color-yellow-five-button-primary-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin downloadsSectionColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color), var(--theme-color-red-four-button-primary-bg-color),
    var(--theme-color-red-four-button-primary-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin downloadsSectionColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color), var(--theme-color-grey-two-button-primary-bg-color),
    var(--theme-color-grey-two-button-primary-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin downloadsSectionColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color), var(--theme-color-grey-five-button-primary-bg-color),
    var(--theme-color-grey-five-button-primary-text-color);
}
