.ContainerWithBackground--Beige .FormContainer {
  @mixin formContainerColorVariant var(--kog_red), var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color), buttonPrimaryThemeColorBeige,
    buttonSecondaryThemeColorBeige;
}

.ContainerWithBackground--GreenFour .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_3), var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color), buttonPrimaryThemeColorGreenFour,
    buttonSecondaryThemeColorGreenFour;
}

.ContainerWithBackground--BlueFive .FormContainer {
  @mixin formContainerColorVariant var(--kog_red), var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color), buttonPrimaryThemeColorBlueFive,
    buttonSecondaryThemeColorBlueFive;
}

.ContainerWithBackground--TealTwo .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_4), var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color), buttonPrimaryThemeColorTealTwo,
    buttonSecondaryThemeColorTealTwo;
}

.ContainerWithBackground--SandFour .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_3), var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color), buttonPrimaryThemeColorSandFour,
    buttonSecondaryThemeColorSandFour;
}

.ContainerWithBackground--TealFive .FormContainer {
  @mixin formContainerColorVariant var(--kog_red), var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color), buttonPrimaryThemeColorTealFive,
    buttonSecondaryThemeColorTealFive;
}

.ContainerWithBackground--YellowFour .FormContainer {
  @mixin formContainerColorVariant var(--kog_red), var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color), buttonPrimaryThemeColorYellowFour,
    buttonSecondaryThemeColorYellowFour;
}

.ContainerWithBackground--GreenTwo .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_4), var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color), buttonPrimaryThemeColorGreenTwo,
    buttonSecondaryThemeColorGreenTwo;
}

.ContainerWithBackground--BlueTwo .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_1), var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color), buttonPrimaryThemeColorBlueTwo,
    buttonSecondaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandTwo .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_2), var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color), buttonPrimaryThemeColorSandTwo,
    buttonSecondaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_4), var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color), buttonPrimaryThemeColorForestTwo,
    buttonSecondaryThemeColorForestTwo;
}

.ContainerWithBackground--Pink .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_3), var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color), buttonPrimaryThemeColorPink, buttonSecondaryThemeColorPink;
}

.ContainerWithBackground--GreenOne .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_1), var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color), buttonPrimaryThemeColorGreenOne,
    buttonSecondaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_1), var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color), buttonPrimaryThemeColorGreenFive,
    buttonSecondaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive .FormContainer {
  @mixin formContainerColorVariant var(--kog_red), var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color), buttonPrimaryThemeColorForestFive,
    buttonSecondaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_2), var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color), buttonPrimaryThemeColorBlueThree,
    buttonSecondaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_3), var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color), buttonPrimaryThemeColorYellowTwo,
    buttonSecondaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive .FormContainer {
  @mixin formContainerColorVariant var(--kog_red), var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color), buttonPrimaryThemeColorYellowFive,
    buttonSecondaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_3), var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color), buttonPrimaryThemeColorRedFour,
    buttonSecondaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_3), var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color), buttonPrimaryThemeColorGreyTwo,
    buttonSecondaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive .FormContainer {
  @mixin formContainerColorVariant var(--kog_red_tint_2), var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color), buttonPrimaryThemeColorGreyFive,
    buttonSecondaryThemeColorGreyFive;
}
