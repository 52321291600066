/* stylelint-disable selector-class-pattern */
@media (--tablet) {
  /* stylelint-disable-next-line selector-not-notation */
  :not(.Figure--float-left):not(.Figure--float-right) {
    &.Figure--caption-left,
    &.Figure--caption-right {
      &.Figure--captionBackgroundColorBlueDark .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-blue-dark-bg-color-lighter),
          var(--theme-color-blue-dark-text-color);
      }

      &.Figure--captionBackgroundColorGreen .Figure__caption {
        @mixin CommonBlockColors var(--accent-green-accent-light),
          var(--theme-color-green-text-color-darker);
      }

      &.Figure--captionBackgroundColorBlue .Figure__caption {
        @mixin CommonBlockColors var(--accent-blue-accent-lighter),
          var(--theme-color-blue-text-color-lighter);
      }

      &.Figure--captionBackgroundColorTurquoiseDark .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-turquoise-dark-bg-color),
          var(--theme-color-turquoise-dark-text-color);
      }

      &.Figure--captionBackgroundColorDefault .Figure__caption,
      &.Figure--captionBackgroundColorBrown .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-brown-bg-color-lighter),
          var(--theme-color-brown-text-color);
      }

      &.Figure--captionBackgroundColorTurquoise .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-turquoise-bg-color),
          var(--theme-color-turquoise-text-color);
      }

      &.Figure--captionBackgroundColorYellow .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-yellow-bg-color),
          var(--theme-color-yellow-text-color-lighter);
      }

      &.Figure--captionBackgroundColorGreenDark .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-green-dark-bg-color),
          var(--theme-color-green-dark-text-color-darker);
      }

      &.Figure--captionBackgroundColorSandDark .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-sand-dark-bg-color),
          var(--theme-color-sand-dark-text-color);
      }

      &.Figure--captionBackgroundColorForestDark .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-forest-dark-bg-color),
          var(--theme-color-forest-dark-text-color);
      }

      &.Figure--captionBackgroundColorWhite .Figure__caption {
        @mixin CommonBlockColors var(--whiteColor), var(--base-grey-black-100);
      }

      &.Figure--captionBackgroundColorBlack .Figure__caption {
        @mixin CommonBlockColors var(--blackColor), var(--whiteColor);
      }

      &.Figure--captionBackgroundColorGreenOne .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-green-one-bg-color),
          var(--theme-color-green-one-text-color);
      }

      &.Figure--captionBackgroundColorGreenFive .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-green-five-bg-color),
          var(--theme-color-green-five-text-color);
      }

      &.Figure--captionBackgroundColorForestFive .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-forest-five-bg-color),
          var(--theme-color-forest-five-text-color);
      }

      &.Figure--captionBackgroundColorBlueThree .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-blue-three-bg-color),
          var(--theme-color-blue-three-text-color);
      }

      &.Figure--captionBackgroundColorYellowTwo .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-yellow-two-bg-color),
          var(--theme-color-yellow-two-text-color);
      }

      &.Figure--captionBackgroundColorYellowFive .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-yellow-five-bg-color),
          var(--theme-color-yellow-five-text-color);
      }

      &.Figure--captionBackgroundColorRedFour .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-red-four-bg-color),
          var(--theme-color-red-four-text-color);
      }

      &.Figure--captionBackgroundColorGreyTwo .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-grey-two-bg-color),
          var(--theme-color-grey-two-text-color);
      }

      &.Figure--captionBackgroundColorGreyFive .Figure__caption {
        @mixin CommonBlockColors var(--theme-color-grey-five-bg-color),
          var(--theme-color-grey-five-text-color);
      }
    }
  }
}
