:root {
  --nav-item-link-height: 2.3125rem; /* 37px */
  --hamburger-icon-line-width: 1.75rem;
  --hamburger-button-size: 2rem;
}

.Nav {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

.Nav--relative {
  @media (--laptop) {
    z-index: 10;
    position: relative;
  }
}

.Nav__menuListWrapper {
  display: none;

  @media (--laptop) {
    display: initial;
  }
}

.Nav__menuList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: calc(1.25 * var(--spacing-base));
}

.Nav__menuItem {
  &.Nav__menuItem--relative {
    z-index: 10;
    position: relative;
  }

  &:last-of-type {
    & .NavSubmenu.NavSubmenu--visible:not(.NavSubmenu--fullWidth) {
      /* stylelint-disable-next-line declaration-no-important */
      width: 326px !important;

      @media (--desktopMedium) {
        /* stylelint-disable-next-line declaration-no-important */
        width: 426px !important;
      }
    }
  }
}

.Nav__menuItemLink {
  @mixin resetButtonStylesNoOverride;
  @mixin typographyDesktopStrongMedium;

  display: flex;
  align-items: center;
  gap: calc(0.75 * var(--spacing-base));
  padding: 0 calc(1.25 * var(--spacing-base));
  height: var(--nav-item-link-height);
  word-break: keep-all;
  transition: background 0.2s;
  @mixin focusVisible var(--outlineColorSecondary);

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:hover,
  &:focus-visible,
  &.Nav__menuItemLink--isOpened {
    background: #f8f8f8;
  }
}

.Nav__menuItemIcon {
  transition: transform 0.2s;
  font-size: 0.9rem;
  min-width: 0.9rem;
  transform: translateY(2px);

  &.Nav__menuItemIcon--rotated {
    transform: translateY(1px) rotate(-180deg);
  }
}

.Nav__searchInputWrapper {
  display: none;

  @media (--laptop) {
    display: initial;
  }
}

.Nav__mobileFocusLock {
  @media (--laptop) {
    display: none;
  }
}

.Nav__hamburger {
  @mixin resetButtonStyles;

  width: var(--hamburger-button-size);
  height: var(--hamburger-button-size);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  position: relative;
  @mixin focusVisible var(--outlineColorSecondary);

  @media (--laptop) {
    display: none;
  }
}

.Nav__hamburgerLine {
  position: relative;
  width: var(--hamburger-icon-line-width);
  height: 2px;
  border-radius: 100px;
  background: var(--blackColor);
  transition: background 0.2s;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 100px;
    background: var(--blackColor);
    left: 0;
    width: 100%;
    height: 2px;
    transition:
      transform 0.3s,
      background 0.2s;
    will-change: transform;
  }

  &::before {
    top: -0.5rem;
  }

  &::after {
    top: 0.5rem;
  }
}

.Nav__hamburger--active .Nav__hamburgerLine {
  background: transparent;

  &::before {
    transform: translateX(-0.5625rem) rotate(-45deg) translateY(0.8125rem);
  }

  &::after {
    transform: translateX(-0.375rem) rotate(45deg) translateY(-0.5625rem);
  }
}

.Nav--isTransparent {
  & .Nav__menuItemLink {
    color: var(--whiteColor);

    &:hover,
    &.Nav__menuItemLink--isOpened {
      background: transparent;
    }
  }

  & .Nav__hamburgerLine {
    &,
    &::before,
    &::after {
      background: var(--whiteColor);
    }
  }
}
