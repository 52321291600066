/* stylelint-disable selector-class-pattern */

.ProductListingSearch {
  --input-height: 46px;
  --max-width-mobile: 334px;
  --max-width-laptop: 421px;

  min-height: var(--input-height);
}

.ProductListingSearch__searchBar {
  position: relative;
  --max-width: var(--max-width-mobile);

  @media (--laptop) {
    background-color: transparent;
    --max-width: var(--max-width-laptop);
  }
}

.ProductListingSearch__close {
  @mixin typographyDesktopStrongSmall;

  appearance: none;
  background: transparent;
  border: none;
  margin-block-end: var(--spacing-xs);
  display: none;
  align-items: center;
  padding: 0;

  & svg {
    transform: rotate(180deg);
  }
}

.ProductListingSearch__searchInput {
  max-width: var(--max-width);
}

.ProductListingSearch__results {
  background: var(--whiteColor);
  height: 0;

  @media (--laptop) {
    width: 100%;
    max-width: var(--max-width);
    position: absolute;
    top: calc(var(--input-height) + var(--spacing-xxxs) - var(--spacing-xxs));
    z-index: var(--z-index-above);
    box-shadow: 0 1px 6px 0 rgba(0 0 0 / 0.24);
    border-radius: calc(var(--spacing-xxxs) / 2);
    height: auto;
  }
}

.ProductListingSearch__header {
  min-height: var(--input-height);
}

/* transition styles */

.ProductListingSearch-enter {
  position: fixed;
  background: transparent;
  inset: 0;
  padding: 0 var(--spacing-s) var(--spacing-s);
  z-index: var(--z-index-modal);

  @media (--laptop) {
    position: static;
    padding: 0;
  }

  .ProductListingSearch__searchInput {
    position: absolute;
    inset-inline: var(--spacing-s);
    z-index: var(--z-index-modal);
    top: var(--top);

    @media (--laptop) {
      position: static;
    }
  }
}

.ProductListingSearch-enter-active {
  background: var(--whiteColor);
  transition: background 0.5s;

  @media (--laptop) {
    background: transparent;
  }
}

.ProductListingSearch-enter-done {
  position: fixed;
  inset: 0;
  padding: 0 var(--spacing-s) var(--spacing-s);
  overflow: auto;
  z-index: var(--z-index-modal);
  background: var(--whiteColor);

  --max-width: 100%;

  @media (--laptop) {
    position: relative;
    background: transparent;
    padding: 0;
    overflow: visible;
    z-index: unset;
    --max-width: var(--max-width-laptop);
  }

  .ProductListingSearch__close {
    display: flex;

    @media (--laptop) {
      display: none;
    }
  }

  .ProductListingSearch__header {
    padding-block: var(--spacing-s) var(--input-height);

    @media (--laptop) {
      padding-block: 0;
    }
  }

  .ProductListingSearch__searchInput {
    position: absolute;
    inset-inline: var(--spacing-s);
    z-index: var(--z-index-modal);
    top: 64px;
    height: var(--input-height);

    @media (--laptop) {
      position: static;
      height: auto;
    }
  }

  .ProductListingSearch__results {
    height: auto;
  }
}

.ProductListingSearch-exit {
  position: fixed;
  inset: 0;
  padding: 0 var(--spacing-s) var(--spacing-s);
  overflow: auto;
  z-index: var(--z-index-modal);
  background: var(--whiteColor);
  --max-width: 100%;

  @media (--laptop) {
    position: static;
    padding: 0;
  }

  .ProductListingSearch__searchInput {
    top: 64px;
    position: absolute;

    @media (--laptop) {
      position: static;
    }
  }
}

.ProductListingSearch-exit-active {
  background: transparent;
  transition: background 0.5s;
  --max-width: 100%;

  @media (--laptop) {
    --max-width: var(--max-width-laptop);
  }

  .ProductListingSearch__searchInput {
    top: var(--top);
    position: absolute;
    transition: top 0.5s;
    inset-inline: var(--spacing-s);

    @media (--laptop) {
      position: static;
    }
  }
}
