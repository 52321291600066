.BackgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.BackgroundOverlay__layer {
  position: absolute;
  background-color: var(--base-grey-dark-100);
  opacity: 0.6;
  width: 100%;
  height: 100%;
}
