.LectureTimePlace__title {
  @mixin typographyMobileStrongLarge;

  margin-block-end: var(--spacing-xs);
}

.LectureTimePlace__timePlace {
  @mixin typographyMobileDefaultLarge;

  display: flex;
  align-items: center;
  gap: var(--spacing-xxxs);
  margin-block-end: var(--spacing-xxs);

  & svg {
    width: var(--spacing-xs);
    height: var(--spacing-xs);
  }
}

.LectureTimePlace {
  grid-area: timePlace;
}
