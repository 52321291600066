@define-mixin arrowLinkRightAnimation {
    animation: move 0.8s ease infinite;
}

@define-mixin arrowLinkRight {
    display: block;
    &::after {
        content: '';
        display: block;
        margin: var(--spacing-xs) 0;
    }

    &:hover {
        &:after {
            @mixin arrowLinkRightAnimation;
        }
    }
}

@define-mixin glyphArrowRight {
    content: '\000A0\02192';
}

@define-mixin arrowLinkRightLarge $color: var(--blackLighterColor) {
    @mixin arrowLinkRight;
    
    &::after {
        width: 30px;
        height: 20px;
        background: svg-load('Icons/ArrowLinkLarge.svg', stroke: $color) no-repeat center;
        background-size: 30px 20px;
    }
}

@define-mixin arrowLinkRightMedium $color: var(--blackLighterColor) {
    @mixin arrowLinkRight;

    &::after {
        width: 23px;
        height: 15px;
        background: svg-load('Icons/ArrowLinkMedium.svg', stroke: $color) no-repeat center;
        background-size: 23px 15px;
    }
}

@define-mixin arrowLinkRightSmall $color: var(--blackLighterColor) {
    @mixin arrowLinkRight;

    &::after {
        width: 19px;
        height: 12px;
        background: svg-load('Icons/ArrowLinkSmall.svg', stroke: $color) no-repeat center;
        background-size: 19px 12px;
    }
}

@define-mixin arrowLinkRightGlyph {
    &::after {
        @mixin glyphArrowRight;
        display: inline-block;
    }
}