.Layout {

  & .ImageWithCaption {
    height: auto;
    position: relative;

    & .ImageWithCaption__inner {
      min-height: auto;

      & picture {
        position: relative;
        min-height: auto;
        height: auto;
        width: 100%;
        display: block;

        &::before {
          content: '';
          display: block;
          padding-top: 65%;
        }
      }

      & img {
        min-height: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    & .ImageWithCaption__label {
      font-size: 14px;
      font-weight: 300;
      font-style: italic;
      line-height: 1.57;
      margin-top: 1.3rem;
      position: relative;
      color: var(--textColor);
      backdrop-filter: none;

      @media (--tablet) {
        font-size: 18px;
        line-height: 1.56;
        padding: 29px;
        width: calc(65px * 8 + 1px);
        background: none;
        margin-top: 0;
        background: svg-load('Grid/grid-item-blue.svg', fill: var(--kog_aqua));
        background-size: 65px;
      }

      &::before {
        display: none;
      }
    }

    & .ImageWithCaption__labelInner {
      margin: 0;
      height: auto;
    }
  }
}