.MicrositeSectionContent {
  &.MicrositeSectionContent--light {
    & .MicrositeSectionContent__outer {
      @media (--tablet) {
        background: color(var(--whiteColor) a(80%));
      }
    }

    & .MicrositeSectionContent__inner {
      background: color(var(--whiteColor) a(80%));

      @media (--tablet) {
        background: none;
      }
    }

    & .MicrositeSectionContent__heading,
    & .MicrositeSectionContent__description,
    & .MicrositeSectionContent__listItem {
      color: var(--blackColor);
    }

    & .MicrositeSectionContent__listItem {
      border-color: var(--blackColor);

      & .MicrositeSectionContent__button {
        @media (--desktop) {
          opacity: 1;
        }

        &:hover,
        &:active {
          opacity: 0.5;
        }

        &:after {
          @media (--mobileOnly) {
            @mixin arrowIcon var (--blackColor);
          }
        }
      }
    }
  }

  &.MicrositeSectionContent--dark {
    @media (--mobileOnly) {
      & .MicrositeSectionContent__inner {
        background: color(var(--blackColor) a(90%));
      }
    }

    & .MicrositeSectionContent__heading,
    & .MicrositeSectionContent__description,
    & .MicrositeSectionContent__listItem {
      color: var(--whiteColor);
    }

    & .MicrositeSectionContent__listItem {
      border-color: var(--whiteColor);

      & .MicrositeSectionContent__button {
        @media (--desktop) {
          opacity: 0.5;
        }

        &:hover,
        &:active {
          opacity: 1;
        }

        &:after {
          @media (--mobileOnly) {
            @mixin arrowIcon;
          }
        }
      }
    }
  }
}
