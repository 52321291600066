.Section {
  @mixin wrapper;
}

.Section--blue {
  background-color: var(--brandColor_tint_2);
}

.Section--darkBlue {
  background-color: var(--kog_blue_tint_9);

  & a {
    color: var(--whiteColor);
  }
}

.Section--beige {
  background-color: var(--secondaryColor_tint_3);
}

/* stylelint-disable-next-line selector-class-pattern */
.Section--dark-tint {
  background: color(var(--kog_blue_tint_5) a(30%));
}

/* stylelint-disable-next-line selector-class-pattern */
.Section--light-tint {
  background: var(--kog_blue_tint_7);
}

.Section--themeLight {
  background: color(var(--themeBrandColor_tint_3) a(25%));
}

.Section--text {
  margin: 2rem auto;

  @media (--tablet) {
    margin: 4.5rem auto;
  }
}

.Section--mb {
  margin-bottom: 2rem;

  @media (--tablet) {
    margin-bottom: 4.5rem;
  }
}

.Section--pt {
  padding-top: 1rem;

  @media (--tablet) {
    padding-top: 4.5rem;
  }
}

.Section--pb {
  padding-bottom: 1rem;

  @media (--tablet) {
    padding-bottom: 4.5rem;
  }
}

.Section--fullWidth {
  @mixin resetWrapper;
}

.Section--verticalPaddings {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (--tablet) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.Section--mediumVerticalPaddings {
  @mixin blockSpacingMedium;
}

.Section--verticalMargins {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (--tablet) {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
}

.Section--marginBottom {
  margin-bottom: 2rem;

  @media (--tablet) {
    margin-bottom: 4.5rem;
  }
}

.Section__title--stories {
  font-family: var(--font-family-stories-secondary);
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 24px;
  font-weight: 400;
  line-height: 2.29;
  letter-spacing: 2px;
  margin: 1rem 0 1.5rem;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 30px;
    margin: 0 0 2rem;
  }

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 35px;
  }
}

.Section__subtitle {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 20px;

  @media (--tablet) {
    margin: 1rem 0;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 24px;
  }
}
