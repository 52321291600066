.RichOverlayImage__mediaPositionRight,
.RichOverlayImage__mediaPositionLeft {
  background-size: auto 100%;
  
  &::before {
    top: 0;
    width: 100%;
    height: calc(100% - 6vh);

    @media(--laptop) {
      width: 65vw;
      height: 100%;
    }
  }
  
  & .RichOverlayImage__image {
    @media(--laptop) {
      width: 35vw;
      height: 100%;
      position: absolute;
      top: 0;
      display: block;
      z-index: 1;
    }
  }

  & .RichOverlayImage__wrapper {
    @media(--laptop) {
      width: calc(100%*7/12);
    }
  }
}

.RichOverlayImage__mediaPositionRight {
  background-position: 25vw, center;
  &::before {
    left: 0;
  }

  & .RichOverlayImage__inner {
    align-items: flex-start;
  }

  & .RichOverlayImage__image {
    @media(--laptop) {
      right: 0;
    }
  }
}

.RichOverlayImage__mediaPositionLeft {
  background-position: -25vw, center;
  &::before {
    right: 0;
  }

  & .RichOverlayImage__inner {
    align-items: flex-end;
  }

  & .RichOverlayImage__image {
    @media(--laptop) {
      left: 0;
    }
  }
}

.RichOverlayImage__mediaPositionTop,
.RichOverlayImage__mediaPositionBottom {
  background-position: center;
  background-size: cover;
  
  &::before {
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 6vh);

    @media(--laptop) {
      top: auto;
      height: 80vh;
      width: 100%;
    }
  }

  & .RichOverlayImage__image {
    @media(--laptop) {
      width: 100vw;
      position: absolute;
      display: block;
      z-index: 1;
      padding-top: 0;
      margin: 0;
      max-width: none;
    }
  }
  
  & .RichOverlayImage__inner {
    display: flex;
    flex-flow: column nowrap;
    @media(--laptop) {
      height: 55vh;
    }
  }
  
  & .SlidesNavigation__innerScroll {
    @media(--laptop) {
      max-height: 20vh;
    }
  }

  & .RichOverlayImage__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @media(--laptop) {
      max-width: 650px;
    }
  }
}

.RichOverlayImage__mediaPositionTop {
  &::before {
    @media(--laptop) {
      bottom: 0;
    }
  }

  & .RichOverlayImage__image {
    @media(--laptop) {
      height: 45vh;
      top: 0;
    }
  }
  
  & .RichOverlayImage__inner {
    @media(--laptop) {
      margin-top: 35vh;
    }
  }
}

.RichOverlayImage__mediaPositionBottom {
  &::before {
    @media(--laptop) {
      top: 0;
    }
  }

  & .RichOverlayImage__image {
    @media(--laptop) {
      height: 25vh;
      bottom: 0;
    }
  }

  & .RichOverlayImage__inner {
    @media(--laptop) {
      align-self: flex-start;
    }
  }
}