.CampaignPage {
  & .ContactCard {
    & .ContactCard__inner {
      &::after {
        content: "";
        position: absolute;
        top: 85px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: svg-load("Common/Background.svg", fill: var(--kog_bronze_tint_3)) no-repeat;
        z-index: -1;
      }
    }

    & .ContactCard__info {
      color: var(--textColor);
    }

    & .ContactCard__name {
      font-weight: 500;
    }

    & a {
      color: var(--textColor);
    }

    & .ContactCard__phone {
      &::before {
        background: svg-load("Icons/Phone1.svg");
      }
    }

    & .ContactCard__address {
      &::before {
        background: svg-load("Icons/EmailEnvelope.svg");
      }
    }
  }

  & .Downloads {
    margin: 0;
  }

  & .TextBlock {
    @mixin wrapper;

    @media (--laptop) {
      padding-left: 0;
      max-width: var(--wrapperWidth);
      padding-right: var(--sidebarWidth);
    }
  }
}

.CampaignHero {
  position: relative;
  color: var(--whiteColor);
  min-height: 460px;
  max-height: 1300px;
  margin-bottom: 2rem;

  @media (--laptop) {
    margin-bottom: 4.5rem;
  }

  & .Hero,
  & .Hero--withImage {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color(var(--kog_blue) a(50%));
    z-index: 1;
  }

  & .Hero--withImage,
  & .Hero--campaign {
    & .Hero__media {
      min-height: 460px;
    }

    & .Hero__media,
    & .Hero__container,
    & .Hero__picture,
    & .Hero__placeholder {
      max-height: 460px;
    }
  }
}

.CampaignHero__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.CampaignHero__inner {
  @mixin wrapper;

  position: relative;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
}

.CampaignHero__logo {
  position: absolute;
  top: 5px;
  left: 0;
  width: 70px;
  height: 90px;
  background: svg-load("Common/logo-dark.svg", fill: var(--whiteColor)) no-repeat center;
  background-size: contain;

  @media (--tablet) {
    width: 80px;
    height: 100px;
  }

  @media (--laptop) {
    top: 30px;
  }
}

.CampaignHero__text {
  line-height: 1.3;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 8rem 0;
  max-width: 100%;

  @media (--tablet) {
    margin: 10rem 0;
  }
}

.CampaignHero__smallTitle {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.5px;
  color: inherit;
  margin: 0;
  max-width: 80%;

  @media (--tablet) {
    @mixin dynamicFontSize 16, 24;
  }
}

.CampaignHero__title {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 2px;
  color: inherit;

  @media (--tablet) {
    @mixin dynamicFontSize 32, 50;
  }
}
