.UpcomingEvents {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  width: calc(100vw - 3rem);

  @media (--laptop) {
    width: initial;
    border-radius: 2px;
  }
}

.UpcomingEvents__slider {
  & .ContentListSlider__sliderTrack {
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: 0 !important;
    /* stylelint-disable-next-line declaration-no-important */
    padding-right: 0 !important;
  }

  & .ContentListSlider__arrows {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }

  & .ContentListSlider__pagination {
    /* stylelint-disable-next-line declaration-no-important */
    width: 100% !important;
    /* stylelint-disable-next-line declaration-no-important */
    max-width: 100% !important;
  }

  & .UpcomingEvents__slide {
    /* stylelint-disable-next-line declaration-no-important */
    margin-right: calc(var(--spacing-xxs) + var(--spacing-xxxs)) !important;
    /* stylelint-disable-next-line declaration-no-important */
    width: 211px !important;
  }

  @media (--laptop) {
    display: none;
  }
}

.UpcomingEvents__desktop {
  display: none;

  @media (--laptop) {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
    background: var(--base-grey-light-60);
    padding: var(--spacing-s) var(--spacing-xs) var(--spacing-xs);
  }
}

.UpcomingEvents__title {
  @mixin typographyMobileStrongXLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopStrongLarge;
  }
}

.UpcomingEvents__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.UpcomingEvents__itemDate {
  @mixin typographyDesktopDefaultMedium;

  margin-bottom: var(--spacing-xxs);
  display: inline-block;
  color: var(--base-grey-dark-100);
}

.UpcomingEvents__itemTitle {
  @mixin typographyMobileStrongLarge;

  margin-bottom: calc(1.5 * var(--spacing-xxxs));
}

.UpcomingEvents__itemLocation {
  @mixin typographyDesktopDefaultSmall;

  color: var(--base-grey-dark-100);
}

.UpcomingEvents__itemIconWrapper {
  display: flex;
  align-items: flex-end;
  flex: 1;
}

.UpcomingEvents__itemArrow {
  margin-top: var(--spacing-xs);
  transition: transform 0.2s;
  margin-left: var(--spacing-xxs);
}

.UpcomingEvents__itemLink {
  display: flex;
  flex-direction: column;

  @mixin focusVisible var(--outlineColorSecondary);

  @media (--mobileAndTablet) {
    width: 100%;
    height: 100%;
  }

  &:hover {
    & .UpcomingEvents__itemArrow {
      transform: translateX(4px);
    }
  }

  @media (--laptop) {
    padding-bottom: var(--spacing-xxs);
    border-bottom: 1px solid #ededed;
  }
}

.UpcomingEvents__seeAllWrapperMobile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
