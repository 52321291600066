.MicrositeHotSpot {
  background: none;
  transition: background 0.3s ease-in-out;
  border-radius: 100%;
  cursor: pointer;

  &.MicrositeHotSpot--visible {
    background: color(var(--whiteColor) a(35%));
  }

  &.MicrositeHotSpot--visibleMobile {
    display: block;
  }

  &.MicrositeHotSpot--hiddenMobile {
    display: none;

    @media (--laptop) {
      display: block;
    }
  }
}

.MicrositeHotSpot__icon {
  @mixin absoluteCenter;
  z-index: 1;
}

.MicrositeHotSpot__popOver {
  z-index: 1;
}
