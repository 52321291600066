:root {
  --imageMaxHeight: calc(100vh - 145px - (2 * 36px));
}

.MicrositeProductImage {
  position: relative;
  display: flex;
  justify-content: center;
  max-height: var(--imageMaxHeight);
  padding-top: 48px;
}

.MicrositeProductImage__pictureOuter {
  position: relative;

  & .MicrositeProductImage__pictureOuter--background {
    background: color(var(--whiteColor) a(70%));
    box-shadow: inset 0px 0px 0px 2px color(var(--whiteColor) a(70%));
    border-radius: 6px;
  }
}

.MicrositeProductImage__picture {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in-out;
}
