@define-mixin teaserColorVariant $primaryBtnVariant {
  & .Teaser__button {
    @mixin $(primaryBtnVariant);
  }
}

.Teaser {
  &.Teaser--buttonColorWhite {
    @mixin teaserColorVariant buttonPrimaryThemeColorWhite;
  }

  &.Teaser--buttonColorBlack {
    @mixin teaserColorVariant buttonPrimaryThemeColorBlack;
  }

  &.Teaser--buttonColorGreenFour {
    @mixin teaserColorVariant buttonPrimaryThemeColorGreenFour;
  }

  &.Teaser--buttonColorBlueFive {
    @mixin teaserColorVariant buttonPrimaryThemeColorBlueFive;
  }

  &.Teaser--buttonColorTealTwo {
    @mixin teaserColorVariant buttonPrimaryThemeColorTealTwo;
  }

  &.Teaser--buttonColorSandFour {
    @mixin teaserColorVariant buttonPrimaryThemeColorSandFour;
  }

  &.Teaser--buttonColorTealFive {
    @mixin teaserColorVariant buttonPrimaryThemeColorTealFive;
  }

  &.Teaser--buttonColorYellowFour {
    @mixin teaserColorVariant buttonPrimaryThemeColorYellowFour;
  }

  &.Teaser--buttonColorGreenTwo {
    @mixin teaserColorVariant buttonPrimaryThemeColorGreenTwo;
  }

  &.Teaser--buttonColorBlueTwo {
    @mixin teaserColorVariant buttonPrimaryThemeColorBlueTwo;
  }

  &.Teaser--buttonColorSandTwo {
    @mixin teaserColorVariant buttonPrimaryThemeColorSandTwo;
  }

  &.Teaser--buttonColorForestTwo {
    @mixin teaserColorVariant buttonPrimaryThemeColorForestTwo;
  }

  &.Teaser--buttonColorGreenOne {
    @mixin teaserColorVariant buttonPrimaryThemeColorGreenOne;
  }

  &.Teaser--buttonColorGreenFive {
    @mixin teaserColorVariant buttonPrimaryThemeColorGreenFive;
  }

  &.Teaser--buttonColorForestFive {
    @mixin teaserColorVariant buttonPrimaryThemeColorForestFive;
  }

  &.Teaser--buttonColorBlueThree {
    @mixin teaserColorVariant buttonPrimaryThemeColorBlueThree;
  }

  &.Teaser--buttonColorYellowTwo {
    @mixin teaserColorVariant buttonPrimaryThemeColorYellowTwo;
  }

  &.Teaser--buttonColorYellowFive {
    @mixin teaserColorVariant buttonPrimaryThemeColorYellowFive;
  }

  &.Teaser--buttonColorRedFour {
    @mixin teaserColorVariant buttonPrimaryThemeColorRedFour;
  }

  &.Teaser--buttonColorGreyTwo {
    @mixin teaserColorVariant buttonPrimaryThemeColorGreyTwo;
  }

  &.Teaser--buttonColorGreyFive {
    @mixin teaserColorVariant buttonPrimaryThemeColorGreyFive;
  }
}
