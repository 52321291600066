@define-mixin linkBlockColorVariant $fontColor {
  & .ContentList {
    & .LinkBlock {
      & a {
        color: $fontColor;
      }

      & .ButtonLink:empty {
        @mixin arrowLinkRightMedium $fontColor;
      }
    }

    & .LinkBlock__icon {
      & svg {
        fill: $fontColor;
      }
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin linkBlockColorVariant var(--theme-color-beige-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin linkBlockColorVariant var(--theme-color-blue-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin linkBlockColorVariant var(--theme-color-blue-dark-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin linkBlockColorVariant var(--theme-color-brown-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin linkBlockColorVariant var(--theme-color-green-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin linkBlockColorVariant var(--theme-color-yellow-text-color);
}

.ContainerWithBackground--Pink {
  @mixin linkBlockColorVariant var(--theme-color-pink-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin linkBlockColorVariant var(--theme-color-turquoise-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin linkBlockColorVariant var(--theme-color-turquoise-dark-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin linkBlockColorVariant var(--theme-color-green-dark-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin linkBlockColorVariant var(--theme-color-sand-dark-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin linkBlockColorVariant var(--theme-color-forest-dark-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin linkBlockColorVariant var(--theme-color-green-one-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin linkBlockColorVariant var(--theme-color-green-five-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin linkBlockColorVariant var(--theme-color-forest-five-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin linkBlockColorVariant var(--theme-color-blue-three-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin linkBlockColorVariant var(--theme-color-yellow-two-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin linkBlockColorVariant var(--theme-color-yellow-five-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin linkBlockColorVariant var(--theme-color-red-four-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin linkBlockColorVariant var(--theme-color-grey-two-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin linkBlockColorVariant var(--theme-color-grey-five-text-color);
}
