@define-mixin buttonPlayVideo {
  /* Container must have position: relative */

  width: 75px;
  height: 75px;
  display: block;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    transition: all 0.3s ease;
  }

  &::before {
    margin: auto;
    inset: 0;
    width: 75px;
    height: 75px;
    cursor: pointer;
    border-radius: 50%;
    background: var(--tertiaryColor);
    z-index: 1;
  }

  &::after {
    pointer-events: none;
    top: calc(50% - 20px);
    left: calc(50% - 8px);
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 25px solid var(--whiteColor);
    z-index: 2;
  }

  &:hover {
    &::before {
      @mixin hoverDarken;
    }
  }
}

@define-mixin discoveryButtonPlayVideo $buttonSize: var(--spacing-m), $iconSize: var(--spacing-s) {
  width: auto;
  height: auto;
  display: block;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    transition: background-color 0.3s ease;
  }

  &::before {
    margin: 0;
    left: 0;
    top: 0;
    width: $buttonSize;
    height: $buttonSize;
    border-radius: 50%;
    background: var(--base-grey-dark-60);
    z-index: var(--z-index-play-video-button-background);
  }

  &::after {
    pointer-events: none;
    top: calc(($buttonSize / 2) - ($iconSize / 2));
    left: calc(($buttonSize / 2) - ($iconSize / 2));
    width: $iconSize;
    height: $iconSize;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    background: svg-load(
        "Icons/Discovery/Play.svg",
        fill: var(--base-grey-white),
        width: $iconSize,
        height: $iconSize
      )
      no-repeat;
    z-index: var(--z-index-play-video-button-icon);
  }

  &:hover,
  &:active {
    &::before {
      background: var(--base-grey-dark-80);
    }
  }

  @mixin focusVisible var(--outlineColorTertiary);
}
