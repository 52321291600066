@define-mixin arrowColor $color {
  svg {
    #PointerExpand,
    #PointerLeg {
      stroke: $color;
    }

    #PointerHead {
      fill: $color;
    }
  }
}

.SideTextHotSpotVariantA__ArrowColor--default {
  @mixin arrowColor var(--kog_red);
}

.SideTextHotSpotVariantA__ArrowColor--blueFive {
  @mixin arrowColor var(--kog_blue_tint_7);
}

.SideTextHotSpotVariantA__ArrowColor--sandFour {
  @mixin arrowColor var(--kog_bronze_tint_1);
}

.SideTextHotSpotVariantA__ArrowColor--tealTwo {
  @mixin arrowColor var(--kog_aqua_tint_6);
}

.SideTextHotSpotVariantA__ArrowColor--tealFive {
  @mixin arrowColor var(--kog_aqua_tint_1);
}

.SideTextHotSpotVariantA__ArrowColor--yellowFour {
  @mixin arrowColor var(--kog_yellow);
}

.SideTextHotSpotVariantA__ArrowColor--greenTwo {
  @mixin arrowColor var(--kog_green_tint_3);
}

.SideTextHotSpotVariantA__ArrowColor--blueTwo {
  @mixin arrowColor var(--kog_blue_tint_1);
}

.SideTextHotSpotVariantA__ArrowColor--sandTwo {
  @mixin arrowColor var(--kog_bronze_tint_6);
}

.SideTextHotSpotVariantA__ArrowColor--forestTwo {
  @mixin arrowColor var(--kog_forest_tint_3);
}

.SideTextHotSpotVariantA__ArrowColor--greenFour {
  @mixin arrowColor var(--kog_green_tint_2);
}

.SideTextHotSpotVariantA__ArrowColor--white {
  @mixin arrowColor var(--whiteColor);
}

.SideTextHotSpotVariantA__ArrowColor--black {
  @mixin arrowColor var(--textColor);
}

.SideTextHotSpotVariantA__ArrowColor--greenOne {
  @mixin arrowColor var(--kog_green_tint_4);
}

.SideTextHotSpotVariantA__ArrowColor--greenFive {
  @mixin arrowColor var(--kog_green_tint_1);
}

.SideTextHotSpotVariantA__ArrowColor--forestFive {
  @mixin arrowColor var(--kog_forest_tint_1);
}

.SideTextHotSpotVariantA__ArrowColor--blueThree {
  @mixin arrowColor var(--kog_blue_tint_9);
}

.SideTextHotSpotVariantA__ArrowColor--yellowTwo {
  @mixin arrowColor var(--kog_yellow_tint_3);
}

.SideTextHotSpotVariantA__ArrowColor--yellowFive {
  @mixin arrowColor var(--kog_yellow_tint_1);
}

.SideTextHotSpotVariantA__ArrowColor--redFour {
  @mixin arrowColor var(--kog_red_tint_1);
}

.SideTextHotSpotVariantA__ArrowColor--greyTwo {
  @mixin arrowColor var(--grayColor_tint_6);
}

.SideTextHotSpotVariantA__ArrowColor--greyFive {
  @mixin arrowColor var(--grayColor_tint_2);
}
