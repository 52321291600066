.ImageWithFactsViewer {
  &.ImageWithFactsViewer--scrollList {
    @media (--tablet) {
      margin-block: var(--spacing-xxxl) 0;
      gap: var(--spacing-s);
      aspect-ratio: 1040 / 510; /* container size / image height */
    }

    & .ImageWithFactsViewer__container {
      @mixin kogTabletColumnStart 7;
      @mixin kogTabletColumn 6;

      background-color: var(--grayColor_tint_2);
      border-radius: var(--spacing-xxs);
      order: 2;
      overflow: hidden;
    }

    & .ImageWithFactsViewer__imageContainer {
      display: block;
      aspect-ratio: 1;

      @mixin kogTabletColumnStart 1;
      @mixin kogTabletColumn 6;

      padding-left: 0;

      & picture,
      & img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    & .ImageWithFactsViewer__list {
      overflow: hidden auto;
      gap: 0;
      height: 100%;
    }

    & .ImageWithFactsViewer__singleFact {
      display: flex;
      flex-direction: column;
      gap: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
      margin-inline: var(--spacing-xs);
      padding-block: var(--spacing-xs);
      position: relative;

      @media (--tablet) {
        display: grid;
        grid-template-columns: 4fr 6fr;
        gap: var(--spacing-xs);
        align-items: start;
      }
    }

    & .ImageWithFactsViewer__listItem {
      transition: background-color 200ms;

      &:hover {
        background-color: var(--kog_blue_tint_7);
      }

      &:not(:last-child) {
        & .ImageWithFactsViewer__singleFact {
          border-bottom: 1px solid var(--black-800);
        }
      }
    }

    .ImageWithFactsViewer__singleFactHeader {
      gap: var(--spacing-xxs);
      align-items: center;

      @media (--tablet) {
        display: flex;
      }
    }

    & .ImageWithFactsViewer__singleFactLetter {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--base-grey-black-100);
      width: var(--spacing-s);
      height: var(--spacing-s);
      flex-shrink: 0;
    }

    & .ImageWithFactsViewer__singleFactLetter,
    & .ImageWithFactsViewer__singleFactTitle {
      @mixin typographyMobileStrongSmall;

      color: var(--base-grey-black-100);
      margin-inline: 0;

      @media (--tablet) {
        @mixin typographyDesktopStrongSmall;
      }
    }

    & .ImageWithFactsViewer__singleFactContent {
      overflow: visible;
    }

    & .ImageWithFactsViewer__singleFactDescription {
      @mixin kogTabletColumnStart 1;
      @mixin kogTabletColumn 12;

      margin: 0 0 var(--spacing-xs);

      &:last-child {
        margin-bottom: 0;
      }

      @media (--tablet) {
        margin: var(--spacing-xxxs) 0 var(--spacing-xs);

        &:last-child {
          margin-bottom: var(--spacing-xxxs);
        }
      }

      & p {
        @mixin typographyMobileDefaultSmall;

        color: var(--base-grey-black-100);
        margin-block: 0 var(--spacing-xxs);

        @media (--tablet) {
          @mixin typographyDesktopDefaultSmall;

          margin-block: 0 calc(var(--spacing-xxs) + var(--spacing-xxxs));
        }

        &:last-child {
          margin-block: 0;
        }
      }
    }
  }
}
