.VacancyContactPerson__name {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;

  @media (--laptop) {
    font-size: 1rem;
  }
}

.VacancyContactPerson__phone {
  @mixin linkContactWithIcon iconPhone, var(--blackColor);

  text-decoration: underline;
}
