.Reports {
  background-color: var(--brandColor_dark_tint_6);
  
  & li {
    list-style: none;
  }
}

.Report__date {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 2em;
}

.Report__dateLabel {
  flex: 1;
  font-size: 24px;
  text-transform: uppercase;
}

.Report_dateValue {
  flex: 2;
}

.Report__tab {
  height: inherit;
  width: inherit;
}

.Report__loader {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, .8);
}