.LinkBlockGrid {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs)));

  @media (--mobileSmall) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (--tablet) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (--laptop) {
    gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  }

  & .LinkBlock--withImage {
    height: 100%;
  }
}
