.CompanyAddressSection {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.CompanyAddressSection .RichtextArea ul {
        list-style: none;
        padding-left: 0;
    }

.CompanyAddressSection .RichtextArea li:before {
        content: "";
        width: 14px;
        height: 14px;
        display: inline-block;
        background: svg-load('Icons/ArrowRight.svg', fill: var(--textColor)) no-repeat;
        background-size: contain;
    }