.ContentListTilesBlock {
  & .ContentListTilesBlock__slide {
    width: 234px;
    height: 357px;
    /* stylelint-disable-next-line declaration-no-important */
    margin-right: 0 !important;
  }

  & .ContentListTilesBlock__slider {
    & .ContentListSlider__slider {
      & .ContentListSlider__pagination {
        margin-top: var(--spacing-xs);
      }
    }
  }
}

.ContentListTilesBlock__mobile {
  @media (--laptop) {
    display: none;
  }
}

.ContentListTilesBlock__desktop {
  display: none;

  @media (--laptop) {
    display: initial;
  }
}

.ContentListTilesBlock__slidesBgWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: var(--z-index-content-list-tiles-slides-bg-wrapper);
  opacity: 0;
  transition: opacity 0.3s;

  &.ContentListTilesBlock__slidesBgWrapper--nr1 {
    left: 0;
  }

  &.ContentListTilesBlock__slidesBgWrapper--nr2 {
    left: -100%;
  }

  &.ContentListTilesBlock__slidesBgWrapper--nr3 {
    left: -200%;
  }

  &.ContentListTilesBlock__slidesBgWrapper--nr4 {
    left: -300%;
  }

  &.ContentListTilesBlock__slidesBgWrapper--nr5 {
    left: -400%;
  }

  &.ContentListTilesBlock__slidesBgWrapper--length2 {
    width: 200%;
  }

  &.ContentListTilesBlock__slidesBgWrapper--length3 {
    width: 300%;
  }

  &.ContentListTilesBlock__slidesBgWrapper--length4 {
    width: 400%;
  }

  &.ContentListTilesBlock__slidesBgWrapper--length5 {
    width: 500%;
  }
}

.ContentListTilesBlock__slidesBg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ContentListTilesBlock__slide--withBg {
  position: relative;

  /* stylelint-disable-next-line selector-class-pattern */
  &.is-active {
    & .ContentListTilesBlock__slidesBgWrapper {
      opacity: 1;
    }

    & .InfoRevealTile__textWrapper--default {
      opacity: 0;
    }

    & .InfoRevealTile__textWrapper--active {
      opacity: 1;
    }
  }
}

.ContentListTilesBlock__cta {
  @mixin flexCenter;

  margin-top: var(--spacing-m);

  @media (--mobileAndTablet) {
    width: 100%;
    text-align: center;
  }
}
