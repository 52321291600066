.FastFactList--Simple .FastFact {
  & .FastFact__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    inset: 0;
  }

  & .FastFact__icon {
    align-items: center;
    margin: 0 0 1rem;
    order: 1;

    @media (--tablet) {
      width: 90px;
      height: 90px;
      margin: 0 0 2.1rem;
    }
  }

  & .FastFact__icon svg {
    max-width: 90%;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  & .FastFact__link {
    &:hover {
      & h2 {
        text-decoration: underline;
      }
    }
  }

  & .FastFact__heading {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 18px;
    text-transform: none;
    margin: 0 0 1rem;
    font-weight: 400;
    order: 2;

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 24px;
      margin: 0 0 0.6rem;
      min-height: 53px;
    }
  }

  & .FastFact__text {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    order: 3;

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 18px;
      line-height: 1.56;
    }
  }
}
