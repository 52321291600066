.ImageViewerFullScreen__inner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  @media (--tablet) {
    gap: var(--spacing-m);
  }
}

.ImageViewerFullScreen__thumbnails {
  display: grid;
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));

  @media (--tablet) {
    grid-template-columns: minmax(49%, max-content) minmax(150px, max-content);
    grid-template-rows: repeat(2, 1fr);
  }
}

.ImageViewerFullScreen__thumbnail {
  @mixin resetButtonStylesNoOverride;
  @mixin focusVisible var(--outlineColorSecondary);

  display: none;
  flex-direction: column;
  gap: var(--spacing-xs);

  @media (--tablet) {
    display: flex;
  }

  & picture,
  & img {
    display: block;
    height: 100%;
  }

  &:not(.ImageViewerFullScreen__thumbnail--fullWidth) {
    cursor: pointer;
  }
}

.ImageViewerFullScreen__thumbnail--mainImage {
  display: flex;
  grid-row: 1 / 3;
}

.ImageViewerFullScreen__thumbnail--fullWidth {
  grid-column: 1 / 3;
}

.ImageViewerFullScreen__figure {
  margin: 0;
}

.ButtonFill.ImageViewerFullScreen__openGalleryButton {
  align-self: end;

  @media (--mobileOnly) {
    width: 100%;
    text-align: center;
  }
}
