.BackgroundHeroSection {
  padding: var(--spacing-s) 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  @media (--tablet) {
    padding: var(--spacing-m) 0;
  }
}

.BackgroundHeroSection__heroImage {
  position: absolute;
  inset: 0;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(0deg, rgba(0 0 0 / 0.6) -5.33%, rgba(0 0 0 / 0) 54.45%);
    z-index: var(--z-index-page-header-hero-overlay);
  }
}

.BackgroundHeroSection__placeholder {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100%;
  z-index: var(--z-index-page-header-hero-placeholder);
  filter: blur(1rem);
}

.BackgroundHeroSection__picture {
  position: absolute;
  inset: 0;
  z-index: var(--z-index-page-header-hero-picture);

  & img {
    min-height: 100%;
    width: 100%;
  }
}

.BackgroundHeroSection__wrapper {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  display: flex;
  z-index: var(--z-index-page-header-hero-content);
}

.BackgroundHeroSection__content {
  width: 100%;
  color: var(--whiteColor);
  display: flex;
  flex-direction: column;
}
