.OfficeLocationsMap {
  & .gm-style-iw.gm-style-iw-c {
    display: flex;
    justify-content: center;
    align-items: center;
    @mixin typographyDesktopStrongSmall;
    line-height: 1;
    color: var(--blackColor);
    padding: 0.625rem !important; /* 10px */
    overflow: visible;
    background-color: var(--base-grey-white);
    border-radius: 4px;
    box-shadow: 0px 3px 7px 0px color(var(--blackColor) a(50%));
  }

  & .gm-style .gm-style-iw-tc {
    &,
    &::after {
      width: 12px;
      height: 10px;
    }
  }

  & .gm-style-iw-d {
    overflow: visible !important;
  }

  & .gm-ui-hover-effect, & .gm-style-iw-chr {
    display: none !important;
  }
}
