@define-mixin blockSpacing {
  clear: both;
  margin: var(--spacing-m) auto;

  @media (--laptop) {
    margin-block: var(--spacing-xxxxxl);
  }
}

@define-mixin blockSpacingSmall {
  @mixin blockSpacing;
  margin-block: var(--spacing-s);

  @media (--laptop) {
    margin-block: var(--spacing-xxxl);
  }
}
