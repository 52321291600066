.JobsToBeDone:not(.JobsToBeDone--active) {
    display: none;
}

.JobsToBeDone__introduction--text {
    max-width: 50ch;
    font-size: 1.375rem;
}

.JobsToBeDone--active {
    display: block;
}
