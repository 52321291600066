@keyframes fadeInMove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0,0);
  }
}

.fadeInMove {
  animation: fadeInMove 1.25s var(--ease_soft) forwards;
}

.animate {
  @media (--tablet) {
    opacity: 0;
  }
}

.no-js .animate {
  opacity: 1;
}

/* Disable all animation on mobile and tablet */
#maincontent .animate {
  @media (--mobileAndTablet) {
    transform: translateY(0);
    opacity: 1;
  }
}