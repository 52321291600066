@define-mixin linkWithArrowGlyph $color: var(--blackLighterColor) {
    @mixin h1;
    @mixin arrowLinkRightGlyph;
    @mixin focusVisible var(--outlineColor), 1px;
    color: $color;
}

@define-mixin linkWithArrowSmall $color: var(--blackLighterColor){
    @mixin arrowLinkRightSmall $color;
    @mixin p;
    color: $color;
}

@define-mixin linkWithArrowMedium $color: var(--blackLighterColor){
    @mixin arrowLinkRightMedium $color;
    @mixin h8HighDesktop;
    color: $color;
}

@define-mixin linkWithArrowLarge $color: var(--blackLighterColor) {
    @mixin arrowLinkRightLarge $color;
    @mixin h5Desktop;
    color: $color;
}