@define-mixin ctaButtonColorVariant $name, $primaryBtnVariant, $secondaryBtnVariant {
  .CallToActionButton--$(name) {
    &.CallToActionButton--colored {
      &.CallToActionButton--primary {
        @mixin $(primaryBtnVariant);
      }

      &.CallToActionButton--secondary {
        @mixin $(secondaryBtnVariant);
      }

      /* TODO Improve/Make common disabled state for buttons styling */
      &[aria-disabled="true"],
      &:disabled {
        color: var(--whiteColor);
        background: var(--grayColor_tint_4);
      }
    }

    &[aria-disabled="true"],
    &:disabled {
      color: var(--whiteColor);
      background: var(--grayColor_tint_4);
    }
  }
}

.CallToActionButton {
  &:not(.CallToActionButton--colored) {
    &.CallToActionButton--primary {
      @mixin buttonPrimary;
      
      padding: calc(0.8 * var(--spacing-xxs)) var(--spacing-s);
    }

    &.CallToActionButton--secondary {
      @mixin buttonSecondary;
    }
  }
}

@mixin ctaButtonColorVariant white, buttonPrimaryThemeColorWhite, buttonSecondaryThemeColorWhite;

@mixin ctaButtonColorVariant black, buttonPrimaryThemeColorBlack, buttonSecondaryThemeColorBlack;

@mixin ctaButtonColorVariant greenFour, buttonPrimaryThemeColorGreenFour,
  buttonSecondaryThemeColorGreenFour;

@mixin ctaButtonColorVariant blueFive, buttonPrimaryThemeColorBlueFive,
  buttonSecondaryThemeColorBlueFive;

@mixin ctaButtonColorVariant tealTwo, buttonPrimaryThemeColorTealTwo,
  buttonSecondaryThemeColorTealTwo;

@mixin ctaButtonColorVariant sandFour, buttonPrimaryThemeColorSandFour,
  buttonSecondaryThemeColorSandFour;

@mixin ctaButtonColorVariant tealFive, buttonPrimaryThemeColorTealFive,
  buttonSecondaryThemeColorTealFive;

@mixin ctaButtonColorVariant yellowFour, buttonPrimaryYellowFour, buttonSecondaryYellowFour;

@mixin ctaButtonColorVariant greenTwo, buttonPrimaryThemeColorGreenFive,
  buttonSecondaryThemeColorGreenFive;

@mixin ctaButtonColorVariant blueTwo, buttonPrimaryThemeColorBlueTwo,
  buttonSecondaryThemeColorBlueTwo;

@mixin ctaButtonColorVariant sandTwo, buttonPrimaryThemeColorSandTwo,
  buttonSecondaryThemeColorSandTwo;

@mixin ctaButtonColorVariant forestTwo, buttonPrimaryThemeColorForestTwo,
  buttonSecondaryThemeColorForestTwo;

@mixin ctaButtonColorVariant greenOne, buttonPrimaryThemeColorGreenOne,
  buttonSecondaryThemeColorGreenOne;

@mixin ctaButtonColorVariant greenFive, buttonPrimaryThemeColorGreenTwo,
  buttonSecondaryThemeColorGreenTwo;

@mixin ctaButtonColorVariant forestFive, buttonPrimaryThemeColorForestFive,
  buttonSecondaryThemeColorForestFive;

@mixin ctaButtonColorVariant blueThree, buttonPrimaryThemeColorBlueThree,
  buttonSecondaryThemeColorBlueThree;

@mixin ctaButtonColorVariant yellowTwo, buttonPrimaryYellowTwo, buttonSecondaryYellowTwo;

@mixin ctaButtonColorVariant yellowFive, buttonPrimaryYellowFive, buttonSecondaryYellowFive;

@mixin ctaButtonColorVariant redFour, buttonPrimaryThemeColorRedFour,
  buttonSecondaryThemeColorRedFour;

@mixin ctaButtonColorVariant greyTwo, buttonPrimaryGreyTwo, buttonSecondaryGreyTwo;

@mixin ctaButtonColorVariant greyFive, buttonPrimaryThemeColorGreyFive,
  buttonSecondaryThemeColorGreyFive;
