@define-mixin backdropColor $color: var(--whiteColor) {
    @media (--laptop) {
        &::before {
            background: $color;
        }
    }
}

.SlideBackgroundColor--Default {
    @mixin backdropColor;
}

.SlideBackgroundColor--BlueFive {
    @mixin backdropColor var(--kog_blue_tint_7);
}

.SlideBackgroundColor--SandFour {
    @mixin backdropColor var(--kog_bronze_tint_1);
}

.SlideBackgroundColor--TealTwo {
    @mixin backdropColor var(--kog_aqua_tint_6);
}

.SlideBackgroundColor--TealFive {
    @mixin backdropColor var(--kog_aqua_tint_1);
}

.SlideBackgroundColor--YellowFour {
    @mixin backdropColor var(--kog_yellow);
}

.SlideBackgroundColor--GreenTwo {
    @mixin backdropColor var(--kog_green_tint_3);
}

.SlideBackgroundColor--BlueTwo {
    @mixin backdropColor var(--kog_blue_tint_1);
}

.SlideBackgroundColor--SandTwo {
    @mixin backdropColor var(--kog_bronze_tint_6);
}

.SlideBackgroundColor--ForestTwo {
    @mixin backdropColor var(--kog_forest_tint_3);
}

.SlideBackgroundColor--GreenFour {
    @mixin backdropColor var(--kog_green_tint_2);
}

.SlideBackgroundColor--White {
    @mixin backdropColor var(--whiteColor);
}

.SlideBackgroundColor--Black {
    @mixin backdropColor var(--textColor);
}

.SlideBackgroundColor--GreenOne {
    @mixin backdropColor var(--kog_green_tint_4);
}

.SlideBackgroundColor--GreenFive {
    @mixin backdropColor var(--kog_green_tint_1);
}

.SlideBackgroundColor--ForestFive {
    @mixin backdropColor var(--kog_forest_tint_1);
}

.SlideBackgroundColor--BlueThree {
    @mixin backdropColor var(--kog_blue_tint_9);
}

.SlideBackgroundColor--YellowTwo {
    @mixin backdropColor var(--kog_yellow_tint_3);
}

.SlideBackgroundColor--YellowFive {
    @mixin backdropColor var(--kog_yellow_tint_1);
}

.SlideBackgroundColor--RedFour {
    @mixin backdropColor var(--kog_red_tint_1);
}

.SlideBackgroundColor--GreyTwo {
    @mixin backdropColor var(--grayColor_tint_6);
}

.SlideBackgroundColor--GreyFive {
    @mixin backdropColor var(--grayColor_tint_2);
}

