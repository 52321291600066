.CertificatesTable {
    table-layout: fixed;
    & tr {
        border-bottom: 1px solid var(--grayColor_tint_1);
        background: transparent;
    }

    & thead tr {
        border-top: 1px solid var(--grayColor_tint_1);
    }

    & th {
        background: transparent;
        font-size: 14px; 
        color: var(--textColor);
        padding: 9px 0;
    }

    & th, & td {
        border-bottom: none;
        padding-left: 2px;
        padding-right: 2px;
        word-break: break-word;
        height: 100%;
        &.CertificatesTable__expirationDate {
            width: 130px;
            @media (--mobileOnly) {
                width: 80px;
            }
        }
    }
    & .CertificatesTable__certificateUrl {
        width: 50%;
        @media (--tablet) {
            width: 60%;
        }
        @media (--mobileOnly) {
            width: 40%;
        }
    } 
    & td.CertificatesTable__certificateUrl a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        color: var(--textColor);
        width: 100%;
        height: 100%;
        padding-right: 15px;
        & i.icon {
            background: transparent svg-load('Icons/ArrowRightSimple.svg') no-repeat;
            width: 30px;
            min-width: 30px;
            height: 30px;
        }
        &:hover{
            text-decoration: underline;
        }
        @media (--mobileOnly) {
            padding-right: 0;
        }
    } 
}