@define-mixin agendaDarkTheme {
    & .Agenda {
        @mixin commonDarkTheme;

        & .Agenda__tile {
            background-color: var(--blue-900);
        }

        & .Agenda__expandButton {
            color: var(--white-0);
            fill: var(--white-0);
        }

        & .Tabs__label {
            color: var(--blue-500);
        }

        & .Tabs__label--multipleDays {
            &::before{
                border-color: var(--blue-500);
            }

            &.Tabs__label--active {
                color: var(--white-0);
                background: var(--blue-500);

                &:hover {
                    background: var(--blue-400);
                }
            }
        }
        
        & .Tabs__showMoreItemsButton {
            color: var(--white-0);
            fill: var(--white-0);
        }
        
        .Agenda__time {
            color: var(--blue-300);

            svg {
                stroke: var(--blue-300);
            }
        }
    }
}

.Page--dark {
    @mixin agendaDarkTheme;
}

@media (--dark) {
    .Page:not(.Page--light) {
        @mixin agendaDarkTheme;
    }
}