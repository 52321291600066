:root {
  --footer-column-width: 14.125rem; /* 226px */
  --footer-column-width-medium-laptop: 12.5rem; /* 200px */
  --footer-column-width-small-laptop: 11rem; /* 176px */
}

.Footer {
  color: var(--whiteColor);
  font-family: var(--themeFontFamilyPrimary);
}

.Footer__topSectionContent,
.Footer__mainSectionInner {
  width: var(--footer-content-width);
  max-width: 100%;
  padding: 0 var(--spacing-s);
  margin: 0 auto;

  @media (--laptop) {
    padding: 0 var(--spacing-xl);
  }
}

.Footer__topSectionContent {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);

  @media (--tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--spacing-m);
    padding-bottom: var(--spacing-m);
  }
}

.Footer__topHeaderWrapper {
  display: flex;
  gap: var(--spacing-xxxs);
  flex-direction: column;

  @media (--tablet) {
    max-width: 500px;
    gap: calc(0.75 * var(--spacing-xxs));
  }

  @media (--laptop) {
    max-width: 600px;
  }

  &.Footer__topHeaderWrapper--noContent {
    display: none;

    @media (--tablet) {
      display: initial;
    }
  }
}

.Footer__topHeader {
  @mixin typographyMobileStrongLarge;

  @media (--laptop) {
    @mixin typographyDesktopStrongXLarge;
  }
}

.Footer__topSubheader {
  @mixin typographyMobileDefaultLarge;

  @media (--laptop) {
    @mixin typographyDesktopDefaultLarge;
  }
}

.Footer__richTextInfo {
  display: flex;
  flex-direction: column;
  gap: calc(1.5 * var(--spacing-xxxs));

  & p {
    @mixin typographyMobileDefaultMedium;

    margin: 0;

    @media (--laptop) {
      @mixin typographyDesktopDefaultMedium;
    }
  }

  &,
  & a,
  & > * {
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--whiteColor) !important;
  }

  & a {
    @mixin textUnderline;

    @media (--laptop) {
      @mixin resetTextUnderline;

      &:hover {
        @mixin textUnderline;
      }
    }
  }
}

.Footer__mainSection {
  background: var(--base-grey-black-100);
}

.Footer__columnsAndLogoContainer {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-s) + var(--spacing-xxxs));
  padding-top: var(--spacing-s);

  @media (--laptop) {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacing-m);
    padding: var(--spacing-l) 0 var(--spacing-xxl);
  }

  @media (--laptopMedium) {
    gap: var(--spacing-xl);
  }
}

.Footer__mainNavWrapper {
  order: 1;

  @media (--laptop) {
    order: initial;
  }
}

.Footer__mobileNavList {
  @media (--laptop) {
    display: none;
  }
}

.Footer__column {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-s) + var(--spacing-xxxs));
  width: var(--footer-column-width);
}

.Footer__columnsList {
  display: none;

  @media (--laptop) {
    display: flex;
    gap: var(--spacing-xl);
  }

  &.Footer__columnsList--narrowColumnsOnSmallDesktop {
    @media (--laptopOnly) {
      gap: var(--spacing-m);
    }

    & .Footer__column {
      @media (--laptop) {
        width: var(--footer-column-width-small-laptop);
      }

      /* stylelint-disable-next-line csstools/media-use-custom-media */
      @media screen and (min-width: 1200px) {
        width: var(--footer-column-width-medium-laptop);
      }

      @media (--desktop) {
        width: var(--footer-column-width);
      }
    }
  }
}

.Footer__columnHeading {
  @mixin typographyDesktopStrongLarge;
}

.Footer__columnCaption {
  @mixin typographyMobileDefaultXLarge;

  margin: 0;

  @media (--laptop) {
    @mixin typographyDesktopDefaultMedium;
  }
}

.Footer__freeTextColumnContent {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));

  @media (--laptop) {
    gap: calc(var(--spacing-s) + var(--spacing-xxxs));
  }
}

.Footer__freeTextButtonsWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));

  @media (--mobileAndTablet) {
    & > * {
      width: 100%;
      text-align: center;
      max-width: 520px;
    }
  }
}

.Footer__columnList {
  display: flex;
  flex-direction: column;
  gap: calc(0.5 * var(--spacing-xxxs));
}

.Footer__columnListItemLink {
  @mixin typographyDesktopDefaultMedium;

  display: flex;
  gap: calc(0.5 * var(--spacing-xxxs));
  align-items: center;
  height: var(--spacing-s);
  color: var(--whiteColor);

  & > svg {
    font-size: var(--spacing-s);
  }

  &:hover {
    @mixin textUnderline;
  }
}

.Footer__logoAndInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-s) + var(--spacing-xxxs));

  &.Footer__logoAndInfoWrapper--columnWidth {
    @media (--laptop) {
      max-width: var(--footer-column-width);
    }

    @media (--dekstop) {
      width: var(--footer-column-width);
    }
  }
}

.Footer__logoWrapper {
  margin: 0 calc(-1 * var(--spacing-xxxs));

  @media (--laptop) {
    margin: 0;
  }
}

.Footer__columnsList,
.Footer__columnList,
.Footer__copyrightList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Footer__copyrightData {
  border-top: 1px solid var(--base-grey-medium-100);
  padding: calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs))) 0;
}

.Footer__copyrightList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
}

.Footer__copyrightListItem {
  &,
  & > a {
    @mixin typographyDesktopDefaultXSmall;

    color: var(--base-grey-medium-100);
  }

  &:not(:last-of-type) {
    position: relative;
    padding-right: calc(var(--spacing-xs) + 3.5px);

    &::after {
      content: "|";
      @mixin typographyDesktopDefaultXSmall;

      color: var(--base-grey-medium-100);
      position: absolute;
      left: calc(100% - calc(0.5 * var(--spacing-xxxs)) - 3.5px);
      top: calc(50% - 1px);
      transform: translateY(-50%);
    }
  }

  @media (--laptop) {
    & > a:hover {
      @mixin textUnderline;
    }
  }
}
