.Checkbox {
  --checkbox-large-size: 1.25rem; /* 20px */
  --checkbox-normal-size: 1rem; /* 16px */
  --checkbox-small-size: var(--checkbox-normal-size);

  --checkbox-size: var(--checkbox-normal-size);

  --gap: 0.5rem; /* 8px */

  display: inline-block;
  position: relative;
}

.Checkbox__label {
  display: flex;
  cursor: pointer;
}

.Checkbox__input {
  @mixin visuallyHidden;
}

.Checkbox__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.Checkbox__box {
  display: block;
  border-radius: 2px;
  border: 1px solid var(--base-grey-medium-100);
  transition: border-color 0.1s;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  flex-shrink: 0;
}

.Checkbox__labelText {
  display: block;
  @mixin typographyDesktopDefaultMedium;

  color: var(--base-grey-black-100);
  line-height: 1;
}

.Checkbox__checkmark {
  color: var(--base-grey-white);
  opacity: 0;
  transition: opacity 0.1s;
  font-size: 1rem; /* 16px */
}

.Checkbox--small {
  --checkbox-size: var(--checkbox-small-size);

  & .Checkbox__box {
    border-width: 2px;
  }

  & .Checkbox__labelText {
    @mixin typographyDesktopDefaultXSmall;
  }
}

.Checkbox--large {
  --checkbox-size: var(--checkbox-large-size);

  & .Checkbox__box {
    border-width: 2px;
  }

  & .Checkbox__labelText {
    @mixin typographyMobileDefaultLarge;
  }
}

.Checkbox__label:hover > .Checkbox__inner,
.Checkbox__label > .Checkbox__input:focus + .Checkbox__inner > .Checkbox__box {
  border-color: var(--base-grey-dark-100);
}

.Checkbox__label > .Checkbox__input:checked + .Checkbox__inner > .Checkbox__box {
  border-width: 0;
  border-color: var(--base-grey-dark-100);
  background-color: var(--base-grey-dark-100);

  & .Checkbox__checkmark {
    opacity: 1;
  }
}

.Checkbox__label > .Checkbox__input:focus-visible + .Checkbox__inner {
  outline: var(--outlineColorSecondary) solid 2px;
}

.Checkbox--large .Checkbox__label > .Checkbox__input:checked + .Checkbox__inner > .Checkbox__box {
  border-width: 2px;
}
