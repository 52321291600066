.Downloads {
  position: relative;
  background-color: var(--secondaryColor_tint_3);
  margin: 20px auto;
  width: 100%;
  clear: both;

  & .Section__subtitle {
    margin: 2rem 0 1.2rem;
  }

  & .cardsList__3col li {
    margin-bottom: 0.5rem;
  }
}

.Downloads__title {
  @mixin h5Desktop;

  margin-block: var(--spacing-s);

  @media (--laptop) {
    @mixin h4Desktop;

    margin-block: var(--spacing-l);
  }
}

.Downloads__container {
  margin-bottom: 2.5rem;

  @media (--tablet) {
    margin-bottom: 4.5rem;
  }
}

.Downloads__items {
  margin-bottom: 1rem;
}

.Downloads__items:last-of-type {
  margin-bottom: initial;
}

.Downloads__item {
  display: flex;
  flex-flow: column;
}

.Downloads__itemName {
  position: relative;
  margin-bottom: 0;

  @media (--tablet) {
    margin-bottom: 0.5rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -40px;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    background-color: var(--brandColor);
    background-image: svg-load("Icons/Paperclip.svg", fill: var(--whiteColor));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
  }
}

.Downloads__itemName--text {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -40px;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    background-color: transparent;
    background-image: svg-load("Icons/DownloadTextDocument.svg", fill: "#334561");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px;
  }
}

.RichtextArea a.Downloads__itemLink,
.Downloads__itemLink {
  padding-left: 40px;
  display: flex;
  flex-flow: column;
  color: var(--textColor);
}
