.NextPageSectionButton {
  @mixin buttonNext var(--blackColor), var(--whiteColor);
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 35px;
  left: 20px;
  z-index: 2;
  transform: translateY(0);
  transition: z-index 0.5s step-start, transform 0.5s ease, opacity 0.5s ease;
  visibility: hidden;
  
  @media (--laptop) {
    display: flex;
    left: calc(50% - var(--wrapperWidth) / 2);
    visibility: visible;
  }  
  
  @media (--desktop) {
    left: calc(50% - var(--wrapperWidth) / 2 - 20px);
  }
  
  &.NextPageSectionButton--hide {
    opacity: 0;
    z-index: -1;
    transform: translateY(200%);
    transition: z-index 0.5s step-end, transform 0.5s ease, opacity 0.5s ease;
  }
  
  &::after {
    transform: rotate(90deg);
    transition: all ease .3s;
  }
  
  &.NextPageSectionButton--backToTop {
    visibility: visible;
  }  
  
  &.NextPageSectionButton--backToTop::after {
    transform: rotate(-90deg);
  }
}