.AnchorNavigation {
  width: 100%;
  background: var(--base-grey-light-80);
  border-bottom: 2px solid var(--base-grey-light-100);
  display: flex;
  align-items: center;

  @media (--laptop) {
    position: sticky;
    z-index: 100;
    top: var(--header-height-with-breadcrumbs);
    min-height: var(--anchor-navigation-height);
  }

  &.AnchorNavigation--hiddenOnMobile {
    @media (--mobileAndTablet) {
      display: none;
    }
  }
}

.AnchorNavigation__list {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  list-style: none;
  margin-bottom: 0;
  display: flex;
  gap: var(--spacing-xxs);
  flex-flow: row wrap;
  padding: var(--spacing-xs) 0;

  & a {
    @mixin typographyDesktopStrongMedium;
    @mixin focusVisible var(--outlineColorSecondary);

    color: var(--base-grey-dark-100);

    @media (--hover-devices) {
      &:hover {
        background: var(--base-grey-light-100);
      }
    }
  }

  @media (--laptop) {
    gap: var(--spacing-xs) calc(1.5 * var(--spacing-xxs));

    & a {
      padding: var(--spacing-xxs) calc(1.75 * var(--spacing-xxs));
    }
  }

  &.AnchorNavigation__list--bigTouchArea {
    @mixin wrapper var(--wrapperWidth), var(--spacing-xs);

    gap: 0;
    padding: var(--spacing-xxs) 0;

    & a {
      display: flex;
      width: 64px;
      height: 64px;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    @media (--tablet) {
      gap: 0 var(--spacing-xxs);
    }

    @media (--laptop) {
      gap: 0 var(--spacing-xs);
    }
  }
}
