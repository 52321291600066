.StoriesCategoryCard {
    position: relative;
    text-align: center;

    &:hover {
        & .StoriesCategoryCard__icon {
            transform: translateY(-15px);
        }

        & .StoriesCategoryCard__link {
            transform: translateY(-15px);
        }
        
        & .StoriesCategoryCard__link::before {
            transform: translate(-50%, 15px);
        }
        
        & .StoriesCategoryCard__link:before {
            display: block;
        }
    }
}

.StoriesCategoryCard__link {
    transition: all .25s ease-out;
}

.StoriesCategoryCard__icon {
    position: relative;
    transition: all .25s ease-out;
    
    & svg {
        width: 90px;
        height: 90px;
        margin: 0 auto;
        pointer-events: none;
    
        @media (--tablet) {
            width: 120px;
            height: 120px;
        }
    }
}

.StoriesCategoryCard__title {
    margin: 1.5rem 0 1rem 0;
    font-size: 1.25rem;
    letter-spacing: 0.06em;
    text-transform: none;
    
    @media (--tablet) {
        font-size: 1.5rem;
        margin: 2.5rem 0 2.2rem 0;
    }
}

.StoriesCategoryCard__description {
    margin-bottom: 0;
}

.StoriesCategoryCard__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    &:hover:before {
        content: attr(data-category);
        display: none;
        position: absolute;
        top: 90%;
        left: 50%;
        width: 120px;
        background: var(--blackColor);
        color: var(--whiteColor);
        font-size: 14px;
        padding: 0.3rem;
        transform: translateX(-50%);
        opacity: 0;
        animation: fadeIn .3s ease-in 0s forwards;
        white-space: nowrap;
    }
}
