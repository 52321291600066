@define-mixin h1Micro {
  font-family: var(--FamilyPressura);
  font-size: 48px;
  line-height: 1.2;
  margin: 0;
  text-transform: none;

  @media (--tablet) {
    @mixin dynamicFontSize 40, 64;
    line-height: 1;
  }

  @media (--desktop4k) {
    font-size: 144px;
    line-height: 1.33;
  }
}

@define-mixin h2Micro {
  font-family: var(--FamilyPressura);
  font-size: 24px;
  line-height: 1.3;
  margin: 0;
  text-transform: none;

  @media (--tablet) {
    @mixin dynamicFontSize 24, 32;
    line-height: 2;
  }

  @media (--desktop4k) {
    font-size: 58px;
    line-height: 1.05;
  }
}

@define-mixin linkMicro {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;

  &:hover,
  &:focus,
  &:active {
    text-decoration-thickness: 2px;
  }

  @media (--desktop4k) {
    @mixin dynamicFontSize 20, 28;
  }
}

@define-mixin pMicro {
  font-size: 14px;
  line-height: 1.8;

  @media (--tablet) {
    font-size: 20px;
    line-height: 1.75;
  }

  @media (--desktop4k) {
    @mixin dynamicFontSize 20, 28;
  }
}
