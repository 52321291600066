.ScrollToTopButton {
  z-index: var(--z-index-scroll-to-top);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: var(--whiteColor);
  border: 1px solid var(--base-grey-light-100);
  box-shadow: 0 4px 4px rgb(0 0 0 / 0.25);
  padding: 0;
  right: calc(1.25 * var(--spacing-xxs));
  bottom: var(--spacing-s);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;

  @mixin focusVisible var(--outlineColorSecondary);

  &.ScrollToTopButton--visible {
    opacity: 1;
    pointer-events: initial;
  }

  & > svg {
    transform: rotate(180deg) translateY(1px);
    width: var(--spacing-s);
    height: var(--spacing-s);
  }

  @media (--laptop) {
    display: none;
  }
}
