.SideTextHotSpotsImage__MobileDescriptionBox {
  display: none;

  @media (--mobileOnly) {
    display: block;
  }
}

.SideTextHotSpotsImage__MobileDescriptionBoxItem {
  border-bottom: 1px solid var(--grayColor);
}

.SideTextHotSpotsImage__MobileDescriptionBoxItemTitle {
  @mixin typographyMobileDefaultXLarge;

  display: block;
  background: none;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  margin: 0;
}

.SideTextHotSpotsImage__MobileDescriptionBoxItemDescription {
  transition: all 0.5s linear;
  max-height: 2000px;
}

.SideTextHotSpotsImage__MobileDescriptionBoxItemDescription--hidden {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
