@define-mixin arrowRightSimple $color, $iconSize: 85% {
  
  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    display: block;
    background: svg-load('Icons/ArrowRightSimple.svg', fill: $color) no-repeat center;
    background-size: $(iconSize);
  }

  &:hover {
    text-decoration: underline;

    &:after {
      animation: move 0.8s ease infinite;
    }
  }
}

