.ImageWithBlur {
  &:before {
    @mixin absoluteFullSize;
    display: block;
    content: "";
    z-index: 0;
  }

  &.ImageWithBlur--dark {
    &:before {
      @mixin backgroundGradient;
    }
  }

  &.ImageWithBlur--light {
    &:before {
      background: color(var(--whiteColor) a(10%));
    }
  }
}

.ImageWithBlur__blur {
  @mixin absoluteFullSize;
  display: block;
  z-index: -1;
  filter: blur(5px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}
