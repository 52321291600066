.LanguageSelector {
  margin: 3rem 0;

  & select {
    font-family: var(--themeFontFamilyPrimary);
    min-width: 60px;
    height: 30px;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 17px;
    border: 1px solid var(--kog_blue_tint_2);
    color: var(--kog_blue_tint_2);
    @mixin focusVisible var(--outlineColorSecondary);
  }
}
