.ContentBar {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: 75px;
    padding: 1rem 4rem 1rem 1.9rem;
    transition: all .3s ease;
    
    @media(--tablet) {
        min-height: 90px;
    }
    
    &.ContentBar--link {
        @mixin arrowRightSimple;
        
        &::after {
            right: 15px;
        }
    }

    &.ContentBar--expanded .ContentBar__icon {
        transform: rotate(-180deg);
    }

    &:hover {
        & .ContentBar__title {
            text-decoration: underline;
        }
    }

    &:focus-within {
        outline: 1px solid var(--outlineColorPrimary);
    }
}

.ContentBar--kog_blue_tint_5 {
    background: var(--kog_blue_tint_5);
}

.ContentBar--white,
.ContentBar__children--white {
    background: var(--whiteColor);
}

.ContentBar__title {
    margin: 0;
    font-size: 1.25rem;
}

.ContentBar__text {
    max-width: 955px;
    margin: 5px 0 0 0;
}

.ContentBar__btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.ContentBar__icon {
    position: absolute;
    right: 15px;
    transition: transform 0.4s ease;

    @mixin arrowDownSimple;
}

.ContentBar__children {
    padding: 0 1rem;
    
    &.ContentBar__children--hide {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
    
    @media (--tablet) {
        padding: 0 3.5rem;
    }
    
    @media (--laptop) {
        padding: 0 6.125rem;
    }
}