.Table {
  & th,
  td {
    border-bottom: 1px solid color(var(--brandColor_dark_tint_5) a(50%));
  }

  & th,
  thead td {
    background-color: var(--brandColor_dark_tint_1);
    color: white;
  }

  & tr:nth-child(even):not(.ClickDimensionsFrom) {
    background-color: color(var(--brandColor_dark_tint_6) a(45%));
  }
}

.Table__wrapper {
  overflow-x: auto;
}

.Table__title {
  margin: 2rem 0 1.5rem;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 24px;

  @media (--tablet) {
    margin: 3rem 0 2.5rem;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 28px;
  }
}
