.UpperContent {
  display: grid;

  @media (--laptop) {
    gap: var(--spacing-xs);
    grid-template-rows: repeat(2, auto);
  }
}

.UpperContent__subtitle {
  @mixin typographyDesktopStrongLarge;
}

.UpperContent__additionalInfo {
  display: flex;
  @mixin typographyDesktopDefaultMedium;

  @media (--laptop) {
    gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  }

  & *:not(:first-child) {
    border-left: 1px solid black;
    padding-left: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  }
}

.UpperContent__icon {
  width: 16px;
  height: 16px;
}

.UpperContent__segment {
  display: flex;
  gap: var(--spacing-xxs);
  width: fit-content;
  align-items: center;
}
