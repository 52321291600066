.RichCampaignFooter {
    position: relative;
    width: 100%;
    min-height: 180px;
    color: var(--whiteColor);
    background-color: var(--kog_blue);
    z-index: 1;
}

.RichCampaignFooter__wrapper {
    @mixin wrapper;
    padding: 2rem 0;
}

.RichCampaignFooter__inner {
    display: flex;
    flex-flow: column wrap;
    position: relative;

    @media (--tablet) {
        flex-flow: row wrap;
        flex-grow: 1;
    }
}

.RichCampaignFooter__logo {
    background: svg-load("Common/logo.svg") no-repeat;
    background-size: contain;
    position: relative;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;

    @media (--tablet) {
        position: absolute;
        right: 30px;
        top: 0;
    }

    & a {
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: calc(50% - 50px);

        @media (--laptop) {
            width: 80px;
            height: 80px;
            left: 0;
        }
    }
}

.RichCampaignFooter__copyright {
    position: absolute;
    bottom: 0;
    left: 0;

    & p {
        margin: 0;
    }
}

.RichCampaignFooter__list {
    list-style-type: none;
    margin: 0;
    padding: 0 0 2rem 0;
    min-height: 120px;

    @media (--tablet) {
        display: flex;
        flex-grow: 1;
        flex-flow: row nowrap;
        max-width: calc(100% - 160px);
    }

    & li {
        list-style-type: none;

        & ul {
            padding: 0;
            margin: 0;
        }
    }

    & a {
        color: var(--whiteColor);
    }
}

.RichCampaignFooter__listItem {
    margin: 1rem 0;

    &:first-of-type {
        padding: 0;
    }

    @media (--tablet) {
        margin-top: 0;
        width: 25%;
        padding-left: 30px;
    }
}

.RichCampaignFooter__heading {
    font-size: 18px;
    text-transform: unset;
    margin-bottom: 14px;

    @media (--laptop) {
        font-size: 20px;
    }
}

.RichCampaignFooter__headingLink {
    padding-right: 27px;
    background-size: 17px;
    background-image: svg-load('Icons/Link.svg');
    background-repeat: no-repeat;
    background-position: right center;

    @media (--tablet) {
        white-space: nowrap;
    }

    @media (--laptop) {
        padding-right: 35px;
        background-size: 20px;
    }
}