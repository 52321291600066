.OfficeLocationsFilters {
  display: grid;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs)); /* 12px */

  @media (--tablet) {
    gap: var(--spacing-xs);
  }
}

.OfficeLocationsFilters__searchRow {
  display: grid;
  grid-template:
    "search locateMe" auto
    "categories categories" auto / minmax(0, 1fr) auto;
  gap: var(--spacing-xxs);

  @media (--tablet) {
    grid-template: "search locateMe categories" auto / minmax(0, 1fr) auto auto;
  }

  @media (--laptop) {
    gap: calc(var(--spacing-xs) + var(--spacing-xxxs)); /* 20px */
  }

  & > .OfficeLocationsFiltersSearchInput {
    grid-area: search;
  }

  & > .OfficeLocationsFiltersLocateMeButton {
    grid-area: locateMe;
  }
}

.OfficeLocationsFilters__filtersRow {
  display: grid;
  grid-template:
    "categories" auto
    "types" auto / minmax(0, 1fr);
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs)); /* 12px */

  @media (--tablet) {
    grid-template: "types categories" auto / minmax(0, 1fr) auto;
    gap: 0;
  }
}

.OfficeLocationsFilters__categoryFilter {
  grid-area: categories;

  & .RadioSelect__menu {
    @media (--tablet) {
      left: auto;
      right: 0;
    }
  }
}

.OfficeLocationsFilters__typesFilter {
  grid-template: types;

  & .CheckboxGroup__list {
    @media (--mobileAndTablet) {
      flex-wrap: wrap;
      gap: var(--spacing-xxs);
    }

    @media (--tabletOnly) {
      margin-right: calc(var(--spacing-xxs) + var(--spacing-xxxs)); /* 12px */
    }

    &.CheckboxGroup__list--inline {
      & .CheckboxGroup__listItem {
        @media (--mobileAndTablet) {
          background-color: var(--base-grey-light-60);
          border: solid 2px var(--grayColor_tint_4);
          padding: var(--spacing-xxs);
          margin-block: 0;
        }

        @media (--tabletOnly) {
          padding: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
        }
      }
    }
  }

  & .Checkbox {
    @media (--mobileOnly) {
      --gap: 0.375rem; /* 6px */
      @mixin discoveryCheckboxLarge;
    }

    & .Checkbox__labelText {
      @media (--mobileOnly) {
        font-size: 0.875rem;
      }
    }
  }
}

.OfficeLocationsFilters--hasAdvancedFilters {
  & .OfficeLocationsFilters__searchRow {
    grid-template: "search locateMe" auto / minmax(0, 1fr) auto;
  }
}
