.MicrositeSideBar {
  display: flex;
  z-index: 1;
  width: 100%;

  @media (--laptop) {
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 36px 0;
    padding: 0 16px;
    border-right: 1px solid #ffffff;
  }

  &.MicrositeSideBar--light {
    color: var(--blackColor);
    border-color: var(--blackColor);

    & .MicrositeSideBar__hamburger {
      & .MicrositeSideBar__patty,
      &:before,
      &:after {
        background: var(--blackColor);
      }
    }

    & .MicrositeSideBar__outline {
      @mixin circleOutline var(--blackColor);
    }
  }

  &.MicrositeSideBar--dark {
    color: var(--whiteColor);

    & .MicrositeSideBar__hamburger {
      & .MicrositeSideBar__patty,
      &:before,
      &:after {
        background: var(--whiteColor);
      }
    }

    & .MicrositeSideBar__outline {
      @mixin circleOutline;
    }
  }
}

.MicrositeSideBar__menuButtonOuter {
  display: flex;
  height: 100%;
  width: 100%;
  padding-bottom: 24px;

  @media (--laptop) {
    flex-direction: column;
  }

  & .MicrositeSideBar__menuButton {
    @mixin resetButtonStyles;
    margin: 0 auto !important;
    transform: rotate(90deg);
    cursor: pointer;
    width: 25px;
    height: 60px;

    @media (--laptop) {
      transform: rotate(0deg);
      margin: auto !important;
    }

    @media (--desktop4k) {
      width: 36px;
      height: 100px;
    }
  }

  & .MicrositeSideBar__outline.MicrositeSideBar__outline {
    @media (--laptop) {
      display: none;
    }
  }
}
