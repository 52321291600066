@define-mixin formContainerColorVariant $errorFontColor, $backgroundColor, $fontColor,
  $submitButtonVariant, $resetButtonVariant {
  background: $backgroundColor;
  color: $fontColor;

  & a {
    color: $fontColor;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .EPiServerForms .FormChoice--Image .Form__Element__Caption,
  & .EPiServerForms label,
  & .EPiServerForms legend {
    color: $fontColor;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  & .Form__Element__ValidationError,
  & .FormTextbox.ValidationFail label,
  & .FormTextbox.ValidationFail fieldset,
  & .FormSelection.ValidationFail label,
  & .FormSelection.ValidationFail fieldset {
    color: $errorFontColor;
  }

  & .Form__Element.FormSubmitButton {
    @mixin $(submitButtonVariant);
  }

  & .Form__Element.FormResetButton {
    @mixin $(resetButtonVariant);

    background: transparent;
  }
}
