.StoryFact {
  display: flex;
  flex-direction: column;

  & p {
    @media (--laptop) {
      flex: 1 1 auto;
    }
  }

  & .StoryFactsList__iconText {
    @mixin h6Desktop;

    margin-block: 0 var(--spacing-xs);

    @media (--tablet) {
      @mixin h5Desktop;

      margin-block: 0 var(--spacing-xs);
    }
  }

  & .StoryFact__body {
    & p {
      @mixin pSmall;

      @media (--laptop) {
        font-size: 1rem;
        line-height: 2rem;
        margin-bottom: 12px;
      }
    }
  }
}

.StoryFact__button {
  width: fit-content;
}
