.VacancyBlock {
  @mixin wrapper;
}

.VacancyBlock__heading {
  @mixin typographyMobileStrongXXLarge;

  @media (--laptop) {
    @mixin typographyDesktopStrongXXLarge;
  }
}

.VacancyBlock__vacancies {
  margin: var(--spacing-m) 0;

  @media (--mobileOnly) {
    & > ul > li > a {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.VacancyBlock__seeAllBtn {
  margin-bottom: var(--spacing-m);
  padding: 0;

  & > a {
    display: block;
    padding: var(--spacing-xxs) var(--spacing-m);
    color: inherit;
  }

  @media (--mobileOnly) {
    width: 100%;
    text-align: center;
  }
}
