.CallToAction--banner {
  margin: 0 auto;

  & .CallToAction__container {
    @mixin wrapper;

    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    padding: var(--spacing-m) 0;
  }

  & .CallToAction__title {
    margin: 0;
    padding: 0;

    @mixin typographyMobileStrongXLarge;

    @media (--laptop) {
      @mixin typographyDesktopStrongXLarge;
    }
  }

  & .CallToAction__buttons {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
  }

  & .CallToAction__buttons .CallToActionButton {
    &,
    &:first-child,
    &:not(:first-child) {
      /* TODO Unify and update button styling */
      @mixin buttonDefaultSize;
    }
  }

  & .CallToAction__title + .CallToAction__buttons {
    margin-top: 0;
  }

  & .CallToAction__richText {
    margin-bottom: 0;
  }
}
