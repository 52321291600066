.HeroV2 {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  margin-bottom: var(--spacing-xxxl);

  @media (--tablet) {
    margin-bottom: var(--spacing-xxxxl);
  }

  & .HeroV2__container,
  & .HeroV2__picture {
    max-height: var(--hero-v2-max-height);
    height: calc(100vh - var(--mobile-header-height));
    width: 100%;
  }

  & .HeroV2__picture {
    & img {
      max-height: var(--hero-v2-max-height);
      height: calc(100vh - var(--mobile-header-height));
      width: 100%;
    }
  }
}

.HeroV2__photoCreditContainer {
  @mixin wrapper;

  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: var(--spacing-xs);

  @media (--tablet) {
    justify-content: flex-end;
  }
}

.HeroV2__photoCreditText {
  @mixin typographyMobileCapsDefault;

  transition: all 0.3s;
  transition-property: max-width, margin-left;
  margin-left: 0;
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;

  @media (--tablet) {
    @mixin typographyDesktopCapsDefault;
  }
}

.HeroV2__photoCredit {
  display: flex;
  align-items: center;
  padding: var(--spacing-xxs);
  border-radius: 50px;
  color: #bdbcbc;
  /* stylelint-disable-next-line color-function-notation */
  background: rgba(255, 255, 255, 0.1);

  @media (--tablet) {
    &:hover {
      .HeroV2__photoCreditText {
        max-width: 400px;
        margin-left: 11px;
      }
    }
  }
}

@media (--mobileOnly) {
  .photoCredit:checked + .HeroV2__photoCreditText {
    max-width: 400px;
    margin-left: 11px;
  }
}

