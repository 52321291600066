.TableContent__expandableSection {
  transition: max-height 0.3s;
  overflow: clip;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 65px;
    width: 100%;
    background: linear-gradient(0deg, var(--base-grey-white) 20%, rgba(248, 248, 248, 0.6) 66%, rgba(248, 248, 248, 0.2) 90%, rgba(248, 248, 248, 0.00) 100%);
    opacity: 1;
    transition: opacity 0.5s;
    display: none;
    pointer-events: none;
  }

  &.TableContent__expandableSection--expanded {
    &::after {
      opacity: 0;
    }
  }

  &.TableContent__expandableSection--expandable {
    &::after {
      display: block;
    }
  }

  @media (--reduced-motion) {
    transition: none;
  }
}

.TableContent__expandBtn {
  align-self: center;

  & svg {
    transition: transform 0.2s;
    will-change: transform;
    transform: translateY(1px);
  }

  &.TableContent__expandBtn--expanded {
    & svg {
      transform: rotate(180deg);
    }
  }

  &.TableContent__expandBtn--hidden {
    display: none !important;
  }
}