.SearchInput__inner {
  height: 100px;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: flex-start;

  @media (--tablet) {
    height: auto;
    padding: 0;
    justify-content: center;
    width: 80%;
    display: block;
    margin: 0 auto;
  }

  @media (--laptop) {
    width: 100%;
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    background: svg-load('Icons/Search.svg', stroke: var(--blackColor)) no-repeat;
    background-size: 18px;
    background-position: center;
    z-index: 1;
    transition: opacity 0.3s ease;
    pointer-events: none;
    width: 80px;
    height: 100%;
    top: 0;
    left: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    @media (--tablet) {
      right: 0;
      left: initial;
      opacity: 0.3;
      background-position: center;
      background-size: 30px;
      width: 100px;
    }
  }

  &:hover:before {
    @media (--tablet) {
      opacity: 0.4;
    }
  }
}

_:-ms-fullscreen, :root .SearchInput__inner:before {
  @media (--tablet) {
    left: auto;
  }
}

.SearchInput__form {
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent;
  color: inherit;
}

.SearchInput__button {
  @mixin resetButtonStyles;
  width: 60px;
  height: 40px;
  position: absolute;
  top: 30px;
  left: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;

  @media (--tablet) {
    right: 0;
    left: initial;
    width: 100px;
    top: 0;
    height: 100%;
  }
}

_:-ms-fullscreen, :root .SearchInput__button {
  @media (--tablet) {
    left: auto;
  }
}