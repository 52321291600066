.RichCampaignPage {
  width: 100%;
  
  & .Hero.Hero--fullHeight {
    position: absolute;
    height: 100%;
    margin-bottom: 0;
    
    & .Hero__container {
      height: 100%;
      max-height: initial;
    }

    & .Hero__media {
      height: 100%;
      max-height: initial;
    }
  }
}