.Counter {
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 1.5rem 0;

  @media (--tablet) {
    padding: 3rem 0;
  }

  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 0;

    @media (--mobileOnly) {
      display: none;
    }
  }
}

.CounterList ul li:last-child {
  & .Counter::before {
    display: none;
  }
}

.Counter__text {
  &,
  & a {
    color: transparent;
    transition: color 0.3s ease;
    font-weight: 400;
  }

  flex-grow: 1;
  max-width: 210px;
}

.Counter__value {
  font-size: 2.625rem;
  font-weight: 500;

  @media (--tablet) {
    font-size: 3.5rem;
    line-height: 1.3;
  }

  @media (--laptop) {
    font-size: 5.75rem;
  }
}
