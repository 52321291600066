.StepByStep__content {
  position: relative;
  display: grid;
  grid-template: repeat(2, auto) / 1fr;

  @media (--tablet) {
    grid-template: auto / var(--spacing-s) 1fr var(--spacing-xxxxxxxl) 1fr;
  }

  @media (--laptop) {
    grid-template: auto / minmax(98px, auto) 1fr calc(var(--spacing-xxxxxxxl) + var(--spacing-xxxs) + var(--spacing-xxs)) 1fr;
  }

  & .Section__title {
    margin: 0 0 1.5rem;
  }
}

.StepByStep__text {
  width: 100%;

  & > ul,
  & > ol {
    list-style-type: disc;
  }

  & > p {
    & > a {
      color: var(--brandColor);
    }
  }

  & a:hover {
    text-decoration: underline;
  }
}

.StepByStep__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;

  & .StepByStep__text:first-of-type {
    & h2,
    & h3,
    & h4 {
      margin-top: 0.5rem;
    }
  }
}

.StepByStep__list--images {
    grid-row: 2/3;

    @media (--tablet) {
        grid-row: 1/2;
        grid-column: 2/3;
    }
}

.StepByStep__list--text {
    grid-row: 1/2;

    @media (--tablet) {
        grid-row: 1/2;
        grid-column: 4/5;
    }
}

.StepByStep__media {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (--tablet) {
    margin-bottom: 2rem;
  }
  @media (--laptop) {
    margin-bottom: 3rem;
  }

  & img {
    width: 100%;
  }
}
