@supports (-ms-ime-align:auto) {
  .StoryPageSection:first-child .StoryTextContent--inherited.StoryTextContent--themeLight .StoryTextContent__background .StoryTextContent__blur {
    display: none;
  }
}

_:-ms-fullscreen, :root .StoryPageSection:first-child {
  & .StoryTextContent--inherited.StoryTextContent--themeLight .StoryTextContent__background .StoryTextContent__blur {
    display: none;
  }
}