.FastFactList--Circle .FastFact {
  border-radius: 50%;
  text-align: center;
  background: color(var(--kog_blue_tint_5) a(50%));

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  & .FastFact__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    inset: 0;
    border-radius: 50%;
  }

  & .FastFact__icon {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--tablet) {
      width: 90px;
      height: 90px;
    }

    @media (--laptop) {
      width: 120px;
      height: 120px;
    }
  }

  & .FastFact__icon svg {
    max-width: 90%;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  & .FastFact__link {
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background: color(var(--kog_blue_tint_5) a(30%));
    }
  }

  & .FastFact__heading {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 300;
    text-transform: none;

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 18px;
    }

    @media (--laptop) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 24px;
    }
  }

  & .FastFact__text {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 24px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 18px;
      max-height: 30px;
    }

    @media (--laptop) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 24px;
      max-height: 40px;
    }
  }
}
