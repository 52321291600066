.ShareModal {
  position: fixed;
  inset: 0;
  background-color: color(var(--black-1000) a(70%));
}

.ShareModal__modal {
  background-color: var(--white-0);
  border-radius: 2px;
  padding: var(--spacing-s);
  max-width: 35rem; /* 560px */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxl);
  outline: none;
  width: calc(100% - (2 * var(--spacing-s)));
}

.ShareModal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ShareModal__title {
  @mixin typographyMobileDisplayLarge;

  max-width: 25rem;
  text-wrap: balance;
  color: var(--base-grey-black-100);

  @media (--mobileSmall) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.ShareModal__close {
  @mixin resetButtonStylesNoOverride;

  cursor: pointer;
  font-size: var(--spacing-s);
  color: var(--stroke-stroke-primary);

  @mixin focusVisible var(--outlineColorSecondary);
}

.ShareModal__copyLinkText {
  @mixin typographyDesktopStrongSmall;

  color: var(--base-grey-black-100);
  margin-block: 0 var(--spacing-xs);
}

.ShareModal__copyLinkInputWithButton {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);

  @media (--mobileSmall) {
    flex-direction: row;
    gap: 0;
  }

  & .ShareModal__copyLinkInput {
    @mixin typographyDesktopDefaultMedium;

    flex: 1;
    border: 1px solid var(--base-grey-dark-80);
    color: var(--base-grey-black-100);
    min-height: 2.5rem;
    padding-inline: var(--spacing-xs);
  }

  & .ShareModal__copyLinkButton {
    @mixin buttonFullWidth;

    width: auto;
    min-width: 120px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
