/* stylelint-disable declaration-no-important */
/* stylelint-disable declaration-property-unit-allowed-list */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-id-pattern */
#onetrust-consent-sdk {
  & #onetrust-banner-sdk {
    & a[href] {
      text-decoration: underline;
      font-weight: 400;
    }

    & #onetrust-close-btn-container {
      right: 12px;
      top: 4px;
      transform: scale(0.8);
      position: absolute;

      @media (--mobileSmall) {
        right: 8px;
        top: 8px;
      }

      @media (--laptop) {
        right: 80px;
        top: 32px;
        transform: none;
      }

      & .onetrust-close-btn-ui {
        width: 40px;
        height: 40px;
        background: var(--blackColor);
        border-radius: 20px;
        top: 0;
        right: 0;

        &:hover {
          background: var(--blackLighterColor);
          opacity: 1;
          color: var(--whiteColor);
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 2px;
          height: 26px;
          border-radius: 15px;
          top: 50%;
          bottom: 50%;
          background: var(--whiteColor);
        }

        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    & .ot-sdk-container {
      padding: 16px;

      @media (--laptop) {
        padding: 32px 80px 40px;
      }

      & .ot-sdk-row {
        & #onetrust-group-container {
          margin: 0;
          width: calc(100% - 38px);
          float: none;
          display: block;

          @media (--laptop) {
            width: calc(100% - 80px);
          }

          & #onetrust-policy {
            margin: 0 0 4px;
            min-height: 68px;

            & #onetrust-policy-text {
              font-weight: 300;
              font-size: 14px;
              line-height: 1.5;
              margin: 0;
              color: var(--kog_blue_tint_6) !important;

              @media (--tablet) {
                font-size: 16px;
              }
            }
          }
        }

        & #onetrust-button-group-parent {
          padding: 0;
          position: relative;
          float: none;
          width: auto;
          left: auto;
          margin: 12px 0 0;
          display: block;
          text-align: center;
          transform: none;

          @media (--laptop) {
            margin: 0;
          }

          & #onetrust-button-group {
            width: auto;
            text-align: center;
            margin: 0;

            @media (--mobileSmall) {
              display: flex;
              justify-content: center;
            }

            @media (--tablet) {
              justify-content: flex-end;
            }

            & button {
              font-weight: 400;
              font-size: 13px;
              line-height: 1.67;
              text-transform: uppercase;
              letter-spacing: 0;
              width: 167px;
              height: 40px;
              padding: 0;
              border-radius: 0;
              margin: 0 auto 8px;
              display: block;
              float: none;
              white-space: nowrap;
              min-width: min-content;

              @media (--mobileSmall) {
                flex: 1;
                max-width: 167px;
                float: left;
                height: 44px;
                margin: 0;
                margin: 0 8px 0 0;
              }

              @media (--tablet) {
                font-size: 15px;
                margin: 0 16px 0 0;
              }

              @media (--laptop) {
                height: 48px;
              }

              &:last-child {
                margin: 0 auto;

                @media (--mobileSmall) {
                  margin: 0;
                }
              }

              &:not(#onetrust-pc-btn-handler):hover {
                opacity: 1;
                background: var(--cookie-banner-primary-button-hover);
                border-color: var(--cookie-banner-primary-button-hover);
                color: var(--kog_blue);
              }

              &#onetrust-pc-btn-handler {
                color: var(--kog_blue_tint_6) !important;
                background: transparent;
                border: 2px solid var(--kog_blue_tint_6);

                @media (--mobileSmall) {
                  float: left;
                }

                @media (--laptop) {
                  margin: 0 16px 0 0;
                }

                &:hover {
                  color: var(--cookie-banner-primary-button-hover);
                  border: 2px solid var(--cookie-banner-primary-button-hover);
                  opacity: 1;
                }
              }

              &#onetrust-reject-all-handler {
                @media (--laptop) {
                  margin: 0 16px 0 0;
                }
              }

              &#onetrust-accept-btn-handler {
                @media (--laptop) {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
