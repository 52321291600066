.RectangularTeaser {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    @media (--tablet) {
        flex-flow: row nowrap;
    }

    & .RectangularTeaser__boxLink { 
      text-decoration: underline;
      color: var(--whiteColor);
    }
  
  & a {
    color: var(--whiteColor);
  }  
  
  & a:visited {
    color: var(--whiteColor);
  }
}

.RectangularTeaser__link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    color: var(--whiteColor);
}

.RectangularTeaser__text {
    width: 100%;

    @media (--tablet) {
        width: 360px;
    }
}

.RectangularTeaser__box {
    color: var(--whiteColor);
    padding: 24px 24px 120px 24px;
    min-height: 200px;
    position: relative;
    max-width: 100%;
    background: svg-load('Common/Background.svg', fill: var(--brandColor)) no-repeat;
    background-position: bottom 0 right 0;

    @media (--tablet) {
        max-width: 360px;
        padding: 24px 24px 90px 24px;
        min-height: 327px;
    }
}

.RectangularTeaser__boxTitle {
    font-size: 24px;
    margin: 0 0 0.4em;

    @media (--tablet) {
        font-size: 32px;
    }
}

.RectangularTeaser__boxLink {
    position: absolute;
    bottom: 40px;
    padding-left: 15px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 20px;
    max-width: 65vw;
    line-height: 1.2;
    color: var(--whiteColor);

    @media (--tablet) {
        max-width: initial;
    }
}

    .RectangularTeaser__boxLink::before {
        content: "";
        position: absolute;
        top: calc(50% - 7px);
        left: -2px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: svg-load('Icons/ArrowRight.svg', fill: var(--whiteColor)) no-repeat;
        background-size: contain;
    }