.RichCampaignPageSlide {
  height: 100vh;
  background: var(--whiteColor);

  & section {
    height: 100%;
  }

  @media(--laptop) {
    &.SlidesNavigation__slide {
      height: 100vh;
    }

    &.SlidesNavigation__slide--up {
      transform: translateY(-100vh);
    }

    &.SlidesNavigation__slide--current {
      transform: none;
    }
  }
}
