.ArticlePage {
  box-shadow: 0 -2px 0 0 var(--whiteColor);

  & .ArticlePage__aside {
    order: 0;
  }
}

.ArticlePage__body {
  display: flex;
  flex-flow: column;
  position: relative;

  @mixin clearfix();

  @media (--tablet) {
    display: block;
  }

  & .CallToAction {
    margin-bottom: 3rem;

    @media (--tablet) {
      margin-bottom: 8rem;
    }
  }
}

.ArticlePage__aside {
  position: relative;
  margin: 0 15px;

  @media (--tablet) {
    margin: 0;
    right: 15px;
  }

  @media (min-width: 1025px) {
    float: right;
    max-width: 370px;
  }

  @media (min-width: 1145px) {
    position: relative;
    right: calc(100% / 2 - var(--wrapperWidth) / 2);
  }

  & > div {
    margin-bottom: 2rem;

    @media (--laptop) {
      margin-bottom: 3rem;
    }
  }

  & .FactBox {
    @media (--tablet) {
      max-width: 360px;
      min-height: 330px;
      float: right;
      margin: 0 0 1.5rem 2rem;
    }
  }
}