.StoriesLandingPage {
  & .StoriesHero + .StoriesSearch {
    margin-top: -4.5rem;
  }

  & .ContentAreaSection.FeaturedStories {
    & .wrapper {
      width: calc(100% - 48px);
    }
  }

  & .FeaturedStories .cardsList__3col--featured {
    
    & li {
      @media (--laptop) {
        max-width: 30%;
        margin-right: 4.9%;
      }
    }

    & > li:nth-child(3n) {
      @media (--laptop) {
        margin-right: 0;
      }
    }
    
    & > li:last-child {
      margin-right: 0;
    }
  }

  & .StoriesCategoryCard {
    &:hover {
      & h3 {
        text-decoration: underline;
      }
      
      & .StoriesCategoryCard__icon {
        transform: none;
      }

      & .StoriesCategoryCard__link:before {
        display: none;
      }
    }
  }
  & .ContentAreaSection {

    & .ContentList.RequireMargins {
      margin: 1.5rem auto;

      & .Section__title {
        margin: 0 0 1.5rem;
      }

      @media(--tablet) {
        margin: 2.5rem 0 2.5rem;

        & .Section__title {
          margin: 0 0 2.5rem;
        }
      }
    }

    & .wrapper.RequireMargins {

      margin: 1.5rem auto;

      & h1, & h2 {
        margin: 0 0 1.5rem;
      }

      @media(--tablet) {
        margin: 2.5rem auto;

        & h1, & h2 {
          margin: 0 0 2.5rem;
        }
      }
    }
  }
}
