.RichCampaignHero {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (--laptop) {
    min-height: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color(var(--kog_blue) a(50%));
    z-index: 1;
  }

  & .SlidesNavigation__innerScroll {
    @media (--laptop) {
      max-height: 20vh;
    }
  }
}

.RichCampaignHero__content {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
}

.RichCampaignHero__inner {
  @mixin richCampaignWrapper;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.RichCampaignHero__logo {
  width: 70px;
  height: 90px;
  background: svg-load("Common/logo-dark.svg", fill: var(--whiteColor)) no-repeat center;
  background-size: contain;

  @media (--tablet) {
    width: 80px;
    height: 100px;
  }
  @media (--laptop) {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
}

.RichCampaignHero.SlidesNavigation__slide .RichCampaignHero__logo {
  @media (--laptop) {
    transition: none;
  }
}

.RichCampaignHero__text {
  line-height: 1.3;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-top: 50px;
  color: inherit;

  @media (--tablet) {
    max-width: 80%;
  }

  @media (--desktopHD) {
    padding-top: 100px;
  }
}

.RichCampaignHero__smallTitle {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 2px;
  color: inherit;
  margin: 0;

  @media (--tablet) {
    @mixin dynamicFontSize 20, 30;
  }
}

.RichCampaignHero__heading {
  display: flex;
  align-items: center;
}

.RichCampaignHero__title {
  flex: 1;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 42px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 3.33px;
  color: inherit;

  @media (--tablet) {
    @mixin dynamicFontSize 42, 100;
  }
}

.RichCampaignHero__intro {
  margin: 0;

  & p {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 18px;
    font-weight: 300;

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 20px;
    }

    @media (--laptop) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 24px;
    }
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & strong {
    font-weight: 400;
  }
}
