.Downloads__itemImage {
  width: 100%;
  position: relative;
  padding-top: 65%;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  overflow: hidden;

  @media (--tablet) {
    margin-bottom: 1.37rem;
    margin-top: 2rem;
  }

  & picture {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & img {
    min-height: 100%;
    width: 100%;
    transition: transform .35s ease-in-out;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    z-index: 2;
    display: block;
  }
}

.Downloads__itemSize {
  font-style: italic;
  margin-bottom: 0.7rem;

  @media (--tablet) {
    margin-bottom: initial;
  }
}