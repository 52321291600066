.OfficeLocationsPage--listWithMap {
  margin-block: var(--spacing-m);

  @media (--tablet) {
    margin-block: var(--spacing-xxl);
  }

  & .OfficeLocationsPage__wrapper {
    @mixin wrapper;
  }
}
