.Catalog {
  & .Catalog__header {
    padding-block: var(--spacing-m);
    color: var(--base-grey-black-100);

    @media (--tablet) {
      padding-block: var(--spacing-xl) var(--spacing-xxl);
    }

    &.Catalog__header--grey {
      background-color: var(--base-grey-light-80);
    }
  }

  & .Catalog__title {
    @mixin typographyMobileDisplayLarge;

    margin-block: 0 var(--spacing-xs);

    @media (--tablet) {
      @mixin typographyDesktopDisplayLarge;

      margin-block: 0 var(--spacing-xs);
    }
  }

  & .Catalog__introduction {
    & p {
      @mixin typographyMobileDefaultXLarge;

      @media (--tablet) {
        @mixin typographyDesktopDefaultXLarge;
      }

      &:not(:last-child) {
        margin-block: 0 var(--spacing-xs);
      }
    }
  }
}
