.FacetFilters {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
  border: none;
  padding: 0;
  margin: 0;

  @media (--tablet) {
    margin-bottom: 0;
  }
}