.StoryFactsList {
  position: relative;
  padding: var(--spacing-m) 0;
  overflow: hidden;
  color: var(--textColor);
  display: flex;
  align-items: center;

  & ul.cardsList__4col--featured > li {
    @media (--tablet) {
      margin-bottom: var(--spacing-s);
    }

    @media (--laptop) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (--tablet) {
    padding: var(--spacing-xl) 0;
  }

  & .LinkBlock__icon {
    @media (--laptop) {
      margin: 4rem 0 1.5rem;
    }
  }
}

.StoryFactsList__wrapper {
  position: relative;
  @mixin storyContentWrapper;
}

.StoryFactsList__background {
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--whiteColor);
  }
}

.StoryFactsList__header {
  @mixin h5Desktop;

  margin-block: 0 var(--spacing-m);

  @media(--tablet) {
    @mixin h4Desktop;

    margin-block: 0 var(--spacing-l);
  }

  &:last-child {
    margin-block: 0;
  }
}

.StoryFactsList__title {
  @mixin h8HighDesktop;

  margin-block: 0 var(--spacing-xxs);

  @media (--tablet) {
    @mixin h6Desktop;

    margin-block: 0 var(--spacing-xxs);
  }
}