.CoursePortalPage {
    & .Intro {
        display: flex;
        flex-flow: column-reverse;
        
        @media(--tablet) {
            display: block;
        }
    }    
    
    & .ContentAreaSection {
        background-color: color(var(--kog_blue_tint_5) a(50%));
    }

    & .RichtextArea {
        & ul {
            list-style-type: disc;
        }
    }
}

.CourseList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.CourseList__item {
    margin-bottom: 1rem;
    
    @media (--tablet) {
        margin-bottom: 1.9rem;
    }
    
    &:last-child {
        margin-bottom: 0;
    }
}

.CourseList__item-description {
    padding-top: 1.5rem;

    @media (--tablet) {
        padding-top: 3.125rem;
    }
}

.CourseList__item-courses {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (--tablet) {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 1.25rem;
    }
}

.CourseList__itemHeading {
    margin: 0 0 0.5rem 0;

    @media (--tablet) {
        margin: 0 0 1rem 0;
    }
}

.ContentBar__list-item {
    margin-bottom: 1rem;

    @media (--tablet) {
        width: calc(50% - 0.95rem);
        margin-bottom: 1.9rem;
    }

    &:nth-child(odd) {
        @media (--tablet) {
            margin-right: 1.9rem;
        }
    }

    &:last-child {
        margin-bottom: 1.9rem;
    }
}