.react-autosuggest__suggestions-container--open {
    font-size: 16px;
    top: 80px;

    @media (--tablet) {
        top: 150px;
    }
}

.react-autosuggest__suggestions-list {
    font-size: 16px;
}

.react-autosuggest__suggestion {
    border-bottom: 1px solid var(--brandColor);
}

.react-autosuggest__suggestion--highlighted {
    background-color: var(--brandColor);
}