.CatalogList {
  padding-block: var(--spacing-m) var(--spacing-l);
}

.CatalogList__list {
  --columns: 1;

  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: var(--spacing-xs);

  @media (--tablet) {
    --columns: 2;
  }

  @media (--laptop) {
    --columns: 3;

    gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  }
}

.CatalogList__listItem {
  display: block;
}
