@define-mixin contentCardDarkTheme {
  & .ContentCard__text {
    color: var(--blue-100);
  }

  & .ContentCard__arrow {
    @mixin linkWithArrowSmall var(--blue-100);
  }
}

.Page--dark {
  @mixin contentCardDarkTheme;
}

@media (--dark) {
  .Page:not(.Page--light) {
    @mixin contentCardDarkTheme;
  }
}

.dark .ContentCard a {
  color: var(--whiteColor);
}
