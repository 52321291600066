@define-mixin tileColorVariant $backgroundColor, $iconColor: var(--base-grey-white) {
  &:not(.Tile--withImage) {
    background-color: $backgroundColor;
    color: var(--base-grey-white);
  }

  & .Tile__image {
    background-color: $backgroundColor;

    &.Tile__image--icon {
      color: $iconColor;
    }
  }
}

.Tile--teal {
  @mixin tileColorVariant var(--accent-teal-accent-darker);
}

.Tile--blue {
  @mixin tileColorVariant var(--accent-blue-accent-darker);
}

.Tile--green {
  @mixin tileColorVariant var(--accent-green-accent-darker);
}

.Tile--success {
  @mixin tileColorVariant var(--success-success-darker);
}

.Tile--alert {
  @mixin tileColorVariant var(--alert-alert-darker);
}

.Tile--error {
  @mixin tileColorVariant var(--error-error-darker);
}

.Tile--brown {
  @mixin tileColorVariant var(--base-sand-brown-100);
}

.Tile--grey {
  @mixin tileColorVariant var(--base-grey-dark-100);
}

.Tile--alertLighter {
  @mixin tileColorVariant var(--alert-alert-lighter), var(--alert-alert-dark);
}

.Tile--accentGreen {
  @mixin tileColorVariant var(--accent-green-accent-lighter), var(--accent-green-accent-dark);
}

.Tile--accentBlue {
  @mixin tileColorVariant var(--accent-blue-accent-lighter), var(--accent-blue-accent-dark);
}

.Tile--accentTeal {
  @mixin tileColorVariant var(--accent-teal-accent-lighter), var(--accent-teal-accent-dark);
}
