.Modal__close {
  @mixin resetButtonStylesNoOverride;

  cursor: pointer;
  font-size: var(--spacing-s);
  display: flex;
  align-items: center;
  z-index: var(--z-index-modal-close);
  gap: var(--spacing-xxxs);
  transition: opacity var(--transition-duration);

  @mixin focusVisible var(--outlineColorSecondary);
}

.Modal__closeLabel {
  @mixin typographyDesktopDefaultSmall;

  text-transform: uppercase;
}

.Modal__dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  width: calc(100% - (2 * var(--wrapperPadding)));
  height: calc(100% - (2 * var(--wrapperPadding)));
  max-width: var(--wrapperWidth);
  max-height: max(640px, 80%);
  z-index: var(--z-index-modal-dialog);

  @media (--laptopMedium) {
    display: block;
  }

  & .Modal__close {
    @media (--laptopMedium) {
      z-index: var(--z-index-modal-close);
    }
  }

  & .Modal__closeLabel {
    display: none;

    @media (--laptopMedium) {
      display: block;
    }
  }
}

.Modal__body {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
  background-color: var(--base-grey-light-100);
  border-radius: var(--border-radius);
  overflow: auto;
  height: 100%;
  transition:
    transform var(--transition-duration),
    opacity var(--transition-duration);
  transform: scale(0.9);
  opacity: 0;

  @media (--reduced-motion) {
    transition: opacity var(--transition-duration);
    transform: none;
  }

  & .Modal__closeLabel {
    display: none;
  }
}

.Modal {
  --opacity: 60%;
  --border-radius: 8px;
  --header-max-height: auto;

  @media (--tablet) {
    --header-max-height: 18.75rem;
  }

  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal);

  &::before {
    content: "";
    background-color: var(--base-grey-black-100);
    opacity: 0;
    z-index: var(--z-index-modal-overlay);
    position: absolute;
    inset: 0;
    transition: opacity var(--transition-duration);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.ReactModal__Overlay--after-open {
    &::before {
      opacity: var(--opacity);
    }

    & .Modal__close {
      opacity: 1;
    }

    & .Modal__body {
      transform: scale(1);
      opacity: 1;

      @media (--reduced-motion) {
        transform: none;
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.ReactModal__Overlay--before-close {
    &::before {
      opacity: 0;
    }

    & .Modal__close {
      opacity: 0;
    }

    & .Modal__body {
      transform: scale(0.9);
      opacity: 0;

      @media (--reduced-motion) {
        transform: none;
      }
    }
  }
}

.Modal__header {
  max-height: var(--header-max-height);
  grid-area: header;
  border-bottom: 1px solid var(--base-sand-medium-100);
}

.Modal__headerPicture {
  & img {
    width: 100%;
  }
}

.Modal__headerInner {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-xxs);
  padding: var(--spacing-s);
}

.Modal__lead {
  @mixin typographyMobileStrongSmall;

  @media (--tablet) {
    @mixin typographyDesktopStrongSmall;
  }
}

.Modal__title {
  @mixin typographyMobileDisplayLarge;

  @media (--tablet) {
    @mixin typographyDesktopStrongXLarge;
  }
}

.Modal__content {
  grid-area: content;
  padding: var(--spacing-s);
  overflow: auto;
}
