.SidePanelModal {
  --border-radius: 0;
}

.SidePanelModal__body {
  grid-column: body;
  grid-row: body;
  pointer-events: all;
  transform: none;
  opacity: 1;
  transition: none;
}

.SidePanelModal__dialog {
  position: relative;
  display: grid;
  grid-template:
    "close body" 7rem
    ".     body" calc(100% - 7rem) / 3.5rem 85%;
  justify-content: end;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: 100%;
  inset: 0;
  transform: translateX(100%);
  transition: transform var(--transition-duration);
  pointer-events: none;
  gap: 0;

  /* stylelint-disable-next-line selector-class-pattern */
  &.ReactModal__Content--after-open {
    transform: translateX(0%);

    & .SidePanelModal__body {
      transform: none;
      opacity: 1;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.ReactModal__Content--before-close {
    transform: translateX(100%);

    & .SidePanelModal__body {
      transform: none;
      opacity: 1;
    }
  }
}

.SidePanelModal__close {
  grid-column: close;
  grid-row: close;
  place-self: center;
  pointer-events: all;
  position: static;
  transform: none;
  margin: 0;
  gap: 0;

  & .Modal__closeLabel {
    @mixin visuallyHidden;
  }
}

.SidePanelModal__lead {
  @mixin typographyMobileStrongSmall;
}

.SidePanelModal__title {
  @mixin typographyMobileDisplayLarge;
}
