@supports (-webkit-backdrop-filter: blur(4px)) {
  .StoriesHero__mediaBlur {
    display: none;
  }
}

_:-ms-fullscreen, :root .StoriesHero__mediaInner,
_:-ms-fullscreen, :root .StoriesHero__mediaBlur {
  overflow: hidden;
}

@supports (-ms-ime-align:auto) {
  .StoriesHero__mediaInner,
  .StoriesHero__mediaBlur {
    overflow: hidden;
  }
}

_:-ms-fullscreen, :root .StoriesHero__titles {
   &::before {
    @media (--laptop) {
      left: auto;
    }
  }
}