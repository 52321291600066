.CounterList {
  & .Counter {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    align-items: center;
    height: 100%;
    position: relative;
    padding: 0;
    gap: var(--spacing-xxs);

    &::before {
      content: "";
      display: block;
      height: calc(100% - 12px);
      width: 1px;
      position: absolute;
      right: 0;
      top: 6px;
      background: var(--blackColor);

      @media (--mobileOnly) {
        display: none;
      }

      @media (--laptop) {
        height: calc(100% - 18px);
      }
    }

    @media (--tablet) {
      gap: var(--spacing-xs);
      text-align: left;
      align-items: flex-start;
    }
  }

  & .CounterList__itemsList {
    & li {
      @media (--tablet) {
        max-width: 50%;
      }
    }

    & li:last-child .Counter::before {
      display: none;
    }

    @media (--tablet) {
      & li .Counter {
        padding: 0 var(--spacing-xs);
      }

      & li:first-child .Counter {
        padding-left: 0;
      }

      & li:last-child .Counter {
        padding-right: 0;
      }
    }
  }

  & .Counter__text {
    @mixin typographyMobileDefaultLarge;

    color: transparent;
    transition: color 0.3s ease;
    flex-grow: 1;
    max-width: initial;

    & a {
      text-decoration: underline;
      color: currentColor;
    }

    @media (--laptop) {
      @mixin typographyDesktopDefaultLarge;
    }
  }

  & .Counter__value {
    @mixin typographyMobileDisplayLarge;

    @media (--laptop) {
      @mixin typographyDesktopDisplayLarge;
    }
  }
}
