.FastFactList--SimpleWithGrid {
  position: relative;

  @media (--mobileAndTablet) {
    /* stylelint-disable-next-line declaration-no-important */
    margin: var(--spacing-s) auto !important;
  }
}

.FastFactList__items {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  &:not(.FastFactList__items--grid) {
    @neat-outer-container;

    & li > div {
      flex-grow: 1;
    }

    & > li {
      margin-bottom: 1.9rem;
      @neat-span-columns 12;

      display: flex;
      flex-flow: column;

      @media (--mobileOnly) {
        @neat-span-columns 6;
        @neat-omega 2n;

        max-width: 48.6%;
        display: flex;
      }

      @media (--tabletOnly) {
        @neat-span-columns 3;
        @neat-omega 4n;

        max-width: 23%;
        display: flex;
      }

      @media (--laptop) {
        @neat-span-columns 3;
        @neat-omega 4n;

        max-width: 23%;
        display: flex;
      }
    }
  }

  &.FastFactList__items--grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
    justify-items: center;

    & > li {
      width: 100%;
    }

    @media (--tablet) {
      justify-items: initial;

      & > li:nth-child(even) {
        justify-self: end;
      }
    }
  }
}

.FastFactList__background {
  position: absolute;
  bottom: calc(-1 * var(--spacing-xxl));
  left: 0;
  width: 676px;
  max-width: 50%;
  height: 474px;
  max-height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: none;

  @media (--laptop) {
    display: initial;
  }
}

.FastFactList__contentWithGrid {
  @mixin wrapper 953px;

  position: relative;
  z-index: 1;
  display: grid;
  justify-items: center;
  gap: var(--spacing-m);

  @media (--laptop) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: initial;
    gap: 0;
  }

  & .Section__title {
    @mixin typographyMobileStrongXXLarge;

    max-width: 550px;
    text-align: center;

    @media (--mobileSmall) {
      @mixin typographyMobileDisplayLarge;
    }

    @media (--laptop) {
      @mixin typographyDesktopDisplayLarge;

      width: 355px;
      min-width: 355px;
      text-align: left;
    }
  }

  & .FastFact__inner {
    display: none;

    &.FastFact__inner--inGrid {
      display: flex;
    }
  }
}

.FastFactList__itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  width: 100%;

  @media (--laptop) {
    width: initial;
    grid-column: 2 / -1;
  }
}

.FastFactList__ctaButton {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryBlueOutlined;

  @media (--mobileAndTablet) {
    width: 100%;
    text-align: center;
  }

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:active,
  &:focus-visible {
    background: transparent;
  }
}
