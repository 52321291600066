@define-mixin buttonWithIcon $icon: arrowRightSimple, $color: white {
  display: flex;
  text-transform: uppercase;
  font-size: 18px;
  position: relative;
  padding-left: 35px;
  align-items: center;
  height: 30px;
  
  &::after {
    left: 0;
    top: 0;
  }
  
  &:hover {
    text-decoration: underline;

    &:after {
      animation: move 0.8s ease infinite;
    }
  }

  @mixin $(icon) $(color);
}