.MicrositeAnimatedCloseButton {
  @mixin resetButtonStyles;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.MicrositeAnimatedCloseButton--dark {
    & .MicrositeAnimatedCloseButton__outline {
      @mixin circleOutline;
    }

    & .MicrositeAnimatedCloseButton__cross {
      & .MicrositeAnimatedCloseButton__crossInner,
      &:before,
      &:after {
        background: var(--whiteColor);
      }
    }
  }

  &.MicrositeAnimatedCloseButton--light {
    & .MicrositeAnimatedCloseButton__outline {
      @mixin circleOutline var(--blackColor);
    }

    & .MicrositeAnimatedCloseButton__cross {
      & .MicrositeAnimatedCloseButton__crossInner,
      &:before,
      &:after {
        background: var(--blackColor);
      }
    }
  }

  & .MicrositeAnimatedCloseButton__cross {
    position: relative;
    display: block;
    width: 40px;
    height: 100%;

    & .MicrositeAnimatedCloseButton__crossInner,
    &:before,
    &:after {
      transition: all .5s ease-in-out;
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      top: 50%;
    }

    & .MicrositeAnimatedCloseButton__crossInner {
      opacity: 0;
    }

    &:before {
      right: 6px;
      transform: translateX(6px) rotate(15deg);
    }

    &:after {
      left: 8px;
      transform: translateX(-9px) rotate(-15deg);
    }
  }
}
