@keyframes accordionArrowMoveDown {
  0%,
  100% {
    transform: rotate(90deg) translateX(0);
  }

  50% {
    transform: rotate(90deg) translateX(5px);
  }
}

@keyframes accordionArrowMoveDownOnHover {
  0%,
  100% {
    transform: rotate(-90deg) translateX(0);
  }

  50% {
    transform: rotate(-90deg) translateX(5px);
  }
}

.LegacyAccordionList {
  & .LegacyAccordionList__accordion {
    width: 100%;
    background: transparent;

    & > .LegacyAccordionList__title {
      display: flex;
      height: 90px;
      background: var(--kog_blue_tint_6);
      padding: 20px 30px;
      margin-bottom: 30px;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 20px;
      transition: all 0.1s ease-in-out;

      & .LegacyAccordionList__arrow {
        display: inline-block;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        transform: rotate(90deg);
        background: transparent svg-load("Icons/ArrowRightSimple.svg") center no-repeat;
        background-size: 100% 100%;
        transition: transform 0.2s ease-in-out;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
      }

      &:hover {
        background: var(--productsAccordionListHover);
        box-shadow: 0 1px 8px 0 color(var(--blackColor) a(10%));
        text-decoration: underline;

        & .LegacyAccordionList__arrow {
          animation-name: accordionArrowMoveDown;
        }
      }
    }

    & .LegacyAccordionList__itemsWrapper {
      display: grid;
      grid-template-rows: 0fr;
      transition: all 0.4s;
      opacity: 0;
      margin: 0 auto;
    }

    & .LegacyAccordionList__items {
      box-sizing: border-box;
      overflow: hidden;
      padding: 0;

      @media (--tablet) {
        max-width: calc(100% - 200px);
      }
    }

    &.LegacyAccordionList__accordion--opened {
      & .LegacyAccordionList__itemsWrapper {
        grid-template-rows: 1fr;
        opacity: 1;
        margin-bottom: 60px;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      & .LegacyAccordionList__arrow {
        transform: rotate(-90deg);
      }

      & .LegacyAccordionList__title {
        &:hover {
          & .LegacyAccordionList__arrow {
            animation-name: accordionArrowMoveDownOnHover;
          }
        }
      }
    }
  }
}
