.RichCampaignNavigation__container {
    background: var(--kog_blue_tint_1);
    margin-bottom: 4.5em;
}

.RichCampaignNavigation__links {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

.RichCampaignNavigation__link {
    display: inline-block;

    @media (--laptop) {
        margin-right: 150px;
    }

    & a {
        font-family: GTPressura;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--whiteColor);
        padding: 1em 15px;
        display: inline-block;

        &.active {
            text-decoration: underline;
        }
    }

    &:hover {
        background: var(--brandColor_dark);
    }
}