.ImageViewerMainImage {
  position: relative;

  @media (--tablet) {
    aspect-ratio: 1040 / 582;
  }
}

.ImageViewerMainImage__container {
  display: none;
  animation: fadeIn 0.3s ease;

  & > picture {
    height: 100%;
    display: block;

    & img {
      width: 100%;
      height: 100%;
      aspect-ratio: 375 / 200;

      @media (--tablet) {
        aspect-ratio: 1040 / 582;
      }
    }
  }

  @media (--tablet) {
    position: absolute;
    inset: 0;
  }

  &.ImageViewerMainImage__container--active {
    display: block;
  }
}

.ImageViewerMainImage__legend {
  padding: var(--spacing-xs) var(--spacing-s);
  width: 100%;
  background-color: #001639;
  color: #fff;

  & p {
    margin: 0;
  }

  @media (--tablet) {
    width: initial;
    max-width: 524px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: var(--z-index-above);
  }
}

.ImageViewerMainImage__legend--xSmall {
  &,
  & p {
    @mixin typographyMobileDefaultSmall;

    @media (--laptop) {
      @mixin typographyDesktopDefaultSmall;
    }
  }
}

.ImageViewerMainImage__legend--small {
  &,
  & p {
    @mixin typographyMobileDefaultSmall;

    @media (--tablet) {
      @mixin typographyMobileDefaultMedium;
    }

    @media (--laptop) {
      @mixin typographyDesktopDefaultMedium;
    }
  }
}

.ImageViewerMainImage__legend--medium {
  &,
  & p {
    @mixin typographyMobileDefaultMedium;

    @media (--laptop) {
      @mixin typographyDesktopDefaultMedium;
    }
  }
}

.ImageViewerMainImage__legend--large {
  &,
  & p {
    @mixin typographyMobileDefaultLarge;

    @media (--laptop) {
      @mixin typographyDesktopDefaultLarge;
    }
  }
}

.ImageViewerMainImage__legend--xLarge {
  &,
  & p {
    @mixin typographyMobileDefaultLarge;

    @media (--laptop) {
      @mixin typographyDesktopDefaultXLarge;
    }
  }
}
