.HeroV2--editMode {
  justify-content: center;
  max-height: 680px;
  
  & .HeroV2__picture {
    top: initial;
    left: initial;
    transform: none;
    display: block;
  }

  & .HeroV2__headings {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}
