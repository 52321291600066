.ProductCertificatesPage__products {
    margin-top: 4rem;
    & ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 7em;
        
        & li {
            padding: 5px 0;
            border-bottom: 1px solid var(--grayColor_tint_3);
            & a {
                display: flex;
                flex-direction: row;
                font-weight: 400;
                font-size: 1.3em;
                width: 100%;
                padding: 15px 0;
                justify-content: space-between;
                align-items: center;
                & span {
                    word-break: break-word;
                }
                & i.icon {
                    background: transparent svg-load('Icons/ArrowRightSimple.svg') no-repeat;
                    width: 35px;
                    height: 30px;
                }
                
                
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}
