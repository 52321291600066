@define-mixin callOutThemeColorVariant $bgColor, $color, $buttonBgColor, $buttonColor, $map, $button {
  & .CallOut__buttons {
    & a {
      @mixin $(button);
    }
  }

  & .CallOut--dark,
  & .CallOut--light {
    color: $color;

    &::before {
      display: none;
    }

    & p,
    & a,
    & span {
      color: $color;

      & a {
        text-decoration: underline;
      }
    }

    &.CallOut--fallbackBackgroundImage {
      background-image: url("/wwwroot/Static/Images/Common/$(map).png");
      background-color: $bgColor;
    }
  }

  & .CallOut.CallOut--withLinks {
    & .CallOut__buttons {
      & a {
        @mixin buttonSquareRotated arrowRightSimple, $buttonBgColor, $buttonColor;

        color: $buttonColor;
        padding-left: 35px;
      }
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin callOutThemeColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color), var(--theme-color-beige-button-primary-bg-color),
    var(--theme-color-beige-button-primary-text-color), world-map-beige,
    buttonPrimaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin callOutThemeColorVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color), var(--theme-color-blue-button-primary-bg-color),
    var(--theme-color-blue-button-primary-text-color), world-map-blue,
    buttonPrimaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin callOutThemeColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color), var(--theme-color-blue-dark-button-primary-bg-color),
    var(--theme-color-blue-dark-button-primary-text-color), world-map-blue-dark,
    buttonPrimaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin callOutThemeColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color), var(--theme-color-brown-button-primary-bg-color),
    var(--theme-color-brown-button-primary-text-color), world-map-brown,
    buttonPrimaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin callOutThemeColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color), var(--theme-color-green-button-primary-bg-color),
    var(--theme-color-green-button-primary-text-color), world-map-green,
    buttonPrimaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin callOutThemeColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color), var(--theme-color-yellow-button-primary-bg-color),
    var(--theme-color-yellow-button-primary-text-color), world-map-yellow,
    buttonPrimaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin callOutThemeColorVariant var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color), var(--theme-color-pink-button-primary-bg-color),
    var(--theme-color-pink-button-primary-text-color), world-map-pink, buttonPrimaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin callOutThemeColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color), var(--theme-color-turquoise-button-primary-bg-color),
    var(--theme-color-turquoise-button-primary-text-color), world-map-turquoise,
    buttonPrimaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin callOutThemeColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color),
    var(--theme-color-turquoise-dark-button-primary-bg-color),
    var(--theme-color-turquoise-dark-button-primary-text-color), world-map-turquoise-dark,
    buttonPrimaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin callOutThemeColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color), var(--theme-color-green-dark-button-primary-bg-color),
    var(--theme-color-green-dark-button-primary-text-color), world-map-green-dark,
    buttonPrimaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin callOutThemeColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color), var(--theme-color-sand-dark-button-primary-bg-color),
    var(--theme-color-sand-dark-button-primary-text-color), world-map-sand-dark,
    buttonPrimaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin callOutThemeColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color),
    var(--theme-color-forest-dark-button-primary-bg-color),
    var(--theme-color-forest-dark-button-primary-text-color), world-map-forest-dark,
    buttonPrimaryThemeColorForestTwo;
}
