.HighlightBlock {
    display: flex;
    align-items: center;
}

.HighlightBlock__icon {
    & svg {
        width: 70px;
        height: 70px;

        @media (--tablet) {
            width: 85px;
            height: 85px;
        }
    }
}

.HighlightBlock__text {
    font-size: 16px;
    margin: 0 0 0 1.5rem;

    @media (--tablet) {
        font-size: 18px;
        margin: 0 0 0 3rem;
    }
    
    @media (--laptop) {
        font-size: 20px;
        line-height: 33px;
    }
}