@define-mixin testimonialThemeColorVariant $backgroundColor, $textColor {
  & .Testimonial {
    background-color: $backgroundColor;

    & .Testimonial__inner,
    & .Testimonial__heading {
      color: $textColor;
    }
  }

  & .Testimonial__background {
    /* stylelint-disable-next-line declaration-no-important */
    background-image: none !important;
  }
}

.ContainerWithBackground--Beige {
  @mixin testimonialThemeColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin testimonialThemeColorVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin testimonialThemeColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin testimonialThemeColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin testimonialThemeColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin testimonialThemeColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color);
}

.ContainerWithBackground--Pink {
  @mixin testimonialThemeColorVariant var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin testimonialThemeColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin testimonialThemeColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin testimonialThemeColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin testimonialThemeColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin testimonialThemeColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin testimonialThemeColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin testimonialThemeColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin testimonialThemeColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin testimonialThemeColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin testimonialThemeColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin testimonialThemeColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin testimonialThemeColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin testimonialThemeColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin testimonialThemeColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color);
}
