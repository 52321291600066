.CounterList {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    @mixin absoluteFullSize;
  }
}

.CounterList__headingsWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  padding: var(--spacing-s) 0 var(--spacing-s);

  @media (--tablet) {
    padding: var(--spacing-l) 0 var(--spacing-s);
  }

  @media (--laptop) {
    padding: calc(var(--spacing-xl) + var(--spacing-xxxs)) 0 var(--spacing-s);
  }
}

.CounterList__title {
  @mixin typographyMobileStrongXXLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopStrongXXLarge;
  }
}

.CounterList__intro {
  &,
  & p {
    @mixin typographyMobileDefaultXLarge;

    & a {
      text-decoration: underline;
    }

    @media (--laptop) {
      @mixin typographyDesktopDefaultXLarge;
    }
  }

  color: var(--base-grey-black-100);

  & p:last-of-type {
    margin-bottom: 0;
  }
}

.CounterList__itemsWrapper {
  padding: var(--spacing-s) 0;

  @media (--tablet) {
    padding: var(--spacing-l) 0;
  }

  @media (--laptop) {
    padding: var(--spacing-xxl) 0;
  }
}

.CounterList__itemsList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);

  @media (--tablet) {
    flex-direction: row;
    gap: 0;

    & li {
      flex: 1;

      & > div {
        height: 100%; /* react render element wrapper */
      }
    }
  }
}
