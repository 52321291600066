/* stylelint-disable no-descending-specificity */
@define-mixin contentListThemeColorVariant $textColor, $buttonVariant {
  & .ContentList,
  & .ContentList--emphasized,
  & .ContentList--emphasized.ContentList--dark,
  & .ContentList--emphasized.ContentList--light,
  & .ContentList--dark,
  & .ContentList--light {
    color: $textColor;

    & .ContentList__Background {
      /* stylelint-disable-next-line declaration-no-important */
      background-image: none !important;

      &::before {
        display: none;
      }
    }

    & .ContentCard {
      & .ContentCard__title,
      & a {
        color: $textColor;
      }

      & .ContentCard__arrow {
        @mixin linkWithArrowSmall $textColor;

        margin: 0;
      }
    }

    & .ContentList__ctaButton {
      @mixin $(buttonVariant);
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin contentListThemeColorVariant var(--theme-color-beige-text-color),
    buttonPrimaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin contentListThemeColorVariant var(--theme-color-blue-text-color),
    buttonPrimaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin contentListThemeColorVariant var(--theme-color-blue-dark-text-color),
    buttonPrimaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin contentListThemeColorVariant var(--theme-color-brown-text-color),
    buttonPrimaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin contentListThemeColorVariant var(--theme-color-green-text-color),
    buttonPrimaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin contentListThemeColorVariant var(--theme-color-yellow-text-color),
    buttonPrimaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin contentListThemeColorVariant var(--theme-color-pink-text-color),
    buttonPrimaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin contentListThemeColorVariant var(--theme-color-turquoise-text-color),
    buttonPrimaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin contentListThemeColorVariant var(--theme-color-turquoise-dark-text-color),
    buttonPrimaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin contentListThemeColorVariant var(--theme-color-green-dark-text-color),
    buttonPrimaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin contentListThemeColorVariant var(--theme-color-sand-dark-text-color),
    buttonPrimaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin contentListThemeColorVariant var(--theme-color-forest-dark-text-color),
    buttonPrimaryThemeColorForestTwo;
}

.ContainerWithBackground--GreenOne {
  @mixin contentListThemeColorVariant var(--theme-color-green-one-text-color),
    buttonPrimaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin contentListThemeColorVariant var(--theme-color-green-five-text-color),
    buttonPrimaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin contentListThemeColorVariant var(--theme-color-forest-five-text-color),
    buttonPrimaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin contentListThemeColorVariant var(--theme-color-blue-three-text-color),
    buttonPrimaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin contentListThemeColorVariant var(--theme-color-yellow-two-text-color),
    buttonPrimaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin contentListThemeColorVariant var(--theme-color-yellow-five-text-color),
    buttonPrimaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin contentListThemeColorVariant var(--theme-color-red-four-text-color),
    buttonPrimaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin contentListThemeColorVariant var(--theme-color-grey-two-text-color),
    buttonPrimaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin contentListThemeColorVariant var(--theme-color-grey-five-text-color),
    buttonPrimaryThemeColorGreyFive;
}
