.ContactCard--inline {
  & .ContactCard__picture {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    
    @media (--tablet) {
      top: 20px;
    }
  }
  
  & .ContactCard__inner::after {
    background-position: top left;
  }
  
  & .ContactCard__link {
    display: none;
  }
  
  &.RequireMargins {
    margin: 0;
  }
}