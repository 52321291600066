.RichCampaignPage {
  & .RichTextBlock__inner {
    @media (--mobileAndTablet) {
      flex-flow: column nowrap;
    }

    & .RichTextBlock__aside {
      & .RichTextBlock__sidebarContent {
        & img {
          @media (--mobileAndTablet) {
            max-width: 120px;
            max-height: 120px;
          }
        }
      }
    }
  }
}
