.Resources__wrapper {
  &.Resources__wrapper--noResults {
    background-color: var(--base-grey-light-60);
    margin-block: 0;
  }
}

.Resources__aside {
  padding: var(--spacing-m) 0 var(--spacing-xl);
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);

  @media (--laptop) {
    padding: 0;
    gap: var(--spacing-xxl);
    margin: 2.6875rem 0 var(--spacing-xl); /* 43px (to make upcoming events title equal to latest news list) */
  }
}

.Resources__wrapperInner {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  @media (--tablet) {
    margin-block: var(--spacing-l) 0;
  }

  &.Resources__wrapperInner--asGrid {
    display: grid;

    & .Resources__aside {
      order: -1;
    }

    @media (--laptop) {
      grid-template-columns: minmax(0, 745px) minmax(0, 243px);
      gap: calc(var(--spacing-xl) + var(--spacing-xxxs));

      & .Resources__aside {
        order: 1;
      }
    }
  }
}

.Resources__noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: var(--spacing-xxxl);

  @media (--tablet) {
    padding-block: var(--spacing-xxxxxxxl);
    height: 500px;
    margin-block: var(--spacing-l) 0;
  }
}

.Resources__header {
  background-color: var(--base-grey-light-60);
  padding-block: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0;
  color: var(--base-grey-black-100);

  @media (--tablet) {
    padding-block: var(--spacing-xxl) 0;
  }
}

.Resources__headerInner {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);
}

.Resources__title {
  @mixin typographyMobileDisplayLarge;

  margin-block: 0 var(--spacing-xs);

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    margin-block: calc(var(--spacing-xs) + var(--spacing-xxxs));
    margin-top: 0;
  }
}

.Resources__subtitle {
  @mixin typographyDesktopStrongXXLarge;

  color: var(--base-grey-black-100);
  margin-block: 0 var(--spacing-xs);

  &.Resources__subtitle--visuallyHidden {
    @media (--mobileOnly) {
      @mixin visuallyHidden;
    }
  }

  &.Resources__subtitle--noResults {
    @mixin typographyMobileDisplayLarge;

    margin-block: 0 var(--spacing-xs);

    @media (--tablet) {
      @mixin typographyDesktopDisplayLarge;

      margin-block: 0 var(--spacing-s);
    }
  }
}

.Resources__description {
  @mixin typographyMobileDefaultLarge;

  margin-block: 0 var(--spacing-xs);

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;

    margin-block: 0 var(--spacing-m);
  }

  &.Resources__description--noResults {
    @mixin typographyMobileDefaultXLarge;

    color: var(--base-grey-black-100);

    @media (--tablet) {
      @mixin typographyDesktopDefaultXLarge;
    }
  }
}

.Resources__filters {
  background-color: var(--grayColor_tint_2);
}

.Resources__form {
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  display: flex;
  justify-content: space-between;
  padding-block: calc(var(--spacing-xs) + (var(--spacing-xxxs) / 2));
}

.SearchField.Resources__searchInput {
  @media (--mobileOnly) {
    display: none;
  }

  @media (--tablet) {
    width: 245px;
    align-items: center;
  }
}

.Resources__filter {
  & .ButtonDropdown {
    color: var(--base-grey-black-100);
    border-color: var(--base-grey-black-100);
    width: fit-content;
    justify-content: center;

    @media (--mobileOnly) {
      width: 100%;
      justify-content: center;
    }
  }
}

.Resources__filtersColumn {
  display: flex;
  gap: var(--spacing-xs);
}

.Resources__filtersButton {
  width: 100%;
  justify-content: center;
}

.Resources__listingWrapper {
  padding-block: calc(var(--spacing-s) + var(--spacing-xxxs));

  @media (--tablet) {
    padding-block: 0;
  }

  &.Resources__listingWrapper--withoutPaddingTopOnMobile {
    padding-top: 0;
  }
}

.Resources__list {
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  row-gap: calc(var(--spacing-xs) + var(--spacing-xxxs));

  @media (--tablet) {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-s);
  }
}

.Resources__listItem {
  min-width: 0;

  &.Resources__listItem--featured {
    @media (--tablet) {
      grid-column: 1 / -1;
    }
  }
}

.Resources__pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: calc(var(--spacing-xxxxl) + var(--spacing-xxxs)) 0;

  @media (--tablet) {
    margin-block: var(--spacing-xl) calc(var(--spacing-xxl) + var(--spacing-xxxs));
  }
}

.Resources__paginationCount {
  @mixin typographyDesktopDefaultSmall;

  color: var(--base-grey-black-100);
  margin-block: 0 calc(var(--spacing-xs) + var(--spacing-xxxs));
}
