/* stylelint-disable declaration-property-unit-allowed-list */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */
.RichtextArea,
.mce-content-body {
  & > img,
  & picture img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0;
  }

  & > img {
    margin: 1.5rem 0;

    @media (--tablet) {
      margin: 2rem 0;
    }
  }

  & form picture {
    margin-bottom: 0;
  }

  @mixin richTextLinks;

  & strong {
    font-weight: 600;
  }

  & i {
    font-style: italic;
  }

  & em {
    font-style: italic;
  }

  & > em,
  & > i,
  & > strong {
    margin: 0 0 1.4rem;

    @media (--tablet) {
      margin: 0 0 1.8rem;
    }
  }

  & figure {
    margin: 2rem 0;
    max-width: 100%;
  }

  & figcaption {
    margin-top: var(--spacing-xxs);
  }

  & iframe {
    width: 100%;
    border: none;
  }

  & video {
    width: 100%;
  }

  & > ul {
    font-size: 16px;
    display: block;
    margin: 1rem 0;
    padding-left: 1.5rem;

    @media (--tablet) {
      margin: 2rem 0;
      padding-left: 3rem;
      font-size: 18px;
    }
  }

  & > h2 + ul,
  & > h3 + ul,
  & > h4 + ul {
    margin: 0.5rem 0;
  }

  & > ol {
    font-size: 16px;
    display: block;
    margin: 1rem 0;
    padding-left: 1.5rem;
    list-style-type: decimal;

    @media (--tablet) {
      margin: 2rem 0;
      padding-left: 3rem;
      font-size: 18px;
    }
  }

  & > *:first-child {
    margin-top: 0;
  }

  & p:empty {
    margin: 0.5rem;
  }

  & > p + h2 {
    margin-top: 1.5rem;

    @media (--tablet) {
      margin-top: 2.5rem;
    }
  }

  & > p + h3 {
    margin-top: 1.5rem;

    @media (--tablet) {
      margin-top: 2.5rem;
    }
  }

  & > p + figure {
    margin: 1.5rem 0;

    @media (--tablet) {
      margin: 2.5rem 0;
    }
  }

  & > h2 {
    font-size: 24px;
    line-height: 1.2;
    margin: 2rem 0 1rem;

    @media (--tablet) {
      font-size: 32px;
    }
  }

  & > h3 {
    font-size: 20px;
    margin: 1rem 0 0.7rem;

    @media (--tablet) {
      font-size: 24px;
    }
  }

  & > h4 {
    font-size: 18px;
    margin: 1rem 0 0.5rem;

    @media (--tablet) {
      font-size: 18px;
    }
  }
}

.RichtextArea__video {
  position: relative;
  width: 100%;
  padding-top: 56%;
  margin: 2rem 0;

  @media (--tablet) {
    margin: 0 0 2.5rem;
  }

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.RichtextArea > h2 + .Figure {
  margin: 0.8em 0 1.2em;
}

.RichtextArea > h2 + .RichtextArea__video {
  margin: 0.8em 0;
}

.image img {
  max-width: 100%;
}

.RichtextArea--body {
  @media (--laptop) {
    padding-left: 0;
    padding-right: var(--sidebarWidth);
  }
}

.mce-content-body {
  & figcaption {
    border: 1px solid black;
  }

  & img {
    max-width: 100%;
  }
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}
