.StoriesFooter {
  background: var(--kog_blue_tint_7);
}

.StoriesFooter__inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.125rem;
  @mixin wrapperStory;
  
  @media (--tablet) {
    padding-left: 5.125rem;
  }
}

.StoriesFooter__text {
  margin: 1.25rem 1.75rem 1.25rem 1.25rem;
  font-size: 0.75rem;
  line-height: 1.2;
  
  @media (--tablet) {
    margin: 2.8125rem;
    font-size: 1rem;
  }
  
  & a:hover {
    text-decoration: underline;
  }
}

.StoriesFooter__copy {
  display: block;
  
  @media (--mobileSmall) {
    display: inline;
  }
}

.StoriesFooter__logo {
  display: flex;
  align-items: center;
  min-width: 58px;
  height: 76px;
  padding: 0 10px;
  background: var(--whiteColor);
  
  @media(--tablet) {
    min-width: 90px;
    height: 119px;
    padding: 0 15px;
  }
  
  &::before {
    content: '';
    background-image: svg-load("Common/logo-dark.svg", fill:var(--blackColor));
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;
  }
  
}