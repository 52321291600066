/* stylelint-disable declaration-no-important */
/* stylelint-disable declaration-property-unit-allowed-list */
.Btn {
  width: 200px;
  min-width: 200px;
  height: 60px;
  font-size: 22px;
  background: var(--kog_red);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--whiteColor);
  cursor: pointer;
  transition: box-shadow 0.2s ease-out;
  border: none;
  outline: none;

  @media (--tablet) {
    width: 260px;
    min-width: 260px;
    height: 65px;
    font-size: 32px;
  }

  &:hover {
    @mixin hoverDarken;
  }
}

a.Btn:visited {
  color: var(--whiteColor);
}

.Btn--secondary {
  font: inherit;
  overflow: visible;
  user-select: none;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.94;
  letter-spacing: normal;
  cursor: pointer;
  margin-bottom: 1rem;
  color: var(--whiteColor);
  transition: box-shadow 0.2s ease-out;
  background: var(--brandColor);
  text-transform: uppercase;
  border: none;
  font-size: 22px;

  @media (--tablet) {
    width: 200px;
  }

  &:hover {
    @mixin hoverDarken;
  }
}

.Btn--xLarge {
  @mixin buttonLarge;
}

.Btn--readMore {
  @mixin buttonSquareRotated arrowRightSimple, var(--buttonSquareColor), white;
}

.Btn--readMoreBlue {
  @mixin buttonSquareRotated arrowRightSimple, var(--brandColor), white;
}

.Btn--blue {
  border: none !important;
  margin: 0 !important;
  overflow: visible !important;
  text-align: inherit !important;
  box-shadow: none !important;
  background-color: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
  @mixin buttonSquareRotated arrowRightSimple, var(--brandColor_dark_tint_1), white;

  padding: 0 10px 0 60px;

  &.prev {
    transform: rotate(-180deg);
  }
}

.Btn--explore {
  @mixin buttonWithIcon arrowRightSimple, var(--buttonExploreColor);
}

.dark .Btn--explore {
  @mixin buttonWithIcon arrowRightSimple, var(--whiteColor);
}

.Btn--playVideo {
  @mixin buttonPlayVideo;
}

.Btn--link {
  height: 50px;
  background: var(--kog_blue_tint_1);
  font-size: 22px;
  width: 100%;
  padding: 2px 15px 0;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    background: svg-load("Icons/Link.svg", fill: var(--blackColor)) no-repeat;
    background-size: 24px;
    width: 24px;
    height: 24px;
    position: relative;
    top: -3px;
  }
}

.Btn--withBorder {
  background: none;
  height: 50px;
  color: var(--kog_blue_tint_1);
  font-size: 22px;
  width: 100%;
  padding: 2px 15px 0;
  border: 1px solid var(--kog_blue_tint_1);
}

.Btn--categoryItem {
  background-color: color(var(--kog_blue_tint_5) a(50%));
  padding: 20px 55px 20px 15px;
  font-weight: 400;
  height: 100%;
  position: relative;
  transition: box-shadow 0.25s ease;

  &:hover {
    @mixin hoverDarken;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    background: var(--kog_blue_tint_1) no-repeat center;
    background-image: svg-load("Icons/ArrowRight.svg", fill: var(--whiteColor));
    background-size: 24px;
  }
}

.Btn--marker,
.Btn--marker:visited {
  background: none;
  height: 50px;
  color: #334561;
  font-size: 22px;
  width: 100%;
  padding: 2px 15px 0;
  border: 1px solid #334561;

  &::before {
    content: "";
    margin-right: 7px;
    width: 26px;
    min-width: 26px;
    height: 26px;
    display: inline-flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: svg-load("Icons/Marker.svg", fill: var(--brandColor_dark));
    color: var(--brandColor_dark_tint_1);
  }
}

.Btn--marker:hover {
  box-shadow: none;

  &::before {
    animation: hoop 0.65s ease-in infinite;
  }
}

.Btn--shareFacebook {
  @mixin buttonSocial iconSocialFacebook, var(--brandColor_dark), var(--brandColor_dark_tint_3);
}

.Btn--shareTwitter {
  @mixin buttonSocial iconSocialTwitter, var(--brandColor_dark), var(--brandColor_dark_tint_3);
}

.Btn--shareLinkedin {
  @mixin buttonSocial iconSocialLinkedin, var(--brandColor_dark), var(--brandColor_dark_tint_3);
}

.Link--PhoneWhite {
  @mixin linkContactWithIcon iconPhone, var(--whiteColor);

  &:hover {
    text-decoration: underline;
  }
}

.Link--EmailWhite {
  color: var(--whiteColor);
  @mixin linkContactWithIcon iconEmail, var(--whiteColor);

  &:hover {
    text-decoration: underline;
  }
}

.Btn--navLink {
  padding: 0 50px 0 22px;
  height: 34px;
  color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--brandColor_dark_tint_1);
  background-image: svg-load("Icons/Link.svg");
  background-repeat: no-repeat;
  background-position: top 42% right 20px;
  background-size: 14px;
  max-width: 300px;
  white-space: nowrap;
}

.Btn--navLink:focus {
  outline-color: var(--outlineColorSecondary);
}

.Btn--transparent {
  border: none;
  background: transparent;
}

.Btn--nextSimple {
  border: none;
  width: 50px;
  height: 50px;
  background-size: 100%;
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  background: transparent svg-load("Icons/ArrowDown.svg", width: 18px, height: 30px) no-repeat
    center;
  transform: rotate(-90deg);
}

.Btn--prevSimple {
  border: none;
  width: 50px;
  height: 50px;
  background-size: 100%;
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  background: transparent svg-load("Icons/ArrowDown.svg", width: 18px, height: 30px) no-repeat
    center;
  transform: rotate(90deg);
}

.Btn--downloadResource {
  @mixin buttonWithIcon downloadResource, var(--brandColor_dark);

  &::after {
    left: 0;
  }

  &:hover::after {
    animation: none;
  }

  & a {
    text-transform: none;
  }
}

.ButtonLink {
  @mixin p;

  line-height: 1.8;

  @mixin textButtonLinkUnderline currentcolor, 2px, 6px;
}

/* Buttons (Discovery) */
.ButtonFill {
  @mixin buttonDefaultSize;
  @mixin buttonBaseFill;
}

.ButtonFill--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonBaseFill;
}

.ButtonFill--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonBaseFill;
}

.ButtonFill--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonBaseFill;
}

.ButtonOutlined {
  @mixin buttonDefaultSize;
  @mixin buttonBaseOutlined;
}

.ButtonOutlined--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonBaseOutlined;
}

.ButtonOutlined--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonBaseOutlined;
}

.ButtonOutlined--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonBaseOutlined;
}

.ButtonGhost {
  @mixin buttonDefaultSize;
  @mixin buttonBaseGhost;
}

.ButtonGhost--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonBaseGhost;
}

.ButtonGhost--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonBaseGhost;
}

.ButtonGhost--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonBaseGhost;
}

.ButtonFill--darkMode {
  @mixin buttonDefaultSize;
  @mixin buttonBaseFillDarkMode;
}

.ButtonFill--darkMode.ButtonFill--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonBaseFillDarkMode;
}

.ButtonFill--darkMode.ButtonFill--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonBaseFillDarkMode;
}

.ButtonFill--darkMode.ButtonFill--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonBaseFillDarkMode;
}

.ButtonOutlined--darkMode {
  @mixin buttonDefaultSize;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonOutlined--darkMode.ButtonOutlined--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonOutlined--darkMode.ButtonOutlined--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonOutlined--darkMode.ButtonOutlined--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonGhost--darkMode {
  @mixin buttonDefaultSize;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonGhost--darkMode.ButtonGhost--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonGhost--darkMode.ButtonGhost--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonGhost--darkMode.ButtonGhost--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonFill--small {
  @mixin buttonSmallSize;
  @mixin buttonBaseFill;
}

.ButtonFill--small.ButtonFill--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonBaseFill;
}

.ButtonFill--small.ButtonFill--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonBaseFill;
}

.ButtonFill--small.ButtonFill--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonBaseFill;
}

.ButtonOutlined--small {
  @mixin buttonSmallSize;
  @mixin buttonBaseOutlined;
}

.ButtonOutlined--small.ButtonOutlined--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonBaseOutlined;
}

.ButtonOutlined--small.ButtonOutlined--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonBaseOutlined;
}

.ButtonOutlined--small.ButtonOutlined--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonBaseOutlined;
}

.ButtonGhost--small {
  @mixin buttonSmallSize;
  @mixin buttonBaseGhost;
}

.ButtonGhost--small.ButtonGhost--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonBaseGhost;
}

.ButtonGhost--small.ButtonGhost--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonBaseGhost;
}

.ButtonGhost--small.ButtonGhost--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonBaseGhost;
}

.ButtonFill--small.ButtonFill--darkMode {
  @mixin buttonSmallSize;
  @mixin buttonBaseFillDarkMode;
}

.ButtonFill--small.ButtonFill--darkMode.ButtonFill--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonBaseFillDarkMode;
}

.ButtonFill--small.ButtonFill--darkMode.ButtonFill--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonBaseFillDarkMode;
}

.ButtonFill--small.ButtonFill--darkMode.ButtonFill--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonBaseFillDarkMode;
}

.ButtonOutlined--small.ButtonOutlined--darkMode {
  @mixin buttonSmallSize;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonOutlined--small.ButtonOutlined--darkMode.ButtonOutlined--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonOutlined--small.ButtonOutlined--darkMode.ButtonOutlined--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonOutlined--small.ButtonOutlined--darkMode.ButtonOutlined--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonBaseOutlinedDarkMode;
}

.ButtonGhost--small.ButtonGhost--darkMode {
  @mixin buttonSmallSize;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonBaseGhostDarkMode;
}

.ButtonFill--blue {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryBlueFill;
}

.ButtonFill--blue.ButtonFill--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryBlueFill;
}

.ButtonFill--blue.ButtonFill--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryBlueFill;
}

.ButtonFill--blue.ButtonFill--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryBlueFill;
}

.ButtonOutlined--blue {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonOutlined--blue.ButtonOutlined--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonOutlined--blue.ButtonOutlined--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonOutlined--blue.ButtonOutlined--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonGhost--blue {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost.ButtonGhost--darkMode {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonGhost--blue.ButtonGhost.ButtonGhost--darkMode.ButtonGhost--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonGhost--blue.ButtonGhost.ButtonGhost--darkMode.ButtonGhost--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonGhost--blue.ButtonGhost.ButtonGhost--darkMode.ButtonGhost--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonFill--blue.ButtonFill--small {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryBlueFill;
}

.ButtonFill--blue.ButtonFill--small.ButtonFill--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryBlueFill;
}

.ButtonFill--blue.ButtonFill--small.ButtonFill--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryBlueFill;
}

.ButtonFill--blue.ButtonFill--small.ButtonFill--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryBlueFill;
}

.ButtonOutlined--blue.ButtonOutlined--small {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonOutlined--blue.ButtonOutlined--small.ButtonOutlined--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonOutlined--blue.ButtonOutlined--small.ButtonOutlined--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonOutlined--blue.ButtonOutlined--small.ButtonOutlined--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryBlueOutlined;
}

.ButtonGhost--blue.ButtonGhost--small {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost--small.ButtonGhost--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost--small.ButtonGhost--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost--small.ButtonGhost--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryBlueGhost;
}

.ButtonGhost--blue.ButtonGhost--small.ButtonGhost--darkMode {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonGhost--blue.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonGhost--blue.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonGhost--blue.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryBlueGhostDarkMode;
}

.ButtonFill--teal {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryTealFill;
}

.ButtonFill--teal.ButtonFill--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryTealFill;
}

.ButtonFill--teal.ButtonFill--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryTealFill;
}

.ButtonFill--teal.ButtonFill--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryTealFill;
}

.ButtonOutlined--teal {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonOutlined--teal.ButtonOutlined--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonOutlined--teal.ButtonOutlined--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonOutlined--teal.ButtonOutlined--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonGhost--teal {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--teal.ButtonGhost--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--teal.ButtonGhost--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--teal.ButtonGhost--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--darkMode.ButtonGhost--teal {
  @mixin buttonDefaultSize;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonGhost--darkMode.ButtonGhost--teal.ButtonGhost--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonGhost--darkMode.ButtonGhost--teal.ButtonGhost--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonGhost--darkMode.ButtonGhost--teal.ButtonGhost--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonFill--small.ButtonFill--teal {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryTealFill;
}

.ButtonFill--small.ButtonFill--teal.ButtonFill--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryTealFill;
}

.ButtonFill--small.ButtonFill--teal.ButtonFill--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryTealFill;
}

.ButtonFill--small.ButtonFill--teal.ButtonFill--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryTealFill;
}

.ButtonOutlined--small.ButtonOutlined--teal {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonOutlined--small.ButtonOutlined--teal.ButtonOutlined--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonOutlined--small.ButtonOutlined--teal.ButtonOutlined--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonOutlined--small.ButtonOutlined--teal.ButtonOutlined--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryTealOutlined;
}

.ButtonGhost--small.ButtonGhost--teal {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--small.ButtonGhost--teal.ButtonGhost--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--small.ButtonGhost--teal.ButtonGhost--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--small.ButtonGhost--teal.ButtonGhost--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryTealGhost;
}

.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--teal {
  @mixin buttonSmallSize;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--teal.ButtonGhost--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--teal.ButtonGhost--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonGhost--small.ButtonGhost--darkMode.ButtonGhost--teal.ButtonGhost--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonPrimaryTealGhostDarkMode;
}

.ButtonFill--destructive {
  @mixin buttonDefaultSize;
  @mixin buttonDestructiveFill;
}

.ButtonFill--destructive.ButtonFill--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonDestructiveFill;
}

.ButtonFill--destructive.ButtonFill--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonDestructiveFill;
}

.ButtonFill--destructive.ButtonFill--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonDestructiveFill;
}

.ButtonOutlined--destructive {
  @mixin buttonDefaultSize;
  @mixin buttonDestructiveOutlined;
}

.ButtonOutlined--destructive.ButtonOutlined--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonDestructiveOutlined;
}

.ButtonOutlined--destructive.ButtonOutlined--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonDestructiveOutlined;
}

.ButtonOutlined--destructive.ButtonOutlined--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonDestructiveOutlined;
}

.ButtonGhost--destructive {
  @mixin buttonDefaultSize;
  @mixin buttonDestructiveGhost;
}

.ButtonGhost--destructive.ButtonGhost--iconLeft {
  @mixin buttonDefaultSizeIconLeft;
  @mixin buttonDestructiveGhost;
}

.ButtonGhost--destructive.ButtonGhost--iconRight {
  @mixin buttonDefaultSizeIconRight;
  @mixin buttonDestructiveGhost;
}

.ButtonGhost--destructive.ButtonGhost--icon {
  @mixin buttonDefaultSizeIconOnly;
  @mixin buttonDestructiveGhost;
}

.ButtonFill--destructive.ButtonFill--small {
  @mixin buttonSmallSize;
  @mixin buttonDestructiveFill;
}

.ButtonFill--destructive.ButtonFill--small.ButtonFill--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonDestructiveFill;
}

.ButtonFill--destructive.ButtonFill--small.ButtonFill--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonDestructiveFill;
}

.ButtonFill--destructive.ButtonFill--small.ButtonFill--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonDestructiveFill;
}

.ButtonOutlined--destructive.ButtonOutlined--small {
  @mixin buttonSmallSize;
  @mixin buttonDestructiveOutlined;
}

.ButtonOutlined--destructive.ButtonOutlined--small.ButtonOutlined--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonDestructiveOutlined;
}

.ButtonOutlined--destructive.ButtonOutlined--small.ButtonOutlined--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonDestructiveOutlined;
}

.ButtonOutlined--destructive.ButtonOutlined--small.ButtonOutlined--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonDestructiveOutlined;
}

.ButtonGhost--destructive.ButtonGhost--small {
  @mixin buttonSmallSize;
  @mixin buttonDestructiveGhost;
}

.ButtonGhost--destructive.ButtonGhost--small.ButtonGhost--iconLeft {
  @mixin buttonSmallSizeIconLeft;
  @mixin buttonDestructiveGhost;
}

.ButtonGhost--destructive.ButtonGhost--small.ButtonGhost--iconRight {
  @mixin buttonSmallSizeIconRight;
  @mixin buttonDestructiveGhost;
}

.ButtonGhost--destructive.ButtonGhost--small.ButtonGhost--icon {
  @mixin buttonSmallSizeIconOnly;
  @mixin buttonDestructiveGhost;
}
