@define-mixin circleOutline $color: var(--whiteColor), $size: 64px, $desktopHDSize: 96px {
  @mixin absoluteCenter;
  border: 1px solid $color;
  border-radius: 100%;
  display: block;
  width: $size;
  height: $size;

  @media (--desktop4k) {
    width: $desktopHDSize;
    height: $desktopHDSize;
  }
}

@define-mixin micrositeButton $color: var(--whiteColor), $activeColor: var(--blackColor) {
  @mixin resetButtonStyles;
  cursor: pointer;
  border: 1px solid $color !important;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  color: $color;
  padding: 4px 32px !important;
  transition: all 0.3s ease-in-out;

  @media (--desktop4k) {
    font-size: 42px;
    line-height: 0.5;
    padding: 32px !important;
    border-radius: 40px;
  }

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background: $color;
    color: $activeColor;
  }
}
