.FactsHero__grid--alignmentLeft {
  justify-content: flex-start;
  text-align: left;

  & .FactsHero__link {
    justify-self: flex-start;
  }
}

.FactsHero__grid--alignmentRight {
  justify-content: flex-end;
  text-align: right;
  
  & .FactsHero__link {
    justify-self: flex-end;
  }
}