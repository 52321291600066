.Label {
  display: inline-flex;
  align-items: center;
  border-radius: 14px;
  text-transform: uppercase;
  white-space: nowrap;

  &,
  &.Label--large {
    @mixin labelLargeMobile;

    @media (--tablet) {
      @mixin labelLargeDesktop;
    }
  }

  &.Label--small {
    @mixin labelSmallMobile;

    @media (--tablet) {
      @mixin labelSmallDesktop;
    }
  }

  &,
  &.Label--default {
    &,
    &.Label--dark {
      background-color: var(--accent-blue-accent-default);
      color: var(--accent-blue-accent-lighter);
    }

    &.Label--light {
      background-color: var(--accent-blue-accent-lighter);
      color: var(--accent-blue-accent-default);
    }
  }

  &.Label--neutral {
    &,
    &.Label--dark {
      background-color: var(--base-grey-dark-100);
      color: var(--base-grey-light-80);
    }

    &.Label--light {
      background-color: var(--base-grey-light-80);
      color: var(--base-grey-dark-100);
    }
  }

  &.Label--success {
    &,
    &.Label--dark {
      background-color: var(--accent-teal-accent-default);
      color: var(--accent-teal-accent-lighter);
    }

    &.Label--light {
      background-color: var(--accent-teal-accent-lighter);
      color: var(--accent-teal-accent-default);
    }
  }

  &.Label--alert {
    &,
    &.Label--dark {
      background-color: var(--alert-alert-dark);
      color: var(--base-grey-white);
    }

    &.Label--light {
      background-color: var(--base-grey-white);
      color: var(--alert-alert-dark);
    }
  }

  &.Label--error {
    &,
    &.Label--dark {
      background-color: var(--error-error-default);
      color: var(--error-error-lighter);
    }

    &.Label--light {
      background-color: var(--error-error-lighter);
      color: var(--error-error-default);
    }
  }
}
