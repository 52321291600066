ul[class^="cardsList"] {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardsList__4col {
  @neat-outer-container;
  width:100%;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & > li {
    margin-bottom: 1.90rem;
    @neat-span-columns 12;
    @neat-omega;
    
    display: flex;
    flex-flow: column;

    @media (--mobile) {
      @neat-span-columns 6;
      @neat-omega 2n;
      max-width: 48.6%;
      margin-right: 2.5%;
      display: flex;
    }

    @media (--tabletOnly) {
      @neat-span-columns 6;
      @neat-omega 2n;
      max-width: 48.6%;
      margin-right: 2.5%;
      margin-bottom: 3rem;
      display: flex;
    }

    @media (--laptopOnly) {
      @neat-span-columns 4;
      @neat-omega 3n;
      max-width: 31.5%;
      margin-right: 2.5%;
      margin-bottom: 4rem;
      display: flex;
    }

    @media (--desktop) {
      @neat-span-columns 3;
      @neat-omega 4n;
      max-width: 24.3%;
      margin-right: 2.5%;
      margin-bottom: 6.2rem;
      display: flex;
    }
  }

  & li > div {
    flex-grow: 1;
  }

  & li:nth-child(4n + 1).animate {
    transform: translateY(60px);
  }

  & li:nth-child(4n + 2).animate {
    transform: translateY(100px);
  }

  & li:nth-child(4n + 3).animate {
    transform: translateY(140px);
  }

  & li:nth-child(4n + 4).animate {
    transform: translateY(180px);
  }
}

.cardsList__4col--featured {
  @neat-outer-container;
  width:100%;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & > li {
    margin-bottom: 1.90rem;
    @neat-span-columns 12;
    @neat-omega;

    display: flex;
    flex-flow: column;

    @media (--mobile) {
      @neat-span-columns 6;
      @neat-omega 2n;
      max-width: 48.6%;
      margin-right: 2.5%;
      display: flex;
    }

    @media (--tabletOnly) {
      @neat-span-columns 4;
      @neat-omega 3n;
      max-width: 31.5%;
      margin-right: 2.5%;
      margin-bottom: 4rem;
      display: flex;
    }

    @media (--laptopOnly) {
      @neat-span-columns 3;
      @neat-omega 4n;
      max-width: 24.3%;
      margin-right: 2.5%;
      margin-bottom: 6.2rem;
      display: flex;
    }

    @media (--desktop) {
      @neat-span-columns 3;
      @neat-omega 4n;
      max-width: 24.3%;
      margin-right: 2.5%;
      margin-bottom: 6.2rem;
      display: flex;
    }
  }

  & li > div {
    flex-grow: 1;
  }

  & li:nth-child(4n + 1).animate {
    transform: translateY(60px);
  }

  & li:nth-child(4n + 2).animate {
    transform: translateY(100px);
  }

  & li:nth-child(4n + 3).animate {
    transform: translateY(140px);
  }

  & li:nth-child(4n + 4).animate {
    transform: translateY(180px);
  }
}

.cardsList__3col {
  @neat-outer-container;
  width:100%;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & > li {
    margin-bottom: 1.90rem;
    @neat-span-columns 12;
    display: flex;
    flex-flow: column;
    
    @media (--mobileOnly) {
      @neat-omega 1n;
      display: flex;
    }

    @media (--tabletOnly) {
      @neat-span-columns 6;
      @neat-omega 2n;
      max-width: 48.6%;
      margin-right: 2.5%;
      display: flex;
    } 
    
    @media (--laptop) {
      @neat-span-columns 4;
      @neat-omega 3n;
      max-width: 31.5%;
      margin-right: 2.5%;
      display: flex;
    }
  }
  
  & li > div {
    flex-grow: 1;
  }

  & li .ContentCard--standard {
    height: 100%;
  }
  
  & li:nth-child(3n + 1).animate {
    transform: translateY(60px);
  }

  & li:nth-child(3n + 2).animate {
    transform: translateY(120px);
  }

  & li:nth-child(3n + 3).animate {
    transform: translateY(180px);
  }
}

.cardsList__3col--featured {
  @neat-outer-container;
  width:100%;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & > li {
    margin-bottom: 1.90rem;
    @neat-span-columns 12;
    display: flex;
    flex-flow: column;

    @media (--mobileOnly) {
      @neat-omega 1n;
      display: flex;
    }

    @media (--tablet) {
      @neat-span-columns 4;
      @neat-omega 3n;
      max-width: 31.5%;
      margin-right: 2.5%;
      display: flex;
    }
  }

  & li > div {
    flex-grow: 1;
  }

  & li:nth-child(3n + 1).animate {
    transform: translateY(60px);
  }

  & li:nth-child(3n + 2).animate {
    transform: translateY(120px);
  }

  & li:nth-child(3n + 3).animate {
    transform: translateY(180px);
  }
}

.cardsList__3col--centered {
  @media (--tabletOnly) {
    & > li > div {
      max-width: 360px;
    }

    & > li:nth-child(2n - 1) {
      & > div {
        margin-left: auto;
      }
    }
  }
}

.cardsList__2col {
  @neat-outer-container ;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & > li {
    margin-bottom: 1rem;
    @neat-span-columns 12;
    display: flex;
    flex-flow: column;

    @media (--mobileOnly) {
      @neat-omega 1n;
      display: flex;
      flex-flow: column;
    }

    @media (--tablet) {
      margin-bottom: 2rem;
      @neat-span-columns 6;
      @neat-omega 2n;
      max-width: 48.6%;
      margin-right: 2.5%;
      display: flex;
      flex-flow: column;
    }
  }

  & li > div {
    flex-grow: 1;
  }
}

.cardsList__2col--centered {
  @media (--tablet) {
    & > li > div {
      max-width: 360px;
    }

    & > li:nth-child(2n - 1) {
      & > div {
        margin-left: auto;
      }
    }
  }
}

.cardsList__1col {
  margin-bottom: -1.9rem;
  & > li {
    margin-bottom: 1.90rem;
    margin-top: 1.90rem;
    @neat-omega n;
  }
}