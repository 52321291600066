@import "../../../node_modules/react-image-lightbox/style.css";

.LightBox {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (--tablet) {
        margin-top: 4.5rem;
        margin-bottom: 4.5rem;
    }

}

/* Fixes scrolling body while lightbox is open */
.ReactModal__Body--open {
    overflow-y: hidden;
}

.LightBox__wrapper.RequireMargins {
    @media (--tabletOnly) {
        margin: 3.5rem auto;
    }
}

.Lightbox__container {
    text-align: center;

    & .Lightbox__container-items {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        user-select: none;
    }
}

.Lightbox__scrollButton {
    position: relative;
    display: inline-block;
    min-width: 48px;
    height: 48px;
    margin-top: auto;
    margin-bottom: auto;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    
    &.Lightbox__scrollButton--previous {
        margin-right: 10px;

        @media (--laptop) {
            margin-right: 15px;
        }
        
        &:hover {
            animation-name: moveRight;
        }
    }

    &.Lightbox__scrollButton--next {
        margin-left: 10px;

        @media (--laptop) {
            margin-left: 15px;
        }

        &:hover {
            animation-name: moveLeft;
        }
    }
}

.Lightbox__container .Lightbox__scrollButton .Lightbox__scrollButtonIcon {
    background: none;    

    &:hover {
        animation: none;
    }
}

.Lightbox__scrollButton--previous .Lightbox__scrollButtonIcon {
    @mixin buttonPrevious var(--blackColor), var(--whiteColor);
}

.Lightbox__scrollButton--next .Lightbox__scrollButtonIcon {
    @mixin buttonNext var(--blackColor), var(--whiteColor);
}

.Lightbox__container-images {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: -16px 0;
    width: 100%;

    @media (--tablet) {
        flex-flow: row nowrap;

        @media (--tablet) {
            
            &::before,
            &::after {
                content: '';
                display: inline-flex;
            }
        }
    }
}

.StoryTextContent .Lightbox__container-images {
    
    @media (--tablet) {
        
        &::before {
            flex-grow: 4;
        }
        
        &::after {
            flex-grow: 1;
        }
    }
}

.ArticlePage__body .Lightbox__container-images {
    
    @media (--tablet) {
        
        &::before {
            flex-grow: 0;
        }

        &::after {
            flex-grow: 1;
        }
    }
}

.Lightbox__image {
    flex: 0 1 50%;
    width: 100%;
    cursor: pointer;
    margin: 16px;
    
    @media (--mobileSmall) {
        flex: 0 1 25%;
    }
    
    @media (--tablet) {
        flex: 0 1 100%;
    }
}

.Lightbox__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation-name: opening;
    animation-duration: 2s;
}

@keyframes opening {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.LightBox__wrapper {
    @mixin wrapper;

    &.animate {
        @media (--tablet) {
            transform: translateY(160px);
        }
    }
}

@keyframes moveLeft {
    0%, 100%{
        left: 0px;
    }

    50% {
        left: 5px;
    }
}

@keyframes moveRight {
    0%, 100%{
        left: 0px;
    }

    50% {
        left: -5px;
    }
}