@define-mixin storyVerticalContentListColorVariant $backgroundColor, $fontColor {
  background-color: $backgroundColor;
  color: $fontColor;

  & .StoryHighlightBlock__icon svg path {
    fill: $fontColor;
  }

  & ul {
    & li {
      border-color: $fontColor;
    }
  }
}

.StoryVerticalContentList {
  &.StoryVerticalContentList--backgroundColorGreyTwo {
    @mixin storyVerticalContentListColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color);
  }

  &.StoryVerticalContentList--backgroundColorGreenFour {
    @mixin storyVerticalContentListColorVariant var(--theme-color-green-bg-color), var(--theme-color-green-text-color);
  }

  &.StoryVerticalContentList--backgroundColorBlueFive {
    @mixin storyVerticalContentListColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color);
  }

  &.StoryVerticalContentList--backgroundColorTealTwo {
    @mixin storyVerticalContentListColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color);
  }

  &.StoryVerticalContentList--backgroundColorSandFour {
    @mixin storyVerticalContentListColorVariant var(--theme-color-brown-bg-color), var(--theme-color-brown-text-color);
  }

  &.StoryVerticalContentList--backgroundColorTealFive {
    @mixin storyVerticalContentListColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color);
  }

  &.StoryVerticalContentList--backgroundColorYellowFour {
    @mixin storyVerticalContentListColorVariant var(--theme-color-yellow-bg-color), var(--theme-color-yellow-text-color);
  }

  &.StoryVerticalContentList--backgroundColorGreenTwo {
    @mixin storyVerticalContentListColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color);
  }

  &.StoryVerticalContentList--backgroundColorBlueTwo {
    @mixin storyVerticalContentListColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color);
  }

  &.StoryVerticalContentList--backgroundColorSandTwo {
    @mixin storyVerticalContentListColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color);
  }

  &.StoryVerticalContentList--backgroundColorForestTwo {
    @mixin storyVerticalContentListColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color);
  }

  &.StoryVerticalContentList--backgroundColorGreenOne {
    @mixin storyVerticalContentListColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color);
  }

  &.StoryVerticalContentList--backgroundColorGreenFive {
    @mixin storyVerticalContentListColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color);
  }

  &.StoryVerticalContentList--backgroundColorForestFive {
    @mixin storyVerticalContentListColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color);
  }

  &.StoryVerticalContentList--backgroundColorBlueThree {
    @mixin storyVerticalContentListColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color);
  }

  &.StoryVerticalContentList--backgroundColorYellowTwo {
    @mixin storyVerticalContentListColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color);
  }

  &.StoryVerticalContentList--backgroundColorYellowFive {
    @mixin storyVerticalContentListColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color);
  }

  &.StoryVerticalContentList--backgroundColorRedFour {
    @mixin storyVerticalContentListColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color);
  }

  &.StoryVerticalContentList--backgroundColorGreyFive {
    @mixin storyVerticalContentListColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color);
  }
}
