.Header__container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-index-header);

  &.Header__container--fixed {
    position: fixed;
  }
}

.Header {
  height: var(--mobile-header-height);
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 0.09);
  font-family: var(--family-soehne);
  transition: border-color 0.2s;

  &:not(.Header--isTransparent) {
    border-bottom: 1px solid var(--base-grey-light-80);
  }

  @media (--laptop) {
    height: var(--header-height-without-breadcrumbs);
  }
}

html {
  scroll-padding-block-start: var(--mobile-header-height);

  @media (--laptop) {
    scroll-padding-block-start: var(--header-height-without-breadcrumbs);
  }
}

.Header__main {
  background: var(--whiteColor);
  transition: background 0.2s;
}

.Header__mainInner {
  width: var(--header-content-width);
  max-width: 100%;
  padding: 0 var(--spacing-s);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--desktop) {
    padding: 0 var(--spacing-xl);
  }
}

.Header__logo {
  display: block;
  width: var(--header-kog-logo-width);
  height: var(--header-kog-logo-height);
  color: var(--base-grey-black-100);
  z-index: 1;
  position: relative;
  transition: color 0.2s;
  @mixin focusVisible var(--outlineColorSecondary);

  & > svg {
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
}

.Header__logoInner {
  width: 100%;
  min-width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.2s;

  &.Header__logoInner--visible {
    opacity: 1;
  }
}

.Header__logoAndNav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-logo-and-nav-height);
}

.Header__topNav {
  height: var(--mobile-header-top-nav-height);
  display: flex;
  align-items: center;
  transition: background-color 0.2s;

  @media (--laptop) {
    height: var(--desktop-header-top-nav-height);
  }

  &.Header__topNav--blue {
    background-color: var(--accent-blue-accent-darker);
  }

  &.Header__topNav--green {
    background-color: var(--accent-teal-accent-darker);
  }
}

.Header__topNav--hiddenOnMobile {
  display: none;

  @media (--laptop) {
    display: flex;
  }
}

.Header__topNavContent {
  width: var(--header-content-width);
  max-width: 100%;
  padding: 0 var(--spacing-s);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--desktop) {
    padding: 0 var(--spacing-xl);
  }
}

.Header__topNavTitle {
  @mixin typographyDesktopStrongSmall;

  display: inline-block;
  color: var(--whiteColor);

  @media (--laptop) {
    @mixin typographyDesktopStrongMedium;
  }
}

.Header__topNavMenu {
  align-items: center;
  gap: var(--spacing-xxl);
  padding: 0;
  margin: 0;
  list-style: none;
  display: none;

  @media (--laptop) {
    display: flex;
  }
}

.Header__topNavMenuMicroItem {
  line-height: 1;

  & > a {
    @mixin typographyDesktopDefaultMedium;

    color: var(--whiteColor);
    @mixin focusVisible var(--outlineColorSecondary);
  }
}

.Header__topNavHeaderItemLink {
  @mixin typographyDesktopStrongSmall;

  display: flex;
  align-items: center;
  gap: var(--spacing-xxxs);
  color: var(--whiteColor);

  @mixin focusVisible var(--outlineColorSecondary);

  & > svg {
    font-size: 1.25rem;
    /* stylelint-disable-next-line declaration-no-important */
    stroke: initial !important;
  }

  @media (--laptop) {
    @mixin typographyDesktopStrongMedium;
  }
}

.Header__backdrop {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100vh;
  z-index: -1;
  background: var(--base-grey-black-100);
  opacity: 0;
  pointer-events: none;
  visibility: none;
  transition: opacity 0.2s;

  &.Header__backdrop--visible {
    opacity: 0.6;
    pointer-events: initial;
    visibility: initial;
  }
}

.SkipToContent {
  border: 0;
  clip: rect(0 0 0 0);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overflow: hidden;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  width: 1px;
  color: var(--textColor);
  text-transform: uppercase;
  font-size: 0.875rem;

  @media (--tablet) {
    left: 200px;
    font-size: 1rem;
  }
}

.SkipToContent:focus,
.SkipToContent:visited:focus {
  clip: initial;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  clip-path: initial;
  margin: 0;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  overflow: auto;
  white-space: nowrap;
  width: auto;
  text-decoration: none;
  font-weight: 300;
  line-height: 1;
  padding: 0 22px;
  height: 34px;
  align-items: center;
  justify-content: center;
  background: var(--brandColor_tint_1);
  display: flex;
  min-width: auto;
  outline: none;
  color: var(--textColor);
  text-transform: uppercase;
  z-index: 2;
}

.Header--isTransparent {
  box-shadow: none;

  & .Header__topNav {
    /* stylelint-disable-next-line declaration-no-important */
    background: transparent !important;
  }

  & .Header__main {
    background: transparent;
  }

  & .Header__logo {
    color: var(--whiteColor);
  }

  & .MainBreadcrumbs {
    background: transparent;
  }
}

.Header--hasBreadcrumbs {
  height: var(--mobile-header-height);

  @media (--laptop) {
    height: var(--header-height-with-breadcrumbs);
  }

  & .NavSubmenu.NavSubmenu--fullWidth {
    top: calc(
      var(--header-height-with-breadcrumbs) - var(--header-breadcrumbs-height) - var(--spacing-s)
    ); /* header height with breadcrumbs - breadcrumbs height - padding-bottom of wrapper */

    & .NavSubmenu__inner {
      max-height: calc(100vh - var(--header-height-with-breadcrumbs));
      margin-top: calc(var(--spacing-s) + var(--header-breadcrumbs-height));
    }
  }
}

html:has(.Header--hasBreadcrumbs) {
  @media (--laptop) {
    scroll-padding-block-start: var(--header-height-with-breadcrumbs);
  }
}

.Header--withoutTopNavOnMobile {
  @media (--mobileAndTablet) {
    height: var(--mobile-header-height-without-top-nav);

    & .NavMobile {
      top: 0;
      height: 100vh;
    }
  }
}

html:has(.Header--withoutTopNavOnMobile) {
  @media (--mobileAndTablet) {
    scroll-padding-block-start: var(--mobile-header-height-without-top-nav);
  }
}
