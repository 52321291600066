.ProductCampaignPageHeader {
  & .HeroHeader__title {
    @mixin typographyMobileDisplayXLarge;

    @media (--laptop) {
      @mixin typographyDesktopDisplayXLarge;
    }
  }
}

.ProductCampaignPageHeader__label {
  @mixin typographyMobileCapsLarge;

  margin-block: 0 var(--spacing-xxs);

  @media (--laptop) {
    margin-block: 0 var(--spacing-xs);
  }
}

.ProductCampaignPageHeader__bottomRow {
  display: flex;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-l);
}

.ProductCampaignPageHeader__sideContent {
  display: grid;
  height: 100%;
  place-content: end;
}
