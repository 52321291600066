.StoryFactsList__withOpacity::before {
   opacity: 0.94
}

.StoryFactsList {
  &.StoryFactsList--brown {
    & .StoryFactsList__background::before {
      background-color: var(--kog_bronze);
    }
  }
  &.StoryFactsList--blue {
    & .StoryFactsList__background::before {
      background-color: var(--kog_blue);
    }
  }
  &.StoryFactsList--aqua {
    & .StoryFactsList__background::before {
      background-color: var(--kog_aqua_tint_1);
        opacity: 0.3;
    }
  }
  &.StoryFactsList--gray {
    & .StoryFactsList__background::before {
      background-color: var(--kog_blue_tint_7);
    }
  }
  &.StoryFactsList--none {
    & .StoryFactsList__background::before {
      background-color: var(--whiteColor);
    }
  }
}