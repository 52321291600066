@define-mixin imageViewerTextColorVariant $backgroundColor, $fontColor {
  background-color: $backgroundColor;

  &,
  & p {
    color: $fontColor;
  }
}

.ImageViewerMainImage__legend {
  &.ImageViewerMainImage__legend--backgroundColorDefault {
    @mixin imageViewerTextColorVariant var(--themeSecondaryColor_tint_2),
      var(--themeSecondaryColor_tint_7);
  }

  &.ImageViewerMainImage__legend--backgroundColorGreenFour {
    @mixin imageViewerTextColorVariant var(--theme-color-green-bg-color),
      var(--theme-color-green-text-color-darker);
  }

  &.ImageViewerMainImage__legend--backgroundColorBlueFive {
    @mixin imageViewerTextColorVariant var(--theme-color-blue-bg-color),
      var(--theme-color-blue-text-color-lighter);
  }

  &.ImageViewerMainImage__legend--backgroundColorTealTwo {
    @mixin imageViewerTextColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorSandFour {
    @mixin imageViewerTextColorVariant var(--theme-color-brown-bg-color-lighter),
      var(--theme-color-brown-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorTealFive {
    @mixin imageViewerTextColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorYellowFour {
    @mixin imageViewerTextColorVariant var(--theme-color-yellow-bg-color),
      var(--theme-color-yellow-text-color-lighter);
  }

  &.ImageViewerMainImage__legend--backgroundColorGreenTwo {
    @mixin imageViewerTextColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color-darker);
  }

  &.ImageViewerMainImage__legend--backgroundColorBlueTwo {
    @mixin imageViewerTextColorVariant var(--theme-color-blue-dark-bg-color-lighter),
      var(--theme-color-blue-dark-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorSandTwo {
    @mixin imageViewerTextColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorForestTwo {
    @mixin imageViewerTextColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorWhite {
    @mixin imageViewerTextColorVariant var(--whiteColor), var(--blackColor);
  }

  &.ImageViewerMainImage__legend--backgroundColorBlack {
    @mixin imageViewerTextColorVariant var(--blackColor), var(--whiteColor);
  }

  &.ImageViewerMainImage__legend--backgroundColorGreenOne {
    @mixin imageViewerTextColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorGreenFive {
    @mixin imageViewerTextColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorForestFive {
    @mixin imageViewerTextColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorBlueThree {
    @mixin imageViewerTextColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorYellowTwo {
    @mixin imageViewerTextColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorYellowFive {
    @mixin imageViewerTextColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorRedFour {
    @mixin imageViewerTextColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorGreyTwo {
    @mixin imageViewerTextColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color);
  }

  &.ImageViewerMainImage__legend--backgroundColorGreyFive {
    @mixin imageViewerTextColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color);
  }
}
