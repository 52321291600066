.TechnicalSpecifications__specList {
  margin: 0;
  margin-bottom: calc(-1 * var(--spacing-xxxs));

  &:not(.TechnicalSpecifications__specList--lastGroup) {
    & .TechnicalSpecifications__specItem:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media (--tablet) {
    margin-bottom: 0;
    margin-top: calc(0.5 * var(--spacing-xxxs));
  }

  &.TechnicalSpecifications__specList--wide {
    @media (--tablet) {
      & .TechnicalSpecifications__specItemLabel {
        grid-column: 1 / 5;
      }

      & .TechnicalSpecifications__specItemValue {
        grid-column: 5 / -1;
      }
    }
  }
}

.TechnicalSpecifications__specItem {
  @mixin typographyMobileDefaultMedium;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--spacing-s);
  padding: calc(var(--spacing-xxs)) 0;
  border-bottom: 1px solid var(--base-grey-medium-100);

  @media (--tablet) {
    @mixin typographyDesktopDefaultMedium;
    padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0;

    &:first-of-type {
      padding-top: 0;
    }
  }
}

.TechnicalSpecifications__specItemLabel {
  grid-column: 1 / 6;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxxs);
}

.TechnicalSpecifications__specItemValue {
  grid-column: 6 / -1;
  margin: 0;
}

.TechnicalSpecifications__specItemLabel,
.TechnicalSpecifications__specItemValue {
  hyphens: auto;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.TechnicalSpecifications__tooltipTrigger {
  @mixin resetButtonStylesNoOverride;
}

.TechnicalSpecifications__tooltipTriggerIcon {
  font-size: 1rem;
}

.TechnicalSpecifications__tooltipContent {
  text-align: center;
}