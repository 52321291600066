.CtaButton {
    display: flex;
    width: fit-content;
    border-radius: 6px;
    cursor: pointer;
    min-width: 100px;
    justify-content: center;
    letter-spacing: 1px;
    transition: box-shadow .2s ease-out;

    &:hover {
        @mixin hoverDarken;
    }
}

.CampaignHero {
    & .CtaButton {
        &.CtaButton--fixed {
            position: fixed;
            opacity: 0;
            bottom: 20px;
            right: 20px;
        }

        &.CtaButton--animEnter {
            animation: .5s fadeIn both;
        }
    }
}

.CampaignHero--azure-blue{
    background-color: var(--themeBrandColor_dark_tint_7);
    color: var(--blackColor);
}

.CtaButton--azure-blue {
    background-color: var(--kog_blue_tint_8);
    color: var(--kog_blue);
}

.CampaignHero--dark-blue,
.CtaButton--dark-blue {
    background-color: var(--kog_blue);
    color: var(--whiteColor);
}

.CampaignHero--golden {
    background-color: var(--themeSecondaryColor_tint_1);
    color: var(--kog_bronze);
}

.CtaButton--golden {
    background-color: var(--themeSecondaryColor_tint_1);
    color: var(--themeSecondaryColor_tint_7);
}

.CampaignHero--ksat-purple,
.CtaButton--ksat-purple {
    background-color: var(--purpleXDark);
    color: var(--whiteColor);
}

.CtaButton--text-black {
    color: var(--blackColor);
}

.CtaButton__link {
    padding: 14px 30px;
    color: currentColor;
}
