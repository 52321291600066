@define-mixin resetButtonStyles {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: visible !important;
  text-align: inherit !important;
  box-shadow: none !important;

  background-color: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  
  &:hover {
    box-shadow: none;
  }
}

/* The same as above but without important! */
@define-mixin resetButtonStylesNoOverride {
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: inherit;
  box-shadow: none;

  background-color: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  &:hover {
    box-shadow: none;
  }
}
