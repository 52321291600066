.MicrositeOverview {
  z-index: 10;
  top: 0;
  left: 0;

  &.MicrositeOverview.MicrositeOverview {
    & .MicrositeOverview__buttonOutline {
      @media (--desktop4k) {
        background-size: 72px;
      }
    }
  }

  &.MicrositeOverview--light {
    & .MicrositeOverview__outer,
    & .MicrositeOverview__heading,
    & .MicrositeOverview__link {
      color: var(--blackColor);
    }

    & .MicrositeOverview__link:before {
      @mixin arrowIcon var(--blackColor);
    }

    & .MicrositeOverview__buttonOutline {
      @mixin circleOutline var(--blackColor);
      background: svg-load('Icons/MicrositeClose.svg', stroke: var(--blackColor)) no-repeat;
      background-position: 50%;
    }
  }

  &.MicrositeOverview--dark {
    & .MicrositeOverview__outer,
    & .MicrositeOverview__heading,
    & .MicrositeOverview__link {
      color: var(--whiteColor);
    }

    & .MicrositeOverview__link:before {
      @mixin arrowIcon;
    }

    & .MicrositeOverview__buttonOutline {
      @mixin circleOutline;
      background: svg-load('Icons/MicrositeClose.svg', stroke: var(--whiteColor)) no-repeat;
      background-position: 50%;
    }
  }
}

.MicrositeOverview__outer {
  @mixin absoluteFullSize;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 32px;

  @media (--laptop) {
    padding: 32px var(--menuBarSize);
  }
}

.MicrositeOverview__inner {
  display: flex;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @media (--tablet) {
    margin: 32px;
    max-width: 75%;
  }

  @media (--laptop) {
    max-width: 600px;
  }

  @media (--desktop4k) {
    max-width: 40%;
  }
}

.MicrositeOverview__content {
  max-height: 100%;
  text-align: center;

  & p {
    @mixin pMicro;
  }
}

.MicrositeOverview__heading {
  @mixin h1Micro;
  margin-bottom: 32px;
}

.MicrositeOverview__link {
  @mixin linkMicro;
  padding-right: 24px;
  position: relative;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    &:before {
      right: 0;
    }
  }

  &:before {
    @mixin arrowIcon;
    position: absolute;
    content: "";
    right: 5px;
    transition: right 0.3s ease-in-out;
    transform: translateY(50%);

    @media (--desktop4k) {
      transform: none;
      top: calc(50% - 6px);
      right: 0;
    }
  }
}

.MicrositeOverview__button {
  @mixin resetButtonStyles;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 64px;
  height: 64px;

  @media (--tablet) {
    top: 32px;
    right: 32px;
  }
}
