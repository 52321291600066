.HeroHeader {
  --left-column-width: 618px;
  --right-column-width: auto;

  padding: var(--spacing-m) 0;
  min-height: 360px;

  @media (--tablet) {
    padding: calc(var(--spacing-xl) + calc(0.5 * var(--spacing-xxxs))) 0;
    min-height: 460px;
  }
  
  & .BackgroundHeroSection__heroImage::before {
    background: linear-gradient(color(#000 a(0%)), color(#000 a(100%)));
  }
}

.HeroHeader__title {
  @mixin typographyMobileDisplayLarge;

  text-wrap: balance;

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.HeroHeader__content {
  display: grid;
  gap: var(--spacing-m);

  @media (--tablet) {
    grid-template-columns: minmax(0, var(--left-column-width)) minmax(0, var(--right-column-width));
    gap: var(--spacing-xxxxxxl);
  }
  
  @media (--mobileOnly) {
    &:has(.Hero__photoCreditContainer) {
      margin-bottom: 1.5rem;
    }
  }
}

.HeroHeader__rightColumn {
  & > div {
    height: 100%;
  }
}

.HeroHeader__buttons {
  display: flex;
  gap: var(--spacing-s);
  margin-top: var(--spacing-l);
}
