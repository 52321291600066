.FacetFilter {
  margin-right: 0.4rem;
  cursor: pointer;
  transition: background-color .3s ease, color .3s ease;
  margin-bottom: 0.4rem;
  border: none;
  overflow: visible;
  text-align: inherit;
  box-shadow: none;
  padding: 0.5rem 0.7rem;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  @media (--laptop) {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: underline;
    box-shadow: none;
  }

  &.FacetFilter--active {
    background: var(--brandColor);
    color: var(--whiteColor);

    &:hover {
      color: var(--blackLighterColor);
    }
  }

  &:focus-within {
    outline: 1px solid black;
  }

  @media (--tablet) {
    margin-right: 1.3rem;
    padding: 0.6rem 1rem;
  }
}