.CallToAction {
  &.CallToAction--mediaPositionRight .CallToAction__inner {
    justify-content: flex-end;
    grid-template-columns: 5fr 4fr;
    
    @media (--tablet) {
      & .CallToAction__textWrapper {
        justify-content: end;
      }
      
      & .CallToAction__text {
        grid-column: 1 / 2;
        justify-self: end;
      }

      & .CallToAction__media {
        grid-column: 2 / 3;
        justify-self: start;
      }
    }

    @media (--cta-2-col-breakpoint) {
      flex-direction: row-reverse;

      & .CallToAction__mediaInner {
        justify-content: flex-start;
      }
    }
  }

  &.CallToAction--mediaPositionLeft .CallToAction__inner {
    justify-content: flex-start;
    grid-template-columns: 4fr 5fr;

    @media (--cta-2-col-breakpoint) {
      flex-direction: row;

      & .CallToAction__mediaInner {
        justify-content: flex-end;
      }
    }
  }
}
