/* stylelint-disable declaration-property-unit-allowed-list */
.Testimonial {
  position: relative;
  overflow: hidden;
  clear: both;
}

.Testimonial__inner {
  display: flex;
  flex-flow: row wrap;
  position: relative;

  @media (--tablet) {
    flex-flow: row wrap;
    flex-direction: row;
    align-items: center;
  }
}

.Testimonial__quote {
  width: 100%;
  margin: 1rem 0;

  @media (--tablet) {
    order: 2;
    margin-left: calc(200px + 4rem);
  }

  & blockquote {
    margin: 0;
  }
}

.Testimonial__author {
  width: 60%;
  align-self: center;
  display: flex;
  flex-flow: row wrap;

  @media (--tablet) {
    order: 3;
    align-self: flex-start;
    width: auto;
    margin-left: calc(200px + 4rem);
  }
}

.Testimonial__authorJobTitle::before {
    content: ", "
}

.Testimonial__picture {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 1rem;

  @media (--tablet) {
    width: 200px;
    order: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & picture {
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin: initial;

    @media (--tablet) {
      width: 200px;
      height: 200px;
    }
  }

  & img {
    min-height: 100%;
    width: 100%;
  }
}

.break::before {
  content: ',';
  display: inline-block;
  margin-right: 0.4rem;
}

.Testimonial.fullWidth {
  & .Testimonial__quote {
    @media (--tablet) {
      width: 100%;
      margin-left: initial;
    }
  }

  & .Testimonial__author {
    margin-left: 0;
  }
}

.Testimonial__heading {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.14;
  margin: 0 0 2rem;

  @media (--tablet) {
    font-size: 36px;
    margin: 0 0 3rem;
  }

  @media (--laptop) {
    font-size: 42px;
    margin: 0 0 4rem;
  }
}

.Testimonial__background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 130%;
  height: 130%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(15px);
  opacity: 0.4;
}