.SectionsNavbar {
  padding: var(--spacing-xs) 0 calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs)));
  display: flex;
  gap: var(--spacing-xxs);
  flex-direction: column;

  @media (--laptop) {
    padding: calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs))) var(--spacing-xl)
      calc(var(--spacing-xs) + var(--spacing-xxxs));
    justify-content: space-between;
    gap: var(--spacing-xl);
    flex-direction: row;
  }
}

.SectionsNavbar__sliderWrapper {
  flex: 1;
  overflow: hidden;
}

.SectionsNavbar__slider {
  max-width: 996px;

  &.splide {
    & .splide__list {
      list-style: none;
      padding-block: 2px;
      margin: 0;
      scroll-behavior: smooth;

      @media (--laptop) {
        padding: 0;
      }

      @media (--mobileAndTablet) {
        user-select: none;
        /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.is-initialized:not(.is-active) {
      & .splide__list {
        display: flex;
      }
    }
  }
}

.SectionsNavbar__slideLink {
  white-space: nowrap;

  &.SectionsNavbar__slideLink--active {
    &:not(:focus-visible, :active) {
      &.ButtonGhost {
        background: var(--base-grey-light-100);
        border-color: transparent;

        &.ButtonGhost--teal {
          background: var(--accent-teal-accent-dark);
          border-color: transparent;
        }
      }
    }
  }

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline-offset: -2px !important;
  }
}

.SectionsNavbar__actionButtons {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);

  @media (--mobileAndTablet) {
    & > a {
      flex: 1;
      text-align: center;
    }
  }

  @media (--mobileAndTablet) {
    padding: 0 var(--spacing-s);
  }

  @media (--laptop) {
    gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  }
}
