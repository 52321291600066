.EventCampaignPageHeaderSideContent {
  min-width: 245px;

  & .EventCampaignPageHeaderSideContent__title {
    @mixin typographyDesktopStrongXXLarge; /* Desktop/Strong/xx-Large */
  }

  & .EventCampaignPageHeaderSideContent__information {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    border-block: 1px solid var(--white-0);
    margin: var(--spacing-s) 0;
    padding: var(--spacing-s) 0;
  }

  & .EventCampaignPageHeaderSideContent__timeText {
    @mixin typographyDesktopDefaultMedium; /* Desktop/Default/medium */
  }

  & .EventCampaignPageHeaderSideContent__locationText {
    @mixin typographyDesktopDefaultMedium; /* Desktop/Default/medium */
  }

  & .EventCampaignPageHeaderSideContent__hint {
    @mixin typographyDesktopDefaultSmall; /* Desktop/Default/small */

    color: var(--blue-400);
    max-width: 245px;
  }

  & .EventCampaignPageHeaderSideContent__icon {
    width: 16px;
    height: 16px;
  }

  & .EventCampaignPageHeaderSideContent__clock {
    background: svg-load("Icons/Clock.svg", stroke: var(--white-0)) no-repeat;
  }

  & .EventCampaignPageHeaderSideContent__marker {
    background: svg-load("Icons/Marker.svg", fill: var(--white-0)) no-repeat;
  }

  & .EventCampaignPageHeaderSideContent__segment {
    display: flex;
    gap: var(--spacing-xxs);
    width: fit-content;
    align-items: center;
  }
}