@define-mixin quoteWrapper {
  max-width: 65rem;
  margin-inline: auto;
}

.Testimonial {
  padding: var(--spacing-l) var(--spacing-s) var(--spacing-xxl);
  max-width: 90rem;
  margin-inline: auto;

  @media (--laptop) {
    padding-block: var(--spacing-xxxxxl) var(--spacing-xxxxxxxxl);
  }

  & .wrapper {
    width: 100%;
  }

  &:has(.Testimonial__picture) {
    @media (--laptop) {
      padding: var(--spacing-l);
      padding-inline-start: 0;
    }

    & .wrapper {
      margin-left: 0;
      max-width: unset;
    }
  }
}

.Testimonial__heading {
  display: none;
}

.Testimonial__quote {
  @mixin typographyMobileDisplayLarge;

  margin: 0 0 var(--spacing-l);

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;

    margin: 0 0 var(--spacing-xxxl);
  }

  blockquote {
    display: inline;

    &::before {
      content: "\0201C";
    }

    &::after {
      content: "\0201D";
    }
  }
}

.Testimonial__author {
  flex-direction: column;

  @media (--tablet) {
    margin-inline-start: 0;
  }
}

.Testimonial__authorName {
  @mixin typographyMobileStrongXLarge;

  @media (--laptop) {
    @mixin typographyDesktopStrongXLarge;
  }
}

.Testimonial__authorJobTitle {
  @mixin typographyMobileDefaultMedium;

  &::before {
    content: none;
  }

  @media (--laptop) {
    @mixin typographyDesktopDefaultMedium;

    margin-block-start: var(--spacing-xs);
  }
}

.Testimonial__picture {
  width: calc(100% + var(--spacing-s) * 2);
  grid-row: 1 / 2;
  aspect-ratio: 331/197;
  overflow: hidden;
  margin: calc(var(--spacing-l) * -1) calc(var(--spacing-s) * -1) var(--spacing-s);

  & picture {
    max-width: 100%;
    width: auto;
    height: auto;
    border-radius: 0;
    border: unset;
  }

  @media (--tablet) {
    position: static;
    transform: none;
  }

  @media (--laptop) {
    grid-row: 1/-1;
    grid-column: 1/2;
    aspect-ratio: 261/364;
    max-width: 364px;
    margin: calc(var(--spacing-l) * -1) 0;
    width: 100%;

    & picture {
      max-width: unset;
      height: 100%;
    }
  }
}

.Testimonial__inner {
  display: grid;
  grid-template: repeat(2, auto) / auto;

  &:has(.Testimonial__picture) {
    grid-template: 1fr auto / 1fr;

    .Testimonial__authorJobTitle {
      margin-block-start: var(--spacing-xxs);
    }

    @media (--laptop) {
      grid-template: 1fr auto / minmax(auto, 261px) auto;
      gap: var(--spacing-s);

      .Testimonial__quote,
      .Testimonial__author {
        grid-column: 2/3;
        width: 100%;
      }

      .Testimonial__quote {
        @mixin typographyDesktopStrongXLarge;
        @mixin quoteWrapper;

        align-self: start;
      }

      .Testimonial__author {
        @mixin quoteWrapper;
      }

      .Testimonial__authorName {
        @mixin typographyDesktopStrongSmall;
      }

      .Testimonial__authorJobTitle {
        @mixin typographyDesktopDefaultSmall;

        margin-block-start: var(--spacing-xxs);
      }
    }
  }
}
