.SocialMedia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-s);


    @media (--tablet){
        align-items: end;
    }
}

.SocialMedia__heading {
    margin: 0;

    @mixin typographyMobileStrongSmall;

    @media (--desktop) {
        @mixin typographyDesktopStrongSmall;
    }
}

.SocialMedia--section {
    @media (--tablet){
        flex-direction: row;
        align-items: center;

        & .SocialMedia__heading {
            width: calc(29 * var(--spacing-xs));
            @mixin typographyDesktopDisplayLarge;
        }
    }
}

.SocialMedia__icons {
    display: flex;
    align-items: center;
    gap: var(--spacing-m);
}

.Icon {
    display: block;
    width: 32px;
    height: 32px;
}

.Icon--instagram {
    background: svg-load('Icons/SocialMedia/Instagram.svg') no-repeat;
}

.Icon--facebook {
    background: svg-load('Icons/SocialMedia/Facebook.svg') no-repeat;
}

.Icon--x {
    height: 28px;
    background: svg-load('Icons/SocialMedia/X.svg') no-repeat;
}

.Icon--threads {
    background: svg-load('Icons/SocialMedia/Threads.svg') no-repeat;
}

.Icon--linkedin {
    background: svg-load('Icons/SocialMedia/LinkedIn.svg') no-repeat;
}

.Icon--youtube {
    background: svg-load('Icons/SocialMedia/Youtube.svg') no-repeat;
    width: 43px;
}