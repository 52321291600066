.QuoteBlock__richText {
  & ul,
  & ol {
    & > li {
      text-align: left;
    }
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
