.Section.ProductIntroductionSection {
  padding-block: var(--spacing-xxxl);

  @media (--laptop) {
    padding-block: calc(var(--spacing-xxxxxxxl) + var(--spacing-xxs));
  }
}

.ProductIntroductionSection__container {
  --max-column-width: 463px;

  display: grid;
  row-gap: var(--spacing-xxxl);

  @media (--tablet) {
    grid-template-columns: 3fr 2fr;
    column-gap: var(--spacing-m);
  }

  @media (--laptop) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--spacing-xl);
  }

  @media (--desktop) {
    grid-template-columns: var(--max-column-width) 1fr 2fr;
    column-gap: 0;

    & > *:first-child {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    & > *:nth-child(2) {
      grid-row: 1/2;
      grid-column: 3/4;
    }

    & > *:nth-child(3) {
      grid-row: 2/3;
      grid-column: 1/2;
    }
  }
}

.ProductIntroductionSection__introductionHeader {
  @mixin typographyMobileStrongLarge;

  margin-block-end: var(--spacing-s);

  @media (--tablet) {
    @mixin typographyDesktopStrongLarge;

    margin-block-end: var(--spacing-s);
  }
}

.ProductIntroductionSection__introduction {
  @mixin typographyMobileDefaultLarge;

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;
  }
}

.ProductIntroductionSection__awards {
  max-width: var(--max-column-width);
}

.ProductIntroductionSection__awardsHeading {
  @mixin typographyMobileStrongLarge;

  margin-block-end: var(--spacing-s);

  @media (--tablet) {
    @mixin typographyDesktopStrongLarge;

    margin-block-end: var(--spacing-s);
  }
}

.ProductIntroductionSection__awardsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-s) + 2px);
}

.ProductIntroductionSection__awardsItem {
  position: relative;
  display: grid;
  column-gap: var(--spacing-s);
  grid-template: repeat(2, 1fr) / 3rem 1fr;
  grid-template-areas:
    "image title"
    "image subtitle";

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: calc(var(--spacing-xs) * -0.75);
    height: 2px;
    width: 100%;
    background: var(--base-grey-medium-100);
    opacity: 0.2;
  }
}

.ProductIntroductionSection__awardsItemImageWrapper {
  width: 3rem;
  height: 3rem;
  grid-area: image;
}

.ProductIntroductionSection__awardsItemImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ProductIntroductionSection__awardsItemName {
  grid-area: title;
  align-self: end;
  @mixin typographyMobileStrongSmall;

  @media (--tablet) {
    @mixin typographyDesktopStrongSmall;
  }
}

.ProductIntroductionSection__awardsItemDate {
  grid-area: subtitle;
  align-self: flex-start;
  @mixin typographyMobileDefaultSmall;

  @media (--tablet) {
    @mixin typographyDesktopDefaultSmall;
  }
}

.ProductIntroductionSection__tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--spacing-xxs) * 0.75);
}

.ProductIntroductionSection__tagsItem {
  @mixin typographyMobileCapsLarge;

  display: flex;
  align-items: center;
  height: 1.3125rem; /* 21px */
  border-radius: 14px;
  background: var(--base-grey-light-80);
  color: var(--base-grey-dark-100);
  padding: 0 var(--spacing-xxs);

  @media (--tablet) {
    @mixin typographyMobileCapsDefault;
  }
}
