.RichFormContainer {
    background-color: var(--kog_bronze_tint_2);
    padding: 3rem 0;

    @media (--laptop) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30vh 0 15vh;
    }

    & .Form__MainBody > section {
        margin: 0;

        & button:last-child {
            margin-bottom: 0;
        }
    }

    & .EPiServerForms {
        padding: 0;
    }

    & form {
        max-width: 750px;
    }
}