.TrainingCentersTeaser {
  position: relative;
  min-height: 355px;
  width: 100%;
  display: flex;
  flex-flow: column;
  float: right;
  margin-top: 2rem;
  z-index: 1;
  
  @media (--tablet) {
    width: 360px;
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  & a {
    color: var(--whiteColor);
    display: flex;
    flex-flow: row wrap;
  }

  & a:focus {
    outline-color: var(--outlineColorSecondary);
  }
}

.TrainingCentersTeaser__inner {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  color: var(--whiteColor);
  z-index: 1;
  padding: 2rem 1.4rem 2.6rem 1.4rem;

  @media(--tablet) {
    padding: 2.5rem 1.6rem 4rem 1.6rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: svg-load('Common/Background.svg',
    fill: var(--brandColor)) bottom right no-repeat;
    z-index: -1;
  }
}

.TrainingCentersTeaser__header {
  font-size: 24px;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-bottom: 5px;
  position: relative;
  line-height: 1.1;

  @media (--tablet) {
    font-size: 32px;
    margin-bottom: 35px;
  }
}

.TrainingCentersTeaser__description {
  font-size: 16px;
  margin-bottom: 16px;
  width: 100%;
  min-height: initial;
  position: relative;

  @media (--tablet) {
    font-size: 20px;
  }
}

.TrainingCentersTeaser__readMore {
  @mixin buttonWithIcon arrowRightSimple, white;
  text-transform: none;
  margin-top: 0.8rem;

  @media(--tablet) {
    margin-top: 2rem;
  }
  
  &::after {
    background-size: 95%;
  }
}

.TrainingCentersTeaser__email {
  @mixin buttonWithIcon iconEmailEnvelope, white;
  text-transform: none;
  padding-left: 0;
  margin-top: 0.8rem;
  
  @media(--tablet) {
    margin-top: 2rem;
  }
  
  & a {
    line-height: 1.2;
  }
}

.TrainingCentersTeaser__link {
  margin-top: 1.5rem;
  width: 200px;
  height: 60px;

  @media(--tablet) {
    margin-top: 2.5rem;
  }
}

.TrainingCentersTeaser__address a:focus,
.TrainingCentersTeaser__phone a:focus,
.TrainingCentersTeaser__readMore:focus {
  outline-color: aqua;
}