/* stylelint-disable selector-class-pattern */
/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-id-pattern */
#onetrust-consent-sdk {
  &,
  & a,
  & input,
  & button {
    transition: 0.1s ease-in all !important;
  }

  & a:focus,
  & input:focus,
  & button:focus,
  & #onetrust-pc-sdk .ot-tgl input:focus + .ot-switch,
  & #onetrust-banner-sdk:focus {
    outline: 1px solid var(--outlineColorSecondary) !important;
    opacity: 1 !important;
  }

  & #ot-sdk-btn-floating {
    & .ot-floating-button__front {
      & svg {
        margin: 0 auto;
      }
    }
  }
}
