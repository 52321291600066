@define-mixin wrapper $maxWidth: var(--wrapperWidth), $spacing: var(--wrapperPadding) {
  margin-right: auto;
  margin-left: auto;
  max-width: $maxWidth;
  width: calc(100% - ($spacing * 2));
}

@define-mixin resetWrapper {
  margin-right: 0;
  margin-left: 0;
  max-width: none;
  width: auto;
}

@define-mixin richCampaignWrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: var(--wrapperWidth);
  width: calc(100% - (var(--wrapperPadding) * 2));

  @media (--tablet) {
    width: calc(100% - (var(--wrapperPadding) * 2));
  }
}

@define-mixin wrapperKsat {
  margin-right: auto;
  margin-left: auto;
  max-width: var(--wrapperWidthKsat);
  width: calc(100% - 30px);

  @media (--tablet) {
    width: calc(100% - 60px);
  }

  @media (--laptop) {
    width: calc(100% - 200px);
  }

  @media (--desktop) {
    width: 100%;
  }
}

@define-mixin wrapperStretched {
  @mixin wrapper var(--wrapper-stretched-width), 0;
}

@define-mixin wrapperDiscoveryWideMedia {
  @mixin wrapper var(--wrapperWideMediaWidth), 0;
}
