.StoryTextContent--themeDark {
  color: var(--whiteColor);
  background-color: var(--blackColor);

  & .StoryTextContent__background {
    &::before {
      display: none;

      @media (--laptop) {
        display: block;
        background-color: color(var(--blackColor));
        opacity: var(--storiesBlockFinalOpacity);
      }
    }
  }

}

.StoryTextContent--themeLight {
  color: var(--blackColor);
  background-color: var(--whiteColor);

  & .StoryTextContent__background {
    @media (--laptop) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: 50%;
      background-size: cover;
    }

    &::before {
      background-color: color(var(--whiteColor));
      opacity: var(--storiesBlockInitialOpacityLight);
      display: none;

      @media (--laptop) {
        display: block;
        opacity: var(--storiesBlockInitialOpacityLight);
      }
    }
  }
}