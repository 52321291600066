.ResetFilters {
  @mixin resetButtonStylesNoOverride;
  @mixin typographyDesktopStrongMedium;

  display: flex;
  gap: 6px;
  padding: var(--spacing-xxs) var(--spacing-xs) var(--spacing-xxs) 0;
  color: var(--accent-teal-accent-dark);
  cursor: pointer;
  @mixin focusVisible;

  & svg {
    font-size: 1.5rem;
  }
}
