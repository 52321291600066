.AddressSection {
  margin-top: 2rem;
  margin-bottom: 2rem;
  
  & iframe {
    background: var(--whiteColor);
    max-width: 100%;
    min-width: 100%;
    @media (--laptop) {
      width: 65%;
      height: 100%;
      min-height: 395px;
    }
  } 
  
}

.AddressSection__wrapper {
  @mixin wrapper;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  @media (--laptop) {
    flex-wrap: nowrap;
  }
}

.AddressSection__iframe {
  width: 100%;
  @media (--laptop) {
    width: 65%;
  }
}

.AddressSection__container {
  width: 100%;
  @media (--laptop) {
  }
  
  background-color: var(--kog_blue_50);
  padding: var(--spacing-xs) calc(var(--spacing-xxxs) + var(--spacing-xxs));
  display: flex;
  flex-flow: column nowrap;
  list-style-type: none;
  
  div {
    padding-bottom: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    border-bottom: 1px solid var(--kog_blue_300);
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &:first-child {
      padding-top: 0;
    }
  }
 
  @media (--laptop) {
      width: 35%; 
      padding-bottom: 2rem;
  }
}

.AddressSection__title {

  @mixin typographyDesktopStrongLarge;

  color: var(--kog_blue_tint_1);
  margin-bottom: calc(var(--spacing-xxs) + var(--spacing-xxxs));

  @media (--mobileOnly) {
    font-size: 1rem;
  }
}

.AddressSection__subtitle {
  font-size: 20px;
  margin: 0 0 1rem 0;

  @media (--tablet) {
    font-size: 24px;
  }
}

.AddressSection__address {
  & span {
    display: block;
  }
  border-bottom: 1px solid var(--kog_blue_300);
}

.AddressSection__contactInfo {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  
  & li {
    margin-bottom: 0.6rem;
  }
  & li:last-of-type {
    margin-bottom: 0;
  }
}

.AddressSection__additionalInfo {
  margin-bottom: 2rem;
  
  @media (--tablet) {
    min-height: 120px;
    margin-bottom: 0;
  }
}

.AddressSection__richtextArea {
  & p {
    margin: 0 0 0.3rem 0;
  }
}

.AddressSection__links {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  padding-top: calc(var(--spacing-xxs) + var(--spacing-xxxs));
}