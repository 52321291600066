.StoriesHeader {
  display: flex;
  align-items: center;
  height: 50px;
  position: relative;

  @media (--tablet) {
    height: 80px;
  }

  & .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  & h1 {
    font-family: var(--font-family-stories-secondary);
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 2px;
    margin: 0;
    user-select: none;

    @media (--tablet) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 18px;
    }

    @media (--laptop) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 20px;
    }
  }

  & .StoriesHeader__topNavLink {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    user-select: none;

    @media (--laptop) {
      display: flex;
    }
  }
}
