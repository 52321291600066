.RichCallToAction {
  width: 100%;
  overflow: hidden;
}

.RichCallToAction__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @mixin richCampaignWrapper;
}

.RichCallToAction__readMore {
  align-self: center;
}

.RichCallToAction .Btn--explore {
  height: auto;
  @media (--tablet) {
    max-width: 400px;
  }
}

.RichCallToAction__title {
  @mixin h2;
}

.RichCallToAction__text {
  width: 100%;
  display: flex;
  align-items: flex-start;

  @media (--tablet) {
    width: 65%;
  }
  @media (--laptop) {
    width: 60%;
  }

  & p,
  & ul,
  & ol {
    margin: 0 0 1rem;
    word-break: break-word;

    @media (--tablet) {
      margin-bottom: 2.6rem;
    }
  }

  & .Btn--explore + .Btn--explore {
    margin-top: 0.5rem;

    @media (--tablet) {
      margin-top: 1rem;
    }
  }
}

.RichCallToAction__media {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  max-height: 360px;
  min-height: 150px;

  @media (--tablet) {
    width: 35%;
    margin-bottom: 1rem;
    min-height: 190px;
  }

  @media (--laptop) {
    margin-bottom: 3.5rem;
    min-height: 250px;
  }

  & picture,
  & .RichCallToAction__video {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transform: none;
    transition: all 0.3s var(--ease_out_cubic);

    &::before {
      content: "";
      width: 100%;
      padding-top: 70%;
      display: block;

      @media (--tablet) {
        padding-top: 65%;
      }
    }
  }

  & img,
  & video,
  & svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.RichCallToAction__mediaInner {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
}

.RichCallToAction__mediaIcon {
  width: 100%;
  height: 100%;

  @media (--tablet) {
    width: 65%;
    height: 65%;
    position: relative;
  }
}

.RichtextArea .RichCallToAction a,
.RichtextArea .RichCallToAction a:visited,
.RichtextArea .mceContentBody .RichCallToAction a,
.RichtextArea .mceContentBody .RichCallToAction a:visited {
  color: inherit;
}

.RichtextArea .RichCallToAction {
  margin-bottom: 4rem;
}
