.LinkArrowBlockList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.LinkArrowBlockList__itemReadMore {
  display: none;

  @media (--laptop) {
    @mixin typographyMobileStrongLarge;

    display: initial;
    opacity: 0;
    transition: opacity 0.2s;
    margin-bottom: 1px;
    text-wrap: nowrap;
  }
}

.LinkArrowBlockList__itemArrowWrapper {
  display: flex;
  align-items: center;
  gap: calc(1.25 * var(--spacing-xxxs));
  transition:
    transform 0.3s,
    background 0.2s,
    color 0.2s;
  height: 2.5rem; /* 40px; */
  color: var(--accent-blue-accent-default);

  @media (--laptop) {
    padding: 0 calc(var(--spacing-xs) + var(--spacing-xxxs));

    &:hover {
      background: var(--accent-blue-accent-light);
      color: var(--accent-blue-accent-darker);
    }
  }
}

.LinkArrowBlockList__itemLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: var(--spacing-s);
  padding: var(--spacing-xs) var(--spacing-s);
  transition: all 0.3s;
  @mixin focusVisible var(--outlineColorSecondary);

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 1px;
    background: #cfd1d3;
    transition: all 0.3s;
  }

  @media (--laptop) {
    padding: var(--spacing-s) calc(1.75 * var(--spacing-xxs)) var(--spacing-s) var(--spacing-xxs);
  }

  &:hover {
    background: var(--kog_blue_tint_7);

    &::after {
      height: 2px;
      background: var(--accent-blue-accent-default);
    }

    & .LinkArrowBlockList__itemReadMore {
      opacity: 1;
    }

    @media (--laptop) {
      & .LinkArrowBlockList__itemArrowWrapper {
        transform: translateX(calc(1.25 * var(--spacing-xxxs)));
      }
    }
  }
}

.LinkArrowBlockList__itemTextsWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  flex: 1;

  @media (--laptop) {
    gap: var(--spacing-s);
    flex-direction: row;
  }
}

.LinkArrowBlockList__itemTitle {
  @mixin typographyMobileStrongLarge;

  flex: 1;

  @media (--laptop) {
    @mixin typographyDesktopStrongLarge;
  }
}

.LinkArrowBlockList__itemLocationWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  flex: 1;

  & svg {
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    color: var(--accent-blue-accent-default);
  }

  @media (--tablet) {
    & svg {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.LinkArrowBlockList__itemLocationText {
  @mixin typographyMobileDefaultSmall;

  @media (--tablet) {
    @mixin typographyDesktopDefaultSmall;
  }
}
