.Footer__topSection {
  &.Footer__topSection--blue {
    background-color: var(--accent-blue-accent-darker);
  }

  &.Footer__topSection--green {
    background-color: var(--accent-teal-accent-darker);
  }
}

.Footer__horizontalLogoKog {
  width: var(--footer-kog-horizontal-logo-width);
  min-width: var(--footer-kog-horizontal-logo-width);
  height: var(--footer-kog-horizontal-logo-height);

  @media (--laptop) {
    display: none;
  }
}

.Footer__verticalLogoKog {
  width: var(--footer-kog-vertical-logo-width);
  min-width: var(--footer-kog-vertical-logo-width);
  height: var(--footer-kog-vertical-logo-width);
  display: none;

  @media (--laptop) {
    display: initial;
  }

  &.Footer__verticalLogoKog--small {
    width: calc(var(--footer-kog-vertical-logo-width) * 0.67);
    min-width: calc(var(--footer-kog-vertical-logo-width) * 0.67);
    height: calc(var(--footer-kog-vertical-logo-width) * 0.67);
  }
}

.Footer__logoKsat {
  display: none;
}
