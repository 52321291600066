.react-autosuggest__container {
  position: relative;
  width: 85%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;

  @media (--tablet) {
    display: block;
    width: 100%;
    margin: 0 auto;
    height: 65px;
    flex-flow: row nowrap;
  }  
  
  @media (--laptop) {
    width: 100%;
  }

  & .NavMobile__hamburgerContainer {
    position: fixed;
    right: -8px;

    @media (--tablet) {
      display: none;
    }
  }
}