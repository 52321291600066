@define-mixin full {
  width: 100%;
  height: 100%;
}

.BlurredBackground {
  @mixin full;
  position: fixed;
  z-index: 0;
  top: 0;

  @media (--laptop) {
    top: initial;
  }
}


.BlurredBackground__container {
  @mixin full;
  position: absolute;
  overflow: hidden;
}

.BlurredBackground__outer {
  @mixin full;
  position: relative;
}

.BlurredBackground__backgroundWrapper {
  @mixin full;
}
