.StoryHeaderInfo--edit-mode,
.StoryHeaderInfo--previewMode {
  & .StoryHeaderInfo__media {
    max-height: 1200px;
  }

  & .StoryHeaderInfo__background {
    height: 100% !important;

    & > div {
      height: 100% !important;
    }
  }

  & .StoryHeaderInfo__header {
    min-height: 100% !important;
  }
}
