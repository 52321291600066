@define-mixin storyProductColorVariant $backgroundColor, $fontColor, $secondaryBtnVariant {
  background: $backgroundColor;

  & .StoryProduct__content {
    color: $fontColor;
  }

  & .StoryProduct__button {
    @mixin $(secondaryBtnVariant);
  }
}

.StoryProduct {
  &.StoryProduct--backgroundColorGreyTwo {
    @mixin storyProductColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color), buttonSecondaryThemeColorGreyTwo;
  }

  &.StoryProduct--backgroundColorGreenFour {
    @mixin storyProductColorVariant var(--theme-color-green-bg-color),
      var(--theme-color-green-text-color), buttonSecondaryThemeColorGreenFour;
  }

  &.StoryProduct--backgroundColorBlueFive {
    @mixin storyProductColorVariant var(--theme-color-blue-bg-color),
      var(--theme-color-blue-text-color), buttonSecondaryThemeColorBlueFive;
  }

  &.StoryProduct--backgroundColorTealTwo {
    @mixin storyProductColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color), buttonSecondaryThemeColorTealTwo;
  }

  &.StoryProduct--backgroundColorSandFour {
    @mixin storyProductColorVariant var(--theme-color-brown-bg-color),
      var(--theme-color-brown-text-color), buttonSecondaryThemeColorSandFour;
  }

  &.StoryProduct--backgroundColorTealFive {
    @mixin storyProductColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color), buttonSecondaryThemeColorTealFive;
  }

  &.StoryProduct--backgroundColorYellowFour {
    @mixin storyProductColorVariant var(--theme-color-yellow-bg-color),
      var(--theme-color-yellow-text-color), buttonSecondaryThemeColorYellowFour;
  }

  &.StoryProduct--backgroundColorGreenTwo {
    @mixin storyProductColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color), buttonSecondaryThemeColorGreenTwo;
  }

  &.StoryProduct--backgroundColorBlueTwo {
    @mixin storyProductColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color), buttonSecondaryThemeColorBlueTwo;
  }

  &.StoryProduct--backgroundColorSandTwo {
    @mixin storyProductColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color), buttonSecondaryThemeColorSandTwo;
  }

  &.StoryProduct--backgroundColorForestTwo {
    @mixin storyProductColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color), buttonSecondaryThemeColorForestTwo;
  }

  &.StoryProduct--backgroundColorGreenOne {
    @mixin storyProductColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color), buttonSecondaryThemeColorGreenOne;
  }

  &.StoryProduct--backgroundColorGreenFive {
    @mixin storyProductColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color), buttonSecondaryThemeColorGreenFive;
  }

  &.StoryProduct--backgroundColorForestFive {
    @mixin storyProductColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color), buttonSecondaryThemeColorForestFive;
  }

  &.StoryProduct--backgroundColorBlueThree {
    @mixin storyProductColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color), buttonSecondaryThemeColorBlueThree;
  }

  &.StoryProduct--backgroundColorYellowTwo {
    @mixin storyProductColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color), buttonSecondaryThemeColorYellowTwo;
  }

  &.StoryProduct--backgroundColorYellowFive {
    @mixin storyProductColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color), buttonSecondaryThemeColorYellowFive;
  }

  &.StoryProduct--backgroundColorRedFour {
    @mixin storyProductColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color), buttonSecondaryThemeColorRedFour;
  }

  &.StoryProduct--backgroundColorGreyFive {
    @mixin storyProductColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color), buttonSecondaryThemeColorGreyFive;
  }
}
