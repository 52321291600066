.Tile {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-s) + var(--spacing-xxxs));
  color: var(--base-grey-black-100);
  height: 100%;

  & .Tile__image {
    border-radius: 2px;
    background-color: #ececec;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      aspect-ratio: 1 / 1;
    }

    & svg {
      width: 1em;
      height: 1em;
      fill: currentColor;
      display: block;
      object-fit: contain;
    }

    &.Tile__image--icon {
      font-size: 11.25rem;
      color: var(--blackColor);
      width: calc(100% - 4rem);
      min-height: 11.25rem;
      padding: 2rem;
      box-sizing: content-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.Tile__image--fallbackImage {
      & img {
        object-fit: cover;
      }
    }
  }

  & .Tile__title {
    @mixin typographyDesktopStrongXXLarge;

    margin-bottom: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  }

  & .Tile__description {
    @mixin typographyDesktopDefaultXLarge;

    margin-bottom: var(--spacing-s);
  }

  & .Tile__links {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxxs);
    padding: 0;
    margin-bottom: calc(var(--spacing-xs) + var(--spacing-xxxs));
    list-style: none;
  }

  & .Tile__link {
    @mixin typographyDesktopDefaultLarge;

    color: inherit;
    text-decoration: underline;
    @mixin focusVisible var(--outlineColorSecondary), 2px, 2px;
  }

  & .Tile__header {
    *:last-child {
      margin-bottom: 0;
    }
  }

  &:not(.Tile--withImage) {
    & .Tile__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: calc(var(--spacing-s) + var(--spacing-xxxs))
        calc(var(--spacing-xs) + var(--spacing-xxxs) + (var(--spacing-xxxs) / 2)) var(--spacing-m);
      height: 100%;

      &.Tile__content--withLinks {
        row-gap: var(--spacing-xxxl);
      }
    }
  }
}

.Tile--small {
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));

  & .Tile__title {
    @mixin typographyDesktopStrongLarge;

    margin-bottom: var(--spacing-xs);
  }

  & .Tile__description {
    @mixin typographyDesktopDefaultMedium;

    margin-bottom: var(--spacing-s);
  }

  & .Tile__link {
    @mixin typographyDesktopDefaultUnderlinedLarge;
  }
}
