@import "../../../node_modules/cookieconsent/build/cookieconsent.min.css";

.cc-window[role='dialog'] {
  background: var(--kog_blue);
  color: var(--whiteColor);
  font-family: var(--FamilyPressura);
  max-width: none;

  & .cc-link, & .cc-link:active, & .cc-link:visited {
    color: var(--whiteColor);
    opacity: 1;
  }

  & .cc-link:hover{
    opacity: 0.8;
  }
}

.cc-window .cc-btn {
  color: var(--whiteColor);
  font-weight: normal;

  &.cc-allow {
    background: var(--kog_blue_tint_2);
  }
}

.cc-revoke.cc-bottom {
  display:none;
}

@media (--mobileAndTablet) {
    .cc-window.cc-banner {
        flex-direction: column;
        -ms-flex-direction: column;
        align-items: stretch;
        -ms-flex-align: stretch;
    }

    .cc-banner .cc-message {
        margin-bottom: 1em;
    }

    .cc-compliance {
        justify-content: flex-end;
        -ms-flex-pack: end;
    }
}
