.RichVideo .RichVideo__intro,
.RichVideo .RichVideo__title {
  &,
  & * {
    color: var(--base-grey-white);
  }
}

.RichVideo--White {
  & .RichVideo__title,
  & .RichVideo__intro {
    &,
    & * {
      color: var(--whiteColor);
    }
  }
}

.RichVideo--Black {
  & .RichVideo__title,
  & .RichVideo__intro {
    &,
    & * {
      color: var(--blackColor);
    }
  }
}
