.ArticlesArchive__content {
  margin-bottom: 6rem;
}

.ArticlesArchive__list {
  margin: 2rem 0;
  padding: 0;
  
  @media (--tablet) {
    margin: 3rem 0;
  }

  @media (--laptop) {
    margin: 5rem 0;
  }
}

.ArticlesArchive__list--1col {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  & > li {
    margin: 30px 0;
    
    @media (--tablet) {
      margin: 50px 0;
    }
  }  
}

.ArticlesArchive__loadMore {
  margin: 90px 0 0;

  & button {
    margin: 0 auto;
  }
}

.ArticlesArchive__resultsPlaceholder {
  font-size: 1.5rem;
  margin: 60px 0;
}


.ArticlesArchiveFiltersList {
  @neat-outer-container;
  @neat-omega: n;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  @media (--tablet) {
    display: flex;
    flex-flow: row wrap;
  }

  & > li {
    @neat-span-columns 12;

    @media (--tabletOnly) {
      @neat-span-columns 6;
      @neat-omega 2n;
      max-width: 48.6%;
    }

    @media (--laptop) {
      @neat-span-columns 4;
      @neat-omega 3n;
      max-width: 31.5%;
    }
  }
}

.ArticlesArchiveFiltersList__item {
  margin-bottom: 20px;
}

.ArticlesArchiveFiltersActive {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.ArticlesArchiveFiltersActive__chip {
  display: block;
  margin: 0 16px 16px 0;

  &:last-child {
    margin-right: 0;
  }
}

.ArticlesArchiveFiltersActive__chipClearBtn {
  @mixin articlesArchiveFiltersChip;
  margin: 0;
  background-color: color(var(--kog_blue_tint_1) a(80%));
  text-transform: none;
  width: auto;
  border-radius: 3px;
  padding: 4px 12px;
  height: 25px;

  &::before {
    content: '';
    display: block;
    background: svg-load('Icons/Close.svg', stroke: var(--whiteColor)) no-repeat center;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
}
