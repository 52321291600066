@define-mixin clickDimensionFormDarkTheme {
    & .ClickDimensionsForm {
      @mixin clickDimensionFormColorVariant var(--blue-950), var(--blue-100), buttonPrimaryBlueFill, , var(--accent-blue-accent-darker)
    }
}

.Page--dark {
  @mixin clickDimensionFormDarkTheme;
}

@media (--dark) {
  .Page:not(.Page--light) {
      @mixin clickDimensionFormDarkTheme;
  }
}