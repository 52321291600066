:root {
  --playVideoButtonSize: 2.8125rem; /* 45px */
  --playVideoButtonSizeDesktop: 4.375rem; /* 70px */
  --playVideoButtonBorderWidth: 0.3125rem; /* 5px */
  --playVideoButtonBorderWidthDesktop: 0.375rem; /* 6px */
}

.Hero--withVideo {
  & .Hero__headingContainer {
    & .Hero__heading {
      & h1 {
        margin-block: 0 calc(var(--playVideoButtonSize) + var(--spacing-xxl));
        padding-bottom: calc(var(--spacing-s) / 2);
        max-width: 370px;

        @media (--mobileSmall) {
          max-width: 85%;
        }

        @media (--laptop) {
          margin-block: 0 calc(var(--playVideoButtonSizeDesktop) + var(--spacing-xl));
          padding-bottom: calc(var(--spacing-l));
          max-width: var(--hero-heading-max-width);
        }
      }

      & .Hero__titleText {
        display: none;
      }      
    }
  }

  & .Hero__link {
    &::after {
      display: none;
    }
  }
}

.Hero--withVideo:not(.Hero--homePage) {
  & .Hero__headingContainer {
    & .Hero__heading {
      & h1 {
        margin-block: 0;
        padding-bottom: 0;
      }
    }
  }

  & .Hero__picture {
    @media (--laptop) {
      display: none;
    }
  }

  & .Hero__videoContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: center;

    @media (--laptop) {
      display: flex;
    }
  }

  & .Hero__video {
    width: 100%;
    display: flex;
    align-items: center;
  }

  & video {
    width: 100%;
  }
}

.Hero__playVideoButton {
  @mixin resetButtonStyles;
  @mixin textButtonLarge;
  @mixin textButtonLinkUnderline currentcolor, 2px, 7px;

  position: absolute;
  bottom: var(--spacing-xl);
  left: 0;
  z-index: 10;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: var(--spacing-xs);

  &::before {
    content: "";
    display: block;
    width: var(--playVideoButtonSize);
    height: var(--playVideoButtonSize);
    border-radius: 100%;
    background-color: color(#494949 a(33%));
    border: var(--playVideoButtonBorderWidth) solid color(var(--whiteColor) a(60%));
    box-sizing: border-box;

    @media (--laptop) {
      width: var(--playVideoButtonSizeDesktop);
      height: var(--playVideoButtonSizeDesktop);
      border: var(--playVideoButtonBorderWidthDesktop) solid color(var(--whiteColor) a(60%));
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(var(--playVideoButtonSize) / 2);
    left: calc(var(--playVideoButtonSize) / 2);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 14px solid color(var(--whiteColor) a(75%));
    transform: translate(calc(-50% + 2px), -50%);

    @media (--laptop) {
      top: calc(var(--playVideoButtonSizeDesktop) / 2);
      left: calc(var(--playVideoButtonSizeDesktop) / 2);
      border-top-width: 16px;
      border-bottom-width: 16px;
      border-left-width: 20px;
      transform: translate(calc(-50% + 2px), -50%);
    }
  }

  @mixin focusVisible;
}
