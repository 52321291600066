@define-mixin storiesHeroTitlesVariant $backgroundColor, $fontColor {
  & .StoriesHero__title,
  & .StoriesHero__subtitle {
    color: $fontColor;
  }

  &::before {
    background: $backgroundColor;
  }
}

.StoriesHero__titles {
  &.StoriesHero__titles--Blue {
    @mixin storiesHeroTitlesVariant var(--theme-color-blue-bg-color),
      var(--theme-color-blue-text-color-lighter);
  }

  &,
  &.StoriesHero__titles--Default,
  &.StoriesHero__titles--BlueDark {
    @mixin storiesHeroTitlesVariant var(--theme-color-blue-dark-bg-color-lighter),
      var(--theme-color-blue-dark-text-color);
  }

  &.StoriesHero__titles--Brown {
    @mixin storiesHeroTitlesVariant var(--theme-color-brown-bg-color-lighter),
      var(--theme-color-brown-text-color);
  }

  &.StoriesHero__titles--Green {
    @mixin storiesHeroTitlesVariant var(--theme-color-green-bg-color),
      var(--theme-color-green-text-color-darker);
  }

  &.StoriesHero__titles--Yellow {
    @mixin storiesHeroTitlesVariant var(--theme-color-yellow-bg-color),
      var(--theme-color-yellow-text-color-lighter);
  }

  &.StoriesHero__titles--Turquoise {
    @mixin storiesHeroTitlesVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color);
  }

  &.StoriesHero__titles--TurquoiseDark {
    @mixin storiesHeroTitlesVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color);
  }

  &.StoriesHero__titles--GreenDark {
    @mixin storiesHeroTitlesVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color-darker);
  }

  &.StoriesHero__titles--SandDark {
    @mixin storiesHeroTitlesVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color);
  }

  &.StoriesHero__titles--ForestDark {
    @mixin storiesHeroTitlesVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color);
  }

  &.StoriesHero__titles--White {
    @mixin storiesHeroTitlesVariant var(--whiteColor), var(--base-grey-black-100);
  }

  &.StoriesHero__titles--Black {
    @mixin storiesHeroTitlesVariant #000000, var(--whiteColor);
  }
}
