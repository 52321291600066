.Section__title {
  @mixin h5Desktop;

  margin-block: var(--spacing-s);

  @media (--laptop) {
    @mixin h4Desktop;

    margin-block: var(--spacing-l);
  }
}
