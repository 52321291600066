.CalendarPage__group {

    list-style: none;
    margin: 0px;
    padding: 0px;
}

.CalendarItem {
    background-color: var(--themeSecondaryColor_tint_2);
    margin-bottom: 1rem;
}

.CalendarItem .row {
    @media (--tablet) {
        display: flex;
    }
}

.CalendarItem__day {    
    padding: 20px 0 0;
    color: var(--themeSecondaryColor_tint_6);
    text-align: center;
    font-size: 90px;
    font-weight: normal;
    line-height: 1;
    
    @media (--tablet) {
        padding: 4px 0;
    }
}

.CalendarItem__month {
    padding: 0 0 20px;
    color: var(--themeSecondaryColor_tint_6);
    text-align: center;
    font-size: 32px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: uppercase;
    
    @media (--tablet) {
        padding: 20px 0;
    }
}

.CalendarItem .ButtonSecondary {
    margin-top: 0.6rem;
}

.CalendarItem p {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (--tablet) {
        margin-top: 0.5rem;
        margin-bottom: 0;
    }
}

.CalendarItem__title {
    margin: 0 0 1rem;
}

.CalendarItem__duration {
    margin-right: 3rem;
    font-weight: normal;
}

.CalendarItem__location {
    font-weight: normal;
    text-wrap:none;
}

.CalendarItem__duration,
.CalendarItem__location {
    color: var(--themeSecondaryColor_tint_6)
}

.CalendarItem__image-col img {
    max-height: 160px;
    width: auto;
    max-width: 100%;
    margin: 1rem 0;
}

.CalendarPage__Section h2 {
    margin-top: 2rem;
}

@media (--tablet) {

    .CalendarItem__text-col, .CalendarItem__btn-col {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .CalendarItem__image-col {
        display: block;
        background-color: var(--whiteColor);
        padding: 0 1rem;
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    .CalendarItem__image-col img {
        position: absolute;
        top: 50%;
        width: calc(100% - 2rem);
        transform: translate(0, -50%);
        max-height: initial;
        max-width: 100%;
        margin: initial;
    }
}

_:-ms-fullscreen, :root .CalendarItem__image-col img {
    @media (--tablet) {
        max-height: none;
        max-width: 100%;
        margin: auto;
    }
}