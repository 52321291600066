.Quote__textWrapper--Left {
  @media (--laptop) {
    justify-content: flex-start;

    & blockquote {
      text-align: left;
    }
  }
}

.Quote__textWrapper--Center {
  justify-content: center;

  & blockquote {
    text-align: center;
  }
}

.Quote__textWrapper--Right {
  @media (--laptop) {
    justify-content: flex-end;

    & blockquote {
      text-align: right;
    }
  }
}