@define-mixin questionAndAnswerListColorVariant $blockBgColor, $blockFontColor, $itemsBgColor,
  $itemsFontColor {
  & .QuestionAndAnswerList {
    background: $blockBgColor;

    & a {
      /* stylelint-disable-next-line declaration-no-important */
      color: $itemsFontColor !important;
      text-decoration: underline;
    }

    & .AccordionList__item {
      background: $itemsBgColor;
      color: $itemsFontColor;
    }

    & .QuestionAndAnswerList__title,
    & .QuestionAndAnswerList__subheading {
      color: $blockFontColor;
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin questionAndAnswerListColorVariant var(--theme-color-beige-bg-color),
    var(--theme-color-beige-text-color), var(--theme-color-beige-block-bg-color),
    var(--theme-color-beige-block-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin questionAndAnswerListColorVariant var(--theme-color-blue-bg-color),
    var(--theme-color-blue-text-color), var(--theme-color-blue-block-bg-color),
    var(--theme-color-blue-block-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin questionAndAnswerListColorVariant var(--theme-color-blue-dark-bg-color),
    var(--theme-color-blue-dark-text-color), var(--theme-color-blue-dark-block-bg-color),
    var(--theme-color-blue-dark-block-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin questionAndAnswerListColorVariant var(--theme-color-brown-bg-color),
    var(--theme-color-brown-text-color), var(--theme-color-brown-block-bg-color),
    var(--theme-color-brown-block-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin questionAndAnswerListColorVariant var(--theme-color-green-bg-color),
    var(--theme-color-green-text-color), var(--theme-color-green-block-bg-color),
    var(--theme-color-green-block-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin questionAndAnswerListColorVariant var(--theme-color-yellow-bg-color),
    var(--theme-color-yellow-text-color), var(--theme-color-yellow-block-bg-color),
    var(--theme-color-yellow-block-text-color);
}

.ContainerWithBackground--Pink {
  @mixin questionAndAnswerListColorVariant var(--theme-color-pink-bg-color),
    var(--theme-color-pink-text-color), var(--theme-color-pink-block-bg-color),
    var(--theme-color-pink-block-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin questionAndAnswerListColorVariant var(--theme-color-turquoise-bg-color),
    var(--theme-color-turquoise-text-color), var(--theme-color-turquoise-block-bg-color),
    var(--theme-color-turquoise-block-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin questionAndAnswerListColorVariant var(--theme-color-turquoise-dark-bg-color),
    var(--theme-color-turquoise-dark-text-color), var(--theme-color-turquoise-dark-block-bg-color),
    var(--theme-color-turquoise-dark-block-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin questionAndAnswerListColorVariant var(--theme-color-green-dark-bg-color),
    var(--theme-color-green-dark-text-color), var(--theme-color-green-dark-block-bg-color),
    var(--theme-color-green-dark-block-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin questionAndAnswerListColorVariant var(--theme-color-sand-dark-bg-color),
    var(--theme-color-sand-dark-text-color), var(--theme-color-sand-dark-block-bg-color),
    var(--theme-color-sand-dark-block-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin questionAndAnswerListColorVariant var(--theme-color-forest-dark-bg-color),
    var(--theme-color-forest-dark-text-color), var(--theme-color-forest-dark-block-bg-color),
    var(--theme-color-forest-dark-block-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin questionAndAnswerListColorVariant var(--theme-color-green-one-bg-color),
    var(--theme-color-green-one-text-color), var(--theme-color-green-one-block-bg-color),
    var(--theme-color-green-one-block-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin questionAndAnswerListColorVariant var(--theme-color-green-five-bg-color),
    var(--theme-color-green-five-text-color), var(--theme-color-green-five-block-bg-color),
    var(--theme-color-green-five-block-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin questionAndAnswerListColorVariant var(--theme-color-forest-five-bg-color),
    var(--theme-color-forest-five-text-color), var(--theme-color-forest-five-block-bg-color),
    var(--theme-color-forest-five-block-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin questionAndAnswerListColorVariant var(--theme-color-blue-three-bg-color),
    var(--theme-color-blue-three-text-color), var(--theme-color-blue-three-block-bg-color),
    var(--theme-color-blue-three-block-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin questionAndAnswerListColorVariant var(--theme-color-yellow-two-bg-color),
    var(--theme-color-yellow-two-text-color), var(--theme-color-yellow-two-block-bg-color),
    var(--theme-color-yellow-two-block-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin questionAndAnswerListColorVariant var(--theme-color-yellow-five-bg-color),
    var(--theme-color-yellow-five-text-color), var(--theme-color-yellow-five-block-bg-color),
    var(--theme-color-yellow-five-block-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin questionAndAnswerListColorVariant var(--theme-color-red-four-bg-color),
    var(--theme-color-red-four-text-color), var(--theme-color-red-four-block-bg-color),
    var(--theme-color-red-four-block-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin questionAndAnswerListColorVariant var(--theme-color-grey-two-bg-color),
    var(--theme-color-grey-two-text-color), var(--theme-color-grey-two-block-bg-color),
    var(--theme-color-grey-two-block-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin questionAndAnswerListColorVariant var(--theme-color-grey-five-bg-color),
    var(--theme-color-grey-five-text-color), var(--theme-color-grey-five-block-bg-color),
    var(--theme-color-grey-five-block-text-color);
}
