:root {
  /* Height used instead of vh units*/
  --fallbackQuoteBlockHeight: 1000px;
}

.Quote--editMode {
  height: var(--fallbackQuoteBlockHeight);

  &.Quote {
    min-height: var(--fallbackQuoteBlockHeight);
  }

  & .Quote__backgroundWrapper {
    height: var(--fallbackQuoteBlockHeight);
  }

  & .Quote__background {
    background-attachment: initial;
  }

  & .Quote__content {
    margin-top: 300px;
  }
}