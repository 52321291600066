.Figure {
    position: relative;

    & picture {
        position: relative;
    }
}

.Figure__caption {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.57;
    margin-top: 1.3rem;
    max-width: 85%;
    position: relative;

    @media (--tablet) {
        font-size: 18px;
        line-height: 1.56;
        background: none;
        margin-top: 0;
    }
}
