.InputGroupAccordion {
  border: 1px solid var(--base-grey-medium-100);
  border-radius: 2px;
}

.InputGroupAccordion__header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-xxs) calc(var(--spacing-xxs) + (var(--spacing-xxxs) * 0.5))
    var(--spacing-xxs) var(--spacing-xs);
  width: 100%;
  position: relative;
}

.InputGroupAccordion__label {
  display: block;
  @mixin typographyDesktopStrongMedium;

  color: var(--base-grey-dark-100);
}

.InputGroupAccordion__actions {
  display: flex;
  align-items: center;
  gap: 6px;
}

.InputGroupAccordion__toggleButton {
  @mixin resetButtonStylesNoOverride;

  display: block;
  color: var(--base-grey-dark-100);
  font-size: 1.5rem;
  cursor: pointer;
  @mixin focusVisible var(--outlineColorSecondary);

  &::after {
    @mixin stretchFullSize;
  }

  & svg {
    transition: 0.25s transform;
  }
}

.InputGroupAccordion__content {
  overflow: hidden;
  visibility: hidden;
  height: 0;
}

.InputGroupAccordion__fieldset {
  padding: 0 calc(var(--spacing-xxs) + (var(--spacing-xxxs) * 0.5))
    calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-xs);
}

.InputGroupAccordion--expanded {
  & .InputGroupAccordion__toggleButton {
    & svg {
      transform: rotate(45deg);
    }
  }
}
