.SearchInput {
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 750px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    transition: transform 0.25s var(--ease_out_cubic), opacity 0.16s ease 0.08s;

    @media (--tablet) {
        background: none;
        height: 145px;
        align-items: center;
    }
}

.SearchInput {
    left: 50%;
    transform: translateX(-50%);
}

.SearchInput__inner {
    background: var(--whiteColor);

    @media (--tablet) {
        background: none;
    }
}