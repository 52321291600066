.CertificatePage {
    min-height: 100vh;
}

.CertificatePage__row {
    border-top: 1px #000000 solid;
    overflow: hidden;
    display: flex;
}

.CertificatePage__row dt {
    flex: 1;
    margin-top: 0.47em;
}

.CertificatePage__row dd {
    flex: 3;
    margin: 0.2em;
}

.CertificatePage__row ul {
    margin: 0;
    padding-left: 1.1em;
    font: inherit;
    list-style-image: svg-load('Icons/DownloadDocument.svg');
}

.CertificatePage__products {
    line-height: 2;
    padding-bottom: 7vh;
}

.CertificatePage__products ul {
    list-style: none;
    padding: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.CertificatePage__products li {
    font-weight: normal;
}

.CertificatePage__products li,
.CertificatePage__value {
    font-size: 24px;
}