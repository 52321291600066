/* stylelint-disable declaration-no-important */
.ContentListSlider {
  padding: var(--spacing-m) 0;
  --content-list-slider-pagination-width: 17.1875rem;
  --content-list-slider-pagination-gap: 0.25rem;
  --content-list-slider-pagination-padding: 0.625rem;
  --content-list-slider-arrow-size: 2rem;
  --content-list-slider-arrow-icon-size: 1.5rem;

  @media (--tablet) {
    --content-list-slider-pagination-width: 36.375rem;
    --content-list-slider-pagination-padding: 0;
    --content-list-slider-arrow-size: 2.625rem;
  }
}

.ContentListSlider__inner {
  overflow: hidden;
}

.ContentListSlider__sliderInner {
  width: 100%;
}

.ContentListSlider__sliderTrack {
  padding-left: var(--wrapperPadding) !important;
  padding-right: var(--wrapperPadding) !important;

  @media (--laptop) {
    /* 1px resolves the problem with overflow */
    padding-left: max(
      var(--wrapperPadding),
      calc((100% - var(--wrapperWidth)) / 2 - 1px)
    ) !important;
    padding-right: max(
      var(--wrapperPadding),
      calc((100% - var(--wrapperWidth)) / 2 - 1px)
    ) !important;
  }
}

.ContentListSlider__sliderItems {
  list-style: none;
}

.ContentListSlider__pagination {
  @mixin wrapper var(--content-list-slider-pagination-width), 0;

  padding: 0;
  gap: var(--content-list-slider-pagination-gap);

  & li {
    flex: 1;
  }

  &.ContentListSlider__pagination--hidden {
    display: none;
  }
}

.ContentListSlider__page {
  @mixin resetButtonStylesNoOverride;

  display: block;
  background-color: var(--base-grey-light-100);
  border-radius: 1px;
  width: 100%;
  height: 4px;
  cursor: pointer;
  @mixin focusVisible;

  transition: background-color 0.2s;

  &:hover {
    background-color: var(--base-grey-medium-100);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.is-active {
    background-color: var(--base-grey-dark-100);
  }
}

.ContentListSlider__arrow {
  @mixin resetButtonStylesNoOverride;
  @mixin flexCenter;

  color: var(--base-black-100);
  border-radius: 21px;
  border: 1px solid var(--base-grey-dark-80);
  background-color: var(--base-grey-white);
  width: var(--content-list-slider-arrow-size);
  height: var(--content-list-slider-arrow-size);
  cursor: pointer;
  transition-property: color, border-color, background-color;
  transition-duration: 0.3s;
  pointer-events: all;

  & svg {
    display: block;
    font-size: var(--content-list-slider-arrow-icon-size);
    position: relative;
    left: 0.0625rem;
  }

  &.ContentListSlider__arrow--prev {
    transform: rotate(180deg);
  }

  &:focus {
    outline: none;
  }

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:focus-visible,
  &:active:not([disabled]) {
    outline: none;
    background-color: var(--base-grey-light-80);
    border: 2px solid var(--base-grey-dark-100);
  }

  &:hover:not([disabled], :active) {
    background-color: var(--base-grey-light-80);
    border-color: var(--base-grey-light-80);
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    border-color: var(--base-grey-medium-100);
    color: var(--base-grey-dark-60);
  }
}

.ContentListSlider__arrows {
  max-width: calc(
    var(--content-list-slider-pagination-width) + (2 * var(--content-list-slider-arrow-size)) +
      (4 * var(--content-list-slider-pagination-gap)) +
      (2 * var(--content-list-slider-pagination-padding))
  );
  display: grid;
  grid-template-columns: var(--content-list-slider-arrow-size) 1fr var(
      --content-list-slider-arrow-size
    );
  width: 100%;
  gap: calc(var(--content-list-slider-pagination-gap) * 2);
  margin: var(--spacing-m) auto 0;
  padding-inline: var(--content-list-slider-pagination-padding);

  @media (--tablet) {
    max-width: calc(
      var(--content-list-slider-pagination-width) + (2 * var(--content-list-slider-arrow-size)) +
        (2.5 * var(--content-list-slider-pagination-gap) * 2)
    );
    gap: calc(2.5 * var(--content-list-slider-pagination-gap));
    margin-top: var(--spacing-l);
  }

  &.ContentListSlider__arrows--hasTransition {
    & .ContentListSlider__arrow {
      transition-property: color, border-color, background-color, opacity;
    }
  }

  &.ContentListSlider__arrows--center {
    max-width: calc(var(--wrapperWidth) + var(--content-list-slider-arrow-size));
    width: calc(100% - var(--spacing-xs)); /* space between the edge of the screen and arrow */
    display: flex;
    justify-content: space-between;
    gap: 0;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    margin: 0;

    @media (--laptop) {
      width: 100%;
    }

    & .ContentListSlider__arrow {
      border-color: transparent;

      &,
      &:hover {
        box-shadow: 0 0 5px 0 color(var(--blackColor) a(25%));
      }

      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      &:focus-visible,
      &:active:not([disabled]) {
        border: 2px solid var(--base-grey-dark-100);
      }

      &[disabled] {
        opacity: 0;
        pointer-events: none;
        cursor: default;
      }
    }

    & + .ContentListSlider__sliderInner {
      & .ContentListSlider__pagination {
        @mixin wrapper;

        margin-top: var(--spacing-m);

        @media (--tablet) {
          margin-top: var(--spacing-l);
          max-width: 675px;
        }
      }
    }
  }
}

.ContentListSlider__titleWrapper {
  margin-bottom: calc(var(--spacing-s) + var(--spacing-xxxs));

  @media (--tablet) {
    margin-bottom: calc(var(--spacing-s) + var(--spacing-xxxs));
  }
}

.ContentListSlider__title {
  @mixin typographyMobileDisplayLarge;

  margin-bottom: calc(1.75 * var(--spacing-xxs));

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;

    margin-bottom: var(--spacing-xxs);
  }
}

.ContentListSlider__description {
  @mixin typographyMobileDefaultLarge;

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;
  }
}

.ContentListSlider__slider--2inRow {
  & .ContentListSlider__slide {
    width: 510px;

    & .Icon {
      height: 510px;
    }
  }
}

.ContentListSlider__slider--3inRow {
  & .ContentListSlider__slide {
    width: 245px;

    & .Icon {
      height: 245px;
    }

    @media (--tablet) {
      width: 333px;

      & .Icon {
        height: 333px;
      }
    }
  }
}

.ContentListSlider__slider--4inRow {
  & .ContentListSlider__slide {
    width: 245px;

    & .Icon {
      height: 245px;
    }

    & button {
      width: 100%;
      text-align: center;
    }
  }
}
