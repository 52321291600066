@define-mixin articleTeaserColorVariant $backgroundColor, $buttonVariant {
  & .ArticleTeaser {
    background: $backgroundColor;
  }

  & .Btn.ArticleTeaser__link {
    @mixin $(buttonVariant);
  }
}

.ContainerWithBackground--Beige {
  @mixin articleTeaserColorVariant var(--theme-color-beige-bg-color), buttonPrimaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin articleTeaserColorVariant var(--theme-color-blue-bg-color), buttonPrimaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin articleTeaserColorVariant var(--theme-color-blue-dark-bg-color),
    buttonPrimaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin articleTeaserColorVariant var(--theme-color-brown-bg-color),
    buttonPrimaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin articleTeaserColorVariant var(--theme-color-green-bg-color),
    buttonPrimaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin articleTeaserColorVariant var(--theme-color-yellow-bg-color),
    buttonPrimaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin articleTeaserColorVariant var(--theme-color-pink-bg-color), buttonPrimaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin articleTeaserColorVariant var(--theme-color-blue-bg-color), buttonPrimaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin articleTeaserColorVariant var(--theme-color-turquoise-dark-bg-color),
    buttonPrimaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin articleTeaserColorVariant var(--theme-color-green-dark-bg-color),
    buttonPrimaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin articleTeaserColorVariant var(--theme-color-sand-dark-bg-color),
    buttonPrimaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin articleTeaserColorVariant var(--theme-color-forest-dark-bg-color),
    buttonPrimaryThemeColorForestTwo;
}

.ContainerWithBackground--GreenOne {
  @mixin articleTeaserColorVariant var(--theme-color-green-one-bg-color),
    buttonPrimaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin articleTeaserColorVariant var(--theme-color-green-five-bg-color),
    buttonPrimaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin articleTeaserColorVariant var(--theme-color-forest-five-bg-color),
    buttonPrimaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin articleTeaserColorVariant var(--theme-color-blue-three-bg-color),
    buttonPrimaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin articleTeaserColorVariant var(--theme-color-yellow-two-bg-color),
    buttonPrimaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin articleTeaserColorVariant var(--theme-color-yellow-five-bg-color),
    buttonPrimaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin articleTeaserColorVariant var(--theme-color-red-four-bg-color),
    buttonPrimaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin articleTeaserColorVariant var(--theme-color-grey-two-bg-color),
    buttonPrimaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin articleTeaserColorVariant var(--theme-color-grey-five-bg-color),
    buttonPrimaryThemeColorGreyFive;
}
