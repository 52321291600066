@define-mixin stepByStepColorVariant $pictureBgColor, $fontColor {
  & .StepByStep {
    color: $fontColor;

    & a {
      color: $fontColor;
      text-decoration: underline;
    }
  }

  & .StepByStepPicture--aqua::before,
  & .StepByStepPicture--grey::before,
  & .StepByStepPicture--beige::before {
    background: $pictureBgColor;
  }
}

.ContainerWithBackground--Beige {
  @mixin stepByStepColorVariant var(--theme-color-beige-block-bg-color),
    var(--theme-color-beige-text-color);
}

.ContainerWithBackground--BlueFive {
  @mixin stepByStepColorVariant var(--theme-color-blue-block-bg-color),
    var(--theme-color-blue-text-color);
}

.ContainerWithBackground--BlueTwo {
  @mixin stepByStepColorVariant var(--theme-color-blue-dark-block-bg-color),
    var(--theme-color-blue-dark-text-color);
}

.ContainerWithBackground--SandFour {
  @mixin stepByStepColorVariant var(--theme-color-brown-block-bg-color),
    var(--theme-color-brown-text-color);
}

.ContainerWithBackground--GreenFour {
  @mixin stepByStepColorVariant var(--theme-color-green-block-bg-color),
    var(--theme-color-green-text-color);
}

.ContainerWithBackground--YellowFour {
  @mixin stepByStepColorVariant var(--theme-color-yellow-block-bg-color),
    var(--theme-color-yellow-text-color);
}

.ContainerWithBackground--Pink {
  @mixin stepByStepColorVariant var(--theme-color-pink-block-bg-color),
    var(--theme-color-pink-text-color);
}

.ContainerWithBackground--TealFive {
  @mixin stepByStepColorVariant var(--theme-color-turquoise-block-bg-color),
    var(--theme-color-turquoise-text-color);
}

.ContainerWithBackground--TealTwo {
  @mixin stepByStepColorVariant var(--theme-color-turquoise-dark-block-bg-color),
    var(--theme-color-turquoise-dark-text-color);
}

.ContainerWithBackground--GreenTwo {
  @mixin stepByStepColorVariant var(--theme-color-green-dark-block-bg-color),
    var(--theme-color-green-dark-text-color);
}

.ContainerWithBackground--SandTwo {
  @mixin stepByStepColorVariant var(--theme-color-sand-dark-block-bg-color),
    var(--theme-color-sand-dark-text-color);
}

.ContainerWithBackground--ForestTwo {
  @mixin stepByStepColorVariant var(--theme-color-forest-dark-block-bg-color),
    var(--theme-color-forest-dark-text-color);
}

.ContainerWithBackground--GreenOne {
  @mixin stepByStepColorVariant var(--theme-color-green-one-block-bg-color),
    var(--theme-color-green-one-text-color);
}

.ContainerWithBackground--GreenFive {
  @mixin stepByStepColorVariant var(--theme-color-green-five-block-bg-color),
    var(--theme-color-green-five-text-color);
}

.ContainerWithBackground--ForestFive {
  @mixin stepByStepColorVariant var(--theme-color-forest-five-block-bg-color),
    var(--theme-color-forest-five-text-color);
}

.ContainerWithBackground--BlueThree {
  @mixin stepByStepColorVariant var(--theme-color-blue-three-block-bg-color),
    var(--theme-color-blue-three-text-color);
}

.ContainerWithBackground--YellowTwo {
  @mixin stepByStepColorVariant var(--theme-color-yellow-two-block-bg-color),
    var(--theme-color-yellow-two-text-color);
}

.ContainerWithBackground--YellowFive {
  @mixin stepByStepColorVariant var(--theme-color-yellow-five-block-bg-color),
    var(--theme-color-yellow-five-text-color);
}

.ContainerWithBackground--RedFour {
  @mixin stepByStepColorVariant var(--theme-color-red-four-block-bg-color),
    var(--theme-color-red-four-text-color);
}

.ContainerWithBackground--GreyTwo {
  @mixin stepByStepColorVariant var(--theme-color-grey-two-block-bg-color),
    var(--theme-color-grey-two-text-color);
}

.ContainerWithBackground--GreyFive {
  @mixin stepByStepColorVariant var(--theme-color-grey-five-block-bg-color),
    var(--theme-color-grey-five-text-color);
}
