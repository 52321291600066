.SlidesNavigation__button {
  @mixin buttonNext var(--blackColor), var(--whiteColor);
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 35px;
  left: 0;
  z-index: 2;
  transform: translateY(0);
  transition: z-index 0.5s step-start, transform 0.5s ease, opacity 0.5s ease;
  visibility: hidden;

  @media (--laptop) {
    display: flex;
    left: calc(50% - var(--wrapperWidth) / 2 - 40px);
    visibility: visible;
  }

  @media (--desktop) {
    left: calc(50% - var(--wrapperWidth) / 2 - 60px);
  }

  &::after {
    transform: rotate(90deg);
    transition: all ease .3s;
  }

  &.SlidesNavigation__button--backToTop {
    visibility: visible;
  }

  &.SlidesNavigation__button--backToTop::after {
    transform: rotate(-90deg);
  }
}

.SlidesNavigation__buttonDot {
  width: 5px;
  height: 5px;
  position: fixed;
  bottom: 100px;
  left: 0;
  z-index: 2;
  transform: translateY(0);
  transition: z-index 0.5s step-start, transform 0.5s ease, opacity 0.5s ease;
  visibility: hidden;
  background: var(--kog_bronze);
  border-radius: 100%;

  @media (--laptop) {
    display: flex;
    left: calc(50% - var(--wrapperWidth) / 2 - 27px);
    visibility: visible;
  }
  
  @media (--desktop) {
    left: calc(50% - var(--wrapperWidth) / 2 - 45px);
  }
}

.SlidesNavigation__buttonDot--active {
  width: 10px;
  height: 10px;

  @media (--laptop) {
    display: flex;
    left: calc(50% - var(--wrapperWidth) / 2 - 27px);
    visibility: visible;
  }

  @media (--desktop) {
    left: calc(50% - var(--wrapperWidth) / 2 - 48px);
  }
}

.SlidesNavigation__buttonDot--disabled {
  opacity: 0;
}

.SlidesNavigation__buttonsContainer {
  opacity: 1;
}

.SlidesNavigation__buttonsContainer--hidden {
  opacity: 0;
  transition: opacity 1s ease;
}