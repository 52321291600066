.NavList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.NavList__topItem {
  &:not(:last-child) {
    border-bottom: 1px solid var(--base-grey-medium-100);
  }
}

.NavList__topItemContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs))) 0;
  margin: 0;
}

.NavList__topItemLink {
  @mixin resetButtonStylesNoOverride;
  @mixin typographyMobileStrongXLarge;

  height: 1.5rem;
  @mixin focusVisible var(--outlineColorSecondary);

  &.NavList__topItemLink--asButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }
}

.NavList__topItemExpandBtn {
  @mixin resetButtonStylesNoOverride;

  cursor: pointer;
  @mixin focusVisible var(--outlineColorSecondary);
}

.NavList__topItemIcon {
  transition: transform 0.2s;
  font-size: 1.5rem;

  &.NavList__topItemIcon--rotated {
    transform: rotate(-180deg);
  }
}

.NavList__subListInner {
  display: flex;
  flex-direction: column;
  gap: calc(0.75 * var(--spacing-xxs));
  overflow: hidden;
}

.NavList__subList,
.NavList__additionalLinksList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: calc(0.75 * var(--spacing-xxs));
  align-items: flex-start;
}

.NavList__subListPadding {
  height: 0;
  transition: height 0.5s;
}

.NavList__subListWrapper {
  display: grid;
  grid-template-rows: 0fr;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  transition: grid-template-rows 0.3s;

  &.NavList__subListWrapper--isOpened {
    grid-template-rows: 1fr;

    & .NavList__subListPadding {
      height: var(--spacing-s);
    }
  }
}

.NavList__subItemLink,
.NavList__additionalLinksItemLink {
  display: flex;
  flex-direction: column;
  gap: calc(0.5 * var(--spacing-xxxs));
  padding: 0 calc(1.25 * var(--spacing-xxs));
  @mixin focusVisible var(--outlineColorSecondary), 2px, -2px;

  &.NavList__subItemLink--bigPadding,
  &.NavList__additionalLinksItemLink--bigPadding {
    padding: 0 calc(var(--spacing-xs) + calc(var(--spacing-xxxs) * 0.5));
  }
}

.NavList__subItemLinkTitle,
.NavList__additionalLinksItemLinkTitle {
  @mixin typographyMobileDefaultXLarge;

  & > span {
    min-height: 1.5rem;
  }

  display: flex;
  align-items: center;
  gap: var(--spacing-xxxs);
  padding: calc(1.25 * var(--spacing-xxxs)) 0;
}

.NavList__subItemIcon {
  font-size: 1.5rem;
  min-width: 1.5rem;
  transform: translateY(-2px);
}

.NavList__subItemLinkDescription {
  @mixin typographyMobileDefaultMedium;

  padding-left: calc(1.25 * var(--spacing-xxs));
  padding-bottom: var(--spacing-xxs);
}

.NavList.NavList--light {
  & .NavList__topItemLink,
  & .NavList__subItemLink {
    color: var(--whiteColor);
  }
}

.NavList__additionalLinks {
  border-top: 1px solid var(--base-grey-medium-100);
  margin-block: calc(var(--spacing-xxs) + var(--spacing-xxxs)) 0;
  margin-inline: calc(var(--spacing-xxs) + (var(--spacing-xxxs) / 2));
}

.NavList__additionalLinksInner {
  padding-block: var(--spacing-m) calc(var(--spacing-xxs) + var(--spacing-xxxs));
}

.NavList__additionalLinksHeader {
  @mixin typographyMobileStrongXLarge;

  margin-block: 0 var(--spacing-xxs);
}

.NavList__additionalLinksDescription {
  @mixin typographyMobileDefaultLarge;
}

.NavList__additionalLinksList {
  padding: var(--spacing-xxs) 0;
}

.NavList__additionalLinksItemLinkTitle {
  @mixin typographyMobileDefaultLarge;
}
