/* stylelint-disable declaration-property-unit-allowed-list */
.StoryTextContent__aside {
  @media (--tablet) {
    font-size: 16px;
    margin: 0 0 0 30px;
  }
}

.StoryTextContent aside {
  position: relative;

  @media (--mobileOnly) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 30px;
      width: 4px;
      height: 100%;
      background: var(--grayColor_tint_1);
    }
  }
}

.StoryTextContent__asideTitle {
  font-family: var(--font-family-stories-primary);
  font-weight: 400;
  letter-spacing: 1.4px;
  line-height: 1.5;
  margin: 0 60px 1rem 30px;
  font-size: 14px;
  padding-left: 1.2rem;

  @media (--tablet) {
    font-size: 20px;
    line-height: 28px;
    padding: 0.3rem 0 0;
    margin: 0;
  }

  @media (--laptop) {
    font-size: 20px;
    line-height: 32px;
  }

  &.animate {
    @media (--tablet) {
      transform: translateY(80px);
    }
  }
}

.ContentAreaSection--stories .StoryTextContent__asideTitle {
  letter-spacing: normal;
  margin-left: 30px;
  margin-right: 60px;
  margin-top: 2rem;
  padding: 0;

  @media (--tablet) {
    margin: 0;
    padding: 0;
  }
}

.StoryTextContent__asideText {
  position: relative;
  padding-left: 1.2rem;
  margin-left: 30px;
  margin-right: 60px;
  margin-bottom: 1rem;

  @media (--tablet) {
    margin: 2rem 0;
    padding-left: 0;
    padding-right: 1.2rem;
  }

  @media (--laptop) {
    margin: 40px 0 2rem;
    padding-right: 2.2rem;
  }

  @media (--tablet) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      background: var(--grayColor_tint_1);
      right: 0;
      width: 1px;
    }
  }

  & ol,
  & ul {
    padding-inline-start: 15px;
    font-size: 14px;
    font-family: var(--font-family-stories-primary);
    font-style: italic;
    line-height: 1.8;

    @media (--tablet) {
      font-size: 16px;
      line-height: 32px;
      padding-inline-start: 20px;
    }
  }
}

.ContentAreaSection--stories .StoryTextContent__asideText {
  padding-left: 0;
}
