.FastFactList--SimpleWithGrid .FastFact {
  max-width: 180px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (--tablet) {
    max-width: 244px;
    width: 244px;
  }

  & .FastFact__content {
    height: 100%;
    padding: calc(var(--spacing-xs) + calc(0.5 * var(--spacing-xxxs))) var(--spacing-xxs);

    @media (--tablet) {
      padding: calc(1.75 * var(--spacing-xxs)) calc(1.75 * var(--spacing-xxs)) var(--spacing-xs);
    }
  }

  & .FastFact__inner {
    align-items: center;
    flex-direction: column;

    @media (--laptop) {
      align-items: flex-start;
    }
  }

  & .FastFact__innerText {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: calc(0.75 * var(--spacing-xxs));

    @media (--tablet) {
      max-width: 190px;
    }

    @media (--tablet) {
      gap: var(--spacing-xxxs);
    }

    @media (--laptop) {
      text-align: left;
      padding-left: calc(0.5 * var(--spacing-xxxs));
    }
  }

  & .FastFact__icon {
    width: 36px;
    height: 36px;
    margin-bottom: var(--spacing-xxs);
    display: flex;
    justify-content: center;

    & svg {
      margin: 0;
      height: auto;
      transition: color 0.2s;
      fill: currentColor;
    }

    @media (--tablet) {
      width: 40px;
      height: 40px;
      justify-content: flex-start;
    }
  }

  & .FastFact__heading {
    @mixin typographyMobileDefaultMedium;

    transition: color 0.2s;

    @media (--tablet) {
      @mixin typographyDesktopDefaultMedium;
    }
  }

  & .FastFact__text {
    @mixin typographyMobileStrongXLarge;

    transition: color 0.2s;

    @media (--tablet) {
      @mixin typographyDesktopStrongXLarge;
    }
  }

  & .FastFact__link {
    border-radius: 4px;
    transition: all 0.2s;
    background: var(--whiteColor);
    box-shadow: 0 0 4px rgb(0 0 0 / 0.25);
    position: relative;
    @mixin focusVisible var(--outlineColorSecondary);

    &::after {
      content: "";
      position: absolute;
      right: calc(var(--spacing-xs) + var(--spacing-xxxs));
      bottom: var(--spacing-s);
      background: svg-load("Icons/Discovery/ArrowRight.svg", fill: var(--base-grey-black-100));
      transition: background 0.2s;
      width: var(--spacing-s);
      height: var(--spacing-s);
      display: none;

      @media (--laptop) {
        display: initial;
      }
    }

    &:hover {
      box-shadow: 0 0 8px rgb(0 0 0 / 0.5);

      & .FastFact__text,
      & .FastFact__icon svg,
      & .FastFact__heading {
        color: var(--accent-blue-accent-default);
      }

      &::after {
        background: svg-load(
          "Icons/Discovery/ArrowRight.svg",
          fill: var(--accent-blue-accent-default)
        );
      }
    }

    @media (--tablet) {
      border-radius: 2px;
    }

    & .FastFact__icon {
      width: 60px;
      height: 60px;

      @media (--tablet) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
