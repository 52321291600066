.ProductPageHeader--isDiscontinued {
  &.BackgroundHeroSection {
    & .BackgroundHeroSection__heroImage {
      filter: grayscale(1);
      opacity: 0.75;

      &::before {
        background: linear-gradient(
          356.39deg,
          color(var(--blackColor) a(50%)) 3.11%,
          color(#666 a(50%)) 72.54%
        );
      }
    }
  }
}

.ProductPageHeader__title {
  @mixin typographyMobileDisplayLarge;

  @media (--tablet) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.ProductPageHeader__tagline {
  @mixin typographyMobileStrongLarge;

  margin-top: var(--spacing-xxs);

  @media (--tablet) {
    @mixin typographyDesktopStrongXLarge;

    margin-top: calc(1.25 * var(--spacing-xxs));
  }
}
