.RichCallToAction__media--background {
  & .RichCallToAction__mediaInner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.3s var(--ease_out_cubic);

    @media (--tablet) {
      width: calc(55vw - 1rem);
    }

    @media (--laptop) {
      width: calc(55vw - 7rem);
    }
  }
}

.RichCallToAction__media.RichCallToAction__media--noBackground {
  @media (--tablet) {
    margin-bottom: 0;
  }
}