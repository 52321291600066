.CheckboxGroup__fieldset {
  margin: 0;
  padding: 0;
  border-width: 0;
}

.CheckboxGroup__listItem {
  display: flex;
  margin-block: var(--spacing-xxs);

  @media (--tablet) {
    line-height: 1;
  }
}

.CheckboxGroup__list--inline {
  display: flex;
  gap: 0.75rem; /* 12px */
  overflow-x: auto;
  white-space: nowrap;
  @mixin hideScrollbar;

  @media (--tablet) {
    gap: 1.125rem; /* 18px */
  }

  & .CheckboxGroup__listItem {
    @media (--tablet) {
      margin-block: 0.75rem; /* 12px */
    }
  }
}
