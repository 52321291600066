/* stylelint-disable selector-class-pattern */
.StoriesSearch {
  border-bottom: 7px solid var(--kog_blue_tint_7);
}

.StoriesSearch__wrapper {
  @mixin wrapper;

  display: flex;
  flex-flow: column nowrap;
  padding: 1.125rem 0;

  @media (--tablet) {
    flex-flow: row wrap;
    align-items: center;
  }
}

.StoriesSearch__title {
  font-family: var(--font-family-stories-secondary);
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 24px;
  font-weight: 400;
  line-height: 2.29;
  letter-spacing: 2px;
  margin: 0;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 28px;
    margin: 0 2rem 0 0;
  }

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 35px;
    max-width: 330px;
    margin-bottom: 0;
    margin-right: 4.125rem;
  }
}

.StoriesSearch__searchBar {
  flex: 1 0 auto;

  & .SearchInput {
    position: static;
    transform: none;
    margin-left: auto;
  }

  & .SearchInput__inner {
    padding: 0;

    @media (--tablet) {
      width: 100%;
    }

    &::before {
      width: 65px;
    }
  }

  & .react-autosuggest__container {
    width: 100%;
  }

  & .react-autosuggest__suggestions-container--open,
  & .react-autosuggest__suggestions-list {
    background-color: var(--whiteColor);
  }

  & .react-autosuggest__suggestions-list {
    top: 0;
    padding: 20px 0 40px;

    @media (--tablet) {
      padding: 20px 0 60px;
    }
  }

  & .react-autosuggest__suggestions-container--open {
    position: absolute;
    top: 48px;
    width: calc(100% + 30px);
    height: auto;
    left: -15px;

    @media (--tablet) {
      position: relative;
      top: 123px;
      left: auto;
      width: 100%;
    }
  }

  & .react-autosuggest__suggestion {
    padding: 10px;
    border-color: var(--grayColor_tint_1);

    &.react-autosuggest__suggestion--highlighted {
      background-color: var(--grayColor_tint_1);
    }
  }
}
