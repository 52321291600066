.ContentCard--wideAlignBottom {
  width: auto;
  height: auto;
  
  & a {
    display: block;
  }
  
  & .ContentCard__body {
    overflow: hidden;
    
    @media (--tablet) {
      display: flex;
    }
  }
  
  & .ContentCard__pictureContainer {
    @media (--tablet) {
      @neat-span-columns 5;
      margin-bottom: 0;
      display: flex;
    }
  }

  & .ContentCard__picture {
    flex: 1;
    padding-top: 70%;
  }
  
  & .ContentCard__text {
    @media (--tablet) {
      @neat-span-columns 6;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.ContentCard__text--noImage {
        @neat-span-columns 12;
      }
    }
  }

  & .ContentCard__textInner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
    
  & .ContentCard__title {
    margin-top: 0;

    & + p {
      margin-top: 20px;
    }
  }
  
  & .ContentCard__date {
    flex: 0;
    @mixin contentCardWideDate;
    margin-bottom: 12px;
  }


  & .ContentCard__link {
    flex: 0;
    margin-top: 32px;
    margin-bottom: 4px;

    & .ContentCard__link--text {
      font-size: 14px;
      background-position-x: 100%;
      padding-top: 4px;
      padding-left: 0;
      padding-right: 35px;

      &:hover {
        animation: moveBackgroundImage-10pxReverse ease 2s infinite;
      }
    }
  }
} 
