.MicrositeMenuModal__contentOuter {
  @mixin absoluteFullSize;
  display: flex;
  align-items: center;

  @media(--laptop) {
    padding-left: var(--menuBarSize);
  }
}

.MicrositeMenuModal__contentInner {
  display: flex;
  justify-content: center;
  margin: 0 16px;
  flex: 1;

  @media (--tablet) {
    margin: 0 48px;
  }

  @media (--laptop) {
    @mixin wrapper;
    flex: initial;
  }

  @media (--desktop4k) {
    max-width: 70%;
  }
}

.MicrositeMenuBar__list {
  flex: 1;
  list-style: none;
  padding: 0;
}
