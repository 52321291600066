.RichContentList {
  max-width: 100%;

  @media (--laptop) {
    display: flex;
    align-items: center;
  }

  & .Section__title {
    margin-top: 0;

    @media (--laptop) {
      margin: 0.5rem 0 2.5rem;
    }
  }

  & .cardsList__3col {
    @media (--laptop) {
      margin-top: 2.2rem;
    }
  }
}

.RichContentList__wrapper {
  @mixin richCampaignWrapper;

  position: relative;
}

.RichContentList__3col {
  display: grid;
  grid-template-columns: 1fr;
  padding-inline-start: 0;
  gap: var(--spacing-xs) var(--spacing-m);

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--laptop) {
    grid-template-columns: repeat(3, 1fr);
  }
}
