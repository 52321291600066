.DiscoveriesList__header {
  @mixin typographyDesktopStrongMedium;

  border-block-end: solid 1px #cfd1d3;
  padding-block-end: var(--spacing-xs);
}

.DiscoveriesList__filters--mobile {
  @media (--laptop) {
    display: none;
  }
}

.DiscoveriesList__filters--desktop {
  display: none;

  @media (--laptop) {
    display: block;
  }
}

.DiscoveriesList__input {
  @mixin resetButtonStylesNoOverride;

  padding-block: var(--spacing-xxs) var(--spacing-xxxs);
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  width: 100%;
  cursor: pointer;
}

.DiscoveriesList__input--selected {
  @mixin typographyDesktopStrongSmall;
}

.DiscoveriesList__filtersList {
  display: flex;
  flex-flow: column nowrap;
  padding-inline-start: 0;
  list-style-type: none;

  @media (--tablet) {
    @mixin typographyDesktopDefaultSmall;

    padding-block-start: var(--spacing-xs);
  }
}
