:root {
  --search-field-mobile-height: calc(4.25 * var(--spacing-base)); /* 34px */
  --search-field-desktop-height: calc(3.375 * var(--spacing-base)); /* 27px */
}

.SearchField {
  display: flex;
}

.SearchField__input {
  @mixin typographyDesktopDefaultLarge;
  @mixin resetSearchAppearance;

  appearance: none;
  border: none;
  border-bottom: 1px solid var(--base-grey-black-100);
  padding: var(--spacing-xxs) 0;
  height: var(--search-field-mobile-height);
  flex: 1;
  max-width: calc(100% - var(--search-field-mobile-height));
  background: transparent;

  &::placeholder {
    color: var(--base-grey-dark-60);
  }

  &:focus {
    outline: none;
  }

  @media (--laptop) {
    @mixin typographyDesktopDefaultMedium;

    max-width: initial;
    height: var(--search-field-desktop-height);
  }

  &.SearchField__input--hasKeyboardFocus {
    @mixin focusVisible var(--outlineColorSecondary), 2px, 1px;
  }
}

.SearchField__button {
  @mixin resetButtonStyles;

  cursor: pointer;
  width: var(--search-field-mobile-height);
  height: var(--search-field-mobile-height);
  display: flex;
  justify-content: center;
  align-items: center;
  @mixin focusVisible var(--outlineColorSecondary);

  @media (--laptop) {
    width: var(--search-field-desktop-height);
    height: var(--search-field-desktop-height);
  }
}

.SearchField__icon {
  font-size: calc(3.5 * var(--spacing-base));

  @media (--laptop) {
    font-size: var(--spacing-xs);
  }
}

.SearchField--light {
  & .SearchField__input {
    color: var(--whiteColor);
    border-color: var(--whiteColor);

    &::placeholder {
      color: var(--base-grey-medium-100);
    }
  }

  & .SearchField__button {
    color: var(--whiteColor);
  }
}
