.MainBreadcrumbs {
  height: var(--header-breadcrumbs-height);
  align-items: center;
  display: none;
  border-top: 1px solid var(--base-grey-light-80);
  background: var(--whiteColor);

  @media (--laptop) {
    display: flex;
  }
}

.MainBreadcrumbs__wrapper {
  width: var(--header-content-width);
  max-width: 100%;
  padding: 0 var(--spacing-s);
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (--desktop) {
    padding: 0 var(--spacing-xl);
  }
}

.MainBreadcrumbs__items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  & > li > a {
    @mixin typographyDesktopDefaultSmall;

    color: var(--header-breadcrumbs-color);
    white-space: nowrap;
    @mixin focusVisible var(--outlineColorSecondary);
  }

  & > li:last-of-type > a {
    @mixin typographyDesktopStrongSmall;

    pointer-events: none;
  }

  & > li:not(:last-of-type) {
    padding-right: calc(1.75 * var(--spacing-xxs));

    & > a {
      position: relative;
      z-index: 1;

      &::after {
        content: "/";
        @mixin typographyDesktopDefaultSmall;

        position: absolute;
        pointer-events: none;
        top: 0;
        left: 100%;
        width: calc(1.75 * var(--spacing-xxs));
        height: 100%;
        display: inline-block;
        text-align: center;
        color: var(--header-breadcrumbs-color);
      }
    }
  }
}
