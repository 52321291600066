.Profile {
  position: relative;
  overflow: hidden visible;

  &.animate {
    @media (--tablet) {
      transform: translateY(160px);
    }
  }
}

.Profile__inner {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 16px 30px;

  @media (--tablet) {
    flex-flow: row nowrap;
    padding: 40px 60px 40px 30px;
  }

  @media (--laptop) {
    @mixin wrapperStory;

    padding: 80px 0;
  }
}

.Profile__imgContainer {
  position: relative;
  width: 90px;
  height: 90px;
  margin: 0 auto 1rem 0;

  @media (--tablet) {
    width: 140px;
    padding-top: 0;
    margin: 0;
    min-width: calc(28% - 15px);
    height: 230px;
  }

  @media (--laptop) {
    width: 172px;
    padding-top: 0;
    min-width: 270px;
    height: 270px;
    margin-right: 35px;
  }

  & img {
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.Profile__description {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
  font-style: normal;
  line-height: 1.6;
  margin: 0 0 var(--spacing-s);

  @media (--tablet) {
    line-height: 1.4;
    align-self: center;
    font-size: 1.25rem;
    padding-left: 40px;
    padding-top: 60px;
  }

  @media (--laptop) {
    padding-left: 25px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: -80px;
    right: 0;

    @media (--tablet) {
      top: 0;
      left: 45px;
    }

    @media (--laptop) {
      left: 25px;
    }
  }
}

.Profile__descriptionSource {
  font-style: italic;
  display: block;
  margin-top: 16px;
}
