.VerticalContentList {
    & ul {
        list-style: none;
        padding: 0;
        margin: 0;

        @media (--laptop) {
            max-width: 75%;
        }

        & li {
            padding: 1.5rem 0;
            border-bottom: 1px solid var(--grayLight);

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.VerticalContentList__heading {
    @mixin h2;

    text-transform: none;
}