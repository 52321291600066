.Quote__textBox--Small {
  & .QuoteBlock__richText {
    &,
    & * {
      font-size: 0.875rem;

      @media (--tablet) {
        font-size: 1rem;
      }
    }

    & h2 {
      font-size: 1.25rem;

      @media (--tablet) {
        font-size: 1.5rem;
      }
    }
  }
}

.Quote__textBox--Regular {
  & .QuoteBlock__richText {
    &,
    & * {
      font-size: 0.875rem;

      @media (--tablet) {
        font-size: 1.25rem;
      }
    }

    & h2 {
      font-size: 1.25rem;

      @media (--tablet) {
        font-size: 2rem;
      }
    }
  }
}

.Quote__textBox--Small,
.Quote__textBox--Regular {
  padding: calc(var(--spacing-s) + calc(var(--spacing-xxxs) * 0.5))
    calc(var(--spacing-s) - calc(var(--spacing-xxxs) * 0.5))
    calc(var(--spacing-m) + calc(var(--spacing-xxxs) * 0.5)); /* 26px 22px 34px */

  @media (--tablet) {
    padding: calc(var(--spacing-s) + calc(var(--spacing-xxxs) * 0.5)) var(--spacing-m)
      calc(var(--spacing-l) - calc(var(--spacing-xxxs) * 0.5))
      calc(var(--spacing-s) - calc(var(--spacing-xxxs) * 0.5)); /* 26px 32px 38px 22px */
  }

  @media (--laptop) {
    width: 623px;
  }
}

.Quote__textBox--Large {
  padding: calc(var(--spacing-s) + calc(var(--spacing-xxxs) * 0.5))
    calc(var(--spacing-xs) + calc(var(--spacing-xxxs) * 0.5));

  @media (--tablet) {
    padding: calc(var(--spacing-l) + var(--spacing-xxxs)) var(--spacing-l) var(--spacing-xxl)
      var(--spacing-xl);
  }

  @media (--laptop) {
    width: 811px;

    & blockquote {
      padding-right: var(--spacing-xl);
    }
  }

  & .QuoteBlock__richText {
    &,
    & * {
      font-size: 1.5rem;

      @media (--tablet) {
        font-size: 1.75rem;
      }
    }

    & h2 {
      font-size: 2rem;

      @media (--tablet) {
        font-size: 2.5rem;
      }
    }
  }
}
