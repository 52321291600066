.Byline__text {
  max-width: 70%;
  margin: 0;
  font-size: 0.875rem;
  font-style: italic;

  @media (--tablet) {
    max-width: none;
    font-size: 1.125rem;
  }

  @media (--laptop) {
    font-size: 1.25rem;
  }
}

.Byline {
  margin: 0 0 1rem;

  @media (--tablet) {
    margin: 0 0 2.5rem;
  }
}

.Byline.Byline--creditsCenter {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @media (--tablet) {
    flex-direction: row;
  }
}

.Byline__date {
  margin-right: 30%;
}

.Byline__creditsWrapper.Byline__creditsWrapper--creditsCenter + .Byline__date {
  margin-right: auto;
}

.Byline__header {
  margin-right: 0.25em;
  font-weight: 600;
  font-style: normal;
}
