@define-mixin storyFactsListColorVariant $backgroundColor, $fontColor {
  color: $fontColor;

  & .StoryFactsList__background {
    &::before {
      background-color: $backgroundColor;
    }
  }

  & .StoryFact__button {
    color: $fontColor;
  }

  & .StoryFactsList__header {
    & svg {
      fill: $fontColor;
    }
  }
}

.StoryFactsList {
  &.StoryFactsList--backgroundColorGreyTwo {
    @mixin storyFactsListColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color);
  }

  &.StoryFactsList--backgroundColorGreenFour {
    @mixin storyFactsListColorVariant var(--theme-color-green-bg-color),
      var(--theme-color-green-text-color);
  }

  &.StoryFactsList--backgroundColorBlueFive {
    @mixin storyFactsListColorVariant var(--theme-color-blue-bg-color),
      var(--theme-color-blue-text-color);
  }

  &.StoryFactsList--backgroundColorTealTwo {
    @mixin storyFactsListColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color);
  }

  &.StoryFactsList--backgroundColorSandFour {
    @mixin storyFactsListColorVariant var(--theme-color-brown-bg-color),
      var(--theme-color-brown-text-color);
  }

  &.StoryFactsList--backgroundColorTealFive {
    @mixin storyFactsListColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color);
  }

  &.StoryFactsList--backgroundColorYellowFour {
    @mixin storyFactsListColorVariant var(--theme-color-yellow-bg-color),
      var(--theme-color-yellow-text-color);
  }

  &.StoryFactsList--backgroundColorGreenTwo {
    @mixin storyFactsListColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color);
  }

  &.StoryFactsList--backgroundColorBlueTwo {
    @mixin storyFactsListColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color);
  }

  &.StoryFactsList--backgroundColorSandTwo {
    @mixin storyFactsListColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color);
  }

  &.StoryFactsList--backgroundColorForestTwo {
    @mixin storyFactsListColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color);
  }

  &.StoryFactsList--backgroundColorGreenOne {
    @mixin storyFactsListColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color);
  }

  &.StoryFactsList--backgroundColorGreenFive {
    @mixin storyFactsListColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color);
  }

  &.StoryFactsList--backgroundColorForestFive {
    @mixin storyFactsListColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color);
  }

  &.StoryFactsList--backgroundColorBlueThree {
    @mixin storyFactsListColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color);
  }

  &.StoryFactsList--backgroundColorYellowTwo {
    @mixin storyFactsListColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color);
  }

  &.StoryFactsList--backgroundColorYellowFive {
    @mixin storyFactsListColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color);
  }

  &.StoryFactsList--backgroundColorRedFour {
    @mixin storyFactsListColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color);
  }

  &.StoryFactsList--backgroundColorGreyFive {
    @mixin storyFactsListColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color);
  }
}
