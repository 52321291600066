@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.NoResultsFound__container {
  padding-block: var(--spacing-s);
  font-size: 1.75rem;
  color: var(--grayColor_tint_4);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxs);
  opacity: 0;
  animation: fadeIn 0.25s 2s forwards;

  @media (--laptop) {
    padding: var(--spacing-s) var(--spacing-xs) var(--spacing-m);
  }
}

.NoResultsFound__header {
  color: var(--grayColor_tint_6);
  @mixin typographyDesktopStrongMedium;
}

.NoResultsFound__description {
  color: var(--grayColor_tint_6);
  @mixin typographyDesktopDefaultXSmall;
}
