/* To be used with Label component */

@define-mixin labelLargeMobile {
  @mixin typographyMobileCapsLarge;

  padding: var(--spacing-xxxs) 0.375rem;

  @media (--tablet) {
    @mixin typographyMobileCapsDefault;

    line-height: 1;
  }
}

@define-mixin labelLargeDesktop {
  @mixin typographyMobileCapsDefault;

  line-height: 1;
  padding: 0.375rem var(--spacing-xxs);
}

@define-mixin labelSmallMobile {
  @mixin typographyMobileCapsDefault;

  line-height: 1;
  padding: 0.1837rem;
}

@define-mixin labelSmallDesktop {
  @mixin typographyDesktopCapsDefault;

  line-height: 1;
  padding: var(--spacing-xxxs) 0.375rem;
}
