.RichFact {
  display: flex;
  flex-direction: column;

  & p {
    @media (--laptop) {
      flex: 1 1 auto;
    }
  }

  & a {
    color: inherit;
  }

  & .RichFact__iconText {
    @mixin h3;

    height: auto;
    margin: 1.4rem 0;
    display: flex;
    align-items: flex-end;

    @media (--tablet) {
      margin: 2rem 0;
      height: 120px;
    }
  }
}

.RichFact__header {
  margin: 0 0 1.4rem;

  @media (--tablet) {
    margin: 0 0 2rem;
    min-height: 55px;
  }

  @media (--laptop) {
    min-height: 77px;
    margin-bottom: 2rem;
  }

  @media (--desktop) {
    margin-bottom: 4rem;
  }

  & svg {
    max-height: 120px;
    max-width: 120px;
  }
}

.RichFact__bigText {
  @mixin h3;

  margin: 1.4rem 0;

  @media (--tablet) {
    margin: 2rem 0;
  }

  @media (--laptop) {
    @mixin h1Desktop;
  }
}

.RichFact__title {
  @mixin h6;

  margin: 0 0 0.25rem;

  @media (--tablet) {
    @mixin h6Desktop;

    margin: 0 0 1rem;
  }
}
