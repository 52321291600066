@define-mixin buttonSocial $icon: iconSocialFacebook, $color: black, $hoverColor: gray {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    width: 46px;
    height: 46px;
    display: block;
    background-size: 26px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s ease;
  }

  @mixin $icon $color, $hoverColor;
}

