.MicrositeProduct {
  z-index: 4;
}

.MicrositeProduct__backgroundImage {
  background-position-y: center;
}

.MicrositeProduct__outer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  
  &.MicrositeProduct__outer--dark {
    color: var(--whiteColor);
    background: color(var(--blackColor) a(90%));
  }

  &.MicrositeProduct__outer--light {
    color: var(--blackColor);
    background: color(var(--whiteColor) a(90%));

    & .MicrositeProduct__link {
      color: var(--blackColor);

      &:before {
        @mixin arrowIcon var(--blackColor);
      }
    }
  }
}

.MicrositeProduct__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px 0;
  height: 100%;

  @media (--tablet) {
    padding: 40px 48px 0;
  }

  @media (--laptop) {
    padding: 36px 124px;
    height: 100%;
  }
}

.MicrositeProduct__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 6;
}

.MicrositeProduct__content {
  width: calc(100% - 108px);
  top: 16px;
  position: absolute;
  z-index: 1;
  
  @media (--tablet) {
    width: calc(100% - 270px);
    top: 40px;
  }

  @media (--laptop) {
    width: calc(90% - 300px);
    top: 36px
  }
}

.MicrositeProduct__heading {
  @mixin h1Micro;
}

.MicrositeProduct__link {
  @mixin linkMicro;
  display: inline-block;
  color: var(--whiteColor);
  position: relative;
  padding-right: 24px;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    &:before {
      right: 0;
    }
  }

  &:before {
    @mixin arrowIcon;
    position: absolute;
    content: "";
    right: 5px;
    transition: right 0.3s ease-in-out;
    transform: translateY(50%);

    @media (--desktop4k) {
      transform: none;
      top: calc(50% - 5px);
      right: 0;
    }
  }
}

.MicrositeProduct__button {
  @mixin sectionPageCloseButtonPosition 124px;
}

.MicrositeProduct__gallery {
  height: 100%;
}
