.StoryHeaderInfo--withVideo {
  
  & a {
    pointer-events: all;
  }
  & .StoryHeaderInfo__background--above {
    z-index: 2;
  }
  & .StoryHeaderInfo__picture,
  & .StoryHeaderInfo__loader {
    @media(--laptop) {
      display: none;
    }
  }
    
  & .VideoFigure {
    height: 100%;
    width: 100%;
    
    & * {
      pointer-events: all;
    }
    
    & .Figure__caption  {
      display: none;
    }
    
    & .VideoFigure__VideoContainer {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.StoryHeaderInfo__media {
  height: 100%;
  width: 100%;
}

.StoryHeaderInfo__video {
  height: 100%;
  width: 100%;
  display: none;
  
  @media (--laptop) {
    display: block;
  }
  
  & > div {
    height: 100%;
  }
  
  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
  }
}