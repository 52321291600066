:root {
    --hamburger-size: 60px;
    --nav-from-top: 40px;
}

.CtaCampaignNavigation {
    position: absolute;
    display: flex;
    transform: translateX(-50%);
    left: 50%;
    z-index: 100;
    width: 100%;
    top: var(--nav-from-top);
}

.CtaCampaignNavigation--sticky {
    position: fixed;
    background-color: var(--kog_blue);
    top: 0;
    padding: 10px 0;
}

.CtaCampaignNavigation__container {
    width: 100%;
}

.CtaCampaignNavigation__checkbox {
    position: absolute;
    right: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 103;
    height: var(--hamburger-size);
    width: var(--hamburger-size);
}

.CtaCampaignNavigation__hamburger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    height: var(--hamburger-size);
    width: var(--hamburger-size);
    z-index: 102;
}

.CtaCampaignNavigation__bar {
    width: var(--hamburger-size);
    height: 1px;
    background-color: var(--whiteColor);
    transition: transform .5s ease-in-out;
}

.CtaCampaignNavigation__bar:not(:last-child) {
    margin-bottom: 8px;
}

.CtaCampaignNavigation__hamburger .bar1 {
    transform-origin: 0 0;
}


.CtaCampaignNavigation__container input[type="checkbox"]:checked ~ .CtaCampaignNavigation__hamburger .bar1 {
    transform: rotate(45deg) translate(-5px, -15px);
}

.CtaCampaignNavigation__container input[type="checkbox"]:checked ~ .CtaCampaignNavigation__hamburger .bar2 {
    transform: scaleY(0);
}

.CtaCampaignNavigation__container input[type="checkbox"]:checked ~ .CtaCampaignNavigation__hamburger .bar3 {
    transform: rotate(-45deg) translate(6px, -8px);
}

.CtaCampaignNavigation__container input[type="checkbox"]:checked ~ .CtaCampaignNavigation__linksContainer {
    transform: translateY(calc(-1 * (var(--hamburger-size) + var(--nav-from-top))));
}

.CtaCampaignNavigation__linksContainer {
    position: fixed;
    width: 100%;
    left: 0;
    transform: translateY(calc(-1 * 100% - (var(--hamburger-size) + var(--nav-from-top))));
    transition: transform 0.5s ease-in-out;
    background-color: var(--kog_blue);
    padding: 160px 0;
    z-index: 101;
}

.CtaCampaignNavigation__links {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 36px;
    line-height: 2;
}

.CtaCampaignNavigation__link a {
    color: var(--whiteColor);
}

.CtaCampaignNavigation__link a.active {
    display: none;
}

.preload {
    transition: none !important;
}