@define-mixin focusVisible $outlineColor: var(--outlineColor), $outlineWidth: 2px, $outlineOffset: 0 {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: $outlineColor solid $outlineWidth;
    outline-offset: $outlineOffset;
  }
}
