.ImageViewerArrow {
  @mixin resetButtonStylesNoOverride;
  @mixin focusVisible var(--outlineColorSecondary);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--whiteColor);
  box-shadow: 0 0 5px rgb(0 0 0 / 0.25);
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 7px rgb(0 0 0 / 0.25);
  }

  @media (--tablet) {
    width: 2.625rem;
    height: 2.625rem;
  }

  & > svg {
    width: var(--spacing-s);
    height: var(--spacing-s);
  }

  &.ImageViewerArrow--left > svg {
    transform: translateX(-1px) rotate(90deg);
  }

  &.ImageViewerArrow--right > svg {
    transform: translateX(1px) rotate(-90deg);
  }

  &.ImageViewerArrow--onMainImage {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.ImageViewerArrow--left {
      left: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    }

    &.ImageViewerArrow--right {
      right: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    }

    @media (--tablet) {
      display: none;
    }
  }
}
