/* stylelint-disable no-descending-specificity */
@define-mixin testimonialTileColorVariant $backgroundColor, $textColor {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: $backgroundColor;
    z-index: -1;
  }

  & .Testimonial.Testimonial--tile {
    & .Testimonial__inner {
      background: $backgroundColor;
      color: $textColor;
    }
  }
}

.ContentListSlider__slider--2inRow {
  & .ContentListSlider__slide:has(.Testimonial.Testimonial--tile) {
    width: 257px;

    @media (--tablet) {
      width: 510px;
    }

    & .Testimonial.Testimonial--tile {
      & .Testimonial__container {
        @mixin resetWrapper;
      }

      & .Testimonial__inner {
        margin-bottom: 0;
      }
    }
  }
}

.TestimonialsSection--colorLoop {
  & .ContentListSlider__slider--2inRow {
    & .ContentListSlider__slide {
      &:nth-child(3n + 1) {
        @mixin testimonialTileColorVariant var(--accent-blue-accent-darker), var(--base-grey-white);

        & .Testimonial--tile {
          @mixin testimonialTileColorVariant var(--accent-blue-accent-darker),
            var(--base-grey-white);
        }
      }

      &:nth-child(3n + 2) {
        @mixin testimonialTileColorVariant #5a3d18, var(--base-grey-white);

        & .Testimonial--tile {
          @mixin testimonialTileColorVariant #5a3d18, var(--base-grey-white);
        }
      }

      &:nth-child(3n + 3) {
        @mixin testimonialTileColorVariant var(--accent-teal-accent-darker), var(--base-grey-white);

        & .Testimonial--tile {
          @mixin testimonialTileColorVariant var(--accent-teal-accent-darker),
            var(--base-grey-white);
        }
      }
    }
  }
}
