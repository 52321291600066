.CallToActionButton {
  &:not(.CallToActionButton--colored) {
    &.CallToActionButton--primary {
      @mixin buttonPrimaryThemeColorBlack;
    }

    &.CallToActionButton--secondary {
      @mixin buttonSecondaryThemeColorBlack;
    }
  }
}