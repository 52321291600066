:root {
  --storiesMinHeroHeight: 500px;
}

/* stylelint-disable-next-line custom-media-pattern */
@custom-media --smallPanorama (min-width: 768px) and (min-aspect-ratio: 12/9) and (max-height: 550px);

/* stylelint-disable-next-line custom-media-pattern */
@custom-media --bigPanorama (min-width: 1025px) and (min-aspect-ratio: 12/9) and (min-height: 551px) and (max-height: 900px);

.StoriesHero__titles {
  min-height: 80px;

  @media (--laptop) {
    position: relative;
    margin-top: var(--storiesLogoAreaHeightLaptop);
    padding: 3rem 3.5rem 3rem 0;
    flex: 1 0 auto;
  }

  @media (--desktop) {
    margin-top: var(--storiesLogoAreaHeightDesktop);
  }

  @media (--bigPanorama) {
    margin-top: var(--storiesLogoAreaHeightTablet);
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: var(--storiesBlur);
    left: 0;
    right: 0;
    top: 0;
    z-index: -2;
    transition: opacity 0.3s ease;

    @media (--laptop) {
      left: initial;
      width: var(--storiesTextBlueBackgroundWidth);
    }
  }

  &:not(.StoriesHero__titles--withoutOpacity) {
    @media (--laptop) {
      &::before {
        opacity: 0.7;
      }
    }
  }
}

.StoriesHero {
  position: relative;
  display: flex;
  min-height: calc(100vh - var(--storiesHeaderHeightMobile));
  flex-flow: column nowrap;
  justify-content: flex-end;
  margin-bottom: 5rem;
  background: var(--kog_blue);

  @media (--laptop) {
    background: none;
    height: auto;
    min-height: calc(100vh - var(--storiesHeaderHeight));
  }

  @media (--smallPanorama) {
    min-height: var(--storiesMinHeroHeight);
  }

  &:hover .StoriesHero__titles:not(.StoriesHero__titles--withoutOpacity) {
    @media (--laptop) {
      &::before {
        opacity: 0.8;
      }
    }
  }

  &:hover .StoriesHero__mediaInner {
    &::before {
      background: color(var(--kog_blue) a(10%));
      z-index: 1;
    }
  }
}

.StoriesHero__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.StoriesHero__media {
  position: relative;
  flex: 1 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(45vh + 1px);

  @media (--laptop) {
    position: absolute;
    height: 100%;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.StoriesHero__mediaInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 250px;

  /* stylelint-disable-next-line no-descending-specificity */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: background-color 0.3s ease;
  }

  @media (--laptop) {
    height: calc(100vh - var(--storiesBottomWhiteBarHeight));
  }

  @media (--smallPanorama) {
    height: calc(var(--storiesMinHeroHeight) - var(--storiesBottomWhiteBarHeight));
  }
}

.StoriesHero__picture {
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: var(--heroMaxHeight);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (--mobileAndTablet) {
    /* stylelint-disable-next-line declaration-no-important */
    background-image: none !important;
  }

  & picture {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (--laptop) {
      height: auto;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (--laptop) {
      min-height: 800px;
      height: calc(100vh - var(--storiesHeaderHeight) - 130px);
    }

    @media (--smallPanorama) {
      height: calc(var(--storiesMinHeroHeight) - var(--storiesHeaderHeight));
    }
  }
}

.StoriesHero__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  @media (--tablet) {
    display: block;
  }

  & video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.StoriesHero__logoArea {
  position: absolute;
  width: var(--storiesLogoAreaHeightMobile);
  max-width: 50%;
  height: var(--storiesLogoAreaHeightMobile);

  @media (--tablet) {
    width: var(--storiesLogoAreaHeightTablet);
    height: var(--storiesLogoAreaHeightTablet);
    max-width: 30%;
  }

  @media (--laptop) {
    width: var(--storiesLogoAreaHeightLaptop);
    height: var(--storiesLogoAreaHeightLaptop);
  }

  @media (--desktop) {
    width: var(--storiesLogoAreaHeightDesktop);
    height: var(--storiesLogoAreaHeightDesktop);
  }

  @media (--bigPanorama) {
    width: var(--storiesLogoAreaHeightLaptop);
    height: var(--storiesLogoAreaHeightLaptop);
  }
}

.StoriesHero__logo {
  position: absolute;
  top: 15px;
  left: 0;
  width: 80%;
  min-height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 3;
  transform: translateY(40px);
  animation: fadeUp 1s var(--ease_soft) forwards;

  @media (--tablet) {
    top: 30px;
  }
}

.StoriesHero__text {
  color: var(--whiteColor);
  z-index: 1;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: left bottom;

  @media (--laptop) {
    position: static;
    min-height: calc(100vh - var(--storiesHeaderHeight));
    margin-top: 0;
    display: flex;
    transform: translateY(160px);
    animation: fadeUp 1.2s var(--ease_soft) forwards;
  }

  @media (--smallPanorama) {
    min-height: calc(var(--storiesMinHeroHeight) - var(--storiesHeaderHeight));
  }
}

.StoriesHero__textInner {
  @mixin wrapper;

  padding: 1rem 0;

  @media (--tablet) {
    padding: 3rem 2rem;
  }

  @media (--laptop) {
    display: flex;
    padding: 0;
    min-height: 100%;
  }
}

.StoriesHero__textCol1 {
  @media (--laptop) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    flex: 70%;
  }
}

.StoriesHero__textCol2 {
  flex: 30%;
}

.StoriesHero__title {
  line-height: 1.3;
  letter-spacing: 2px;
  margin: 1rem 0 0;
  @mixin dynamicFontSize 24, 90;

  @media (--tablet) {
    margin: 1rem 0;
    @mixin dynamicFontSize 36, 50;
  }

  @media (--laptop) {
    margin: 1rem 0;
    @mixin dynamicFontSize 36, 80;
  }

  @media (--desktop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 80px;
  }

  @media (--bigPanorama) {
    @mixin dynamicFontSize 50, 74;
  }
}

.StoriesHero__subtitle {
  font-family: var(--font-family-stories-secondary);
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 18px;
  font-weight: 400;
  line-height: 2.29;
  letter-spacing: 2px;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 26px;
  }

  @media (--laptop) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 35px;
  }

  @media (--bigPanorama) {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 30px;
  }
}

.StoriesHero__intro {
  color: var(--textColor);
  position: relative;
  display: flex;
  flex-flow: column;

  @media (--laptop) {
    margin-top: calc(100vh - var(--storiesBottomWhiteBarHeight));
    min-height: 130px;
    justify-content: flex-end;
  }

  @media (--smallPanorama) {
    margin-top: 340px;
  }

  & p {
    color: var(--whiteColor);
    margin: 0 0 1.4rem;

    @media (--laptop) {
      color: inherit;
    }
  }

  & .Btn--explore {
    margin-bottom: 2rem;

    @media (--tablet) {
      margin: 0 0 0 1.4rem;
    }

    & a {
      color: var(--whiteColor);

      @media (--tablet) {
        color: inherit;
      }
    }
  }

  & .Intro__date {
    font-family: var(--font-family-stories-secondary);
    font-weight: 500;
  }
}

.StoriesHeroIntroCTA__container {
  display: flex;
  justify-content: center;
}

.StoriesHero__introCTA {
  margin: 0;
  @media (--laptop) {
    width: 100%;
  }
}

.StoriesHero__introContent {
  margin: 1rem 0 1.4rem;

  @media (--laptop) {
    margin: 2rem 2rem 0;
  }
}
