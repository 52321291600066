table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  
  @media(--tablet) {
    font-size: 18px;
  }

  & a {
    color: var(--brandColor);
    text-decoration: none;
    word-break: normal;
  }
  
  & a:hover {
    text-decoration: underline;
  }
}

th, td {
  padding: 10px;
  text-align: left;

  @media(--tablet) {
    padding: 15px;
  }
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}