/* stylelint-disable selector-id-pattern */
.FormModal__modalContent {
  display: none;
}

.FormModal {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal);

  @media (--mobileOnly) {
    &.Modal::before {
      content: unset;
    }

    & .FormModal__dialog {
      max-width: unset;
      max-height: unset;
      width: 100%;
    }
  }

  & .FormModal__dialog {
    background-color: var(--base-grey-white);
    overflow-y: scroll;
    height: 100vh;

    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    @supports (height: 100dvh) {
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      height: 100dvh;
    }

    & .Modal__body {
      transition: unset;
      transform: unset;
      opacity: 1;
    }
  }

  & .FormModal__modalContent {
    display: block;
  }

  & .Form__Title {
    @mixin typographyDesktopStrongXXLarge;

    margin-bottom: var(--spacing-xs);
  }

  & .EPiServerForms {
    padding-block: var(--spacing-m);
  }

  & input[type="text"].FormTextbox__Input,
  textarea.FormTextbox__Input {
    max-width: unset;
  }

  & section.Form__Element {
    display: flex;
    flex-direction: column;
  }

  & .FormSubmitButton {
    align-self: end;
  }

  & section.Form__Element,
  & .FormSubmitButton {
    margin: 0;
  }
}
