@define-mixin storyCtaColorVariant $backgroundColor, $fontColor, $primaryBtnVariant {
  & .StoryCallToAction {
    & .StoryCallToAction__title {
      color: $fontColor;
    }

    & .StoryCallToAction__body {
      & > * {
        color: $fontColor;
      }
    }

    & .StoryCallToAction__overlay::after {
      background-color: $backgroundColor;
    }

    & .StoryCallToAction__button {
      @mixin $(primaryBtnVariant);
    }
  }
}

.ContainerWithBackground {
  & .StoryCallToAction {
    transform: translateY(0);
    margin-block: calc(-1 * var(--spacing-xs));

    @media (--tablet) {
      margin-block: calc(-1 * var(--spacing-xl));
    }
  }
}

.ContainerWithBackground--Beige {
  @mixin storyCtaColorVariant var(--theme-color-beige-bg-color), var(--theme-color-beige-text-color), buttonPrimaryThemeColorBeige;
}

.ContainerWithBackground--BlueFive {
  @mixin storyCtaColorVariant var(--theme-color-blue-bg-color), var(--theme-color-blue-text-color),
      buttonPrimaryThemeColorBlueFive;
}

.ContainerWithBackground--BlueTwo {
  @mixin storyCtaColorVariant var(--theme-color-blue-dark-bg-color),
      var(--theme-color-blue-dark-text-color), buttonPrimaryThemeColorBlueTwo;
}

.ContainerWithBackground--SandFour {
  @mixin storyCtaColorVariant var(--theme-color-brown-bg-color), var(--theme-color-brown-text-color),
      buttonPrimaryThemeColorSandFour;
}

.ContainerWithBackground--GreenFour {
  @mixin storyCtaColorVariant var(--theme-color-green-bg-color), var(--theme-color-green-text-color),
      buttonPrimaryThemeColorGreenFour;
}

.ContainerWithBackground--YellowFour {
  @mixin storyCtaColorVariant var(--theme-color-yellow-bg-color), var(--theme-color-yellow-text-color),
    buttonPrimaryThemeColorYellowFour;
}

.ContainerWithBackground--Pink {
  @mixin storyCtaColorVariant var(--theme-color-pink-bg-color), var(--theme-color-pink-text-color),
    buttonPrimaryThemeColorPink;
}

.ContainerWithBackground--TealFive {
  @mixin storyCtaColorVariant var(--theme-color-turquoise-bg-color),
      var(--theme-color-turquoise-text-color), buttonPrimaryThemeColorTealFive;
}

.ContainerWithBackground--TealTwo {
  @mixin storyCtaColorVariant var(--theme-color-turquoise-dark-bg-color),
      var(--theme-color-turquoise-dark-text-color), buttonPrimaryThemeColorTealTwo;
}

.ContainerWithBackground--GreenTwo {
  @mixin storyCtaColorVariant var(--theme-color-green-dark-bg-color),
      var(--theme-color-green-dark-text-color), buttonPrimaryThemeColorGreenTwo;
}

.ContainerWithBackground--SandTwo {
  @mixin storyCtaColorVariant var(--theme-color-sand-dark-bg-color),
      var(--theme-color-sand-dark-text-color), buttonPrimaryThemeColorSandTwo;
}

.ContainerWithBackground--ForestTwo {
  @mixin storyCtaColorVariant var(--theme-color-forest-dark-bg-color),
      var(--theme-color-forest-dark-text-color), buttonPrimaryThemeColorForestTwo;
}

.ContainerWithBackground--GreenOne {
  @mixin storyCtaColorVariant var(--theme-color-green-one-bg-color),
      var(--theme-color-green-one-text-color), buttonPrimaryThemeColorGreenOne;
}

.ContainerWithBackground--GreenFive {
  @mixin storyCtaColorVariant var(--theme-color-green-five-bg-color),
      var(--theme-color-green-five-text-color), buttonPrimaryThemeColorGreenFive;
}

.ContainerWithBackground--ForestFive {
  @mixin storyCtaColorVariant var(--theme-color-forest-five-bg-color),
      var(--theme-color-forest-five-text-color), buttonPrimaryThemeColorForestFive;
}

.ContainerWithBackground--BlueThree {
  @mixin storyCtaColorVariant var(--theme-color-blue-three-bg-color),
      var(--theme-color-blue-three-text-color), buttonPrimaryThemeColorBlueThree;
}

.ContainerWithBackground--YellowTwo {
  @mixin storyCtaColorVariant var(--theme-color-yellow-two-bg-color),
      var(--theme-color-yellow-two-text-color), buttonPrimaryThemeColorYellowTwo;
}

.ContainerWithBackground--YellowFive {
  @mixin storyCtaColorVariant var(--theme-color-yellow-five-bg-color),
      var(--theme-color-yellow-five-text-color), buttonPrimaryThemeColorYellowFive;
}

.ContainerWithBackground--RedFour {
  @mixin storyCtaColorVariant var(--theme-color-red-four-bg-color),
      var(--theme-color-red-four-text-color), buttonPrimaryThemeColorRedFour;
}

.ContainerWithBackground--GreyTwo {
  @mixin storyCtaColorVariant var(--theme-color-grey-two-bg-color),
      var(--theme-color-grey-two-text-color), buttonPrimaryThemeColorGreyTwo;
}

.ContainerWithBackground--GreyFive {
  @mixin storyCtaColorVariant var(--theme-color-grey-five-bg-color),
      var(--theme-color-grey-five-text-color), buttonPrimaryThemeColorGreyFive;
}
