.ClickDimensionsForm {
  padding: 2rem 0;
  overflow: hidden;
}

.clickdform.mainDiv {
  font-family: var(--FamilyPressura) !important;
  font-size: 18px !important;
  float: left;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  margin: 0 !important;
  overflow: initial !important;
}

.clickdform .responsiveRow {
  margin-bottom: .3rem;
}

.clickdform .responsiveCell div.alignTop,
.clickdform .responsiveCell div.alignBottom {
  display: block;
}

.clickdform .responsiveCell td {
    display: table-cell !important;

    & > * {
    vertical-align: middle !important;
  }
}

@define-mixin inputStyles {
  font-family: var(--FamilyPressura) !important;
  color: var(--blackLighterColor);
  padding: 0 15px !important;
  margin: 0;
  min-height: 40px;
  font-weight: normal;
  background-color: var(--secondaryColor_tint_2) !important;
  border: 1px solid var(--blackLighterColor) !important;
  max-width: 400px;
}

@define-mixin inputStylesFocus {
  box-shadow: 0 0 8px var(--secondaryColor_tint_2) !important;
  background: var(--whiteColor);
  border: 0 !important;
  color: var(--textColor);
}

.clickdform .responsiveCell,
.clickdform .responsiveCellSize1,
.clickdform .maxSize1,
.clickdform .minSize1 {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.ClickDimensionsForm .responsiveCell.responsiveCellSize1 {
    vertical-align: top;
}

.ClickDimensionsForm .responsiveCell.responsiveCellSize1,
.ClickDimensionsForm .alignBottom.minSize1 {
    width: unset;
    min-width: unset;
    max-width: unset;
}

.ClickDimensionsForm .responsiveCell.responsiveCellSize1 {
    width: 100%
}

@media (--tablet) {
    .ClickDimensionsForm .responsiveCell.responsiveCellSize1 {
        width: 33.333%;
    }
}

.clickdform input[type='text'],
.clickdform textarea,
.clickdform select {
  width: 100%;
  margin: 0 auto;
  @mixin inputStyles;

  &:focus {
    @mixin inputStylesFocus;
  }
}

.clickdform input[type="radio"],
.clickdform input[type="checkbox"] {
  margin-right: 5px;
}

.clickdform input[type="checkbox"] {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  background-color: var(--secondaryColor_tint_2);
  border: 1px solid var(--secondaryColor_tint_1);
  box-shadow: none;
  border-radius: 0;
  display: inline-block;
  position: relative;
  padding: 0;
}

.clickdform input[type="checkbox"]:checked {
  &::before {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--secondaryColor);
    height: 100%;
  }
}

.clickdform span {
  color: var(--textColor) !important;
  font-family: var(--FamilyPressura) !important;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px !important;
}

.clickdform span.maxSize1 {
  font-weight: 400 !important;
}

.clickdform .requiredInfo {
  font-family: var(--FamilyPressura) !important;
  margin-bottom: .6rem !important;
}

.clickdform .buttonContainer {
  margin: 0 auto;
  display: block;
}

.clickdform #btnSubmit,
.clickdform .WizardNextButton,
.clickdform .WizardPrevButton {
  border: none !important;
  border-radius: 0 !important;
  font-family: var(--FamilyPressura) !important;
  font-weight: 400 !important;
  margin: 15px 0 0 0;
  padding: 12px 24px;
  clear: both;
  width: 200px;
  min-width: 200px;
  height: 60px;
  font-size: 22px !important;
  background: var(--kog_red);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--whiteColor) !important;
  cursor: pointer;
  transition: box-shadow .2s ease-out;

  @media (--tablet) {
    width: 260px;
    min-width: 260px;
    font-size: 32px;
  }

  &:hover {
    @mixin hoverDarken;
  }
}

.clickdform .WizardPrevButton {
  background: var(--secondaryColor_tint_1);
}

.clickdform .WizardNextButton {
  background: var(--secondaryColor_tint_1);
}

.responsiveCell .responsiveCellSize1 {
  text-align: left !important;
}

.clickdform .QapTcha .dropError,
.clickdform .QapTcha .dropSuccess {
  font-size: 12px;
  padding: 2px;
}