.KeyFeaturesBlock {
  padding: 0;
  margin-block: 0;
  margin-left: calc(var(--spacing-xs) + var(--spacing-xxxs));
  column-count: 1;

  @media (--mobileSmall) {
    column-count: 2;
    column-gap: var(--spacing-xl);
  }

  @media (--tablet) {
    margin-left: calc(var(--spacing-s) + var(--spacing-xxxs));
  }

  @media (--laptop) {
    column-count: 3;
  }
}

.KeyFeaturesBlock__item {
  @mixin typographyMobileDefaultMedium;
  break-inside: avoid;
  page-break-inside: avoid;
  color: #011520;

  &:nth-child(6n) {
    margin-bottom: calc(var(--spacing-s) + var(--spacing-xxxs));
  }

  @media (--mobileSmall) {
    &:nth-child(6n) {
      margin-bottom: 0;
    }
  }

  @media (--tablet) {
    @mixin typographyDesktopDefaultLarge;
  }
}