.MicrositeHotSpot--white {
  &.MicrositeHotSpot--xSmall {
    @mixin hotSpot 40px;

    @media (--desktop4k) {
      @mixin hotSpot 75px;
    }
  }

  &.MicrositeHotSpot--small {
    @mixin hotSpot 48px;

    @media (--desktop4k) {
      @mixin hotSpot 82px;
    }
  }

  &.MicrositeHotSpot--medium {
    @mixin hotSpot 64px;

    @media (--desktop4k) {
      @mixin hotSpot 100px;
    }
  }

  &.MicrositeHotSpot--large {
    @mixin hotSpot 112px;

    @media (--desktop4k) {
      @mixin hotSpot 145px;
    }
  }

  & .MicrositeHotSpot__icon--arrow {
    @mixin arrowIcon var(--kog_bronze);
  }

  & .MicrositeHotSpot__icon--video {
    @mixin videoIcon var(--kog_bronze);
  }

  & .MicrositeHotSpot__icon--gallery {
    @mixin galleryIcon var(--kog_bronze);
  }
}

.MicrositeHotSpot--bronze {
  &.MicrositeHotSpot--xSmall {
    @mixin hotSpot 40px, var(--kog_bronze), var(--whiteColor);

    @media (--desktop4k) {
      @mixin hotSpot 75px, var(--kog_bronze), var(--whiteColor);
    }
  }

  &.MicrositeHotSpot--small {
    @mixin hotSpot 48px, var(--kog_bronze), var(--whiteColor);

    @media (--desktop4k) {
      @mixin hotSpot 82px, var(--kog_bronze), var(--whiteColor);
    }
  }

  &.MicrositeHotSpot--medium {
    @mixin hotSpot 64px, var(--kog_bronze), var(--whiteColor);

    @media (--desktop4k) {
      @mixin hotSpot 100px, var(--kog_bronze), var(--whiteColor);
    }
  }

  &.MicrositeHotSpot--large {
    @mixin hotSpot 112px, var(--kog_bronze), var(--whiteColor);

    @media (--desktop4k) {
      @mixin hotSpot 145px, var(--kog_bronze), var(--whiteColor);
    }
  }

  & .MicrositeHotSpot__icon--arrow {
    @mixin arrowIcon var(--whiteColor);
  }

  & .MicrositeHotSpot__icon--video {
    @mixin videoIcon var(--whiteColor);;
  }

  & .MicrositeHotSpot__icon--gallery {
    @mixin galleryIcon var(--whiteColor);;
  }
}

.MicrositeHotSpot--clear {
  &.MicrositeHotSpot--dark {
    &.MicrositeHotSpot--xSmall {
      @mixin hotSpotClear 40px;
  
      @media (--desktop4k) {
        @mixin hotSpotClear 75px;
      }
    }
  
    &.MicrositeHotSpot--small {
      @mixin hotSpotClear 48px;
  
      @media (--desktop4k) {
        @mixin hotSpotClear 82px;
      }
    }
  
    &.MicrositeHotSpot--medium {
      @mixin hotSpotClear 64px;
  
      @media (--desktop4k) {
        @mixin hotSpotClear 100px;
      }
    }
  
    &.MicrositeHotSpot--large {
      @mixin hotSpotClear 112px;
  
      @media (--desktop4k) {
        @mixin hotSpotClear 145px;
      }
    }
  
    & .MicrositeHotSpot__icon--arrow {
      @mixin arrowIcon var(--whiteColor);
    }
  
    & .MicrositeHotSpot__icon--video {
      @mixin videoIcon var(--whiteColor);;
    }
  
    & .MicrositeHotSpot__icon--gallery {
      @mixin galleryIcon var(--whiteColor);;
    }
  }

  &.MicrositeHotSpot--light {   
    &.MicrositeHotSpot--xSmall {
      @mixin hotSpotClear 40px, var(--blackColor);

      @media (--desktop4k) {
        @mixin hotSpotClear 75px, var(--blackColor);
      }
    }
  
    &.MicrositeHotSpot--small {
      @mixin hotSpotClear 48px, var(--blackColor);

      @media (--desktop4k) {
        @mixin hotSpotClear 82px, var(--blackColor);
      }
    }
  
    &.MicrositeHotSpot--medium {
      @mixin hotSpotClear 64px, var(--blackColor);

      @media (--desktop4k) {
        @mixin hotSpotClear 100px, var(--blackColor);
      }
    }
  
    &.MicrositeHotSpot--large {
      @mixin hotSpotClear 112px, var(--blackColor);

      @media (--desktop4k) {
        @mixin hotSpotClear 145px, var(--blackColor);
      }
    }
  
    & .MicrositeHotSpot__icon--arrow {
      @mixin arrowIcon var(--blackColor);
    }
  
    & .MicrositeHotSpot__icon--video {
      @mixin videoIcon var(--blackColor);;
    }
  
    & .MicrositeHotSpot__icon--gallery {
      @mixin galleryIcon var(--blackColor);;
    }
  }
}
