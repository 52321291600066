@define-mixin buttonSquareRotated $icon: arrowRightSimple, $bgcolor: red, $color: white {
  height: 62px;
  font-size: 16px;
  color: var(--blackColor);
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 70px;
  line-height: 1.2;
  max-width: 300px;
  
  @media (--tablet) {
    height: 72px;
    padding: 0 10px 0 90px;
    font-size: 20px;
  }
  
  &::before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    transform: rotate(45deg);
    background-color: $bgcolor;
    left: 10px;
    top: 10px;
    transition: all 0.3s ease;

    @media (--tablet) {
      width: 50px;
      height: 50px;
      top: 10px;
    }
  }

  &::after {
    left: 17px;
    top: 15px;

    @media (--tablet) {
      left: 22px;
      top: 20px;
    }
  }

  @mixin $(icon) $(color), 100%;

  @media (--tablet) {
    @mixin $(icon) $(color), 120%;
  }
  
  &:hover:before {
    @mixin hoverDarken;
  }
}