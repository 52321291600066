.ProductCertificatesPage {
    & .ProductCertificatesPage__intro {
        margin-bottom: 10rem;
    }

    & .ProductCertificatesPage__certificates {
        margin-bottom: 10rem;
    }
}

