.StoryCard {
    box-shadow: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12);
    transition: box-shadow 0.3s ease;
    
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    & a {
        display: flex;

        @media (--mobileSmall) {
            display: block;
        }   
    }
}

.StoryCard__text {
    padding: 12px 60px 12px 18px;
    min-height: 85px;
    position: relative;
    background: var(--kog_blue);
    flex: 1;
    @mixin arrowRight;    

    @media (--mobileSmall) {
        min-height: 170px;
        padding: 15px 80px 15px 15px;
    }    
    
    @media (--mobile) {
        padding: 30px 80px 30px 30px;
    }

    &::before {
        position: absolute;
        right: 20px;
        bottom: 27px;
        width: 25px;
        height: 25px;
        background-color: transparent;
        z-index: 2;

        @media (--mobileSmall) {
            width: 40px;
            height: 40px;
            right: 27px;
            bottom: 27px;
        }
    }

    &::after {
        content: '';
        display: inline-block;
        background: #fff;
        position: absolute;
        width: 23px;
        height: 23px;
        right: 21px;
        bottom: 28px;
        z-index: 1;
        transform: rotate(-45deg);

        @media (--mobileSmall) {
            width: 38px;
            height: 38px;
            right: 28px;
            bottom: 28px;
        }
    }
}

.StoryCard__title {
    color: var(--whiteColor);
    margin: 0;
    font-size: 16px;
    line-height: 1.33;
    text-transform: none;
    font-weight: 400;
    
    @media (--tablet) {
        font-size: 20px;
    }    
    
    @media (--laptop) {
        font-size: 22px;
    }
}

.StoryCard__image {
    width: 85px;
    height: 85px;
    position: relative;
    flex-shrink: 0;
    
    @media (--mobileSmall) {
        padding-top: 100%;
        width: 100%;
    }
    
    & picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    & img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: none;
    }
}
