.IconWithText {
  padding: 40px 0;
  transform: translateY(80px);

  @media (--tablet) {
    padding: 80px 0;
  }
}

.IconWithText__inner {
  align-items: center;
  display: flex;
  margin: 0 30px;

  @media (--tablet) {
    margin: 0 60px 0 30px;
  }

  @media (--laptop) {
    max-width: 890px;
    margin: 0 auto;
    width: calc(100% - 30px);
  }
}

.IconWithText__icon {
  & svg {
    margin: inherit;
    min-width: 120px;
    min-height: 120px;
    max-width: 144px;
    max-height: 144px;
  }

  @media (--tablet) {
    margin-left: auto;
  }

  @media (--laptop) {
    padding-right: 32px;
  }
}

.IconWithText__body {
  font-family: var(--font-family-stories-secondary);
  padding-left: 25px;
  font-weight: 400;
  line-height: 1.9;
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 14px;

  @media (--tablet) {
    width: 73%;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 18px;
    padding-left: 40px;
  }

  @media (--laptop) {
    line-height: 1.6;
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 20px;
    padding-left: 25px;
    width: 66%;
  }
}
