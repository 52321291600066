/* stylelint-disable selector-class-pattern */
/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-id-pattern */
#onetrust-consent-sdk {
  & #onetrust-banner-sdk {
    background-color: var(--kog_blue) !important;

    & a[href] {
      color: var(--kog_aqua_tint_1) !important;

      &:hover {
        color: var(--kog_aqua_tint_2) !important;
      }
    }

    & #onetrust-close-btn-container {
      & .onetrust-close-btn-ui {
        background: var(--kog_blue_tint_6) !important;

        &:hover {
          background: var(--cookie-banner-primary-button-hover) !important;
        }

        &::before,
        &::after {
          background: var(--kog_blue);
        }
      }
    }
  }
}
