.NavSubmenu__wrapper {
  background: var(--base-grey-light-100);
  margin-top: calc(1.25 * var(--spacing-base));
}

.NavSubmenu__inner {
  overflow-y: auto;
  max-height: calc(100vh - var(--header-height-without-breadcrumbs));
}

.NavSubmenu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.NavSubmenu__itemLink {
  @mixin typographyDesktopStrongMedium;

  display: flex;
  align-items: center;
  padding: calc(1.75 * var(--spacing-xxs)) var(--spacing-xs);
  transition: background 0.1s;

  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  &:hover,
  &:focus-visible {
    background: var(--whiteColor);
  }

  @mixin focusVisible var(--outlineColorSecondary), 2px, -2px;
}

.NavSubmenu {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.2s;

  &:not(.NavSubmenu--fullWidth) {
    width: 0;
  }

  &.NavSubmenu--fullWidth {
    width: 100%;
    top: calc(
      var(--header-height-without-breadcrumbs) - var(--spacing-s)
    ); /* header height without breadcrumbs - padding-bottom of wrapper */

    & .NavSubmenu__wrapper {
      box-shadow: inset 0 4px 10px 0 rgb(0 0 0 / 0.09);
      margin-top: 0;
    }

    & .NavSubmenu__inner {
      width: var(--header-content-width);
      max-width: 100%;
      margin: 0 auto;
      padding: calc(var(--spacing-xxs) + var(--spacing-xxxs)) var(--spacing-xl);
      overflow-y: auto;
      margin-top: var(--spacing-s);
      height: 100%;
      max-height: calc(100vh - var(--header-height-without-breadcrumbs));
    }

    & .NavSubmenu__list {
      width: 426px;
      gap: calc(0.75 * var(--spacing-base));
      transform: translateX(calc(-1 * var(--spacing-xs)));
    }

    & .NavSubmenu__itemLink {
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      &:focus-visible {
        outline-offset: 0;
      }
    }
  }

  &.NavSubmenu--visible {
    z-index: 1;
    opacity: 1;
    pointer-events: initial;
    visibility: initial;

    &:not(.NavSubmenu--fullWidth) {
      width: 426px;
    }
  }
}
