@define-mixin contentListDarkTheme {
  & .ContentList {
    & .Section__title {
      color: var(--white-0);
    }
  }
}

.Page--dark {
  @mixin contentListDarkTheme;
}

@media (--dark) {
  .Page:not(.Page--light) {
    @mixin contentListDarkTheme;
  }
}

