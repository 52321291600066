/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-property-unit-allowed-list */
.VacancyPage {
  margin-top: var(--spacing-l);

  @media (--laptop) {
    margin-top: var(--spacing-xxxxxxl);
  }

  & h1 {
    word-break: break-word;
  }
}

.VacancyPage__picture > picture > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  display: block;
}

.VacancyPage__heading {
  @mixin typographyMobileDisplayLarge;

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.VacancyPage__subheading {
  margin-top: var(--spacing-xs);

  @media (--laptop) {
    border-bottom: 1px solid var(--grayColor_tint_3);
    margin-top: var(--spacing-m);
  }

  & > p {
    @mixin typographyMobileStrongXLarge;

    @media (--laptop) {
      @mixin typographyDesktopStrongXLarge;

      margin-bottom: var(--spacing-m);
    }
  }
}

.VacancyPage__body {
  @mixin clearfix();
  @mixin wrapper var(--wrapperWidth), var(--spacing-s);

  display: grid;
  gap: var(--spacing-m);
  grid-template-rows: repeat(3, auto);

  @media (--laptop) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr;
    column-gap: var(--spacing-xl);
  }
}

.VacancyPage__bodyContent {
  grid-row: 3;

  @media (--laptop) {
    grid-column: 1 / 3;
    grid-row: 2;
  }
}

.VacancyPage__headingsWrapper {
  @media (--laptop) {
    grid-column: 1 / 3;
    grid-row: 1;
  }
}

.VacancyPage__applyNowBtn {
  justify-content: center;
  /* stylelint-disable-next-line declaration-no-important */
  width: 100% !important;
  min-height: var(--spacing-l);

  @media (--mobileOnly) {
    order: 1;
  }

  & > svg {
    width: var(--spacing-xs);
    height: var(--spacing-xs);
    margin-left: var(--spacing-xxs);
  }
}

.VacancyPage__aside {
  grid-row: 2;

  & > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
  }

  @media (--laptop) {
    max-width: var(--sidebarWidth);
    grid-column: 3 / -1;
    grid-row: 1 / -1;

    & > div {
      /* stylelint-disable-next-line value-no-vendor-prefix */
      position: -webkit-sticky;
      position: sticky;
      top: calc(
        var(--header-height-with-breadcrumbs) + calc(var(--spacing-xs) + var(--spacing-xxxs))
      );
    }
  }

  & .InfoPanel {
    background-color: var(--kog_blue_50);
    padding: var(--spacing-xs) calc(var(--spacing-xxxs) + var(--spacing-xxs));

    @media (--tablet) {
      min-width: var(--sidebarWidth);
    }

    @media (--laptop) {
      max-width: var(--sidebarWidth);
    }

    & dt {
      @mixin typographyDesktopStrongLarge;

      color: var(--kog_blue_tint_1);
      margin-bottom: var(--spacing-xxs);

      &:last-child {
        margin-bottom: 0;
      }

      @media (--mobileOnly) {
        font-size: 1rem;
      }
    }

    & > .InfoPanel__richText {
      margin: 0;

      & > div > dd,
      .InfoPanel__richText > div > dd & a {
        @mixin typographyDesktopDefaultMedium;

        margin-left: 0;

        @media (--mobileOnly) {
          font-size: 0.875rem;
        }
      }
    }
  }

  & .InfoPanel__richText > div {
    padding-bottom: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    padding-top: calc(var(--spacing-xxs) + var(--spacing-xxxs));
    border-bottom: 1px solid var(--kog_blue_300);

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &:first-child {
      padding-top: 0;
    }

    & > a {
      margin-bottom: 0;
      color: var(--blackColor);
    }
  }

  .VacancyPage__address {
    margin-left: 0;

    p {
      font-size: 0.875rem;
      margin-bottom: 0;

      @media (--tablet) {
        font-size: 1rem;
      }
    }

    div {
      display: flex;
      justify-content: space-between;

      & p {
        font-size: 0.875rem;
        margin-bottom: 0;

        @media (--tablet) {
          font-size: 1rem;
        }
      }
    }
  }

  .VacancyPage__addressButton {
    @mixin typographyDesktopStrongXSmall;

    margin-left: 21px;
    color: var(--kog_blue_tint_1);
    text-decoration: underline;
    text-decoration-thickness: 2px;
    position: relative;

    &:hover {
      text-decoration-thickness: 2px;
    }

    &::before {
      content: "";
      margin-right: 7px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      display: inline-flex;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: svg-load("Icons/Marker.svg", fill: var(--brandColor_dark));
      color: var(--brandColor_dark_tint_1);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -21px;
    }
  }

  .VacancyPage__persons {
    background-color: var(--kog_blue_50);
    padding: var(--spacing-xs) calc(var(--spacing-xxxs) + var(--spacing-xxs));
  }

  .VacancyPage__contactTitle,
  .VacancyPage__shareTitle {
    @mixin typographyDesktopStrongLarge;

    color: var(--kog_blue_tint_1);

    @media (--mobileOnly) {
      font-size: 1rem;
    }
  }

  .VacancyPage__social {
    @media (--mobileOnly) {
      order: 2;
    }
  }
}
