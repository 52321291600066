.FactsHero {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeSecondaryColor_tint_6);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  flex-flow: column;
  overflow: hidden;
  background-position: center;
  background-color: var(--themeSecondaryColor_tint_2);
}

.FactsHero__grid {
  @mixin wrapper;

  display: grid;
  grid-template-columns: auto;
  align-content: center;
  gap: var(--spacing-s);
  position: relative;
  z-index: 1;
  min-height: 360px;
  margin-block: var(--spacing-xxxxl);

  @media (--tablet) {
    grid-template-columns: min-content auto;
    gap: var(--spacing-m) var(--spacing-xl);
    min-height: 450px;
    margin-block: 0;
  }

  @media (--laptopMedium) {
    min-height: 500px;
  }
}

.FactsHero__fact {
  grid-row-start: 2;
  align-self: flex-end;
  width: 100%;

  @media (--tablet) {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  & > * {
    @mixin lineClamp 5;
  }

  & span,
  & p {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 34px;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;

    @media (--tablet) {
      @mixin h3Desktop;

      margin: 0;
    }
  }

  & i {
    font-style: italic;
  }

  & u {
    text-decoration: underline;
  }
}

.FactsHero__value {
  @mixin h1Desktop;

  margin-block: 0;
  align-self: flex-end;
  grid-row-start: 1;

  @media (--tablet) {
    /* stylelint-disable-next-line declaration-no-important */
    font-size: var(--h1-desktop-font-size) !important;
  }

  @media (--laptop) {
    @mixin h0Desktop;

    /* stylelint-disable-next-line declaration-no-important */
    font-size: var(--h0-desktop-font-size) !important;
    margin-block: 0;
  }

  &.FactsHero__value--decreaseFontOnMidSizes {
    @media (--tablet) {
      /* stylelint-disable-next-line declaration-no-important */
      font-size: 4rem !important;
    }

    @media (--laptopMedium) {
      /* stylelint-disable-next-line declaration-no-important */
      font-size: var(--h0-desktop-font-size) !important;
    }
  }
}

.FactsHero__link {
  grid-row-start: 3;
  justify-self: flex-start;

  @media (--tablet) {
    grid-row-start: 2;
    grid-column-start: 2;
  }
}

.FactsHero--hasBackgroundImage.FactsHero--hasContent {
  color: var(--whiteColor);

  &::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: color(var(--blackColor) a(40%));
  }

  & .FactsHero__fact {
    max-width: 450px;
  }
}
