.ProductBrowser {
  background: var(--base-grey-light-80);
  padding: var(--spacing-xxxxl) 0;
}

.ProductBrowser__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxxs);
}

.ProductBrowser__itemTitle {
  @mixin typographyMobileStrongMedium;
}

.ProductBrowser__itemButton {
  @mixin resetButtonStylesNoOverride;
  @mixin focusVisible var(--outlineColorSecondary);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-m);
  min-height: 60px;
  cursor: pointer;
  width: 100%;
  padding: var(--spacing-xs);

  @media (--tablet) {
    padding: var(--spacing-xs) var(--spacing-s);
  }
}

.ProductBrowser__itemIconWrapper {
  position: relative;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
}

.ProductBrowser__itemIcon {
  transition: opacity 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ProductBrowser__item {
  background: var(--whiteColor);
  border-radius: 0 0 4px 4px;
}

.ProductBrowser__headingsWrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xxs) + var(--spacing-xxxs));
  margin-bottom: var(--spacing-m);
}

.ProductBrowser__heading {
  @mixin typographyMobileDisplayLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopDisplayLarge;
  }
}

.ProductBrowser__subHeading {
  @mixin typographyMobileDefaultXLarge;

  color: var(--base-grey-black-100);

  @media (--laptop) {
    @mixin typographyDesktopDefaultXLarge;
  }
}
