@define-mixin iconWithTextColorVariant $backgroundColor, $fontColor {
  background-color: $backgroundColor;

  & .IconWithText__icon svg {
    fill: $fontColor;
  }

  & .IconWithText__body {
    color: $fontColor;
  }
}

.IconWithText {
  &,
  &.IconWithText--None {
    @mixin iconWithTextColorVariant var(--whiteColor), var(--blackColor);
  }

  &.IconWithText--Default {
    @mixin iconWithTextColorVariant var(--themeSecondaryColor_tint_2), var(--kog_bronze_tint_7);
  }

  &.IconWithText--Green {
    @mixin iconWithTextColorVariant var(--kog_green_tint_2), var(--kog_green_tint_4);
  }

  &.IconWithText--Blue {
    @mixin iconWithTextColorVariant var(--kog_blue_tint_7), var(--blackColor);
  }

  &.IconWithText--TurquoiseDark {
    @mixin iconWithTextColorVariant var(--kog_aqua_tint_6), var(--whiteColor);
  }

  &.IconWithText--Brown {
    @mixin iconWithTextColorVariant var(--themeSecondaryColor_tint_1), var(--kog_bronze_tint_7);
  }

  &.IconWithText--Turquoise {
    @mixin iconWithTextColorVariant var(--kog_aqua_tint_1), var(--kog_aqua_tint_7);
  }

  &.IconWithText--Yellow {
    @mixin iconWithTextColorVariant var(--kog_yellow), var(--blackColor);
  }

  &.IconWithText--GreenDark {
    @mixin iconWithTextColorVariant var(--kog_green_tint_3), var(--whiteColor);
  }

  &.IconWithText--BlueDark {
    @mixin iconWithTextColorVariant var(--kog_blue_tint_1), var(--whiteColor);
  }

  &.IconWithText--SandDark {
    @mixin iconWithTextColorVariant var(--kog_bronze_tint_6), var(--whiteColor);
  }

  &.IconWithText--ForestDark {
    @mixin iconWithTextColorVariant var(--kog_forest_tint_3), var(--whiteColor);
  }
}
