.ContactCard {
  position: relative;
  transition: all 0.2s ease;
  display: flex;
  height: 100%;

  & a {
    color: var(--whiteColor);
    display: flex;
    flex-flow: row wrap;
  }

  & a:focus {
    outline-color: var(--outlineColorSecondary);
  }
}

.ContactCard__inner {
  display: flex;
  flex-flow: column;
  width: 100%;
  z-index: 1;
}

.ContactCard__picture {
  position: absolute;
  width: 148px;
  height: 148px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0;

  &.Figure {
    position: absolute;
  }

  @media (--tablet) {
    width: 200px;
    height: 200px;
  }

  & picture {
    display: block;
    width: 148px;
    height: 148px;

    @media (--tablet) {
      width: 200px;
      height: 200px;
    }
  }

  & img {
    min-height: 100%;
    width: 100%;
  }
}

.ContactCard__name {
  @mixin h4;

  margin-block: 0 var(--spacing-xs);
}

.ContactCard__title {
  @mixin p;
}

.ContactCard__address {
  display: flex;
  align-items: center;
  @mixin p;

  margin-bottom: var(--spacing-xs);
  text-decoration: underline;

  &::before {
    content: "";
    background: svg-load("Icons/Email.svg");
    width: 25px;
    height: 25px;
    min-width: 25px;
    display: block;
    margin-right: var(--spacing-xxs);
    border-radius: 4px;
  }
}

.ContactCard__phone {
  display: flex;
  align-items: center;
  text-decoration: underline;

  &::before {
    content: "";
    background: svg-load("Icons/Phone.svg");
    width: 25px;
    height: 25px;
    min-width: 25px;
    display: block;
    margin-right: var(--spacing-xxs);
    border-radius: 4px;
  }
}

.ContactCard__readMore {
  margin-top: var(--spacing-m);
}

.ContactCard__address a:focus,
.ContactCard__phone a:focus,
.ContactCard__readMore:focus {
  outline-color: aqua;
}

.ContactCard__readMoreLink {
  @mixin p;
  @mixin arrowLinkRightGlyph;

  font-weight: 600;
  margin: 0;
}

.ContactCard--transitioned {
  z-index: 1;
  margin-bottom: 2rem;

  @media (--desktop) {
    margin-top: -235px;
    margin-bottom: 3rem;
  }
}

.Section .ContactCard {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (--tablet) {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
}

.ContactCard--absolute {
  @media (--desktop) {
    position: absolute;
  }
}
