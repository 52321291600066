/* stylelint-disable selector-pseudo-element-colon-notation */
.DropDownList__select {
  position: relative;

  &::before,
  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    right: 20px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    z-index: 1;
  }

  &::after {
    top: calc(50% + 2px);
    border-top-color: var(--brandColor);
  }

  &::before {
    bottom: calc(50% + 2px);
    border-bottom-color: var(--brandColor);
  }

  &.disabled {
    opacity: 0.4;

    &:before,
    &:after {
      opacity: 0.4;
    }
  }

  & select {
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 16px;
    height: 50px;
    padding: 0 45px 0 15px;
    box-sizing: border-box;
    appearance: none;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: none;
    border-color: var(--brandColor);
    background-color: var(--whiteColor);
    color: var(--brandColor);
    width: 100%;
  }

  & select:focus {
    box-shadow: 0 0 8px var(--brandColor_tint_2);
    border: 1px solid var(--brandColor);
    background: var(--whiteColor);
    color: var(--brandColor);
  }

  & select::-ms-expand {
    display: none;
  }

  /* stylelint-disable-next-line csstools/media-use-custom-media */
  @media screen and (min-width: 0\0) {
    select {
      background: none \9;
      padding: 5px \9;
    }
  }
}

.DropDownList__label {
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  font-size: 18px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
  color: var(--kog_blue_tint_1);
}

.DropDownList--dark .DropDownList__select {
  &::after {
    top: calc(50% + 2px);
    border-top-color: var(--kog_blue_tint_1);
  }

  &::before {
    bottom: calc(50% + 2px);
    border-bottom-color: var(--kog_blue_tint_1);
  }

  & select {
    border-color: var(--kog_blue_tint_1);
    color: var(--kog_blue_tint_1);
  }

  & select:focus {
    box-shadow: 0 0 8px var(--kog_blue_tint_3);
    border: 1px solid var(--kog_blue_tint_1);
    color: var(--kog_blue_tint_1);
  }
}
