.Teaser {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xs) + var(--spacing-xxxs));
  min-width: 207px;

  &.Teaser--noHeading {
    @media (--mobileAndTablet) {
      display: none;
    }
  }
}

.Teaser__headingDesktop {
  @mixin typographyMobileStrongLarge;

  @media (--laptop) {
    @mixin typographyDesktopStrongLarge;

    color: var(--base-grey-black-100);
    display: initial;
  }
}

.Teaser__headingMobile {
  @mixin typographyMobileStrongXLarge;
}

.Teaser__imageWrapper {
  width: 100%;
  overflow: hidden;

  & img {
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s;
    will-change: transform;
  }
}

.Teaser__imageLink {
  @media (--laptop) {
    @mixin focusVisible var(--outlineColorSecondary), 2px, 1px;

    display: initial;
  }

  &:hover {
    & .Teaser__imageWrapper img {
      transform: scale(1.2);
    }
  }
}

.Teaser__title {
  @mixin typographyMobileStrongXLarge;

  @media (--laptop) {
    @mixin typographyDesktopStrongXLarge;
  }
}

.Teaser__subTitle {
  @mixin typographyMobileDefaultLarge;

  @media (--laptop) {
    @mixin typographyDesktopDefaultLarge;
  }
}

.Teaser__details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  padding: var(--spacing-xs) calc(var(--spacing-xxxs) + var(--spacing-xxs));
  position: relative;
  z-index: var(--z-index-above);

  & > button {
    width: 100%;
    text-align: center;
  }
}

.Teaser__detailsText {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);

  @media (--laptop) {
    gap: calc(var(--spacing-xxxs) + var(--spacing-xxs));
  }
}

.Teaser--transparent {
  & .Teaser__details {
    color: var(--whiteColor);
    background-color: transparent;

    & picture {
      height: auto;
      padding-top: 0;
      margin-top: 0;
      position: relative;
      z-index: var(--z-index-rich-video-player-wrapper);
      overflow: hidden;
    }
  }

  & .Teaser__imageWrapper {
    position: relative;

    /* stylelint-disable-next-line no-descending-specificity */
    & img {
      height: 100%;
    }

    & .Teaser__details {
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      inset: 0;
      background:
        linear-gradient(180deg, color(var(--blackColor) a(0%)) 30.73%, var(--blackColor) 100%),
        transparent 0 0.99px / 100% 99.755% no-repeat;
      transition: all 0.6s ease;
      animation: fadeIn 0.3s ease;
    }
  }
}
