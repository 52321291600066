.RichCallToAction__container--mediaPositionRight {
  flex-direction: row;

  @media (--tablet) {
    flex-direction: row-reverse;
  }

  & .RichCallToAction__mediaInner {
    justify-content: flex-end;

    &::before {
      @media (--mobileOnly) {
        top: 15px;
        left: 15px;
      }
      
      @media (--tablet) {
        right: auto;
        left: 0;
        transform: translate(1rem, 1rem);
      }   
      
      @media (--laptop) {
        transform: translate(7rem, 3.5rem);
      }
    }
  }
}

.RichCallToAction__container--mediaPositionLeft {
  flex-direction: row;

  & .RichCallToAction__mediaInner {
    justify-content: flex-start;

    &::before {
      @media (--mobileOnly) {
        top: 15px;
        left: -15px;
      }

      @media (--tablet) {
        transform: translate(-1rem, 1rem);
        left: auto;
        right: 0;
      }

      @media (--laptop) {
        transform: translate(-7rem, 3.5rem);
      }
    }
  }
}